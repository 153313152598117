import { t } from 'i18next';
import { h } from 'hyperapp';
import { Card, Button, Form, FormInput, Enter, Calendar, Tile, Modal } from '@app/elements';
import { isDefined } from '@app/core';
import * as constants from '@app/constants';

import { ModalViewRedirectRDS } from './modals/modalView-redirectRds';
import { ModalViewMedicalConfirm } from './modals/modalView-medicalConfirm-default';

export default {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div class='patient-central'>
                <Card classes={'btzPageCard btzBackgroundCover'} kind={'datas'}>
                    {state.loaded &&
                        <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                            <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
                                <dummy>
                                    <Tile>
                                        <Tile.Content>
                                            <p class='btzTitle btzXl' style='display: inline-block;'>
                                                {t('Ajouter un patient.')}
                                            </p>
                                        </Tile.Content>
                                    </Tile>

                                    <Form onsubmit={event => event.preventDefault()} classes={'columns'}>
                                        <div class='col-6 col-xs-12'>
                                            <Form.Group classes={'borderedFormGroup'}>
                                                <div class='col-12 thfAlignTop'>
                                                    <p class='btzForm-btzLabel'>{t('Genre biologique')}</p>
                                                    <FormInput
                                                        placeholder={t('sélectionnez un genre')}
                                                        key='input-gender'
                                                        type='dropdown'
                                                        id='gender'
                                                        name='gender'
                                                        value={state.values.gender}
                                                        list={constants.custo.biologicalGender}
                                                        validation={true}
                                                        withSearch={false}
                                                        textedArea={false}
                                                        loaded={state.loaded}
                                                        oninputcb={actions._retrieveInput}
                                                        onblurcb={actions._retrieveErrorMessagePatient}
                                                        formSubmittedOnce={state.formSubmittedOnce}
                                                        errorMessage={state.errorMessages.gender}
                                                        isTyping={state.isTyping.gender}
                                                        required
                                                    />
                                                </div>
                                                <div class='col-12 thfAlignTop'>
                                                    <p class='btzForm-btzLabel'>{t('Nom')}</p>
                                                    <FormInput
                                                        placeholder={t('nom de famille du patient')}
                                                        key='input-lname'
                                                        type='text'
                                                        id='lname'
                                                        name='lname'
                                                        value={state.values.lname}
                                                        validation={true}
                                                        loaded={state.loaded}
                                                        oninputcb={actions._retrieveInput}
                                                        onblurcb={actions._retrieveErrorMessagePatient}
                                                        formSubmittedOnce={state.formSubmittedOnce}
                                                        errorMessage={state.errorMessages.lname}
                                                        isTyping={state.isTyping.lname}
                                                        required
                                                    />
                                                </div>
                                                <div class='col-12 thfAlignTop'>
                                                    <p class='btzForm-btzLabel'>{t('Prénom')}</p>
                                                    <FormInput
                                                        placeholder={t('prénom du patient')}
                                                        key='input-fname'
                                                        type='text'
                                                        id='fname'
                                                        name='fname'
                                                        value={state.values.fname}
                                                        validation={true}
                                                        loaded={state.loaded}
                                                        oninputcb={actions._retrieveInput}
                                                        onblurcb={actions._retrieveErrorMessagePatient}
                                                        formSubmittedOnce={state.formSubmittedOnce}
                                                        errorMessage={state.errorMessages.fname}
                                                        isTyping={state.isTyping.fname}
                                                        required
                                                    />
                                                </div>
                                                <div class='col-12 thfAlignTop'>
                                                    <Calendar oninput={actions._retrieveInput} onblur={actions._retrieveErrorMessagePatient} key={'birthdate'} name={'birthdate'} title={t('Date de naissance')} calendarDate={state.values.birthdate} deletetable={false} isValid={(state.formSubmittedOnce ? state.isValid.birthdate : 'is-default')} formSubmittedOnce={state.formSubmittedOnce} errorMessage={state.errorMessages.birthdate} isTyping={state.isTyping.birthdate} noFuture={true} />
                                                </div>
                                                <div class='col-12 thfAlignTop'>
                                                    <p class='btzForm-btzLabel'>{t('Identifiant patient unique')}</p>
                                                    <FormInput
                                                        placeholder={t('suite de caratères alphanumériques')}
                                                        key='input-hospitalid'
                                                        type='text'
                                                        id='hospitalId'
                                                        name='hospitalId'
                                                        value={state.values.hospitalId}
                                                        validation={true}
                                                        loaded={state.loaded}
                                                        oninputcb={actions._retrieveInput}
                                                        onblurcb={actions._retrieveErrorMessagePatient}
                                                        formSubmittedOnce={state.formSubmittedOnce}
                                                        errorMessage={state.errorMessages.hospitalId}
                                                        isTyping={state.isTyping.hospitalId}
                                                        required
                                                    />
                                                </div>
                                                <div class='col-12 thfAlignTop'>
                                                    <p class='btzForm-btzLabel'>{t('Numéro de sécurité sociale')}<font class='btzForm-btzLabel btzForm-btzLabelOptional'>{' (' + t('facultatif') + ')'}</font></p>
                                                    <FormInput
                                                        placeholder={t('285057510611204')}
                                                        key='input-socialsecuritynumber'
                                                        type='text'
                                                        id='socialSecurityNumber'
                                                        name='socialSecurityNumber'
                                                        value={state.values.socialSecurityNumber}
                                                        validation={true}
                                                        isInteger={true}
                                                        optional={true}
                                                        loaded={state.loaded}
                                                        oninputcb={actions._retrieveInput}
                                                        onblurcb={actions._retrieveErrorMessagePatient}
                                                        formSubmittedOnce={state.formSubmittedOnce}
                                                        errorMessage={state.errorMessages.socialSecurityNumber}
                                                        isTyping={state.isTyping.socialSecurityNumber}
                                                        required
                                                    />
                                                </div>
                                                <div class='col-12 thfAlignTop thfCustomPhone'>
                                                    <p class='btzForm-btzLabel'>{t('Numéro de téléphone')}</p>
                                                    <FormInput
                                                        key='input-phonePrefix'
                                                        type='dropdown'
                                                        id='phonePrefix'
                                                        name='phonePrefix'
                                                        value={state.values.phonePrefix}
                                                        list={constants.custo.phonePrefixes}
                                                        validation={true}
                                                        withSearch={false}
                                                        textedArea={false}
                                                        loaded={state.loaded}
                                                        oninputcb={actions._retrieveInput}
                                                        onblurcb={actions._retrieveErrorMessagePatient}
                                                        formSubmittedOnce={state.formSubmittedOnce}
                                                        errorMessage={state.errorMessages.phonePrefix}
                                                        isTyping={state.isTyping.phonePrefix}
                                                        required
                                                    />
                                                    <FormInput
                                                        placeholder={['06', '06', '06', '06', '06']}
                                                        key='input-phone'
                                                        type='phone'
                                                        id='phone'
                                                        name='phone'
                                                        valueType='phone'
                                                        value={state.values.phone}
                                                        validation={true}
                                                        isInteger={true}
                                                        dynFocus={true}
                                                        isValid={(state.formSubmittedOnce ? state.isValid.phone : 'is-default')}
                                                        loaded={state.loaded}
                                                        oninputcb={actions._retrieveInput}
                                                        onblurcb={actions._retrieveErrorMessagePatient}
                                                        formSubmittedOnce={state.formSubmittedOnce}
                                                        errorMessage={state.errorMessages.phone}
                                                        isTyping={state.isTyping.phone}
                                                        required
                                                    />
                                                </div>
                                                <div class='col-12 thfAlignTop'>
                                                    <p class='btzForm-btzLabel'>{t('Langue préférée')}</p>
                                                    <FormInput
                                                        key='input-locale'
                                                        type='dropdown'
                                                        id='locale'
                                                        name='locale'
                                                        value={state.values.locale}
                                                        list={constants.custo.languages}
                                                        oninputcb={actions._retrieveInput}
                                                        onblurcb={actions._retrieveErrorMessagePatient}
                                                        isTyping={state.isTyping.locale}
                                                    />
                                                </div>
                                                <div class='col-12 thfAlignTop'>
                                                    <p class='btzForm-btzLabel'>{t('Fuseau horaire du patient')}</p>
                                                    <FormInput
                                                        key='input-timezone'
                                                        type='dropdown'
                                                        id='timezone'
                                                        name='timezone'
                                                        value={state.values.timezone}
                                                        list={state.timezones}
                                                        oninputcb={actions._retrieveInput}
                                                        onblurcb={actions._retrieveErrorMessagePatient}
                                                        isTyping={state.isTyping.timezone}
                                                    />
                                                </div>
                                                <div class='col-12 thfAlignTop'>
                                                    <p class='btzForm-btzLabel'>{t('Email')}<font class='btzForm-btzLabel btzForm-btzLabelOptional'>{' (' + t('facultatif') + ')'}</font></p>
                                                    <FormInput
                                                        placeholder={t('adresse@mail.com')}
                                                        key='input-email'
                                                        type='email'
                                                        id='email'
                                                        name='email'
                                                        value={state.values.email}
                                                        validation={true}
                                                        optional={true}
                                                        isValid={(state.formSubmittedOnce ? state.isValid.email : 'is-default')}
                                                        oninputcb={actions._retrieveInput}
                                                        onblurcb={actions._retrieveErrorMessagePatient}
                                                        formSubmittedOnce={state.formSubmittedOnce}
                                                        errorMessage={state.errorMessages.email}
                                                        isTyping={state.isTyping.email}
                                                        required
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>

                                        <div class='col-6 col-xs-12'>
                                            <Form.Group classes={'borderedFormGroup'} style='padding-bottom: 24px;'>
                                                <div class='col-12 thfAlignTop thfButtonListing'>
                                                    <p class='btzForm-btzLabel'>{t('Sélection pathologie(s) / intervention(s)')}<font class='btzForm-btzLabel btzForm-btzLabelOptional'>{' (' + t('facultatif') + ')'}</font></p>
                                                    <FormInput
                                                        placeholder={t('Recherchez ou sélectionnez les pathologies ou interventions')}
                                                        key='input-pathologiesType'
                                                        type='dropdown'
                                                        id='pathologiesType'
                                                        name='pathologiesType'
                                                        valueType='pathologiesType'
                                                        value={state.values.pathologiesType}
                                                        list={constants.custo.pathologiesTypes.filter((f) => (state.values.pathologiesType.indexOf(f.key) === -1))}
                                                        fullList={constants.custo.pathologiesTypes}
                                                        validation={true}
                                                        withSearch={true}
                                                        textedArea={true}
                                                        optional={true}
                                                        loaded={state.loaded}
                                                        oninputcb={actions._retrieveInput}
                                                        onblurcb={actions._retrieveErrorMessagePatient}
                                                        formSubmittedOnce={state.formSubmittedOnce}
                                                        errorMessage={state.errorMessages.pathologiesType}
                                                        isTyping={state.isTyping.pathologiesType}
                                                        required
                                                    />
                                                </div>
                                                <div class='col-12 thfAlignTop thfButtonListing'>
                                                    <p class='btzForm-btzLabel'>{t('Notes')}<font class='btzForm-btzLabel btzForm-btzLabelOptional'>{' (' + t('facultatif') + ')'}</font></p>
                                                    <FormInput
                                                        placeholder={t('saisie libre')}
                                                        key='input-pathology'
                                                        type='textarea'
                                                        id='pathology'
                                                        name='pathology'
                                                        value={state.values.pathology}
                                                        validation={true}
                                                        optional={true}
                                                        textareaRows={5}
                                                        oninputcb={actions._retrieveInput}
                                                        onblurcb={actions._retrieveErrorMessagePatient}
                                                        formSubmittedOnce={state.formSubmittedOnce}
                                                        isTyping={state.isTyping.pathology}
                                                        required
                                                    />
                                                </div>
                                                <div class='col-12 thfAlignTop'>
                                                    <p class='btzForm-btzLabel'>{t('Établissement référent')}</p>
                                                    <FormInput
                                                        key='input-customer'
                                                        type='dropdown'
                                                        id='referenceCustomer'
                                                        name='referenceCustomer'
                                                        value={state.referenceCustomer}
                                                        list={(state.doctorRole === 'nurse' ? state.referenceHospital : [])}
                                                        readonly={(state.doctorRole === 'nurse' ? false : true)}
                                                        loaded={state.loaded}
                                                        oninputcb={actions.retrieveHospital}
                                                        onblurcb={actions._retrieveErrorMessagePatient}
                                                        formSubmittedOnce={state.formSubmittedOnce}
                                                        errorMessage={state.errorMessages.referenceCustomer}
                                                        isTyping={state.isTyping.referenceCustomer}
                                                        required
                                                    />
                                                </div>
                                                <div class='col-12 thfAlignTop'>
                                                    <p class='btzForm-btzLabel'>{t('Médecin référent')}</p>
                                                    <FormInput
                                                        placeholder={t('sélectionnez le médecin parmi la liste')}
                                                        key='input-doctor'
                                                        type='dropdown'
                                                        id='doctor'
                                                        name='doctor'
                                                        value={state.values.doctor}
                                                        list={state.referenceHealTeam.filter((f) => ((f.key !== state.values.coach) && state.values.nurses.indexOf(f.key) === -1))}
                                                        excludeList={state.referenceHealTeam.filter((f) => ((f.key !== state.values.coach) && (state.values.nurses.indexOf(f.key) === -1) && (f.status !== 'USER_ACTIVE')))}
                                                        validation={true}
                                                        withSearch={true}
                                                        textedArea={false}
                                                        loaded={state.loaded}
                                                        oninputcb={actions._retrieveInput}
                                                        onblurcb={actions._retrieveErrorMessagePatient}
                                                        formSubmittedOnce={state.formSubmittedOnce}
                                                        errorMessage={state.errorMessages.doctor}
                                                        isTyping={state.isTyping.doctor}
                                                        required
                                                    />
                                                </div>
                                                <div class='col-12 thfAlignTop'>
                                                    <p class='btzForm-btzLabel'>{t('Équipe médicale')}<font class='btzForm-btzLabel btzForm-btzLabelOptional'>{' (' + t('facultatif') + ')'}</font></p>
                                                    <FormInput
                                                        placeholder={t('recherchez et ajoutez les autres membres')}
                                                        key='input-nurses'
                                                        type='dropdown'
                                                        id='nurses'
                                                        name='nurses'
                                                        valueType='nurses'
                                                        value={state.values.nurses}
                                                        list={state.referenceHealTeam.filter((f) => ((f.key !== state.values.doctor) && (f.key !== state.values.coach) && state.values.nurses.indexOf(f.key) === -1))}
                                                        excludeList={state.referenceHealTeam.filter((f) => ((f.key !== state.values.doctor) && (f.key !== state.values.coach) && (state.values.nurses.indexOf(f.key) === -1) && (f.status !== 'USER_ACTIVE')))}
                                                        fullList={state.referenceHealTeam}
                                                        validation={true}
                                                        withSearch={true}
                                                        textedArea={true}
                                                        optional={true}
                                                        loaded={state.loaded}
                                                        oninputcb={actions._retrieveInput}
                                                        onblurcb={actions._retrieveErrorMessagePatient}
                                                        formSubmittedOnce={state.formSubmittedOnce}
                                                        errorMessage={state.errorMessages.nurses}
                                                        isTyping={state.isTyping.nurses}
                                                        required
                                                    />
                                                    <Button flat cancel onclick={actions.addWholeTeam}>
                                                        {t('Ajouter tout le monde') + ' (' + state.referenceHealTeam.filter((f) => ((f.key !== state.values.doctor) && (f.key !== state.values.coach) && state.values.nurses.indexOf(f.key) === -1)).length + ')'}
                                                    </Button>
                                                    {(isDefined(state.addWholeDisclamerMessage) && (state.addWholeDisclamerMessage !== '') && (!isDefined(state.values.doctor) || (state.values.doctor === ''))) &&
                                                        <p class="errorMessage" style='padding: 10px 0px;'>{state.addWholeDisclamerMessage}</p>
                                                    }
                                                </div>
                                            </Form.Group>
                                        </div>

                                        <Form.Group classes='btzForm-btzFooter' style={{ 'text-align': 'left', 'padding-left': '15px' }}>
                                            <Button loading={(state.formIsSubmitting)} active={state.isValid.email && state.isValid.birthdate && state.isValid.coach && state.isValid.doctor && state.isValid.etapes && state.isValid.fname && state.isValid.gender && state.isValid.hospitalId && state.isValid.lname && state.isValid.phone && state.isValid.phonePrefix && state.isValid.socialSecurityNumber} onclick={actions.onSubmit}>
                                                {t('Enregistrer')}
                                            </Button>
                                        </Form.Group>
                                    </Form>
                                </dummy>
                                {state.displayedRedirectRDS &&
                                    <Modal key={'modal-redirectrds'} customClass='modal-central' cancelAction={() => { actions.redirectToPatient({force: true, updatesettings: false}); }} modalDisplayed={true} content={<ModalViewRedirectRDS displayed={'redirectrds'} confirmAction={() => { actions.redirectToPatient({force: false, updatesettings: true}); }} cancelAction={() => { actions.redirectToPatient({force: true, updatesettings: true}); }} _retrieveInput={actions._retrieveInput} {...state} loaded={true}></ModalViewRedirectRDS>} />
                                }
                                {state.medicalTeamUpdateConfirm &&
                                    <Modal key={'modal-medical-confirm'} customClass='modal-central' cancelAction={() => { actions.onAddMeThenSubmit(); }} modalDisplayed={true} content={<ModalViewMedicalConfirm displayed={'medicalconfirm'} {...state} onSubmit={() => { actions.createPatient(true) }} onAddMeThenSubmit={() => { actions.onAddMeThenSubmit() }} cancelAction={() => { actions.onAddMeThenSubmit() }} loaded={true}></ModalViewMedicalConfirm>}></Modal>
                                }
                            </Enter>
                        </Card.Header>
                    }
                </Card>
            </div>
        );
        return view;
    }
}