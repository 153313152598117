import { t } from 'i18next';
import { h } from 'hyperapp';
import { Button, Card } from '@app/elements';
import { isDefined } from '@app/core';

import icoBack from '@app/img/ico/ico-back.png';
import icoBackx2 from '@app/img/ico/ico-back@2x.png';

import { ModalViewTreatmentForm } from './forms/modalView-treatmentForm-default';
import { ModalViewConfirm } from './confirm/modalView-confirm-default';

export const ModalViewTreatment = (props, actions) => (
    <Card classes={'btzInformations-btzInformationsModal'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            <div key={props.modalEditing} key={'treatment'}>
                <p class='btzTitle btzXl' style={{ 'padding-left': (((props.treatmentStep > 0) && (props.values.status !== false)) ? '60px' : '') }}>{t('Configuration')}</p>
                <p class='btzSubTitle'>{t('Configuration.')}</p>
            </div>
        </Card.Header>
        {props.loading ?
            <Card.Body>
                <div class='thfInput thfLoadingBox' style='height: 100%;position: absolute;left: 0;top: 0;width: 100%;'><div class='thfLoadingBoxWrapper' style='margin: 0px;'></div></div>
            </Card.Body>
            :
            <dummy>
                {(props.deactivationConfirm || props.stopConfirm) ?
                    <Card.Body>
                        <ModalViewConfirm {...props} />
                    </Card.Body>
                    :
                    <Card.Body>
                        {isDefined(props.currentTreatment) &&
                            <dummy>
                                {((props.treatmentStep > 0) && (props.values.status !== false)) &&
                                    <img class='thfBackButton' src={icoBack} srcset={`${icoBackx2} 2x`} alt='' onclick={() => {
                                        if (props.editFromRecap) {
                                            props.rollBackValuesWithPreviousValues();
                                            props.setState({key: 'treatmentStep', value: 3});
                                        } else {
                                            props.nextTreatmentStep({newStep: (props.treatmentStep - 1), fromStep: 'back'});
                                        }
                                    }} />
                                }
                                <ModalViewTreatmentForm {...props} />

                                <div class='btzCard-btzFooter-btzModal' style='margin-top: 40px;'>
                                    {((props.treatmentStep === 2) && (props.errorMessages.prescription !== '') && (props.values.prescription.length === 0)) &&
                                        <p class='btzForm-btzFormInput-errorMessage btzForm-btzFormInput-errorMessageForced'>{props.errorMessages.prescription}</p>
                                    }
                                    {(props.values.status === false) ?
                                        <dummy>
                                            <div style='position: absolute;left: 20px;bottom: 80px;'>
                                                <Button style={{ 'margin-left': '15px' }} flat successed onclick={() => {
                                                    // props.nextTreatmentStep({newStep: 'active'});
                                                    props.onEditStatusTreatment('reactivate');
                                                }}>
                                                    {t('Réactiver ce médicament')}
                                                </Button>
                                            </div>
                                            <Button anchorRight flat cancel onclick={(e) => {
                                                props.cancelAction(e);
                                            }}>
                                                {t('Annuler')}
                                            </Button>
                                        </dummy>
                                        :
                                        <dummy>
                                            {(isDefined(props.values.id) && (props.treatmentStep === 3)) ?
                                                <div style='position: absolute;left: 20px;bottom: 80px;'>
                                                    <Button style={{ 'margin-left': '15px' }} dangerous flat onclick={() => {
                                                        props.onEditStatusTreatment('deactivate');
                                                    }}>
                                                        {t('Arrêter ce médicament')}
                                                    </Button>
                                                </div>
                                                :
                                                <p class='thfSteps'>{t('étape') + ' ' + (props.treatmentStep + 1) + '/4'}</p>
                                            }
                                            {props.editFromRecap ?
                                                <dummy>
                                                    <Button anchorRight flat margin={'0 0 0 15px'} active onclick={() => {
                                                        props.nextTreatmentStep({newStep: 3, fromStep: (props.treatmentStep + 1)});
                                                    }}>
                                                        {(props.treatmentStep < 3) ? t('Confirmer') : t('Enregistrer')}
                                                    </Button>
                                                    <Button anchorRight flat cancel onclick={(e) => {
                                                        props.rollBackValuesWithPreviousValues();
                                                        if (props.treatmentStep === 3) {
                                                            props.cancelAction(e);
                                                        } else {
                                                            props.setState({key: 'treatmentStep', value: 3});
                                                        }
                                                    }}>
                                                        {t('Annuler')}
                                                    </Button>
                                                </dummy>
                                                :
                                                <dummy>
                                                    <Button anchorRight flat margin={'0 0 0 15px'} onclick={() => {
                                                        props.nextTreatmentStep({newStep: (props.treatmentStep + 1), fromStep: null});
                                                    }} active={(((props.treatmentStep === 0) && (props.isValid.name)) || ((props.treatmentStep === 1) && (props.values.frequencies === '0')) || ((props.treatmentStep === 1) && (props.values.frequencies === '1') && props.isValid.intervalday && props.isValid.interval) || ((props.treatmentStep === 1) && (props.values.frequencies === '2') && props.isValid.days) || ((props.treatmentStep === 1) && (props.values.frequencies === '3') && props.isValid.takeday && props.isValid.breakday) || ((props.treatmentStep === 2) && props.isValid.prescription) || (props.treatmentStep === 3))}>
                                                        {(props.treatmentStep < 3) ? t('Continuer') : t('Enregistrer')}
                                                    </Button>
                                                    <Button anchorRight flat cancel onclick={(e) => {
                                                        props.cancelAction(e);
                                                    }}>
                                                        {t('Annuler')}
                                                    </Button>
                                                </dummy>
                                            }
                                        </dummy>
                                    }
                                </div>
                            </dummy>
                        }
                    </Card.Body>
                }
            </dummy>
        }
    </Card>
);