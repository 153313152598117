import { t } from 'i18next'
import { h } from 'hyperapp'
import { Button, Form, FormInput, Card } from '@app/elements'
import * as constants from '@app/constants'

import icoArrowWhite from '@app/img/ico/ico-arrow-white-right.png'
import icoArrowWhitex2 from '@app/img/ico/ico-arrow-white-right@2x.png'

import icoTreat from '@app/img/ico/ico-treat.png'
import icoTreatx2 from '@app/img/ico/ico-treat@2x.png'
import icoTreatOff from '@app/img/ico/ico-treat-off.png'
import icoTreatOffx2 from '@app/img/ico/ico-treat-off@2x.png'

export const ModalViewPhone = (props, actions) => (
    <Card classes={'btzInformations-btzInformationsModal'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            <div class={'btzClassMarker-btz'} key={'phone'}>
                <p class="btzTitle btzXl">
                    {t('Modification de votre numéro')}
                </p>
                <p class="btzSubTitle">
                    {t(
                        'Pour changer votre numéro, renseignez votre nouveau numéro, saisissez le code reçu par SMS et répondez à votre question secrète.'
                    )}
                </p>

                <Form onsubmit={(event) => event.preventDefault()}>
                    <Form.Group>
                        <div class="col-12 thfAlignTop thfCustomPhone">
                            <p class="btzForm-btzLabel">
                                {t('Nouveau numéro de téléphone')}
                            </p>
                            <FormInput
                                key="input-phonePrefix"
                                type="dropdown"
                                id="phonePrefix"
                                name="phonePrefix"
                                value={props.values.phonePrefix}
                                list={constants.custo.phonePrefixes}
                                validation={true}
                                readonly={
                                    props.modalEditing === 'phone'
                                        ? false
                                        : true
                                }
                                oninputcb={props.retrieveInput}
                                onblurcb={props.retrieveErrorMessage}
                                formSubmittedOnce={props.formSubmittedOnce}
                                errorMessage={props.errorMessages.phonePrefix}
                                isTyping={props.isTyping.phonePrefix}
                                noAuthRequired={true}
                                required
                            />
                            <FormInput
                                placeholder={['01', '23', '45', '67', '89']}
                                key="input-newphone"
                                type="phone"
                                id="newphone"
                                name="newphone"
                                valueType="phone"
                                value={props.values.newphone}
                                validation={true}
                                isInteger={true}
                                dynFocus={true}
                                readonly={
                                    props.modalEditing === 'phone'
                                        ? false
                                        : true
                                }
                                oninputcb={props.retrieveInput}
                                onblurcb={props.retrieveErrorMessage}
                                formSubmittedOnce={props.formSubmittedOnce}
                                errorMessage={props.errorMessages.newphone}
                                isTyping={props.isTyping.newphone}
                                noAuthRequired={true}
                                required
                            />
                            {props.modalEditing === 'phone' && (
                                <Button
                                    flat
                                    active={
                                        props.isValid.phonePrefix &&
                                        props.isValid.newphone
                                    }
                                    loading={props.formIsSubmitting}
                                    noAuthRequired={true}
                                    onclick={(e) => {
                                        props.onSubmitPhone(e)
                                    }}
                                >
                                    <img
                                        alt={'send-code'}
                                        src={icoArrowWhite}
                                        srcset={`${icoArrowWhitex2} 2x`}
                                    />
                                    {t('Envoyer le code')}
                                </Button>
                            )}
                        </div>
                        {props.modalEditing.indexOf('phone-code') > -1 && (
                            <div class="col-12 thfAlignTop">
                                <p class="btzForm-btzLabel">
                                    {t('Code à 6 chiffres')}
                                </p>
                                <FormInput
                                    placeholder={['0', '0', '0', '0', '0', '0']}
                                    value={props.values.code}
                                    type="code"
                                    id="code"
                                    name="code"
                                    valueType="code"
                                    validation={true}
                                    isInteger={true}
                                    dynFocus={true}
                                    isValid={
                                        props.formSubmittedOnce
                                            ? props.isValid.code
                                            : 'is-default'
                                    }
                                    readonly={
                                        props.modalEditing === 'phone-code'
                                            ? false
                                            : true
                                    }
                                    oninputcb={props.retrieveInput}
                                    onblurcb={props.retrieveErrorMessage}
                                    formSubmittedOnce={props.formSubmittedOnce}
                                    errorMessage={props.errorMessages.code}
                                    isTyping={props.isTyping.code}
                                    noAuthRequired={true}
                                    required
                                />
                                {props.modalEditing === 'phone-code' && (
                                    <Button
                                        anchorRight
                                        flat
                                        active={props.isValid.code}
                                        loading={props.formIsSubmitting}
                                        noAuthRequired={true}
                                        onclick={(e) => {
                                            props.onSubmitPhoneCode(e)
                                        }}
                                    >
                                        {t('Vérifier le code')}
                                    </Button>
                                )}
                                {props.modalEditing === 'phone-code' && (
                                    <div class="" style="text-align: center;">
                                        <p class="ovr-lightgrey-colored">
                                            {t(
                                                "Vous n'avez pas reçu le code ?"
                                            )}
                                            <a
                                                class="btn-link"
                                                style="display: inline-block;margin-left: 5px;"
                                                href={'#'}
                                                onclick={(e) =>
                                                    props.onSubmitPhone(e)
                                                }
                                            >
                                                {t('Me renvoyer le code')}
                                            </a>
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}
                        {props.modalEditing.indexOf('phone-code-secret') > -1 &&
                            props.isValid.code && (
                                <div class="col-12 thfAlignTop">
                                    <p class="btzForm-btzLabel">
                                        {t(props.doctor.question)}
                                    </p>
                                    <FormInput
                                        placeholder={t('votre réponse secrète')}
                                        key="input-secret"
                                        type="text"
                                        id="secret"
                                        name="secret"
                                        validation={true}
                                        isValid={
                                            props.formSubmittedOnce
                                                ? props.isValid.secret
                                                : 'is-default'
                                        }
                                        readonly={
                                            props.modalEditing ===
                                            'phone-code-secret'
                                                ? false
                                                : true
                                        }
                                        oninputcb={props.retrieveInput}
                                        onblurcb={props.retrieveErrorMessage}
                                        formSubmittedOnce={
                                            props.formSubmittedOnce
                                        }
                                        errorMessage={
                                            props.errorMessages.secret
                                        }
                                        isTyping={props.isTyping.secret}
                                        noAuthRequired={true}
                                        required
                                    />
                                </div>
                            )}
                    </Form.Group>
                    <Form.Group classes="btzForm-btzFooter">
                        <Button
                            anchorLeft
                            flat
                            cancel
                            noAuthRequired={true}
                            onclick={(e) => {
                                props.cancelAction(e)
                            }}
                        >
                            {t('Annuler')}
                        </Button>
                        <Button
                            anchorRight
                            flat
                            active={
                                props.modalEditing === 'phone-code-secret' &&
                                props.isValid.phonePrefix &&
                                props.isValid.newphone &&
                                props.isValid.code &&
                                props.isValid.secret
                            }
                            noAuthRequired={true}
                            loading={
                                props.formIsSubmitting ||
                                (props.modalEditing !== 'phone-code-secret' &&
                                    !props.isValid.phonePrefix) ||
                                !props.isValid.newphone ||
                                !props.isValid.code ||
                                !props.isValid.secret
                            }
                            onclick={(e) => {
                                if (
                                    props.modalEditing ===
                                        'phone-code-secret' &&
                                    props.isValid.phonePrefix &&
                                    props.isValid.newphone &&
                                    props.isValid.code &&
                                    props.isValid.secret
                                ) {
                                    props.onSubmit(e)
                                }
                            }}
                        >
                            <img
                                class="activated activated-visible"
                                src={icoTreat}
                                srcset={`${icoTreatx2} 2x`}
                                alt=""
                            />
                            <img
                                class="deactivated-visible"
                                src={icoTreatOff}
                                srcset={`${icoTreatOffx2} 2x`}
                                alt=""
                            />
                            {t('Enregistrer')}
                        </Button>
                    </Form.Group>
                </Form>
            </div>
        </Card.Header>
    </Card>
)
