import { t } from 'i18next';
import { h } from 'hyperapp';
import { Card, Tile, Enter } from '@app/elements';
import * as constants from '@app/constants';


export default {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div class='patient-central' style={{ 'background-color': '#f8fafb' }}>
                <Card classes={'btzPageCard btzBackgroundCover'} kind={'datas'} style={{ 'margin': '26px 38px 0px 40px' }}>
                    <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                        <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
                            <dummy>
                                <Tile>
                                    <Tile.Content>
                                        <p class='btzTitle btzXl'>
                                            {t('Télésuivi IC')}
                                        </p>
                                    </Tile.Content>
                                </Tile>
                            </dummy>
                        </Enter>
                    </Card.Header>
                </Card>
            </div>
        );
        return view;
    }
}