import { t } from 'i18next';
import { h } from 'hyperapp';
import { allRoutes } from '@app/core';
import { Link } from '@app/elements';

export const view = (state, actions) => (props, children) => (
    <div key='QuatreCentQuatre'>
        <div class='btzQuatreCentQuatre'>
            <div class='btzQuatreCentQuatre-btzWrapper'>
                <p class='btzTitle btzXl' style='font-size: 180px !important;line-height: 180px;'>• 404 •</p>
                <p class='btzTitle btzXl'>{t('La page que vous demandez n‘existe pas.')}</p>

                <Link to={allRoutes['index'].pathname}>{t('> Revenir au site <')}</Link>
            </div>
        </div>
    </div>
);