import { t } from 'i18next';

export const pathologiesTypes = [
    {'label': 'Cancer Col Utérus', 'i18nlabel': t('Cancer Col Utérus'), 'key': 'CANCERCERVICAL'},
    {'label': 'Cancer Colon', 'i18nlabel': t('Cancer Colon'), 'key': 'CANCERCOLON'},
    {'label': 'Cancer Corps Utérin', 'i18nlabel': t('Cancer Corps Utérin'), 'key': 'CANCERUTERINEBODY'},
    {'label': 'Cancer Estomac', 'i18nlabel': t('Cancer Estomac'), 'key': 'CANCERSTOMACH'},
    {'label': 'Cancer Foie', 'i18nlabel': t('Cancer Foie'), 'key': 'CANCERLIVER'},
    {'label': 'Cancer Oesophage', 'i18nlabel': t('Cancer Oesophage'), 'key': 'CANCERESOPHAGEAL'},
    {'label': 'Cancer Ovaire', 'i18nlabel': t('Cancer Ovaire'), 'key': 'CANCEROVARIAN'},
    {'label': 'Cancer Pancréas', 'i18nlabel': t('Cancer Pancréas'), 'key': 'CANCERPANCREATIC'},
    {'label': 'Cancer Poumon', 'i18nlabel': t('Cancer Poumon'), 'key': 'CANCERLUNG'},
    {'label': 'Cancer Prostate', 'i18nlabel': t('Cancer Prostate'), 'key': 'CANCERPROSTATE'},
    {'label': 'Cancer Rectum', 'i18nlabel': t('Cancer Rectum'), 'key': 'CANCERRECTUM'},
    {'label': 'Cancer Rein', 'i18nlabel': t('Cancer Rein'), 'key': 'CANCERKIDNEY'},
    {'label': 'Cancer Sein', 'i18nlabel': t('Cancer Sein'), 'key': 'CANCERBREAST'},
    {'label': 'Cancer VADS', 'i18nlabel': t('Cancer VADS'), 'key': 'CANCERUAT'},
    {'label': 'Cancer Voies Biliaires', 'i18nlabel': t('Cancer Voies Biliaires'), 'key': 'CANCERBILEDUCT'},
    {'label': 'Cancer Vagin', 'i18nlabel': t('Cancer Vagin'), 'key': 'CANCERVAGINAL'},
    {'label': 'Cancer Vessie', 'i18nlabel': t('Cancer Vessie'), 'key': 'CANCERBLADDER'},
    {'label': 'Hématologie', 'i18nlabel': t('Hématologie'), 'key': 'HEMATOLOGY'},
    {'label': 'Mélanome', 'i18nlabel': t('Mélanome'), 'key': 'MELANOMA'}
];