export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        var swFound = false
        navigator.serviceWorker
            .getRegistrations()
            .then(function (registrations) {
                for (var registration of registrations) {
                    registration.unregister()
                    swFound = true
                }
                if (swFound) {
                    window.location.reload()
                }
            })
    },
}
