export const state = {
    ovalues: {
        fname: '',
        lname: '',
        birthdate: {
            year: '',
            month: '',
            day: ''
        },
        hospitalId: '',
    },
    patientid: null,
    loggedUser: null,
    hospitals: null,
    refHospital: null,
    doctor: null,
    loaded: false,
    informations: null,
};
