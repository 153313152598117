import { t } from 'i18next'
import { Alert, User } from '@app/api'
import { allRoutes, addCSRFToken, isDefined, getUser, coreFn } from '@app/core'

import * as constants from '@app/constants'

/**
 * @module AlertsListing
 * @description Display listing of alerts, related to activated monitoring modules
 */

export default {
    initAction: function () {
        let AlertsListing = {
            onComponentCreate: (props) => (state, actions) => {
                User.searchTheraflowUser(0, {}, '').then((res) => {
                    if (isDefined(res.data) && res.data.result.length > 0) {
                        let ICMesure = process.env.APP_IC_MODULE || ''
                        actions._setState({ key: 'ICMesure', value: ICMesure })
                        let then = (loggedUser) => {
                            actions._setState({
                                key: 'doctor',
                                value: loggedUser,
                            })
                            let dataTypeCurrent =
                                constants.custo.telesuiviSettings
                                    .filter(
                                        (f) =>
                                            f.crypt !== ICMesure &&
                                            state.ICMesureIgnore.indexOf(
                                                f.crypt
                                            ) === -1
                                    )
                                    .map((m) =>
                                        m.alertValueKey
                                            .filter((f) => f.key !== 'mean')
                                            .map(
                                                (vKey) =>
                                                    m.crypt.toUpperCase() +
                                                    '_' +
                                                    String(
                                                        vKey.crypt
                                                    ).toUpperCase()
                                            )
                                    )
                                    .reduce((a, b) => a.concat(b))
                            let themeCurrent = []
                            if (ICMesure !== '') {
                                themeCurrent = constants.custo.telesuiviSettings
                                    .filter(
                                        (f) =>
                                            f.crypt === ICMesure &&
                                            state.ICMesureIgnore.indexOf(
                                                f.crypt
                                            ) === -1
                                    )
                                    .map((m) =>
                                        m.alertValueKey
                                            .filter((f) => f.key !== 'mean')
                                            .map(
                                                (vKey) =>
                                                    m.crypt.toUpperCase() +
                                                    '_' +
                                                    String(
                                                        vKey.crypt
                                                    ).toUpperCase()
                                            )
                                    )
                                    .reduce((a, b) => a.concat(b))
                                if (isDefined(themeCurrent[0])) {
                                    themeCurrent = themeCurrent[0].toUpperCase()
                                }
                            } else {
                                actions._setState({
                                    key: 'ICModule',
                                    value: null,
                                })
                            }
                            //
                            actions._setState({
                                key: 'dataTypeCurrent',
                                value: dataTypeCurrent,
                            })
                            actions._setState({
                                key: 'themeCurrent',
                                value: themeCurrent,
                            })
                            actions.getPage()
                        }
                        getUser(then)
                    } else {
                        actions._setState({ key: 'alerts', value: [] })
                        actions._setState({ key: 'noPatients', value: true })
                    }
                })
            },
            /**
             * Redirect when user click on a table row (to telesuivi tab)
             * @function AlertsListing:redirectToPatient
             * @param {string} patientid - Id of patient
             * @param {ymd} date - ymd date of alert
             * @param {string} crypt - crypt of alert
             * @param {string} period - period of alert (week, month)
             */
            redirectToPatient:
                ({ patientid, date, crypt, period }) =>
                (state, actions) => {
                    window.location.href = addCSRFToken(
                        allRoutes['private.patient.edit'].pathname.replace(
                            ':id',
                            patientid
                        ) +
                            '?sp=telesuivi&date=' +
                            date +
                            '&mesure=' +
                            crypt +
                            '&period=' +
                            period
                    )
                },
            /**
             * Retrieve form input on the fly, then call [getPage]{@link module:AlertsListing~AlertsListing:getPage})
             * @param {input} input - Contain [isvalid], [name], [id] and [values] fields
             * @function AlertsListing:retrieveInputAction
             */
            retrieveInputAction: (input) => (state, actions) => {
                const { values, id } = input
                let patientid = id.split('-')[1]
                let alertid = id.split('-')[0]
                Alert.updateUserAlert(patientid, alertid, {
                    status: values,
                }).then(() => {
                    actions.getPage()
                    var event = new CustomEvent('updateInfos')
                    window.dispatchEvent(event)
                })
            },
            /**
             * Parse a value to get a human readable string
             * @param {object} el - object to update
             * @param {object} item - Item, containing value to parse
             * @function AlertsListing:getReadableValue
             */
            getReadableValue:
                ({ el, item }) =>
                (state, actions) => {
                    let parsedValue = ''
                    if (item.value === 'NODATA') {
                        parsedValue = 'N.C'
                    } else {
                        parsedValue = item.value
                    }
                    el.innerHTML = parsedValue
                },
            /**
             * Parse a triggerType to get a human readable string
             * @param {object} el - object to update
             * @param {object} item - Item, containing triggerType to parse
             * @function AlertsListing:getReadableTriggerType
             */
            getReadableTriggerType:
                ({ el, item }) =>
                (state, actions) => {
                    let parsedValue = ''
                    if (item.triggerType === 'NODATA') {
                        parsedValue = t('1 mesure est manquante')
                    } else if (item.triggerType === 'INCREASE') {
                        parsedValue = t('1 mesure est en augmentation')
                        if (isDefined(item.alert)) {
                            if (+item.alert.value1 === 2) {
                                if (+item.alert.interval === 2) {
                                    parsedValue = t('+2kg en 2 jours')
                                } else if (+item.alert.interval === 6) {
                                    parsedValue = t('+2kg en 6 jours')
                                }
                            }
                        }
                    } else {
                        parsedValue = item.triggerType
                    }
                    el.innerHTML = parsedValue
                },
            /**
             * Parse a triggerType to get a human readable string
             * @param {object} el - object to update
             * @param {object} alert - Alert, containing dataType to parse
             * @function AlertsListing:getCellType
             */
            getCellType:
                ({ el, alert }) =>
                (state, actions) => {
                    let cellType = constants.custo.telesuiviSettings.filter(
                        (f) =>
                            f.alertValueKey.filter(
                                (fKey) =>
                                    f.crypt.toUpperCase() +
                                        '_' +
                                        String(fKey.key).toUpperCase() ===
                                    alert.dataType
                            )[0]
                    )
                    if (isDefined(cellType) && isDefined(cellType[0])) {
                        cellType = cellType[0].value
                    }
                    el.innerHTML = cellType
                },
            /**
             * Sort listing, then call [getPage]{@link module:AlertsListing~AlertsListing:getPage})
             * @function AlertsListing:onSortList
             * @param {string} sortby - The sort key
             */
            onSortList: (sortby) => (state, actions) => {
                let sorting = state.sort,
                    newSorting = { sort: {} }
                if (!isDefined(sorting.sort)) {
                    sorting.sort = {}
                }
                if (isDefined(sorting.sort[sortby])) {
                    if (sorting.sort[sortby] === 1) {
                        newSorting.sort[sortby] = -1
                    } else {
                        newSorting.sort[sortby] = 1
                    }
                } else {
                    newSorting.sort[sortby] = 1
                }
                actions._setState({ key: 'sort', value: newSorting })
                actions.getPage()
            },
            /**
             * Update listing page following current page variable
             * @function AlertsListing:getPage
             */
            getPage: () => (state, actions) => {
                let totalAlerts = null,
                    alerts = []
                if (state.ICModule) {
                    let dataSpecific = {
                        dataType: [state.themeCurrent],
                        sort: state.sort.sort,
                    }
                    Alert.getAlertAll(state.page, dataSpecific).then(
                        (icalerts) => {
                            alerts = icalerts.data
                            if (isDefined(alerts)) {
                                if (isDefined(alerts.infos)) {
                                    totalAlerts = alerts.infos.count
                                }
                                if (isDefined(alerts.result)) {
                                    alerts = alerts.result
                                }
                            }
                            actions._setState({
                                key: 'totalAlerts',
                                value: totalAlerts,
                            })
                            actions._setState({ key: 'alerts', value: alerts })
                        }
                    )
                } else if (isDefined(state.REFApi)) {
                    let dataRef = { sort: state.sort.sort }
                    if (isDefined(state.REFStudy)) {
                        dataRef.name = [state.REFStudy]
                    }
                    state.REFApi(state.page, dataRef).then((refalerts) => {
                        alerts = refalerts.data
                        if (isDefined(alerts)) {
                            if (isDefined(alerts.infos)) {
                                totalAlerts = alerts.infos.count
                            }
                            if (isDefined(alerts.result)) {
                                alerts = alerts.result
                            }
                        }
                        actions._setState({
                            key: 'totalAlerts',
                            value: totalAlerts,
                        })
                        actions._setState({ key: 'alerts', value: alerts })
                    })
                } else {
                    let dataAll = { dataType: state.dataTypeCurrent } //, sort: state.sort.sort};
                    Alert.getAlertAll(state.page, dataAll).then((allalerts) => {
                        alerts = allalerts.data
                        if (isDefined(alerts)) {
                            if (isDefined(alerts.infos)) {
                                totalAlerts = alerts.infos.count
                            }
                            if (isDefined(alerts.result)) {
                                alerts = alerts.result
                            }
                        }
                        actions._setState({
                            key: 'totalAlerts',
                            value: totalAlerts,
                        })
                        actions._setState({ key: 'alerts', value: alerts })
                    })
                }
            },
            /**
             * Parse a patient lname and fname to get a human readable string
             * @param {object} el - object to update
             * @param {object} patient - Patient, containing lname and fname to parse
             * @function AlertsListing:getPatientName
             */
            getPatientName:
                ({ el, patient }) =>
                (state, actions) => {
                    if (isDefined(patient.user)) {
                        patient = patient.user
                    }
                    let patientLName =
                        patient.lname || patient.name.split(' ')[0]
                    let patientFName =
                        patient.fname || patient.name.split(' ')[1]
                    let patientName =
                        patientLName.substring(0, 1).toUpperCase() +
                        patientFName.substring(0, 1).toUpperCase()
                    el.innerHTML = patientName
                },
            /**
             * Retrieve form input on the fly from table header survey name filter, then call [getPage]{@link module:AlertsListing~AlertsListing:getPage})
             * @param {input} input - Contain [isvalid], [name], [id] and [values] fields
             * @function AlertsListing[Oncoflow]:retrieveAlertSort
             */
            retrieveAlertSort: (input) => (state, actions) => {
                const { values } = input
                let newSelectedAlert = state.selectedAlert
                if (newSelectedAlert.indexOf(values) > -1) {
                    newSelectedAlert.splice(newSelectedAlert.indexOf(values), 1)
                } else {
                    newSelectedAlert.push(values)
                }
                actions._setState({
                    key: 'selectedAlert',
                    value: newSelectedAlert,
                })
                if (newSelectedAlert.length > 0) {
                    actions.getPage()
                }
            },
            /**
             * Set current page, then call [getPage]{@link module:AlertsListing~AlertsListing:getPage})
             * @function AlertsListing:targetPage
             */
            targetPage: (page) => (state, actions) => {
                actions._setState({ key: 'page', value: page })
                actions.getPage(page)
            },
            /**
             * Increments current page, then call [getPage]{@link module:AlertsListing~AlertsListing:getPage})
             * @function AlertsListing:nextPage
             */
            nextPage: () => (state, actions) => {
                actions._setState({ key: 'page', value: state.page + 1 })
                actions.getPage()
            },
            /**
             * Decrement current page, then call [getPage]{@link module:AlertsListing~AlertsListing:getPage})
             * @function AlertsListing:prevPage
             */
            prevPage: () => (state, actions) => {
                actions._setState({ key: 'page', value: state.page - 1 })
                actions.getPage()
            },
        }
        AlertsListing = { ...coreFn, ...AlertsListing }
        return AlertsListing
    },
}
