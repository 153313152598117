export const state = {
    ovalues: {
        fname: '',
        lname: '',
        birthdate: {
            year: '',
            month: '',
            day: ''
        },
        hospitalId: '',
        etapes: false,
    },
    doctorRole: null,
    dynamicParam: {date: null, mesure: null, period: null},
    patientid: null,
    studies: [],
    referenceCustomer: null,
    referenceHealTeam: [],
    referenceCoachTeam: [],
    referenceHospital: null,
    hospitals: null,
    editedPatient: null,
    loaded: false,
    currentPage: 'profil',
    doctor: null,
    ICMesure: '',
    modalEditing: '',
    modalDisplayed: false,
    modalHangup: false,
    twilioUniqName: '',
    twilioRoom: null,
    visioCallActive: false,
    stream: null,
    twilioDoctorTrackVideo: null,
    twilioDoctorTrackAudio: null,
    atLeastOneModification: false,
    trgid: null,
    strgid: null,
};
