import { Admin, Hospital } from '@app/api';
import { isDefined, enableTheseButton, desableTheseButton, getUser, coreFn } from '@app/core';

/**
 * @module AccountEdit
 * @description Doctor account index, serve different admin account pages.
 */

export default {
    initAction: function() {
        let AccountEdit = {
            onComponentCreate: (props) => (state, actions) => {
                let then = (doctor) => {
                    actions._setState({key: 'loggedUser', value: doctor.customer});
                    Admin.getInfo().then((infos) => {
                        actions._setState({key: 'informations', value: infos.data});
                        Hospital.getHospitals().then((res) => {
                            if (isDefined(res.data) && Array.isArray(res.data)) {
                                actions._setState({key: 'hospitals', value: res.data});
                                let refHosp = res.data.filter((f) => f.name === doctor.customer)[0];
                                actions._setState({key: 'refHospital', value: refHosp});
                            }
                            //
                            actions._setState({key: 'doctor', value: doctor});
                            actions._setState({key: 'loaded', value: true});
                        });
                    })
                };
                getUser(then);
            },
            /**
             * Get and set doctor informations.
             * @function AccountEdit:getDoctor
             * @param {boolean} reload - If true, reload page (used for language change).
             */
            getDoctor: (reload = false) => (state, actions) => {
                let then = (doctor) => {
                    actions._setState({key: 'doctor', value: doctor});
                    if (reload) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 400);
                    }
                };
                getUser(then, true);
            },
        }
        AccountEdit = {...coreFn, ...AccountEdit};
        return AccountEdit;
    }
}