import { Component } from '@app/utils'
import { h } from 'hyperapp'
import { t } from 'i18next'

import {
    isDefined,
    getYMDfromDateObj,
    createUTCDate,
    getDiffDate,
    getDateObjFromYMD,
    createDateComplientSafari,
    isValidDate,
    isAuthorizedRole,
} from '@app/core'
import { Form, FormInput } from '@app/elements'
import { default as isLength } from 'validator/lib/isLength'
import datepicker from 'js-datepicker'
import './index.scss'

import icoCalendar from '@app/img/ico/ico-calendar.png'
import icoCalendarx2 from '@app/img/ico/ico-calendar@2x.png'

const Calendar = Component(
    {
        id: '',
        today: null,
        withInput: true,
        defaultHidden: true,
        visible: false,
        withUtc: true,
        calendarDate: {
            day: '',
            month: '',
            year: '',
        },
        btzCalendar: null,
        minDate: false,
        maxDate: false,
        deletetable: false,
        loaded: true,
        noFuture: false,
        noPast: false,
        onInputCb: null,
        onBlurCb: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            let today = new Date()
            let day = today.getDate()
            let month = +today.getMonth() + 1
            let year = today.getFullYear()
            if (+day < 10) {
                day = '0' + day
            }
            if (+month < 10) {
                month = '0' + month
            }
            state.today = year + month + day
            state.id = props.name
            //
            if (isDefined(props.deletetable)) {
                actions.setDeletable(props.deletetable)
            }
            if (isDefined(props.noFuture)) {
                actions.setNoFuture(props.noFuture)
            }
            if (isDefined(props.noPast)) {
                actions.setNoPast(props.noPast)
            }
            if (isDefined(props.oninput)) {
                actions.setOnInputCb(props.oninput)
            }
            if (isDefined(props.onblur)) {
                actions.setOnBlurCb(props.onblur)
            }
            if (isDefined(props.withUtc)) {
                actions.setWithUtc(props.withUtc)
            }
            actions.onComponentUpdate(props)
            //
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.min) {
                actions.setMinDate(props.min)
            }
            if (props.max) {
                actions.setMaxDate(props.max)
            }
            //
            if (isDefined(props.calendarDate)) {
                actions.setCalendarDate(
                    isDefined(props.calendarDate, true, true)
                        ? props.calendarDate
                        : { day: '', month: '', year: '' }
                )
            }
            actions.updateCalendar(props.calendarDate)
        },
        resetDate: (props) => (state, actions) => {
            let resetValue = { day: '', month: '', year: '' }
            actions.setCalendarDate(resetValue)
            if (state.onInputCb) {
                actions.onInputCb({
                    name: state.id,
                    values: resetValue,
                    isvalid: true,
                })
            }
        },
        createCalendar:
            ({ el, props }) =>
            (state, actions) => {
                if (
                    !isAuthorizedRole() &&
                    (!isDefined(props.noAuthRequired) ||
                        (isDefined(props.noAuthRequired) &&
                            props.noAuthRequired === false))
                ) {
                    return false
                }
                //
                let startDate = new Date()
                let calendarDate = props.calendarDate
                if (isDefined(calendarDate)) {
                    let newDate = createDateComplientSafari(
                        getDateObjFromYMD(calendarDate).date
                    )
                    if (newDate instanceof Date && !isNaN(newDate)) {
                        if (
                            isDefined(calendarDate) &&
                            calendarDate.day !== '' &&
                            calendarDate.month !== '' &&
                            calendarDate.year !== ''
                        ) {
                            startDate = createDateComplientSafari(
                                getDateObjFromYMD(calendarDate).date
                            )
                        }
                    }
                }
                //
                let btzCalendar = datepicker(el, {
                    startDay: 1,
                    startDate: startDate,
                    dateSelected: startDate,
                    showAllDates: true,
                    overlayPlaceholder: t('Année souhaité ...'),
                    overlayButton: t('Afficher'),
                    customDays: [
                        t('Dim'),
                        t('Lun'),
                        t('Mar'),
                        t('Mer'),
                        t('Jeu'),
                        t('Ven'),
                        t('Sam'),
                    ],
                    customMonths: [
                        t('Janvier'),
                        t('Février'),
                        t('Mars'),
                        t('Avril'),
                        t('Mai'),
                        t('Juin'),
                        t('Juillet'),
                        t('Aout'),
                        t('Septembre'),
                        t('Octobre'),
                        t('Novembre'),
                        t('Décembre'),
                    ],
                    customOverlayMonths: [
                        t('Jan'),
                        t('Fév'),
                        t('Mars'),
                        t('Avr'),
                        t('Mai'),
                        t('Juin'),
                        t('Juil'),
                        t('Aout'),
                        t('Sept'),
                        t('Oct'),
                        t('Nov'),
                        t('Déc'),
                    ],
                    onSelect: (instance) => {
                        if (isDefined(instance.dateSelected)) {
                            // props.oninput({name: props.name, values: getYMDfromDateObj(createUTCDate(instance.dateSelected.toUTCString())), isvalid: true, id: props.name});
                            if (state.withUtc) {
                                actions.onInputCb({
                                    name: props.name,
                                    values: getYMDfromDateObj(
                                        createUTCDate(
                                            instance.dateSelected.toUTCString()
                                        )
                                    ),
                                    isvalid: true,
                                    id: props.name,
                                })
                            } else {
                                actions.onInputCb({
                                    name: props.name,
                                    values: getYMDfromDateObj(
                                        createDateComplientSafari(
                                            instance.dateSelected.toString(),
                                            false
                                        )
                                    ),
                                    isvalid: true,
                                    id: props.name,
                                })
                            }
                        }
                    },
                    onMonthChange: (instance) => {
                        //
                    },
                })
                actions.setStartDate(startDate)
                actions.setCalendar(btzCalendar)
                actions.updateCalendar()
            },
        updateCalendar:
            (newValue = null) =>
            (state, actions) => {
                let btzCalendar = state.btzCalendar,
                    refValue = state.startDate
                if (isDefined(newValue)) {
                    refValue = createDateComplientSafari(
                        getDateObjFromYMD(newValue).date
                    )
                }
                if (!isDefined(btzCalendar)) {
                    return false
                }
                //
                let maxDate = null
                if (state.noFuture) {
                    maxDate = createDateComplientSafari()
                } else if (
                    isDefined(state.maxDate) &&
                    state.maxDate !== false
                ) {
                    maxDate = createDateComplientSafari(
                        getDateObjFromYMD(state.maxDate).date
                    )
                }
                let minDate = null
                if (
                    state.noPast &&
                    (!isDefined(state.minDate) || !state.minDate)
                ) {
                    minDate = createDateComplientSafari()
                } else if (
                    isDefined(state.minDate) &&
                    state.minDate !== false
                ) {
                    minDate = createDateComplientSafari(
                        getDateObjFromYMD(state.minDate).date
                    )
                }
                if (
                    isDefined(refValue) &&
                    refValue !== false &&
                    isDefined(maxDate) &&
                    maxDate !== false
                ) {
                    if (getDiffDate(refValue, maxDate) < 0) {
                        maxDate = refValue
                    }
                }
                //
                if (state.calendarDate.day !== '') {
                    if (isDefined(minDate) && isValidDate(minDate)) {
                        if (getDiffDate(refValue, minDate) > 0) {
                            btzCalendar.setDate(minDate.toDate(), true)
                        }
                        btzCalendar.setMin(minDate, true)
                    }
                    if (isDefined(maxDate) && isValidDate(maxDate)) {
                        if (getDiffDate(refValue, maxDate) < 0) {
                            btzCalendar.setDate(maxDate.toDate(), true)
                        }
                        btzCalendar.setMax(maxDate, true)
                    }
                }
            },
        setCalendarDate: (newState) => (state) => ({
            calendarDate: newState,
        }),
        toggleCalendarStatus: (e) => (state, actions) => {
            if (!isDefined(state.btzCalendar)) {
                return false
            }
            //
            e.preventDefault()
            e.stopPropagation()
            const isHidden =
                state.btzCalendar.calendarContainer.classList.contains(
                    'qs-hidden'
                )
            state.btzCalendar[isHidden ? 'show' : 'hide']()
            return false
        },
        onInputCb: (input) => (state, actions) => {
            let { isvalid, values, id, name } = input
            if (isDefined(values)) {
                let newDate = null
                if (values.day !== '') {
                    newDate = createDateComplientSafari(
                        getDateObjFromYMD(values).date,
                        true
                    )
                }
                if (
                    isvalid &&
                    isDefined(newDate) &&
                    !isNaN(newDate.valueOf())
                ) {
                    let maxDate = false
                    if (state.noFuture) {
                        maxDate = new Date()
                    } else if (state.maxDate) {
                        maxDate = new Date(
                            getDateObjFromYMD(state.maxDate).date
                        )
                    }
                    let minDate = false
                    if (state.noPast) {
                        minDate = new Date()
                    } else if (state.minDate) {
                        minDate = new Date(
                            getDateObjFromYMD(state.minDate).date
                        )
                    }
                    if (isDefined(newDate) && newDate !== false) {
                        if (isDefined(maxDate) && maxDate !== false) {
                            if (getDiffDate(newDate, maxDate) < 0) {
                                newDate = null
                                isvalid = false
                            }
                        } else if (isDefined(minDate) && minDate !== false) {
                            if (getDiffDate(newDate, minDate) > 0) {
                                newDate = null
                                isvalid = false
                            }
                        }
                    }
                    if (
                        isvalid &&
                        isDefined(newDate) &&
                        isDefined(state.btzCalendar)
                    ) {
                        state.btzCalendar.setDate(newDate.toDate(), true)
                    }
                }
            }
            if (state.onInputCb) {
                state.onInputCb({
                    isvalid: isvalid,
                    values: values,
                    id: id,
                    name: name,
                })
            }
        },
        onBlurCb: (e) => (state, actions) => {
            if (isDefined(state.onBlurCb)) {
                state.onBlurCb(e)
            }
        },
        switchCalendar: (newState) => (state) => ({
            visible: newState,
        }),
        setDeletable: (newState) => (state) => ({
            deletetable: newState,
        }),
        setCalendar: (newState) => (state) => ({
            btzCalendar: newState,
        }),
        setNoFuture: (newState) => (state) => ({
            noFuture: newState,
        }),
        setNoPast: (newState) => (state) => ({
            noPast: newState,
        }),
        setStartDate: (newState) => (state) => ({
            startDate: newState,
        }),
        setMinDate: (newState) => (state) => ({
            minDate: newState,
        }),
        setMaxDate: (newState) => (state) => ({
            maxDate: newState,
        }),
        setOnInputCb: (newState) => (state) => ({
            onInputCb: newState,
        }),
        setOnBlurCb: (newState) => (state) => ({
            onBlurCb: newState,
        }),
        setWithUtc: (newState) => (state) => ({
            withUtc: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div key={state.visible} class={'btzCalendar btzCalendar-' + state.id}>
            {state.withInput && (
                <Form
                    onsubmit={(event) => event.preventDefault()}
                    style={{ position: 'relative' }}
                >
                    <button
                        type="submit"
                        disabled
                        style="display: none"
                        aria-hidden="true"
                    ></button>
                    {((isDefined(props.title) && props.title !== '') ||
                        props.optional === true) && (
                        <p class="btzForm-btzLabel">
                            {props.title}
                            <font>
                                {props.optional
                                    ? ' (' + t('facultatif') + ')'
                                    : ''}
                            </font>
                        </p>
                    )}
                    <Form.Group>
                        <FormInput
                            placeholder={{
                                day: '01',
                                month: '01',
                                year: '1990',
                            }}
                            key={'input-date-' + props.name}
                            type="date"
                            id={props.name}
                            name={props.name}
                            valueType="date"
                            value={state.calendarDate}
                            validation={true}
                            dynFocus={true}
                            isInteger={true}
                            readonly={props.readonly}
                            isValid={props.isValid}
                            optional={props.optional}
                            loaded={state.loaded}
                            oninputcb={actions.onInputCb}
                            onblurcb={actions.onBlurCb}
                            noFuture={props.noFuture}
                            noPast={props.noPast}
                            minDate={props.minDate}
                            maxDate={props.maxDate}
                            readonlyMessage={props.readonlyMessage}
                            formSubmittedOnce={props.formSubmittedOnce}
                            errorMessage={props.errorMessage}
                            isTyping={
                                isDefined(props.isTyping)
                                    ? props.isTyping
                                    : false
                            }
                            required
                        />
                        {(!props.readonly || props.readonly === undefined) && (
                            <img
                                tabindex="0"
                                class="btzCalendarImage"
                                src={icoCalendar}
                                srcset={`${icoCalendarx2} 2x`}
                                alt=""
                                onclick={(e) => {
                                    actions.toggleCalendarStatus(e)
                                }}
                            />
                        )}
                        {state.deletetable &&
                            (state.calendarDate.day !== '' ||
                                state.calendarDate.month !== '' ||
                                state.calendarDate.year !== '') && (
                                <div
                                    class="btzCalendar-btzCalendarReset"
                                    onclick={(e) => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                        actions.resetDate(props)
                                    }}
                                >
                                    {'x'}
                                </div>
                            )}
                        {(!props.readonly || props.readonly === undefined) && (
                            <div
                                id={'btzCalendar-' + state.id}
                                oncreate={(el) =>
                                    actions.createCalendar({
                                        el: el,
                                        props: props,
                                    })
                                }
                            ></div>
                        )}
                    </Form.Group>
                </Form>
            )}
        </div>
    ),
    'calendar'
)

export { Calendar }
