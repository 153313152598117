import { t } from 'i18next';
import { h } from 'hyperapp';
import { Card, Button, Form, FormInput, Tile, Enter } from '@app/elements';
import * as constants from '@app/constants';

export default {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div class='login-central'>
                <Card classes={'btzPageCard btzBackgroundCover'} kind={'datas'}>
                    <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                        <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
                            {state.loginStep === 'login' &&
                                <dummy>
                                    <div class='thfProgressBarWrapper'><div class='thfProgressBar' style='width: 40%'></div></div>
                                    <Tile>
                                        <Tile.Content>
                                            <div class='loginHeader'>
                                                <p class='btzTitle btzXl'>{t('Connectez-vous.')}</p>
                                                <p class='btzSubTitle'>{t('Pour vous connecter à votre compte, renseignez votre adresse email ainsi que votre mot de passe.')}</p>
                                            </div>
                                        </Tile.Content>
                                    </Tile>

                                    <Form onsubmit={event => event.preventDefault()} autocomplete={'on'}>
                                        <button type='submit' disabled style='display: none' aria-hidden='true'></button>
                                        <Form.Group>
                                            <p class='btzForm-btzLabel'>{t('Votre adresse email')}</p>
                                            <FormInput
                                                placeholder={t('l\'adresse email habituelle')}
                                                key='input-email'
                                                type='email'
                                                id='email'
                                                name='email'
                                                value={state.values.email}
                                                validation={true}
                                                autocomplete={'email'}
                                                oninputcb={actions._retrieveInput}
                                                onblurcb={actions.retrieveErrorMessage}
                                                formSubmittedOnce={state.formSubmittedOnce}
                                                errorMessage={state.errorMessages.email}
                                                isTyping={state.isTyping.email}
                                                initialFocus={true}
                                                required
                                            />
                                            <p class='btzForm-btzLabel'>{t('Votre mot de passe')}</p>
                                            <FormInput
                                                placeholder={t('le mot de passe habituel')}
                                                key='input-password'
                                                type='password'
                                                id='password'
                                                name='password'
                                                valueType='password'
                                                value={state.values.password}
                                                validation={true}
                                                isValid={(state.formSubmittedOnce ? state.isValid.password : 'is-default')}
                                                autocomplete={'current-password'}
                                                showPasswordButton={true}
                                                oninputcb={actions._retrieveInput}
                                                onblurcb={actions.retrieveErrorMessage}
                                                formSubmittedOnce={state.formSubmittedOnce}
                                                errorMessage={state.errorMessages.password}
                                                isTyping={state.isTyping.password}
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group classes='btzForm-btzFooter'>
                                            <Button primary loading={(state.formIsSubmitting)} active={state.isValid.email && state.isValid.password} onclick={actions.onSubmit}>
                                                {t('Étape suivante')}
                                            </Button>
                                        </Form.Group>

                                        <div class='' style='text-align: center;'>
                                            <p class='ovr-lightgrey-colored' style='white-space: nowrap;'>{t('Vous avez oublié votre mot de passe ?')}<a class='btn-link' style='display: inline-block;margin-left: 5px;' href={'#'} onclick={actions.gotoResetPassword}>{t('réinitialiser')}</a></p>
                                        </div>
                                    </Form>
                                </dummy>
                            }
                            {state.loginStep === 'code' &&
                                <dummy>
                                    <div class='thfProgressBarWrapper'><div class='thfProgressBar' style='width: 95%'></div></div>
                                    <Tile>
                                        <Tile.Content>
                                            {(constants.custo.CUSTOMERS[props.customer].modules.codeSendBy === 'sms') ?
                                                <div class='loginHeader'>
                                                    <p class='btzTitle btzXl'>{t('Vous identifier.')}</p>
                                                    <p class='btzSubTitle'>{t('Merci de saisir le code à 6 chiffres qui a été envoyé par SMS.')}</p>
                                                    <p class='thfInfoBlock'>{state.values.phone[0] + '.xx.xx.' + state.values.phone[3] + '.' + state.values.phone[4]}</p>
                                                </div>
                                                :
                                                <div class='loginHeader'>
                                                    <p class='btzTitle btzXl'>{t('Vous identifier.')}</p>
                                                    <p class='btzSubTitle'>{t('Merci de saisir le code à 6 chiffres qui a été envoyé par email.')}</p>
                                                    <p class='thfInfoBlock'>{state.values.email}</p>
                                                </div>
                                            }
                                        </Tile.Content>
                                    </Tile>

                                    <Form onsubmit={event => { event.preventDefault(); event.stopPropagation(); }}>
                                        <button type='submit' disabled style='display: none' aria-hidden='true'></button>
                                        <Form.Group>
                                            <p class='btzForm-btzLabel'>{t('Code à 6 chiffres')}</p>
                                            <FormInput
                                                placeholder={['-', '-', '-', '-', '-', '-']}
                                                value={state.values.code}
                                                type='code'
                                                id='code'
                                                name='code'
                                                valueType='code'
                                                validation={true}
                                                isValid={(state.formSubmittedOnce ? state.isValid.code : 'is-default')}
                                                isInteger={true}
                                                dynFocus={true}
                                                oninputcb={actions._retrieveInput}
                                                onblurcb={actions.retrieveErrorMessage}
                                                formSubmittedOnce={state.formSubmittedOnce}
                                                errorMessage={state.errorMessages.code}
                                                isTyping={state.isTyping.code}
                                                initialFocus={true}
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group classes='btzForm-btzFooter'>
                                            <Button flat anchorLeft loading={(state.formIsSubmitting)} width={'35%'} cancel onclick={() => { actions._setState({key: 'loginStep', value: 'login'}); }}>
                                                {t('Retour')}
                                            </Button>
                                            <Button flat anchorRight loading={(state.formIsSubmitting)} width={'60%'} active={state.isValid.code} onclick={actions.onSubmit}>
                                                {t('Se connecter')}
                                            </Button>
                                        </Form.Group>

                                        <div class='' style='text-align: center;'>
                                            <p class='ovr-lightgrey-colored' style='white-space: nowrap;'>{t('Vous n\'avez pas reçu le code ?')}<a class='btn-link' style='display: inline-block;margin-left: 5px;' href={'#'} onclick={actions.createSessionAgain}>{t('Me renvoyer le code')}</a></p>
                                        </div>
                                    </Form>
                                </dummy>
                            }
                        </Enter>
                    </Card.Header>
                </Card>
            </div>
        );
        return view;
    }
}