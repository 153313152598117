import { h } from 'hyperapp';
import { allRoutes } from '@app/core';
import { Card, Enter } from '@app/elements';
import * as constants from '@app/constants';

import PublicLayout from '@app/layouts/register/layout';
import ViewMentionResilience from '@app/modules/Mentions/views/subview/subview-resilience';

import icoCloseGrey from '@app/img/ico/ico-close-grey.png';
import icoCloseGreyx2 from '@app/img/ico/ico-close-grey@2x.png';

export default {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <PublicLayout page={'mentionspublic'} customer={props.customer}>
                <div class='mentions-central' style={{ 'background-color': '#f8fafb' }}>
                    <Card classes={'btzPageCard btzBackgroundCover'} kind={'datas'} style={{ 'padding-bottom': '30px' }}>
                        <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                            <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
                                <div class='btzModal-btzCloseModal' style='' onclick={() => { window.location.href = allRoutes['index'].pathname; }}><img class='thfBackButton' src={icoCloseGrey} srcset={`${icoCloseGreyx2} 2x`} alt='' /></div>
                                <ViewMentionResilience {...state}></ViewMentionResilience>
                            </Enter>
                        </Card.Header>
                    </Card>
                </div>
            </PublicLayout>
        );
        return view;
    }
}