import * as constants from '@app/constants';
export const state = {
    alerts: null,
    info: null,
    icalerts: null,
    page: 0,
    bypage: 10,
    dataTypeCurrent: [],
    REFStudy: null,
    REFApi: null,
    ICMesure: 'CryptedWeight',
    ICMesureIgnore: ['Observance'],
    ICModule: false,
    toTreatAlert: true,
    noPatients: false,
    doctor: null,
    selectedSurvey: constants.custo.configureSurvey.map((config) => config.key),
    selectedAlert: [],
    sort: {sort: {date: -1}},
    totalAlerts: null,
    isMomentListing: true,
};