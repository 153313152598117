//@ts-nocheck
import { h } from 'hyperapp'
import { Component } from '@app/utils'
import moment from 'moment'
import * as constants from '@app/constants'

import './index.scss'

import {
    isFloat,
    isInteger,
    createDateComplientSafari,
    createUTCDate,
    getReadableDate,
} from '@app/core'
import {
    isDefined,
    getYMDfromDateObj,
    getDateObjFromYMD,
    getDaysInWeek,
    getDaysInMonth,
} from '@app/core'

const DataViz = Component(
    {
        test_FAKE_VALUE: null, // max [int] to get random value between 0 and <test_FAKE_VALUE> for each chart (set to null to desable)
        updateApi: null,
        updateApiDashboard: null,
        updateApiAlert: null,
        updateApiUserAlert: null,
        patientId: null,
        mesureType: '',
        mesureUnit: '',
        mesureLabel: '',
        mesureChart: 'circle',
        mesureValue: 'value',
        mesureOffValue: [],
        alertValue: { key: 'value', chart: 'bar' },
        locale: 'fr-FR',
        datavizCreated: false,
        telesuiviKPI: constants.custo.telesuiviKPI,
        datavizs: [['DUMB']],
        ticksNumber: {
            x: {
                week: 7,
                month: null,
                year: 12,
            },
            y: {
                week: 3,
                month: 3,
                year: 3,
            },
            format: {
                week: '%A',
                month: '%A',
                year: '%B',
            },
            substring: {
                week: 100,
                month: 1,
                year: 3,
            },
        },
        noFutur: false,
        svgPadding: 25,
        valueLine: null,
        valueLineRight: null,
        xAxis: null,
        yAxis: null,
        yRightAxis: null,
        padding: { top: 20, right: 0, bottom: 50, left: 20 },
        margin: { top: 20, right: 10, bottom: 70, left: 20 },
        height: 275,
        width: 900,
        barwidth: 0,
        xLabelHeight: 25,
        maxXRange: 0,
        date: '',
        period: null,
        availableWidth: 0,
        availableHeight: 0,
        datepicker: null,
        datepickerLabels: null,
        mesuresListing: null,
        apiurl: null,
        tooltip: null,
        updateFromChildToParent: null,
        missingDays: { week: [], month: [] },
        data: null,
        mesureData: null,
        refBoundingBox: null,
        specificDashApi: ['observance'],
        withOffset: false,
        currentYMin: 0,
        currentYMax: 0,
        thereIsNoData: false,
        defaultLegendColor: '#4D7CFE',
        alerts: [],
        chartMesures: [],
        d3obj: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            import('d3').then((d3) => {
                actions.setD3obj(d3)
                actions.onComponentCreated(props)
            })
        },
        onComponentCreated: (props) => (state, actions) => {
            state.xAxis = state.d3obj.scaleTime()
            state.yAxis = state.d3obj.scaleLinear()
            state.yRightAxis = state.d3obj.scaleLinear()

            if (isDefined(props.patientid)) {
                actions.setPatientId(props.patientid)
            }
            if (isDefined(props.updateFromChildToParent)) {
                actions.setUpdateFromChildToParent(
                    props.updateFromChildToParent
                )
            }

            if (isDefined(props.editingMesure)) {
                if (isDefined(props.editingMesure.mesureunit)) {
                    actions.setMesureUnit(props.editingMesure.mesureunit)
                }
                if (isDefined(props.editingMesure.value)) {
                    actions.setMesureValue(props.editingMesure.value)
                }
                if (isDefined(props.editingMesure.chart)) {
                    actions.setMesureChart(props.editingMesure.chart)
                }
                if (isDefined(props.editingMesure.chartValueKey)) {
                    actions.setMesureChartValueKey(
                        props.editingMesure.chartValueKey
                    )
                }
                if (isDefined(props.editingMesure.chartValueOffKey)) {
                    actions.setChartValueOffKey(
                        props.editingMesure.chartValueOffKey
                    )
                }
                if (isDefined(props.editingMesure.alertValueKey)) {
                    actions.setAlertValue(props.editingMesure.alertValueKey)
                }
            }
            //
            const getLocalesVariables = async () => {
                let storedLocale = localStorage.getItem('locale')
                let userLang = state.locale
                if (isDefined(storedLocale) && storedLocale != false) {
                    userLang = storedLocale.replace('_', '-')
                }
                actions.setLocale(userLang)
                const d3Locale = await import(`locales/d3/${userLang}.json`)
                state.d3obj.timeFormatDefaultLocale(d3Locale)
                actions.getParsedDate()
                actions.createDataVizs()
            }
            getLocalesVariables()
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (isDefined(props.alerts)) {
                actions.setAlerts(props.alerts)
            }
            if (isDefined(props.chartMesures)) {
                actions.setChartMesures(props.chartMesures)
            }
            //
            if (isDefined(props.editingMesure)) {
                if (isDefined(props.editingMesure.mesureunit)) {
                    actions.setMesureUnit(props.editingMesure.mesureunit)
                }
                if (isDefined(props.editingMesure.value)) {
                    actions.setMesureValue(props.editingMesure.value)
                }
                if (isDefined(props.editingMesure.chart)) {
                    actions.setMesureChart(props.editingMesure.chart)
                }
                if (isDefined(props.editingMesure.chartValueKey)) {
                    actions.setMesureChartValueKey(
                        props.editingMesure.chartValueKey
                    )
                }
                if (isDefined(props.editingMesure.chartValueOffKey)) {
                    actions.setChartValueOffKey(
                        props.editingMesure.chartValueOffKey
                    )
                }
                if (isDefined(props.editingMesure.alertValueKey)) {
                    actions.setAlertValue(props.editingMesure.alertValueKey)
                }
            }
            //
            if (isDefined(props.currentDate) && props.currentDate !== false) {
                actions.setDate(props.currentDate)
            }
            if (
                isDefined(props.currentPeriod) &&
                props.currentPeriod !== false
            ) {
                actions.setPeriod(props.currentPeriod)
            }
            if (isDefined(props.themeCurrent)) {
                let themeCurrent = props.themeCurrent.split('_')
                actions.setMesureType(themeCurrent[0])
            }
            actions.getParsedDate()
            //
            for (let dataviz in state.datavizs) {
                actions.createDataViz(state.datavizs[dataviz])
            }
            actions.updateDataVizs()
        },
        createDataVizs: () => (state, actions) => {
            let tmptmt = setTimeout(() => {
                clearTimeout(tmptmt)
                state.datavizCreated = true
                var dtv = document.getElementById(
                    'ov-suivi-wrapper-dataviz-wrapper'
                )
                state.availableWidth = dtv.offsetWidth
                state.availableHeight = dtv.offsetHeight
                //
                state.maxXRange =
                    state.availableWidth -
                    state.padding.left -
                    state.margin.left -
                    state.margin.right -
                    state.padding.right
                state.xAxis.range([
                    state.padding.left + state.margin.left,
                    state.maxXRange - state.padding.left - state.margin.left,
                ])
                state.yAxis.range([
                    state.availableHeight -
                        state.padding.bottom -
                        state.xLabelHeight,
                    0,
                ])
                state.yRightAxis.range([
                    state.availableHeight -
                        state.padding.bottom -
                        state.xLabelHeight,
                    0,
                ])
                state.valueLine = state.d3obj
                    .line()
                    .defined(function (d) {
                        return (
                            d !== null &&
                            d != 'null' &&
                            d.length > 0 &&
                            isDefined(d[0]) &&
                            (isDefined(d[0].mean) || isDefined(d[0].value))
                        )
                    })
                    .x(function (d) {
                        var barWidth = actions.parseW(d[0].datlength)
                        return (
                            +state.xAxis(d[0].date) +
                            (-d[0].datlength / 2 + d[0].i) *
                                (barWidth + d[0].barMargin) -
                            barWidth / 2
                        )
                        // return state.xAxis(d[0].date);
                    })
                    .y(function (d) {
                        return state.yAxis(d[0].mean || d[0].value)
                    })
                state.valueLineRight = state.d3obj
                    .line()
                    .defined(function (d) {
                        return (
                            d !== null &&
                            d != 'null' &&
                            d.length > 0 &&
                            isDefined(d[0]) &&
                            isDefined(d[0].secondChartValue)
                        )
                    })
                    .x(function (d) {
                        var barWidth = actions.parseW(d[0].datlength)
                        return (
                            +state.xAxis(d[0].date) +
                            (-d[0].datlength / 2 + d[0].i) *
                                (barWidth + d[0].barMargin) -
                            barWidth / 2
                        )
                        // return state.xAxis(d[0].date);
                    })
                    .y(function (d) {
                        return state.yRightAxis(
                            d[0].mean || d[0].secondChartValue
                        )
                    })

                if (state.date === '') {
                    let todayDate = getYMDfromDateObj(false)
                    state.date =
                        todayDate.year +
                        '' +
                        todayDate.month +
                        '' +
                        todayDate.day
                }
                state.period = 'week'
                for (let dataviz in state.datavizs) {
                    actions.createDataViz(state.datavizs[dataviz])
                }
            }, 100)
        },

        createDataViz: (names) => (state, actions) => {
            var svg = null
            var dtv = document.querySelectorAll('.ov-suivi-wrapper-dataviz')[0]
            svg = state.d3obj.select(dtv)
            var root = document.querySelectorAll(
                '.root-container-' + names[0].toLowerCase()
            )
            var initialized = false
            if (!root || !isDefined(root) || root.length === 0) {
                root = svg.append('g')
            } else {
                root = state.d3obj.select(root[0])
                initialized = true
            }

            if (state.availableWidth <= 414) {
                state.padding.left = 40
                state.margin.left = 25
            }
            //
            var g = document.querySelectorAll(
                '.root-wrapper-' + names[0].toLowerCase()
            )
            if (!initialized) {
                g = root
                    .append('g')
                    .attr('class', 'root-wrapper-' + names[0].toLowerCase())
            } else {
                g = state.d3obj.select(g[0])
            }
            g.attr('transform', 'translate(0, 0)')
            //
            var x = document.querySelectorAll(
                '.axis.axis--x.axis--x-' + names[0]
            )
            if (!initialized) {
                x = g
                    .append('g')
                    .attr('class', 'axis axis--x axis--x-' + names[0])
            } else {
                x = state.d3obj.select(x[0])
            }
            x.attr(
                'transform',
                'translate(0, ' +
                    (state.availableHeight -
                        state.xLabelHeight -
                        state.padding.bottom) +
                    ')'
            )
            //
            var y = document.querySelectorAll(
                '.axis.axis--y.axis--y-' + names[0]
            )
            if (!initialized) {
                y = g
                    .append('g')
                    .attr('class', 'axis axis--y axis--y-' + names[0])
            } else {
                y = state.d3obj.select(y[0])
            }
            y.attr('transform', 'translate(' + -state.padding.left + ', 0)')
            //
            var yright = document.querySelectorAll(
                '.axis.axis--y.axis--yright-' + names[0]
            )
            if (!initialized) {
                yright = g
                    .append('g')
                    .attr('class', 'axis axis--y axis--yright-' + names[0])
            } else {
                yright = state.d3obj.select(yright[0])
            }
            yright.attr(
                'transform',
                'translate(' +
                    (state.availableWidth -
                        state.padding.left -
                        state.margin.left -
                        state.padding.right -
                        state.margin.right) +
                    ', 0)'
            )
            //
            var subx = document.querySelectorAll(
                '.axis.sub-axis.sub-axis--subx.sub-axis--subx-' + names[0]
            )
            if (!initialized) {
                subx = g
                    .append('g')
                    .attr(
                        'class',
                        'axis sub-axis sub-axis--subx sub-axis--subx-' +
                            names[0]
                    )
            } else {
                subx = state.d3obj.select(subx[0])
            }
            subx.attr(
                'transform',
                'translate(0, ' +
                    (state.availableHeight -
                        state.xLabelHeight -
                        state.padding.bottom +
                        15) +
                    ')'
            )
            //
            if (!initialized) {
                state.tooltip = state.d3obj
                    .select('div#ov-suivi-wrapper-dataviz-wrapper')
                    .append('div')
                    .style('opacity', 0)
                    .attr('class', 'tooltip')
                //
                root.append('g').attr('class', 'rectBg')
                root.append('g').attr('class', 'linesPath')
                root.append('g').attr('class', 'rectCircle')
            }
            //
            root.attr('class', 'root-container-' + names[0].toLowerCase()).attr(
                'transform',
                'translate(' + state.margin.left + ', 0)'
            )
        },

        addMissingDays: (dashData) => (state, actions) => {
            var newDashData = [],
                nextDay = null,
                found = false,
                parsedDate = null
            if (state.period === 'week') {
                for (
                    var i = 0, len = state.missingDays.week.length;
                    i < len;
                    i++
                ) {
                    nextDay = getYMDfromDateObj(state.missingDays.week[i])
                    nextDay =
                        nextDay.year + '' + nextDay.month + '' + nextDay.day
                    found = false
                    for (let j = 0, lenj = dashData.length; j < lenj; j++) {
                        if (dashData[j]) {
                            parsedDate = getYMDfromDateObj(
                                createDateComplientSafari(dashData[j].date.date)
                            )
                            parsedDate =
                                parsedDate.year +
                                '' +
                                parsedDate.month +
                                '' +
                                parsedDate.day
                            if (parsedDate === nextDay) {
                                newDashData.push(dashData[j])
                                found = true
                            }
                        }
                    }
                    if (!found) {
                        newDashData.push({ date: nextDay, value: null })
                    }
                }
                dashData = newDashData
            } else if (state.period === 'month') {
                for (
                    i = 0, len = state.missingDays.month.length;
                    i < len;
                    i++
                ) {
                    nextDay = getYMDfromDateObj(state.missingDays.month[i])
                    nextDay =
                        nextDay.year + '' + nextDay.month + '' + nextDay.day
                    found = false
                    for (let j = 0, lenj = dashData.length; j < lenj; j++) {
                        if (dashData[j]) {
                            parsedDate = getYMDfromDateObj(
                                createDateComplientSafari(dashData[j].date.date)
                            )
                            parsedDate =
                                parsedDate.year +
                                '' +
                                parsedDate.month +
                                '' +
                                parsedDate.day
                            if (parsedDate === nextDay) {
                                newDashData.push(dashData[j])
                                found = true
                            }
                        }
                    }
                    if (!found) {
                        newDashData.push({ date: nextDay, value: null })
                    }
                }
                dashData = newDashData
            }
            return dashData
        },

        updateDataVizsAlerts:
            ({ datavizData, callback }) =>
            (state, actions) => {
                state.thereIsNoData = false
                var thenAlert = function () {
                    datavizData = actions.addMissingDays(datavizData)
                    //
                    if (isDefined(callback) && typeof callback === 'function') {
                        callback(datavizData)
                    }
                }
                thenAlert()
            },

        updateDataVizs: () => (state, actions) => {
            state.xAxis.range([
                state.padding.left + state.margin.left,
                state.maxXRange - state.padding.left - state.margin.left,
            ])
            if (state.period === 'month') {
                state.xAxis.range([0, state.maxXRange])
            }
            var then = function (data) {
                var thenAlert = function (parsedData) {
                    for (let dataviz in state.datavizs) {
                        actions.updateDataViz({
                            names: state.datavizs[dataviz],
                            data: parsedData,
                        })
                    }
                }
                if (state.mesureType.toLowerCase() === 'observance') {
                    thenAlert(data)
                } else {
                    actions.updateDataVizsAlerts({
                        datavizData: data,
                        callback: thenAlert,
                    })
                }
            }
            then(state.chartMesures)
        },

        updateDataViz:
            ({ names, data }) =>
            (state, actions) => {
                var parsedData = {},
                    dashData = []
                var tmpYMin = null,
                    tmpYMax = null
                var tmpYMinSecond = null,
                    tmpYMaxSecond = null
                var pdata = {}
                if (data.interval !== undefined) {
                    data = data.interval
                }
                for (let dat in data) {
                    pdata = {}
                    var dateDat = data[dat]
                    if (!isDefined(dateDat)) {
                        continue
                    }
                    var dateDatDay = dateDat.date
                    var dateDatValue = dateDat.value
                    //
                    var dateDatValueSecondChart = null
                    var dateDatValueSecondChartName = null
                    for (let oAlert in state.alertValue) {
                        if (
                            +oAlert === 1 &&
                            isDefined(dateDat[state.alertValue[oAlert].key])
                        ) {
                            dateDatValueSecondChart =
                                dateDat[state.alertValue[oAlert].key]
                            dateDatValueSecondChartName =
                                state.alertValue[oAlert].key
                        }
                        if (isDefined(state.alertValue[oAlert].key)) {
                            pdata[state.alertValue[oAlert].key] = isDefined(
                                dateDat[
                                    state.alertValue[oAlert].key.toLowerCase()
                                ]
                            )
                                ? dateDat[
                                      state.alertValue[oAlert].key.toLowerCase()
                                  ]
                                : state.alertValue[oAlert][
                                      state.alertValue[oAlert].key
                                  ] // (dateDat[state.alertValue[oAlert].key.toLowerCase()] || state.alertValue[oAlert][state.alertValue[oAlert].key]);
                        }
                    }
                    //
                    var dateDatName = ''
                    if (isDefined(dateDat[state.mesureValue.toLowerCase()])) {
                        dateDatValue = dateDat[state.mesureValue.toLowerCase()]
                        dateDatName = state.mesureValue.toLowerCase()
                    }
                    var dateDatObjective = dateDat.objective
                    var dateDatOffValue = []
                    var dateDatAlerts = dateDat.alerts
                    var dateDatTriggerType = ''
                    if (
                        isDefined(dateDat.alert) &&
                        isDefined(dateDat.alert.triggerType)
                    ) {
                        dateDatTriggerType = dateDat.alert.triggerType
                    }
                    var dateDatValue1 = +dateDat.value1 || ''
                    var dateDatInterval = +dateDat.interval || ''
                    var dateDatId = dateDat.id || ''
                    var dateDatStatus = dateDat.status || ''
                    var dateDatHardDate = ''
                    if (
                        isDefined(state.mesureOffValue) &&
                        state.mesureOffValue.length > 0
                    ) {
                        for (let offv in state.mesureOffValue) {
                            if (
                                isDefined(
                                    dateDat[
                                        state.mesureOffValue[offv.toLowerCase()]
                                    ]
                                )
                            ) {
                                dateDatOffValue.push(
                                    dateDat[
                                        state.mesureOffValue[offv.toLowerCase()]
                                    ]
                                )
                            }
                        }
                    }
                    //
                    var day = '',
                        dayhour = ''
                    if (
                        state.specificDashApi.indexOf(
                            state.mesureType.toLowerCase()
                        ) > -1
                    ) {
                        dateDat = data[dat]['TREATMENT_GLOBAL_COMPLIANCE']
                        if (!isDefined(dateDat)) {
                            continue
                        }
                        var todayTime = '23:59:59.000000'
                        dateDatHardDate =
                            dat.substr(6, 2) +
                            '/' +
                            dat.substr(4, 2) +
                            '/' +
                            dat.substr(0, 4) +
                            ' à ' +
                            '23:59'
                        var today = getYMDfromDateObj(new Date())
                        dateDatValue =
                            isDefined(dateDat) && isDefined(dateDat.total)
                                ? dateDat.total
                                : null
                        if (
                            dat ===
                            String(today.year) +
                                String(today.month) +
                                String(today.day)
                        ) {
                            todayTime = 'now'
                            dateDatHardDate = ''
                        } else if (
                            +dat >
                            +(today.year + '' + today.month + '' + today.day)
                        ) {
                            dateDatValue = null
                        }
                        dateDatDay = getDateObjFromYMD(dat, todayTime, true) // YYYYMMDD
                        day = dat
                        dayhour = createDateComplientSafari(dateDatDay.date)
                    } else if (
                        isDefined(dateDatDay) &&
                        isDefined(dateDatDay.date)
                    ) {
                        day = getYMDfromDateObj(
                            createDateComplientSafari(dateDatDay.date)
                        )
                        dayhour = dateDatDay.date
                        day = day.year + '' + day.month + '' + day.day
                    } else {
                        day = dateDatDay
                    }
                    //
                    if (isDefined(dateDatValue)) {
                        let mesureObject = state.telesuiviKPI.filter(
                            (f) =>
                                isDefined(f.value) &&
                                f.value.toLowerCase() ===
                                    state.mesureType.toLowerCase()
                        )[0]
                        if (
                            isDefined(mesureObject) &&
                            isDefined(mesureObject.roundValue)
                        ) {
                            if (
                                dateDatValue > 0 &&
                                mesureObject.roundValue !== false
                            ) {
                                dateDatValue =
                                    Math.round(
                                        dateDatValue * mesureObject.roundValue
                                    ) / mesureObject.roundValue
                            }
                        }
                    } else {
                        dateDatValue = null
                    }
                    //
                    pdata.alerts = dateDatAlerts
                    if (isDefined(dateDatAlerts) && dateDatAlerts.length > 0) {
                        dateDatValue = null
                    }
                    //
                    if (
                        !isDefined(dateDatValue) &&
                        isDefined(dateDatName) &&
                        isDefined(pdata[dateDatName])
                    ) {
                        dateDatValue = pdata[dateDatName]
                    }
                    //
                    var test_FAKE_VALUE = state.test_FAKE_VALUE
                    if (isDefined(test_FAKE_VALUE)) {
                        test_FAKE_VALUE =
                            Math.floor(Math.random() * test_FAKE_VALUE) + 1
                        dateDatValue = isDefined(test_FAKE_VALUE)
                            ? test_FAKE_VALUE
                            : dateDatValue
                        dateDatValue1 = isDefined(test_FAKE_VALUE)
                            ? test_FAKE_VALUE
                            : dateDatValue1
                    }
                    //
                    pdata.name = dateDatName
                    pdata.kpi = 'none'
                    pdata.label = 'none'
                    pdata.date = day
                    pdata.fulldate = dayhour
                    pdata.hardDate = dateDatHardDate
                    pdata.min = 0
                    pdata.max = dateDatValue
                    pdata.total = dateDatValue
                    pdata.current = dateDatValue
                    pdata.value = dateDatValue
                    pdata.mean = dateDatObjective
                    pdata.secondChartValue = dateDatValueSecondChart
                    pdata.secondChartName = dateDatValueSecondChartName
                    pdata.offvalue = dateDatOffValue
                    pdata.dataType = state.mesureType
                    pdata.triggerType = dateDatTriggerType
                    pdata.id = dateDatId
                    pdata.value1 = dateDatValue1
                    pdata.interval = dateDatInterval
                    pdata.status = dateDatStatus
                    //
                    if (
                        pdata.value !== null &&
                        pdata.value !== 'NODATA' &&
                        (tmpYMin === null || +pdata.value < tmpYMin)
                    ) {
                        tmpYMin = pdata.value
                    }
                    if (
                        pdata.value !== null &&
                        pdata.value !== 'NODATA' &&
                        (tmpYMax === null || +pdata.value > tmpYMax)
                    ) {
                        tmpYMax = pdata.value
                    }
                    if (isDefined(pdata.mean) && pdata.mean > tmpYMax) {
                        tmpYMax = pdata.mean + pdata.mean / 10
                    }
                    //
                    if (
                        pdata.secondChartValue !== null &&
                        (tmpYMinSecond === null ||
                            +pdata.secondChartValue < tmpYMinSecond)
                    ) {
                        tmpYMinSecond = pdata.secondChartValue
                    }
                    if (
                        pdata.secondChartValue !== null &&
                        (tmpYMaxSecond === null ||
                            +pdata.secondChartValue > tmpYMaxSecond)
                    ) {
                        tmpYMaxSecond = pdata.secondChartValue
                    }

                    if (parsedData[day] === undefined) {
                        parsedData[day] = []
                    }
                    parsedData[day].push(pdata)
                }
                for (let dday in parsedData) {
                    dashData.push(parsedData[dday])
                }
                var parsedXAxis = []
                var parseTime = state.d3obj.timeParse('%Y%m%d')
                var refAlertInsteadofMesures = [],
                    refAlertInsteadofMesure = ''
                dashData.forEach(function (d) {
                    d.forEach(function (dd) {
                        // if we found an alert, we dont show mesure value, showing only alert value
                        if (dd.triggerType !== '') {
                            refAlertInsteadofMesure =
                                String(dd.date) + '-' + String(dd.value)
                            if (
                                refAlertInsteadofMesures.indexOf(
                                    refAlertInsteadofMesure
                                ) === -1
                            ) {
                                refAlertInsteadofMesures.push(
                                    refAlertInsteadofMesure
                                )
                            }
                        }
                    })
                    //
                    d.forEach(function (dd) {
                        var tmpDate = dd.date
                        if (tmpDate !== undefined) {
                            dd.showMesure = true
                            refAlertInsteadofMesure =
                                String(dd.date) + '-' + String(dd.value)
                            if (
                                refAlertInsteadofMesures.indexOf(
                                    refAlertInsteadofMesure
                                ) > -1
                            ) {
                                dd.showMesure = false
                            }
                            //
                            if (tmpDate.indexOf('MONTH') > -1) {
                                tmpDate = tmpDate.replace('MONTH', '') + '01'
                            }
                            dd.key = tmpDate
                            dd.date = parseTime(tmpDate)
                        }
                        dd.origvalue = dd.value
                    })
                    parsedXAxis.push(d[0])
                })
                // tmpYMin = 0; // remove to get a dynamique Y low value
                // tmpYMax = Math.round(tmpYMax + ((tmpYMax / 100) * 40)); // top offset
                let tmpNumberOfTicks = 5
                if (
                    [
                        'cryptedtiredness',
                        'cryptedmoral',
                        'cryptedsleep',
                        'cryptedstress',
                    ].indexOf(state.mesureType.toLowerCase()) > -1
                ) {
                    tmpYMin = 0
                    tmpYMax = 6
                    tmpNumberOfTicks = 6
                } else if (
                    ['cryptedsmoke'].indexOf(state.mesureType.toLowerCase()) >
                    -1
                ) {
                    tmpYMin = 0
                    tmpYMax += 10
                } else if (
                    ['cryptedactivity'].indexOf(
                        state.mesureType.toLowerCase()
                    ) > -1
                ) {
                    tmpYMin = 0
                    tmpYMax += 1000
                } else if (
                    ['observance'].indexOf(state.mesureType.toLowerCase()) > -1
                ) {
                    tmpYMin = 0
                    tmpYMax = 100
                } else {
                    if (!isDefined(tmpYMin) || isNaN(tmpYMin)) {
                        tmpYMin = 0
                    }
                    if (!isDefined(tmpYMax) || isNaN(tmpYMax)) {
                        tmpYMax = 1
                    }
                    if (state.thereIsNoData) {
                        tmpYMin = 0
                    } else {
                        tmpYMin = Math.floor((tmpYMin - 10) / 10) * 10 // remove to get a dynamique Y low value
                        if (
                            ['cryptedhydration'].indexOf(
                                state.mesureType.toLowerCase()
                            ) > -1
                        ) {
                            tmpYMax = Math.floor((tmpYMax + 2) / 2) * 2 // top offset
                        } else {
                            tmpYMax = Math.floor((tmpYMax + 10) / 10) * 10 // top offset
                        }
                    }
                }
                if (tmpYMin < 0) {
                    tmpYMin = 0
                }
                state.xAxis.domain(
                    state.d3obj.extent(parsedXAxis, function (d) {
                        return d.date
                    })
                )
                state.yAxis.domain([tmpYMin, tmpYMax])
                if (isDefined(tmpYMinSecond) && isDefined(tmpYMaxSecond)) {
                    state.yRightAxis.domain([tmpYMinSecond, tmpYMaxSecond])
                }
                state.currentYMin = tmpYMin
                state.currentYMax = tmpYMax
                //
                if (isDefined(names)) {
                    var x = state.d3obj.select('g.axis--x-' + names[0])
                    x.call(
                        state.d3obj
                            .axisBottom(state.xAxis)
                            .tickFormat(function (d) {
                                if (
                                    state.ticksNumber.substring[
                                        state.period
                                    ] !== null
                                ) {
                                    return state.d3obj
                                        .timeFormat(
                                            state.ticksNumber.format[
                                                state.period
                                            ]
                                        )(d)
                                        .substring(
                                            0,
                                            state.ticksNumber.substring[
                                                state.period
                                            ]
                                        )
                                }
                                return state.d3obj.timeFormat(
                                    state.ticksNumber.format[state.period]
                                )(d)
                            })
                            .ticks(parsedXAxis.length)
                    )
                    var y = state.d3obj.select('g.axis--y-' + names[0])
                    y.call(
                        state.d3obj
                            .axisLeft(state.yAxis)
                            .tickValues(
                                state.yAxis
                                    .ticks(tmpNumberOfTicks)
                                    .concat(state.yAxis.domain())
                            )
                            .tickSize(
                                -(
                                    state.availableWidth -
                                    state.padding.left -
                                    state.margin.left -
                                    state.padding.right -
                                    state.margin.right
                                ),
                                0
                            )
                            .tickFormat(function (d) {
                                return d
                            })
                            .ticks(state.ticksNumber.y[state.period])
                    )
                    var subx = state.d3obj.select(
                        'g.sub-axis--subx-' + names[0]
                    )
                    subx.call(
                        state.d3obj
                            .axisBottom(state.xAxis)
                            .tickFormat(function (d) {
                                if (state.period === 'week') {
                                    return state.d3obj.timeFormat('%d/%m/%y')(d)
                                }
                                return state.d3obj.timeFormat('%d')(d)
                            })
                            .ticks(parsedXAxis.length)
                    )
                    //
                    var yright = state.d3obj.select(
                        'g.axis--yright-' + names[0]
                    )
                    if (isDefined(tmpYMaxSecond)) {
                        yright.style('display', 'block')
                        yright.call(
                            state.d3obj
                                .axisLeft(state.yRightAxis)
                                .tickValues(
                                    state.yRightAxis
                                        .ticks(tmpNumberOfTicks)
                                        .concat(state.yRightAxis.domain())
                                )
                                .tickSize(
                                    -(
                                        state.availableWidth -
                                        state.padding.left -
                                        state.margin.left -
                                        state.padding.right -
                                        state.margin.right
                                    ),
                                    0
                                )
                                .tickFormat(function (d) {
                                    return d
                                })
                                .ticks(state.ticksNumber.y[state.period])
                        )
                    } else {
                        yright.style('display', 'none')
                    }
                }
                //
                if (state.period === 'week') {
                    actions.setBarwidth(15)
                } else if (state.period === 'month') {
                    actions.setBarwidth(3)
                }
                //
                actions.updateCircleValue({ data: dashData, names: names })
                actions.updateBarChart({ data: dashData, names: names })
            },

        updateBarChart:
            ({ data, names }) =>
            (state, actions) => {
                state.data = data
                var rootContainer = state.d3obj.select(
                    document.querySelectorAll(
                        '.root-container-' + names[0].toLowerCase()
                    )[0]
                )
                var rectBg = rootContainer.select('g.rectBg')

                actions.updateBgRect({ data: data, names: names })
                let atLeastOneBarCart =
                    isDefined(state.alertValue) &&
                    Array.isArray(state.alertValue)
                        ? state.alertValue.filter((f) => f.chart === 'bar')
                        : []
                if (
                    !isDefined(atLeastOneBarCart) ||
                    atLeastOneBarCart.length === 0
                ) {
                    return false
                }
                //
                var grpBgRect = rootContainer.selectAll('g.grpbgrectangles')
                var length = data.length
                var availableWidth =
                    (state.availableWidth -
                        state.padding.left -
                        state.margin.left) /
                    length
                var barWidth = 0
                if (isDefined(grpBgRect.node())) {
                    actions.setRefBoundingBox(
                        grpBgRect.node().getBoundingClientRect()
                    )
                }
                //
                for (let dat in data) {
                    var datlength = data[dat].length
                    var rectBgBars = rectBg
                        .select('g.grpbgrectangles-' + +data[dat][0].key)
                        .selectAll('rect.objgcharts')
                        .data(
                            data[dat].sort((a, b) => {
                                return moment(a.fulldate) - moment(b.fulldate)
                            })
                        )
                    //
                    rectBgBars.exit().remove()
                    let barMargin = 1
                    //
                    for (let oAlert in state.alertValue) {
                        if (state.alertValue[oAlert].chart === 'bar') {
                            var datKey = state.alertValue[oAlert].key
                            rectBgBars
                                .enter()
                                .append('path')
                                .attr('class', function () {
                                    return 'objgcharts'
                                })
                                .style('fill', function (d) {
                                    return actions.retrieveLegendColor({
                                        oValue: d,
                                        chart: state.alertValue[oAlert],
                                    })
                                })
                                .attr('d', function (d, i) {
                                    d.datlength = datlength
                                    d.datKey = datKey
                                    var bHeight = !isDefined(d)
                                        ? d
                                        : actions.parseH(d[datKey])
                                    if (
                                        isNaN(actions.parseY(d[datKey])) ||
                                        isNaN(actions.parseX(datlength)) ||
                                        isNaN(bHeight) ||
                                        bHeight === null
                                    ) {
                                        if (d.triggerType === 'NODATA') {
                                            //
                                        } else {
                                            return ''
                                        }
                                    }
                                    if (bHeight <= 0) {
                                        bHeight = 2
                                    }
                                    barWidth = actions.parseW(datlength)
                                    var rCorner = barWidth / 6
                                    if (bHeight < rCorner) {
                                        rCorner = bHeight
                                    }
                                    var yPos = actions.parseY(d[datKey])
                                    if (
                                        datKey === 'color' ||
                                        d.triggerType === 'NODATA'
                                    ) {
                                        bHeight =
                                            state.availableHeight -
                                            state.xLabelHeight -
                                            50
                                        yPos = 0
                                    }
                                    //
                                    var xPos =
                                        +actions.parseX(datlength) +
                                        (-datlength / 2 + i) *
                                            (barWidth + barMargin) -
                                        barWidth / 2
                                    return actions.rounded_rect({
                                        x: xPos,
                                        y: yPos,
                                        w: barWidth,
                                        h: bHeight,
                                        r: rCorner,
                                        tl: true,
                                        tr: true,
                                        bl: false,
                                        br: false,
                                    })
                                })
                                .on('mouseover', function (d, i, el) {
                                    state.tooltip
                                        .style('opacity', 1)
                                        .style('left', function () {
                                            barWidth = actions.parseW(
                                                d.datlength
                                            )
                                            let leftOffset =
                                                el[i].getBoundingClientRect()
                                                    .left
                                            if (state.period === 'month') {
                                                leftOffset -= 5
                                            } else if (
                                                state.period === 'week'
                                            ) {
                                                if (d.datlength > 5) {
                                                    leftOffset -= 2
                                                } else {
                                                    leftOffset += 3
                                                }
                                            }
                                            return leftOffset + 'px'
                                        })
                                        .style(
                                            'top',
                                            el[i].getBoundingClientRect().top +
                                                'px'
                                        )
                                        .style('bottom', 'inherit')
                                        .html(function () {
                                            var offvalue = ''
                                            if (
                                                isDefined(d.offvalue) &&
                                                d.offvalue.length > 0
                                            ) {
                                                for (let offv in d.offvalue) {
                                                    offvalue +=
                                                        '/' + d.offvalue[offv]
                                                }
                                            }
                                            return (
                                                '<b>' +
                                                (isDefined(d[d.datKey]) &&
                                                d[d.datKey] !== 'NODATA' &&
                                                !isNaN(d[d.datKey])
                                                    ? d[d.datKey]
                                                    : 'n.c') +
                                                offvalue +
                                                (isDefined(state.mesureUnit) &&
                                                isDefined(
                                                    state.mesureUnit[d.datKey]
                                                ) &&
                                                isDefined(d[d.datKey]) &&
                                                d[d.datKey] !== 'NODATA' &&
                                                !isNaN(d[d.datKey]) &&
                                                state.mesureUnit[d.datKey] !==
                                                    ''
                                                    ? ' ' +
                                                      state.mesureUnit[d.datKey]
                                                    : '') +
                                                '</b></br><font style="white-space: nowrap;"">' +
                                                (d.hardDate !== ''
                                                    ? d.hardDate
                                                    : getReadableDate(
                                                          createUTCDate(
                                                              d.fulldate,
                                                              state.withOffset
                                                          ),
                                                          'DD/MM/YYYY HH:mm'
                                                      )) +
                                                '</font>'
                                            )
                                        })
                                })
                                .on('mouseleave', function () {
                                    state.tooltip.style('opacity', 0)
                                })
                                .on('mousedown', function (d) {
                                    if (state.period === 'month') {
                                        if (
                                            isDefined(
                                                state.updateFromChildToParent
                                            )
                                        ) {
                                            state.updateFromChildToParent({
                                                period: 'week',
                                                date: d.key,
                                            })
                                        }
                                    }
                                })
                        }
                    }
                }
            },

        updateBgRect:
            ({ data, names }) =>
            (state, actions) => {
                state.data = data
                var rootContainer = state.d3obj.select(
                    document.querySelectorAll(
                        '.root-container-' + names[0].toLowerCase()
                    )[0]
                )
                var rectBg = rootContainer.select('g.rectBg')
                rectBg.selectAll('*').remove() // ugly, but necessary for now (todo: do it better way)
                //
                var rect = rectBg.selectAll('g.grpbgrectangles').data(data)
                rect.exit().remove()
                //
                var grpBgRect = rect
                    .enter()
                    .append('g')
                    .attr('class', function (d) {
                        var dat = d[0] || d
                        return 'grpbgrectangles grpbgrectangles-' + +dat.key
                    })
                    .merge(rect)
                grpBgRect
                    .transition()
                    .duration(400)
                    .attr('transform', function (d) {
                        var dat = d[0] || d
                        return 'translate(' + state.xAxis(dat.date) + ', 0)'
                    })

                grpBgRect.selectAll('*').remove()
                grpBgRect.exit().remove()
                var length = data.length
                var barWidth = 0
                let barMargin = 1
                var availableWidth =
                    (state.availableWidth -
                        state.padding.left -
                        state.margin.left) /
                    length
                for (let dat in data) {
                    var dataBg = data[dat]
                    var datlength = dataBg.length
                    rectBg
                        .select('g.grpbgrectangles-' + +data[dat][0].key)
                        .selectAll('g.grpbgrectanglesbg')
                        .data(dataBg)
                        .enter()
                        .append('path')
                        .attr('class', function (d) {
                            return (
                                'objgcharts bgrectangles bgrectangles-' + +d.key
                            )
                            // return 'objgcharts bgrectangles bgrectangles-' + +d.key + ' ' + (((isDefined(d.triggerType) && (d.triggerType !== '')) || isDefined(d.value)) ? '' : 'bgrectangles-invisible');
                        })
                        .attr('d', function (d, i) {
                            d.datlength = datlength
                            var bHeight =
                                state.availableHeight - state.xLabelHeight - 50
                            var barWidth = actions.parseW(datlength)
                            //
                            var xPos =
                                +actions.parseX(datlength) +
                                (-datlength / 2 + i) * (barWidth + barMargin) -
                                barWidth / 2
                            // if (state.period === 'month') {
                            //     xPos -= 2;
                            // }
                            return actions.rounded_rect({
                                x: xPos,
                                y: 0,
                                w: barWidth,
                                h: bHeight,
                                r: 0,
                                tl: false,
                                tr: false,
                                bl: false,
                                br: false,
                            })
                        })
                        .style('pointer', function () {
                            if (state.period === 'month') {
                                return 'cursor'
                            }
                            return 'default'
                        })
                        .on('mousedown', function (d) {
                            if (state.period === 'month') {
                                if (isDefined(state.updateFromChildToParent)) {
                                    state.updateFromChildToParent({
                                        period: 'week',
                                        date: d.key,
                                    })
                                }
                            }
                        })
                }
            },

        updateCircleValue:
            ({ data, names }) =>
            (state, actions) => {
                var rootContainer = state.d3obj.select(
                    document.querySelectorAll(
                        '.root-container-' + names[0].toLowerCase()
                    )[0]
                )
                var rectCircle = rootContainer.select('g.rectCircle')
                var pathLine = rootContainer.select('g.linesPath')
                rectCircle.selectAll('*').remove() // can be do better way
                pathLine.selectAll('*').remove() // can be do better way
                //
                let atLeastOneCircleCart =
                    isDefined(state.alertValue) &&
                    Array.isArray(state.alertValue)
                        ? state.alertValue.filter((f) => f.chart === 'circle')
                        : []
                if (
                    !isDefined(atLeastOneCircleCart) ||
                    atLeastOneCircleCart.length === 0
                ) {
                    return false
                }
                //
                state.data = data
                var circlWidth = 14
                var circlHeight = 14
                var length = data.length,
                    datlength = 0
                if (state.period === 'month') {
                    circlWidth = 5
                    circlHeight = 5
                }
                //
                let pathLined = pathLine.append('path')
                let pathLinedRight = pathLine.append('path')
                //
                let parsedDatas = [],
                    parsedData = null
                for (let pDat in data) {
                    var temp = data[pDat].sort((a, b) => {
                        return moment(a.fulldate) - moment(b.fulldate)
                    })
                    for (let psDat in temp) {
                        parsedData = data[pDat][psDat]
                        parsedData.datlength = +data[pDat].length
                        parsedData.barMargin = 1
                        parsedData.i = +psDat + 1
                        parsedDatas.push([parsedData])
                    }
                }
                //
                pathLined
                    .data([parsedDatas])
                    .attr('class', 'line')
                    .attr('d', state.valueLine)
                    .attr('opacity', '0')
                    .transition()
                    .duration(400)
                    .ease(state.d3obj.easeLinear)
                    .attr('opacity', '1')
                pathLinedRight
                    .data([parsedDatas])
                    .attr('class', 'line lineRight')
                    .attr('d', state.valueLineRight)
                    .attr('opacity', '0')
                    .transition()
                    .duration(400)
                    .ease(state.d3obj.easeLinear)
                    .attr('opacity', '1')
                //
                for (let dat in data) {
                    datlength = data[dat].length
                    var dataRect = data[dat].filter(function (d) {
                        return !isDefined(d.triggerType) || d.triggerType === ''
                    })
                    var grpCircleRect = rectCircle
                        .selectAll('g.circles-' + dat)
                        .data(dataRect)
                    //
                    for (let oAlert in state.alertValue) {
                        if (state.alertValue[oAlert].chart === 'circle') {
                            var datKey = state.alertValue[oAlert].key
                            let isMean = datKey === 'mean'
                            var gg = grpCircleRect
                                .enter()
                                .append('g')
                                .attr('class', function (d) {
                                    d.datKey = datKey
                                    return 'circles circles-' + dat
                                })
                                .merge(grpCircleRect)
                            //
                            gg.transition()
                                .duration(400)
                                .attr('transform', function (d) {
                                    let yPos = 0
                                    if (+oAlert === 0) {
                                        yPos = state.yAxis(d[datKey])
                                    } else if (+oAlert === 1) {
                                        yPos = state.yRightAxis(d[datKey])
                                    }
                                    if (isMean) {
                                        yPos = state.yAxis(d.mean)
                                    }
                                    return (
                                        'translate(' +
                                        state.xAxis(d.date) +
                                        ', ' +
                                        yPos +
                                        ')'
                                    )
                                })
                            //
                            let availableWidth =
                                (state.availableWidth -
                                    state.padding.left -
                                    state.margin.left) /
                                length
                            let barWidth = 0
                            let barMargin = 1
                            let localUnit = state.alertValue[oAlert].unit || ''
                            gg.append('rect')
                                .attr('class', function (d, i) {
                                    return (
                                        'objrectangles objrectangles-' +
                                        i +
                                        ' circles-colored-' +
                                        datKey
                                    )
                                })
                                .attr('fill', function () {
                                    return '#0082A1'
                                })
                                .attr('dat-value', function (d) {
                                    var dvalue = d[d.datKey]
                                    return dvalue
                                })
                                .attr('dat-index', function () {
                                    return oAlert
                                })
                                .attr('dat-mean', function () {
                                    return isMean
                                })
                                .style('display', function (d) {
                                    var dvalue = d[d.datKey]
                                    d.datValue = dvalue
                                    d.datlength = datlength
                                    if (!isDefined(dvalue)) {
                                        return 'none'
                                    }
                                    return 'block'
                                })
                                .attr('width', function () {
                                    return circlWidth + 'px'
                                })
                                .attr('height', circlHeight + 'px')
                                .attr('x', function (d, i) {
                                    var barWidth = actions.parseW(datlength)
                                    var offsetX =
                                        state.period === 'month'
                                            ? barWidth + barMargin
                                            : datlength >= 5
                                            ? barWidth - barMargin + 2
                                            : barWidth - barMargin
                                    return (
                                        +actions.parseX(datlength) +
                                        (-datlength / 2 + d.i) *
                                            (barWidth + barMargin) -
                                        barWidth / 2 -
                                        offsetX
                                    )
                                })
                                .attr('y', circlHeight * -0.5 + 'px')
                                .attr('rx', circlHeight / 2)
                                .attr('ry', circlHeight / 2)
                                .on('mouseover', function (d, i, el) {
                                    let datValue =
                                        +el[i].getAttribute('dat-value')
                                    state.tooltip
                                        .style('opacity', 1)
                                        .style('left', function () {
                                            let leftOffset =
                                                el[i].getBoundingClientRect()
                                                    .left
                                            if (state.period === 'month') {
                                                leftOffset -= 5
                                            }
                                            return leftOffset + 'px'
                                        })
                                        .style('top', function () {
                                            return (
                                                el[i].getBoundingClientRect()
                                                    .top + 'px'
                                            )
                                        })
                                        .style('bottom', 'inherit')
                                        .html(function () {
                                            var offvalue = ''
                                            if (
                                                isDefined(d.offvalue) &&
                                                d.offvalue.length > 0
                                            ) {
                                                for (let offv in d.offvalue) {
                                                    offvalue +=
                                                        '/' + d.offvalue[offv]
                                                }
                                            }
                                            return (
                                                '<b>' +
                                                Math.round(datValue * 100) /
                                                    100 +
                                                offvalue +
                                                (isDefined(state.mesureUnit) &&
                                                isDefined(
                                                    state.mesureUnit[d.datKey]
                                                ) &&
                                                state.mesureUnit[d.datKey] !==
                                                    ''
                                                    ? ' ' +
                                                      state.mesureUnit[d.datKey]
                                                    : ' ' + localUnit) +
                                                '</b></br><font style="white-space: nowrap;">' +
                                                (d.hardDate !== ''
                                                    ? d.hardDate
                                                    : getReadableDate(
                                                          createUTCDate(
                                                              d.fulldate,
                                                              state.withOffset
                                                          ),
                                                          'DD/MM/YYYY HH:mm'
                                                      )) +
                                                '</font>'
                                            )
                                        })
                                })
                                .on('mouseleave', function () {
                                    state.tooltip.style('opacity', 0)
                                })
                        }
                    }
                }
            },

        getParsedDate: () => (state, actions) => {
            var today = moment().startOf('day')
            var todayDate = moment(
                state.date.substr(0, 4) +
                    '-' +
                    state.date.substr(4, 2) +
                    '-' +
                    state.date.substr(6, 2)
            ).startOf('day')
            var refMonthDate = moment(
                state.date.substr(0, 4) +
                    '-' +
                    state.date.substr(4, 2) +
                    '-' +
                    state.date.substr(6, 2)
            )
            //
            var dt = moment(
                state.date.substr(0, 4) +
                    '-' +
                    state.date.substr(4, 2) +
                    '-' +
                    state.date.substr(6, 2)
            )
            var firstday = moment(dt).startOf('isoWeek')
            var lastday = moment(firstday).endOf('isoWeek')
            //
            if (state.period === 'week') {
                state.missingDays.week = getDaysInWeek(firstday)
            } else if (state.period === 'month') {
                state.missingDays.month = getDaysInMonth(
                    firstday.month(),
                    firstday.year()
                )
            }
            var refDateLong = firstday
            var firstdaylong = refDateLong
            var lastdaylong = null
            if (lastday > today || todayDate >= today) {
                state.noFutur = true
            } else {
                if (state.noFutur) {
                    state.noFutur = false
                }
            }
            //
            var options = 'DD MMMM YYYY' // { month: 'long', day: 'numeric', year: 'numeric' };
            if (state.period === 'week') {
                firstdaylong = moment(refDateLong.set('day', 1))
                options = 'dd DD MMMM YYYY' // { weekday:'long', month: 'long', day: 'numeric', year: 'numeric' };
                lastdaylong = lastday.locale(state.locale).format(options)

                var lday = firstday.day()
                if (+lday < 10) {
                    lday = '0' + lday
                }
                var lmonth = firstday.month() + 1
                if (+lmonth < 10) {
                    lmonth = '0' + lmonth
                }
                firstday = lday + '/' + lmonth
                //
                lday = lastday.day()
                if (+lday < 10) {
                    lday = '0' + lday
                }
                lmonth = lastday.month() + 1
                if (+lmonth < 10) {
                    lmonth = '0' + lmonth
                }
                lastday = lday + '/' + lmonth
            } else if (state.period === 'month') {
                options = 'MMMM YYYY' // { month: 'long', year: 'numeric' };
                lastdaylong = lastday.locale(state.locale, options)
                firstday = firstday.locale(state.locale).format(options)
            } else if (state.period === 'year') {
                options = 'YYYY' // { year: 'numeric' };
                lastdaylong = lastday.locale(state.locale).format(options)
                firstday = firstday.locale(state.locale).format(options)
            }
            // firstdaylong = new Date(Date.parse(new Date(firstdaylong)));
            firstdaylong = firstdaylong.locale(state.locale).format(options)
            //
            return {
                firstday: { short: firstday, long: firstdaylong },
                lastday: { short: lastday, long: lastdaylong },
            }
        },
        // TOOLS
        rounded_rect:
            ({ x, y, w, h, r, tl, tr, bl, br }) =>
            (state, actions) => {
                var retval
                retval = 'M' + (x + r) + ',' + y
                retval += 'h' + (w - 2 * r)
                if (tr) {
                    retval += 'a' + r + ',' + r + ' 0 0 1 ' + r + ',' + r
                } else {
                    retval += 'h' + r
                    retval += 'v' + r
                }
                retval += 'v' + (h - 2 * r)
                if (br) {
                    retval += 'a' + r + ',' + r + ' 0 0 1 ' + -r + ',' + r
                } else {
                    retval += 'v' + r
                    retval += 'h' + -r
                }
                retval += 'h' + (2 * r - w)
                if (bl) {
                    retval += 'a' + r + ',' + r + ' 0 0 1 ' + -r + ',' + -r
                } else {
                    retval += 'h' + -r
                    retval += 'v' + -r
                }
                retval += 'v' + (2 * r - h)
                if (tl) {
                    retval += 'a' + r + ',' + r + ' 0 0 1 ' + r + ',' + -r
                } else {
                    retval += 'v' + -r
                    retval += 'h' + r
                }
                retval += 'z'
                return retval
            },
        castToNumberOfFloat: (str) => (state, actions) => {
            if (str === null || str === undefined || str === '' || isNaN(str)) {
                return null
            } else if (isInteger(str) || isFloat(str)) {
                return str
            } else if (str.indexOf('.') > -1 || str.indexOf(',') > -1) {
                return parseFloat(str)
            } else {
                return parseInt(str)
            }
        },
        retrieveLegendColor:
            ({ oValue, chart }) =>
            (state, actions) => {
                let defaultLegendColor = state.defaultLegendColor
                let refLegend = state.telesuiviKPI.filter(
                    (f) =>
                        f.value.toLowerCase() === oValue.dataType.toLowerCase()
                )[0]
                let optMean = oValue.mean
                if (isDefined(refLegend)) {
                    refLegend = refLegend.legend
                }
                if (isDefined(refLegend)) {
                    if (isDefined(optMean)) {
                        for (let legend in refLegend) {
                            if (refLegend[legend].success === true) {
                                refLegend[legend].minValue = optMean
                            } else if (refLegend[legend].success == false) {
                                refLegend[legend].maxValue = optMean
                            }
                        }
                    }
                    if (isDefined(oValue[chart.key])) {
                        for (let legendd in refLegend) {
                            if (
                                +oValue[chart.key] >=
                                    +refLegend[legendd].minValue &&
                                +oValue[chart.key] <=
                                    +refLegend[legendd].maxValue
                            ) {
                                defaultLegendColor = refLegend[legendd].color
                            }
                        }
                    }
                }
                if (
                    isDefined(oValue.triggerType) &&
                    oValue.triggerType !== ''
                ) {
                    if (oValue.triggerType === 'NODATA') {
                        defaultLegendColor = '#F2F4F6'
                    } else {
                        defaultLegendColor = '#FF3498'
                    }
                }
                return defaultLegendColor
            },
        parseX: (datlength) => (state, actions) => {
            // return (state.barwidth / 2) - (actions.parseW(datlength) / 2);
            return actions.parseW(datlength) / 2
        },
        parseY: (d) => (state, actions) => {
            return state.yAxis(d)
        },
        parseH: (d) => (state, actions) => {
            if (d === undefined || d === null) {
                return null
            }
            return state.height - state.yAxis(d) - state.padding.bottom
        },
        parseW: (datlength) => (state, actions) => {
            var bgWidth =
                (state.width - state.padding.left - state.margin.left) /
                    state.data.length -
                4 // 4 is the stroke
            let parsedW = 0
            if (datlength <= 4) {
                parsedW = Math.floor(bgWidth / 6)
            } else {
                if (datlength === 5) {
                    parsedW = Math.floor(bgWidth / 8)
                } else {
                    parsedW = Math.floor(bgWidth / (datlength + 5)) // 8
                }
            }
            return parsedW
        },
        parseXAxis: (value) => (state, actions) => {
            return value - (state.period === 'week' ? 20 : 25)
        },
        parseYAxis: (value) => (state, actions) => {
            return value - (state.period === 'week' ? 62 : 50)
        },
        setMesureUnit: (newState) => (state) => ({
            mesureUnit: newState,
        }),
        setMesureValue: (newState) => (state) => ({
            mesureLabel: newState,
        }),
        setMesureChart: (newState) => (state) => ({
            mesureChart: newState,
        }),
        setMesureChartValueKey: (newState) => (state) => ({
            mesureValue: newState,
        }),
        setChartValueOffKey: (newState) => (state) => ({
            mesureOffValue: newState,
        }),
        setPeriod: (newState) => (state) => ({
            period: newState,
        }),
        setDate: (newState) => (state) => ({
            date: newState,
        }),
        setPatientId: (newState) => (state) => ({
            patientId: newState,
        }),
        setAlertValue: (newState) => (state) => ({
            alertValue: newState,
        }),
        setMesureType: (newState) => (state) => ({
            mesureType: newState,
        }),
        setRefBoundingBox: (newState) => (state) => ({
            refBoundingBox: newState,
        }),
        setLocale: (newState) => (state) => ({
            locale: newState,
        }),
        setBarwidth: (newState) => (state) => ({
            barwidth: newState,
        }),
        setUpdateFromChildToParent: (newState) => (state) => ({
            updateFromChildToParent: newState,
        }),
        setAlerts: (newState) => (state) => ({
            alerts: newState,
        }),
        setChartMesures: (newState) => (state) => ({
            chartMesures: newState,
        }),
        setD3obj: (newState) => (state) => ({
            d3obj: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div
            id="ov-suivi-wrapper-dataviz-wrapper"
            class=""
            style="position: relative;display: block;height: 300px;"
        >
            <svg
                key={props.forceRender}
                class={
                    'thfDataViz ov-suivi-wrapper-dataviz ' +
                    props.date +
                    '-' +
                    props.mesureType +
                    '-' +
                    props.period +
                    '-' +
                    props.themeCurrent +
                    '-' +
                    props.forceRender
                }
                id={'thfDataViz-' + props.themeCurrent}
            ></svg>
        </div>
    ),
    'dataviz'
)

export { DataViz }
