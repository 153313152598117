import { t } from 'i18next';
import { h } from 'hyperapp';
import { Button, Card } from '@app/elements';

export const ModalViewEndStudy = (props, actions) => (
    <Card classes={'btzInformations-btzInformationsModal'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            <div key={props.modalEditing} key={'treatment'}>
                <p class='btzTitle btzXl'>{t('Terminer le parcours de votre patient')}</p>
            </div>
        </Card.Header>
        <Card.Body>
            <p class='btzModal-greyContent'>{t('En mettant fin au parcours vous stoppez tous les envois de questionnaires à venir s\'il y en avait. Vous pourrez à tout moment commencer un nouveau parcours pour la série de questionnaires de votre choix.')}</p>
            <div class='btzCard-btzFooter-btzModal'>
                <Button anchorRight flat margin={'0 0 0 15px'} dangerous  onclick={props.confirmAction}>
                    {t('Terminer le parcours')}
                </Button>
                <Button anchorRight flat cancel onclick={props.cancelAction}>
                    {t('Annuler')}
                </Button>
            </div>
        </Card.Body>
    </Card>
);