import { settings } from '@app/core/settings';
import { handleErrors } from '@app/api/errors';
import { queueQueries } from '@app/api/controlleur';

const { API_URL } = settings;

function sendClientError(data) {
    return queueQueries(`${API_URL}/jse/`, 'POST', 'application/json', false, true, true, false, true, data);
}

export const ClientErrors = {
    sendClientError,
};