import { t } from 'i18next';
import { h } from 'hyperapp';
import { Button, Form, FormInput, Card } from '@app/elements';

import icoTreat from '@app/img/ico/ico-treat.png';
import icoTreatx2 from '@app/img/ico/ico-treat@2x.png';
import icoTreatOff from '@app/img/ico/ico-treat-off.png';
import icoTreatOffx2 from '@app/img/ico/ico-treat-off@2x.png';

export const ModalViewPassword = (props, actions) => (
    <Card classes={'btzInformations-btzInformationsModal'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            <div key={props.modalEditing} class={'btzClassMarker-btz'} key={'password'}>
                <p class='btzTitle btzXl'>{t('Modification de votre mot de passe')}</p>
                <p class='btzSubTitle'>{t('Pour changer votre mot de passe, renseignez votre nouveau mot de passe, confirmez ce mot de passe puis répondez à votre question secrète.')}</p>

                <Form onsubmit={event => event.preventDefault()}>
                    <Form.Group>
                        <div class='col-12 thfAlignTop specialValidationAnchoredLeft'>
                            <p class='btzForm-btzLabel'>{t('Nouveau mot de passe')}</p>
                            <FormInput
                                placeholder={t('le mot de passe de votre choix')}
                                key='input-password'
                                type='password'
                                id='password'
                                name='password'
                                valueType='password'
                                value={props.values.password}
                                validation={true}
                                autocomplete={'new-password'}
                                showPasswordButton={true}
                                activeSpecialValidation={true}
                                oninputcb={props.retrieveInput}
                                onblurcb={props.retrieveErrorMessage}
                                formSubmittedOnce={props.formSubmittedOnce}
                                errorMessage={props.errorMessages.password}
                                isTyping={props.isTyping.password}
                                noAuthRequired={true}
                                required
                            />
                        </div>
                        <div class='col-12 thfAlignTop'>
                            <p class='btzForm-btzLabel'>{t('Confirmer votre nouveau mot de passe')}</p>
                            <FormInput
                                placeholder={t('le mot de passe de votre choix')}
                                key='input-passwordconfirm'
                                type='password'
                                id='passwordconfirm'
                                name='passwordconfirm'
                                valueType='password'
                                value={props.values.passwordconfirm}
                                validation={true}
                                isValid={(props.formSubmittedOnce ? props.isValid.passwordconfirm : 'is-default')}
                                showPasswordButton={true}
                                mustBeEqualTo={props.values.password}
                                oninputcb={props.retrieveInput}
                                onblurcb={props.retrieveErrorMessage}
                                formSubmittedOnce={props.formSubmittedOnce}
                                errorMessage={props.errorMessages.passwordconfirm}
                                isTyping={props.isTyping.passwordconfirm}
                                noAuthRequired={true}
                                required
                            />
                        </div>
                        <div class='col-12 thfAlignTop'>
                            <p class='btzForm-btzLabel'>{t(props.doctor.question)}</p>
                            <FormInput
                                placeholder={t('votre réponse secrète')}
                                key='input-secret'
                                type='text'
                                id='secret'
                                name='secret'
                                value={props.values.secret}
                                validation={true}
                                isValid={(props.formSubmittedOnce ? props.isValid.secret : 'is-default')}
                                oninputcb={props.retrieveInput}
                                onblurcb={props.retrieveErrorMessage}
                                formSubmittedOnce={props.formSubmittedOnce}
                                errorMessage={props.errorMessages.secret}
                                isTyping={props.isTyping.secret}
                                noAuthRequired={true}
                                required
                            />
                        </div>
                    </Form.Group>
                    <Form.Group classes='btzForm-btzFooter'>
                        <Button anchorLeft flat cancel noAuthRequired={true} onclick={(e) => {
                            props.cancelAction(e);
                        }}>
                            {t('Annuler')}
                        </Button>
                        <Button anchorRight flat active={props.isValid.password && props.isValid.passwordconfirm && props.isValid.secret} noAuthRequired={true} onclick={(e) => {
                            props.onSubmit(e);
                        }}>
                            <img class='activated activated-visible' src={icoTreat} srcset={`${icoTreatx2} 2x`} alt='' />
                            <img class='deactivated-visible' src={icoTreatOff} srcset={`${icoTreatOffx2} 2x`} alt='' />
                            {t('Enregistrer')}
                        </Button>
                    </Form.Group>
                </Form>
            </div>
        </Card.Header>
    </Card>
);