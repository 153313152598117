import { settings } from '@app/core/settings'
import { queueQueries } from '@app/api/controlleur'
import { isDefined } from '@app/core'
const { API_URL } = settings

function sendKafkaEvent(data) {
    return queueQueries(
        `${API_URL}/kafka/evt/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

const eventsList = [
    {
        name: 'login-reached',
        data: 'content-event-login-reached',
        type: 'DISPATCH_EVENT_DEFAULT',
    },
    {
        name: 'user-logged',
        data: 'content-event-user-logged',
        type: 'DISPATCH_EVENT_DEFAULT',
    },
]

const withKafkaEvents =
    (opts = {}) =>
    (nextApp) =>
    (initialState, initialActions, initialView, initialRoot) => {
        const options = {
            ...{
                //
            },
            ...opts,
        }

        const enhancedState = {
            // lang: options.lng
        }

        const enhancedActions = {
            initEventListeners: () => {
                //
            },
            triggerEvent: (name) => {
                const evt = eventsList.filter(
                    (evtlst) => evtlst.name === name
                )[0]
                if (isDefined(evt, true, true)) {
                    /*
                sendKafkaEvent(evt).then((res) => {
                    treatEventCallback(evt, res.data);
                })
                */
                }
            },
        }

        const treatEventCallback = (evt, response) => {
            switch (evt.type) {
                case 'DISPATCH_EVENT_DEFAULT':
                //
            }
        }
        enhancedActions.initEventListeners()

        return nextApp(
            { ...initialState, ...enhancedState },
            { ...initialActions, ...enhancedActions },
            initialView,
            initialRoot
        )
    }

export { withKafkaEvents }
