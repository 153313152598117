import { t } from "i18next";
import { h } from "hyperapp";
import { allRoutes, addCSRFToken, isDefined } from "@app/core";
import { Link } from "@app/elements";

import { UserMenu } from "@app/layouts/usermenu";

import icoPatient from "@app/img/ico/custo/resilience/ico-patient.png";
import icoPatientx2 from "@app/img/ico/custo/resilience/ico-patient@2x.png";
import icoPatientGrey from "@app/img/ico/custo/resilience/ico-patient-active.png";
import icoPatientGreyx2 from "@app/img/ico/custo/resilience/ico-patient-active@2x.png";

import icoPatients from "@app/img/ico/custo/resilience/ico-listing.png";
import icoPatientsx2 from "@app/img/ico/custo/resilience/ico-listing@2x.png";
import icoPatientsGrey from "@app/img/ico/custo/resilience/ico-listing-active.png";
import icoPatientsGreyx2 from "@app/img/ico/custo/resilience/ico-listing-active@2x.png";

import icoAlerts from "@app/img/ico/custo/resilience/ico-alert.png";
import icoAlertsx2 from "@app/img/ico/custo/resilience/ico-alert@2x.png";
import icoAlertsGrey from "@app/img/ico/custo/resilience/ico-alert-active.png";
import icoAlertsGreyx2 from "@app/img/ico/custo/resilience/ico-alert-active@2x.png";

import icoHelp from "@app/img/ico/left-menu/ico-help.png";
import icoHelpx2 from "@app/img/ico/left-menu/ico-help@2x.png";
import icoOrientation from "@app/img/ico/left-menu/ico-orientation.png";
import icoOrientationx2 from "@app/img/ico/left-menu/ico-orientation@2x.png";

export default {
	initView: function () {
		let view = (props, actions) => (
			<div class="leftmenu-wrapper">
				<ul class="mainmenu">
					<div
						class={
							"minifyLeftMenu " +
							(props.minifiedLeftMenu ? "minifiedLeftMenu" : "")
						}
						onclick={props.minifyLeftMenu}>
						<p class=""></p>
					</div>
					<p class="thfOnglet">{t("Patients")}</p>
					<li
						onclick={() =>
							(window.location.href = addCSRFToken(
								allRoutes["private.patients.listing"].pathname
							))
						}
						class={props.page == "patients" ? "active" : ""}>
						<div>
							{props.page == "patients" ? (
								<img
									class="activated"
									src={icoPatientsGrey}
									srcset={`${icoPatientsGreyx2} 2x`}
									alt=""
								/>
							) : (
								<img
									class="activated"
									src={icoPatients}
									srcset={`${icoPatientsx2} 2x`}
									alt=""
								/>
							)}
						</div>
						<Link>{t("Liste des patients")}</Link>
					</li>
					{isDefined(props.doctor) &&
						props.doctor.role !== "promoter" && (
							<li
								onclick={() =>
									(window.location.href = addCSRFToken(
										allRoutes["private.patient.add"]
											.pathname
									))
								}
								class={
									"thfOngletAddPatient " +
									(props.page == "patient" ? "active" : "")
								}>
								<div>
									{props.page == "patient" ? (
										<img
											class="activated"
											src={icoPatientGrey}
											srcset={`${icoPatientGreyx2} 2x`}
											alt=""
										/>
									) : (
										<img
											class="activated"
											src={icoPatient}
											srcset={`${icoPatientx2} 2x`}
											alt=""
										/>
									)}
								</div>
								<Link>{t("Ajouter un patient")}</Link>
							</li>
						)}
					{isDefined(props.doctor) &&
						isDefined(props.doctor.refHosp) &&
						isDefined(
							props.doctor.refHosp.customerModules
								.studySurveyResult
						) &&
						props.doctor.refHosp.customerModules
							.studySurveyResult === true && (
							<dummy>
								<p class="thfOnglet">{t("Télésurveillance")}</p>
								<li
									onclick={() =>
										(window.location.href = addCSRFToken(
											allRoutes[
												"private.alertsrds.listing"
											].pathname
										))
									}
									class={
										props.page == "alertsrds"
											? "active"
											: ""
									}>
									<div>
										{props.page == "alertsrds" ? (
											<img
												class="activated"
												src={icoAlertsGrey}
												srcset={`${icoAlertsGreyx2} 2x`}
												alt=""
											/>
										) : (
											<img
												class="activated"
												src={icoAlerts}
												srcset={`${icoAlertsx2} 2x`}
												alt=""
											/>
										)}
									</div>
									{isDefined(
										props.infos
											?.countUserMedicalStudySurveyAlertTodo
									) &&
										!Array.isArray(
											props.infos
												.countUserMedicalStudySurveyAlertTodo
										) && (
											<p class="">
												{Object.values(
													props.infos
														.countUserMedicalStudySurveyAlertTodo
												).reduce((a, b) => a + b)}
											</p>
										)}
									<Link>{t("Alertes")}</Link>
								</li>
							</dummy>
						)}
					<p class="thfOnglet">{t("Besoin d'aide ?")}</p>
					<li
						onclick={() =>
							(window.location.href = addCSRFToken(
								allRoutes["private.patients.listing"].pathname
							))
						}>
						<div>
							<img
								class="activated"
								src={icoHelp}
								srcset={`${icoHelpx2} 2x`}
								alt=""
							/>
						</div>
						<Link
							target={"_blank"}
							to={"https://resilience-academy.stonly.com/kb/fr"}>
							{t("Aide")}
						</Link>
					</li>
					{isDefined(props.doctor) &&
						isDefined(props.doctor.refHosp) &&
						isDefined(
							props.doctor.refHosp.customerModules.orientation
						) &&
						props.doctor.refHosp.customerModules.orientation ===
							true && (
							<li
								onclick={() =>
									(window.location.href = addCSRFToken(
										allRoutes["private.patients.listing"]
											.pathname
									))
								}>
								<div>
									<img
										class="activated"
										src={icoOrientation}
										srcset={`${icoOrientationx2} 2x`}
										alt=""
									/>
								</div>
								<Link
									target={"_blank"}
									to={
										"https://resilience-academy.stonly.com/kb/guide/fr/bonnes-pratiques-de-prise-en-charge-7YWmEPqHl4/Steps/1502597"
									}>
									{t("Orientation")}
								</Link>
							</li>
						)}
				</ul>

				<UserMenu
					{...props}
					display={"connected"}
					minifiedLeftMenu={props.minifiedLeftMenu}></UserMenu>
			</div>
		);
		return view;
	},
};
