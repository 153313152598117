import { t } from 'i18next'
import { User, Hospital, Admin } from '@app/api'
import { enableTheseButton, desableTheseButton, getUser } from '@app/core'
import { allRoutes, addCSRFToken, isDefined, deepCopy, coreFn } from '@app/core'
import { displayErrors } from '@app/api/errors'
import * as constants from '@app/constants'

/**
 * @module PatientAdd
 * @description Patient creation
 */

export default {
    initAction: function () {
        let PatientAdd = {
            onComponentCreate: (props) => (state, actions) => {
                if (isDefined(props.customer)) {
                    if (
                        ['oncoflow', 'unicancer'].indexOf(props.customer) > -1
                    ) {
                        actions._setValidity({ coach: true })
                    }
                }
                actions._setState({ key: 'customer', value: props.customer })
                //
                let then = (loggedUser) => {
                    actions._setState({ key: 'doctor', value: loggedUser })
                    actions._setState({
                        key: 'referenceCustomer',
                        value: loggedUser.customer,
                    })
                    actions._setState({
                        key: 'doctorRole',
                        value: loggedUser.readableRole,
                    })
                    //
                    let redirectAfterCreation = loggedUser.settings.filter(
                        (f) => f.name === 'DOCTOR_PATIENT_REDIRECTION'
                    )[0]
                    if (isDefined(redirectAfterCreation)) {
                        actions._setState({
                            key: 'redirectAfterCreation',
                            value: redirectAfterCreation.value,
                        })
                    }
                    if (isDefined(loggedUser.locale)) {
                        actions._setValues({ locale: loggedUser.locale })
                    }
                    if (isDefined(loggedUser.timezone)) {
                        actions._setValues({ timezone: loggedUser.timezone })
                    }
                    User.getTimezone().then((timezones) => {
                        actions._setState({
                            key: 'timezones',
                            value: timezones.data.map((tmz) => {
                                return { key: tmz, label: tmz }
                            }),
                        })
                        //
                        Hospital.getHospitals().then((res) => {
                            if (isDefined(res.data)) {
                                let refHosp = res.data.filter(
                                    (f) => f.name === loggedUser.customer
                                )[0]
                                let refNurseHosp = res.data.filter(
                                    (f) => f.parent.name === loggedUser.customer
                                )
                                //
                                if (
                                    !isDefined(refHosp) &&
                                    loggedUser.readableRole === 'nurse'
                                ) {
                                    refHosp = refNurseHosp[0]
                                    actions._setState({
                                        key: 'hospitals',
                                        value: refNurseHosp,
                                    })
                                    refNurseHosp = refNurseHosp.map((hosp) => {
                                        return {
                                            key: hosp.id,
                                            label: hosp.name,
                                        }
                                    })
                                    actions._setState({
                                        key: 'referenceHospital',
                                        value: refNurseHosp,
                                    })
                                    actions._setState({
                                        key: 'referenceCustomer',
                                        value: refNurseHosp[0].key,
                                    })
                                    //
                                }
                                if (isDefined(refHosp)) {
                                    actions.parseHospitalHealTeam(refHosp)
                                }
                                //
                                if (
                                    constants.custo.CUSTOMERS[props.customer]
                                        .modules.patient.etapes === false
                                ) {
                                    actions._setValidity({ etapes: true })
                                    actions._setValidity({ coach: true })
                                }
                                //
                                actions.hydratePatientFromLocalSession()
                                actions._setState({
                                    key: 'loaded',
                                    value: true,
                                })
                            }
                        })
                    })
                }
                getUser(then)
            },
            hydratePatientFromLocalSession: () => (state, actions) => {
                let patientUniqId = sessionStorage.getItem('thf-unique_id')
                let patientIncluNum = sessionStorage.getItem(
                    'thf-inclusion_number'
                )
                let patientLastName = sessionStorage.getItem('thf-last_name')
                let patientFirstName = sessionStorage.getItem('thf-first_name')
                let patientBirthDate =
                    sessionStorage.getItem('thf-date_of_birth')
                let patientPhone = sessionStorage.getItem('thf-phone')
                let patientPrefix = sessionStorage.getItem('thf-prefix')
                let patientEmail = sessionStorage.getItem('thf-email')
                let patientMaiden = sessionStorage.getItem('thf-maiden_name')
                //
                if (isDefined(patientUniqId)) {
                    actions._setValues({ hospitalId: patientUniqId })
                    actions._setValidity({ hospitalId: true })
                }
                if (isDefined(patientIncluNum)) {
                    actions._setValues({ inclusionNumber: patientIncluNum })
                    actions._setValidity({ inclusionNumber: true })
                }
                if (isDefined(patientLastName)) {
                    actions._setValues({ lname: patientLastName })
                    actions._setValidity({ lname: true })
                }
                if (isDefined(patientFirstName)) {
                    actions._setValues({ fname: patientFirstName })
                    actions._setValidity({ fname: true })
                }
                if (isDefined(patientBirthDate)) {
                    patientBirthDate = {
                        year: patientBirthDate.split('-')[0],
                        month: patientBirthDate.split('-')[1],
                        day: patientBirthDate.split('-')[2],
                    }
                    actions._setValues({ birthdate: patientBirthDate })
                    actions._setValidity({ birthdate: true })
                }
                if (isDefined(patientPhone)) {
                    actions._setValues({ phone: patientPhone })
                    actions._setValidity({ phone: true })
                }
                if (isDefined(patientPrefix)) {
                    actions._setValues({ phonePrefix: patientPrefix })
                    actions._setValidity({ phonePrefix: true })
                }
                if (isDefined(patientEmail)) {
                    actions._setValues({ email: patientEmail })
                    actions._setValidity({ email: true })
                }
                if (isDefined(patientMaiden)) {
                    actions._setValues({ bname: patientMaiden })
                    actions._setValidity({ bname: true })
                }
                //
                sessionStorage.removeItem('thf-unique_id')
                sessionStorage.removeItem('thf-last_name')
                sessionStorage.removeItem('thf-first_name')
                sessionStorage.removeItem('thf-date_of_birth')
                sessionStorage.removeItem('thf-phone')
                sessionStorage.removeItem('thf-prefix')
                sessionStorage.removeItem('thf-email')
                sessionStorage.removeItem('thf-maiden_name')
            },
            /**
             * Validate all patient fields, if success, call [createPatient]{@link module:PatientAdd~PatientAdd:createPatient})
             * @function PatientAdd:onSubmit
             */
            onSubmit: (event) => (state, actions) => {
                event.preventDefault()
                if (state.formIsSubmitting) {
                    return false
                }
                //
                actions._setTypingFalse()
                actions._retrieveErrorMessagePatient(null)
                //
                if (
                    actions.isValidSpecificField() &&
                    state.isValid.email &&
                    state.isValid.fname &&
                    state.isValid.lname &&
                    state.isValid.birthdate &&
                    state.isValid.gender &&
                    state.isValid.phone &&
                    state.isValid.doctor &&
                    state.isValid.nurses &&
                    state.isValid.hospitalId
                ) {
                    actions._setState({ key: 'formIsSubmitting', value: true })
                    actions.createPatient()
                } else {
                    displayErrors(
                        t('Veuillez renseigner toutes les informations.'),
                        2000
                    )
                }
            },
            onAddMeThenSubmit: (event) => (state, actions) => {
                let userNurses = state.values.nurses
                let doctorId = state.doctor.id
                //
                userNurses.push(doctorId)
                actions.createPatient()
            },
            isValidSpecificField: () => (state, actions) => {
                let returnValue = true
                if (state.customer.toLowerCase().indexOf('uroconnect') === -1) {
                    if (!state.isValid.coach) {
                        returnValue = false
                    }
                }
                if (
                    state.customer.toLowerCase().indexOf('uroconnect') >= -1 ||
                    state.customer.toLowerCase().indexOf('resilience') >= -1
                ) {
                    if (!state.isValid.pathologiesType) {
                        returnValue = false
                    }
                }
                return returnValue
            },
            /**
             * Parse data following customer, then verify disponibility of email, if success call [reallyCreatePatient]{@link module:PatientAdd~PatientAdd:reallyCreatePatient})
             * @function PatientAdd:createPatient
             */
            createPatient:
                (bypassRedirect = false) =>
                (state, actions) => {
                    let parsedValues = deepCopy(state.values)
                    //
                    let doctorId = state.doctor.id
                    let userNurses = parsedValues.nurses
                    let userDoctor = parsedValues.doctor
                    let userCoach = parsedValues.coach
                    //
                    let disclamerAutoBan = true
                    if (isDefined(userDoctor) && userDoctor === doctorId) {
                        disclamerAutoBan = false
                    }
                    if (isDefined(userCoach) && userCoach === doctorId) {
                        disclamerAutoBan = false
                    }
                    if (
                        isDefined(userNurses) &&
                        userNurses.length > 0 &&
                        userNurses
                            .map((nurse) => {
                                return nurse
                            })
                            .indexOf(doctorId) > -1
                    ) {
                        disclamerAutoBan = false
                    }
                    //
                    if (
                        !state.medicalTeamUpdateConfirm &&
                        disclamerAutoBan === true
                    ) {
                        actions.setMedicalTeamUpdateConfirm(true)
                        return false
                    } else {
                        actions.setMedicalTeamUpdateConfirm(false)
                    }
                    //
                    // parsedValues.phone = (parsedValues?.phone?.length === 9) ? ('0' + parsedValues.phone) : parsedValues.phone;
                    parsedValues.birthdate =
                        parsedValues.birthdate.year +
                        '' +
                        parsedValues.birthdate.month +
                        '' +
                        parsedValues.birthdate.day
                    // parsedValues.gender = ((parsedValues.gender === 1) || (parsedValues.gender === true)) ? true : false;
                    if (state.customer === 'oncoflow') {
                        delete parsedValues.socialSecurityNumber
                    }
                    delete parsedValues.redirection
                    delete parsedValues.lyfenName
                    if (!isDefined(parsedValues?.lifenId)) {
                        delete parsedValues?.lifenId
                    }
                    //
                    let email = state.values.email
                    if (bypassRedirect) {
                        disclamerAutoBan = bypassRedirect
                    }
                    if (email !== '') {
                        User.isEmailAvailable(email).then((res) => {
                            if (
                                isDefined(res.data) &&
                                isDefined(res.data.code) &&
                                res.data.code === '102'
                            ) {
                                actions.reallyCreatePatient({
                                    parsedValues: parsedValues,
                                    disclamerAutoBan: disclamerAutoBan,
                                })
                            } else {
                                actions._setState({
                                    key: 'formIsSubmitting',
                                    value: false,
                                })
                                actions._setErrorMessages({
                                    email: t('Cet email est déjà utilisé'),
                                })
                                actions._setValidity({ email: false })
                            }
                        })
                    } else {
                        delete parsedValues.email
                        actions.reallyCreatePatient({
                            parsedValues: parsedValues,
                            disclamerAutoBan: disclamerAutoBan,
                        })
                    }
                },
            /**
             * Send all data to create new patient, then redirect to patient profil page
             * @param {object} parsedValues - Parsed patient datas
             * @function PatientAdd:reallyCreatePatient
             */
            reallyCreatePatient:
                ({ parsedValues, disclamerAutoBan }) =>
                (state, actions) => {
                    //
                    User.createTheraflowUser(parsedValues).then((res) => {
                        if (state.medicalTeamUpdateConfirm) {
                            actions._handleLeaveWithoutSaveing(false)
                            window.location.href = addCSRFToken(
                                allRoutes['private.patients.listing'].pathname
                            )
                            return false
                        }
                        //
                        if (isDefined(res.data.id)) {
                            actions._handleLeaveWithoutSaveing(false)
                            let createdPatient = res.data
                            actions._setState({
                                key: 'createdPatient',
                                value: createdPatient,
                            })
                            //
                            if (
                                !disclamerAutoBan &&
                                (createdPatient.customer.customerModules
                                    .studySurveyResult === true ||
                                    createdPatient.customer.customerModules
                                        .study === true) &&
                                (state.redirectAfterCreation === true ||
                                    state.redirectAfterCreation === '1')
                            ) {
                                actions._setState({
                                    key: 'displayedRedirectRDS',
                                    value: true,
                                })
                            } else {
                                if (disclamerAutoBan) {
                                    window.location.href = addCSRFToken(
                                        allRoutes['private.patients.listing']
                                            .pathname
                                    )
                                } else {
                                    actions.redirectToPatient({
                                        force: true,
                                        updatesettings: false,
                                    })
                                }
                            }
                            return false
                        } else {
                            actions._setState({
                                key: 'formIsSubmitting',
                                value: false,
                            })
                            let errorMessage = res.data.message
                            if (+res.data.code === 149) {
                                errorMessage = t(
                                    'Merci de renseigner un numéro de téléphone valide'
                                )
                            }
                            displayErrors(errorMessage, 3000)
                            actions._handle400(res.data)
                        }
                    })
                },
            redirectToPatient:
                ({ force = false, updatesettings = false }) =>
                (state, actions) => {
                    actions._setState({
                        key: 'displayedRedirectRDS',
                        value: false,
                    })
                    //
                    let redirect = allRoutes[
                        'private.patient.edit'
                    ].pathname.replace(':id', state.createdPatient.id)
                    if (!force) {
                        if (
                            state.createdPatient.customer.customerModules
                                .studySurveyResult === true
                        ) {
                            redirect += '?sp=survey'
                        } else if (
                            state.createdPatient.customer.customerModules
                                .study === true
                        ) {
                            redirect += '?sp=study'
                        }
                    } else {
                        redirect += '?sp=profil'
                    }
                    if (state.values.redirection && updatesettings) {
                        let settings = {
                            name: 'DOCTOR_PATIENT_REDIRECTION',
                            value: '0',
                        }
                        Admin.updateSettings([settings]).then((res) => {
                            getUser(() => {
                                window.location.href = addCSRFToken(redirect)
                            }, true)
                        })
                    } else {
                        window.location.href = addCSRFToken(redirect)
                    }
                },

            /**
             * Retrieve hospital on the fly, then call [parseHospitalHealTeam]{@link module:PatientAdd~PatientAdd:parseHospitalHealTeam})
             * @param {input} input - Contain [isvalid], [name], [id] and [values] fields
             * @function PatientAdd:retrieveHospital
             */
            retrieveHospital: (input) => (state, actions) => {
                const { name, values } = input
                if (state[name] === values) {
                    // if we are selecting the same hospital as already defined, no reset
                    return false
                }
                //
                actions._setState({ key: [name], value: values })
                //
                let refHosp = null
                for (let hosp in state.hospitals) {
                    if (state.hospitals[hosp].id === values) {
                        refHosp = state.hospitals[hosp]
                    }
                }
                //
                actions._setValues({ doctor: '' })
                actions._setValidity({ doctor: false })
                actions._setValues({ coach: '' })
                actions._setValidity({ coach: 'is-default' })
                actions._setValues({ nurses: [] })
                actions._setValidity({ nurses: 'is-default' })
                if (isDefined(refHosp)) {
                    actions.parseHospitalHealTeam(refHosp)
                }
            },
            /**
             * Parse hospital heal team following current selected hospital
             * @param {object} hospital - Hospital object
             * @function PatientAdd:parseHospitalHealTeam
             */
            parseHospitalHealTeam: (hospital) => (state, actions) => {
                let healTeam = [],
                    coachTeam = []
                for (var heal in hospital.nurses) {
                    healTeam.push({
                        label:
                            hospital.nurses[heal].fname +
                            ' ' +
                            hospital.nurses[heal].lname,
                        key: hospital.nurses[heal].id,
                        status: hospital.nurses[heal].status,
                    })
                    coachTeam.push({
                        label:
                            hospital.nurses[heal].fname +
                            ' ' +
                            hospital.nurses[heal].lname,
                        key: hospital.nurses[heal].id,
                        status: hospital.nurses[heal].status,
                    })
                }
                for (var coach in hospital.coaches) {
                    coachTeam.push({
                        label:
                            hospital.coaches[coach].fname +
                            ' ' +
                            hospital.coaches[coach].lname,
                        key: hospital.coaches[coach].id,
                        status: hospital.coaches[coach].status,
                    })
                }
                actions._setState({
                    key: 'referenceCoachTeam',
                    value: coachTeam,
                })
                actions._setState({ key: 'referenceHealTeam', value: healTeam })
            },
            addWholeTeam: () => (state, actions) => {
                if (
                    isDefined(state.values.doctor) &&
                    state.values.doctor !== ''
                ) {
                    let wholeTeam = state.referenceHealTeam
                        .filter(
                            (f) =>
                                f.key !== state.values.doctor &&
                                f.key !== state.values.coach &&
                                state.values.nurses.indexOf(f.key) === -1 &&
                                f.status === 'USER_ACTIVE'
                        )
                        .map((m) => m.key)
                    let existingTeam = state.values.nurses
                    for (let member in wholeTeam) {
                        existingTeam.push(wholeTeam[member])
                    }
                    actions._setValues({ nurses: existingTeam })
                    actions._setState({
                        key: 'addWholeDisclamerMessage',
                        value: '',
                    })
                } else {
                    actions._setState({
                        key: 'addWholeDisclamerMessage',
                        value: t(
                            'Sélectionnez le médecin référent avant d’ajouter « Tous »'
                        ),
                    })
                }
            },
            /**
             * Show/hide medical disclamer change modal
             * @function PatientAdd:setMedicalTeamUpdateConfirm
             * @param {object} newState - Target {[name]: {string}} to update
             */
            setMedicalTeamUpdateConfirm: (newState) => (state) => ({
                formIsSubmitting: newState,
                medicalTeamUpdateConfirm: newState,
            }),
            setLyfenName: (newState) => (state) => ({
                lyfenName: newState?.values,
            }),
            searchLyfenPatient: (lyfenName) => (state, actions) => {
                if (isDefined(state.lifenUser)) {
                    return false
                }
                // actions.setLyfenName(lyfenName);
                if (lyfenName.length > 1) {
                    if (!isNaN(lyfenName.charAt(0))) {
                        User.getLyfenUserById(lyfenName).then((res) => {
                            actions._setState({
                                key: 'lyfenUsers',
                                value: res.data,
                            })
                            actions.handleLyfenError(res)
                        })
                    } else {
                        User.getLyfenUserByName(lyfenName).then((res) => {
                            actions._setState({
                                key: 'lyfenUsers',
                                value: res.data,
                            })
                            actions.handleLyfenError(res)
                        })
                    }
                } else {
                    // nop
                }
            },
            handleLyfenError: (response) => (state, actions) => {
                if (
                    isDefined(response.data) &&
                    isDefined(response.data.code) &&
                    +response.data.code === 2602
                ) {
                    displayErrors(
                        t(
                            'Recherche indisponible. Utilisez la création manuelle ou réessayez ultérieurement. Si le problème persiste, contactez le support.'
                        ),
                        3500
                    )
                }
            },
            hydratePatientFromLifen: (selected) => (state, actions) => {
                if (isDefined(selected?.values, true, true)) {
                    let lifenUser = state.lyfenUsers.filter(
                        (f) => f.lifenId === selected?.values
                    )[0]
                    actions._setState({ key: 'lifenUser', value: lifenUser })
                    actions.setLyfenName('')
                    actions._setState({ key: 'lyfenUsers', value: [] })
                    actions._setState({
                        key: 'displayedManualAdd',
                        value: true,
                    })
                    if (isDefined(lifenUser)) {
                        if (isDefined(lifenUser.lifenId, true, true)) {
                            actions._setValues({ lifenId: lifenUser.lifenId })
                            actions._setValidity({ lifenId: true })
                        }
                        if (isDefined(lifenUser.firstName, true, true)) {
                            actions._setValues({ fname: lifenUser.firstName })
                            actions._setValidity({ fname: true })
                        }
                        if (isDefined(lifenUser.lastName, true, true)) {
                            actions._setValues({ lname: lifenUser.lastName })
                            actions._setValidity({ lname: true })
                        }
                        if (isDefined(lifenUser.maiden, true, true)) {
                            actions._setValues({ bname: lifenUser.maiden })
                            actions._setValidity({ bname: true })
                        } else {
                            actions._setValues({ bname: '' })
                            actions._setValidity({ bname: false })
                        }
                        if (isDefined(lifenUser.email, true, true)) {
                            actions._setValues({ email: lifenUser.email })
                            actions._setValidity({ email: true })
                        } else {
                            actions._setValues({ email: '' })
                            actions._setValidity({ email: false })
                        }
                        if (isDefined(lifenUser.gender, true, true)) {
                            actions._setValues({ gender: lifenUser.gender })
                            actions._setValidity({ gender: true })
                        }
                        if (isDefined(lifenUser.phone, true, true)) {
                            actions._setValues({ phone: lifenUser.phone })
                            actions._setValidity({ phone: true })
                        } else {
                            actions._setValues({ phone: '' })
                            actions._setValidity({ phone: false })
                        }
                        if (isDefined(lifenUser.prefix, true, true)) {
                            actions._setValues({
                                phonePrefix: lifenUser.prefix,
                            })
                            actions._setValidity({ phonePrefix: true })
                        }
                        if (
                            isDefined(
                                lifenUser.socialSecurityNumber,
                                true,
                                true
                            )
                        ) {
                            actions._setValues({
                                socialSecurityNumber:
                                    lifenUser.socialSecurityNumber,
                            })
                            actions._setValidity({ socialSecurityNumber: true })
                        } else {
                            actions._setValues({ socialSecurityNumber: '' })
                            actions._setValidity({
                                socialSecurityNumber: false,
                            })
                        }
                        if (isDefined(lifenUser.ipp, true, true)) {
                            actions._setValues({ hospitalId: lifenUser.ipp })
                            actions._setValidity({ hospitalId: true })
                        }
                        if (isDefined(lifenUser.birthDate?.date, true, true)) {
                            let lifenUserBD =
                                lifenUser.birthDate.date.split(' ')[0]
                            lifenUserBD = {
                                year: lifenUserBD.split('-')[0],
                                month: lifenUserBD.split('-')[1],
                                day: lifenUserBD.split('-')[2],
                            }
                            actions._setValues({ birthdate: lifenUserBD })
                            actions._setValidity({ birthdate: true })
                        }
                        //
                    }
                } else {
                    actions._setState({ key: 'lifenUser', value: null })
                    actions.setLyfenName('')
                    actions._setValues({ gender: null })
                    actions._setValidity({ gender: false })
                    actions._setValues({ fname: '' })
                    actions._setValidity({ fname: false })
                    actions._setValues({ lname: '' })
                    actions._setValidity({ lname: false })
                    actions._setValues({ bname: '' })
                    actions._setValidity({ bname: false })
                    actions._setValues({ email: '' })
                    actions._setValidity({ email: false })
                    actions._setValues({ phone: '' })
                    actions._setValidity({ phone: false })
                    actions._setValues({ birthdate: '' })
                    actions._setValidity({ birthdate: false })
                    actions._setValues({ lifenId: '' })
                    actions._setValidity({ lifenId: null })
                    actions._setValues({ socialSecurityNumber: '' })
                    actions._setValidity({ socialSecurityNumber: false })
                    actions._setValues({ hospitalId: '' })
                    actions._setValidity({ hospitalId: false })
                }
                actions._setValues({ nurses: [] })
                actions._setValidity({ nurses: false })
                actions._setValues({ doctor: '' })
                actions._setValidity({ doctor: false })
                actions._setValues({ pathology: '' })
                actions._setValidity({ pathology: false })
                actions._setValues({ pathologiesType: [] })
                actions._setValidity({ pathologiesType: false })
            },
        }
        PatientAdd = { ...coreFn, ...PatientAdd }
        return PatientAdd
    },
}
