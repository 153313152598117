import { h } from 'hyperapp';
import { Component } from '@app/utils';
import './index.scss';

import IndexLayout from '@app/layouts/pages/layout';

import Edit from './edit';

const state = {
    email: {
        value: '',
        isValid: false,
        isApproved: null,
        isResetted: null
    },
    password: {
        value: '',
        isValid: false,
        isApproved: null,
        isResetted: null
    }
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        // localStorage.clear();
    },
    setState: ({ ns, key, value }) => state => ({
        [ns]: { ...state[ns], ...{ [key]: value } },
    })
};

const view = (state, actions) => ({ location, match, customer }, children) => (
    <div key='account' class='root-wrapper'>
        <IndexLayout page={'account'} customer={customer}>
            {/* Default view */}
            {(location.pathname.indexOf('/account/edit') > -1) && (
                <Edit initial={state.email} customer={customer} match={match} send={actions.setState} />
            )}
        </IndexLayout>
    </div>
);

export {
    Edit,
};

export default Component(state, actions, view, 'account');
