import { settings } from '@app/core/settings';
import { handleErrors } from '@app/api/errors';
import { queueQueries } from '@app/api/controlleur';

const { API_URL } = settings;

function getTheraflowUserById(id) {
    return queueQueries(`${API_URL}/theraflow/users/${id}/`, 'GET');
}
function getLyfenUserByName(name) {
    return queueQueries(`${API_URL}/lifen/patient/name/${name}`, 'GET');
}
function getLyfenUserById(id) {
    return queueQueries(`${API_URL}/lifen/patient/id/${id}`, 'GET');
}
function getLyfenUserByLifenId(id) {
    return queueQueries(`${API_URL}/lifen/patient/lifenid/${id}`, 'GET');
}
function blockTheraflowUser(id) {
    return queueQueries(`${API_URL}/theraflow/users/${id}/block/`, 'GET');
}
function searchTheraflowUser(page = 0, sorts = {}, searchval = '') {
    return queueQueries(`${API_URL}/theraflow/users/search/${page}/`, 'POST', 'application/json', true, true, true, true, true, {search: searchval, ...sorts});
}

function putUser(id, data) {
    return queueQueries(`${API_URL}/user/${id}/`, 'PUT', 'application/json', true, true, true, true, true, data);
}

function isEmailAvailable(email) {
    return queueQueries(`${API_URL}/user/checkemail/`, 'POST', 'application/json', true, true, true, true, true, {email: email});
}

function resiliateUser(id) {
    return queueQueries(`${API_URL}/admin/users/${id}/resiliate/`, 'POST');
}

function createTheraflowUser(data) {
    return queueQueries(`${API_URL}/theraflow/users/`, 'POST', 'application/json', true, true, true, true, true, data);
}

function updateTheraflowUser(id, data) {
    return queueQueries(`${API_URL}/theraflow/users/${id}/`, 'PUT', 'application/json', true, true, true, true, true, data);
}

function updateTheraflowUserSettings(id, data) {
    return queueQueries(`${API_URL}/theraflow/users/${id}/settings/`, 'PUT', 'application/json', true, true, true, true, true, data);
}

function checkPatientId(id) {
    return queueQueries(`${API_URL}/theraflow/users/check-hospital-id/${id}/`, 'GET');
}

function getQrCode(id) {
    return queueQueries(`${API_URL}/theraflow/users/${id}/qrcode/`, 'GET');
}
function getQrCodeImage(id) {
    return queueQueries(`${API_URL}/theraflow/users/${id}/qrcode/image/?xtoken=` + localStorage.getItem('apitoken'), 'GET');
}

function getAlerts(id) {
    return queueQueries(`${API_URL}/theraflow/user-alerts/${id}/`, 'GET');
}

function updateThemes(id, data) {
    return queueQueries(`${API_URL}/theraflow/users/${id}/themes/`, 'POST', 'application/json', true, true, true, true, true, data);
}

function updateMonitoring(id, data) {
    return queueQueries(`${API_URL}/theraflow/users/${id}/monitoring/`, 'PUT', 'application/json', true, true, true, true, true, data);
}

function getInfos(id) {
    return queueQueries(`${API_URL}/theraflow/user/${id}/infos/`, 'GET');
}

function getTimezone() {
    return queueQueries(`${API_URL}/timezones/`, 'GET');
}

function getETPProgramsAvailableByUserID(id) {
    return queueQueries(`${API_URL}/theraflow/users/program-etp/${id}/available/`, 'GET');
}
function getETPProgramsByUserID(id) {
    return queueQueries(`${API_URL}/theraflow/users/program-etp/${id}/`, 'GET');
}
function updateETPProgramsByUserID(id, data) {
    return queueQueries(`${API_URL}/theraflow/users/program-etp/${id}/`, 'POST', 'application/json', true, true, true, true, true, data);
}
function deleteETPProgramsByUserID(id, idprogram) {
    return queueQueries(`${API_URL}/theraflow/users/program-etp/${id}/${idprogram}/`, 'DELETE');
}

export const User = {
    getTheraflowUserById,
    getLyfenUserByName,
    getLyfenUserById,
    getLyfenUserByLifenId,
    blockTheraflowUser,
    searchTheraflowUser,
    putUser,
    isEmailAvailable,
    resiliateUser,
    createTheraflowUser,
    updateTheraflowUser,
    updateTheraflowUserSettings,
    checkPatientId,
    getQrCode,
    getQrCodeImage,
    getAlerts,
    updateThemes,
    updateMonitoring,
    getInfos,
    getTimezone,
    getETPProgramsAvailableByUserID,
    getETPProgramsByUserID,
    updateETPProgramsByUserID,
    deleteETPProgramsByUserID
};