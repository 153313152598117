import { t } from 'i18next'
import { h } from 'hyperapp'
import { isDefined, getClosest } from '@app/core'
import { Card, Button, Tile, Enter, Modal } from '@app/elements'
import * as constants from '@app/constants'
import * as rds from '@app/constants/constants-rds'

import icoTreatment from '@app/img/rds/surveys/treatment.png'
import icoTreatmentx2 from '@app/img/rds/surveys/treatment@2x.png'
import icoSurvey from '@app/img/rds/surveys/surveillance.png'
import icoSurveyx2 from '@app/img/rds/surveys/surveillance@2x.png'
import icoTreatOfSurvey from '@app/img/rds/surveys/treatorsurvey.png'
import icoTreatOfSurveyx2 from '@app/img/rds/surveys/treatorsurvey@2x.png'
import icoRemote from '@app/img/rds/surveys/remote.png'
import icoRemotex2 from '@app/img/rds/surveys/remote@2x.png'

import icoSend from '@app/img/ico/ico-send-white.png'
import icoSendx2 from '@app/img/ico/ico-send-white@2x.png'

import icoArrow from '@app/img/ico/ico-arrow-blue-right.png'
import icoArrowx2 from '@app/img/ico/ico-arrow-blue-right@2x.png'
import icoArrowGrey from '@app/img/ico/ico-arrow-grey-right.png'
import icoArrowGreyx2 from '@app/img/ico/ico-arrow-grey-right@2x.png'
import icoArrowWhite from '@app/img/ico/ico-arrow-white-right.png'
import icoArrowWhitex2 from '@app/img/ico/ico-arrow-white-right@2x.png'

import icoInterrogation from '@app/img/ico/ico-interrogation.png'
import icoInterrogationx2 from '@app/img/ico/ico-interrogation@2x.png'
import icoInterrogationDarkBlue from '@app/img/ico/ico-interrogation-darkblue.png'
import icoInterrogationDarkBluex2 from '@app/img/ico/ico-interrogation-darkblue@2x.png'

import { ModalViewSurveyPreview } from './../../modals/modalView-surveyPreview-default'
import { ModalViewSurvey } from './../../modals/modalView-survey-default'
import { ModalViewSwitchPhase } from './../../modals/modalView-switchPhase-default'

export const ViewConfiguration = (props, actions) => (
    <Card
        classes={'btzPageCard btzBackgroundCover'}
        kind={'datas'}
        style={{ margin: '30px 30px 0px 30px' }}
    >
        <Card.Header classes={'btzCard-btzHeader-btzPage'}>
            <Enter
                time={constants.custo.ANIM_DURATION}
                easing={constants.custo.ANIM_EASING}
                css={constants.custo.ANIM_CSS}
            >
                <dummy>
                    <Tile>
                        <Tile.Content classes={'rdsCong-listingOrgans'}>
                            <div class="btzCard-btzHeaderWrapper">
                                <p class="btzTitle btzXl">
                                    {t("Configurer l'envoi des questionnaires")}
                                </p>
                                <p
                                    class="btzSubTitle"
                                    style="margin-bottom: 30px;"
                                >
                                    {'1. ' +
                                        t(
                                            "Choisissez un organe ou un groupe d'organes."
                                        )}
                                </p>
                            </div>
                            <div class="rdsCong-listingOrgans">
                                {props.repeatableMedicalInfos
                                    .sort((x, y) =>
                                        x.label === y.label
                                            ? 0
                                            : x.label < y.label
                                            ? -1
                                            : 1
                                    )
                                    .map((m, idx) => (
                                        <div
                                            key={'organ-' + m.name + '-' + idx}
                                            class="col-2 col-md-6 col-xs-12"
                                        >
                                            <div
                                                class={
                                                    'rdsOrgan ' +
                                                    (isDefined(
                                                        props.selectedOrgan
                                                    ) &&
                                                    props.selectedOrgan.key ===
                                                        m.key
                                                        ? 'active'
                                                        : '')
                                                }
                                                onclick={() => {
                                                    props.selectThisOrgan(m)
                                                }}
                                            >
                                                <div
                                                    class="rdsImg"
                                                    data-nsurvey={
                                                        props.medicalStudies.filter(
                                                            (f) =>
                                                                f.organ ===
                                                                    m.key &&
                                                                f.status
                                                        ).length
                                                    }
                                                >
                                                    <img
                                                        alt={`Organe ${m.label}`}
                                                        class=""
                                                        src={m.img.normal}
                                                        srcset={`${m.img.retina} 2x`}
                                                    />
                                                </div>
                                                <p class="">{t(m.label)}</p>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </Tile.Content>
                        {isDefined(props.selectedOrgan) && (
                            <Tile.Content classes={'rdsCong-scrollMarker'}>
                                <div class="btzCard-btzHeaderWrapper">
                                    <p
                                        class="btzSubTitle"
                                        style="margin-bottom: 30px;"
                                    >
                                        {'2. ' +
                                            t(
                                                'Choisissez le questionnaire le plus adapté à la situation de votre patient.'
                                            )}
                                    </p>
                                </div>
                                <div class="rdsCong-listingTreatments">
                                    {props.selectedOrgan.surveys
                                        .sort((x, y) =>
                                            x.phase === y.phase
                                                ? 0
                                                : x.phase < y.phase
                                                ? -1
                                                : 1
                                        )
                                        .sort((x, y) =>
                                            x.usualName === y.usualName
                                                ? 0
                                                : x.usualName < y.usualName
                                                ? -1
                                                : 1
                                        )
                                        .map((m) => (
                                            <div class="rdsCong-listingTreatment">
                                                <div
                                                    class={
                                                        'rdsTreatment ' +
                                                        (isDefined(
                                                            props.medicalStudies.filter(
                                                                (f) =>
                                                                    f.name ===
                                                                    m.name
                                                            )[0]
                                                        ) &&
                                                        isDefined(
                                                            props.medicalStudies.filter(
                                                                (f) =>
                                                                    f.name ===
                                                                    m.name
                                                            )[0].key
                                                        )
                                                            ? props.medicalStudies.filter(
                                                                  (f) =>
                                                                      f.name ===
                                                                      m.name
                                                              )[0].status ===
                                                              false
                                                                ? 'deactivated'
                                                                : 'active'
                                                            : '')
                                                    }
                                                    onclick={() => {
                                                        props.activateThisTreatment(
                                                            m
                                                        )
                                                    }}
                                                >
                                                    <div class="rdsTreatmentHeader">
                                                        <div
                                                            class={
                                                                'rdsSurveyHeaderLeft rdsSurveyHeaderLeftTreatment ' +
                                                                (isDefined(
                                                                    props.medicalStudies.filter(
                                                                        (f) =>
                                                                            f.name ===
                                                                            m.name
                                                                    )[0]
                                                                )
                                                                    ? 'active'
                                                                    : '')
                                                            }
                                                        >
                                                            {m.phase ===
                                                                'TREATMENT' && (
                                                                <img
                                                                    alt={
                                                                        'Traitement'
                                                                    }
                                                                    src={
                                                                        icoTreatment
                                                                    }
                                                                    srcset={`${icoTreatmentx2} 2x`}
                                                                />
                                                            )}
                                                            {m.phase ===
                                                                'MONITORING' && (
                                                                <img
                                                                    alt={
                                                                        'Questionnaire'
                                                                    }
                                                                    src={
                                                                        icoSurvey
                                                                    }
                                                                    srcset={`${icoSurveyx2} 2x`}
                                                                />
                                                            )}
                                                            {m.phase ===
                                                                'TREATMENT_OR_MONITORING' && (
                                                                <img
                                                                    alt={
                                                                        'Traitement ou monitoring'
                                                                    }
                                                                    src={
                                                                        icoTreatOfSurvey
                                                                    }
                                                                    srcset={`${icoTreatOfSurveyx2} 2x`}
                                                                />
                                                            )}
                                                            {m.phase ===
                                                                'REMOTE_MONITORING' && (
                                                                <img
                                                                    alt={
                                                                        'Monitoring'
                                                                    }
                                                                    src={
                                                                        icoRemote
                                                                    }
                                                                    srcset={`${icoRemotex2} 2x`}
                                                                />
                                                            )}
                                                        </div>
                                                        <div class="rdsTreatmentHeaderRight">
                                                            {m.phase ===
                                                                'TREATMENT' && (
                                                                <p class="btzTitle">
                                                                    {t(
                                                                        'Traitement'
                                                                    )}
                                                                </p>
                                                            )}
                                                            {m.phase ===
                                                                'MONITORING' && (
                                                                <p class="btzTitle">
                                                                    {t(
                                                                        'Surveillance'
                                                                    )}
                                                                </p>
                                                            )}
                                                            {m.phase ===
                                                                'TREATMENT_OR_MONITORING' && (
                                                                <p class="btzTitle">
                                                                    {t(
                                                                        'Traitement ou Surveillance'
                                                                    )}
                                                                </p>
                                                            )}
                                                            {m.phase ===
                                                                'REMOTE_MONITORING' && (
                                                                <p class="btzTitle">
                                                                    {t(
                                                                        'Télésurveillance'
                                                                    )}
                                                                </p>
                                                            )}
                                                            <div class="rdsSurveyHeaderWrapper">
                                                                <p class="btzSubTitle">
                                                                    {t(
                                                                        m.usualName
                                                                    )}
                                                                </p>
                                                                <div class="rdsTreatmentHeaderRight-infos">
                                                                    <img
                                                                        alt={
                                                                            'Informations'
                                                                        }
                                                                        tabindex="1"
                                                                        class="btzLightSmallPopupShower"
                                                                        src={
                                                                            props.customer ===
                                                                            'resilience'
                                                                                ? icoInterrogationDarkBlue
                                                                                : icoInterrogation
                                                                        }
                                                                        srcset={`${
                                                                            props.customer ===
                                                                            'resilience'
                                                                                ? icoInterrogationDarkBluex2
                                                                                : icoInterrogationx2
                                                                        } 2x`}
                                                                        onclick={(
                                                                            el
                                                                        ) => {
                                                                            el.target.nextSibling.style.display =
                                                                                null
                                                                        }}
                                                                    />
                                                                    <div class="rdsTreatmentHeaderInfo btzLightSmallPopup">
                                                                        <div class="btzLightSmallPopup-content">
                                                                            <p class="btzSmallPopupTitle">
                                                                                {t(
                                                                                    m.usualName
                                                                                )}
                                                                            </p>
                                                                            <div class="btzSmallPopupContent">
                                                                                <p class="btzSmallPopupLabel">
                                                                                    {t(
                                                                                        'Indication clinique'
                                                                                    ) +
                                                                                        ' :'}
                                                                                </p>
                                                                                <p class="btzSmallPopupContentText">
                                                                                    {t(
                                                                                        m.clinicalIndication
                                                                                    )}
                                                                                </p>
                                                                                <p
                                                                                    class="btzSmallPopupLightButton"
                                                                                    style="display: block !important; margin: 15px auto;"
                                                                                    onclick={() => {
                                                                                        props.retrieveSurveyContent(
                                                                                            m
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    {t(
                                                                                        'Consulter le questionnaire'
                                                                                    )}
                                                                                </p>
                                                                                <div class="displayed-if-deactivated">
                                                                                    <p
                                                                                        class="displayed-if-deactivated text-dangerous"
                                                                                        style="margin-left: 0px;"
                                                                                    >
                                                                                        {t(
                                                                                            'Envois arrêtés'
                                                                                        )}
                                                                                    </p>
                                                                                    {
                                                                                        ' - '
                                                                                    }
                                                                                    <p
                                                                                        class="btzSmallPopupLightButton"
                                                                                        onclick={() => {
                                                                                            props.retrieveStudy(
                                                                                                m
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        {t(
                                                                                            'Réactiver les envois'
                                                                                        )}
                                                                                    </p>
                                                                                </div>
                                                                                <div class="displayed-if-active ">
                                                                                    <p
                                                                                        class="thfColored-green"
                                                                                        style="margin-left: 0px;"
                                                                                    >
                                                                                        {t(
                                                                                            'Envois en cours'
                                                                                        )}
                                                                                    </p>
                                                                                    {
                                                                                        ' - '
                                                                                    }
                                                                                    <p
                                                                                        class="btzSmallPopupLightButton"
                                                                                        onclick={() => {
                                                                                            props.retrieveStudy(
                                                                                                m
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        {t(
                                                                                            'Modifier'
                                                                                        )}
                                                                                    </p>
                                                                                </div>
                                                                                <p
                                                                                    class="btzSmallPopupLightButton hidden-if-active hidden-if-deactivated"
                                                                                    onclick={() => {
                                                                                        props._setState(
                                                                                            {
                                                                                                key: 'newSurvey',
                                                                                                value: true,
                                                                                            }
                                                                                        )
                                                                                        props.retrieveStudy(
                                                                                            m
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    {t(
                                                                                        'Activer les envois'
                                                                                    )}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="btzLightSmallPopup-footer">
                                                                            <Button
                                                                                cancel
                                                                                flat
                                                                                onclick={(
                                                                                    el
                                                                                ) => {
                                                                                    getClosest(
                                                                                        el.target,
                                                                                        '.btzLightSmallPopup'
                                                                                    ).style.display =
                                                                                        'none'
                                                                                }}
                                                                            >
                                                                                {t(
                                                                                    'Fermer'
                                                                                )}
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p class="thfColored-green displayed-if-active">
                                                                    {t(
                                                                        'Envois en cours'
                                                                    )}
                                                                </p>
                                                                <p class="text-dangerous displayed-if-deactivated">
                                                                    {t(
                                                                        'Envois arrêtés'
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="rdsTreatmentContext">
                                                        {m.tags.map((c) => (
                                                            <div class="">
                                                                {isDefined(
                                                                    rds.RDSTagsMapping.filter(
                                                                        (f) =>
                                                                            f.key ===
                                                                            c
                                                                    )[0]
                                                                )
                                                                    ? rds.RDSTagsMapping.filter(
                                                                          (f) =>
                                                                              f.key ===
                                                                              c
                                                                      )[0].label
                                                                    : c}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div
                                                        class="displayed-if-active"
                                                        style="display: inline-block;width: auto;margin-top: 15px;"
                                                    >
                                                        <Button
                                                            successed
                                                            onclick={() => {
                                                                props.retrieveStudy(
                                                                    m
                                                                )
                                                            }}
                                                        >
                                                            <img
                                                                alt={
                                                                    'Modifier les envois'
                                                                }
                                                                style="vertical-align: middle;margin: -2px 10px 0 -10px;"
                                                                src={
                                                                    icoArrowWhite
                                                                }
                                                                srcset={`${icoArrowWhitex2} 2x`}
                                                            />
                                                            {t(
                                                                'Modifier les envois'
                                                            )}
                                                        </Button>
                                                    </div>
                                                    <div
                                                        class="hidden-if-active"
                                                        style="display: inline-block;width: auto;margin-top: 15px;"
                                                    >
                                                        <Button
                                                            active
                                                            onclick={() => {
                                                                props._setState(
                                                                    {
                                                                        key: 'newSurvey',
                                                                        value: true,
                                                                    }
                                                                )
                                                                props.retrieveStudy(
                                                                    m
                                                                )
                                                            }}
                                                        >
                                                            {isDefined(
                                                                props.medicalStudies.filter(
                                                                    (f) =>
                                                                        f.name ===
                                                                        m.name
                                                                )[0]
                                                            ) ? (
                                                                <dummy style="color: white;">
                                                                    <img
                                                                        alt={
                                                                            'Reactiver les envois'
                                                                        }
                                                                        style="vertical-align: middle;margin: -2px 10px 0 -10px;"
                                                                        src={
                                                                            icoSend
                                                                        }
                                                                        srcset={`${icoSendx2} 2x`}
                                                                    />
                                                                    {t(
                                                                        'Réactiver les envois'
                                                                    )}
                                                                </dummy>
                                                            ) : (
                                                                <dummy style="color: white;">
                                                                    <img
                                                                        alt={
                                                                            'Activer les envois'
                                                                        }
                                                                        style="vertical-align: middle;margin: -2px 10px 0 -10px;"
                                                                        src={
                                                                            icoSend
                                                                        }
                                                                        srcset={`${icoSendx2} 2x`}
                                                                    />
                                                                    {t(
                                                                        'Activer les envois'
                                                                    )}
                                                                </dummy>
                                                            )}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    {props.summaryDisplayed && (
                                        <Modal
                                            key={'modal-survey-survey'}
                                            cancelAction={() => {
                                                props._setState({
                                                    key: 'newSurvey',
                                                    value: false,
                                                })
                                                props._setState({
                                                    key: 'summaryDisplayed',
                                                    value: false,
                                                })
                                                props.onCancelEditSurvey()
                                            }}
                                            modalDisplayed={true}
                                            content={
                                                <ModalViewSurvey
                                                    displayed={'survey'}
                                                    {...props}
                                                    retrieveInput={
                                                        props.retrieveInput
                                                    }
                                                    onSubmit={props.onSaveStudy}
                                                    cancelAction={() => {
                                                        props._setState({
                                                            key: 'newSurvey',
                                                            value: false,
                                                        })
                                                        props._setState({
                                                            key: 'summaryDisplayed',
                                                            value: false,
                                                        })
                                                        props.onCancelEditSurvey()
                                                    }}
                                                    loaded={true}
                                                    summaryDisplayed={
                                                        props.summaryDisplayed
                                                    }
                                                    newSurvey={props.newSurvey}
                                                ></ModalViewSurvey>
                                            }
                                        ></Modal>
                                    )}
                                </div>
                                {props.surveyPreviewDisplayed && (
                                    <Modal
                                        key={'modal-medical-confirm'}
                                        customClass="modal-central modal-previewsurvey"
                                        cancelAction={() => {
                                            props._setState({
                                                key: 'surveyPreviewDisplayed',
                                                value: false,
                                            })
                                        }}
                                        modalDisplayed={true}
                                        content={
                                            <ModalViewSurveyPreview
                                                displayed={'medicalconfirm'}
                                                {...props}
                                                cancelAction={() => {
                                                    props._setState({
                                                        key: 'surveyPreviewDisplayed',
                                                        value: false,
                                                    })
                                                }}
                                                loaded={true}
                                            ></ModalViewSurveyPreview>
                                        }
                                    ></Modal>
                                )}
                            </Tile.Content>
                        )}
                        {isDefined(props.medicalStudies) &&
                            props.medicalStudies.length > 0 && (
                                <div style="display: inline-block;width: auto;margin: 15px 0 0 15px;">
                                    <Button
                                        flat
                                        cancel
                                        onclick={props.backToSurvey}
                                    >
                                        {['resilience'].indexOf(
                                            props.customer
                                        ) > -1 ? (
                                            <img
                                                alt={
                                                    'Retour au rapport de santé'
                                                }
                                                style="vertical-align: bottom;margin: 0 8px 0 -4px;"
                                                src={icoArrowGrey}
                                                srcset={`${icoArrowGreyx2} 2x`}
                                            />
                                        ) : (
                                            <img
                                                alt={
                                                    'Retour au rapport de santé'
                                                }
                                                style="vertical-align: bottom;margin: 0 8px 0 -4px;"
                                                src={icoArrow}
                                                srcset={`${icoArrowx2} 2x`}
                                            />
                                        )}
                                        {t('Retour au rapport de santé')}
                                    </Button>
                                </div>
                            )}
                        {props.switchPhase && (
                            <Modal
                                key={'modal-medical-confirm'}
                                customClass="modal-central modal-switchphase"
                                cancelAction={() => {
                                    props.cancelPhaseSwitching()
                                }}
                                modalDisplayed={true}
                                content={
                                    <ModalViewSwitchPhase
                                        displayed={'medicalconfirm'}
                                        {...props}
                                        cancelAction={() => {
                                            props.cancelPhaseSwitching()
                                        }}
                                        loaded={true}
                                    ></ModalViewSwitchPhase>
                                }
                            ></Modal>
                        )}
                    </Tile>
                </dummy>
            </Enter>
        </Card.Header>
    </Card>
)
