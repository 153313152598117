import { Consent } from '@app/api'
import {
    isDefined,
    redirectToGoodIndex,
    coreFn,
    getSupport,
    parseJsonObjectToString,
} from '@app/core'

/**
 * @module LoginAuthentication
 * @description Login page
 */

export default {
    initAction: function () {
        let ConsentObj = {
            onComponentCreate: (props) => (state, actions) => {
                actions.setSupport(getSupport())
                Consent.getPendingUserLegalAgreement('doctor').then((res) => {
                    let legalDocument = res.data.legalDocuments
                    if (
                        Array.isArray(legalDocument) &&
                        legalDocument.length > 0
                    ) {
                        actions.setTitle(res.data.title)
                        actions.setDescription(res.data.description)
                        //
                        actions.setAgreements({
                            terms: legalDocument.filter(
                                (f) => f.type === 'terms'
                            )[0],
                            privacy: legalDocument.filter(
                                (f) => f.type === 'privacy'
                            )[0],
                        })
                        actions.setAgreementToAprove(true)
                    } else {
                        localStorage.setItem('userconsent', true)
                        Consent.getLastUserLegalAgreement('doctor').then(
                            (res) => {
                                let userTerms = res?.data?.filter(
                                    (f) => f.type === 'terms'
                                )?.[0]
                                if (isDefined(userTerms)) {
                                    localStorage.setItem(
                                        'terms',
                                        parseJsonObjectToString(userTerms)
                                    )
                                }
                                //
                                let userPrivacy = res?.data?.filter(
                                    (f) => f.type === 'privacy'
                                )?.[0]
                                if (isDefined(userPrivacy)) {
                                    localStorage.setItem(
                                        'privacy',
                                        parseJsonObjectToString(userPrivacy)
                                    )
                                }
                                //
                                redirectToGoodIndex(window.location.search)
                            }
                        )
                    }
                })
            },
            /**
             * Accept or decline agreement
             * @function Consent:onAcceptAgreement
             * @param {boolean} accept - Boolean to accept or decline
             */
            onAcceptAgreement: (accept) => (state, actions) => {
                let nSend = 0
                if (state.agreements.terms.pending) {
                    nSend++
                    if (state.agreements.privacy.pending) {
                        nSend++
                    }
                    Consent.postUserLegalAgreement(
                        state.agreements.terms.id,
                        accept
                    ).then(() => {
                        nSend--
                        if (accept && nSend === 0) {
                            redirectToGoodIndex(window.location.search)
                        }
                    })
                } else {
                    nSend++
                }
                //
                if (state.agreements.privacy.pending) {
                    Consent.postUserLegalAgreement(
                        state.agreements.privacy.id,
                        accept
                    ).then(() => {
                        nSend--
                        if (accept && nSend === 0) {
                            redirectToGoodIndex(window.location.search)
                        }
                    })
                }
            },
            onCancel: () => (state, actions) => {
                actions.setRefused(true)
            },
            onSubmit: () => (state, actions) => {
                actions.setRefused(false)
            },
            setAgreements: (newState) => (state) => ({
                agreements: newState,
            }),
            setRefused: (newState) => (state) => ({
                refused: newState,
            }),
            displayPDC: () => (state) => ({
                modalEditing: 'document-pdc',
                modalDisplayed: true,
            }),
            onHidePDC: () => (state, actions) => ({
                modalDisplayed: false,
            }),
            displayCGU: () => (state) => ({
                modalEditing: 'document-cgu',
                modalDisplayed: true,
            }),
            onHideCGU: () => (state, actions) => ({
                modalDisplayed: false,
            }),
            setSupport: (newState) => (state, actions) => ({
                support: newState,
            }),
            setTitle: (newState) => (state, actions) => ({
                title: newState,
            }),
            setDescription: (newState) => (state, actions) => ({
                description: newState,
            }),
            setAgreementToAprove: (newState) => (state, actions) => ({
                agreementToAprove: newState,
            }),
        }
        ConsentObj = { ...coreFn, ...ConsentObj }
        return ConsentObj
    },
}
