import { t } from 'i18next';
import { h } from 'hyperapp';
import { isDefined } from '@app/core';
import { Card, Enter } from '@app/elements';
import * as constants from '@app/constants';

import Profil from './../subpages/profil';

export default {
    initView: function() {
        let AccountView = (state, actions) => (props, children) => (
            <div class=''>
                {state.loaded &&
                    <dummy>
                        <Card classes={'btzPageCard btzBackgroundCover'} kind={'datas'} style={{ 'padding-bottom': '10px' }}>
                            <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                                <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
                                    <div class='btzCard-btzHeader-btzPage-btzWrapper'>
                                        {isDefined(state.doctor) &&
                                            <div class='btzCard-btzHeader-btzPage-btzInfo' style='max-height: 71px;'>
                                                <div class='col-5 col-md-12 btzCard-btzHeader-topMargin' style='padding-left: 0px;vertical-align: top;'>
                                                    <div class='btzCard-btzHeader-btzPage-btzNamePuce'><p>{(isDefined(state.doctor.lname, true) ? state.doctor.lname.substring(0, 1).toUpperCase() : '') + (isDefined(state.doctor.fname, true) ? state.doctor.fname.substring(0, 1).toUpperCase() : '')}</p></div>
                                                    <div class='btzCard-btzHeader-btzPage-btzName' style='display: inline-block;max-width: 75%;'><p>{state.doctor.lname + ' ' + state.doctor.fname.toUpperCase()}</p></div>
                                                </div>
                                                <div class='col-7 col-md-12 btzCard-btzHeader-topMargins' style='margin: 10px 0px;white-space: normal;text-align: left;float: none;'>
                                                    <div class='btzCard-btzHeader-btzPage-btzInfoDevided'>
                                                        <p class='btzCard-btzHeader-btzPage-btzInfoTitle'>{state.doctor.customer}</p>
                                                        <p class='btzCard-btzHeader-btzPage-btzInfoSubTitle' style='display: block;'>{((isDefined(state.refHospital) && isDefined(state.refHospital.parent)) ? state.refHospital.parent.name : t('Hopital de référence'))}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </Enter>
                            </Card.Header>
                        </Card>

                        <div key={state.currentPage}>
                            <Profil {...state} updateDoctor={actions.getDoctor} />
                        </div>
                    </dummy>
                }
            </div>
        );
        return AccountView;
    }
}