jest.mock('i18next', () => ({
    t: (str) => {
        return str
    },
}))

// https://stackoverflow.com/questions/65282181/how-to-use-jest-for-testing-a-react-component-with-localstorage
const storagePrototype = {
    getItem: function (key) {
        return localStorageMock[key] || null
    },
    setItem: function (key, value) {
        if (!localStorageMock[key]) {
            this.length++
        }
        localStorageMock[key] = value.toString()
    },
    removeItem: function (key) {
        if (localStorageMock[key]) {
            this.length--
        }
        delete localStorageMock[key]
    },
    clear: function () {
        Object.keys(localStorageMock).forEach(
            (key) => delete localStorageMock[key]
        )
        this.length = 0
    },
    length: 0,
}
export const localStorageMock = Object.create(storagePrototype)
Object.defineProperty(window, 'localStorage', {
    value: localStorageMock,
})

// jest.mock('@hyperapp/router')
