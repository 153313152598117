import { t } from 'i18next';
import { h } from 'hyperapp';
import { isDefined, getYMDfromDateObj, createUTCDate } from '@app/core';
import { Card, Button, Form, FormInput, Enter, Calendar, Modal } from '@app/elements';
import * as constants from '@app/constants';

import './../index.scss';

import icoTreat from '@app/img/ico/ico-treat.png';
import icoTreatx2 from '@app/img/ico/ico-treat@2x.png';
import icoTreatOff from '@app/img/ico/ico-treat-off.png';
import icoTreatOffx2 from '@app/img/ico/ico-treat-off@2x.png';

import { ModalViewMedicalConfirm } from './modals/modalView-medicalConfirm-default';
import { ModalViewLifenSynch } from './modals/modalView-lifenSynch-default';

export default {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div class='patient-central'>
                <Card classes={'btzPageCard btzBackgroundCover'} kind={'datas'} style={{ 'margin': '30px 26px 0px 30px' }}>
                    <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                        <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
                            <dummy>
                                <Form onsubmit={event => event.preventDefault()} classes={'columns'}>
                                    <div class='col-6 col-xs-12'>
                                        <Form.Group classes={'borderedFormGroup'}>
                                            <div class='col-12 thfAlignTop'>
                                                <p class='btzForm-btzLabel'>{t('Nom d\'usage')}</p>
                                                <FormInput
                                                    placeholder={t('nom d\'usage du patient')}
                                                    key='input-lname'
                                                    type='text'
                                                    id='lname'
                                                    name='lname'
                                                    value={state.values.lname}
                                                    validation={true}
                                                    readonly={isDefined(state.lifenUser) ? true : false}
                                                    readonlyMessage={t('Cette information doit être modifiée au sein de votre Dossier Patient Informatisé.')}
                                                    loaded={state.loaded}
                                                    oninputcb={actions._retrieveInput}
                                                    onblurcb={actions._retrieveErrorMessagePatient}
                                                    formSubmittedOnce={state.formSubmittedOnce}
                                                    errorMessage={state.errorMessages.lname}
                                                    isTyping={state.isTyping.lname}
                                                    required
                                                />
                                            </div>
                                            <div class='col-12 thfAlignTop'>
                                                <p class='btzForm-btzLabel'>{t('Nom de naissance')}<font class='btzForm-btzLabel btzForm-btzLabelOptional'>{' (' + t('facultatif') + ')'}</font></p>
                                                <FormInput
                                                    placeholder={t('nom de naissance du patient')}
                                                    key='input-bname'
                                                    type='text'
                                                    id='bname'
                                                    name='bname'
                                                    value={state.values.bname}
                                                    validation={true}
                                                    optional={true}
                                                    readonly={isDefined(state.lifenUser) ? true : false}
                                                    readonlyMessage={t('Cette information doit être modifiée au sein de votre Dossier Patient Informatisé.')}
                                                    loaded={state.loaded}
                                                    oninputcb={actions._retrieveInput}
                                                    onblurcb={actions._retrieveErrorMessagePatient}
                                                    formSubmittedOnce={state.formSubmittedOnce}
                                                    errorMessage={state.errorMessages.bname}
                                                    isTyping={state.isTyping.bname}
                                                    required
                                                />
                                            </div>
                                            <div class='col-12 thfAlignTop'>
                                                <p class='btzForm-btzLabel'>{t('Prénom')}</p>
                                                <FormInput
                                                    placeholder={t('son prénom')}
                                                    key='input-fname'
                                                    type='text'
                                                    id='fname'
                                                    name='fname'
                                                    value={state.values.fname}
                                                    validation={true}
                                                    readonly={isDefined(state.lifenUser) ? true : false}
                                                    readonlyMessage={t('Cette information doit être modifiée au sein de votre Dossier Patient Informatisé.')}
                                                    loaded={state.loaded}
                                                    oninputcb={actions._retrieveInput}
                                                    onblurcb={actions._retrieveErrorMessagePatient}
                                                    formSubmittedOnce={state.formSubmittedOnce}
                                                    errorMessage={state.errorMessages.fname}
                                                    isTyping={state.isTyping.fname}
                                                    required
                                                />
                                            </div>
                                            <div class='col-12 thfAlignTop'>
                                                <Calendar oninput={actions._retrieveInput} onblur={actions._retrieveErrorMessagePatient} readonly={isDefined(state.lifenUser) ? true : false} readonlyMessage={t('Cette information doit être modifiée au sein de votre Dossier Patient Informatisé.')} key={'birthdate'} name={'birthdate'} title={t('Date de naissance')} calendarDate={state.values.birthdate} deletetable={false} formSubmittedOnce={state.formSubmittedOnce} errorMessage={state.errorMessages.birthdate} withUtc={false} noFuture={true} />
                                            </div>
                                            <div class='col-12 thfAlignTop'>
                                                <p class='btzForm-btzLabel'>{t('Identifiant patient unique')}</p>
                                                <FormInput
                                                    placeholder={t('0624945382342394')}
                                                    key='input-hospitalid'
                                                    type='text'
                                                    id='hospitalId'
                                                    name='hospitalId'
                                                    value={state.values.hospitalId}
                                                    validation={true}
                                                    readonly={isDefined(state.lifenUser) ? true : false}
                                                    readonlyMessage={t('Cette information doit être modifiée au sein de votre Dossier Patient Informatisé.')}
                                                    loaded={state.loaded}
                                                    oninputcb={actions._retrieveInput}
                                                    onblurcb={actions._retrieveErrorMessagePatient}
                                                    isTyping={state.isTyping.hospitalId}
                                                    required
                                                />
                                            </div>
                                            <div class='col-12 thfAlignTop'>
                                                <p class='btzForm-btzLabel'>{t('Numéro de sécurité sociale')}<font class='btzForm-btzLabel btzForm-btzLabelOptional'>{' (' + t('facultatif') + ')'}</font></p>
                                                <FormInput
                                                    placeholder={t('285057510611204')}
                                                    key='input-socialsecuritynumber'
                                                    type={{'action': actions.ensureNIRValidity}}
                                                    id='socialSecurityNumber'
                                                    name='socialSecurityNumber'
                                                    value={(state.values.socialSecurityNumber || '')}
                                                    validation={true}
                                                    isInteger={true}
                                                    optional={true}
                                                    loaded={state.loaded}
                                                    oninputcb={actions._retrieveInput}
                                                    onblurcb={actions._retrieveErrorMessagePatient}
                                                    formSubmittedOnce={state.formSubmittedOnce}
                                                    errorMessage={state.errorMessages.socialSecurityNumber}
                                                    isTyping={state.isTyping.socialSecurityNumber}
                                                    required
                                                />
                                            </div>
                                            <div class='col-12 thfAlignTop'>
                                                <p class='btzForm-btzLabel'>{t('Genre biologique')}</p>
                                                <FormInput
                                                    key='input-gender'
                                                    type='dropdown'
                                                    id='gender'
                                                    name='gender'
                                                    value={state.values.gender}
                                                    list={constants.custo.biologicalGender}
                                                    validation={true}
                                                    withSearch={false}
                                                    textedArea={false}
                                                    readonly={isDefined(state.lifenUser) ? true : false}
                                                    readonlyMessage={t('Cette information doit être modifiée au sein de votre Dossier Patient Informatisé.')}
                                                    loaded={state.loaded}
                                                    oninputcb={actions._retrieveInput}
                                                    onblurcb={actions._retrieveErrorMessagePatient}
                                                    formSubmittedOnce={state.formSubmittedOnce}
                                                    errorMessage={state.errorMessages.gender}
                                                    isTyping={state.isTyping.gender}
                                                    required
                                                />
                                            </div>
                                            <div class='col-12 thfAlignTop thfCustomPhone'>
                                                <p class='btzForm-btzLabel'>{t('Numéro de téléphone')}</p>
                                                <FormInput
                                                    key='input-phonePrefix'
                                                    type='dropdown'
                                                    id='phonePrefix'
                                                    name='phonePrefix'
                                                    value={state.values.phonePrefix}
                                                    list={constants.custo.phonePrefixes}
                                                    validation={true}
                                                    withSearch={false}
                                                    textedArea={false}
                                                    loaded={state.loaded}
                                                    oninputcb={actions._retrieveInput}
                                                    onblurcb={actions._retrieveErrorMessagePatient}
                                                    formSubmittedOnce={state.formSubmittedOnce}
                                                    errorMessage={state.errorMessages.phonePrefix}
                                                    isTyping={state.isTyping.phonePrefix}
                                                    required
                                                />
                                                <FormInput
                                                    placeholder={['', '', '', '', '']}
                                                    key='input-phone'
                                                    type='phone'
                                                    id='phone'
                                                    name='phone'
                                                    valueType='phone'
                                                    value={state.values.phone}
                                                    validation={true}
                                                    isInteger={true}
                                                    dynFocus={true}
                                                    isValid={(state.formSubmittedOnce ? state.isValid.phone : 'is-default')}
                                                    loaded={state.loaded}
                                                    oninputcb={actions._retrieveInput}
                                                    onblurcb={actions._retrieveErrorMessagePatient}
                                                    formSubmittedOnce={state.formSubmittedOnce}
                                                    errorMessage={state.errorMessages.phone}
                                                    isTyping={state.isTyping.phone}
                                                    required
                                                />
                                            </div>
                                            <div class='col-12 thfAlignTop'>
                                                <p class='btzForm-btzLabel'>{t('Langue préférée')}</p>
                                                <FormInput
                                                    key='input-locale'
                                                    type='dropdown'
                                                    id='locale'
                                                    name='locale'
                                                    value={state.values.locale}
                                                    list={constants.custo.languages}
                                                    oninputcb={actions._retrieveInput}
                                                    onblurcb={actions._retrieveErrorMessagePatient}
                                                    isTyping={state.isTyping.locale}
                                                />
                                            </div>
                                            <div class='col-12 thfAlignTop'>
                                                <p class='btzForm-btzLabel'>{t('Fuseau horaire du patient')}</p>
                                                <FormInput
                                                    key='input-timezone'
                                                    type='dropdown'
                                                    id='timezone'
                                                    name='timezone'
                                                    value={state.values.timezone}
                                                    list={state.timezones}
                                                    oninputcb={actions._retrieveInput}
                                                    onblurcb={actions._retrieveErrorMessagePatient}
                                                    isTyping={state.isTyping.timezone}
                                                />
                                            </div>
                                            <div class='col-12 thfAlignTop'>
                                                <p class='btzForm-btzLabel'>{t('Email')}</p>
                                                <FormInput
                                                    placeholder={t('adresse@mail.com')}
                                                    key='input-email'
                                                    type='email'
                                                    id='email'
                                                    name='email'
                                                    value={state.values.email}
                                                    validation={true}
                                                    isValid={state.isValid.email}
                                                    oninputcb={actions._retrieveInput}
                                                    onblurcb={actions._retrieveErrorMessagePatient}
                                                    formSubmittedOnce={state.formSubmittedOnce}
                                                    errorMessage={state.errorMessages.email}
                                                    isTyping={state.isTyping.email}
                                                    required
                                                />
                                            </div>
                                        </Form.Group>
                                        {isDefined(state.editedPatient) &&
                                            <div class='btzCard-btzContent-btzPage-btzInfo' style='margin-top: 35px;'>
                                                <div class='btzCard-btzHeader-btzPage-btzInfoDevided'>
                                                    <p class='btzCard-btzHeader-btzPage-btzInfoSubTitle'>{t('Date d\'inclusion')}</p>
                                                    <p class='btzCard-btzHeader-btzPage-btzInfoTitle'>{Object.values(getYMDfromDateObj(createUTCDate(state.editedPatient.creation.date))).join('.')}</p>
                                                </div>
                                                <div class='btzCard-btzHeader-btzPage-btzInfoDevided'>
                                                    <p class='btzCard-btzHeader-btzPage-btzInfoSubTitle'>{t('Dernière connexion')}</p>
                                                    <p class='btzCard-btzHeader-btzPage-btzInfoTitle'>{(isDefined(state.editedPatient.lastConnexion) ? Object.values(getYMDfromDateObj(createUTCDate(state.editedPatient.lastConnexion.date.date))).join('.') : 'Ø')}</p>
                                                </div>
                                                <div class='btzCard-btzHeader-btzPage-btzInfoDevided'>
                                                    <p class='btzCard-btzHeader-btzPage-btzInfoSubTitle'>{t('Version de l\'app')}</p>
                                                    <p class='btzCard-btzHeader-btzPage-btzInfoTitle'>{(isDefined(state.editedPatient.lastConnexion) && isDefined(state.editedPatient.lastConnexion.frontPlatformVersion)) ? state.editedPatient.lastConnexion.frontPlatformVersion : 'Ø'}</p>
                                                </div>
                                                <div class='btzCard-btzHeader-btzPage-btzInfoDevided'>
                                                    <p class='btzCard-btzHeader-btzPage-btzInfoSubTitle'>{t('Consentement')}</p>
                                                    {(isDefined(state.consents) && isDefined(state.consents.filter((f) => f.type === 'terms')[0])) && <p class='btzCard-btzHeader-btzPage-btzInfoTitle'>{t('CGU') + ' - ' + state.consents.filter((f) => f.type === 'terms')[0].version + ' - ' + ((state.consents.filter((f) => f.type === 'terms')[0].agreement === true) ? Object.values(getYMDfromDateObj(createUTCDate(state.consents.filter((f) => f.type === 'terms')[0].date.date))).join('.') : 'Ø') + ' - '}<span style='display: inline-block;'>{(!isDefined(state.consents.filter((f) => f.type === 'terms')[0].agreement) ? 'en attente' : (state.consents.filter((f) => f.type === 'terms')[0].agreement ? 'accepté' : 'refusé'))}</span></p>}
                                                    {(isDefined(state.consents) && isDefined(state.consents.filter((f) => f.type === 'privacy')[0])) && <p class='btzCard-btzHeader-btzPage-btzInfoTitle'>{t('PdC') + ' - ' + state.consents.filter((f) => f.type === 'privacy')[0].version + ' - ' + ((state.consents.filter((f) => f.type === 'privacy')[0].agreement === true) ? Object.values(getYMDfromDateObj(createUTCDate(state.consents.filter((f) => f.type === 'privacy')[0].date.date))).join('.') : 'Ø') + ' - '}<span style='display: inline-block;'>{(!isDefined(state.consents.filter((f) => f.type === 'privacy')[0].agreement) ? 'en attente' : (state.consents.filter((f) => f.type === 'privacy')[0].agreement ? 'accepté' : 'refusé'))}</span></p>}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div class='col-6 col-xs-12'>
                                        <Form.Group classes={'borderedFormGroup'}>
                                            <div class='col-12 thfAlignTop'>
                                                <p class='btzForm-btzLabel'>{t('Sélection pathologie(s) / intervention(s)')}</p>
                                                <FormInput
                                                    placeholder={t('Recherchez ou sélectionnez les pathologies ou interventions')}
                                                    key='input-pathologiesType'
                                                    type='dropdown'
                                                    id='pathologiesType'
                                                    name='pathologiesType'
                                                    valueType='pathologiesType'
                                                    value={(state.values.pathologiesType || [])}
                                                    list={constants.custo.pathologiesTypes.filter((f) => (!isDefined(state.values.pathologiesType) || (isDefined(state.values.pathologiesType) && (state.values.pathologiesType.indexOf(f.key) === -1))))}
                                                    fullList={constants.custo.pathologiesTypes}
                                                    validation={true}
                                                    withSearch={true}
                                                    textedArea={true}
                                                    loaded={state.loaded}
                                                    oninputcb={actions._retrieveInput}
                                                    onblurcb={actions._retrieveErrorMessagePatient}
                                                    formSubmittedOnce={state.formSubmittedOnce}
                                                    errorMessage={state.errorMessages.pathologiesType}
                                                    isTyping={state.isTyping.pathologiesType}
                                                    required
                                                />
                                            </div>
                                            <div class='col-12 thfAlignTop thfButtonListing'>
                                                <p class='btzForm-btzLabel'>{t('Notes')}<font class='btzForm-btzLabel btzForm-btzLabelOptional'>{' (' + t('facultatif') + ')'}</font></p>
                                                <FormInput
                                                    placeholder={t('Vos notes ici')}
                                                    key='input-pathology'
                                                    type='textarea'
                                                    id='pathology'
                                                    name='pathology'
                                                    value={state.values.pathology}
                                                    validation={true}
                                                    optional={true}
                                                    textareaRows={5}
                                                    oninputcb={actions._retrieveInput}
                                                    onblurcb={actions._retrieveErrorMessagePatient}
                                                    formSubmittedOnce={state.formSubmittedOnce}
                                                    errorMessage={state.errorMessages.pathology}
                                                    isTyping={state.isTyping.pathology}
                                                    required
                                                />
                                            </div>
                                            <div class='col-12 thfAlignTop'>
                                                <p class='btzForm-btzLabel'>{t('Établissement référent')}</p>
                                                <FormInput
                                                    key='input-customer'
                                                    type='dropdown'
                                                    id='referenceCustomer'
                                                    name='referenceCustomer'
                                                    value={state.referenceCustomer}
                                                    list={state.referenceHospital}
                                                    readonly={(state.doctorRole === 'nurse' ? false : true)}
                                                    loaded={state.loaded}
                                                    oninputcb={actions._retrieveInput}
                                                    onblurcb={actions._retrieveErrorMessagePatient}
                                                    formSubmittedOnce={state.formSubmittedOnce}
                                                    errorMessage={state.errorMessages.referenceCustomer}
                                                    isTyping={state.isTyping.referenceCustomer}
                                                    required
                                                />
                                            </div>
                                            <div class='col-12 thfAlignTop'>
                                                <p class='btzForm-btzLabel'>{t('Médecin référent')}</p>
                                                <FormInput
                                                    key='input-doctor'
                                                    type='dropdown'
                                                    id='doctor'
                                                    name='doctor'
                                                    value={state.values.doctor}
                                                    list={state.referenceHealTeam.filter((f) => ((f.key !== state.values.coach) && state.values.nurses.indexOf(f.key) === -1))}
                                                    excludeList={state.referenceHealTeam.filter((f) => ((f.key !== state.values.coach) && (state.values.nurses.indexOf(f.key) === -1) && (f.status !== 'USER_ACTIVE')))}
                                                    validation={true}
                                                    withSearch={true}
                                                    textedArea={false}
                                                    loaded={state.loaded}
                                                    oninputcb={actions._retrieveInput}
                                                    onblurcb={actions._retrieveErrorMessagePatient}
                                                    formSubmittedOnce={state.formSubmittedOnce}
                                                    errorMessage={state.errorMessages.doctor}
                                                    isTyping={state.isTyping.doctor}
                                                    required
                                                />
                                            </div>
                                            <div class='col-12 thfAlignTop'>
                                                <p class='btzForm-btzLabel'>{t('Équipe médicale')}<font class='btzForm-btzLabel btzForm-btzLabelOptional'>{' (' + t('facultatif') + ')'}</font></p>
                                                <FormInput
                                                    key='input-nurses'
                                                    type='dropdown'
                                                    id='nurses'
                                                    name='nurses'
                                                    valueType='nurses'
                                                    value={state.values.nurses}
                                                    list={state.referenceHealTeam.filter((f) => ((f.key !== state.values.doctor) && (f.key !== state.values.coach) && state.values.nurses.indexOf(f.key) === -1))}
                                                    excludeList={state.referenceHealTeam.filter((f) => ((f.key !== state.values.doctor) && (f.key !== state.values.coach) && (state.values.nurses.indexOf(f.key) === -1) && (f.status !== 'USER_ACTIVE')))}
                                                    fullList={state.referenceHealTeam}
                                                    validation={true}
                                                    withSearch={true}
                                                    textedArea={true}
                                                    optional={true}
                                                    loaded={state.loaded}
                                                    oninputcb={actions._retrieveInput}
                                                    onblurcb={actions._retrieveErrorMessagePatient}
                                                    formSubmittedOnce={state.formSubmittedOnce}
                                                    errorMessage={state.errorMessages.nurses}
                                                    isTyping={state.isTyping.nurses}
                                                    required
                                                />
                                                <Button flat cancel onclick={actions.addWholeTeam}>
                                                    {t('Ajouter tout le monde') + ' (' + state.referenceHealTeam.filter((f) => ((f.key !== state.values.doctor) && (f.key !== state.values.coach) && state.values.nurses.indexOf(f.key) === -1)).length + ')'}
                                                </Button>
                                                {(isDefined(state.addWholeDisclamerMessage) && (state.addWholeDisclamerMessage !== '') && (!isDefined(state.values.doctor) || (state.values.doctor === ''))) &&
                                                    <p class="errorMessage" style='padding: 10px 0px;'>{state.addWholeDisclamerMessage}</p>
                                                }
                                            </div>
                                        </Form.Group>
                                    </div>

                                    {state.medicalTeamUpdateConfirm &&
                                        <Modal key={'modal-medical-confirm'} cancelAction={() => { actions.setMedicalTeamUpdateConfirm(false); }} modalDisplayed={true} content={<ModalViewMedicalConfirm displayed={'medicalconfirm'} {...state} onSubmit={() => { actions.updatePatient(true) }} cancelAction={() => { actions.setMedicalTeamUpdateConfirm(false) }} loaded={true}></ModalViewMedicalConfirm>}></Modal>
                                    }

                                    {state.needLifenSynch &&
                                        <Modal key={'modal-lifen-synch'} customClass='modal-central' cancelAction={() => { actions.setLifenSynchConfirm(false); }} modalDisplayed={true} content={<ModalViewLifenSynch displayed={'lifensynch'} {...state} onSubmit={() => { actions.synchLifenPatient() }} cancelAction={() => { actions.setLifenSynchConfirm(false) }} loaded={true}></ModalViewLifenSynch>}></Modal>
                                    }

                                    <Form.Group classes='btzForm-btzFooter' style={{ 'text-align': 'left', 'padding-left': '15px', 'display': 'inline-block' }}>
                                        {state.previewLifenSynch &&
                                            <div style='display: inline-block; margin-right: 25px'>
                                                <Button active revertedColor flat onclick={(e) => { actions.setLifenSynchConfirm(true) }}>
                                                    {t('Actualiser les informations')}
                                                </Button>
                                            </div>
                                        }
                                        <Button loading={(state.formIsSubmitting)} flat active={state.isValid.email && state.isValid.birthdate && state.isValid.coach && state.isValid.doctor && state.isValid.etapes && state.isValid.fname && state.isValid.gender && state.isValid.hospitalId && state.isValid.lname && state.isValid.phone && state.isValid.phonePrefix && state.isValid.socialSecurityNumber && state.isValid.pathologiesType} onclick={actions.onSubmit}>
                                            <img class='activated activated-visible' src={icoTreat} srcset={`${icoTreatx2} 2x`} alt='' />
                                            <img class='deactivated-visible' src={icoTreatOff} srcset={`${icoTreatOffx2} 2x`} alt='' />
                                            {t('Enregistrer')}
                                        </Button>
                                    </Form.Group>
                                </Form>
                            </dummy>
                        </Enter>
                    </Card.Header>
                </Card>
            </div>
        );
        return view;
    }
}