import { logoutAndRedirect } from '@app/core'
import { allRoutes, addCSRFToken, getQueryVariable, isDefined } from '@app/core'

let msgTimeout = null

const handleErrors = (response) => {
    if (response.code) {
        switch (+response.code) {
            case 102: // user not found
            case 200:
            case 201:
            case 202:
            case 203: // wrong mail/pwd (login)
            case 208: // 5 errors, account blocked
            case 303: // no timeline for this day
            case 510: // tracker conflict
            case 812: // invalid invitation code, see #BL-5516
            case 907: // no debit was done with this credit card
            case 1801: // passphrase invalide
            case 432: // invalid auth code
            case 149: // bad phone
            case 2602: // error on lifen API
                // OK, no error to display / or display custom error directly from call callback
                break

            case 122: // no valid payment method
            case 805: // customer license is expired
                logoutAndRedirect()
                break

            case 115:
                logoutAndRedirect()
                break

            case 103: // disabled account
            case 210:
                logoutAndRedirect()
                break

            case 205:
            case 204: // expired session
                var isRedirected = getQueryVariable('unique_id', false)
                if (isDefined(isRedirected, false, true)) {
                    // let the redirect caller handle the logout
                } else {
                    logoutAndRedirect()
                }
                break

            case 1802: // crypt error
                displayErrors(response.message, 2800)
                setTimeout(() => {
                    window.location.href = addCSRFToken(
                        allRoutes['private.patients.listing'].pathname
                    )
                }, 3000)
                break

            default:
                displayErrors(response.message, 2000)
        }
        return response
    } else {
        // everything is ok
    }
    return response
}

const displayErrors = function (message, time, kind = 'error') {
    let errorContent = document.getElementById('btzMaintextContent')
    let errorMssg = document.getElementById('btzMainMessage')
    if (errorContent !== null) {
        errorContent.innerHTML = message

        if (errorMssg !== null) {
            errorMssg.classList.add('active')
            errorMssg.classList.add(kind)

            msgTimeout = setTimeout(function () {
                clearTimeout(msgTimeout)
                msgTimeout = null
                errorMssg.classList.remove('active')
                errorMssg.classList.remove(kind)
            }, time)
        }
    }
}

export { displayErrors, handleErrors }
