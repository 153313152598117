import { t } from 'i18next'
import {
    getQueryVariable,
    isDefined,
    allRoutes,
    addCSRFToken,
    castToBool,
    definePatientSession,
} from '@app/core'
import { Hospital } from '@app/api'
import { displayErrors } from '@app/api/errors'
/*
http://localhost:8084/?unique_id=92380PF&phone=0644940582&email=adrien+patientchv2@betterise.me&date_of_birth=1990-10-10&last_name=RIBEAUCOURT%20DE%20MEYER&first_name=EDITH&redirect=public.redirectingway
http://localhost:8084/chv?unique_id=92380PF&phone=0644940582&email=adrien+patientchv2@betterise.me&date_of_birth=1990-10-10&last_name=RIBEAUCOURT%20DE%20MEYER&first_name=EDITH
https://dashboard-release.resilience.care/chv?unique_id=SDFKJ23LKKK
http://localhost:3000/chv?unique_id=92380PF&phone=0644940582&email=adrien+patientchv2@betterise.me&date_of_birth=1990-10-10&last_name=RIBEAUCOURT%20DE%20MEYER&first_name=EDITH
http://localhost:3000/chv?unique_id=SDFKJSL4K23
https://release.oncolaxy.com/chv?unique_id=92380PF&phone=0644940582&email=adrien+patientchv2@betterise.me&date_of_birth=1990-10-10&last_name=RIBEAUCOURT%20DE%20MEYER&first_name=EDITH
http://localhost:8084/ijb?unique_id=92380PF&phone=0644940582&email=adrien+patientchv2@betterise.me&date_of_birth=1990-10-10&last_name=RIBEAUCOURT%20DE%20MEYER&first_name=EDITH&prefix=+42&maiden_name=NOM
 */

const clearTHFStorage = function () {
    sessionStorage.removeItem('thf-unique_id')
    sessionStorage.removeItem('thf-last_name')
    sessionStorage.removeItem('thf-first_name')
    sessionStorage.removeItem('thf-date_of_birth')
    sessionStorage.removeItem('thf-phone')
    sessionStorage.removeItem('thf-prefix')
    sessionStorage.removeItem('thf-email')
    sessionStorage.removeItem('thf-maiden_name')
}

export const actions = {
    onComponentCreate: (props) => (state, actions) => {
        let isRedirected = getQueryVariable('redirect', false)
        //
        let invalidApiToken = () => {
            if (
                isDefined(isRedirected, false, true) &&
                castToBool(isRedirected) === true
            ) {
                // no infinite loop
                return false
            }
            //
            definePatientSession()
            let redirect = 'public.redirectingway'
            if (props?.targetapi === 'ijb') {
                redirect = 'public.redirectingway.ijb'
            }
            window.location.href = addCSRFToken(
                allRoutes['index'].pathname + '?redirect=' + redirect
            )
        }
        let patientDoesNotExist = () => {
            definePatientSession()
            window.location.href = addCSRFToken(
                allRoutes['private.patient.add'].pathname
            )
        }
        let patientExist = (patientid) => {
            window.location.href = addCSRFToken(
                allRoutes['private.patient.edit'].pathname.replace(
                    ':id',
                    patientid
                )
            )
        }
        let accessDenied = () => {
            displayErrors(
                t("L'accès a ces données patient n'est pas autorisé."),
                3000
            )
            clearTHFStorage()
            setTimeout(() => {
                window.location.href = addCSRFToken(
                    allRoutes['private.patients.listing'].pathname
                )
            }, 3000)
        }
        //
        let patientObject = {}
        //
        if (isDefined(getQueryVariable('unique_id', false), false, true)) {
            patientObject.unique_id = getQueryVariable('unique_id', false)
        } else if (
            isDefined(sessionStorage.getItem('thf-unique_id'), false, true)
        ) {
            patientObject.unique_id = sessionStorage.getItem('thf-unique_id')
        }
        if (isDefined(getQueryVariable('email', false), false, true)) {
            patientObject.email = getQueryVariable('email', false)
        } else if (
            isDefined(sessionStorage.getItem('thf-email'), false, true)
        ) {
            patientObject.email = sessionStorage.getItem('thf-email')
        }
        if (isDefined(getQueryVariable('phone', false), false, true)) {
            patientObject.phone = getQueryVariable('phone', false)
        } else if (
            isDefined(sessionStorage.getItem('thf-phone'), false, true)
        ) {
            patientObject.phone = sessionStorage.getItem('thf-phone')
        }
        //
        if (isDefined(patientObject.unique_id, false, true)) {
            let token = localStorage.getItem('apitoken')
            if (!isDefined(token) || token === 'null') {
                invalidApiToken()
            } else {
                Hospital.checkCHU(
                    props?.targetapi || 'chv',
                    patientObject
                ).then((res) => {
                    sessionStorage.setItem(
                        'thf-unique_id',
                        patientObject.unique_id
                    )
                    if (isDefined(res.data)) {
                        if (res.data.code) {
                            if (+res.data.code === 102) {
                                patientDoesNotExist()
                            } else if (+res.data.code === 204) {
                                invalidApiToken()
                            } else if (+res.data.code === 436) {
                                accessDenied()
                            }
                        } else {
                            if (res.data.id) {
                                patientExist(res.data.id)
                            }
                        }
                    } else {
                        invalidApiToken()
                    }
                })
            }
        } else {
            displayErrors(
                t(
                    "Vous devez fournir le paramètre de requête [unique_id] correspondant à l'identifiant unique du patient au sein de l'établissement"
                ),
                10000
            )
        }
    },
}

/*
?last_name=nom&first_name=prenom&date_of_birth=yyyy-mm-dd&unique_id=zzzzzzzz&phone=0000000&email=xx@yy.zz&doctor=ddd
 */
