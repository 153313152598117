import { t } from 'i18next'
import { h } from 'hyperapp'
import {
    Card,
    Button,
    Form,
    FormInput,
    Tile,
    Enter,
    Modal,
} from '@app/elements'
import {
    isDefined,
    getReadableDate,
    createUTCDate,
    getDiffDate,
    createDateComplientSafari,
    getDateObjFromYMD,
} from '@app/core'
import * as constants from '@app/constants'

import { settings } from '@app/core/settings'
const { API_URL } = settings

import ViewForm from './view-form'

import { ModalViewEndStudy } from './modals/modalView-endStudy'
import { ModalViewEditStudy } from './modals/modalView-editStudy'
import { ModalViewCommentStudy } from './modals/modalView-commentStudy'
import { ModalViewDoSurvey } from './modals/modalView-doSurvey'

import icoEdit from '@app/img/ico/ico-edit-pencil.png'
import icoEditx2 from '@app/img/ico/ico-edit-pencil@2x.png'

import icoCalendrier from '@app/img/ico/ico-calendar.png'
import icoCalendrierx2 from '@app/img/ico/ico-calendar@2x.png'

import icoMessageOff from '@app/img/ico/ico-message-off.png'
import icoMessageOffx2 from '@app/img/ico/ico-message-off@2x.png'

import icoFold from '@app/img/ico/drop-arrow.png'
import icoFoldx2 from '@app/img/ico/drop-arrow@2x.png'

import icoExport from '@app/img/ico/ico-export.png'
import icoExportx2 from '@app/img/ico/ico-export@2x.png'

const momentsFeatureDisplayedFor = [
    'curve',
    'less',
    'uroaccurate99',
    'urodipru',
    'uroanxiety113dipru',
    'uroanxiety113',
    'urosocratic152sa',
    'urosocratic152sur',
    'uropersonalize114',
]
const noQuestionnaireLabelFor = [
    'less',
    'uroaccurate99',
    'urodipru',
    'uroanxiety113dipru',
    'uroanxiety113',
]

export default {
    initView: function () {
        let view = (state, actions) => (props, children) => (
            <div class="patient-central">
                <Card classes={'btzPageCard btzBackgroundCover'} kind={'datas'}>
                    <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                        <Enter
                            time={constants.custo.ANIM_DURATION}
                            easing={constants.custo.ANIM_EASING}
                            css={constants.custo.ANIM_CSS}
                        >
                            <dummy>
                                {state.studies.length === 0 ? (
                                    <Tile>
                                        <Tile.Content
                                            style={{
                                                'margin-top': '30px',
                                            }}
                                        >
                                            <div class="thfStudy-header">
                                                <p class="thfStudy-headerTitle btzTitle btzXl ovr-base-colored">
                                                    {t(
                                                        "Aucun questionnaire n'est disponible pour vous."
                                                    )}
                                                </p>
                                                <p class="thfStudy-headerSubTitle btzSubTitle ovr-lightgrey-colored">
                                                    {t(
                                                        "Aucun questionnaire n'est activé pour l'entreprise à laquelle vous appartenez."
                                                    )}
                                                </p>
                                            </div>
                                        </Tile.Content>
                                    </Tile>
                                ) : (
                                    <dummy>
                                        {state.usersTimelines?.length > 0 && (
                                            <Tile
                                                style={{
                                                    'margin-bottom': '25px',
                                                }}
                                            >
                                                <div class="thfStudy-studiesButtonsHeader">
                                                    <div class="thfStudy-studiesButtonsHeaderLeft">
                                                        {state.studies
                                                            .filter(
                                                                (std) =>
                                                                    state.activeTimelines
                                                                        ?.map(
                                                                            (
                                                                                tml
                                                                            ) =>
                                                                                tml.name
                                                                        )
                                                                        ?.indexOf(
                                                                            std.key
                                                                        ) > -1
                                                            )
                                                            .map((item) => (
                                                                <div
                                                                    class={
                                                                        'thfStudy-studiesButton ' +
                                                                        (state
                                                                            .values
                                                                            .name ===
                                                                        item.key
                                                                            ? 'active'
                                                                            : '')
                                                                    }
                                                                    onclick={() => {
                                                                        actions.switchCurrentStudy(
                                                                            item.key
                                                                        )
                                                                    }}
                                                                >
                                                                    {item.label}
                                                                </div>
                                                            ))}
                                                    </div>
                                                    <div class="thfStudy-studiesButtonsHeaderRight">
                                                        {state.studies
                                                            .filter(
                                                                (std) =>
                                                                    state.activeTimelines
                                                                        ?.map(
                                                                            (
                                                                                tml
                                                                            ) =>
                                                                                tml.name
                                                                        )
                                                                        ?.indexOf(
                                                                            std.key
                                                                        ) === -1
                                                            )
                                                            .map((item) => (
                                                                <div
                                                                    class={
                                                                        'thfStudy-studiesButton ' +
                                                                        (state
                                                                            .values
                                                                            .name ===
                                                                        item.key
                                                                            ? 'active'
                                                                            : '')
                                                                    }
                                                                    onclick={() => {
                                                                        actions.switchCurrentStudy(
                                                                            item.key
                                                                        )
                                                                    }}
                                                                >
                                                                    {item.label}
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>
                                            </Tile>
                                        )}
                                        {!isDefined(state.currentTimeline) && (
                                            <Tile>
                                                <div class="thfSurveyReport-header">
                                                    <span>
                                                        {state.pastTimelines.filter(
                                                            (pst) =>
                                                                pst.name ===
                                                                state.values
                                                                    .name
                                                        ).length + 1}
                                                    </span>
                                                    <p class="">
                                                        {t('Nouveau parcours')}
                                                    </p>
                                                </div>
                                                <Tile.Content
                                                    style={{
                                                        'margin-top': '30px',
                                                    }}
                                                >
                                                    {state.values.name ===
                                                        'palatine' &&
                                                        (!isDefined(
                                                            state.studyId
                                                        ) ||
                                                            state.studyId ===
                                                                '') && (
                                                            <div class="thfStudy-header">
                                                                <p class="thfStudy-headerTitle btzTitle btzXl ovr-base-colored">
                                                                    {t(
                                                                        "Configurer les dates clés de l'étude pour commencer l'envoi des questionnaires"
                                                                    )}
                                                                </p>
                                                                <p class="thfStudy-headerSubTitle btzSubTitle">
                                                                    {t(
                                                                        'Les dates facultatives pourront être renseignées plus tard.'
                                                                    )}
                                                                </p>
                                                            </div>
                                                        )}
                                                    {state.values.name ===
                                                        'palatine' &&
                                                        isDefined(
                                                            state.studyId
                                                        ) &&
                                                        state.studyId !==
                                                            '' && (
                                                            <div class="thfStudy-header">
                                                                <p class="thfStudy-headerTitle btzTitle btzXl ovr-base-colored">
                                                                    {t(
                                                                        'Envoi des questionnaires en cours !'
                                                                    )}
                                                                </p>
                                                                <p class="thfStudy-headerSubTitle btzSubTitle">
                                                                    {t(
                                                                        'Vous pouvez à tout moment mettre à jour les dates ci-dessous.'
                                                                    )}
                                                                </p>
                                                            </div>
                                                        )}
                                                    {state.values.name !==
                                                        'palatine' && (
                                                        <div class="thfStudy-header">
                                                            <p class="thfStudy-headerTitle btzTitle btzXl ovr-base-colored">
                                                                {t(
                                                                    "Configurer l'envoi des questionnaires"
                                                                )}
                                                            </p>
                                                            <p class="thfStudy-headerSubTitle btzSubTitle">
                                                                {t(
                                                                    "Sélectionnez ci-dessous la série de questionnaires, le mode d'émission et les dates clés."
                                                                )}
                                                            </p>
                                                        </div>
                                                    )}
                                                    {!isDefined(
                                                        state.usersTimelines
                                                    ) ||
                                                    state.usersTimelines
                                                        ?.length === 0 ? (
                                                        <div class="thfStudy-studiesButtons">
                                                            {state.studies.map(
                                                                (item) => (
                                                                    <div
                                                                        class={
                                                                            'thfStudy-studiesButton ' +
                                                                            (state
                                                                                .values
                                                                                .name ===
                                                                            item.key
                                                                                ? 'active'
                                                                                : '')
                                                                        }
                                                                        onclick={() => {
                                                                            actions.retrieveStudy(
                                                                                item.key
                                                                            )
                                                                        }}
                                                                    >
                                                                        {t(
                                                                            'Questionnaires'
                                                                        ) +
                                                                            ' ' +
                                                                            item.label}
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div class="thfStudy-studiesButtons">
                                                            <div
                                                                class={
                                                                    'thfStudy-studiesButton active'
                                                                }
                                                            >
                                                                {t(
                                                                    'Questionnaires'
                                                                ) +
                                                                    ' ' +
                                                                    state.studies.filter(
                                                                        (std) =>
                                                                            std.key ===
                                                                            state
                                                                                .values
                                                                                .name
                                                                    )?.[0]
                                                                        ?.label}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <Form
                                                        key={state.values.name}
                                                        onsubmit={(event) =>
                                                            event.preventDefault()
                                                        }
                                                    >
                                                        <ViewForm
                                                            {...state}
                                                            retrieveInput={
                                                                actions._retrieveInput
                                                            }
                                                            retrieveErrorSendBy={
                                                                actions.retrieveErrorSendBy
                                                            }
                                                        />
                                                        <Form.Group
                                                            classes="btzForm-btzFooter"
                                                            style={{
                                                                display:
                                                                    'table',
                                                                margin: 'auto',
                                                            }}
                                                        >
                                                            <Button
                                                                loading={
                                                                    state.formIsSubmitting
                                                                }
                                                                active={
                                                                    state.isValidForm
                                                                }
                                                                onclick={
                                                                    actions.onSubmit
                                                                }
                                                            >
                                                                {isDefined(
                                                                    state.studyId
                                                                )
                                                                    ? t(
                                                                          'Enregistrer'
                                                                      )
                                                                    : t(
                                                                          'Commencer les envois'
                                                                      )}
                                                            </Button>
                                                        </Form.Group>
                                                    </Form>
                                                    {state.momentEligibility
                                                        .length > 0 && (
                                                        <div class="thfStudy-informations">
                                                            <p class="thfStudy-informationTitle">
                                                                {t(
                                                                    'Information'
                                                                )}
                                                            </p>
                                                            <p class="thfStudy-informationTexte">
                                                                {t(
                                                                    'Avec cette configuration, les questionnaires seront envoyés'
                                                                ) +
                                                                    ' ' +
                                                                    (state
                                                                        .values
                                                                        .issueSentBy ===
                                                                    'SMS'
                                                                        ? t(
                                                                              'par SMS'
                                                                          )
                                                                        : 'par EMAIL') +
                                                                    ', ' +
                                                                    t(
                                                                        'aux dates suivantes :'
                                                                    )}
                                                            </p>
                                                            <div class="thfStudy-informationsMoments">
                                                                {state.momentEligibility
                                                                    .filter(
                                                                        (f) =>
                                                                            isDefined(
                                                                                f[0]
                                                                                    .date
                                                                            )
                                                                    )
                                                                    .map(
                                                                        (
                                                                            item
                                                                        ) => (
                                                                            <div
                                                                                class={
                                                                                    'thfStudy-informationsMoment ' +
                                                                                    (momentsFeatureDisplayedFor.includes(
                                                                                        state
                                                                                            .values
                                                                                            .name
                                                                                    )
                                                                                        ? ''
                                                                                        : 'thfStudy-informationsMoment-daysMoment')
                                                                                }
                                                                            >
                                                                                {!momentsFeatureDisplayedFor.includes(
                                                                                    state
                                                                                        .values
                                                                                        .name
                                                                                ) && (
                                                                                    <div class="thfStudy-informationsMomentBlock">
                                                                                        <p class="">
                                                                                            {item
                                                                                                .map(
                                                                                                    (
                                                                                                        m
                                                                                                    ) =>
                                                                                                        m.moment
                                                                                                )
                                                                                                .join(
                                                                                                    ', '
                                                                                                )}
                                                                                        </p>
                                                                                    </div>
                                                                                )}
                                                                                <p class="thfStudy-informationsMomentLabel">
                                                                                    {getReadableDate(
                                                                                        createUTCDate(
                                                                                            item[0]
                                                                                                .date
                                                                                        )
                                                                                    )}
                                                                                </p>
                                                                                <div class="thfStudy-informationsMomentBlock">
                                                                                    <p class="">
                                                                                        {item
                                                                                            .map(
                                                                                                (
                                                                                                    m
                                                                                                ) => {
                                                                                                    return momentsFeatureDisplayedFor.includes(
                                                                                                        state
                                                                                                            .values
                                                                                                            .name
                                                                                                    )
                                                                                                        ? m.moment
                                                                                                        : t(
                                                                                                              'Questionnaire '
                                                                                                          ) +
                                                                                                              m.moment
                                                                                                }
                                                                                            )
                                                                                            .join(
                                                                                                ', '
                                                                                            )}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </Tile.Content>
                                            </Tile>
                                        )}
                                        {state.displayMode === 'moment' &&
                                            isDefined(state.currentTimeline) &&
                                            state.currentTimeline.timeline
                                                .length > 0 && (
                                                <dummy>
                                                    <Tile>
                                                        <div class="thfSurveyReport-header">
                                                            <span>
                                                                {state.pastTimelines.filter(
                                                                    (pst) =>
                                                                        pst.name ===
                                                                        state
                                                                            .values
                                                                            .name
                                                                ).length + 1}
                                                            </span>
                                                            <p class="">
                                                                {t(
                                                                    'Questionnaire'
                                                                ) +
                                                                    (isDefined(
                                                                        state.studies.filter(
                                                                            (
                                                                                f
                                                                            ) =>
                                                                                f.key ===
                                                                                state
                                                                                    .values
                                                                                    .name
                                                                        )[0]
                                                                    )
                                                                        ? ' ' +
                                                                          state.studies.filter(
                                                                              (
                                                                                  f
                                                                              ) =>
                                                                                  f.key ===
                                                                                  state
                                                                                      .values
                                                                                      .name
                                                                          )[0]
                                                                              .label
                                                                        : '')}
                                                            </p>
                                                            <Button
                                                                primary
                                                                flat
                                                                active
                                                                successed
                                                                revertedColor
                                                            >
                                                                {t('En cours')}
                                                            </Button>
                                                        </div>
                                                        <Tile.Content
                                                            style={{
                                                                'margin-top':
                                                                    '30px',
                                                            }}
                                                        >
                                                            <div
                                                                key={
                                                                    state.values
                                                                        ?.name
                                                                }
                                                                class="thfStudy-Configuration"
                                                            >
                                                                <p class="thfStudy-headerLittleTitle">
                                                                    {t(
                                                                        'Configuration'
                                                                    )}
                                                                </p>
                                                                <div class="thfStudy-Configuration-blocks">
                                                                    <div class="thfStudy-Configuration-block active">
                                                                        <Modal
                                                                            key={
                                                                                'modal-edit-survey'
                                                                            }
                                                                            cancelAction={() => {
                                                                                actions.setModaldisplayed(
                                                                                    ''
                                                                                )
                                                                                actions.retrieveBuffedValues()
                                                                            }}
                                                                            modalDisplayed={
                                                                                state.modaldisplayed.indexOf(
                                                                                    'editsurvey-sendingway'
                                                                                ) >
                                                                                -1
                                                                            }
                                                                            content={
                                                                                <ModalViewEditStudy
                                                                                    displayed={
                                                                                        'editsurvey-sendingway'
                                                                                    }
                                                                                    onSubmit={
                                                                                        actions.onSubmit
                                                                                    }
                                                                                    cancelAction={() => {
                                                                                        actions.setModaldisplayed(
                                                                                            ''
                                                                                        )
                                                                                        actions.retrieveBuffedValues()
                                                                                    }}
                                                                                    _retrieveInput={
                                                                                        actions._retrieveInput
                                                                                    }
                                                                                    retrieveErrorSendBy={
                                                                                        actions.retrieveErrorSendBy
                                                                                    }
                                                                                    {...state}
                                                                                    loaded={
                                                                                        true
                                                                                    }
                                                                                ></ModalViewEditStudy>
                                                                            }
                                                                        >
                                                                            <div
                                                                                onclick={() => {
                                                                                    actions.buffStudyValues()
                                                                                    actions.setModaldisplayed(
                                                                                        'editsurvey-sendingway'
                                                                                    )
                                                                                }}
                                                                            >
                                                                                <p class="">
                                                                                    {t(
                                                                                        "Mode d'émission :"
                                                                                    ) +
                                                                                        ' ' +
                                                                                        (state
                                                                                            .values
                                                                                            .issueSentBy ===
                                                                                        'SMS'
                                                                                            ? t(
                                                                                                  'par SMS'
                                                                                              )
                                                                                            : 'par EMAIL')}
                                                                                    <img
                                                                                        src={
                                                                                            icoEdit
                                                                                        }
                                                                                        srcset={`${icoEditx2} 2x`}
                                                                                        alt=""
                                                                                    />
                                                                                </p>
                                                                            </div>
                                                                        </Modal>
                                                                    </div>
                                                                    {(state
                                                                        .values
                                                                        .inclusionDate ||
                                                                        (state.buffedValues &&
                                                                            state
                                                                                .buffedValues
                                                                                .inclusionDate)) &&
                                                                        state
                                                                            .values
                                                                            .inclusionDate &&
                                                                        state
                                                                            .values
                                                                            .inclusionDate && (
                                                                            <div class="thfStudy-Configuration-block active">
                                                                                <Modal
                                                                                    key={
                                                                                        'modal-edit-survey'
                                                                                    }
                                                                                    cancelAction={() => {
                                                                                        actions.setModaldisplayed(
                                                                                            ''
                                                                                        )
                                                                                        actions.retrieveBuffedValues()
                                                                                    }}
                                                                                    modalDisplayed={
                                                                                        state.modaldisplayed.indexOf(
                                                                                            'editsurvey-inclusiondate'
                                                                                        ) >
                                                                                        -1
                                                                                    }
                                                                                    content={
                                                                                        <ModalViewEditStudy
                                                                                            displayed={
                                                                                                'editsurvey-inclusiondate'
                                                                                            }
                                                                                            onSubmit={
                                                                                                actions.onSubmit
                                                                                            }
                                                                                            cancelAction={() => {
                                                                                                actions.setModaldisplayed(
                                                                                                    ''
                                                                                                )
                                                                                                actions.retrieveBuffedValues()
                                                                                            }}
                                                                                            _retrieveInput={
                                                                                                actions._retrieveInput
                                                                                            }
                                                                                            retrieveErrorSendBy={
                                                                                                actions.retrieveErrorSendBy
                                                                                            }
                                                                                            {...state}
                                                                                            loaded={
                                                                                                true
                                                                                            }
                                                                                        ></ModalViewEditStudy>
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        onclick={() => {
                                                                                            actions.buffStudyValues()
                                                                                            actions.setModaldisplayed(
                                                                                                'editsurvey-inclusiondate'
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        <p class="">
                                                                                            {t(
                                                                                                "Date d'inclusion :"
                                                                                            ) +
                                                                                                ' ' +
                                                                                                getReadableDate(
                                                                                                    createUTCDate(
                                                                                                        getDateObjFromYMD(
                                                                                                            state
                                                                                                                .values
                                                                                                                .inclusionDate
                                                                                                        )
                                                                                                            .date
                                                                                                    ),
                                                                                                    'DD/MM/YYYY'
                                                                                                )}
                                                                                            <img
                                                                                                src={
                                                                                                    icoEdit
                                                                                                }
                                                                                                srcset={`${icoEditx2} 2x`}
                                                                                                alt=""
                                                                                            />
                                                                                        </p>
                                                                                    </div>
                                                                                </Modal>
                                                                            </div>
                                                                        )}
                                                                    {(isDefined(
                                                                        state
                                                                            .values
                                                                            .visitDate
                                                                    ) ||
                                                                        (isDefined(
                                                                            state.buffedValues
                                                                        ) &&
                                                                            isDefined(
                                                                                state
                                                                                    .buffedValues
                                                                                    .visitDate
                                                                            ))) && (
                                                                        <div class="thfStudy-Configuration-block active">
                                                                            <Modal
                                                                                key={
                                                                                    'modal-edit-survey'
                                                                                }
                                                                                cancelAction={() => {
                                                                                    actions.setModaldisplayed(
                                                                                        ''
                                                                                    )
                                                                                    actions.retrieveBuffedValues()
                                                                                }}
                                                                                modalDisplayed={
                                                                                    state.modaldisplayed.indexOf(
                                                                                        'editsurvey-visitdate'
                                                                                    ) >
                                                                                    -1
                                                                                }
                                                                                content={
                                                                                    <ModalViewEditStudy
                                                                                        displayed={
                                                                                            'editsurvey-visitdate'
                                                                                        }
                                                                                        onSubmit={
                                                                                            actions.onSubmit
                                                                                        }
                                                                                        cancelAction={() => {
                                                                                            actions.setModaldisplayed(
                                                                                                ''
                                                                                            )
                                                                                            actions.retrieveBuffedValues()
                                                                                        }}
                                                                                        _retrieveInput={
                                                                                            actions._retrieveInput
                                                                                        }
                                                                                        retrieveErrorSendBy={
                                                                                            actions.retrieveErrorSendBy
                                                                                        }
                                                                                        {...state}
                                                                                        loaded={
                                                                                            true
                                                                                        }
                                                                                    ></ModalViewEditStudy>
                                                                                }
                                                                            >
                                                                                <div
                                                                                    onclick={() => {
                                                                                        actions.buffStudyValues()
                                                                                        actions.setModaldisplayed(
                                                                                            'editsurvey-visitdate'
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    <p class="">
                                                                                        {t(
                                                                                            'Première visite :'
                                                                                        ) +
                                                                                            ' ' +
                                                                                            getReadableDate(
                                                                                                createUTCDate(
                                                                                                    getDateObjFromYMD(
                                                                                                        state
                                                                                                            .values
                                                                                                            .visitDate
                                                                                                    )
                                                                                                        .date
                                                                                                ),
                                                                                                'DD/MM/YYYY'
                                                                                            )}
                                                                                        <img
                                                                                            src={
                                                                                                icoEdit
                                                                                            }
                                                                                            srcset={`${icoEditx2} 2x`}
                                                                                            alt=""
                                                                                        />
                                                                                    </p>
                                                                                </div>
                                                                            </Modal>
                                                                        </div>
                                                                    )}
                                                                    {(state
                                                                        .values
                                                                        .surgeryDate ||
                                                                        (state.buffedValues &&
                                                                            state
                                                                                .buffedValues
                                                                                .surgeryDate)) && (
                                                                        <div class="thfStudy-Configuration-block active">
                                                                            <Modal
                                                                                key={
                                                                                    'modal-edit-survey'
                                                                                }
                                                                                cancelAction={() => {
                                                                                    actions.setModaldisplayed(
                                                                                        ''
                                                                                    )
                                                                                    actions.retrieveBuffedValues()
                                                                                }}
                                                                                modalDisplayed={
                                                                                    state.modaldisplayed.indexOf(
                                                                                        'editsurvey-surgerydate'
                                                                                    ) >
                                                                                    -1
                                                                                }
                                                                                content={
                                                                                    <ModalViewEditStudy
                                                                                        displayed={
                                                                                            'editsurvey-surgerydate'
                                                                                        }
                                                                                        onSubmit={
                                                                                            actions.onSubmit
                                                                                        }
                                                                                        cancelAction={() => {
                                                                                            actions.setModaldisplayed(
                                                                                                ''
                                                                                            )
                                                                                            actions.retrieveBuffedValues()
                                                                                        }}
                                                                                        _retrieveInput={
                                                                                            actions._retrieveInput
                                                                                        }
                                                                                        retrieveErrorSendBy={
                                                                                            actions.retrieveErrorSendBy
                                                                                        }
                                                                                        {...state}
                                                                                        loaded={
                                                                                            true
                                                                                        }
                                                                                    ></ModalViewEditStudy>
                                                                                }
                                                                            >
                                                                                <div
                                                                                    onclick={() => {
                                                                                        actions.buffStudyValues()
                                                                                        actions.setModaldisplayed(
                                                                                            'editsurvey-surgerydate'
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    <p class="">
                                                                                        {t(
                                                                                            'Chirurgie :'
                                                                                        ) +
                                                                                            ' ' +
                                                                                            getReadableDate(
                                                                                                createUTCDate(
                                                                                                    getDateObjFromYMD(
                                                                                                        state
                                                                                                            .values
                                                                                                            .surgeryDate
                                                                                                    )
                                                                                                        .date
                                                                                                ),
                                                                                                'DD/MM/YYYY'
                                                                                            )}
                                                                                        <img
                                                                                            src={
                                                                                                icoEdit
                                                                                            }
                                                                                            srcset={`${icoEditx2} 2x`}
                                                                                            alt=""
                                                                                        />
                                                                                    </p>
                                                                                </div>
                                                                            </Modal>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div class="thfStudy-Events">
                                                                <p class="thfStudy-headerLittleTitle">
                                                                    {t(
                                                                        'Événements '
                                                                    ) +
                                                                        '(' +
                                                                        state
                                                                            .currentTimeline
                                                                            .timeline
                                                                            .length +
                                                                        ')'}
                                                                </p>
                                                                <div class="thfStudy-Events-blocks">
                                                                    <table
                                                                        class="thfTable"
                                                                        key={
                                                                            'thfTable-length-' +
                                                                            state
                                                                                .currentTimeline
                                                                                .timeline
                                                                                .length
                                                                        }
                                                                    >
                                                                        <tbody>
                                                                            {state.currentTimeline.timeline.map(
                                                                                (
                                                                                    item,
                                                                                    idx
                                                                                ) => (
                                                                                    <tr
                                                                                        key={
                                                                                            'line-' +
                                                                                            idx +
                                                                                            '-' +
                                                                                            item.label
                                                                                        }
                                                                                        key={
                                                                                            'thfTable-new-' +
                                                                                            idx
                                                                                        }
                                                                                        onclick={() => {
                                                                                            if (
                                                                                                state.modaldisplayed !==
                                                                                                    '' ||
                                                                                                item.type ===
                                                                                                    'DATE' ||
                                                                                                (!isDefined(
                                                                                                    item.answered
                                                                                                ) &&
                                                                                                    item.isExpired) ||
                                                                                                !isDefined(
                                                                                                    item.surveyId
                                                                                                )
                                                                                            ) {
                                                                                                return false
                                                                                            }
                                                                                            if (
                                                                                                item.answered ===
                                                                                                    false &&
                                                                                                item.isExpired ===
                                                                                                    false
                                                                                            ) {
                                                                                                actions.setModaldisplayed(
                                                                                                    'dosurvey-' +
                                                                                                        idx
                                                                                                )
                                                                                                actions.retrieveSurvey(
                                                                                                    {
                                                                                                        item: item,
                                                                                                        userMedicalStudyToken:
                                                                                                            state
                                                                                                                .currentTimeline
                                                                                                                .urlToken,
                                                                                                    }
                                                                                                )
                                                                                            } else {
                                                                                                actions.getSurveyResults(
                                                                                                    {
                                                                                                        surveyid:
                                                                                                            item.surveyId,
                                                                                                        answerid:
                                                                                                            item.answerId,
                                                                                                        marker:
                                                                                                            'commentsurvey-' +
                                                                                                            idx,
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        {item.type ===
                                                                                        'DATE' ? (
                                                                                            <td
                                                                                                key={
                                                                                                    'case-survey-' +
                                                                                                    idx +
                                                                                                    '-date'
                                                                                                }
                                                                                                class={
                                                                                                    'thfTable-cellSurvey ' +
                                                                                                    (getDiffDate(
                                                                                                        null,
                                                                                                        createDateComplientSafari(
                                                                                                            item
                                                                                                                .date
                                                                                                                .date
                                                                                                        )
                                                                                                    ) <=
                                                                                                    0
                                                                                                        ? 'stepSuccess'
                                                                                                        : 'stepFutur') +
                                                                                                    ' ' +
                                                                                                    (isDefined(
                                                                                                        item.action
                                                                                                    )
                                                                                                        ? 'stepTreated'
                                                                                                        : '')
                                                                                                }
                                                                                                oncreate={(
                                                                                                    e
                                                                                                ) => {
                                                                                                    actions.retrieveEventLabel(
                                                                                                        {
                                                                                                            el: e,
                                                                                                            item: item,
                                                                                                        }
                                                                                                    )
                                                                                                }}
                                                                                            ></td>
                                                                                        ) : (
                                                                                            <td
                                                                                                key={
                                                                                                    'case-survey-' +
                                                                                                    idx +
                                                                                                    '-survey'
                                                                                                }
                                                                                                class={
                                                                                                    'thfTable-cellSurvey ' +
                                                                                                    (!item.inFutur &&
                                                                                                    isDefined(
                                                                                                        item.answered
                                                                                                    )
                                                                                                        ? isDefined(
                                                                                                              item.alerts
                                                                                                          ) &&
                                                                                                          item
                                                                                                              .alerts
                                                                                                              .length >
                                                                                                              0
                                                                                                            ? item
                                                                                                                  .alerts[0] ===
                                                                                                              'NODATA'
                                                                                                                ? 'stepNodata'
                                                                                                                : 'stepError'
                                                                                                            : item.answered ===
                                                                                                              true
                                                                                                            ? 'stepSuccess'
                                                                                                            : ''
                                                                                                        : isDefined(
                                                                                                              item.date
                                                                                                          ) &&
                                                                                                          getDiffDate(
                                                                                                              null,
                                                                                                              createDateComplientSafari(
                                                                                                                  item
                                                                                                                      .date
                                                                                                                      .date
                                                                                                              )
                                                                                                          ) <=
                                                                                                              0
                                                                                                        ? 'stepPast'
                                                                                                        : 'stepFutur') +
                                                                                                    ' ' +
                                                                                                    (isDefined(
                                                                                                        item.action
                                                                                                    )
                                                                                                        ? 'stepTreated'
                                                                                                        : '')
                                                                                                }
                                                                                            >
                                                                                                {(noQuestionnaireLabelFor.includes(
                                                                                                    state
                                                                                                        .currentTimeline
                                                                                                        .name
                                                                                                )
                                                                                                    ? ''
                                                                                                    : t(
                                                                                                          'Questionnaire'
                                                                                                      ) +
                                                                                                      ' ') +
                                                                                                    (isDefined(
                                                                                                        item.medicalStudyMoment
                                                                                                    )
                                                                                                        ? item.medicalStudyMoment +
                                                                                                          ' - ' +
                                                                                                          item.medicalStudySurvey
                                                                                                        : 'Ø')}
                                                                                            </td>
                                                                                        )}
                                                                                        <td class="thfTable-cellDate">
                                                                                            <img
                                                                                                src={
                                                                                                    icoCalendrier
                                                                                                }
                                                                                                srcset={`${icoCalendrierx2} 2x`}
                                                                                                alt=""
                                                                                            />
                                                                                            <p>
                                                                                                {isDefined(
                                                                                                    item.date
                                                                                                )
                                                                                                    ? getReadableDate(
                                                                                                          createUTCDate(
                                                                                                              item
                                                                                                                  .date
                                                                                                                  .date
                                                                                                          ),
                                                                                                          'DD/MM/YYYY'
                                                                                                      )
                                                                                                    : 'Ø'}
                                                                                            </p>
                                                                                        </td>
                                                                                        {item.type ===
                                                                                            'DATE' ||
                                                                                        !isDefined(
                                                                                            item.answered
                                                                                        ) ||
                                                                                        getDiffDate(
                                                                                            null,
                                                                                            createDateComplientSafari(
                                                                                                item
                                                                                                    .date
                                                                                                    .date
                                                                                            )
                                                                                        ) >=
                                                                                            0 ? (
                                                                                            <td
                                                                                                key={
                                                                                                    'case-alert-' +
                                                                                                    idx +
                                                                                                    '-date'
                                                                                                }
                                                                                                class="thfTable-cellAlert"
                                                                                            ></td>
                                                                                        ) : (
                                                                                            <td
                                                                                                key={
                                                                                                    'case-alert-' +
                                                                                                    idx +
                                                                                                    '-survey'
                                                                                                }
                                                                                                class="thfTable-cellAlert"
                                                                                            >
                                                                                                {(isDefined(
                                                                                                    item.alerts
                                                                                                ) &&
                                                                                                item
                                                                                                    .alerts
                                                                                                    .length >
                                                                                                    0 &&
                                                                                                item
                                                                                                    .alerts[0] !==
                                                                                                    'NODATA'
                                                                                                    ? item
                                                                                                          .alerts
                                                                                                          .length +
                                                                                                      ' ' +
                                                                                                      (item
                                                                                                          .alerts
                                                                                                          .length >
                                                                                                      1
                                                                                                          ? t(
                                                                                                                'alertes'
                                                                                                            )
                                                                                                          : t(
                                                                                                                'alerte'
                                                                                                            ))
                                                                                                    : item.answered ===
                                                                                                          false &&
                                                                                                      isDefined(
                                                                                                          item.alerts
                                                                                                      ) &&
                                                                                                      item
                                                                                                          .alerts[0] ===
                                                                                                          'NODATA'
                                                                                                    ? t(
                                                                                                          'Questionnaire non complété'
                                                                                                      )
                                                                                                    : '') +
                                                                                                    (isDefined(
                                                                                                        item.action
                                                                                                    ) &&
                                                                                                    item.action !==
                                                                                                        ''
                                                                                                        ? ' - ' +
                                                                                                          t(
                                                                                                              constants.custo.studyActionsAlert.filter(
                                                                                                                  (
                                                                                                                      f
                                                                                                                  ) =>
                                                                                                                      f.key ===
                                                                                                                      item.action
                                                                                                              )[0]
                                                                                                                  .label
                                                                                                          )
                                                                                                        : '')}
                                                                                            </td>
                                                                                        )}
                                                                                        {item.type ===
                                                                                            'DATE' ||
                                                                                        item.inFutur ||
                                                                                        !isDefined(
                                                                                            item.commentsCount
                                                                                        ) ? (
                                                                                            <td
                                                                                                key={
                                                                                                    'case-comment-' +
                                                                                                    idx +
                                                                                                    '-date'
                                                                                                }
                                                                                                class="thfTable-cellComment"
                                                                                            ></td>
                                                                                        ) : (
                                                                                            <td
                                                                                                key={
                                                                                                    'case-comment-' +
                                                                                                    idx +
                                                                                                    '-survey'
                                                                                                }
                                                                                                class={
                                                                                                    'thfTable-cellComment active'
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    src={
                                                                                                        icoMessageOff
                                                                                                    }
                                                                                                    srcset={`${icoMessageOffx2} 2x`}
                                                                                                    alt=""
                                                                                                />
                                                                                                <p>
                                                                                                    {
                                                                                                        item.commentsCount
                                                                                                    }
                                                                                                </p>
                                                                                            </td>
                                                                                        )}
                                                                                        {item.type ===
                                                                                            'DATE' ||
                                                                                        item.inFutur ||
                                                                                        !isDefined(
                                                                                            item.surveyId
                                                                                        ) ? (
                                                                                            <td
                                                                                                key={
                                                                                                    'case-answer-' +
                                                                                                    idx +
                                                                                                    '-date'
                                                                                                }
                                                                                                class="thfTable-cellAnswerSurvey"
                                                                                            ></td>
                                                                                        ) : (
                                                                                            <td
                                                                                                key={
                                                                                                    'case-answer-' +
                                                                                                    idx +
                                                                                                    '-survey'
                                                                                                }
                                                                                                class="thfTable-cellAnswerSurvey"
                                                                                            >
                                                                                                {item.answered ===
                                                                                                    false &&
                                                                                                item.isExpired ===
                                                                                                    false ? (
                                                                                                    <Modal
                                                                                                        key={
                                                                                                            'modal-do-survey-' +
                                                                                                            idx
                                                                                                        }
                                                                                                        customClass="modal-central"
                                                                                                        cancelAction={() => {
                                                                                                            actions.closeSurveyIframe()
                                                                                                        }}
                                                                                                        modalDisplayed={
                                                                                                            state.modaldisplayed.indexOf(
                                                                                                                'dosurvey-' +
                                                                                                                    idx
                                                                                                            ) >
                                                                                                            -1
                                                                                                        }
                                                                                                        content={
                                                                                                            <ModalViewDoSurvey
                                                                                                                displayed={
                                                                                                                    'dosurvey-' +
                                                                                                                    idx
                                                                                                                }
                                                                                                                cancelAction={() => {
                                                                                                                    actions.closeSurveyIframe()
                                                                                                                }}
                                                                                                                {...state}
                                                                                                                loaded={
                                                                                                                    true
                                                                                                                }
                                                                                                            ></ModalViewDoSurvey>
                                                                                                        }
                                                                                                    >
                                                                                                        <span>
                                                                                                            {t(
                                                                                                                'Compléter le questionnaire'
                                                                                                            )}
                                                                                                        </span>
                                                                                                    </Modal>
                                                                                                ) : (
                                                                                                    <Modal
                                                                                                        key={
                                                                                                            'modal-comment-survey-' +
                                                                                                            idx
                                                                                                        }
                                                                                                        customClass="modal-central modal-multi-modal"
                                                                                                        cancelAction={() => {
                                                                                                            actions.clearModalState()
                                                                                                        }}
                                                                                                        modalDisplayed={
                                                                                                            state.modaldisplayed.indexOf(
                                                                                                                'commentsurvey-' +
                                                                                                                    idx
                                                                                                            ) >
                                                                                                            -1
                                                                                                        }
                                                                                                        content={
                                                                                                            <ModalViewCommentStudy
                                                                                                                displayed={
                                                                                                                    'commentsurvey-' +
                                                                                                                    idx
                                                                                                                }
                                                                                                                cancelAction={() => {
                                                                                                                    actions.clearModalState()
                                                                                                                }}
                                                                                                                onSubmit={
                                                                                                                    actions.updatePatientState
                                                                                                                }
                                                                                                                setModaldisplayed={
                                                                                                                    actions.setModaldisplayed
                                                                                                                }
                                                                                                                _setState={
                                                                                                                    actions._setState
                                                                                                                }
                                                                                                                retrieveStudyAnswer={
                                                                                                                    actions.retrieveSurvey
                                                                                                                }
                                                                                                                survey={
                                                                                                                    item
                                                                                                                }
                                                                                                                urlToken={
                                                                                                                    state
                                                                                                                        .currentTimeline
                                                                                                                        .urlToken
                                                                                                                }
                                                                                                                updateCurrentActionState={
                                                                                                                    actions.updateCurrentActionState
                                                                                                                }
                                                                                                                commentCallback={
                                                                                                                    actions.commentCallback
                                                                                                                }
                                                                                                                {...state}
                                                                                                                loaded={
                                                                                                                    true
                                                                                                                }
                                                                                                            ></ModalViewCommentStudy>
                                                                                                        }
                                                                                                    >
                                                                                                        <span
                                                                                                            id={
                                                                                                                'getsurvey-' +
                                                                                                                item.surveyId
                                                                                                            }
                                                                                                        >
                                                                                                            {t(
                                                                                                                'Voir les résultats'
                                                                                                            )}
                                                                                                        </span>
                                                                                                    </Modal>
                                                                                                )}
                                                                                            </td>
                                                                                        )}
                                                                                        <td class="thfTable-cellMore">
                                                                                            <Form
                                                                                                onsubmit={(
                                                                                                    event
                                                                                                ) =>
                                                                                                    event.preventDefault()
                                                                                                }
                                                                                                classes={
                                                                                                    'hidden-field three-little-dot'
                                                                                                }
                                                                                            >
                                                                                                <Form.Group>
                                                                                                    <FormInput
                                                                                                        key={
                                                                                                            'surveyexport'
                                                                                                        }
                                                                                                        type="hidden-dropdown"
                                                                                                        id={
                                                                                                            'surveyexport'
                                                                                                        }
                                                                                                        name=""
                                                                                                        list={[]}
                                                                                                        oninputcb={
                                                                                                            ''
                                                                                                        }
                                                                                                        required
                                                                                                    />
                                                                                                </Form.Group>
                                                                                            </Form>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                    <div
                                                                        class={
                                                                            'btzButtonsWrapperNoBorderButton'
                                                                        }
                                                                        style="display: block;text-align: center;margin: 25px auto auto;"
                                                                    >
                                                                        <div style="display: inline-block;">
                                                                            <Modal
                                                                                key={
                                                                                    'modal-end-survey'
                                                                                }
                                                                                customClass="modal-central"
                                                                                cancelAction={() => {
                                                                                    actions.setModaldisplayed(
                                                                                        ''
                                                                                    )
                                                                                }}
                                                                                modalDisplayed={
                                                                                    state.modaldisplayed.indexOf(
                                                                                        'endsurvey'
                                                                                    ) >
                                                                                    -1
                                                                                }
                                                                                content={
                                                                                    <ModalViewEndStudy
                                                                                        displayed={
                                                                                            'endsurvey'
                                                                                        }
                                                                                        confirmAction={
                                                                                            actions.endStudy
                                                                                        }
                                                                                        cancelAction={() => {
                                                                                            actions.setModaldisplayed(
                                                                                                ''
                                                                                            )
                                                                                        }}
                                                                                        {...state}
                                                                                        loaded={
                                                                                            true
                                                                                        }
                                                                                    ></ModalViewEndStudy>
                                                                                }
                                                                            >
                                                                                <div
                                                                                    onclick={() => {
                                                                                        actions.setModaldisplayed(
                                                                                            'endsurvey'
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    <Button
                                                                                        primary
                                                                                        active
                                                                                    >
                                                                                        {t(
                                                                                            'Terminer et commencer un nouveau parcours'
                                                                                        )}
                                                                                    </Button>
                                                                                </div>
                                                                            </Modal>
                                                                        </div>
                                                                        <div style="display: inline-block;margin-left: 25px;vertical-align: middle;">
                                                                            <div class="btzButtonWrapper">
                                                                                <a
                                                                                    class="btn btn-primary btn-blueFont"
                                                                                    href={
                                                                                        API_URL +
                                                                                        '/theraflow/medical-study-oneoff/export/' +
                                                                                        props
                                                                                            .editedPatient
                                                                                            .id +
                                                                                        '/study/' +
                                                                                        state
                                                                                            .currentTimeline
                                                                                            .userMedicalStudyId +
                                                                                        '/' +
                                                                                        '?xtoken=' +
                                                                                        encodeURIComponent(
                                                                                            localStorage.getItem(
                                                                                                'apitoken'
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                    target="_blank"
                                                                                >
                                                                                    <img
                                                                                        class=""
                                                                                        src={
                                                                                            icoExport
                                                                                        }
                                                                                        srcset={`${icoExportx2} 2x`}
                                                                                        alt=""
                                                                                        style="display: inline-block;margin-right: 10px;vertical-align: middle;"
                                                                                    />
                                                                                    {t(
                                                                                        'Exporter tous les questionnaires'
                                                                                    )}
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Tile.Content>
                                                    </Tile>
                                                </dummy>
                                            )}
                                        {state.pastTimelines
                                            .filter(
                                                (pst) =>
                                                    pst.name ===
                                                    state.values.name
                                            )
                                            .map((ptm, pidx) => (
                                                <dummy key={'pasttm-' + pidx}>
                                                    <Tile
                                                        style={{
                                                            'margin-top':
                                                                '30px',
                                                        }}
                                                        classes={'folded'}
                                                    >
                                                        <div
                                                            class="thfSurveyReport-header"
                                                            onclick={(el) => {
                                                                el.target.parentElement.classList.toggle(
                                                                    'folded'
                                                                )
                                                            }}
                                                        >
                                                            <span>
                                                                {state.pastTimelines.filter(
                                                                    (pst) =>
                                                                        pst.name ===
                                                                        state
                                                                            .values
                                                                            .name
                                                                ).length - pidx}
                                                            </span>
                                                            <p class="">
                                                                {t(
                                                                    'Questionnaire'
                                                                ) +
                                                                    (isDefined(
                                                                        state.studies.filter(
                                                                            (
                                                                                f
                                                                            ) =>
                                                                                f.key ===
                                                                                ptm.name
                                                                        )[0]
                                                                    )
                                                                        ? ' ' +
                                                                          state.studies.filter(
                                                                              (
                                                                                  f
                                                                              ) =>
                                                                                  f.key ===
                                                                                  ptm.name
                                                                          )[0]
                                                                              .label
                                                                        : '')}
                                                            </p>
                                                            <img
                                                                src={icoFold}
                                                                srcset={`${icoFoldx2} 2x`}
                                                                alt=""
                                                            />
                                                            <Button
                                                                primary
                                                                flat
                                                                dangerous
                                                            >
                                                                {t('Terminé')}
                                                            </Button>
                                                        </div>
                                                        <Tile.Content
                                                            style={{
                                                                'margin-top':
                                                                    '30px',
                                                            }}
                                                        >
                                                            <div class="thfStudy-Configuration">
                                                                <p class="thfStudy-headerLittleTitle">
                                                                    {t(
                                                                        'Configuration'
                                                                    )}
                                                                </p>
                                                                <div class="thfStudy-Configuration-blocks">
                                                                    <div class="thfStudy-Configuration-block">
                                                                        <p class="">
                                                                            {t(
                                                                                "Mode d'émission :"
                                                                            ) +
                                                                                ' ' +
                                                                                (ptm
                                                                                    .userMedicalStudyDatas
                                                                                    .issueSentBy ===
                                                                                'SMS'
                                                                                    ? t(
                                                                                          'par SMS'
                                                                                      )
                                                                                    : 'par EMAIL')}
                                                                            <img
                                                                                src={
                                                                                    icoEdit
                                                                                }
                                                                                srcset={`${icoEditx2} 2x`}
                                                                                alt=""
                                                                            />
                                                                        </p>
                                                                    </div>
                                                                    {isDefined(
                                                                        ptm
                                                                            .userMedicalStudyDatas
                                                                            .visitDate
                                                                    ) &&
                                                                        isDefined(
                                                                            ptm
                                                                                .userMedicalStudyDatas
                                                                                .visitDate
                                                                                .date
                                                                        ) && (
                                                                            <div class="thfStudy-Configuration-block">
                                                                                <p class="">
                                                                                    {t(
                                                                                        'Première visite :'
                                                                                    ) +
                                                                                        ' ' +
                                                                                        getReadableDate(
                                                                                            createUTCDate(
                                                                                                ptm
                                                                                                    .userMedicalStudyDatas
                                                                                                    .visitDate
                                                                                                    .date
                                                                                            ),
                                                                                            'DD/MM/YYYY'
                                                                                        )}
                                                                                    <img
                                                                                        src={
                                                                                            icoEdit
                                                                                        }
                                                                                        srcset={`${icoEditx2} 2x`}
                                                                                        alt=""
                                                                                    />
                                                                                </p>
                                                                            </div>
                                                                        )}
                                                                    {isDefined(
                                                                        ptm
                                                                            .userMedicalStudyDatas
                                                                            .surgeryDate
                                                                    ) &&
                                                                        isDefined(
                                                                            ptm
                                                                                .userMedicalStudyDatas
                                                                                .surgeryDate
                                                                                .date
                                                                        ) && (
                                                                            <div class="thfStudy-Configuration-block">
                                                                                <p class="">
                                                                                    {t(
                                                                                        'Chirurgie :'
                                                                                    ) +
                                                                                        ' ' +
                                                                                        getReadableDate(
                                                                                            createUTCDate(
                                                                                                ptm
                                                                                                    .userMedicalStudyDatas
                                                                                                    .surgeryDate
                                                                                                    .date
                                                                                            ),
                                                                                            'DD/MM/YYYY'
                                                                                        )}
                                                                                    <img
                                                                                        src={
                                                                                            icoEdit
                                                                                        }
                                                                                        srcset={`${icoEditx2} 2x`}
                                                                                        alt=""
                                                                                    />
                                                                                </p>
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </div>
                                                            <div class="thfStudy-Events">
                                                                <p class="thfStudy-headerLittleTitle">
                                                                    {t(
                                                                        'Événements'
                                                                    )}
                                                                </p>
                                                                <div class="thfStudy-Events-blocks">
                                                                    <table
                                                                        class="thfTable"
                                                                        key={
                                                                            'thfTable-pastlength-' +
                                                                            state
                                                                                .pastTimelines
                                                                                .length +
                                                                            '-' +
                                                                            pidx
                                                                        }
                                                                    >
                                                                        <tbody>
                                                                            {ptm.timeline.map(
                                                                                (
                                                                                    item,
                                                                                    idx
                                                                                ) => (
                                                                                    <tr
                                                                                        key={
                                                                                            'thfTable-past-' +
                                                                                            pidx +
                                                                                            '-' +
                                                                                            idx
                                                                                        }
                                                                                        onclick={() => {
                                                                                            if (
                                                                                                state.modaldisplayed !==
                                                                                                    '' ||
                                                                                                !isDefined(
                                                                                                    item.answerId
                                                                                                ) ||
                                                                                                item.type ===
                                                                                                    'DATE'
                                                                                            ) {
                                                                                                return false
                                                                                            }
                                                                                            actions.getSurveyResults(
                                                                                                {
                                                                                                    surveyid:
                                                                                                        item.surveyId,
                                                                                                    answerid:
                                                                                                        item.answerId,
                                                                                                    marker:
                                                                                                        'commentsurvey-' +
                                                                                                        idx +
                                                                                                        '-' +
                                                                                                        pidx,
                                                                                                }
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        {item.type ===
                                                                                        'DATE' ? (
                                                                                            <td
                                                                                                class={
                                                                                                    'thfTable-cellSurvey ' +
                                                                                                    (getDiffDate(
                                                                                                        null,
                                                                                                        createDateComplientSafari(
                                                                                                            item
                                                                                                                .date
                                                                                                                .date
                                                                                                        )
                                                                                                    ) <=
                                                                                                    0
                                                                                                        ? 'stepSuccess'
                                                                                                        : 'stepError') +
                                                                                                    ' ' +
                                                                                                    (isDefined(
                                                                                                        item.action
                                                                                                    )
                                                                                                        ? 'stepTreated'
                                                                                                        : '')
                                                                                                }
                                                                                                oncreate={(
                                                                                                    e
                                                                                                ) => {
                                                                                                    actions.retrieveEventLabel(
                                                                                                        {
                                                                                                            el: e,
                                                                                                            item: item,
                                                                                                        }
                                                                                                    )
                                                                                                }}
                                                                                            ></td>
                                                                                        ) : (
                                                                                            <td
                                                                                                class={
                                                                                                    'thfTable-cellSurvey ' +
                                                                                                    (!item.inFutur &&
                                                                                                    isDefined(
                                                                                                        item.answered
                                                                                                    )
                                                                                                        ? isDefined(
                                                                                                              item.alerts
                                                                                                          ) &&
                                                                                                          item
                                                                                                              .alerts
                                                                                                              .length >
                                                                                                              0
                                                                                                            ? item
                                                                                                                  .alerts[0] ===
                                                                                                              'NODATA'
                                                                                                                ? 'stepNodata'
                                                                                                                : 'stepError'
                                                                                                            : item.answered ===
                                                                                                              true
                                                                                                            ? 'stepSuccess'
                                                                                                            : ''
                                                                                                        : 'stepFutur') +
                                                                                                    ' ' +
                                                                                                    (isDefined(
                                                                                                        item.action
                                                                                                    )
                                                                                                        ? 'stepTreated'
                                                                                                        : '')
                                                                                                }
                                                                                            >
                                                                                                {(noQuestionnaireLabelFor.includes(
                                                                                                    ptm
                                                                                                        .timeline
                                                                                                        .name
                                                                                                )
                                                                                                    ? ''
                                                                                                    : t(
                                                                                                          'Questionnaire'
                                                                                                      ) +
                                                                                                      ' ') +
                                                                                                    (isDefined(
                                                                                                        item.medicalStudyMoment
                                                                                                    )
                                                                                                        ? item.medicalStudyMoment +
                                                                                                          ' - ' +
                                                                                                          item.medicalStudySurvey
                                                                                                        : 'Ø')}
                                                                                            </td>
                                                                                        )}
                                                                                        <td class="thfTable-cellDate">
                                                                                            <img
                                                                                                src={
                                                                                                    icoCalendrier
                                                                                                }
                                                                                                srcset={`${icoCalendrierx2} 2x`}
                                                                                                alt=""
                                                                                            />
                                                                                            <p>
                                                                                                {isDefined(
                                                                                                    item.date
                                                                                                )
                                                                                                    ? getReadableDate(
                                                                                                          createUTCDate(
                                                                                                              item
                                                                                                                  .date
                                                                                                                  .date
                                                                                                          ),
                                                                                                          'DD/MM/YYYY'
                                                                                                      )
                                                                                                    : 'Ø'}
                                                                                            </p>
                                                                                        </td>
                                                                                        {item.type ===
                                                                                            'DATE' ||
                                                                                        !isDefined(
                                                                                            item.answered
                                                                                        ) ? (
                                                                                            <td class="thfTable-cellAlert"></td>
                                                                                        ) : (
                                                                                            <td class="thfTable-cellAlert">
                                                                                                {(item.answered ===
                                                                                                    false &&
                                                                                                isDefined(
                                                                                                    item.alerts
                                                                                                ) &&
                                                                                                item
                                                                                                    .alerts[0] ===
                                                                                                    'NODATA'
                                                                                                    ? t(
                                                                                                          'Questionnaire non complété'
                                                                                                      )
                                                                                                    : isDefined(
                                                                                                          item.alerts
                                                                                                      ) &&
                                                                                                      item
                                                                                                          .alerts
                                                                                                          .length >
                                                                                                          0
                                                                                                    ? item
                                                                                                          .alerts
                                                                                                          .length +
                                                                                                      ' ' +
                                                                                                      (item
                                                                                                          .alerts
                                                                                                          .length >
                                                                                                      1
                                                                                                          ? t(
                                                                                                                'alertes'
                                                                                                            )
                                                                                                          : t(
                                                                                                                'alerte'
                                                                                                            ))
                                                                                                    : '') +
                                                                                                    (isDefined(
                                                                                                        item.action
                                                                                                    ) &&
                                                                                                    item.action !==
                                                                                                        ''
                                                                                                        ? ' - ' +
                                                                                                          t(
                                                                                                              constants.custo.studyActionsAlert.filter(
                                                                                                                  (
                                                                                                                      f
                                                                                                                  ) =>
                                                                                                                      f.key ===
                                                                                                                      item.action
                                                                                                              )[0]
                                                                                                                  .label
                                                                                                          )
                                                                                                        : '')}
                                                                                            </td>
                                                                                        )}
                                                                                        {item.type ===
                                                                                            'DATE' ||
                                                                                        !isDefined(
                                                                                            item.answerId
                                                                                        ) ? (
                                                                                            <td class="thfTable-cellComment"></td>
                                                                                        ) : (
                                                                                            <td
                                                                                                class={
                                                                                                    'thfTable-cellComment ' +
                                                                                                    (item.commentsCount >
                                                                                                    0
                                                                                                        ? 'active'
                                                                                                        : '')
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    src={
                                                                                                        icoMessageOff
                                                                                                    }
                                                                                                    srcset={`${icoMessageOffx2} 2x`}
                                                                                                    alt=""
                                                                                                />
                                                                                                <p>
                                                                                                    {
                                                                                                        item.commentsCount
                                                                                                    }
                                                                                                </p>
                                                                                            </td>
                                                                                        )}
                                                                                        {item.type ===
                                                                                            'DATE' ||
                                                                                        !isDefined(
                                                                                            item.answerId
                                                                                        ) ? (
                                                                                            <td class="thfTable-cellAnswerSurvey"></td>
                                                                                        ) : (
                                                                                            <td
                                                                                                key={
                                                                                                    'case-answer-' +
                                                                                                    idx +
                                                                                                    '-survey'
                                                                                                }
                                                                                                class="thfTable-cellAnswerSurvey"
                                                                                            >
                                                                                                {item.answered ===
                                                                                                    false &&
                                                                                                item.isExpired ===
                                                                                                    false ? (
                                                                                                    <Modal
                                                                                                        key={
                                                                                                            'modal-do-survey-' +
                                                                                                            idx
                                                                                                        }
                                                                                                        customClass="modal-central"
                                                                                                        cancelAction={() => {
                                                                                                            actions.closeSurveyIframe()
                                                                                                        }}
                                                                                                        modalDisplayed={
                                                                                                            state.modaldisplayed.indexOf(
                                                                                                                'dosurvey-' +
                                                                                                                    idx
                                                                                                            ) >
                                                                                                            -1
                                                                                                        }
                                                                                                        content={
                                                                                                            <ModalViewDoSurvey
                                                                                                                displayed={
                                                                                                                    'dosurvey-' +
                                                                                                                    idx
                                                                                                                }
                                                                                                                cancelAction={() => {
                                                                                                                    actions.closeSurveyIframe()
                                                                                                                }}
                                                                                                                {...state}
                                                                                                                loaded={
                                                                                                                    true
                                                                                                                }
                                                                                                            ></ModalViewDoSurvey>
                                                                                                        }
                                                                                                    >
                                                                                                        <span>
                                                                                                            {t(
                                                                                                                'Compléter le questionnaire'
                                                                                                            )}
                                                                                                        </span>
                                                                                                    </Modal>
                                                                                                ) : (
                                                                                                    <Modal
                                                                                                        key={
                                                                                                            'modal-comment-survey-' +
                                                                                                            idx
                                                                                                        }
                                                                                                        customClass="modal-central modal-multi-modal"
                                                                                                        cancelAction={() => {
                                                                                                            actions.clearModalState()
                                                                                                        }}
                                                                                                        modalDisplayed={
                                                                                                            state.modaldisplayed.indexOf(
                                                                                                                'commentsurvey-' +
                                                                                                                    idx
                                                                                                            ) >
                                                                                                            -1
                                                                                                        }
                                                                                                        content={
                                                                                                            <ModalViewCommentStudy
                                                                                                                displayed={
                                                                                                                    'commentsurvey-' +
                                                                                                                    idx
                                                                                                                }
                                                                                                                cancelAction={() => {
                                                                                                                    actions.clearModalState()
                                                                                                                }}
                                                                                                                onSubmit={
                                                                                                                    actions.updatePatientState
                                                                                                                }
                                                                                                                setModaldisplayed={
                                                                                                                    actions.setModaldisplayed
                                                                                                                }
                                                                                                                _setState={
                                                                                                                    actions._setState
                                                                                                                }
                                                                                                                retrieveStudyAnswer={
                                                                                                                    actions.retrieveSurvey
                                                                                                                }
                                                                                                                survey={
                                                                                                                    item
                                                                                                                }
                                                                                                                urlToken={
                                                                                                                    state
                                                                                                                        .currentTimeline
                                                                                                                        ?.urlToken
                                                                                                                }
                                                                                                                updateCurrentActionState={
                                                                                                                    actions.updateCurrentActionState
                                                                                                                }
                                                                                                                commentCallback={
                                                                                                                    actions.commentCallback
                                                                                                                }
                                                                                                                {...state}
                                                                                                                loaded={
                                                                                                                    true
                                                                                                                }
                                                                                                            ></ModalViewCommentStudy>
                                                                                                        }
                                                                                                    >
                                                                                                        <span
                                                                                                            id={
                                                                                                                'getsurvey-' +
                                                                                                                item.surveyId
                                                                                                            }
                                                                                                        >
                                                                                                            {t(
                                                                                                                'Voir les résultats'
                                                                                                            )}
                                                                                                        </span>
                                                                                                    </Modal>
                                                                                                )}
                                                                                            </td>
                                                                                        )}
                                                                                        <td class="thfTable-cellMore">
                                                                                            <Form
                                                                                                onsubmit={(
                                                                                                    event
                                                                                                ) =>
                                                                                                    event.preventDefault()
                                                                                                }
                                                                                                classes={
                                                                                                    'hidden-field three-little-dot'
                                                                                                }
                                                                                            >
                                                                                                <Form.Group>
                                                                                                    <FormInput
                                                                                                        key={
                                                                                                            'surveyexport'
                                                                                                        }
                                                                                                        type="hidden-dropdown"
                                                                                                        id={
                                                                                                            'surveyexport'
                                                                                                        }
                                                                                                        name=""
                                                                                                        list={[]}
                                                                                                        oninputcb={
                                                                                                            ''
                                                                                                        }
                                                                                                        required
                                                                                                    />
                                                                                                </Form.Group>
                                                                                            </Form>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                    <div
                                                                        class={
                                                                            'btzButtonsWrapperNoBorderButton'
                                                                        }
                                                                        style="display: block; text-align: center"
                                                                    >
                                                                        <div style="display: inline-block;max-width: 210px;margin: 25px auto auto;">
                                                                            <Button
                                                                                primary
                                                                                flat
                                                                                dangerous
                                                                            >
                                                                                {t(
                                                                                    'Envois terminés'
                                                                                )}
                                                                            </Button>
                                                                        </div>
                                                                        <div style="display: inline-block;margin-left: 25px;vertical-align: middle;">
                                                                            <div class="btzButtonWrapper">
                                                                                <a
                                                                                    class="btn btn-primary btn-blueFont"
                                                                                    href={
                                                                                        API_URL +
                                                                                        '/theraflow/medical-study-oneoff/export/' +
                                                                                        props
                                                                                            .editedPatient
                                                                                            .id +
                                                                                        '/study/' +
                                                                                        ptm.userMedicalStudyId +
                                                                                        '/' +
                                                                                        '?xtoken=' +
                                                                                        encodeURIComponent(
                                                                                            localStorage.getItem(
                                                                                                'apitoken'
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                    target="_blank"
                                                                                >
                                                                                    <img
                                                                                        class=""
                                                                                        src={
                                                                                            icoExport
                                                                                        }
                                                                                        srcset={`${icoExportx2} 2x`}
                                                                                        alt=""
                                                                                        style="display: inline-block;margin-right: 10px;vertical-align: middle;"
                                                                                    />
                                                                                    {t(
                                                                                        'Exporter tous les questionnaires'
                                                                                    )}
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Tile.Content>
                                                    </Tile>
                                                </dummy>
                                            ))}
                                    </dummy>
                                )}
                            </dummy>
                        </Enter>
                    </Card.Header>
                </Card>
            </div>
        )
        return view
    },
}
