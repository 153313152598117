import { t } from 'i18next';
import { h } from 'hyperapp';
import { Component } from '@app/utils';
import './index.scss';

const CustomSelect = Component(
    {
        displayed: false,
        list: [],
        selected: 'Tous',
        action: null,
        name: 'default',
        label: '',
        prefix: '',
    },
    {
        onComponentCreate: (props) => (state) => {
            if (props.list) {
                state.list = Object.keys(props.list).map(i => props.list[i]);
            }
            if (props.action) {
                state.action = props.action;
            }
            if (props.name) {
                state.name = props.name;
            }
            if (props.label) {
                state.label = props.label;
            }
            if (props.prefix) {
                state.prefix = props.prefix;
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.list) {
                state.list = Object.keys(props.list).map(i => props.list[i]);
            }
            if ((props.selecteds !== undefined) && (props.selecteds !== null)) {
                let selected = props.selecteds[state.name];
                if ((selected === undefined) || (selected === '')) {
                    actions.setSelected('Tous');
                }
            }
        },
        launchFilter: (filter) => (state, actions) => {
            if (filter === null) {
                actions.setSelected('Tous');
                state.action(null);
            } else {
                actions.setSelected(filter.value);
                filter.name = state.name;
                state.action(filter);
            }
        },
        setDisplayed: newState => state => ({
            displayed: newState,
        }),
        setSelected: newState => state => ({
            selected: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div class='sup-group-form sup-top-wrapper-filter-wrapper' tabindex='0' data-type={state.name} onblur={() => {actions.setDisplayed(false)}}>
            <p class='sup-grey-italic-title'>{state.prefix + ' '} <span>{t('par') + ' ' + (state.label === '' ? state.name : state.label)}</span></p>
            <div class='sup-custom-select ' data-name={state.name} onclick={() => { actions.setDisplayed(state.displayed === false ? true : false); }}>
                <div class='sup-custom-select-default-label'>{state.selected}</div>
                <div class='sup-drop-down'></div>
                <ul class='sup-custom-select-ul' style={{ 'display': (state.displayed ? 'block' : 'none') }}>
                    <li class='sup-custom-select-li' onclick={() => { actions.launchFilter(null); }}>Tous</li>
                    {state.list.filter((e) => e.value !== '').map(l =>
                        <li class='sup-custom-select-li' onclick={() => { actions.launchFilter(l); }}>{l.value}</li>
                    )}
                </ul>
            </div>
        </div>
    ),
    'customselect'
);

export { CustomSelect };
