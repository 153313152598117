import {
    Login,
    Patients,
    Patient,
    Alerts,
    Alertsic,
    Alertscurve,
    Alertsrds,
    Account,
    Mentions,
    Help,
    Consent,
    RedirectingWay,
} from '@app/modules';

const allRoutes = {
    'index': {
        label: 'Accueil',
        pathname: '/',
        component: Login,
        parent: false,
    },
    'index.reset': {
        label: 'Mot de passe',
        pathname: '/login/recovery',
        component: Login,
        parent: false,
    },
    'index.mentions': {
        label: 'Mentions',
        pathname: '/public/mentions',
        component: Mentions,
        parent: false,
    },
    'public.redirectingway': {
        label: 'Redirection',
        pathname: '/chv',
        component: RedirectingWay,
        parent: false,
    },
    'public.redirectingway.ijb': {
        label: 'Redirection',
        pathname: '/ijb',
        component: RedirectingWay,
        parent: false,
    },
    'private.mentions': {
        label: 'Mentions',
        pathname: '/mentions',
        component: Mentions,
        parent: false,
    },
    'private.help': {
        label: 'Aide',
        pathname: '/help',
        component: Help,
        parent: false,
    },
    'private.consent': {
        label: 'Consentement',
        pathname: '/consent',
        component: Consent,
        parent: false,
    },
    'private.patient.edit': {
        label: 'Admin',
        pathname: '/patient/edit/:id',
        component: Patient,
        parent: false,
    },
    'private.patient.add': {
        label: 'Admin',
        pathname: '/patient/add',
        component: Patient,
        parent: false,
    },
    'private.patients.listing': {
        label: 'Admin',
        pathname: '/patients/listing',
        component: Patients,
        parent: false,
    },
    'private.alerts.listing': {
        label: 'Admin',
        pathname: '/alerts/listing',
        component: Alerts,
        parent: false,
    },
    'private.alertsic.listing': {
        label: 'Admin',
        pathname: '/alertsic/listing',
        component: Alertsic,
        parent: false,
    },
    'private.alertscurve.listing': {
        label: 'Admin',
        pathname: '/alertscurve/listing',
        component: Alertscurve,
        parent: false,
    },
    'private.alertsrds.listing': {
        label: 'Admin',
        pathname: '/alertsrds/listing',
        component: Alertsrds,
        parent: false,
    },
    'private.account': {
        label: 'Admin',
        pathname: '/account/edit',
        component: Account,
        parent: false,
    },

    // 'private.programs.edit': {
    //     label: 'programs',
    //     pathname: '/content/programs/:id',
    //     component: Programs,
    //     parent: false,
    // },
};

export { allRoutes };
