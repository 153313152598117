import { t } from 'i18next';
import { Phone, Admin } from '@app/api';
import { enableTheseButton, desableTheseButton, coreFn } from '@app/core';
import { allRoutes, addCSRFToken, isDefined, ensurePasswordStrength, redirectToGoodIndex } from '@app/core';

/**
 * @module LoginRecovery
 * @description Recovery page
 */

export default {
    initAction: function() {
        let LoginRecovery = {
            onComponentCreate: (props) => (state, actions) => {
                actions.initRecovery();
            },
            /**
            * Initialize recovery page, set <enter> keyboard key listener
            * @function LoginRecovery:initRecovery
            * @param {object} props - /
            */
            initRecovery: (props) => (state, actions) => {
                window.onkeyup = function(e) {
                    var key = e.keyCode ? e.keyCode : e.which;
                    if (key === 13) {
                        e.preventDefault();
                        e.stopPropagation();
                        actions.onSubmit(e);
                        return false;
                    }
                }
            },
            /**
            * Submit recovery form, validation and error message displaying
            * @function LoginRecovery:onSubmit
            */
            onSubmit: event => (state, actions) => {
                event.preventDefault();
                event.stopPropagation();

                if (state.formIsSubmitting) {
                    return false;
                }
                actions._setTypingFalse();
                actions._retrieveErrorMessageDoctor(null);
                if (state.recoveryStep === 'email') {
                    actions.onSubmitEmail(event);
                } else if (state.recoveryStep === 'code') {
                    actions.onSubmitCode(event);
                } else if (state.recoveryStep === 'secret') {
                    actions.onSubmitSecret(event);
                } else if (state.recoveryStep === 'password') {
                    actions.onSubmitPassword(event);
                }
            },
            /**
            * Ask for a reset from email
            * @function LoginRecovery:onSubmitEmail
            */
            onSubmitEmail: event => (state, actions) => {
                event.preventDefault();
                event.stopPropagation();

                actions._setTypingFalse();
                actions._retrieveErrorMessageDoctor(null);

                if (state.isValid.email) {
                    actions._setState({key: 'formIsSubmitting', value: true});
                    Admin.askReset({email: state.values.email}).then((res) => {
                        if (isDefined(res.data.message) && (res.data.message.toLowerCase() === 'ok')) {
                            actions._setState({key: 'recoveryStep', value: 'code'});
                            actions._setState({key: 'formSubmittedOnce', value: false});
                        } else {
                            actions._setErrorMessages({'email': t('cette adresse email n’est pas enregistrée sur le service')});
                            actions._setValidity({'email': false});
                        }
                        actions._setState({key: 'formIsSubmitting', value: false});
                    });
                } else {
                    //
                }
            },
            /**
            * Use code to verify the recovery legit
            * @function LoginRecovery:onSubmitCode
            */
            onSubmitCode: event => (state, actions) => {
                event.preventDefault();
                event.stopPropagation();

                if (state.formIsSubmitting) {
                    return false;
                }
                actions._setTypingFalse();
                actions._retrieveErrorMessageDoctor(null);

                if (state.isValid.code) {
                    actions._setState({key: 'formIsSubmitting', value: true});
                    Admin.checkReset({email: state.values.email, code: state.values.code.join('')}).then((res) => {
                        actions._setState({key: 'formIsSubmitting', value: false});
                        if (isDefined(res.data.message) && (res.data.message.toLowerCase() === 'ok')) {
                            actions._setErrorMessages({'secret': ''});
                            actions._setValues({ 'question': res.data.question });
                            actions._setState({key: 'recoveryStep', value: 'secret'});
                            actions._setState({key: 'formSubmittedOnce', value: false});
                        } else {
                            if (isDefined(res.data.message) && (+res.data.code === 432)) {
                                actions._setErrorMessages({'code': t('ce code ne semble pas valable')});
                                actions._setValidity({'code': false});
                            } else {
                                actions._setErrorMessages({'code': res.data.message});
                            }
                        }
                    });
                } else {
                    //
                }
            },
            /**
            * Use secret to verify the recovery legit
            * @function LoginRecovery:onSubmitSecret
            */
            onSubmitSecret: event => (state, actions) => {
                event.preventDefault();
                event.stopPropagation();

                if (state.formIsSubmitting) {
                    return false;
                }
                actions._setTypingFalse();
                actions._retrieveErrorMessageDoctor(null);

                if (state.isValid.secret) {
                    actions._setState({key: 'recoveryStep', value: 'password'});
                    actions._setState({key: 'formSubmittedOnce', value: false});
                } else {
                    //
                }
            },
            /**
            * Send the new password, then log doctor and redirect him to alerts listing
            * @function LoginRecovery:onSubmitPassword
            */
            onSubmitPassword: event => (state, actions) => {
                event.preventDefault();
                event.stopPropagation();

                if (state.formIsSubmitting) {
                    return false;
                }
                actions._setTypingFalse();
                actions._retrieveErrorMessageDoctor(null);

                if (state.isValid.password && (state.values.password === state.values.passwordconfirm)) {
                    actions._setState({key: 'formIsSubmitting', value: true});
                    let resetData = {
                        answer: state.values.secret,
                        code: state.values.code.join(''),
                        email: state.values.email,
                        password: state.values.password,
                    };
                    Admin.doReset(resetData).then((res) => {
                        if (isDefined(res.data) && isDefined(res.data.token)) {
                            localStorage.setItem('apitoken', res.data.token);
                            localStorage.setItem('refresh', res.data.refresh);
                            localStorage.setItem('expiration', res.data.expiration.date);
                            redirectToGoodIndex();
                        } else {
                            actions._setState({key: 'formIsSubmitting', value: false});
                            if (isDefined(res.data)) {
                                if (+res.data.code === 1801) {
                                    actions._setState({key: 'recoveryStep', value: 'secret'});
                                    actions._setErrorMessages({'secret': t('cette réponse ne correspond pas à votre question secrète')});
                                    actions._setValues({ 'secret': '' });
                                    actions._setValidity({'secret': false});
                                    actions._setTyping({ 'secret': false });
                                } else {
                                    actions._setErrorMessages({'password': res.data.message});
                                    actions._setValidity({'password': false});
                                    actions._setTyping({ 'password': false });
                                }
                            }
                        }
                    });
                } else {
                    //
                }
            },
            /**
            * Redirect to the recovery page
            * @function LoginRecovery:gotoLogin
            */
            gotoLogin: () => (state, actions) => {
                window.location.href = addCSRFToken(allRoutes['index'].pathname);
            },
        }
        LoginRecovery = {...coreFn, ...LoginRecovery};
        return LoginRecovery;
    }
}