import { t } from 'i18next';
import { h } from 'hyperapp';
import { settings } from '@app/core/settings';
import { isDefined, getDateObjFromYMD, getYMDfromDateObj, createDateComplientSafari, createUTCDate, getReadableDate } from '@app/core';
import { Button, Card, Form, FormInput, Modal} from '@app/elements';

import { ModalViewDoSurvey } from './modalView-doSurvey';

import * as constants from '@app/constants';
const { API_URL } = settings;

import icoTreat from '@app/img/ico/ico-treat.png';
import icoTreatx2 from '@app/img/ico/ico-treat@2x.png';
import icoExport from '@app/img/ico/ico-export.png';
import icoExportx2 from '@app/img/ico/ico-export@2x.png';
import icoEdit from '@app/img/ico/ico-edit-pencil-grey.png';
import icoEditx2 from '@app/img/ico/ico-edit-pencil-grey@2x.png';

import CommentFeature from './commentFeature';

export const ModalViewCommentStudy = (props, actions) => (
    <Card classes={'btzInformations-btzInformationsModal btzSplittedModal'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            <div key={props.modalEditing} class={'btzClassMarker-btz btzClassMarker-btzBGColored'} key={'acquit'}>
                {isDefined(props.currentAnswer) &&
                    <p class='btzAboveTitle'>{props.editedPatient.fname + ' ' + props.editedPatient.lname}</p>
                }
                {(isDefined(props.currentAnswer)) &&
                    <p class='btzTitle btzXl'>{t('Questionnaire') + ' ' + (isDefined(props.currentAnswer.medicalStudyMoment) ? (props.currentAnswer.medicalStudyMoment) : 'Ø') + ' - ' + getReadableDate(createUTCDate(props.currentAnswer.date.date), 'DD/MM/YYYY') + ((!props.currentAnswer.empty && props.currentAnswer.firstAnswer) ? '' : (' (' + t('non répondu') + ')'))}</p>
                }
            </div>
        </Card.Header>
        <Card.Body>
            <div class='btzCard-btzBody-splitted btzCard-btzBody-splittedColored'>
                <Form onsubmit={event => event.preventDefault()}>
                    <Form.Group>
                        <div class='thfSurveyReport-cellPopupContent'>
                            {(isDefined(props.currentAnswer) && isDefined(props.currentAnswer.values) && (props.currentAnswer.values.length > 0)) ?
                                <div class='thfSurveyReport-cellPopupContent-cellModalBlock'>
                                    {props.currentAnswer.values.map((item, idx) =>
                                        <div class='btzStudy-answerBlock'>
                                            <p class='btzStudy-answerBlock-label'>{(idx + 1) + '. ' + item.question}</p>
                                            <div class='btzStudy-answerBlock-answer'>
                                                <p class={'btzStudy-answerBlock-puce ' + (isDefined(props.currentAnswer.alerts.filter((f) => f.evaluationName === item.evaluationName)[0]) ? 'stepError' : (((props.currentAnswer.alerts.length === 1) && (props.currentAnswer.alerts[0].name === 'NODATA')) ? 'stepNodata' : 'stepSuccess'))}></p>
                                                <p class=''>{(item.label || '-')}</p>
                                            </div>
                                            {/*<div style='position: absolute;right: 5px;top: 25px;cursor: pointer;' onclick={(e) => { props.retrieveStudyAnswer({item: props.survey, userMedicalStudyToken: props.urlToken, answerName: item.evaluationName, surveyId: props.currentTimeline.userMedicalStudyId}) }}><img class='' src={icoEdit} srcset={`${icoEditx2} 2x`} alt='' style='' /></div>*/}
                                            {((props.answerId === props.survey?.answerId) && (item.evaluationName === props.answerName) && isDefined(props.surveyUrl)) &&
                                                <div>
                                                    <Modal key={'modal-update-survey-' + idx} customClass='modal-central' cancelAction={() => { props._setState({key: 'surveyUrl', value: null}); }} modalDisplayed={true} content={<ModalViewDoSurvey displayed={'updatesurvey-' + idx} cancelAction={() => { props._setState({key: 'surveyUrl', value: null}); }} {...props} loaded={true}></ModalViewDoSurvey>}></Modal>
                                                </div>
                                            }
                                        </div>
                                    )}
                                </div>
                                :
                                <div class='thfSurveyReport-cellPopupContent-cellModalBlock'>
                                    <p class='' style='margin: 0px;padding: 15px !important;'>{t('Ce questionnaire ne comporte aucune réponses disponibles.')}</p>
                                </div>
                            }
                        </div>
                    </Form.Group>
                </Form>
                {(isDefined(props.currentAnswer) && (props.currentAnswer.alerts.length > 0)) &&
                    <dummy>
                        <div class='btzCard-btzHeader-btzModal'>
                            <p class='btzTitle btzXl'>{t('Gestion de l\'alerte')}</p>
                            {(isDefined(props.currentAnswer) && (props.currentAnswer.alerts.length === 1) && isDefined(props.currentAnswer.alerts[0]) && (props.currentAnswer.alerts[0].name === 'NODATA')) ?
                                <p class='btzSubTitle' style='width: 100%;margin-bottom: 25px;'>{t('Questionnaire non répondu dans le temps imparti.')}</p>
                                :
                                <p class='btzSubTitle' style='width: 100%;margin-bottom: 25px;'>{t('Ce questionnaire a fait l\'objet d\'au moins une alerte. Choisissez dans la liste ci-contre l\'action que vous avez mis en place pour traiter cette alerte et ainsi informer les autres membres de l\'équipe.')}</p>
                            }
                        </div>
                        <Form>
                            {isDefined(props.currentAnswer) &&
                                <Form.Group>
                                    <FormInput
                                        key={'input-patientstate'}
                                        type='dropdown'
                                        id={'patientstate'}
                                        name='patientstate'
                                        value={(isDefined(props.currentAnswer.action) ? props.currentAnswer.action.action : '')}
                                        list={constants.custo.studyActionsAlert.filter((f) => f.service.indexOf('study') > -1)}
                                        initialCalls={false}
                                        validation={true}
                                        withSearch={false}
                                        textedArea={false}
                                        stricMode={true}
                                        oninputcb={props.updateCurrentActionState}
                                        placeholder={t('Choisissez une action')}
                                        required
                                    />
                                    {(isDefined(props.currentAnswer.action) && isDefined(props.currentAnswer.action.date)) &&
                                        <div class='btzGreenBgColored' style='margin-bottom: 25px;'>
                                            {t('Rapport traité le') + ' ' + getReadableDate(createUTCDate(props.currentAnswer.action.date.date), 'DD/MM/YYYY à HH:mm') + ' ' + t('par') + ' ' + props.currentAnswer.action.author.fname.charAt(0) + '.' + props.currentAnswer.action.author.lname}
                                        </div>
                                    }
                                    {(!isDefined(props.currentAnswer.action) || props.newAnswerSelected) &&
                                        <Form.Group classes='btzForm-btzFooter' style={{ 'padding': '0 15px' }}>
                                            <Button anchorRight flat width={'60%'} active={isDefined(props.currentAnswer.action)} onclick={(e) => {
                                                props.onSubmit(e);
                                            }}>
                                                <img class='' src={icoTreat} srcset={`${icoTreatx2} 2x`} alt='' style='display: inline-block;margin-right: 10px;vertical-align: middle;' />{t('Traiter le rapport')}
                                            </Button>
                                        </Form.Group>
                                    }
                                </Form.Group>
                            }
                        </Form>
                    </dummy>
                }
                {isDefined(props.currentAnswer) &&
                    <div class='btzCard-btzBody-splitted btzCard-btzBody-splittedColored btzMobileHidding'>
                        <div key={props.modalEditing} class={'btzClassMarker-btz'} key={'acquit'}>
                            <div class='btzCard-btzHeader-btzModal'>
                                <p class='btzTitle btzXl'>{t('Exporter le document')}</p>
                            </div>
                            <Form onsubmit={event => event.preventDefault()}>
                                <Form.Group classes='btzForm-btzFooter' style={{ 'padding': '0 15px' }}>
                                    <div class='btzButtonWrapper'>
                                        <a class='btn btn-flat btn-revertedColor' href={API_URL + "/theraflow/medical-study-oneoff/export/" + props.editedPatient.id + "/" + props.currentAnswer.surveyId + "/" + "?xtoken=" + encodeURIComponent(localStorage.getItem('apitoken'))} target='_blank'>
                                            <img class='' src={icoExport} srcset={`${icoExportx2} 2x`} alt='' style='display: inline-block;margin-right: 10px;vertical-align: middle;' />{t('Exporter (pdf)')}
                                        </a>
                                    </div>
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                }
            </div>
            <div class='btzCard-btzBody-splitted btzCard-btzBody-splittedColored'>
                <div key={props.modalEditing} class={'btzClassMarker-btz btzClassMarker-btzBGColored'} key={'acquit'}>
                    <CommentFeature {...props} closeButton={false} callback={props.commentCallback}></CommentFeature>
                </div>
            </div>
        </Card.Body>
    </Card>
);