import { t } from 'i18next'
import { Phone, Admin } from '@app/api'
import {
    enableTheseButton,
    desableTheseButton,
    ensurePasswordStrength,
    getUser,
} from '@app/core'
import {
    allRoutes,
    addCSRFToken,
    isDefined,
    getQueryVariable,
    redirectToGoodIndex,
    coreFn,
} from '@app/core'

import * as constants from '@app/constants'

/**
 * @module RegisterRegistering
 * @description Registering page
 */

export default {
    initAction: function () {
        let RegisterRegistering = {
            onComponentCreate: (props) => (state, actions) => {
                if (
                    isDefined(props.match) &&
                    isDefined(props.match.params) &&
                    isDefined(props.match.params.lang) &&
                    isDefined(props.defineLanguage)
                ) {
                    let targetLanguage = props.match.params.lang
                    if (
                        constants.custo.languages
                            .map((lng) => lng.key)
                            .indexOf(targetLanguage) > -1
                    ) {
                        targetLanguage = constants.custo.languages.find(
                            (lng) => lng.key === targetLanguage
                        )
                        if (isDefined(targetLanguage)) {
                            targetLanguage = targetLanguage.key
                            props.defineLanguage(targetLanguage)
                        }
                    }
                }
                //
                let registerToken = getQueryVariable('registerToken', false)
                Admin.getInfosFromToken(registerToken).then((infos) => {
                    let doctor = infos.data
                    if (isDefined(doctor) && isDefined(doctor.id)) {
                        let doctorPhone = doctor.phone
                        if (String(doctorPhone).length < 10) {
                            doctorPhone = '0' + doctorPhone
                        }
                        if (doctorPhone.length > 10) {
                            let lastChars = doctorPhone.substring(
                                doctorPhone.length - 10
                            )
                            let firstChars = doctorPhone.substring(
                                0,
                                doctorPhone.length - 10
                            )
                            doctorPhone = lastChars.match(/.{2}/g)
                            doctorPhone[0] = firstChars + doctorPhone[0]
                        } else {
                            doctorPhone = doctorPhone.match(/.{2}/g)
                        }
                        //
                        actions._setState({ key: 'doctor', value: doctor })
                        actions._setValues({ id: doctor.id })
                        actions._setValues({
                            name: doctor.fistname + ' ' + doctor.lastname,
                        })
                        actions._setValues({ phone: doctorPhone })
                        actions._setValues({ phonePrefix: doctor.phonePrefix })
                        actions._setValues({ email: doctor.email })
                    }
                    actions.initRegister()
                })
                if (
                    constants.custo.CUSTOMERS[props.customer].modules
                        .codeSendBy === 'email'
                ) {
                    actions._setState({
                        key: 'steps',
                        value: {
                            welcome: true,
                            password: true,
                            code: false,
                            secret: true,
                        },
                    })
                }
                actions._setState({
                    key: 'customerName',
                    value: constants.custo.CUSTOMERS[props.customer].logo,
                })
            },
            /**
             * Initialize register page, clear storage and set <enter> keyboard key listener, then call [switchStep]{@link module:LoginAuthentication~LoginAuthentication:switchStep})
             * @function RegisterRegistering:initRegister
             * @param {object} props - /
             */
            initRegister: (props) => (state, actions) => {
                window.onkeyup = function (e) {
                    var key = e.keyCode ? e.keyCode : e.which
                    if (key === 13) {
                        e.preventDefault()
                        e.stopPropagation()
                        actions.switchStep(e)
                        return false
                    }
                }
            },
            /**
             * Switch current displayed register step. Only used when <enter> keyboard key is pressed
             * @param {event} e - Keyboard event
             * @function RegisterRegistering:switchStep
             */
            switchStep: (e) => (state, actions) => {
                if (state.registerStep === 'welcome') {
                    actions._setState({
                        key: 'registerStep',
                        value: 'password',
                    })
                } else if (state.registerStep === 'password') {
                    actions.onSubmitPassword(e)
                } else if (state.registerStep === 'code') {
                    actions.onSubmitCode(e)
                } else if (state.registerStep === 'secret') {
                    actions.onSubmitSecret(e)
                }
            },
            /**
             * Validate password and submit phone
             * @function RegisterRegistering:onSubmitPassword
             */
            onSubmitPassword: (event) => (state, actions) => {
                event.preventDefault()
                event.stopPropagation()

                if (state.formIsSubmitting) {
                    return false
                }
                actions._setTypingFalse()
                actions._retrieveErrorMessageDoctor(null)

                if (
                    state.isValid.password &&
                    state.values.password === state.values.passwordconfirm
                ) {
                    if (state.steps.code === true) {
                        actions._setState({
                            key: 'formIsSubmitting',
                            value: true,
                        })
                        Phone.savePhone(state.values.id, {
                            phone: Array.isArray(state.values.phone)
                                ? state.values.phone.join('')
                                : state.values.phone,
                            phonePrefix: state.values.phonePrefix,
                        }).then((res) => {
                            if (isDefined(res.data.token)) {
                                actions._setValues({ token: res.data.token })
                                actions._setState({
                                    key: 'registerStep',
                                    value: 'code',
                                })
                                actions._setState({
                                    key: 'formSubmittedOnce',
                                    value: false,
                                })
                                actions._setState({
                                    key: 'formIsSubmitting',
                                    value: false,
                                })
                            } else {
                                actions._setErrorMessages({
                                    password: res.data.message,
                                })
                                actions._setValidity({ password: false })
                                actions._setState({
                                    key: 'formIsSubmitting',
                                    value: false,
                                })
                            }
                        })
                    } else {
                        actions._setState({
                            key: 'formSubmittedOnce',
                            value: false,
                        })
                        actions._setState({
                            key: 'registerStep',
                            value: 'secret',
                        })
                    }
                } else {
                    //
                }
            },
            /**
             * Validate code and submit it to validate phone
             * @function RegisterRegistering:onSubmitCode
             */
            onSubmitCode: (event) => (state, actions) => {
                event.preventDefault()
                event.stopPropagation()

                if (state.formIsSubmitting) {
                    return false
                }
                actions._setTypingFalse()
                actions._retrieveErrorMessageDoctor(null)

                if (state.isValid.code) {
                    actions._setState({ key: 'formIsSubmitting', value: true })
                    Phone.validatePhone(state.values.id, {
                        code: state.values.code.join(''),
                        token: state.values.token,
                    }).then((res) => {
                        if (
                            isDefined(res.data.message) &&
                            res.data.message.toLowerCase() === 'ok'
                        ) {
                            actions._setValues({ token: res.data.token })
                            actions._setState({
                                key: 'registerStep',
                                value: 'secret',
                            })
                            actions._setState({
                                key: 'formSubmittedOnce',
                                value: false,
                            })
                            actions._setState({
                                key: 'formIsSubmitting',
                                value: false,
                            })
                        } else {
                            actions._setErrorMessages({
                                code: t(
                                    'le code renseigné ne semble pas être le bon'
                                ),
                            })
                            actions._setValidity({ code: false })
                            actions._setState({
                                key: 'formIsSubmitting',
                                value: false,
                            })
                        }
                    })
                } else {
                    //
                }
            },
            /**
             * Validate question and secret then submit all data to update doctor informations, if success, rediret to alerts listing
             * @function RegisterRegistering:onSubmitSecret
             */
            onSubmitSecret: (event) => (state, actions) => {
                event.preventDefault()
                event.stopPropagation()

                if (state.formIsSubmitting) {
                    return false
                }
                actions._setTypingFalse()
                actions._retrieveErrorMessageDoctor(null)

                if (state.isValid.question && state.isValid.secret) {
                    actions._setState({ key: 'formIsSubmitting', value: true })
                    const {
                        id,
                        email,
                        phone,
                        phonePrefix,
                        password,
                        question,
                        secret,
                    } = state.values
                    let data = {
                        id: id,
                        email: email,
                        phone: Array.isArray(phone) ? phone.join('') : phone,
                        phonePrefix: phonePrefix,
                        password: password,
                        question: question,
                        answer: secret,
                    }

                    Admin.saveDoctor(data).then((res) => {
                        if (isDefined(res.data.token)) {
                            sessionStorage.removeItem('adminuser')
                            localStorage.removeItem('user')
                            //
                            let userCallback = () => {
                                redirectToGoodIndex()
                            }
                            localStorage.setItem('apitoken', res.data.token)
                            localStorage.setItem('refresh', res.data.refresh)
                            localStorage.setItem(
                                'expiration',
                                res.data.expiration.date
                            )
                            getUser(userCallback, true)
                        } else {
                            actions._setErrorMessages({
                                secret: t('la réponse secréte semble invalide'),
                            })
                            actions._setState({
                                key: 'formIsSubmitting',
                                value: false,
                            })
                        }
                    })
                } else {
                    //
                }
            },
            /**
             * Go back to code step (from secret step)
             * @function RegisterRegistering:goBackToCode
             */
            goBackToCode: (event) => (state, actions) => {
                if (state.steps.code === true) {
                    actions._setValues({ code: ['', '', '', '', '', ''] })
                    actions.onSubmitPassword(event)
                } else {
                    actions._setState({
                        key: 'registerStep',
                        value: 'password',
                    })
                }
            },
            /**
             * Redirect to the login page
             * @function RegisterRegistering:gotoLogin
             */
            gotoLogin: () => (state, actions) => {
                window.location.href = addCSRFToken(allRoutes['index'].pathname)
            },
        }
        RegisterRegistering = { ...coreFn, ...RegisterRegistering }
        return RegisterRegistering
    },
}
