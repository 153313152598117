// eslint-disable-next-line
import { location } from '@hyperapp/router';
// eslint-disable-next-line
const withRouting = (opts = {}) => (nextApp) => (initialState, initialActions, initialView, initialRoot) => {

    const enhancedState = { ...initialState, ...location.state };
    const enhancedActions = { ...initialActions, ...location.actions };

    const enhancedApp = nextApp(
        enhancedState,
        enhancedActions,
        initialView,
        initialRoot
    )

    return enhancedApp
}

export { withRouting };