export const state = {
    isDoctor: true,
    ovalues: {
        id: '',
        fname: '',
        lname: '',
        email: '',
        // phone: ['', '', '', '', ''],
        phone: '',
        phonePrefix: '+33',
        role: '',
        rpps: '',
    },
    values: {
        id: '',
        email: '',
        password: '',
        passwordconfirm: '',
        // phone: ['', '', '', '', ''],
        // newphone: ['', '', '', '', ''],
        phone: '',
        newphone: '',
        phonePrefix: '+33',
        code: ['', '', '', '', '', ''],
        secret: '',
        question: '',
    },
    doctor: {
        rpps: '',
        lastConnexion: '',
        hospitalId: '',
        role: '',
        locale: '',
        customer: null,
    },
    isTouched: {
        id: false,
        email: false,
        password: false,
        passwordconfirm: false,
        phone: false,
        phonePrefix: true,
        newphone: false,
        code: false,
        secret: false,
    },
    isValid: {
        id: false,
        email: false,
        password: false,
        passwordconfirm: false,
        phone: false,
        newphone: false,
        phonePrefix: true,
        code: false,
        secret: false,
    },
    isTyping: {
        id: false,
        email: false,
        password: false,
        passwordconfirm: false,
        phone: false,
        newphone: false,
        phonePrefix: true,
        code: false,
        secret: false,
    },
    errorMessages: {
        id: '',
        email: '',
        password: '',
        passwordconfirm: '',
        phonePrefix: '',
        newphone: '',
        code: '',
        secret: '',
        locale: '',
    },
    api: {
        message: '',
        code: 200,
        kind: 'info',
    },
    modalEditing: '',
    modalDisplayed: false,
    hospitals: [],
    referenceHospital: null,
    referenceCustomer: '',
    formIsSubmitting: false,
    sendToParent: null,
    updateDoctor: null,
    loaded: false,
    informations: null,
}
