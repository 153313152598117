import { t } from 'i18next'
import { Treatment } from '@app/api'
import {
    enableTheseButton,
    desableTheseButton,
    deepCopy,
    isValidDateYMDObj,
    coreFn,
} from '@app/core'
import {
    isDefined,
    getYMDfromDateObj,
    createUTCDate,
    getDateObjFromYMD,
} from '@app/core'

import * as constants from '@app/constants'

/**
 * @module PatientEditTreatment
 * @description Patient configuration treatment
 */

export default {
    initAction: function () {
        let PatientEditTreatment = {
            onComponentCreate: (props) => (state, actions) => {
                actions.hydrateTreatment({ treatment: null, fromapi: false })
                actions._setState({ key: 'patientid', value: props.patientid })
                actions._setState({ key: 'customer', value: props.customer })
                actions.getTreatments()
            },
            /**
             * Get patient treatments
             * @function PatientEditTreatment:getTreatments
             */
            getTreatments: () => (state, actions) => {
                actions.setModalDisplayed(false)
                actions._setState({ key: 'modalEditing', value: null })
                Treatment.getTreatments(state.patientid).then(
                    (userTreatments) => {
                        let today = getDateObjFromYMD(null).replace(/-/g, ''),
                            start = null,
                            end = null
                        let treatments = { running: [], ended: [] }
                        actions._setState({
                            key: 'treatments',
                            value: treatments,
                        })
                        //
                        let userTreatment = null
                        userTreatments = userTreatments.data
                        for (var treat in userTreatments) {
                            userTreatment = userTreatments[treat]
                            start = null
                            end = null
                            //
                            if (userTreatment.days.length === 7) {
                                userTreatment.frequencies = '0'
                            } else if (userTreatment.days.length > 0) {
                                userTreatment.frequencies = '2'
                            } else if (
                                isDefined(userTreatment.interval) &&
                                userTreatment.interval !== ''
                            ) {
                                userTreatment.frequencies = '1'
                            } else if (
                                isDefined(userTreatment.cycle) &&
                                userTreatment.cycle !== ''
                            ) {
                                userTreatment.frequencies = '3'
                            }
                            //
                            if (userTreatment.status === false) {
                                userTreatment.treatStatus = 'stoped'
                                treatments.ended.push(userTreatment)
                            } else {
                                if (
                                    userTreatment.start &&
                                    isDefined(userTreatment.start.date)
                                ) {
                                    start = userTreatment.start.date
                                        .split(' ')[0]
                                        .replace(/-/g, '')
                                    if (
                                        userTreatment.end &&
                                        isDefined(userTreatment.end.date)
                                    ) {
                                        end = userTreatment.end.date
                                            .split(' ')[0]
                                            .replace(/-/g, '')
                                    }
                                    if (today < start) {
                                        userTreatment.treatStatus = 'comming'
                                        treatments.running.push(userTreatment)
                                    } else {
                                        if (today < end || end === null) {
                                            userTreatment.treatStatus =
                                                'running'
                                            treatments.running.push(
                                                userTreatment
                                            )
                                        } else {
                                            userTreatment.treatStatus = 'ended'
                                            treatments.ended.push(userTreatment)
                                        }
                                    }
                                }
                            }
                        }
                        actions._setState({
                            key: 'treatments',
                            value: treatments,
                        })
                    }
                )
            },
            /**
             * Retrieve readable phrase from values
             * @function PatientEditTreatment:getReadableFrequency
             * @param {object} el - Dom element to modify
             * @param {object} item - Item object containing values
             */
            getReadableFrequency:
                ({ el, item }) =>
                (state, actions) => {
                    if (item.frequencies === '0') {
                        el.innerHTML = t('Tous les jours')
                    } else if (item.frequencies === '2') {
                        el.innerHTML = constants.custo.daysFrequencies
                            .filter((f) => item.days.indexOf(f.key) > -1)
                            .map((m) => t(m.label))
                            .join(', ')
                    } else if (item.frequencies === '1') {
                        let intervalday = item.interval.replace(/P|D|W|M/g, '')
                        let interval =
                            constants.custo.periodsFrequencies.filter(
                                (f) => f.key === item.interval.slice(-1)
                            )[0].label
                        if (intervalday === '') {
                            intervalday = item.intervalday
                        }
                        if (interval === '') {
                            interval =
                                constants.custo.periodsFrequencies.filter(
                                    (f) => f.key === item.interval
                                )[0].label
                        }
                        el.innerHTML =
                            t('Tous les') +
                            ' ' +
                            intervalday +
                            ' ' +
                            t(interval)
                    } else if (item.frequencies === '3') {
                        let parsedCycle = null
                        if (
                            isDefined(item.breakday) &&
                            item.breakday !== '' &&
                            isDefined(item.takeday) &&
                            item.takeday !== ''
                        ) {
                            parsedCycle =
                                item.takeday +
                                ' ' +
                                t('jours de prise') +
                                ', ' +
                                item.breakday +
                                ' ' +
                                t('jours de pause')
                        } else {
                            parsedCycle =
                                item.cycle[0].replace(/P|D|W|M/g, '') +
                                ' ' +
                                t('jours de prise') +
                                ', ' +
                                item.cycle[1].replace(/P|D|W|M/g, '') +
                                ' ' +
                                t('jours de pause')
                        }
                        el.innerHTML = parsedCycle || 'Ø'
                    }
                },
            /**
             * Initialize and display treatment configuration modal
             * @function PatientEditTreatment:onEditTreatment
             * @param {string} treatId - Id of treatment to edit (null if its a creation)
             */
            onEditTreatment: (treatId) => (state, actions) => {
                actions.onEditStatusTreatmentCancel()
                if (isDefined(treatId)) {
                    Treatment.getTreatment(state.patientid, treatId).then(
                        (otreatment) => {
                            actions._setState({
                                key: 'treatmentStep',
                                value: 3,
                            })
                            let parsedTreatmentPrescriptions = [],
                                constPrescriont = null,
                                oprescription = null
                            for (var prescription in otreatment.data
                                .prescription) {
                                oprescription =
                                    otreatment.data.prescription[prescription]
                                constPrescriont =
                                    constants.custo.takePeriods.filter(
                                        (f) =>
                                            f.key ===
                                            oprescription.startHour +
                                                '-' +
                                                oprescription.endHour
                                    )[0]
                                if (isDefined(constPrescriont)) {
                                    constPrescriont = deepCopy(constPrescriont)
                                    constPrescriont.dosage =
                                        oprescription.dosage
                                    constPrescriont.dosageUnit =
                                        oprescription.dosageUnit
                                    constPrescriont.context =
                                        oprescription.context
                                    parsedTreatmentPrescriptions.push(
                                        constPrescriont
                                    )
                                }
                            }
                            otreatment.data.prescription =
                                parsedTreatmentPrescriptions
                            actions.hydrateTreatment({
                                treatment: otreatment.data,
                                fromapi: true,
                            })
                            actions.setModalDisplayed(true)
                            actions._setState({
                                key: 'modalEditing',
                                value: 'treatment',
                            })
                            actions._setState({
                                key: 'editFromRecap',
                                value: true,
                            })
                        }
                    )
                } else {
                    actions._setState({ key: 'treatmentStep', value: 0 })
                    actions.hydrateTreatment({ treatment: null, fromapi: true })
                    actions.setModalDisplayed(true)
                    actions._setValidity({ prescription: false })
                    actions._setState({
                        key: 'modalEditing',
                        value: 'newtreatment',
                    })
                    actions._setState({ key: 'editFromRecap', value: false })
                }
                //
            },
            /**
             * Parse and hydrate state from treatment object
             * @function PatientEditTreatment:hydrateTreatment
             * @param {object} treatment - Treatment object containing all values
             * @param {boolean} fromapi - Particular treatment if the call after an API call
             */
            hydrateTreatment:
                ({ treatment, fromapi }) =>
                (state, actions) => {
                    let values = deepCopy(state.ovalues)
                    values.end = null
                    values.before = null
                    //
                    if (!isDefined(treatment)) {
                        values.status = null
                        values.frequencies = state.dvalues.frequencies
                        values.days = deepCopy(state.dvalues.days)
                        values.prescription = []
                        //
                    } else {
                        if (isDefined(treatment.id)) {
                            values.id = treatment.id
                        }
                        if (isDefined(treatment.name)) {
                            values.name = treatment.name
                        }
                        if (isDefined(treatment.toDelete)) {
                            values.toDelete = treatment.toDelete
                        }
                        if (isDefined(treatment.status)) {
                            values.status = treatment.status
                        }
                        //
                        if (isDefined(treatment.days)) {
                            values.frequencies = '0'
                            values.days = deepCopy(treatment.days)
                            if (treatment.days.length < 7) {
                                values.frequencies = '2'
                            }
                        }
                        if (
                            isDefined(treatment.cycle) &&
                            treatment.cycle.length === 2
                        ) {
                            values.cycle = treatment.cycle
                            values.takeday = treatment.cycle[0].replace(
                                /P|D|W|M/g,
                                ''
                            )
                            values.breakday = treatment.cycle[1].replace(
                                /P|D|W|M/g,
                                ''
                            )
                            values.frequencies = '3'
                        }
                        if (isDefined(treatment.measureType)) {
                            values.measureType = treatment.measureType
                        }
                        if (isDefined(treatment.interval)) {
                            values.interval = treatment.interval.slice(-1)
                            values.intervalday = treatment.interval.replace(
                                /P|D|W|M/g,
                                ''
                            )
                            values.frequencies = '1'
                        }
                        if (isDefined(treatment.intervalday)) {
                            values.intervalday = treatment.intervalday
                        }
                        if (isDefined(treatment.start)) {
                            if (fromapi && isDefined(treatment.start.date)) {
                                values.start = getYMDfromDateObj(
                                    createUTCDate(treatment.start.date, true)
                                )
                            } else {
                                values.start = treatment.start
                            }
                        }
                        if (isDefined(treatment.end)) {
                            if (fromapi && isDefined(treatment.end.date)) {
                                values.end = getYMDfromDateObj(
                                    createUTCDate(treatment.end.date, false)
                                )
                            } else {
                                values.end = null
                                if (isValidDateYMDObj(treatment.end)) {
                                    values.end = treatment.end
                                }
                            }
                        }
                        if (isDefined(treatment.frequencies)) {
                            values.frequencies = treatment.frequencies
                            if (treatment.frequencies === '0') {
                                values.days = deepCopy(state.dvalues.days)
                            }
                        }
                        let prescriptions = []
                        if (isDefined(treatment.prescription)) {
                            for (var prescription in treatment.prescription) {
                                prescriptions.push(
                                    treatment.prescription[prescription]
                                )
                            }
                        }
                        values.prescription = prescriptions
                    }
                    actions._setState({
                        key: 'previousValues',
                        value: deepCopy(values),
                    })
                    actions._setState({
                        key: 'currentTreatment',
                        value: deepCopy(values),
                    })
                    actions._setValuesObj(values)
                },
            /**
             * Retrieve form input on the fly
             * @function PatientEditTreatment:retrieveInput
             * @param {input} input - Contain [isvalid], [name], [id] and [values] fields
             */
            retrieveInput: (input) => (state, actions) => {
                const { isvalid, name, values } = input
                if (name === 'frequencies') {
                    let parsedValues = state.values
                    if (values === '0') {
                        parsedValues.days = deepCopy(state.dvalues.days)
                    } else if (values === '1') {
                        if (
                            parsedValues.interval === '' ||
                            parsedValues.intervalday === ''
                        ) {
                            parsedValues.interval = state.dvalues.interval
                            parsedValues.intervalday = state.dvalues.intervalday
                        }
                    } else if (values === '2') {
                        parsedValues.days = deepCopy(state.dvalues.ddays)
                    } else if (values === '3') {
                        parsedValues.takeday = state.dvalues.takeday
                        parsedValues.breakday = state.dvalues.breakday
                    } else {
                        parsedValues.days = deepCopy(state.dvalues.days)
                        parsedValues.frequencies = state.dvalues.frequencies
                    }
                    actions._setValuesObj(parsedValues)
                }
                //
                actions._setValues({ [name]: values })
                actions._setValidity({ [name]: isvalid })
                actions._setTyping({ [name]: true })
                if (isvalid) {
                    actions._setErrorMessages({ [name]: '' })
                }
            },
            /**
             * Initialize and display treatment configuration modal
             * @function PatientEditTreatment:nextTreatmentStep
             * @param {string} newStep - Next step
             * @param {string} fromStep - Actual step (used when editing, to send doctor to last step after editing any treatment step)
             */
            nextTreatmentStep:
                ({ newStep, fromStep }) =>
                (state, actions) => {
                    actions._setTypingFalse()
                    if (newStep === 'stop') {
                        actions.sendTreatment(false)
                    } else if (newStep === 'active') {
                        actions.sendTreatment(true)
                    } else {
                        if (fromStep === 'back') {
                            actions._setState({
                                key: 'treatmentStep',
                                value: newStep,
                            })
                        } else {
                            let isValid = false
                            let testingStep = newStep
                            if (isDefined(fromStep)) {
                                testingStep = fromStep
                            }
                            if (testingStep === 1) {
                                // choosing medic name
                                if (state.isValid.name) {
                                    isValid = true
                                }
                            } else if (testingStep === 2) {
                                // configuring treatment
                                if (
                                    state.isValid.frequencies &&
                                    state.isValid.start &&
                                    state.isValid.end
                                ) {
                                    if (
                                        state.values.frequencies === '1' &&
                                        state.isValid.interval &&
                                        state.isValid.intervalday
                                    ) {
                                        isValid = true
                                    } else if (
                                        state.values.frequencies === '2' &&
                                        state.isValid.days
                                    ) {
                                        isValid = true
                                    } else if (
                                        state.values.frequencies === '3' &&
                                        state.isValid.takeday &&
                                        state.isValid.breakday
                                    ) {
                                        isValid = true
                                    } else if (
                                        state.values.frequencies === '0'
                                    ) {
                                        isValid = true
                                    }
                                }
                            } else if (testingStep === 3) {
                                // configuring prescriptions
                                if (state.isValid.prescription) {
                                    isValid = true
                                }
                            } else if (testingStep === 4) {
                                // displaying end recap
                                actions.sendTreatment(true)
                                return false
                            }
                            if (isValid) {
                                if (isDefined(fromStep)) {
                                    actions._setState({
                                        key: 'previousValues',
                                        value: deepCopy(state.values),
                                    })
                                }
                                actions._setState({
                                    key: 'treatmentStep',
                                    value: newStep,
                                })
                            } else {
                                actions.retrieveErrorMessageTreatment(
                                    isDefined(fromStep) ? fromStep : newStep - 1
                                )
                            }
                        }
                    }
                },
            retrieveErrorMessageTreatment: (target) => (state, actions) => {
                if (isDefined(target) && isDefined(target.target)) {
                    target = target.target.getAttribute('name')
                }
                actions._setState({ key: 'formSubmittedOnce', value: true })
                //
                if (state.treatmentStep === 0) {
                    if (state.values.name === '') {
                        actions._setErrorMessages({
                            name: t('merci de renseigner le nom du médicament'),
                        })
                        actions._setTyping({ name: false })
                    } else {
                        actions._setErrorMessages({ name: '' })
                    }
                } else if (state.treatmentStep === 1) {
                    if (!state.isValid.start) {
                        actions._setErrorMessages({
                            start: t(
                                'merci de renseigner une date de début valide (jj/mm/aaaa)'
                            ),
                        })
                        actions._setTyping({ start: false })
                    } else {
                        actions._setErrorMessages({ start: '' })
                    }
                    if (!state.isValid.end) {
                        actions._setErrorMessages({
                            end: t(
                                'merci de renseigner une date de fin valide (jj/mm/aaaa)'
                            ),
                        })
                        actions._setTyping({ end: false })
                    } else {
                        actions._setErrorMessages({ end: '' })
                    }
                    if (+state.values.frequencies === 1) {
                        if (!state.isValid.intervalday) {
                            actions._setErrorMessages({
                                intervalday: t(
                                    'veuillez renseigner un interval valide'
                                ),
                            })
                            actions._setTyping({ intervalday: false })
                        } else {
                            actions._setErrorMessages({ intervalday: '' })
                        }
                    } else if (+state.values.frequencies === 2) {
                        if (!state.isValid.days) {
                            actions._setErrorMessages({
                                days: t(
                                    'veuillez renseigner au moins un jour de prise'
                                ),
                            })
                            actions._setTyping({ days: false })
                            actions._setValidity({ days: false })
                        } else {
                            actions._setErrorMessages({ days: '' })
                            actions._setValidity({ days: true })
                        }
                    } else if (+state.values.frequencies === 3) {
                        if (!state.isValid.takeday) {
                            actions._setErrorMessages({
                                takeday: t(
                                    'veuillez renseigner un interval de jour(s) de prise valide'
                                ),
                            })
                            actions._setTyping({ takeday: false })
                        } else {
                            actions._setErrorMessages({ takeday: '' })
                        }
                        if (!state.isValid.breakday) {
                            actions._setErrorMessages({
                                breakday: t(
                                    'veuillez renseigner un interval de jour(s) de pause valide'
                                ),
                            })
                            actions._setTyping({ breakday: false })
                        } else {
                            actions._setErrorMessages({ breakday: '' })
                        }
                    }
                } else if (state.treatmentStep === 2) {
                    if (!state.isValid.prescription) {
                        actions._setErrorMessages({
                            prescription: t(
                                'merci de configurer au moins une prise'
                            ),
                        })
                        actions._setTyping({ prescription: false })
                    } else {
                        actions._setErrorMessages({ prescription: '' })
                    }
                }
                actions._setState({ key: 'formIsSubmitting', value: false })
            },
            /**
             * Parse and send treatment configuration form, then call [getTreatments]{@link module:PatientEditTreatment~PatientEditTreatment:getTreatments})
             * @function PatientEditTreatment:sendTreatment
             * @param {boolean} status - Treatment status
             */
            sendTreatment: (status) => (state, actions) => {
                let parsedData = {
                    start: getDateObjFromYMD(state.values.start, null, true),
                    status: status,
                    name: state.values.name,
                    drug: state.values.name,
                }
                if (+state.values.frequencies === 0) {
                    parsedData.days = state.values.days
                } else if (+state.values.frequencies === 1) {
                    parsedData.interval =
                        'P' + state.values.intervalday + state.values.interval
                } else if (+state.values.frequencies === 2) {
                    parsedData.days = state.values.days
                } else if (+state.values.frequencies === 3) {
                    parsedData.cycle = [
                        'P' + state.values.takeday + 'D',
                        'P' + state.values.breakday + 'D',
                    ]
                }
                if (
                    isDefined(state.values.end) &&
                    isValidDateYMDObj(state.values.end)
                ) {
                    parsedData.end = getDateObjFromYMD(
                        state.values.end,
                        'now',
                        true
                    )
                }
                if (isDefined(state.values.prescription)) {
                    var tempPrescription = []
                    for (var prscrpt in state.values.prescription) {
                        let hours = state.values.prescription[prscrpt].key,
                            startH = null,
                            endH = null
                        if (!isDefined(hours)) {
                            startH =
                                +state.values.prescription[prscrpt].startHour
                            endH = +state.values.prescription[prscrpt].endHour
                        } else {
                            startH = +hours.split('-')[0]
                            endH = +hours.split('-')[1]
                        }
                        tempPrescription.push({
                            push: true,
                            context: state.values.prescription[prscrpt].context,
                            startHour: startH,
                            endHour: endH,
                            dosage: state.values.prescription[prscrpt].dosage,
                            dosageUnit:
                                state.values.prescription[prscrpt].dosageUnit,
                        })
                    }
                    parsedData.prescription = tempPrescription
                }
                //
                if (isDefined(state.values.id)) {
                    Treatment.updateTreatment(
                        state.patientid,
                        state.values.id,
                        parsedData
                    ).then(() => {
                        actions.getTreatments()
                    })
                } else {
                    Treatment.addTreatment(state.patientid, parsedData).then(
                        () => {
                            actions.getTreatments()
                        }
                    )
                }
            },
            /**
             * Used to get drug list (from first treatment step configuration)
             * @function PatientEditTreatment:parseMedicine
             * @param {string} search - The searched drug
             */
            parseMedicine: (searchResults) => (state, actions) => {
                let parsedSearchResults = [],
                    searchResultsData =
                        isDefined(searchResults.target) &&
                        isDefined(searchResults.target.response)
                            ? searchResults.target.response
                            : searchResults.data
                if (
                    !Array.isArray(searchResultsData) &&
                    typeof searchResultsData === 'string'
                ) {
                    searchResultsData = JSON.parse(searchResultsData)
                }
                for (let result in searchResultsData) {
                    let parsedResult = searchResultsData[result].replace(
                        /<\/?[^>]+(>|$)/g,
                        ''
                    )
                    parsedSearchResults.push({
                        key: parsedResult,
                        label: parsedResult,
                    })
                }
                actions._setState({
                    key: 'drugsList',
                    value: parsedSearchResults,
                })
            },
            /**
             * Used to get drug list (from first treatment step configuration)
             * @function PatientEditTreatment:getMedicine
             * @param {string} search - The searched drug
             */
            getMedicine: (search) => (state, actions) => {
                Treatment.searchTreatment(search).then((searchResults) => {
                    // actions.parseMedicine({});
                    actions.parseMedicine(searchResults)
                })
            },
            /**
             * Retrieve prescription form input on the fly
             * @function PatientEditTreatment:retrieveInputPrescription
             * @param {input} input - Contain [isvalid], [name], [id] and [values] fields
             */
            retrieveInputPrescription: (input) => (state, actions) => {
                const { name, values, id } = input
                let existingPrescription = state.values.prescription.filter(
                    (f) => +f.marker === +id.replace('prescription-', '')
                )[0]
                existingPrescription[name] = values

                let parsedIsValid = true
                if (state.values.prescription.length === 0) {
                    parsedIsValid = false
                } else {
                    for (let presc in state.values.prescription) {
                        if (
                            state.values.prescription[presc].dosage === '' ||
                            state.values.prescription[presc].dosageUnit === ''
                        ) {
                            parsedIsValid = false
                        }
                    }
                }

                actions._setValues({ prescription: state.values.prescription })
                actions._setValidity({ prescription: parsedIsValid })
            },
            /**
             * Add a prescription to the treatment
             * @function PatientEditTreatment:addPrescription
             * @param {object} prescription - Prescription object to add
             */
            addPrescription: (prescription) => (state, actions) => {
                let newPrescription = deepCopy(state.dprescription)
                newPrescription.isvalid = prescription.isvalid
                newPrescription.marker = prescription.marker
                newPrescription.startHour = prescription.key.split('-')[0]
                newPrescription.endHour = prescription.key.split('-')[1]

                let newPrescriptions = state.values.prescription
                newPrescriptions.push(newPrescription)
                //
                actions._setValues({ prescription: newPrescriptions })
                actions._setValidity({ prescription: true })
            },
            /**
             * Remove a prescription to the treatment
             * @function PatientEditTreatment:removePrescription
             * @param {object} prescription - Prescription object to remove
             */
            removePrescription: (prescription) => (state, actions) => {
                let newPrescriptions = []
                let existingPrescriptions = state.values.prescription
                for (let presc in existingPrescriptions) {
                    if (
                        existingPrescriptions[presc].marker !==
                        prescription.marker
                    ) {
                        newPrescriptions.push(existingPrescriptions[presc])
                    }
                }
                //
                actions._setValues({ prescription: newPrescriptions })
                if (newPrescriptions.length === 0) {
                    actions._setValidity({ prescription: false })
                }
            },
            /**
             * Cancel treatment modal status change (cancel a deactivation or activation)
             * @function PatientEditTreatment:onEditStatusTreatmentCancel
             */
            onEditStatusTreatmentCancel: () => (state, actions) => {
                actions._setState({ key: 'deactivationConfirm', value: false })
                actions._setState({ key: 'deactivationConfirmKind', value: '' })
            },
            /**
             * Change the current treatment modal status displayed
             * @function PatientEditTreatment:onEditStatusTreatment
             * @param {boolean} status - The searched drug
             */
            onEditStatusTreatment: (status) => (state, actions) => {
                actions._setState({ key: 'deactivationConfirm', value: true })
                actions._setState({
                    key: 'deactivationConfirmKind',
                    value: status,
                })
            },
            /**
             * When cancel button are clicked, retrieve old values
             * @function PatientEditTreatment:rollBackValuesWithPreviousValues
             */
            rollBackValuesWithPreviousValues: () => (state, actions) => {
                actions._setValuesObj(deepCopy(state.previousValues))
            },
            /**
             * Update modal display status and kind of display
             * @function PatientEditTreatment:setModalDisplayed
             * @param {boolean} active - Show/hide modal, if false, empty <modalEditing>
             */
            setModalDisplayed: (active) => (state, actions) => ({
                modalDisplayed: active,
                modalEditing: active ? state.modalEditing : '',
            }),
        }
        PatientEditTreatment = { ...coreFn, ...PatientEditTreatment }
        return PatientEditTreatment
    },
}
