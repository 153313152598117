export { default as Login } from './Login';
export { default as Register } from './Register';

export { default as Mentions } from './Mentions';
export { default as MentionsPublic } from './MentionsPublic';
export { default as Help } from './Help';
export { default as Consent } from './Consent';

export { default as Patient } from './Patient';
export { default as Patients } from './Patients';

export { default as Alerts } from './Alerts';
export { default as Alertsic } from './Alertsic';
export { default as Alertscurve } from './Alertscurve';
export { default as Alertsrds } from './Alertsrds';

export { default as Account } from './Account';

export { default as QuatreCentQuatre } from './Public/quatrecentquatre';
export { default as UnregisterSW } from './Public/unregistersw';
export { default as RedirectingWay } from './Public/redirectingway';