import { t } from 'i18next';
import { h } from 'hyperapp';
import { Button, Modal } from '@app/elements';
import { isDefined, getAge, createDateComplientSafari } from '@app/core';
import moment from 'moment';

import icoEdit from '@app/img/ico/ico-edit-corner.png';
import icoEditx2 from '@app/img/ico/ico-edit-corner@2x.png';

import icoEditGrey from '@app/img/ico/ico-edit-corner-grey.png';
import icoEditGreyx2 from '@app/img/ico/ico-edit-corner-grey@2x.png';

import { ModalViewTwilio } from './../modals/modalView-twilio-default';

export const HeaderViewPatient = (props, actions) => (
    <div class='btzCard-btzHeader-btzPage-btzWrapper'>
        <div class='btzCard-btzHeader-btzPage-btzInfo'>
            <div class='col-8 col-md-12 btzCard-btzHeader-topMargin' style='padding-left: 0px;'>
                {/*(isDefined(props.editedPatient.userTheraflow) && (props.editedPatient.userTheraflow.etapes === true)) &&
                    <div class='btzCard-btzHeader-btzPage-btzInfoEtapes'>
                        <p class=''>{t('ÉTAPES')}</p>
                    </div>
                */}
                <div class='btzCard-btzHeader-btzPage-btzName' style='margin-bottom: 20px;'><p><font>{props.editedPatient.lname}</font>{' ' + props.editedPatient.fname}</p></div>
                <p class='btzCard-btzHeader-btzPage-btzInfoSubTitle'>{((+props.editedPatient.gender === 0) ? t('Féminin') : ((+props.editedPatient.gender === 1) ? t('Masculin') : t('Indéterminé'))) + ' - ' + (isDefined(props.editedPatient.birthdate) ? getAge(createDateComplientSafari(props.editedPatient.birthdate.date), moment(), true) : 'Ø') + ' - ' + t('Identifiant') + ' : ' + props.editedPatient.userTheraflow.hospitalId}</p>
                <p class='btzCard-btzHeader-btzPage-btzInfoSubTitle' style='display: block;'>{t('Email') + ' : '}
                {(props.editedPatient.email.indexOf(props.editedPatient.id) === -1) ?
                    <a href={'mailto:' + props.editedPatient.email}>{props.editedPatient.email}</a>
                    :
                    <p style='font-size: 14px;'>{t('non renseigné')}</p>
                }
                <span class='hide-xs'>{' - '}</span>{ ' ' + t('Tel') + ' : '}<a href={'tel:' + ((props.editedPatient.phone.length < 10) ? ('0' + props.editedPatient.phone) : props.editedPatient.phone).match(/.{1,2}/g).join('')}>{((props.editedPatient.phone.length < 10) ? ('0' + props.editedPatient.phone) : props.editedPatient.phone).match(/.{1,2}/g).join('.')}</a></p>
            </div>
            <div class='col-4 col-md-12 btzCard-btzHeader-btzPage-btzTwilio'>
                {(isDefined(props.editedPatient.userTheraflow.pathology) && (props.editedPatient.userTheraflow.pathology !== '')) &&
                    <div class='btzCard-btzHeader-btzPage-btzNote'>
                        <p class='btzCard-btzHeader-btzPage-btzNoteTitle'>{t('Notes') + ' :'}</p>
                        <p class='btzCard-btzHeader-btzPage-btzNoteContent'>{props.editedPatient.userTheraflow.pathology}</p>
                        {['resilience'].indexOf(props.customer) > -1 ?
                            <img class='' src={icoEditGrey} srcset={`${icoEditGreyx2} 2x`} alt='' onclick={() => { props.updateCurrentPage({subpage: 'profil', focusfield: 'pathology'}) }} />
                            :
                            <img class='' src={icoEdit} srcset={`${icoEditx2} 2x`} alt='' onclick={() => { props.updateCurrentPage({subpage: 'profil', focusfield: 'pathology'}) }} />
                        }
                    </div>
                }
                {(props.editedPatient.customer.customerModules.videoConference === true) &&
                    <Modal key={'modal-patient-twilio-' + props.modalDisplayed} cancelAction={() => { props.setModalDisplayed(false) }} backdropClosing={false} modalDisplayed={(props.modalDisplayed && (props.modalEditing.indexOf('twilio') > -1))} content={<ModalViewTwilio displayed={'twilio'} {...props} onTwilioHangup={props.onTwilioHangup} onTwilioConfirmHangup={props.onTwilioConfirmHangup} onTwilioCancelHangup={props.onTwilioCancelHangup} cancelAction={() => { props.setModalDisplayed(false) }} loaded={true} patientFName={props.editedPatient.fname} patientLName={props.editedPatient.lname} patientAge={getAge(createDateComplientSafari(props.editedPatient.birthdate.date), moment(), true)}></ModalViewTwilio>}>
                        <Button twilioButton={true} flat onclick={() => {
                            props.setModalDisplayed(true);
                            props.onTwilioCall();
                        }}>
                            {t('Appeler')}
                        </Button>
                    </Modal>
                }
            </div>
        </div>
    </div>
);