import { h } from 'hyperapp';
import { Component } from '@app/utils';
import './index.scss';

import LoginLayout from '@app/layouts/register/layout';

import Authentication from './authentication';
import Recovery from './recovery';

const state = {
    email: {
        value: '',
        isValid: false,
        isApproved: null,
        isResetted: null
    },
    password: {
        value: '',
        isValid: false,
        isApproved: null,
        isResetted: null
    }
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        // localStorage.clear();
    },
    setState: ({ ns, key, value }) => state => ({
        [ns]: { ...state[ns], ...{ [key]: value } },
    })
};

const view = (state, actions) => ({ location, match, customer }, children) => (
    <div key={((location.pathname === '/login/recovery') ? 'recovery' : 'login')} class='root-wrapper'>
        <LoginLayout page={((location.pathname === '/login/recovery') ? 'recovery-root' : 'login-root') + ' ' + 'layout-fullheight'} customer={customer}>
            {location.pathname === '/login/recovery' ?
                <Recovery initial={state.email} customer={customer} send={actions.setState} />
                :
                <Authentication initial={state.email} customer={customer} send={actions.setState} />
            }
        </LoginLayout>
    </div>
);

export {
    Authentication,
    Recovery,
};

export default Component(state, actions, view, 'login');
