import { h } from 'hyperapp';
import { Component } from '@app/utils';
import './index.scss';

import ImgLess from './ico-less.png';
import ImgLessx2 from './ico-less@2x.png';

import ImgPlus from './ico-plus.png';
import ImgPlusx2 from './ico-plus@2x.png';

const Counter = Component(
    {
        count: 0,
        max: 5,
        min: 0,
        tmout: null,
        onfocus: null,
        oninput: null,
        name: 'counter',
        floating: false,
    },
    {
        onComponentCreate: (props) => (state) => {
            state.max = (props.max || state.max);
            state.min = (props.min || state.min);
            state.count = (props.count || state.count);
            if (props.onfocus) {
                state.onfocus = props.onfocus;
            }
            if (props.oninput) {
                state.oninput = props.oninput;
            }
            if (props.name) {
                state.name = props.name;
            }
            if (props.floating) {
                state.floating = props.floating;
            }
        },
        down: () => state => ({ count: (((+state.count - 1) < state.min) ? state.min : (+state.count - 1)) }),
        up: () => state => ({ count: (((+state.count + 1) > state.max) ? state.max : (+state.count + 1)) }),
        setCount: newState => state => ({
            count: newState,
        }),
        isNumberKey: (evt) => (state, actions) => {
            let charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode === 38) {
                actions.up();
            } else if (charCode === 40) {
                actions.down();
            } else {
                let pattern = /^[0-9.,]+$/;
                if (evt.target.value === '') {
                    actions.setCount('');
                } else if (!pattern.test(evt.target.value)) {
                    return true;
                }

                state.tmout = setTimeout(function() {
                    clearTimeout(state.tmout);
                    state.tmout = null;
                    if (state.floating) {
                        actions.setCount(evt.target.value);
                    } else if (!isNaN(+evt.target.value)) {
                        actions.setCount(+evt.target.value);
                    }
                }, 100);
            }
            return false;
        }
    },
    (state, actions) => (props, children) => (
        <div class='btzCounter' id={props.id} data-id={props.did} data-tag={props.tag} data-value={state.count} data-kind={state.kind}>
            <input id={'input-' + props.id} class='btzCounter-btzCounterValue' name={props.name} value={state.count} placeholder={'0'} onkeyup={(evt) => {
                actions.isNumberKey(evt);
            }} onfocus={state.onfocus} oninput={state.oninput} onclick={() => { if (state.count === 0) { actions.setCount(''); } }} />
            {props.label &&
                <div class='btzCounter-btzCounterLabel'>{props.label}</div>
            }
            <button class='btzCounter-btzCounterButtonLeft' onclick={() => {
                actions.down();
                if (state.oninput !== null) {
                    state.oninput({target : document.getElementById('input-' + props.id)});
                }
            }}>
                <img src={ImgLess} srcset={`${ImgLessx2} 2x`} alt='' class='' />
            </button>
            <button class='btzCounter-btzCounterButtonRight' onclick={() => {
                actions.up();
                if (state.oninput !== null) {
                    state.oninput({target : document.getElementById('input-' + props.id)});
                }
            }}>
                <img src={ImgPlus} srcset={`${ImgPlusx2} 2x`} alt='' class='' />
            </button>
        </div>
    ),
    'counter'
);

export { Counter };
