import { t } from 'i18next';
import { h } from 'hyperapp';
import { Form, Button, Card, FormInput } from '@app/elements';
import { isDefined } from '@app/core';

import icoArrow from '@app/img/ico/ico-arrow-white-right.png';
import icoArrowx2 from '@app/img/ico/ico-arrow-white-right@2x.png';

export const ModalViewRedirectRDS = (props, actions) => (
    <Card classes={'btzInformations-btzInformationsModal btzInformations-btzInformationsModalRedirect'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            <div key={props.modalEditing} key={'treatment'}>
                <p class='btzTitle btzXl'>{t('Configurer un questionnaire')}</p>
            </div>
        </Card.Header>
        <Card.Body>
            <p class='btzModal-greyContent'>{t('Votre patient a été correctement ajouté.')}<br/>{t('Souhaitez-vous maintenant configurer l\'envoi du questionnaire ?')}</p>
            <Form onsubmit={event => event.preventDefault()} classes={'columns'}>
                <Form.Group classes={'borderedFormGroup'}>
                    <FormInput
                        placeholder={t('ne plus m\'afficher ce message')}
                        key='input-redirection'
                        type='checkbox'
                        valueType='checkbox'
                        id='redirection'
                        name='redirection'
                        value={props.values.redirection}
                        list={[{'key': true, 'label': t('ne plus m\'afficher ce message')}]}
                        oninputcb={props._retrieveInput}
                        required
                    />
                </Form.Group>
            </Form>
            <div class='btzCard-btzFooter-btzModal'>
                <Button anchorRight flat margin={'0 0 0 15px'} active onclick={props.confirmAction}>
                    <img class='activated' src={icoArrow} srcset={`${icoArrowx2} 2x`} alt='' />
                    {t('Configurer le questionnaire')}
                </Button>
                <Button anchorRight flat cancel onclick={props.cancelAction}>
                    {t('Plus tard')}
                </Button>
            </div>
        </Card.Body>
    </Card>
);