import { h } from 'hyperapp'
import { Route } from '@app/elements'
import { scrollTo } from '@app/core'

const Links = ({ routes }) =>
    Object.entries(routes).map(
        ([key, { label, pathname, parent }]) =>
            parent && (
                <Link key={key} to={pathname}>
                    {label}
                </Link>
            )
    )

const Routes = ({ routes }) =>
    Object.entries(routes).map(
        ([key, { pathname, component: Component, parent }]) => (
            <Route
                key={key}
                path={pathname}
                parent={parent}
                render={Component}
            />
        )
    )

const Link = (props, children) => {
    var to = props.to
    var scrollto = props.scrollto
    var location = props.location || window.location
    delete props.to
    // delete props.scrollto;
    delete props.location

    if (props.offsetHeader === undefined) {
        props.offsetHeader = 150
    }

    if (scrollto !== undefined) {
        props.trg = document.getElementById(props.scrollto)
        props.self = document.querySelectorAll(
            'a[scrollto="' + props.scrollto + '"]'
        )[0]
        if (props.self !== undefined) {
            props.self = props.self.parentNode
        }
        window.addEventListener('scroll', () => {
            if (props.trg === null || props.self === undefined) {
                props.trg = document.getElementById(props.scrollto)
                props.self = document.querySelectorAll(
                    'a[scrollto="' + props.scrollto + '"]'
                )[0]
                if (props.self !== undefined) {
                    props.self = props.self.parentNode
                }

                if (props.trg === null || props.self === undefined) {
                    return false
                }
            }

            let offsetTop =
                props.trg.getBoundingClientRect().top - (props.offsetHeader + 5)
            let offsetHeight = props.trg.getBoundingClientRect().height
            props.self.classList.remove('active')
            if (offsetTop < 0 && offsetTop + offsetHeight > 0) {
                props.self.classList.add('active')
            }
        })
    }

    props.href = scrollto !== undefined ? '#' : to
    props.onclick = function (e) {
        if (
            e.button !== 0 ||
            e.altKey ||
            e.metaKey ||
            e.ctrlKey ||
            e.shiftKey ||
            props.target === '_blank' ||
            e.currentTarget.origin !== location.origin
        ) {
            //
        } else {
            e.preventDefault()
            if (scrollto !== undefined) {
                let trg = document.getElementById(scrollto)
                if (trg) {
                    let offset =
                        trg.getBoundingClientRect().top + window.scrollY
                    if (offset > 0) {
                        offset = offset - props.offsetHeader
                    }
                    scrollTo(
                        document.scrollingElement || document.documentElement,
                        offset,
                        240
                    )
                }
            } else if (to !== location.pathname) {
                document.body.classList.remove('btzOverflowHidden')
                history.pushState(location.pathname, '', to)
            }
        }
    }

    return <a {...props}>{children}</a>
}

export { Links, Routes, Link }
