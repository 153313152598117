import { t } from 'i18next'
import { User } from '@app/api'
import {
    isDefined,
    enableTheseButton,
    desableTheseButton,
    coreFn,
    deepCopy,
} from '@app/core'
import { displayErrors } from '@app/api/errors'
import * as constants from '@app/constants'

/**
 * @module PatientEditEtp
 * @description Patient configuration etp
 */

export default {
    initAction: function () {
        let PatientEditEtp = {
            onComponentCreate: (props) => (state, actions) => {
                actions._setState({ key: 'patientid', value: props.patientid })
                actions.updateUser()
                actions.onComponentUpdate(props)
            },
            onComponentUpdate: (props) => (state, actions) => {
                if (
                    !isDefined(state.setAtLeastOneModification) &&
                    props.setAtLeastOneModification
                ) {
                    state.setAtLeastOneModification =
                        props.setAtLeastOneModification
                }
            },
            /**
             * Deactivate a ETP module
             * @param {string} id - Id of ETP to deactivate
             * @function PatientEditEtp:deactivateEtp
             */
            deactivateEtp: (id) => (state, actions) => {
                User.updateThemes(state.patientid, [{ id: id, state: 0 }]).then(
                    () => {
                        actions.updateUser()
                    }
                )
            },
            /**
             * Activate a ETP module
             * @param {string} id - Id of ETP to activate
             * @function PatientEditEtp:activateEtp
             */
            activateEtp: (id) => (state, actions) => {
                User.updateThemes(state.patientid, [{ id: id, state: 1 }]).then(
                    () => {
                        actions.updateUser()
                    }
                )
            },
            /**
             * Save current activated/deactivated ETP modules
             * @function PatientEditEtp:onSubmit
             */
            onSubmit: () => (state, actions) => {
                if (state.editedPatient.customer.customerModules.etp === true) {
                    actions.sendEtpModule()
                } else if (
                    state.editedPatient.customer.customerModules.programETP ===
                    true
                ) {
                    actions.sendEtpProgramModule()
                }
            },
            sendEtpModule: () => (state, actions) => {
                let parsedThemesOn = state.etp.filter(
                    (f) =>
                        f.status &&
                        state.editedPatient.themes.indexOf(
                            constants.custo.etpSettings[f.name].key
                        ) > -1
                )
                parsedThemesOn = parsedThemesOn.map((m) => {
                    return {
                        id: constants.custo.etpSettings[m.name].key,
                        state: 1,
                    }
                })
                let parsedThemesOff = state.etp.filter(
                    (f) =>
                        f.status &&
                        state.editedPatient.themes.indexOf(
                            constants.custo.etpSettings[f.name].key
                        ) === -1
                )
                parsedThemesOff = parsedThemesOff.map((m) => {
                    return {
                        id: constants.custo.etpSettings[m.name].key,
                        state: 0,
                    }
                })
                let parsedThemes = parsedThemesOn.concat(parsedThemesOff)
                User.updateThemes(state.patientid, parsedThemes).then(() => {
                    if (
                        state.editedPatient.customer.customerModules
                            .programETP === true
                    ) {
                        actions.sendEtpProgramModule()
                    } else {
                        actions.removeModificationListener()
                    }
                })
            },
            sendEtpProgramModule: () => (state, actions) => {
                let programsToSend = [],
                    nProgramsToSend = 0
                for (let availableProgram in state.availablePrograms) {
                    availableProgram = state.availablePrograms[availableProgram]
                    if (
                        state.originalPrograms
                            .map((m) => m.programETP.id)
                            .indexOf(availableProgram.id) > -1 &&
                        state.programs.indexOf(availableProgram.id) === -1
                    ) {
                        programsToSend.push({
                            id: state.originalPrograms.filter(
                                (f) => f.programETP.id === availableProgram.id
                            )[0].id,
                            method: 'delete',
                        })
                    } else if (
                        state.originalPrograms
                            .map((m) => m.programETP.id)
                            .indexOf(availableProgram.id) === -1 &&
                        state.programs.indexOf(availableProgram.id) > -1
                    ) {
                        programsToSend.push({
                            id: availableProgram.id,
                            method: 'post',
                        })
                    }
                }
                nProgramsToSend = programsToSend.length
                if (nProgramsToSend === 0) {
                    actions.removeModificationListener()
                } else {
                    let then = () => {
                        nProgramsToSend--
                        if (nProgramsToSend === 0) {
                            User.getETPProgramsByUserID(state.patientid).then(
                                (programs) => {
                                    actions._setState({
                                        key: 'originalPrograms',
                                        value: deepCopy(programs.data),
                                    })
                                    actions.removeModificationListener()
                                }
                            )
                        }
                    }
                    for (let programToSend in programsToSend) {
                        programToSend = programsToSend[programToSend]
                        if (programToSend.method === 'delete') {
                            User.deleteETPProgramsByUserID(
                                state.patientid,
                                programToSend.id
                            ).then(() => {
                                then()
                            })
                        } else if (programToSend.method === 'post') {
                            User.updateETPProgramsByUserID(state.patientid, {
                                programETP: programToSend.id,
                                session: 0,
                                status: 'IN_PROGRESS',
                            }).then(() => {
                                then()
                            })
                        }
                    }
                }
            },
            removeModificationListener: () => (state, actions) => {
                actions.handleLeaveWithoutSaveing(false)
                if (isDefined(state.setAtLeastOneModification)) {
                    state.setAtLeastOneModification(false)
                }
                actions.updateUser()
                displayErrors(
                    t('Modules mis à jour avec succès.'),
                    3000,
                    'success'
                )
            },
            /**
             * Retrieve form input on the fly
             * @param {input} input - Contain [isvalid], [name], [id] and [values] fields
             * @function PatientEditEtp:retrieveInput
             */
            retrieveInput: (input) => (state, actions) => {
                const { name, values, id } = input
                let modified = false
                if (name.indexOf('program') > -1) {
                    let programs = state.programs
                    if (values && programs.indexOf(id) === -1) {
                        programs.push(id)
                        modified = true
                    } else if (!values && programs.indexOf(id) > -1) {
                        programs.splice(programs.indexOf(id), 1)
                        modified = true
                    }
                    actions._setState({ key: 'programs', value: programs })
                } else if (name.indexOf('etp') > -1) {
                    let editedPatientThemes = state.editedPatient.themes
                    //
                    if (values && editedPatientThemes.indexOf(id) === -1) {
                        editedPatientThemes.push(id)
                        modified = true
                    } else if (
                        !values &&
                        editedPatientThemes.indexOf(id) > -1
                    ) {
                        editedPatientThemes.splice(
                            editedPatientThemes.indexOf(id),
                            1
                        )
                        modified = true
                    }
                    state.editedPatient.themes = editedPatientThemes
                    actions._setState({
                        key: 'editedPatient',
                        value: state.editedPatient,
                    })
                }
                //
                if (modified) {
                    actions.handleLeaveWithoutSaveing(true)
                    if (isDefined(state.setAtLeastOneModification)) {
                        state.setAtLeastOneModification(true)
                    }
                }
            },
            /**
             * Get user to retrieve current ETP modules
             * @function PatientEditEtp:updateUser
             */
            updateUser: () => (state, actions) => {
                User.getTheraflowUserById(state.patientid).then(
                    (editedPatient) => {
                        actions._setState({
                            key: 'editedPatient',
                            value: editedPatient.data,
                        })
                        if (
                            editedPatient.data.customer.customerModules.etp ===
                            true
                        ) {
                            actions._setState({
                                key: 'etp',
                                value: editedPatient.data.customer
                                    .customerModules.etpSettings,
                            })
                        }
                        if (
                            editedPatient.data.customer.customerModules
                                .programETP === true
                        ) {
                            actions.updateEtpPrograms()
                        } else {
                            actions._setState({ key: 'loaded', value: true })
                        }
                    }
                )
            },
            /**
             * Get user to retrieve current ETP modules
             * @function PatientEditEtp:updateUser
             */
            updateEtpPrograms: () => (state, actions) => {
                User.getETPProgramsAvailableByUserID(state.patientid).then(
                    (availablePrograms) => {
                        actions._setState({
                            key: 'availablePrograms',
                            value: availablePrograms.data,
                        })
                        User.getETPProgramsByUserID(state.patientid).then(
                            (programs) => {
                                actions._setState({
                                    key: 'programs',
                                    value: programs.data.map(
                                        (m) => m.programETP.id
                                    ),
                                })
                                actions._setState({
                                    key: 'originalPrograms',
                                    value: deepCopy(programs.data),
                                })
                                actions._setState({
                                    key: 'loaded',
                                    value: true,
                                })
                            }
                        )
                    }
                )
            },
            /**
             * Listener to display disclamer popup if leaving without saving changes (displayed only if an ETP has been edited)
             * @param {boolean} handle - Boolean to activate/deactivate the listener
             * @function PatientEditEtp:handleLeaveWithoutSaveing
             */
            handleLeaveWithoutSaveing: (handle) => (state, actions) => {
                if (handle) {
                    window.onbeforeunload = function () {
                        return ''
                    }
                } else {
                    window.onbeforeunload = function () {
                        //
                    }
                }
                actions._setState({
                    key: 'atLeastOneModification',
                    value: handle,
                })
            },
        }
        PatientEditEtp = { ...coreFn, ...PatientEditEtp }
        return PatientEditEtp
    },
}
