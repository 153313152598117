import * as constants from '@app/constants';
export const state = {
    values: {
        email: 'dontexist@missingtoken.me',
        name: 'Dr. Inconnu',
        password: '',
        passwordconfirm: '',
        code: ['', '', '', '', '', ''],
        phone: '',
        phonePrefix: '',
        token: '',
        question: '',
        secret: '',
    },
    isTouched: {
        password: false,
        passwordconfirm: false,
        phonePrefix: false,
        code: false,
        question: false,
        secret: false,
    },
    isValid: {
        password: false,
        passwordconfirm: false,
        phonePrefix: false,
        code: false,
        question: false,
        secret: false,
    },
    errorMessages: {
        phonePrefix: '',
        password: '',
        passwordconfirm: '',
        code: '',
        question: '',
        secret: '',
    },
    isTyping: {
        password: false,
        passwordconfirm: false,
        phonePrefix: false,
        code: false,
        question: false,
        secret: false,
    },
    api: {
        message: '',
        code: 200,
        kind: 'info'
    },
    steps: {
        welcome: true,
        password: true,
        code: true,
        secret: true,
    },
    customerName: '',
    doctor: null,
    accessToken: null,
    registerStep: 'password',
    isPasswordContentVisible: true,
    message: [],
    formIsSubmitting: false,
    formSubmittedOnce: false,
    sendToParent: null,
    rendering: false,
    targetapi: {value: 'theraflow-api.betterise.me', name: 'overrideapi', list: constants.custo.APIS},
};
