import { t } from 'i18next'
import { h } from 'hyperapp'
import { default as isLength } from 'validator/lib/isLength'
import { default as isNumeric } from 'validator/lib/isNumeric'
import { Component } from '@app/utils'
import {
    isEmail,
    isDefined,
    isObject,
    addZero,
    getYMDfromDateObj,
    castToBool,
    ensurePasswordStrength,
    deepCopy,
    isAuthorizedRole,
} from '@app/core'

import './../Form/index.scss'
import '@yaireo/tagify/dist/tagify.polyfills.min.js'

import icoEye from '@app/img/ico/ico-eye-off.png'
import icoEyex2 from '@app/img/ico/ico-eye-off@2x.png'
import icoEyeOn from '@app/img/ico/ico-eye-on.png'
import icoEyeOnx2 from '@app/img/ico/ico-eye-on@2x.png'

import icoEyeResilience from '@app/img/ico/custo/resilience/ico-eye-off.png'
import icoEyeResiliencex2 from '@app/img/ico/custo/resilience/ico-eye-off@2x.png'
import icoEyeResilienceOn from '@app/img/ico/custo/resilience/ico-eye-on.png'
import icoEyeResilienceOnx2 from '@app/img/ico/custo/resilience/ico-eye-on@2x.png'

var timeout
const FormInput = Component(
    {
        id: '',
        name: '',
        valueType: '',
        loaded: true,
        validation: true,
        validationOpt: null,
        type: '',
        placeholder: '',
        values: {
            basic: '',
            password: '',
            date: { day: '', month: '', year: '' },
            code: ['', '', '', '', '', ''],
            // phone: ['', '', '', '', ''],
            phone: '',
            nurses: [],
            contentEditable: { value: '', tags: [] },
        },
        hasBeenFilled: false,
        onInputCB: null,
        onFocusCB: null,
        onBlurCB: null,
        isInteger: false,
        isFloat: false,
        floatingDecimal: 1,
        dynFocus: false,
        isSuccess: '',
        isError: '',
        isValid: false,
        isTyping: false,
        specialValidation: {
            password: {
                atLeastHeight: false,
                atLeastOneUpper: false,
                atLeastOneLower: false,
                atLeastOneInt: false,
                atLeastOneSpecialChar: false,
            },
        },
        activeSpecialValidation: false,
        infoBlockAnchor: null,
        mustBeEqualTo: null,
        textedArea: false,
        withSearch: false,
        isQueryInput: false,
        search: '',
        readonly: false,
        deletable: false,
        optional: false,
        multiselect: false,
        list: [],
        actionList: null,
        editedList: [],
        fullList: [],
        excludeList: [],
        labelValue: [],
        readableValue: '',
        labelKey: '',
        dropddownImg: null,
        showPasswordButton: false,
        passwordToText: false,
        actionType: null,
        defaultSelected: null,
        noFuture: false,
        noPast: false,
        minDate: null,
        maxDate: null,
        minValue: null,
        maxValue: null,
        deletetable: false,
        formSubmittedOnce: false,
        autocomplete: 'off',
        errorMessage: null,
        localErrorMessage: null,
        minlength: null,
        maxlength: null,
        maxPhoneLength: 14,
        textareaRows: null,
        isSmallDropdown: false,
        noAuthRequired: null,
        initialFocus: false,
        optClasses: '',
        stricMode: false,
        readonlyMessage: '',
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            //
            actions.onComponentUpdate(props)
            if (isDefined(props.validation)) {
                actions.setState({ key: 'validation', value: props.validation })
            }
            if (props.validationOpt) {
                actions.setState({
                    key: 'validationOpt',
                    value: props.validationOpt,
                })
            }
            if (props.readonlyMessage) {
                actions.setState({
                    key: 'readonlyMessage',
                    value: props.readonlyMessage,
                })
            }
            if (props.list) {
                if (isDefined(props.queryAction)) {
                    actions.setState({ key: 'isQueryInput', value: true })
                    actions.setState({
                        key: 'queryAction',
                        value: props.queryAction,
                    })
                }
            }
            if (props.oninputcb) {
                actions.setState({ key: 'onInputCB', value: props.oninputcb })
            }
            if (props.ondropdowncb) {
                actions.setState({
                    key: 'onDropDownCB',
                    value: props.ondropdowncb,
                })
            }
            if (props.initialFocus) {
                actions.setState({
                    key: 'initialFocus',
                    value: props.initialFocus,
                })
            }
            if (props.infoBlockAnchor) {
                actions.setState({
                    key: 'infoBlockAnchor',
                    value: props.infoBlockAnchor,
                })
            }
            if (props.onfocuscb) {
                actions.setState({ key: 'onFocusCB', value: props.onfocuscb })
            }
            if (props.onblurcb) {
                actions.setState({ key: 'onBlurCB', value: props.onblurcb })
            }
            if (isDefined(props.withSearch)) {
                actions.setState({ key: 'withSearch', value: props.withSearch })
            }
            if (isDefined(props.autocomplete)) {
                actions.setState({
                    key: 'autocomplete',
                    value: props.autocomplete,
                })
            }
            if (isDefined(props.minlength)) {
                actions.setState({ key: 'minlength', value: props.minlength })
            }
            if (isDefined(props.maxlength)) {
                actions.setState({ key: 'maxlength', value: props.maxlength })
            }
            if (props.textareaRows) {
                actions.setState({
                    key: 'textareaRows',
                    value: props.textareaRows,
                })
            }
            if (props.dropddownImg) {
                actions.setState({
                    key: 'dropddownImg',
                    value: props.dropddownImg,
                })
            }
            if (props.isSmallDropdown) {
                actions.setState({
                    key: 'isSmallDropdown',
                    value: props.isSmallDropdown,
                })
            }
            if (isDefined(props.noAuthRequired)) {
                actions.setState({
                    key: 'noAuthRequired',
                    value: props.noAuthRequired,
                })
            }
            if (props.optClasses) {
                actions.setState({ key: 'optClasses', value: props.optClasses })
            }
            if (props.stricMode) {
                actions.setState({ key: 'stricMode', value: props.stricMode })
            }

            if (props.type) {
                if (isDefined(props.type.action)) {
                    actions.setState({ key: 'type', value: 'basic' })
                    actions.setState({
                        key: 'actionType',
                        value: props.type.action,
                    })
                } else {
                    actions.setState({ key: 'type', value: props.type })
                }
            }
            //
            if (
                !isDefined(props.initialCalls) ||
                (isDefined(props.initialCalls) && props.initialCalls === true)
            ) {
                actions.updateValues(props.value)
            }
            //
            if (isDefined(props.defaultSelected)) {
                let propsList = props.list
                if (isDefined(props.list.list)) {
                    propsList = props.list.list
                }
                //
                if (isDefined(propsList)) {
                    actions.setState({
                        key: 'defaultSelected',
                        value: props.defaultSelected,
                    })
                    for (let val in propsList) {
                        if (+val === +props.defaultSelected) {
                            actions.updateValues(propsList[val].key)
                        }
                    }
                }
            }
        },
        onComponentUpdate: (props) => (state, actions) => {
            let valueType = props.valueType
            actions.setState({ key: 'localErrorMessage', value: null })
            if (!isDefined(valueType)) {
                valueType = props.name
            }
            if (isDefined(props.isTyping)) {
                actions.setState({ key: 'isTyping', value: props.isTyping })
            }

            if (isDefined(props.formSubmittedOnce)) {
                actions.setState({
                    key: 'formSubmittedOnce',
                    value: props.formSubmittedOnce,
                })
                if (!props.formSubmittedOnce) {
                    actions.updateValidationState({
                        isValid: false,
                        isFilled: false,
                        isTyping: false,
                    })
                }
            }
            if (isDefined(props.optional)) {
                actions.setState({ key: 'optional', value: props.optional })
            }
            //
            if (props.id) {
                actions.setState({ key: 'id', value: props.id })
            }
            if (props.name) {
                actions.setState({ key: 'name', value: props.name })

                if (props.value !== undefined) {
                    let values = props.value,
                        valueType = props.valueType
                    if (props.type === 'contentEditable') {
                        valueType = 'contentEditable'
                        values = deepCopy(state.values['contentEditable'])
                        values.value = props.value
                    }
                    actions.setValues({
                        [isDefined(state.values[valueType])
                            ? valueType
                            : 'basic']: values,
                    })
                }
                if (isDefined(props.validation) && props.validation === true) {
                    actions.updateIsInputValid({ value: props.value })
                }
            }
            if (isDefined(props.mustBeEqualTo)) {
                actions.setState({
                    key: 'mustBeEqualTo',
                    value: props.mustBeEqualTo,
                })
                if (isDefined(props.validation) && props.validation === true) {
                    actions.updateIsInputValid({ value: props.value })
                }
            }
            //
            if (isDefined(props.errorMessage)) {
                actions.setState({
                    key: 'errorMessage',
                    value: props.errorMessage,
                })
            }
            if (isDefined(props.isTyping)) {
                // remove error message when typing in input
                if (props.isTyping) {
                    actions.setState({ key: 'isError', value: 'is-default' })
                    actions.setState({ key: 'errorMessage', value: '' })
                }
            }
            //
            if (isDefined(props.loaded)) {
                actions.setState({ key: 'loaded', value: props.loaded })
            }
            if (
                isDefined(props.isValid) &&
                (isAuthorizedRole() ||
                    (!isAuthorizedRole() &&
                        isDefined(props.noAuthRequired) &&
                        props.noAuthRequired === false))
            ) {
                actions.setState({ key: 'isValid', value: props.isValid })
                actions.setState({ key: 'isSuccess', value: '' })
                if (
                    !props.isValid &&
                    (!isDefined(props.isTyping) || !props.isTyping)
                ) {
                    actions.setState({ key: 'isError', value: 'is-error' })
                }
            }
            if (props.valueType) {
                actions.setState({ key: 'valueType', value: props.valueType })
            }
            if (isDefined(props.isInteger)) {
                actions.setState({ key: 'isInteger', value: props.isInteger })
            }
            if (isDefined(props.isFloat)) {
                actions.setState({ key: 'isFloat', value: props.isFloat })
                if (isDefined(props.floatingDecimal)) {
                    actions.setState({
                        key: 'floatingDecimal',
                        value: props.floatingDecimal,
                    })
                }
            }
            if (isDefined(props.textedArea)) {
                actions.setState({ key: 'textedArea', value: props.textedArea })
            }
            if (isDefined(props.noFuture)) {
                actions.setState({ key: 'noFuture', value: props.noFuture })
            }
            if (isDefined(props.noPast)) {
                actions.setState({ key: 'noPast', value: props.noPast })
            }
            if (isDefined(props.minDate)) {
                actions.setState({ key: 'minDate', value: props.minDate })
            }
            if (isDefined(props.maxDate)) {
                actions.setState({ key: 'maxDate', value: props.maxDate })
            }
            if (isDefined(props.minValue)) {
                actions.setState({ key: 'minValue', value: props.minValue })
            }
            if (isDefined(props.maxValue)) {
                actions.setState({ key: 'maxValue', value: props.maxValue })
            }
            if (props.deletetable) {
                actions.setState({
                    key: 'deletetable',
                    value: props.deletetable,
                })
            }
            if (props.list) {
                let propsList = props.list
                if (
                    isDefined(state.id, true, true) &&
                    state.id === 'lyfenName' &&
                    (propsList.length !== state.list.length ||
                        isDefined(
                            propsList.filter(
                                (f) =>
                                    state.list
                                        .map((m) => m.key)
                                        .indexOf(f.key) === -1
                            )[0]
                        ))
                ) {
                    // document.querySelector(`input#${state.id}`)?.focus();
                }
                actions.setState({ key: 'list', value: propsList })
                actions.setLabelValues(propsList)
                actions.getReadableValue()
            }
            if (props.fullList) {
                actions.setState({ key: 'fullList', value: props.fullList })
            }
            if (props.excludeList) {
                actions.setState({
                    key: 'excludeList',
                    value: props.excludeList,
                })
            }
            if (props.dynFocus) {
                actions.setState({ key: 'dynFocus', value: props.dynFocus })
            }
            if (
                isDefined(props.readonly) ||
                (!isAuthorizedRole() &&
                    (!isDefined(props.noAuthRequired) ||
                        (isDefined(props.noAuthRequired) &&
                            props.noAuthRequired === false)))
            ) {
                actions.setState({
                    key: 'readonly',
                    value: isDefined(props.readonly) ? props.readonly : true,
                })
            }
            if (props.placeholder) {
                actions.setState({
                    key: 'placeholder',
                    value: props.placeholder,
                })
            }
            if (isDefined(props.multiselect)) {
                actions.setState({
                    key: 'multiselect',
                    value: props.multiselect,
                })
            }
            if (isDefined(props.showPasswordButton)) {
                actions.setState({
                    key: 'showPasswordButton',
                    value: props.showPasswordButton,
                })
            }
            if (isDefined(props.activeSpecialValidation)) {
                actions.setState({
                    key: 'activeSpecialValidation',
                    value: props.activeSpecialValidation,
                })
            }
        },
        updateValidation:
            ({ value }) =>
            (state, actions) => {
                if (
                    !isAuthorizedRole() &&
                    (isAuthorizedRole() ||
                        (!isAuthorizedRole() &&
                            isDefined(state.noAuthRequired) &&
                            state.noAuthRequired === false))
                ) {
                    return false
                }
                //
                let name = state.name.toLowerCase()
                let isTyping =
                    isDefined(document.activeElement) &&
                    isDefined(document.activeElement.getAttribute('name'))
                        ? document.activeElement
                              .getAttribute('name')
                              .split('-')[0] === state.name
                        : false
                let isValid = false
                let isFilled = true
                let referenceDate = null
                let tocompareDate = null
                if (!isDefined(name)) {
                    return false
                }
                if (value === undefined && !state.deletetable) {
                    return false
                }
                let specialValidation = state.specialValidation
                switch (isDefined(value)) {
                    case name.indexOf('code') > -1:
                        isFilled = state.values.code.join('').length === 6
                        if (!Array.isArray(value)) {
                            isValid =
                                isNumeric(String(value)) &&
                                isLength(value, { min: 1, max: 1 }) &&
                                value > -1 &&
                                value < 10
                        } else {
                            isValid = isFilled
                        }

                        break
                    case name.indexOf('phone') > -1:
                        isFilled = false
                        if (name === 'phoneprefix' && value.length > 1) {
                            isValid = true
                            isFilled = true
                        } else {
                            if (Array.isArray(state.values.phone)) {
                                isFilled =
                                    state.values.phone.join('').length > 8 &&
                                    state.values.phone.join('').length <
                                        state.maxPhoneLength + 1
                            } else {
                                isFilled =
                                    String(state.values.phone).length > 8 &&
                                    String(state.values.phone).length <
                                        state.maxPhoneLength + 1
                            }
                            if (!Array.isArray(value)) {
                                isValid =
                                    isNumeric(String(state.values.phone)) &&
                                    isLength(String(state.values.phone), {
                                        min: 9,
                                        max: state.maxPhoneLength,
                                    }) &&
                                    state.values.phone > -1
                            } else {
                                isValid = isFilled
                            }
                        }

                        break
                    case name.indexOf('email') > -1:
                        isValid = isEmail(value)
                        isFilled = isEmail(value)

                        break
                    case name.indexOf('password') > -1:
                        if (state.activeSpecialValidation) {
                            specialValidation = specialValidation['password']
                            let passwordValidity = ensurePasswordStrength(value)
                            specialValidation.atLeastHeight =
                                passwordValidity.atLeastHeight
                            specialValidation.atLeastOneUpper =
                                passwordValidity.oneUppercase
                            specialValidation.atLeastOneLower =
                                passwordValidity.oneLowercase
                            specialValidation.atLeastOneInt =
                                passwordValidity.oneInt
                            specialValidation.atLeastOneSpecialChar =
                                passwordValidity.oneSpecialChar
                            //
                            isValid = passwordValidity.isValid
                            isFilled = isValid
                            actions.setSpecialValidation({
                                password: specialValidation,
                            })
                        } else {
                            if (isDefined(value)) {
                                isValid = value.length > 1
                            }
                            isFilled = isValid
                        }
                        if (isDefined(state.mustBeEqualTo)) {
                            if (value !== state.mustBeEqualTo) {
                                isValid = false
                            }
                        }

                        break
                    case state.type === 'date':
                    case name.indexOf('date') > -1:
                        isFilled =
                            isNumeric(String(state.values.date.day)) &&
                            isLength(String(state.values.date.day), {
                                min: 1,
                                max: 2,
                            }) &&
                            state.values.date.day > 0 &&
                            state.values.date.day < 32
                        if (isFilled) {
                            isFilled =
                                isNumeric(String(state.values.date.month)) &&
                                isLength(String(state.values.date.month), {
                                    min: 1,
                                    max: 2,
                                }) &&
                                state.values.date.month > 0 &&
                                state.values.date.month < 13
                        }
                        if (isFilled) {
                            isFilled =
                                isNumeric(String(state.values.date.year)) &&
                                isLength(String(state.values.date.year), {
                                    min: 4,
                                    max: 4,
                                }) &&
                                state.values.date.year > 1900 &&
                                state.values.date.year < 2100
                        }
                        if (name.indexOf('day') > -1) {
                            isValid =
                                isNumeric(String(state.values.date.day)) &&
                                isLength(String(state.values.date.day), {
                                    min: 1,
                                    max: 2,
                                }) &&
                                state.values.date.day > 0 &&
                                state.values.date.day < 32
                        } else if (name.indexOf('month') > -1) {
                            isValid =
                                isNumeric(String(state.values.date.month)) &&
                                isLength(String(state.values.date.month), {
                                    min: 1,
                                    max: 2,
                                }) &&
                                state.values.date.month > 0 &&
                                state.values.date.month < 13
                        } else if (name.indexOf('year') > -1) {
                            isValid =
                                isNumeric(String(state.values.date.year)) &&
                                isLength(String(state.values.date.year), {
                                    min: 4,
                                    max: 4,
                                }) &&
                                state.values.date.year > 1900 &&
                                state.values.date.year < 2100
                        }
                        if (isFilled) {
                            isValid = isFilled
                        }
                        //
                        referenceDate = null
                        tocompareDate =
                            state.values.date.year +
                            '' +
                            addZero(state.values.date.month) +
                            '' +
                            addZero(state.values.date.day)
                        if (+tocompareDate > 0) {
                            if (
                                isDefined(state.noFuture) &&
                                state.noFuture === true
                            ) {
                                referenceDate = getYMDfromDateObj(
                                    false,
                                    true,
                                    true
                                )
                                if (+tocompareDate > +referenceDate) {
                                    isValid = false
                                }
                            }
                            if (
                                isDefined(state.noPast) &&
                                state.noPast === true
                            ) {
                                referenceDate = getYMDfromDateObj(
                                    false,
                                    true,
                                    true
                                )
                                if (tocompareDate < referenceDate) {
                                    isValid = false
                                }
                            }
                            if (isDefined(state.maxDate)) {
                                referenceDate = state.maxDate
                                if (tocompareDate > referenceDate) {
                                    isValid = false
                                    state.localErrorMessage = t(
                                        'merci de renseigner une date de fin future à la date de début'
                                    )
                                }
                            }
                            if (isDefined(state.minDate)) {
                                referenceDate = state.minDate
                                if (tocompareDate < referenceDate) {
                                    isValid = false
                                    state.localErrorMessage = t(
                                        'merci de renseigner une date de fin future à la date de début'
                                    )
                                }
                            }
                        }
                        //
                        if (state.optional) {
                            if (
                                state.values.date.day === '' &&
                                state.values.date.month === '' &&
                                state.values.date.year === ''
                            ) {
                                isValid = true
                                isFilled = false
                            }
                        }

                        break
                    default:
                        if (!state.formSubmittedOnce) {
                            isValid = false
                            isFilled = false
                            if (state.optional) {
                                if (
                                    isDefined(state.minValue) ||
                                    isDefined(state.maxValue)
                                ) {
                                    if (
                                        isDefined(state.minValue) &&
                                        +value >= state.minValue
                                    ) {
                                        isValid = true
                                    } else if (
                                        isDefined(state.maxValue) &&
                                        +value <= state.maxValue
                                    ) {
                                        isValid = true
                                    }
                                    isFilled = isValid
                                } else {
                                    isValid = true
                                    if (
                                        isDefined(value) &&
                                        value !== '' &&
                                        Array.isArray(value) &&
                                        value.length > 0
                                    ) {
                                        isFilled = true
                                    }
                                }
                            } else if (
                                !state.optional &&
                                value !== '' &&
                                isDefined(value)
                            ) {
                                isValid = true
                                isFilled = true
                                if (
                                    isDefined(state.minValue) ||
                                    isDefined(state.maxValue)
                                ) {
                                    if (
                                        isDefined(state.minValue) &&
                                        +value < state.minValue
                                    ) {
                                        isValid = false
                                    } else if (
                                        isDefined(state.maxValue) &&
                                        +value > state.maxValue
                                    ) {
                                        isValid = false
                                    }
                                } else {
                                    if (
                                        Array.isArray(value) &&
                                        value.length === 0
                                    ) {
                                        isValid = false
                                    }
                                }
                            }
                            break
                        }
                        if (!isDefined(state.actionType)) {
                            isValid = isDefined(value) && value !== ''
                            if (state.isInteger) {
                                isValid = !isNaN(+value) && value !== ''
                            } else if (state.isFloat) {
                                let pattern = /^[+-]?\d+((\.|,)\d+)?$/
                                value = String(value).replace(',', '.')
                                isValid =
                                    (pattern.test(value) && value !== '') ||
                                    !isNaN(+value)
                                if (value.indexOf('.') > -1) {
                                    let decimal = value.split('.')[1]
                                    if (isDefined(decimal) && decimal !== '') {
                                        if (
                                            decimal.length >
                                            state.floatingDecimal
                                        ) {
                                            isValid = false
                                        }
                                    } else {
                                        isValid = false
                                    }
                                }
                            }
                            isFilled = isValid
                            if (state.optional) {
                                if (
                                    value === '' ||
                                    (Array.isArray(value) &&
                                        value.length === 0) ||
                                    !isDefined(value)
                                ) {
                                    isValid = true
                                    isFilled = false
                                }
                            } else {
                                if (
                                    Array.isArray(value) &&
                                    value.length === 0
                                ) {
                                    isValid = false
                                    isFilled = true
                                }
                            }
                        }

                        break
                }
                if (
                    isFilled &&
                    ((state.formSubmittedOnce && !isTyping) || !isTyping)
                ) {
                    if (isDefined(state.minlength)) {
                        let localErrorMessage = ''
                        if (String(value).length < state.minlength) {
                            localErrorMessage =
                                t('Renseignez') +
                                ' ' +
                                state.minlength +
                                ' ' +
                                t('caractères minimum')
                        }
                        //
                        actions.setState({
                            key: 'localErrorMessage',
                            value: localErrorMessage,
                        })
                        if (localErrorMessage !== '') {
                            isValid = false
                        }
                    }
                }
                if (
                    state.optional &&
                    (value === '' ||
                        !isDefined(value) ||
                        (Array.isArray(value) && value.length === 0))
                ) {
                    isValid = true
                    isFilled = false
                }
                actions.updateValidationState({
                    isValid: isValid,
                    isFilled: isFilled,
                    isTyping: isTyping,
                })

                return {
                    isValid: isValid,
                    isFilled: isFilled,
                    isTyping: isTyping,
                }
            },
        updateValidationState:
            ({ isValid, isFilled, isTyping }) =>
            (state, actions) => {
                if (
                    !isAuthorizedRole() &&
                    (isAuthorizedRole() ||
                        (!isAuthorizedRole() &&
                            isDefined(state.noAuthRequired) &&
                            state.noAuthRequired === false))
                ) {
                    actions.setState({ key: 'isSuccess', value: '' })
                    actions.setState({ key: 'isError', value: '' })
                    actions.setState({ key: 'errorMessage', value: '' })
                    return false
                }
                //
                if (
                    (state.optional && isValid && !isFilled) ||
                    !state.formSubmittedOnce ||
                    !state.validation
                ) {
                    actions.setState({ key: 'isSuccess', value: '' })
                    actions.setState({ key: 'isError', value: '' })
                    actions.setState({ key: 'errorMessage', value: '' })
                } else {
                    if (
                        isFilled ||
                        state.hasBeenFilled ||
                        state.formSubmittedOnce
                    ) {
                        actions.setState({ key: 'hasBeenFilled', value: true })
                        if (isValid && isFilled) {
                            actions.setState({
                                key: 'isSuccess',
                                value: 'is-success',
                            })
                            actions.setState({ key: 'isError', value: '' })
                            actions.setState({ key: 'errorMessage', value: '' })
                        } else {
                            actions.setState({ key: 'isSuccess', value: '' })
                            if (
                                !isValid &&
                                !isTyping &&
                                state.type !== 'date' &&
                                name.indexOf('date') === -1
                            ) {
                                actions.setState({
                                    key: 'isError',
                                    value: 'is-error',
                                })
                            }
                        }
                    }
                }
                if (isValid) {
                    actions.setState({ key: 'errorMessage', value: '' })
                }
            },
        resetValue: (props) => (state, actions) => {
            actions.setState({ key: 'search', value: '' })
            actions.updateValues('')
            actions.setState({ key: 'list', value: [] })
            //
            if (state.onInputCB) {
                state.onInputCB({
                    name: state.name,
                    values: '',
                    isvalid: true,
                    id: state.id,
                })
            }
        },
        updateValues: (e) => (state, actions) => {
            if (
                !isAuthorizedRole() &&
                (!isDefined(state.noAuthRequired) ||
                    (isDefined(state.noAuthRequired) &&
                        state.noAuthRequired === false))
            ) {
                return
            }
            //
            let target = isDefined(e) ? e.target : null
            let targetValue =
                isDefined(e) && isDefined(target) ? target.value : e
            if (
                isDefined(e) &&
                isDefined(e.target) &&
                ['checkbox', 'radio'].indexOf(state.type) === -1
            ) {
                e.preventDefault()
                e.stopPropagation()
            } else if (!isDefined(e)) {
                // return;
            }
            actions.setState({ key: 'search', value: '' })
            let isTyping = isDefined(document.activeElement)
                ? document.activeElement.getAttribute('name') === state.name
                : false
            if (
                isDefined(e) &&
                isDefined(e.currentTarget) &&
                isDefined(e.currentTarget.getAttribute('data-value'))
            ) {
                targetValue = e.currentTarget.getAttribute('data-value')
            }
            let value = ''
            let existingValue = ''
            let subTypeValue = ''
            let indexValue = ''
            let maxlength = ''
            //
            indexValue =
                isDefined(e) && isDefined(target)
                    ? target.getAttribute('index')
                    : null
            maxlength =
                isDefined(e) && isDefined(target)
                    ? target.getAttribute('maxlength')
                    : null
            //
            let pattern = /^[+-]?\d+((\.|,)\d+)?$/
            let isValid = false
            if (isDefined(targetValue) && state.isFloat) {
                targetValue = String(targetValue).replace(',', '.')
                isValid =
                    (pattern.test(targetValue) && targetValue !== '') ||
                    !isNaN(+targetValue)
                if (targetValue.indexOf('.') > -1) {
                    let decimal = targetValue.split('.')[1]
                    if (
                        isDefined(decimal) &&
                        decimal.length > state.floatingDecimal
                    ) {
                        isValid = false
                    }
                }
            }
            //
            if (
                state.isInteger &&
                (isNaN(+targetValue) ||
                    (!isNumeric(String(targetValue)) && targetValue !== ''))
            ) {
                existingValue = state.values[state.type] || state.values.basic
                if (isDefined(state.subtype)) {
                    existingValue = existingValue[state.subtype] || ''
                }
                if (isDefined(existingValue)) {
                    value = existingValue
                }
            } else if (state.isFloat && targetValue !== '' && !isValid) {
                existingValue = state.values[state.type] || state.values.basic
                if (isDefined(state.subtype)) {
                    existingValue = existingValue[state.subtype] || ''
                }
                if (isDefined(existingValue)) {
                    value = existingValue
                }
            } else {
                if (isDefined(targetValue)) {
                    value = targetValue
                }
                existingValue =
                    state.values[
                        isDefined(state.values[state.valueType])
                            ? state.valueType
                            : 'basic'
                    ]
                subTypeValue =
                    isDefined(e) && isDefined(target)
                        ? target.getAttribute('subtype')
                        : null
                //
                if (isDefined(existingValue)) {
                    if (isDefined(subTypeValue)) {
                        if (isDefined(existingValue[subTypeValue])) {
                            existingValue[subTypeValue] = value
                        }
                        if (isDefined(existingValue)) {
                            value = existingValue
                        }
                    } else if (isDefined(indexValue)) {
                        existingValue[indexValue] = value
                        if (isDefined(existingValue)) {
                            value = existingValue
                        }
                    }
                }
            }
            //
            if (state.textedArea && value !== '' && value.length > 0) {
                if (existingValue.indexOf(value) > -1) {
                    existingValue.splice(existingValue.indexOf(value), 1)
                } else if (Array.isArray(value)) {
                    existingValue = value
                } else {
                    existingValue.push(value)
                }
                if (isDefined(existingValue)) {
                    value = existingValue
                }
            }
            //
            if (state.multiselect) {
                existingValue =
                    state.values[
                        isDefined(state.values[state.valueType])
                            ? state.valueType
                            : 'basic'
                    ]
                if (!Array.isArray(value) && !isNaN(+value)) {
                    value = +value
                }
                if (existingValue.indexOf(value) > -1) {
                    existingValue.splice(existingValue.indexOf(value), 1)
                } else if (existingValue.indexOf(+value) > -1) {
                    existingValue.splice(existingValue.indexOf(+value), 1)
                } else if (!Array.isArray(value)) {
                    existingValue.push(value)
                }
                if (isDefined(existingValue)) {
                    value = existingValue
                }
            }
            //
            if (state.type === 'contentEditable') {
                let values = deepCopy(state.values['contentEditable'])
                values.value = value
                value = values
            }
            //
            if (['checkbox', 'radio'].indexOf(state.type) > -1) {
                if (value && state.values.basic) {
                    value = false
                }
            }
            //
            actions.setValues({
                [isDefined(state.values[state.valueType])
                    ? state.valueType
                    : 'basic']: value,
            })
            //
            let isFilled = false
            isValid = false
            if (state.validation) {
                let updatedValidations = actions.updateIsInputValid({
                    value: value,
                })
                isValid = updatedValidations.isValid
                isFilled = updatedValidations.isFilled
            }
            if (isDefined(state.actionType)) {
                isFilled = false
                if (isDefined(value)) {
                    isValid = state.actionType(value)
                    isFilled = true
                } else if (state.optional) {
                    isValid = true
                }
                actions.updateValidationState({
                    isValid: isValid,
                    isFilled: isFilled,
                    isTyping: isTyping,
                })
            }
            //
            let locValid = false
            if (isDefined(maxlength) && maxlength !== '') {
                if (
                    Array.isArray(value) &&
                    isDefined(indexValue) &&
                    indexValue !== ''
                ) {
                    let validValue = value[indexValue]
                    if (String(validValue).length === +maxlength) {
                        locValid = true
                    }
                } else {
                    if (isDefined(subTypeValue)) {
                        let validValue = value[subTypeValue]
                        if (String(validValue).length === +maxlength) {
                            locValid = true
                        }
                    } else {
                        if (String(value).length === +maxlength) {
                            locValid = true
                        }
                    }
                }
            }
            if ((isValid || locValid) && state.dynFocus) {
                actions.focusNextField({ target: e.target, direction: 'right' })
            }
            if (!state.optional && !isFilled && isValid) {
                isValid = false
            }
            //
            if (isDefined(state.onInputCB)) {
                if (state.valueType === 'date') {
                    if (value.day === '') {
                        value = null
                    }
                }
                state.onInputCB({
                    name: state.name,
                    values: castToBool(value),
                    isvalid: isValid,
                    id: state.id,
                })
            }
            actions.getReadableValue()
            //
            setTimeout(() => {
                let lifenInput = document.querySelector(`input#lyfenName`)
                if (lifenInput) {
                    if (lifenInput?.value?.length > 0) {
                        lifenInput.style.width = `calc(${lifenInput.value.length}ch + 50px)`
                    } else {
                        lifenInput.style.width = '100%'
                    }
                }
            }, 100)
        },
        blurInput: (e) => (state, actions) => {
            if (state.type == 'dropdown') {
                if (isDefined(state.onDropDownCB)) {
                    state.onDropDownCB({
                        name: state.name,
                        values: e.target.value,
                        isvalid: true,
                        id: state.id,
                    })
                }
            } else {
                let bluredValue = e.target.value
                let subTypeValue = e.target.getAttribute('subtype')
                //
                if (state.type === 'date') {
                    if (subTypeValue === 'day') {
                        if (!isNaN(bluredValue) && bluredValue < 10) {
                            bluredValue = '0' + +bluredValue
                        }
                    }
                }
                if (state.type !== 'code') {
                    actions.updateValues(e)
                }
                if (state.onBlurCB) {
                    let relatedTarget = e.relatedTarget
                    let relatedName = null
                    if (
                        isDefined(relatedTarget) &&
                        isDefined(relatedTarget.name)
                    ) {
                        relatedName = relatedTarget.name.split('-')[0]
                    }
                    //
                    if (isDefined(document.activeElement)) {
                        let nextName =
                            document.activeElement.getAttribute('name')
                        if (isDefined(nextName)) {
                            nextName = nextName.split('-')[0]
                            if (nextName === relatedName) {
                                return false
                            }
                        }
                    }
                    //
                    if (
                        !isDefined(relatedTarget) ||
                        state.name !== relatedName
                    ) {
                        state.onBlurCB(e)
                    }
                } else {
                    // actions.updateValues(e);
                }
            }
        },
        focusInput: (e) => (state, actions) => {
            e.preventDefault()
            e.stopPropagation()
            e.target.select()
            return false
            // if (state.onFocusCB) {
            //     state.onFocusCB(e);
            // }
        },
        setLabelValues: (list) => (state, actions) => {
            let existingValue =
                state.values[
                    isDefined(state.values[state.valueType])
                        ? state.valueType
                        : 'basic'
                ]
            let labelValue = existingValue,
                labelKey = existingValue
            if (state.textedarea === false) {
                labelValue =
                    list.filter(
                        (f) => String(f.key) === String(existingValue)
                    )[0] !== undefined
                        ? list.filter(
                              (f) => String(f.key) === String(existingValue)
                          )[0].label
                        : ''
                labelKey =
                    list.filter(
                        (f) => String(f.key) === String(existingValue)
                    )[0] !== undefined
                        ? list.filter(
                              (f) => String(f.key) === String(existingValue)
                          )[0].key
                        : ''
            }
            if (Array.isArray(labelKey)) {
                labelKey = labelKey.join(',')
            }
            if (state.textedArea) {
                if (Array.isArray(labelValue)) {
                    actions.setState({ key: 'labelValue', value: labelValue })
                }
            } else {
                actions.setState({ key: 'labelValue', value: labelValue })
            }
            actions.setState({ key: 'labelKey', value: labelKey })
        },
        //
        switchPwd: ({ target }) => {
            var inp = target.parentNode.firstChild
            inp.setAttribute(
                'type',
                inp.getAttribute('type') === 'text' ? 'password' : 'text'
            )
        },
        updateIsInputValid:
            ({ value }) =>
            (state, actions) => {
                return actions.updateValidation({ value: value })
            },
        focusNextField:
            ({ target, direction }) =>
            (state, actions) => {
                if (!isDefined(target)) {
                    return false
                }
                let newValue = target.value,
                    maxValue = target.getAttribute('maxlength')
                if (
                    (String(newValue).length === +maxValue &&
                        direction === 'right') ||
                    (newValue === '' && direction === 'left')
                ) {
                    var next = target
                    if (direction === 'right') {
                        // eslint-disable-next-line
                        while ((next = next.nextElementSibling)) {
                            if (!isDefined(next)) break
                            if (next.tagName.toLowerCase() === 'input') {
                                next.focus()
                                if (state.type !== 'code') {
                                    next.setSelectionRange(0, next.value.length)
                                }
                                break
                            }
                        }
                    } else if (direction === 'left') {
                        // eslint-disable-next-line
                        while ((next = next.previousElementSibling)) {
                            if (!isDefined(next)) break
                            if (next.tagName.toLowerCase() === 'input') {
                                next.focus()
                                if (state.type !== 'code') {
                                    next.setSelectionRange(0, next.value.length)
                                }
                                break
                            }
                        }
                    }
                }
            },
        focusPreviousField: (e) => (state, actions) => {
            if (e.keyCode === 8) {
                actions.focusNextField({ target: e.target, direction: 'left' })
            }
        },
        getReadableValue: () => (state, actions) => {
            let readableValue = '',
                selectedImg = null
            if (state.textedArea === false) {
                if (isDefined(state.labelValue) && state.labelValue !== '') {
                    if (state.multiselect) {
                        if (Array.isArray(state.labelValue)) {
                            let filter = state.list.filter(
                                (f) =>
                                    state.labelValue.indexOf(String(f.key)) >
                                        -1 ||
                                    state.labelValue.indexOf(f.key) > -1
                            )
                            if (isDefined(filter)) {
                                readableValue = filter
                                    .map((m) => t(m.label.split(' ')[0]))
                                    .join(', ')
                            }
                        }
                    } else {
                        let filter = state.list.filter(
                            (f) => String(f.key) === String(state.labelValue)
                        )[0]
                        if (isDefined(filter)) {
                            readableValue = t(filter.label)
                            selectedImg = filter.img
                        } else {
                            readableValue = state.labelValue
                        }
                    }
                }
            }
            if (isDefined(selectedImg)) {
                actions.setDropddownImg(selectedImg)
            }
            actions.setReadableValue(readableValue)
        },
        setReadableValue: (newState) => (state) => ({
            readableValue: newState,
        }),
        setDropddownImg: (newState) => (state) => ({
            dropddownImg: newState,
        }),
        debounce: ({ func, wait, argts = {}, immediate }) => {
            return function () {
                var later = function () {
                    timeout = null
                    if (!immediate) func(argts)
                }
                var callNow = immediate && !timeout
                clearTimeout(timeout)
                timeout = setTimeout(later, wait)
                if (callNow) func(argts)
            }
        },
        debounceListing: (ev) => (state, actions) => {
            let filter = ev.target.value
            let parentNode = ev.target.parentNode
            let childNode = parentNode.querySelectorAll(
                '.btzForm-btzFormInputListing'
            )
            childNode.forEach(function (entry) {
                if (
                    entry.textContent
                        .toLowerCase()
                        .indexOf(filter.toLowerCase()) > -1 ||
                    filter === ''
                ) {
                    entry.style.display = ''
                } else {
                    entry.style.display = 'none'
                }
            })
        },
        waitForLastInput:
            ({ target }) =>
            (state, actions) => {
                actions.setState({ key: 'search', value: target.value })
                if (target.value === '') {
                    state.onDropDownCB({
                        name: state.name,
                        values: target.value,
                        isvalid: true,
                        id: state.id,
                    })
                } else {
                    actions.debounce({
                        func: actions.updateListingFilterDebounce,
                        wait: 210,
                        argts: target,
                    })
                }
            },
        updateListingFilterDebounce: (target) => (state, actions) => {
            let value = state.search
            if (isDefined(value)) {
                if (isDefined(state.validationOpt)) {
                    if (state.validationOpt.ifNotEmpty) {
                        actions.updateValues(value)
                    }
                }

                if (value !== '') {
                    state.queryAction(value)
                }
            }
            state.onDropDownCB({
                name: state.name,
                values: value,
                isvalid: true,
                id: state.id,
            })
        },
        createContentEditableEvent: (e) => (state, actions) => {
            import('@yaireo/tagify').then((Tagify) => {
                let tagify = new Tagify.default(e, {
                    mode: 'mix', // <--  Enable mixed-content
                    pattern: /@/, // <--  Text starting with @ or # (if single, String can be used here)
                    tagTextProp: 'text', // <-- the default property (from whitelist item) for the text to be rendered in a tag element.
                    whitelist: state.list.map((m) => {
                        return { text: m.label, value: m.key }
                    }),
                    enforceWhitelist: true,
                    dropdown: {
                        enabled: 0,
                        position: 'text', // <-- render the suggestions list next to the typed text ("caret")
                        mapValueTo: 'text', // <-- similar to above "tagTextProp" setting, but for the dropdown items
                        highlightFirst: true, // automatically highlights first sugegstion item in the dropdown
                    },
                })
                tagify.on('input', function (e) {
                    state.values.contentEditable.value = e.detail.textContent
                    if (isDefined(state.onInputCB)) {
                        state.onInputCB({
                            name: state.name,
                            values: state.values.contentEditable,
                            isvalid: true,
                            id: state.id,
                        })
                    }
                })
                tagify.on('blur', function (e) {
                    state.values.contentEditable.tags = tagify.value.map(
                        (m) => m.value
                    )
                    state.values.contentEditable.value =
                        tagify.state.lastOriginalValueReported
                    if (isDefined(state.onInputCB)) {
                        state.onInputCB({
                            name: state.name,
                            values: state.values.contentEditable,
                            isvalid: true,
                            id: state.id,
                        })
                    }
                })
            })
            // tagify.on('remove', function(e) {
            //     state.values.contentEditable.tags.splice(state.values.contentEditable.tags.indexOf(e.detail.data.value), 1);
            //     state.values.contentEditable.value = tagify.state.lastOriginalValueReported;
            //     if (isDefined(state.onInputCB)) {
            //         state.onInputCB({name: state.name, values: state.values.contentEditable, isvalid: true, id: state.id});
            //     }
            // });
        },
        onInitialFocus: (el) => (state, actions) => {
            if (state.initialFocus) {
                actions.setState({ key: 'initialFocus', value: false })
                el.focus()
                // el.setSelectionRange(el.value.length, el.value.length);
            }
        },
        setValues: (newState) => (state) => ({
            values: { ...state.values, ...newState },
        }),
        setSpecialValidation: (newState) => (state) => ({
            specialValidation: newState,
        }),
        switchPasswordToText: () => (state) => ({
            passwordToText: !state.passwordToText,
        }),
        setState:
            ({ key, value }) =>
            (state) => ({
                [key]: value,
            }),
    },
    (state, actions) => (props, children) => (
        <div
            key={
                'forminput-' +
                state.name +
                '-' +
                state.formSubmittedOnce +
                '-' +
                state.values[
                    isDefined(state.values[state.valueType])
                        ? state.valueType
                        : 'basic'
                ]
            }
            class={
                'btzForm-btzFormInputWrapper ' +
                'forminputmarker forminput-' +
                state.name +
                ' forminput-' +
                state.type +
                ' ' +
                (state.isSmallDropdown ? 'small-dropdown' : '') +
                ' ' +
                state.optClasses
            }
            style={{ position: 'relative', height: 'auto' }}
        >
            {!state.loaded ? (
                <div class="thfInput thfLoadingBox">
                    <div class="thfLoadingBoxWrapper"></div>
                </div>
            ) : (
                <div
                    class={
                        (state.textedArea === true
                            ? 'thfInputTextedArea'
                            : 'thfInput ') +
                        (!state.readonly
                            ? state.isSuccess + ' ' + state.isError
                            : '')
                    }
                >
                    {state.type == 'password' && (
                        <div
                            class={'btzForm-btzFormInput'}
                            style={{ position: 'relative' }}
                        >
                            <input
                                key={'forminput-' + state.name}
                                autocomplete={state.autocomplete}
                                name={state.name}
                                value={state.values.password}
                                type={
                                    state.passwordToText ? 'text' : state.type
                                }
                                class="btzForm-btzFormInput"
                                placeholder={state.placeholder}
                                onfocus={actions.focusInput}
                                onblur={actions.blurInput}
                                oninput={actions.updateValues}
                                readonly={state.readonly}
                            />
                            {state.showPasswordButton && (
                                <div
                                    class="btzForm-btzFormInput-btzFormEye"
                                    onclick={actions.switchPasswordToText}
                                >
                                    {state.passwordToText ? (
                                        <img
                                            src={
                                                process.env.APP_CUSTOMISATION.toLowerCase() ===
                                                'resilience'
                                                    ? icoEyeResilienceOn
                                                    : icoEyeOn
                                            }
                                            srcset={
                                                (process.env.APP_CUSTOMISATION.toLowerCase() ===
                                                'resilience'
                                                    ? icoEyeResilienceOnx2
                                                    : icoEyeOnx2) + ' 2x'
                                            }
                                            alt=""
                                            class="btzForm-btzFormInput-btzEyeOn"
                                        />
                                    ) : (
                                        <img
                                            src={
                                                process.env.APP_CUSTOMISATION.toLowerCase() ===
                                                'resilience'
                                                    ? icoEyeResilience
                                                    : icoEye
                                            }
                                            srcset={
                                                (process.env.APP_CUSTOMISATION.toLowerCase() ===
                                                'resilience'
                                                    ? icoEyeResiliencex2
                                                    : icoEyex2) + ' 2x'
                                            }
                                            alt=""
                                            class="btzForm-btzFormInput-btzEye"
                                        />
                                    )}
                                </div>
                            )}
                            {state.activeSpecialValidation &&
                                (state.values.password !== '' ||
                                    state.isError === 'is-error') && (
                                    <div
                                        class={
                                            'thfInput-thfInputPwdValidity ' +
                                            (isDefined(state.infoBlockAnchor)
                                                ? state.infoBlockAnchor
                                                : '')
                                        }
                                    >
                                        <p
                                            class={
                                                state.specialValidation.password
                                                    .atLeastOneLower
                                                    ? 'success'
                                                    : state.formSubmittedOnce
                                                    ? 'error'
                                                    : ''
                                            }
                                        >
                                            {t('1 minuscule')}
                                        </p>
                                        <p
                                            class={
                                                state.specialValidation.password
                                                    .atLeastOneUpper
                                                    ? 'success'
                                                    : state.formSubmittedOnce
                                                    ? 'error'
                                                    : ''
                                            }
                                        >
                                            {t('1 majuscule')}
                                        </p>
                                        <p
                                            class={
                                                state.specialValidation.password
                                                    .atLeastHeight
                                                    ? 'success'
                                                    : state.formSubmittedOnce
                                                    ? 'error'
                                                    : ''
                                            }
                                        >
                                            {t('8 caractères minimum')}
                                        </p>
                                        <p
                                            class={
                                                state.specialValidation.password
                                                    .atLeastOneInt
                                                    ? 'success'
                                                    : state.formSubmittedOnce
                                                    ? 'error'
                                                    : ''
                                            }
                                        >
                                            {t('1 chiffre')}
                                        </p>
                                        <p
                                            class={
                                                state.specialValidation.password
                                                    .atLeastOneSpecialChar
                                                    ? 'success'
                                                    : state.formSubmittedOnce
                                                    ? 'error'
                                                    : ''
                                            }
                                        >
                                            {t('1 caractère spécial : $*/;')}
                                        </p>
                                    </div>
                                )}
                        </div>
                    )}
                    {state.type == 'date' && (
                        <div
                            class={
                                'btzForm-btzFormInput btzInputDate ' +
                                (state.readonly ? 'readonly' : '')
                            }
                            style={{
                                'position': 'relative',
                                'padding-right': '20px',
                            }}
                        >
                            <input
                                name={state.name}
                                type={'hidden'}
                                value={
                                    state.values.date.year +
                                    state.values.date.month +
                                    state.values.date.day
                                }
                            />
                            <input
                                style={{ width: '55px' }}
                                name={state.name + '-day'}
                                type={'text'}
                                maxlength={'2'}
                                subtype={'day'}
                                class="btzForm-btzFormInput"
                                value={state.values.date.day}
                                placeholder={state.placeholder.day}
                                onfocus={actions.focusInput}
                                onblur={actions.blurInput}
                                oninput={actions.updateValues}
                                readonly={state.readonly}
                                oncreate={(el) => {
                                    actions.onInitialFocus(el)
                                }}
                            />
                            <p>/</p>
                            <input
                                style={{ width: '55px' }}
                                name={state.name + '-month'}
                                type={'text'}
                                maxlength={'2'}
                                subtype={'month'}
                                class="btzForm-btzFormInput"
                                value={state.values.date.month}
                                placeholder={state.placeholder.month}
                                onfocus={actions.focusInput}
                                onblur={actions.blurInput}
                                oninput={actions.updateValues}
                                readonly={state.readonly}
                            />
                            <p>/</p>
                            <input
                                name={state.name + '-year'}
                                type={'text'}
                                maxlength={'4'}
                                subtype={'year'}
                                class="btzForm-btzFormInput"
                                value={state.values.date.year}
                                placeholder={state.placeholder.year}
                                onfocus={actions.focusInput}
                                onblur={actions.blurInput}
                                oninput={actions.updateValues}
                                readonly={state.readonly}
                            />
                            {state.readonlyMessage && state.readonly && (
                                <p class="btzForm-btzFormInput-inputreadonlymessage">
                                    {state.readonlyMessage}
                                </p>
                            )}
                        </div>
                    )}
                    {state.type == 'code' && (
                        <div
                            class={'btzForm-btzFormInput btzInputCode'}
                            style={{ position: 'relative' }}
                        >
                            <input
                                name={state.name}
                                type={'hidden'}
                                value={state.values.code.join('')}
                            />
                            <input
                                name={state.name + '-num-0'}
                                type={'number'}
                                pattern={'[0-9]*'}
                                inputmode={'numeric'}
                                maxlength={'1'}
                                index={0}
                                class="btzForm-btzFormInput"
                                value={state.values.code[0]}
                                placeholder={state.placeholder[0]}
                                onfocus={actions.focusInput}
                                onblur={actions.blurInput}
                                oninput={(e) => {
                                    if (
                                        e.target.value !== '' &&
                                        e.inputType !== 'deleteContentBackward'
                                    ) {
                                        e.target.value = Math.round(
                                            e.target.value.substring(0, 1)
                                        )
                                        actions.updateValues(e)
                                    } else {
                                        e.target.value = ''
                                        actions.updateValues(e)
                                    }
                                }}
                                oncreate={(el) => {
                                    actions.onInitialFocus(el)
                                }}
                            />
                            <input
                                name={state.name + '-num-1'}
                                type={'number'}
                                pattern={'[0-9]*'}
                                inputmode={'numeric'}
                                maxlength={'1'}
                                index={1}
                                class="btzForm-btzFormInput"
                                value={state.values.code[1]}
                                placeholder={state.placeholder[1]}
                                onfocus={actions.focusInput}
                                onblur={actions.blurInput}
                                oninput={(e) => {
                                    if (
                                        e.target.value !== '' &&
                                        e.inputType !== 'deleteContentBackward'
                                    ) {
                                        e.target.value = Math.round(
                                            e.target.value.substring(0, 1)
                                        )
                                        actions.updateValues(e)
                                    } else {
                                        e.target.value = ''
                                        actions.updateValues(e)
                                    }
                                }}
                                onkeydown={actions.focusPreviousField}
                            />
                            <input
                                name={state.name + '-num-2'}
                                type={'number'}
                                pattern={'[0-9]*'}
                                inputmode={'numeric'}
                                maxlength={'1'}
                                index={2}
                                class="btzForm-btzFormInput"
                                value={state.values.code[2]}
                                placeholder={state.placeholder[2]}
                                onfocus={actions.focusInput}
                                onblur={actions.blurInput}
                                oninput={(e) => {
                                    if (
                                        e.target.value !== '' &&
                                        e.inputType !== 'deleteContentBackward'
                                    ) {
                                        e.target.value = Math.round(
                                            e.target.value.substring(0, 1)
                                        )
                                        actions.updateValues(e)
                                    } else {
                                        e.target.value = ''
                                        actions.updateValues(e)
                                    }
                                }}
                                onkeydown={actions.focusPreviousField}
                            />
                            <input
                                name={state.name + '-num-3'}
                                type={'number'}
                                pattern={'[0-9]*'}
                                inputmode={'numeric'}
                                maxlength={'1'}
                                index={3}
                                class="btzForm-btzFormInput"
                                value={state.values.code[3]}
                                placeholder={state.placeholder[3]}
                                onfocus={actions.focusInput}
                                onblur={actions.blurInput}
                                oninput={(e) => {
                                    if (
                                        e.target.value !== '' &&
                                        e.inputType !== 'deleteContentBackward'
                                    ) {
                                        e.target.value = Math.round(
                                            e.target.value.substring(0, 1)
                                        )
                                        actions.updateValues(e)
                                    } else {
                                        e.target.value = ''
                                        actions.updateValues(e)
                                    }
                                }}
                                onkeydown={actions.focusPreviousField}
                            />
                            <input
                                name={state.name + '-num-4'}
                                type={'number'}
                                pattern={'[0-9]*'}
                                inputmode={'numeric'}
                                maxlength={'1'}
                                index={4}
                                class="btzForm-btzFormInput"
                                value={state.values.code[4]}
                                placeholder={state.placeholder[4]}
                                onfocus={actions.focusInput}
                                onblur={actions.blurInput}
                                oninput={(e) => {
                                    if (
                                        e.target.value !== '' &&
                                        e.inputType !== 'deleteContentBackward'
                                    ) {
                                        e.target.value = Math.round(
                                            e.target.value.substring(0, 1)
                                        )
                                        actions.updateValues(e)
                                    } else {
                                        e.target.value = ''
                                        actions.updateValues(e)
                                    }
                                }}
                                onkeydown={actions.focusPreviousField}
                            />
                            <input
                                name={state.name + '-num-5'}
                                type={'number'}
                                pattern={'[0-9]*'}
                                inputmode={'numeric'}
                                maxlength={'1'}
                                index={5}
                                class="btzForm-btzFormInput"
                                value={state.values.code[5]}
                                placeholder={state.placeholder[5]}
                                onfocus={actions.focusInput}
                                onblur={actions.blurInput}
                                oninput={(e) => {
                                    if (
                                        e.target.value !== '' &&
                                        e.inputType !== 'deleteContentBackward'
                                    ) {
                                        e.target.value = Math.round(
                                            e.target.value.substring(0, 1)
                                        )
                                        actions.updateValues(e)
                                    } else {
                                        e.target.value = ''
                                        actions.updateValues(e)
                                    }
                                }}
                                onkeydown={actions.focusPreviousField}
                            />
                        </div>
                    )}
                    {state.type == 'phone' && (
                        <div
                            class={'btzForm-btzFormInput btzInputPhone'}
                            style={{ position: 'relative' }}
                        >
                            <input
                                name={state.name}
                                class="btzForm-btzFormInput"
                                type={'text'}
                                maxlength={state.maxPhoneLength}
                                value={state.values.phone}
                                placeholder={
                                    isDefined(state.placeholder) &&
                                    Array.isArray(state.placeholder)
                                        ? state.placeholder.join(' ')
                                        : state.placeholder
                                }
                                onfocus={actions.focusInput}
                                oninput={actions.updateValues}
                                onblur={actions.blurInput}
                                readonly={state.readonly}
                            />
                            {/*<input name={state.name + '-num-0'} type={'text'} maxlength={'2'} index={0} class='btzForm-btzFormInput' value={state.values.phone[0]} placeholder={state.placeholder[0]} onfocus={actions.focusInput} onblur={actions.blurInput} oninput={actions.updateValues} readonly={state.readonly} />
                            <input name={state.name + '-num-1'} type={'text'} maxlength={'2'} index={1} class='btzForm-btzFormInput' value={state.values.phone[1]} placeholder={state.placeholder[1]} onfocus={actions.focusInput} onblur={actions.blurInput} oninput={actions.updateValues} readonly={state.readonly} />
                            <input name={state.name + '-num-2'} type={'text'} maxlength={'2'} index={2} class='btzForm-btzFormInput' value={state.values.phone[2]} placeholder={state.placeholder[2]} onfocus={actions.focusInput} onblur={actions.blurInput} oninput={actions.updateValues} readonly={state.readonly} />
                            <input name={state.name + '-num-3'} type={'text'} maxlength={'2'} index={3} class='btzForm-btzFormInput' value={state.values.phone[3]} placeholder={state.placeholder[3]} onfocus={actions.focusInput} onblur={actions.blurInput} oninput={actions.updateValues} readonly={state.readonly} />
                            <input name={state.name + '-num-4'} type={'text'} maxlength={'2'} index={4} class='btzForm-btzFormInput' value={state.values.phone[4]} placeholder={state.placeholder[4]} onfocus={actions.focusInput} onblur={actions.blurInput} oninput={actions.updateValues} readonly={state.readonly} />*/}
                        </div>
                    )}
                    {state.type == 'dropdown' && (
                        <dummy
                            class={state.readonly ? 'isreadonly' : ''}
                            style="height: 100%;display: block;"
                        >
                            <div
                                key={state.name + '-dropdown'}
                                tabindex="0"
                                class={
                                    'btzForm-btzFormInputWrapper-thfDropDown ' +
                                    (state.textedArea === true
                                        ? 'btzForm-btzFormInputWrapper-thfTextedArea'
                                        : '') +
                                    ' ' +
                                    (state.readonly ? 'readonly' : '')
                                }
                                style="height: 100%;width: 100%;"
                                onmousedown={(e) => {
                                    if (
                                        document.activeElement.isEqualNode(
                                            e.target.offsetParent
                                        ) ||
                                        document.activeElement?.offsetParent?.isEqualNode(
                                            e.target.offsetParent
                                        )
                                    ) {
                                        let sttmt = setTimeout(() => {
                                            clearTimeout(sttmt)
                                            e.target.blur()
                                            e.target.offsetParent.blur()
                                        }, 10)
                                    }
                                }}
                            >
                                {isDefined(state.dropddownImg) && (
                                    <img
                                        src={state.dropddownImg}
                                        srcset={state.dropddownImg + ' 2x'}
                                        alt=""
                                        class=""
                                    />
                                )}
                                {state.withSearch === false ? (
                                    <p
                                        key={
                                            'input-' +
                                            state.name +
                                            '-' +
                                            state.values[
                                                isDefined(
                                                    state.values[
                                                        state.valueType
                                                    ]
                                                )
                                                    ? state.valueType
                                                    : 'basic'
                                            ]
                                        }
                                        style="border: none !important;background-color: transparent;padding-right: 40px;line-height: 2;"
                                        class={
                                            'thfInput btzForm-btzFormInput btzForm-btzFormInputWrapper-thfLabel ' +
                                            (state.readonly ? 'readonly' : '') +
                                            ' ' +
                                            ((!isDefined(state.readableValue) ||
                                                state.readableValue === '') &&
                                            isDefined(state.placeholder) &&
                                            state.placeholder !== ''
                                                ? 'placeholder-like'
                                                : '')
                                        }
                                        onclick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            return false
                                        }}
                                    >
                                        {(!isDefined(state.readableValue) ||
                                            state.readableValue === '') &&
                                        isDefined(state.placeholder) &&
                                        state.placeholder !== ''
                                            ? state.placeholder
                                            : state.readableValue}
                                    </p>
                                ) : (
                                    <input
                                        key={'input-' + state.name}
                                        id={state.id}
                                        name={state.name}
                                        autocomplete={state.autocomplete}
                                        class={
                                            'thfInput btzForm-btzFormInput btzForm-btzFormInputWrapper-thfLabel'
                                        }
                                        style={{ padding: '1px 35px 1px 15px' }}
                                        value={
                                            state.search !== ''
                                                ? state.search
                                                : state.readableValue
                                        }
                                        alt={state.labelKey}
                                        oninput={
                                            state.isQueryInput
                                                ? actions.waitForLastInput
                                                : actions.debounceListing
                                        }
                                        onfocus={
                                            state.isQueryInput
                                                ? actions.waitForLastInput
                                                : actions.debounceListing
                                        }
                                        onblur={actions.blurInput}
                                        placeholder={props.placeholder}
                                        readonly={state.readonly}
                                    />
                                )}
                                {!state.readonly && state.list.length > 0 && (
                                    <div
                                        key={state.name + '-dropdownwrapper'}
                                        class="btzForm-btzFormInputWrapper-thfDropDownWrapper"
                                    >
                                        {state.list
                                            .filter(
                                                (f) =>
                                                    !isDefined(
                                                        state.excludeList.filter(
                                                            (e) =>
                                                                e.key === f.key
                                                        )[0]
                                                    )
                                            )
                                            .map((item, idx) => (
                                                <dummy
                                                    key={
                                                        state.name +
                                                        '-entry-' +
                                                        idx
                                                    }
                                                    class={
                                                        'thfDropDownDisplay btzForm-btzFormInputListing ' +
                                                        ((
                                                            state.stricMode
                                                                ? String(
                                                                      state.labelValue
                                                                  ) ===
                                                                  String(
                                                                      item.key
                                                                  )
                                                                : String(
                                                                      state.labelValue
                                                                  ).indexOf(
                                                                      String(
                                                                          item.key
                                                                      )
                                                                  ) > -1
                                                        )
                                                            ? 'active'
                                                            : '')
                                                    }
                                                >
                                                    {isDefined(item.img) && (
                                                        <img
                                                            src={item.img}
                                                            srcset={
                                                                item.img + ' 2x'
                                                            }
                                                            alt=""
                                                            class=""
                                                        />
                                                    )}
                                                    <label
                                                        for={
                                                            state.name +
                                                            '-' +
                                                            item.label
                                                        }
                                                        innerHTML={t(
                                                            item.label
                                                        )}
                                                    ></label>
                                                    <input
                                                        tabindex="1"
                                                        class=""
                                                        name={state.name}
                                                        onclick={
                                                            actions.updateValues
                                                        }
                                                        value={item.key}
                                                        id={
                                                            state.name +
                                                            '-' +
                                                            item.label
                                                        }
                                                    />
                                                </dummy>
                                            ))}
                                    </div>
                                )}
                                {!state.readonly && state.list.length === 0 && (
                                    <div class="btzForm-btzFormInputWrapper-thfDropDownWrapper">
                                        <dummy
                                            key={state.name + '-no-entry'}
                                            class={
                                                'thfDropDownDisplay btzForm-btzFormInputListing'
                                            }
                                        >
                                            <label
                                                for={'empty-list'}
                                                style="font-style: italic;"
                                            >
                                                {state.withSearch
                                                    ? t('Aucun résultat')
                                                    : t(
                                                          'Aucune entré disponible'
                                                      )}
                                            </label>
                                            <input
                                                tabindex="1"
                                                class=""
                                                name={'empty-list'}
                                                value={null}
                                                id={'empty-list'}
                                            />
                                        </dummy>
                                    </div>
                                )}
                                {!state.readonly && (
                                    <div class="btzForm-btzFormInputWrapper-thfDropDownIndicator"></div>
                                )}
                            </div>
                            {state.textedArea === true && (
                                <div
                                    class={
                                        'thfInput btzForm-btzFormInput btzForm-btzFormInputWrapper-thfLabel btzForm-btzFormInputTextedArea'
                                    }
                                    style="height: auto;width: 100%;"
                                >
                                    {state.labelValue
                                        .filter((l) =>
                                            isDefined(
                                                state.fullList.filter(
                                                    (f) =>
                                                        String(f.key) ===
                                                        String(l)
                                                )[0]
                                            )
                                        )
                                        .map((item) => (
                                            <dummy class="thfDropDownDisplay">
                                                <label
                                                    for={
                                                        state.fullList.filter(
                                                            (f) =>
                                                                String(
                                                                    f.key
                                                                ) ===
                                                                String(item)
                                                        )[0].label
                                                    }
                                                >
                                                    {t(
                                                        state.fullList.filter(
                                                            (f) =>
                                                                String(
                                                                    f.key
                                                                ) ===
                                                                String(item)
                                                        )[0].label
                                                    )}
                                                </label>
                                                <input
                                                    tabindex="1"
                                                    class=""
                                                    name={state.name}
                                                    onclick={
                                                        actions.updateValues
                                                    }
                                                    value={
                                                        state.fullList.filter(
                                                            (f) =>
                                                                String(
                                                                    f.key
                                                                ) ===
                                                                String(item)
                                                        )[0].key
                                                    }
                                                    id={
                                                        state.fullList.filter(
                                                            (f) =>
                                                                String(
                                                                    f.key
                                                                ) ===
                                                                String(item)
                                                        )[0].label
                                                    }
                                                />
                                            </dummy>
                                        ))}
                                </div>
                            )}
                            {state.readonlyMessage && state.readonly && (
                                <p class="btzForm-btzFormInput-inputreadonlymessage">
                                    {state.readonlyMessage}
                                </p>
                            )}
                        </dummy>
                    )}
                    {state.type == 'hidden-dropdown' && (
                        <dummy>
                            <div
                                tabindex="0"
                                class={
                                    'btzForm-btzFormInputWrapper-thfDropDownHidden'
                                }
                            >
                                {isDefined(state.dropddownImg) && (
                                    <img
                                        src={state.dropddownImg}
                                        srcset={state.dropddownImg + ' 2x'}
                                        alt=""
                                        class=""
                                    />
                                )}
                                {state.list.length > 0 && (
                                    <div class="btzForm-btzFormInputWrapper-thfDropDownWrapper">
                                        {state.list.map((item) => (
                                            <dummy
                                                class={
                                                    'thfDropDownDisplay btzForm-btzFormInputListing ' +
                                                    (String(
                                                        state.labelValue
                                                    ).indexOf(
                                                        String(item.key)
                                                    ) > -1
                                                        ? 'active'
                                                        : '')
                                                }
                                            >
                                                {!isDefined(item.directLink) &&
                                                    !isDefined(
                                                        item.redirectLink
                                                    ) && (
                                                        <div class="thfSelectionPuce"></div>
                                                    )}
                                                <label for={item.label}>
                                                    {t(item.label)}
                                                </label>
                                                <input
                                                    tabindex="1"
                                                    class=""
                                                    name={state.name}
                                                    onclick={
                                                        actions.updateValues
                                                    }
                                                    value={item.key}
                                                    id={item.label}
                                                />
                                                {isDefined(item.directLink) && (
                                                    <a
                                                        href={item.directLink}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        download="rapport-de-sante.pdf"
                                                    >
                                                        {''}
                                                    </a>
                                                )}
                                                {isDefined(
                                                    item.redirectLink
                                                ) && (
                                                    <a
                                                        onclick={
                                                            item.redirectLink
                                                        }
                                                    >
                                                        {''}
                                                    </a>
                                                )}
                                            </dummy>
                                        ))}
                                    </div>
                                )}
                                {!isDefined(state.dropddownImg) && (
                                    <div class="btzForm-btzFormInputWrapper-thfDropDownIndicator"></div>
                                )}
                            </div>
                        </dummy>
                    )}
                    {state.type == 'radio' && (
                        <dummy>
                            {state.list.map((item) => (
                                <div
                                    class="input-radio"
                                    onclick={actions.updateValues}
                                    data-value={String(item.key)}
                                >
                                    <input
                                        type="radio"
                                        id={
                                            state.name +
                                            '-' +
                                            item.label +
                                            '-' +
                                            item.key
                                        }
                                        name={
                                            state.name +
                                            '-' +
                                            item.label +
                                            '-' +
                                            item.key
                                        }
                                        value={item.key}
                                        checked={
                                            String(state.values['basic']) ===
                                            String(item.key)
                                                ? 'checked'
                                                : ''
                                        }
                                    />
                                    <span class="input-radio"></span>
                                    <label
                                        for={
                                            state.name +
                                            '-' +
                                            item.label +
                                            '-' +
                                            item.key
                                        }
                                        checked
                                    >
                                        {item.label}
                                    </label>
                                </div>
                            ))}
                        </dummy>
                    )}
                    {state.type == 'checkbox' && (
                        <dummy>
                            {state.list.map((item) => (
                                <div
                                    class="input-checkbox"
                                    onclick={actions.updateValues}
                                    data-value={String(item.key)}
                                >
                                    <input
                                        type="checkbox"
                                        id={
                                            state.name +
                                            '-' +
                                            item.label +
                                            '-' +
                                            item.key
                                        }
                                        name={
                                            state.name +
                                            '-' +
                                            item.label +
                                            '-' +
                                            item.key
                                        }
                                        value={item.key}
                                        checked={
                                            String(state.values['basic']) ===
                                            String(item.key)
                                                ? 'checked'
                                                : ''
                                        }
                                    />
                                    <span class="input-checkbox"></span>
                                    <label
                                        for={
                                            state.name +
                                            '-' +
                                            item.label +
                                            '-' +
                                            item.key
                                        }
                                        checked
                                    >
                                        {item.label}
                                    </label>
                                </div>
                            ))}
                        </dummy>
                    )}
                    {state.type == 'textarea' && (
                        <textarea
                            key={'formtextarea-' + state.name}
                            autocomplete={state.autocomplete}
                            class={
                                'btzForm-btzFormInput ' +
                                state.isSuccess +
                                ' ' +
                                state.isError
                            }
                            id={state.id}
                            name={state.name}
                            type={state.type}
                            placeholder={state.placeholder}
                            onfocus={actions.focusInput}
                            onblur={actions.blurInput}
                            oninput={actions.updateValues}
                            readonly={state.readonly}
                            rows={state.textareaRows || 2}
                            maxlength={state.maxlength}
                            oncreate={(el) => {
                                actions.onInitialFocus(el)
                            }}
                        >
                            {state.values.basic}
                        </textarea>
                    )}
                    {state.type == 'contentEditable' && (
                        <div
                            class={
                                'btzForm-btzFormInputWrapper ' +
                                state.isSuccess +
                                ' ' +
                                state.isError
                            }
                        >
                            <textarea
                                key={'formcontentEditable-' + state.name}
                                style="padding: 5px 10px;text-align: left;cursor: text;display: block;width: 100%"
                                id={'editablecontent-' + state.id}
                                name={state.name}
                                type={state.type}
                                placeholder={state.placeholder}
                                readonly={state.readonly}
                                oncreate={(e) => {
                                    actions.createContentEditableEvent(e)
                                }}
                            >
                                {state.readonly
                                    ? state.values.contentEditable.value
                                    : ''}
                            </textarea>
                        </div>
                    )}
                    {state.type == 'switch' && (
                        <dummy>
                            <label
                                class={
                                    'input-switch ' +
                                    (state.readonly ? 'readonly' : '')
                                }
                                onclick={actions.updateValues}
                                data-value={
                                    ['true', '1'].indexOf(
                                        String(state.values['basic'])
                                    ) > -1
                                        ? 'false'
                                        : 'true'
                                }
                            >
                                <input
                                    type="checkbox"
                                    checked={
                                        ['true', '1'].indexOf(
                                            String(state.values['basic'])
                                        ) > -1
                                            ? 'checked'
                                            : ''
                                    }
                                    readonly={state.readonly}
                                />
                                <span class="input-slider input-round"></span>
                            </label>
                            <p class="thfSwitchLabel">
                                {isDefined(
                                    state.list.filter(
                                        (f) =>
                                            String(f.key) ===
                                            String(state.values['basic'])
                                    )[0]
                                )
                                    ? state.list.filter(
                                          (f) =>
                                              String(f.key) ===
                                              String(state.values['basic'])
                                      )[0].label
                                    : ''}
                            </p>
                        </dummy>
                    )}
                    {[
                        'password',
                        'date',
                        'code',
                        'phone',
                        'dropdown',
                        'hidden-dropdown',
                        'radio',
                        'checkbox',
                        'switch',
                        'textarea',
                        'contentEditable',
                    ].indexOf(state.type) === -1 && (
                        <dummy style="width: 100%;display: block;height: 100%;">
                            <input
                                key={'forminput-' + state.name}
                                autocomplete={state.autocomplete}
                                class={
                                    'btzForm-btzFormInput ' +
                                    state.isSuccess +
                                    ' ' +
                                    state.isError
                                }
                                id={state.id}
                                name={state.name}
                                type={state.type}
                                value={state.values.basic}
                                placeholder={state.placeholder}
                                onfocus={actions.focusInput}
                                onblur={actions.blurInput}
                                oninput={actions.updateValues}
                                readonly={state.readonly}
                                oncreate={(el) => {
                                    actions.onInitialFocus(el)
                                }}
                            />
                            {state.readonlyMessage && state.readonly && (
                                <p class="btzForm-btzFormInput-inputreadonlymessage">
                                    {state.readonlyMessage}
                                </p>
                            )}
                        </dummy>
                    )}
                    {state.deletetable &&
                        state.values[
                            isDefined(state.values[state.valueType])
                                ? state.valueType
                                : 'basic'
                        ] !== '' &&
                        state.values[
                            isDefined(state.values[state.valueType])
                                ? state.valueType
                                : 'basic'
                        ] !== null && (
                            <div
                                class="btzForm-btzFormReset"
                                onclick={() => {
                                    actions.resetValue(props)
                                }}
                            >
                                {'x'}
                            </div>
                        )}
                </div>
            )}
            {!state.readonly ? (
                <p class="btzForm-btzFormInput-errorMessage">
                    {isDefined(state.localErrorMessage) &&
                    state.localErrorMessage !== '' &&
                    state.isError === 'is-error'
                        ? state.localErrorMessage
                        : isDefined(state.errorMessage) &&
                          state.errorMessage !== '' &&
                          state.isError === 'is-error'
                        ? state.errorMessage
                        : ''}
                </p>
            ) : (
                <p
                    class="btzForm-btzFormInput-errorMessage"
                    style="visibility: hidden;"
                ></p>
            )}
            <div class="btzForm-btzFormInput-hr"></div>
        </div>
    ),
    'forminput'
)

export { FormInput }
