export const state = {
    patients: [],
    totalPatients: null,
    loaded: false,
    page: 0,
    search: '',
    searching: false,
    sort: {},
    customer: 'theraflow',
};
