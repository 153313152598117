import { t } from 'i18next'
import { Admin } from '@app/api'
import { isDefined, isEmail, ensurePasswordStrength } from '@app/core'
import { createLocaleFromLangAndUserObject } from './localeHandlers/createLocaleFromLangAndUserObject'
const no400handleFor = ['phonePrefix']
//
const coreFn = {
    _handle400: (err) => (state, actions) => {
        if (
            isDefined(err) &&
            isDefined(err.extras) &&
            isDefined(err.extras.field)
        ) {
            let fields = err.extras.field.replace(/ /g, '').split('/')
            if (!Array.isArray(fields)) {
                fields = [fields]
            }
            for (let field in fields) {
                field = fields[field]
                if (no400handleFor.indexOf(field) > -1) {
                    continue
                }
                if (isDefined(state.values[field])) {
                    actions._setValidity({ [field]: false })
                    actions._setTyping({ [field]: false })
                }
            }
        }
    },
    /**
     * Retrieve error directly from input or when submitting form (from patient edition form)
     * @function corefn:_retrieveErrorMessagePatient
     */
    _retrieveErrorMessagePatient: (target) => (state, actions) => {
        if (isDefined(target)) {
            target = target.target.getAttribute('name')
        }
        actions._setState({ key: 'formSubmittedOnce', value: true })
        actions._setState({ key: 'formIsSubmitting', value: false })
        //
        let name = '',
            message = ''
        for (name in state.isValid) {
            if (
                !state.isValid[name] &&
                (!isDefined(target) ||
                    (isDefined(target) && target.indexOf(name) > -1))
            ) {
                message = ''
                //
                if (name === 'lname') {
                    message = t('merci de renseigner le nom du patient')
                } else if (name === 'fname') {
                    message = t('merci de renseigner le prénom du patient')
                } else if (name === 'gender') {
                    message = t('merci de renseigner le genre biologique')
                } else if (name === 'birthdate') {
                    message = t(
                        'merci de renseigner une date de naissance valide jj/mm/aaaa'
                    )
                } else if (name === 'phone') {
                    message = t(
                        'merci de renseigner un numéro de téléphone valide'
                    )
                } else if (name === 'doctor') {
                    message = t('merci de renseigner un médecin référent')
                } else if (name === 'coach') {
                    message = t(
                        'merci de renseigner un accompagnant thérapeutique'
                    )
                } else if (name === 'hospitalId') {
                    message = t('merci de renseigner un identifiant unique')
                } else if (name === 'socialSecurityNumber') {
                    message = t(
                        'merci de renseigner un numéro de sécurité sociale valide'
                    )
                } else if (name === 'email') {
                    message = t("merci de renseigner l'email")
                } else if (name === 'pathologiesType') {
                    message = t('merci de renseigner une pathologie')
                }
                actions._setErrorMessages({ [name]: message })
                actions._setTyping({ [name]: false })
            }
        }
    },
    /**
     * Retrieve error directly from input or when submitting form (from doctor edition form)
     * @function corefn:retrieveErrorMessageDoctor
     */
    _retrieveErrorMessageDoctor: (target) => (state, actions) => {
        if (isDefined(target) && isDefined(target.target)) {
            target = target.target.getAttribute('name')
        }
        actions._setState({ key: 'formSubmittedOnce', value: true })
        //
        let errorStep = state.recoveryStep || state.registerStep
        errorStep = errorStep || state.modalEditing
        //
        if (errorStep === 'email') {
            if (state.isValid.email && state.isValid.secret) {
                actions._setErrorMessages({ email: '' })
                actions._setErrorMessages({ secret: '' })
            } else {
                if (!isDefined(target) || target === 'email') {
                    if (state.values.email === '') {
                        actions._setErrorMessages({
                            email: t('saisissez une adresse email'),
                        })
                    } else if (!isEmail(state.values.email)) {
                        actions._setErrorMessages({
                            email: t(
                                'votre adresse e-mail ne semble pas valide'
                            ),
                        })
                    }
                    actions._setTyping({ email: false })
                }
                if (
                    state.values.secret === '' &&
                    (!isDefined(target) || target === 'secret')
                ) {
                    actions._setErrorMessages({
                        secret: t('saisissez une réponse secrète'),
                    })
                    actions._setTyping({ secret: false })
                }
                actions._setState({ key: 'formIsSubmitting', value: false })
            }
        } else if (errorStep === 'password') {
            if (
                (!isDefined(target) || target === 'password') &&
                !state.isValid.password
            ) {
                if (state.values.password === '') {
                    actions._setErrorMessages({
                        password: t('saisissez un mot de passe'),
                    })
                } else {
                    let pwdStrength = ensurePasswordStrength(
                        state.values.password
                    )
                    if (pwdStrength.isValid === false) {
                        actions._setErrorMessages({
                            password: t(
                                'votre mot de passe n’est pas assez fort'
                            ),
                        })
                    }
                }
                actions._setTyping({ password: false })
                actions._setState({ key: 'formIsSubmitting', value: false })
            } else if (state.isValid.password) {
                actions._setErrorMessages({ password: '' })
            }
            //
            if (
                (!isDefined(target) || target === 'passwordconfirm') &&
                !state.isValid.passwordconfirm
            ) {
                if (state.values.passwordconfirm === '') {
                    actions._setErrorMessages({
                        passwordconfirm: t('saisissez un mot de passe'),
                    })
                } else if (
                    state.values.password !== state.values.passwordconfirm
                ) {
                    actions._setErrorMessages({
                        passwordconfirm: t(
                            'les deux mots de passe ne sont pas similaires'
                        ),
                    })
                }
                actions._setTyping({ passwordconfirm: false })
                actions._setState({ key: 'formIsSubmitting', value: false })
            } else if (
                state.isValid.passwordconfirm &&
                state.values.password === state.values.passwordconfirm
            ) {
                actions._setErrorMessages({ passwordconfirm: '' })
            } else if (state.values.password !== state.values.passwordconfirm) {
                actions._setErrorMessages({
                    passwordconfirm: t(
                        'les deux mots de passe ne sont pas similaires'
                    ),
                })
            }
            //
            if (
                (!isDefined(target) || target === 'secret') &&
                !state.isValid.secret
            ) {
                if (state.values.secret === '') {
                    actions._setErrorMessages({
                        secret: t('saisissez une réponse secrète'),
                    })
                }
                actions._setTyping({ secret: false })
                actions._setState({ key: 'formIsSubmitting', value: false })
            } else if (state.isValid.secret) {
                actions._setErrorMessages({ secret: '' })
            }
        } else if (errorStep === 'phone-code-secret') {
            if (
                state.isValid.newphone &&
                state.isValid.code &&
                state.isValid.secret
            ) {
                actions._setErrorMessages({ secret: '' })
                actions._setErrorMessages({ code: '' })
                actions._setErrorMessages({ newphone: '' })
            } else {
                if (
                    !state.isValid.secret &&
                    (!isDefined(target) || target === 'secret')
                ) {
                    actions._setErrorMessages({
                        secret: t('saisissez une réponse secrète'),
                    })
                    actions._setTyping({ secret: false })
                }
                if (
                    !state.isValid.code &&
                    (!isDefined(target) || target === 'code')
                ) {
                    actions._setErrorMessages({
                        code: t('le code est invalide'),
                    })
                    actions._setTyping({ code: false })
                }
                if (
                    !state.isValid.newphone &&
                    (!isDefined(target) || target === 'newphone')
                ) {
                    actions._setErrorMessages({
                        newphone: t('le téléphone est mal formaté'),
                    })
                    actions._setTyping({ newphone: false })
                }
                actions._setState({ key: 'formIsSubmitting', value: false })
            }
        } else if (errorStep === 'phone') {
            if (state.isValid.newphone && state.isValid.phonePrefix) {
                actions._setErrorMessages({ newphone: '' })
                actions._setErrorMessages({ phonePrefix: '' })
            } else {
                actions._setValidity({ newphone: false })
                if (state.values.newphone === '') {
                    actions._setErrorMessages({
                        newphone: t('saisissez un numéro de téléphone'),
                    })
                } else {
                    actions._setErrorMessages({
                        newphone: t(
                            'votre numéro de téléphone ne semble pas valide'
                        ),
                    })
                }
                actions._setTyping({ newphone: false })
                actions._setState({ key: 'formIsSubmitting', value: false })
            }
        } else if (errorStep === 'phone-code') {
            if (state.isValid.code) {
                actions._setErrorMessages({ code: '' })
            } else {
                actions._setValidity({ code: false })
                actions._setTyping({ code: false })
                actions._setErrorMessages({
                    code: t('saisissez le code complet'),
                })
                actions._setState({ key: 'formIsSubmitting', value: false })
            }
        } else if (errorStep === 'code') {
            if (state.isValid.code) {
                actions._setErrorMessages({ code: '' })
            } else {
                if (state.values.code.join('') === '') {
                    actions._setErrorMessages({
                        code: t('saisissez un code complet'),
                    })
                } else {
                    actions._setErrorMessages({
                        code: t('le code renseigné ne semble pas être le bon'),
                    })
                }
                actions._setTyping({ code: false })
                actions._setState({ key: 'formIsSubmitting', value: false })
            }
        } else if (errorStep === 'secret') {
            if (state.isValid.secret) {
                actions._setErrorMessages({ secret: '' })
            } else {
                if (!state.isValid.secret) {
                    if (
                        state.values.secret === '' &&
                        (!isDefined(target) || target === 'secret')
                    ) {
                        actions._setErrorMessages({
                            secret: t('saisissez une réponse secrète'),
                        })
                    }
                    actions._setTyping({ secret: false })
                }
                actions._setState({ key: 'formIsSubmitting', value: false })
            }
        }
    },
    /**
     * Retrieve form input on the fly
     * @function corefn:retrieveInput
     * @param {input} input - Contain [isvalid], [name], [id] and [values] fields
     */
    _retrieveInput: (input) => (state, actions) => {
        const { isvalid, name, values } = input
        if (name === 'locale') {
            if (
                isDefined(values) &&
                values !== '' &&
                isDefined(state.isDoctor) &&
                state.isDoctor === true &&
                values !== state.doctor.locale
            ) {
                if (!state.formIsSubmitting) {
                    actions._setState({ key: 'formIsSubmitting', value: true })
                    const parsedLocale = createLocaleFromLangAndUserObject(
                        values,
                        state.doctor
                    )
                    Admin.updateDoctor({ locale: parsedLocale }).then(() => {
                        actions._setState({
                            key: 'formIsSubmitting',
                            value: false,
                        })
                        localStorage.setItem('locale', values)
                        if (isDefined(state.updateDoctor)) {
                            state.updateDoctor(true)
                        }
                    })
                    return false
                }
            }
        }
        if (state.values[name] === undefined && values === undefined) {
            // if [name] is not defined in state.values, the field must not be sended
            return false
        }
        let testingValue = values
        let testedValue = state.values[name]
        if (!isDefined(testingValue)) {
            testingValue = ''
        } else if (Array.isArray(testingValue)) {
            testingValue = testingValue.join('')
        } else if (typeof testingValue === 'object') {
            testingValue = Object.values(testingValue).join('')
        }
        if (isDefined(testedValue)) {
            if (Array.isArray(testedValue)) {
                testedValue = testedValue.join('')
            } else if (typeof testedValue === 'object') {
                testedValue = Object.values(testedValue).join('')
            }
        }
        //
        let isItReallyValid = isvalid
        if (name.indexOf('password') > -1) {
            if (values !== '' && isvalid) {
                if (name === 'password') {
                    isItReallyValid = true
                    actions._setValidity({ passwordconfirm: isItReallyValid })
                } else if (
                    name === 'passwordconfirm' &&
                    values === state.values.password &&
                    state.isValid.password
                ) {
                    isItReallyValid = true
                    actions._setValidity({ password: isItReallyValid })
                } else {
                    isItReallyValid = false
                }
            } else {
                isItReallyValid = false
            }
            if (isItReallyValid) {
                actions._setErrorMessages({ password: '' })
            }
        }
        //
        actions._setValues({ [name]: values })
        actions._setValidity({ [name]: isItReallyValid })
        if (name === 'secret') {
            if (values !== state.values[name]) {
                actions._setTyping({ [name]: true })
            }
        } else {
            actions._setTyping({ [name]: true })
        }
        //
        if (isvalid) {
            actions._setErrorMessages({ [name]: '' })
        }
        //
        if (
            isDefined(state.atLeastOneModification) &&
            !state.atLeastOneModification &&
            isDefined(testedValue) &&
            testedValue !== testingValue
        ) {
            if (['redirection'].indexOf(name) === -1) {
                window.onbeforeunload = function () {
                    return ''
                }
                actions._handleLeaveWithoutSaveing(true)
                actions._setState({
                    key: 'atLeastOneModification',
                    value: true,
                })
            }
        }
        //
        if (
            name.indexOf('password') > -1 &&
            state.values.passwordconfirm !== '' &&
            isvalid
        ) {
            if (
                (name === 'password' &&
                    values === state.values.passwordconfirm) ||
                (name === 'passwordconfirm' && values === state.values.password)
            ) {
                actions._setErrorMessages({ passwordconfirm: '' })
                actions._setErrorMessages({ password: '' })
            } else {
                actions._setValidity({ passwordconfirm: false })
                actions._setErrorMessages({
                    passwordconfirm: t(
                        'les deux mots de passe ne sont pas similaires'
                    ),
                })
                actions._setTyping({ passwordconfirm: false })
            }
        }
        //
        if (isItReallyValid) {
            actions._setErrorMessages({ [name]: '' })
            if (name.indexOf('phone') > -1) {
                actions._setState({ key: 'modalEditing', value: 'phone' })
            } else if (name.indexOf('code') > -1) {
                actions._setState({ key: 'modalEditing', value: 'phone-code' })
            }
        }
        if (values === '' || !isDefined(values)) {
            actions._setTouched({ [name]: false })
        } else if (!state.isTouched[name]) {
            actions._setTouched({ [name]: true })
        }
        //
        actions._globalOnInputCallback()
    },
    /**
     * Listener to display disclamer popup if leaving without saving changes (displayed only if a change has occured)
     * @param {boolean} handle - Boolean to activate/deactivate the listener
     * @function corefn:handleLeaveWithoutSaveing
     */
    _handleLeaveWithoutSaveing: (handle) => (state, actions) => {
        if (handle) {
            window.onbeforeunload = function () {
                return ''
            }
        } else {
            window.onbeforeunload = function () {
                //
            }
        }
        actions._setState({ key: 'atLeastOneModification', value: handle })
    },
    _globalOnInputCallback: () => (state, actions) => {
        //
    },
    /**
     * Update error message key state
     * @function corefn:setErrorMessages
     * @param {object} newState - Target {[name]: {string}} to update
     */
    _setErrorMessages: (newState) => (state) => ({
        errorMessages: { ...state.errorMessages, ...newState },
    }),
    /**
     * Update values key state
     * @function corefn:setValues
     * @param {object} newState - Target {[name]: values} to update
     */
    _setValues: (newState) => (state) => ({
        values: { ...state.values, ...newState },
    }),
    /**
     * Update the whole values object
     * @function corefn:setValuesObj
     * @param {object} newState - Values object to replace with
     */
    _setValuesObj: (newState) => (state) => ({
        values: newState,
    }),
    /**
     * Update the whole ovalues object
     * @function corefn:setOValuesObj
     * @param {object} newState - Ovalues object to replace with
     */
    _setOValuesObj: (newState) => (state) => ({
        ovalues: newState,
    }),
    /**
     * Update touched key state
     * @function corefn:setTouched
     * @param {object} newState - Target {[name]: {boolean}} to update
     */
    _setTouched: (newState) => (state) => ({
        isTouched: { ...state.isTouched, ...newState },
    }),
    /**
     * Update validity key state
     * @function corefn:setValidity
     * @param {object} newState - Target {[name]: {boolean}} to update
     */
    _setValidity: (newState) => (state) => ({
        isValid: { ...state.isValid, ...newState },
    }),
    /**
     * Update state
     * @function corefn:setState
     * @param {string} key - Target key to update
     * @param {string} value - value
     */
    _setState:
        ({ key, value }) =>
        (state) => ({
            [key]: value,
        }),
    /**
     * Update all typing key state
     * @function corefn:setTypingFalse
     */
    _setTypingFalse: () => (state, actions) => {
        let isTyping = state.isTyping
        for (let tpng in isTyping) {
            isTyping[tpng] = false
        }
        state.isTyping = isTyping
    },
    /**
     * Update typing key state
     * @function corefn:setTyping
     * @param {object} newState - Target {[name]: {boolean}} to update
     */
    _setTyping: (newState) => (state) => ({
        isTyping: { ...state.isTyping, ...newState },
    }),
}

export { coreFn }
