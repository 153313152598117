import { t } from 'i18next'
import { Session } from '@app/api'
import {
    enableTheseButton,
    desableTheseButton,
    getUser,
    getSupport,
    parseMobileLeadingZero,
} from '@app/core'
import {
    allRoutes,
    addCSRFToken,
    isDefined,
    redirectToGoodIndex,
    coreFn,
    getQueryVariable,
} from '@app/core'

/**
 * @module LoginAuthentication
 * @description Login page
 */

export default {
    initAction: function () {
        let LoginAuthentication = {
            onComponentCreate: (props) => (state, actions) => {
                window.main.triggerEvent('login-reached')
                sessionStorage.removeItem('infos')
                //
                let refresh = localStorage.getItem('refresh')
                if (
                    isDefined(props.customer) &&
                    props.customer === 'theraflow'
                ) {
                    localStorage.removeItem('refresh')
                    refresh = null
                }
                actions._setState({ key: 'customer', value: props.customer })
                //
                let apitoken = localStorage.getItem('apitoken')
                let redirect = getQueryVariable('redirect', false)
                if (
                    isDefined(apitoken) &&
                    apitoken !== 'null' &&
                    redirect !== false &&
                    isDefined(redirect, true, true)
                ) {
                    getUser((loggedUser) => {
                        if (isDefined(loggedUser) && isDefined(loggedUser.id)) {
                            redirectToGoodIndex(window.location.search)
                        } else {
                            localStorage.removeItem('apitoken')
                            actions.initLogin(props)
                        }
                    }, true)
                } else {
                    actions.initLogin(props)
                }
            },
            /**
             * Initialize login page, clear storage and set <enter> keyboard key listener
             * @function LoginAuthentication:initLogin
             * @param {object} props - /
             */
            initLogin: (props) => (state, actions) => {
                if (props.send) {
                    state.sendToParent = props.send
                }
                // sessionStorage.removeItem('csrftoken');
                sessionStorage.removeItem('adminuser')
                //
                localStorage.removeItem('adminuser')
                localStorage.removeItem('refresh')
                localStorage.removeItem('expiration')

                window.onkeyup = function (e) {
                    var key = e.keyCode ? e.keyCode : e.which
                    if (key === 13) {
                        e.preventDefault()
                        e.stopPropagation()
                        actions.onSubmit(e)
                        return false
                    }
                }
            },
            /**
             * Submit login form, validation and error message displaying
             * @function LoginAuthentication:onSubmit
             */
            onSubmit: (event) => (state, actions) => {
                event.preventDefault()
                event.stopPropagation()

                if (state.formIsSubmitting) {
                    return
                }
                actions._setTypingFalse()
                actions.retrieveErrorMessage(null)
                if (state.loginStep === 'login') {
                    if (state.isValid.email && state.isValid.password) {
                        actions._setState({
                            key: 'formIsSubmitting',
                            value: true,
                        })
                        actions.createSession(event)
                    } else {
                        //
                    }
                } else if (state.loginStep === 'code') {
                    if (state.isValid.code) {
                        actions._setState({
                            key: 'formIsSubmitting',
                            value: true,
                        })
                        actions.createSession(event)
                    } else {
                        //
                    }
                }
            },
            /**
             * Retrieve error directly from input or when submitting form
             * @function LoginAuthentication:retrieveErrorMessage
             */
            retrieveErrorMessage: (target) => (state, actions) => {
                if (isDefined(target)) {
                    target = target.target.getAttribute('name')
                }
                actions._setState({ key: 'formSubmittedOnce', value: true })
                if (state.loginStep === 'login') {
                    if (state.isValid.email && state.isValid.password) {
                        actions._setErrorMessages({ email: '' })
                        actions._setErrorMessages({ password: '' })
                    } else {
                        actions._setState({
                            key: 'formIsSubmitting',
                            value: false,
                        })
                        if (!isDefined(target) || target === 'email') {
                            if (state.values.email === '') {
                                actions._setErrorMessages({
                                    email: t(
                                        'saisissez votre adresse email habituelle'
                                    ),
                                })
                                actions._setTyping({ email: false })
                            } else if (!state.isValid.email) {
                                actions._setErrorMessages({
                                    email: t(
                                        "l'email renseigné n'est pas valide"
                                    ),
                                })
                                actions._setTyping({ email: false })
                            }
                        }
                        if (!isDefined(target) || target === 'password') {
                            if (state.values.password === '') {
                                actions._setErrorMessages({
                                    password: t(
                                        'saisissez votre mot de passe habituel'
                                    ),
                                })
                                actions._setTyping({ password: false })
                            }
                        }
                    }
                } else if (state.loginStep === 'code') {
                    if (state.isValid.code) {
                        actions._setErrorMessages({ code: '' })
                    } else {
                        actions._setState({
                            key: 'formIsSubmitting',
                            value: false,
                        })
                        actions._setErrorMessages({
                            code: t(
                                'le code renseigné ne semble pas être le bon'
                            ),
                        })
                        actions._setValidity({ code: false })
                        actions._setTyping({ code: false })
                    }
                }
            },
            /**
             * Send to [createSessionAgain]{@link module:LoginAuthentication~LoginAuthentication:createSessionAgain}) or try to open session using <code> (first daily login), then, if success, call redirectToGoodIndex
             * @function LoginAuthentication:createSession
             */
            createSession: (event) => (state, actions) => {
                if (state.loginStep === 'login') {
                    actions.createSessionAgain(event)
                } else if (state.loginStep === 'code') {
                    const { code, password } = state.values
                    Session.openSession(
                        code.join(''),
                        password,
                        state.accessToken
                    ).then((res) => {
                        if (
                            isDefined(res.data) &&
                            !isDefined(res.data.message)
                        ) {
                            localStorage.setItem('apitoken', res.data.token)
                            localStorage.setItem('refresh', res.data.refresh)
                            localStorage.setItem(
                                'expiration',
                                res.data.expiration.date
                            )

                            getUser(() => {
                                redirectToGoodIndex(window.location.search)
                            }, true)
                            return false
                        } else {
                            if (isDefined(res.data) && +res.data.code === 102) {
                                actions._setErrorMessages({
                                    code: t(
                                        'le code renseigné ne semble pas être le bon'
                                    ),
                                })
                            } else {
                                actions._setErrorMessages({
                                    code: res.data.message,
                                })
                            }
                            actions._setState({
                                key: 'formIsSubmitting',
                                value: false,
                            })
                            actions._setValidity({ code: false })
                        }
                    })
                }
            },
            /**
             * Create session using <email>/<pwd>, then, if success (and not the first daily login), call redirectToGoodIndex
             * @function LoginAuthentication:createSessionAgain
             */
            createSessionAgain: (event) => (state, actions) => {
                event.preventDefault()
                event.stopPropagation()

                const { email, password } = state.values
                Session.createSession(email, password).then((res) => {
                    if (
                        isDefined(res.data) &&
                        res.data !== false &&
                        isDefined(res.data.token)
                    ) {
                        if (isDefined(res.data.refresh)) {
                            localStorage.setItem('apitoken', res.data.token)
                            localStorage.setItem('refresh', res.data.refresh)
                            localStorage.setItem(
                                'expiration',
                                res.data.expiration.date
                            )

                            getUser((doctor) => {
                                if (isDefined(doctor.locale)) {
                                    localStorage.setItem(
                                        'locale',
                                        doctor.locale
                                    )
                                }
                                redirectToGoodIndex(window.location.search)
                            }, true)
                        } else if (isDefined(res.data.token)) {
                            if (isDefined(res.data.phone)) {
                                let phone = res.data.phone
                                if (phone.length > 10) {
                                    let lastChars = phone.substring(
                                        phone.length - 10
                                    )
                                    let firstChars = phone.substring(
                                        0,
                                        phone.length - 10
                                    )
                                    phone = lastChars.match(/.{2}/g)
                                    phone[0] = firstChars + phone[0]
                                } else {
                                    phone =
                                        parseMobileLeadingZero(phone).match(
                                            /.{2}/g
                                        )
                                }
                                // actions._setValues({ 'phone': parseMobileLeadingZero(res.data.phone).match(/.{2}/g) });
                                actions._setValues({ phone: phone })
                            }
                            actions._setValues({
                                code: ['', '', '', '', '', ''],
                            })
                            //
                            actions._setState({
                                key: 'accessToken',
                                value: res.data.token,
                            })
                            actions._setState({
                                key: 'loginStep',
                                value: 'code',
                            })
                            actions._setState({
                                key: 'formIsSubmitting',
                                value: false,
                            })
                            actions._setState({
                                key: 'formSubmittedOnce',
                                value: false,
                            })
                        }
                    } else {
                        if (isDefined(res.data) && +res.data.code === 208) {
                            actions._setErrorMessages({
                                password:
                                    t('compte bloqué : écrivez à') +
                                    ' ' +
                                    getSupport(),
                            })
                        } else {
                            actions._setErrorMessages({
                                password: t(
                                    'la combinaison email / mot de passe est incorrecte'
                                ),
                            })
                        }
                        actions._setState({
                            key: 'formIsSubmitting',
                            value: false,
                        })
                        actions._setValidity({ password: false })
                    }
                })
            },
            /**
             * Redirect to the recovery page
             * @function LoginAuthentication:gotoResetPassword
             */
            gotoResetPassword: () => (state, actions) => {
                window.location.href = addCSRFToken(
                    allRoutes['index.reset'].pathname
                )
            },
        }
        LoginAuthentication = { ...coreFn, ...LoginAuthentication }
        return LoginAuthentication
    },
}
