import { settings } from '@app/core/settings';
import { handleErrors } from '@app/api/errors';
import { queueQueries } from '@app/api/controlleur';

const { API_URL } = settings;

function closeSurvey(id, studyid) {
    return queueQueries(`${API_URL}/theraflow/medical-study-repeatable/${id}/close-alert/${studyid}/`, 'PUT');
}
function getStudy(id) {
    return queueQueries(`${API_URL}/theraflow/medical-study/user/${id}/`, 'GET');
}
function sendStudy(id, data) {
    return queueQueries(`${API_URL}/theraflow/medical-study/user/${id}/`, 'POST', 'application/json', true, true, true, true, true, data);
}
function updateStudy(id, studyid, data) {
    return queueQueries(`${API_URL}/theraflow/medical-study/user/${id}/${studyid}/`, 'PUT', 'application/json', true, true, true, true, true, data);
}
function getMedicalSurvey(id, studyname, page) {
    return queueQueries(`${API_URL}/theraflow/medical-study-repeatable/${id}/responses/${studyname}/${page}/1/`, 'GET');
}
function getMedicalSurveyAlerts(page, filter) {
    return queueQueries(`${API_URL}/theraflow/medical-study-repeatable/alert/search/${page}/`, 'POST', 'application/json', true, true, true, true, true, filter);
}
function getMedicalColoredSurveyAlerts(page, filter) {
    return queueQueries(`${API_URL}/theraflow/medical-study/alert/search/${page}/`, 'POST', 'application/json', true, true, true, true, true, filter);
}
function getMedicalColoredSurveyAlert(id) {
    return queueQueries(`${API_URL}/theraflow/medical-study/alert/${id}/content/`, 'GET');
}
function getLastStudyConfig(userid) {
    return queueQueries(`${API_URL}/theraflow/medical-study-repeatable/get-last-config/${userid}/`, 'GET');
}


function getStudyAnswers(answerid) {
    return queueQueries(`${API_URL}/theraflow/medical-study-common/answer/${answerid}/`, 'GET');
}
function updateStudyAnswerAction(answerid, data) {
    return queueQueries(`${API_URL}/theraflow/medical-study-common/action/${answerid}/`, 'PUT', 'application/json', true, true, true, true, true, data);
}
function addStudyAnswerComment(answerid, data) {
    return queueQueries(`${API_URL}/theraflow/medical-study-common/comment/${answerid}/`, 'POST', 'application/json', true, true, true, true, true, data);
}
function getStudyTimeline(patientid) {
    return queueQueries(`${API_URL}/theraflow/medical-study-oneoff/timeline/${patientid}/`, 'GET');
}
function getStudyMomentEligibility(userMedicalStudyId) {
    return queueQueries(`${API_URL}/theraflow/medical-study-oneoff/moment-eligibility/${userMedicalStudyId}/`, 'GET');
}
function getBufferedStudyMomentEligibility(data) {
    return queueQueries(`${API_URL}/theraflow/medical-study-oneoff/moment-eligibility/`, 'POST', 'application/json', true, true, true, true, true, data);
}

function sendInstantSurvey(userid, studyid) {
    return queueQueries(`${API_URL}/theraflow/medical-study-repeatable/${userid}/send-survey/${studyid}/`, 'POST');
}

function getMedicalInfos() {
    return queueQueries(`${API_URL}/theraflow/medical-study-repeatable/infos/`, 'GET');
}
function getMedicalNamedInfos(name) {
    return queueQueries(`${API_URL}/theraflow/medical-study-repeatable/infos/${name}/`, 'GET');
}

/*function getAlertAll(page, data) {
    return queueQueries(`${API_URL}/theraflow/user-alerts/all/search/${page}/`, 'POST', 'application/json', true, true, true, true, true, data);
}*/
function getMomentsUnderAlert(page, data) {
    return queueQueries(`${API_URL}/theraflow/medical-study-oneoff/listing/${page}/`, 'POST', 'application/json', true, true, true, true, true, data);
}

export const Medical = {
    closeSurvey,
    getStudy,
    sendStudy,
    updateStudy,
    getMedicalSurvey,
    getMedicalSurveyAlerts,
    getMedicalColoredSurveyAlerts,
    getMedicalColoredSurveyAlert,
    getStudyAnswers,
    updateStudyAnswerAction,
    addStudyAnswerComment,
    getStudyTimeline,
    getStudyMomentEligibility,
    getBufferedStudyMomentEligibility,
    sendInstantSurvey,
    getMomentsUnderAlert,
    getMedicalInfos,
    getMedicalNamedInfos,
    getLastStudyConfig,
};