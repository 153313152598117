export const state = {
    loaded: false,
    editedPatient: null,
    autoGeneratedEmail: false,
    buffedValues: null,
    values: {},
    isValid: {
        issueSentBy: false,
        inclusionDate: false,
        beginDate: false,
        endDateLogo: false,
        endDate: false,
        visitDate: false,
        surgeryDate: false,
        firstInterviewDate: false,
        postT2Date: false,
        postSurgeryDate: false,
    },
    isTouched: {
        issueSentBy: false,
        inclusionDate: true,
        beginDate: false,
        endDateLogo: false,
        endDate: false,
        visitDate: false,
        surgeryDate: false,
        firstInterviewDate: false,
        postT2Date: false,
        postSurgeryDate: false,
    },
    isTyping: {
        issueSentBy: false,
        inclusionDate: false,
        beginDate: false,
        endDateLogo: false,
        endDate: false,
        visitDate: false,
        surgeryDate: false,
        firstInterviewDate: false,
        postT2Date: false,
        postSurgeryDate: false,
    },
    errorMessages: {
        issueSentBy: '',
    },
    isValidForm: false,
    studyId: null,
    studies: [],
    referenceHealTeam: [],
    patientHealTeam: [],
    formIsSubmitting: false,
    formSubmittedOnce: false,
    patientstatus: 0,
    modaldisplayed: '',
    pastTimelines: [],
    currentTimeline: undefined,
    currentAnswer: null,
    surveyUrl: null,
    momentEligibility: [],
    displayMode: 'default', // 'default' / 'moment'
    queryParam: {
        studyid: null,
        commentid: null,
    },
    updateParentState: null,
    newAnswerSelected: false,
    answerName: '',
    answerId: null,
    messagerObj: null,
}
