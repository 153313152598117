import { t } from "i18next";
import { h } from "hyperapp";
import { Component } from "@app/utils";
import { isDefined, getReadableDate, getDateObjFromYMD } from "@app/core";
import { Tile } from "@app/elements";

import icoCE from "@app/img/mentions/logo-right-ce.png";
import icoCEx2 from "@app/img/mentions/logo-right-ce@2x.png";
import icoFACTORY from "@app/img/mentions/logo-right-factory.png";
import icoFACTORYx2 from "@app/img/mentions/logo-right-factory@2x.png";
import icoINFOS from "@app/img/mentions/logo-infos.png";
import icoINFOSx2 from "@app/img/mentions/logo-infos@2x.png";

import icoMD from "@app/img/ico/custo/resilience/ico-mentions-md.png";
import icoMDx2 from "@app/img/ico/custo/resilience/ico-mentions-md@2x.png";
import icoREF from "@app/img/ico/custo/resilience/ico-mentions-ref.png";
import icoREFx2 from "@app/img/ico/custo/resilience/ico-mentions-ref@2x.png";
import icoUDI from "@app/img/ico/custo/resilience/ico-mentions-udi.png";
import icoUDIx2 from "@app/img/ico/custo/resilience/ico-mentions-udi@2x.png";

import ViewInstructionDefault from "./subview-instructions";

const actions = {
	//
};

const view = (state, actions) => (props, children) =>
	(
		<dummy>
			<Tile>
				<Tile.Content>
					<p class="btzTitle btzXl">{t("Mentions légales")}</p>
				</Tile.Content>
			</Tile>
			<div
				class="thfMentionsWrapper"
				style="padding: 20px 15px 0px;background: white;">
				<p class="">
					{t(
						"La solution Resilience comprend le module de télésurveillance Oncolaxy, indiqué pour le suivi de patients atteints de cancer, en permettant l’analyse de l’évolution globale de la dynamique bio-médicale dans le but de détecter une évolution, une récidive ou une toxicité thérapeutique."
					)}
				</p>
				<p class="">
					{t(
						"L‘ensemble des éléments constituant notre plateforme ont été conçus en suivant de près les normes internationales les plus strictes en terme de gestion de la qualité, de sécurité du système d‘information et de la conception de logiciels dispositifs médicaux."
					)}
				</p>
				<div class="" style="margin-bottom: 30px;margin-top: 30px;">
					<div style="margin-bottom: 20px;">
						<img
							class=""
							style="margin-right: 20px;height: 64px;vertical-align: middle"
							src={icoMD}
							srcset={`${icoMDx2} 2x`}
							alt=""
						/>
						<div
							class="thfMentionsLogos"
							style="display: inline-block;vertical-align: middle;height: 40px;">
							<img
								class=""
								style="margin-right: 5px;height: 40px;"
								src={icoCE}
								srcset={`${icoCEx2} 2x`}
								alt=""
							/>
							<p
								class=""
								style="display: inline-block; margin: 0px 10px 0px 0px; height: 15px;line-height: 1;">
								{"0459"}
							</p>
							<img
								class=""
								style="margin-right: 15px;height: 40px;"
								src={icoINFOS}
								srcset={`${icoINFOSx2} 2x`}
								alt=""
							/>
							<img
								class=""
								style="height: 40px;"
								src={icoFACTORY}
								srcset={`${icoFACTORYx2} 2x`}
								alt=""
							/>
						</div>
						<div style="display: inline-block;margin-left: 15px;vertical-align: middle;">
							<p class="" style="margin: 0px;line-height: 1;">
								Betterise Technologies
							</p>
							<p class="" style="margin: 0px;line-height: 1;">
								3 rue Loustau, 64200 Biarritz
							</p>
							<p class="" style="margin: 0px;line-height: 1;">
								{props.support.mailto}
							</p>
						</div>
					</div>
					<div style="margin-bottom: 20px;">
						<img
							class=""
							style="margin-right: 20px;height: 64px;vertical-align: middle"
							src={icoREF}
							srcset={`${icoREFx2} 2x`}
							alt=""
						/>
						<div style="display: inline-block;vertical-align: middle;">
							<p
								class=""
								style="text-transform: capitalize;margin: 0px;">
								{"Oncolaxy" +
									" - " +
									t("Versions : ") +
									(process.env.APP_VERSIONPRODUCT || "N.C") +
									" : (Front " +
									(process.env.APP_VERSION || "N.C") +
									", API " +
									(process.env.APP_VERSIONAPI || "N.C") +
									")" +
									(isDefined(process.env.APP_YMD)
										? ", " +
										  getReadableDate(
												getDateObjFromYMD(
													process.env.APP_YMD
												).date,
												"dddd DD MMMM YYYY"
										  )
										: " - N.C")}
							</p>
						</div>
					</div>
					{/*<div>
                    <img class='' style='margin-right: 20px;height: 64px;vertical-align: middle' src={icoUDI} srcset={`${icoUDIx2} 2x`} alt='' />
                    <div style='display: inline-block;vertical-align: middle;'>
                        <p class='' style='text-transform: capitalize;margin: 0px;'>{'XXXXXXXXXXXXXXXXXXX'}</p>
                    </div>
                </div>*/}
				</div>
				<p class="">
					{t(
						"Seules les pages identifiées par « Oncolaxy, CE 0459 » constituent le module Oncolaxy marqué CE. »"
					)}
				</p>
				<ViewInstructionDefault
					support={props.support}
					legalDocuments={
						Array.isArray(props.legalAgreements)
							? props.legalAgreements.map(
									(item) =>
										item.legalDocument ||
										item.legalDocuments
							  )
							: props.legalAgreements.legalDocument ||
							  props.legalAgreements.legalDocuments
					}
					forcedCustomer={"Oncolaxy"}></ViewInstructionDefault>
			</div>
		</dummy>
	);

export default Component({}, actions, view, "subview");
