import UAParser from 'ua-parser-js'

export const supportedBrowsers = [
    { name: 'Firefox', minVersion: 80 },
    { name: 'Chrome', minVersion: 79 },
    { name: 'Chrome Headless', minVersion: 79 },
    { name: 'Edge', minVersion: 18 },
]

export const getUserAgentInfos = (userAgent) => {
    const parser = new UAParser(userAgent)
    const browser = parser.getBrowser()
    const device = parser.getDevice()

    // unknown user agents are not supported
    if (!browser.version || !browser.name) {
        return { supported: false }
    }

    // mobile are not supported, even if they use supported browsers
    if (device.type === 'mobile') {
        return { supported: false, isMobile: true }
    }

    const major = parseInt(browser.version.split('.')[0])

    const supportedBrowser = supportedBrowsers.find(
        (b) => b.name === browser.name
    )

    if (!supportedBrowser || major < supportedBrowser.minVersion) {
        return {
            supported: false,
            isMobile: false,
            browserName: browser.name,
            browserMajor: major,
        }
    }

    return {
        supported: true,
    }
}
