import { h } from 'hyperapp';
import { Component } from '@app/utils';
import './../Login/index.scss';
import './index.scss';

import RegisterLayout from '@app/layouts/register/layout';

import Registering from './registering';

const state = {
    email: {
        value: '',
        isValid: false,
        isApproved: null,
        isResetted: null
    },
    password: {
        value: '',
        isValid: false,
        isApproved: null,
        isResetted: null
    }
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        // localStorage.clear();
    },
    setState: ({ ns, key, value }) => state => ({
        [ns]: { ...state[ns], ...{ [key]: value } },
    })
};

const view = (state, actions) => ({ match, location, customer, defineLanguage }, children) => (
    <div key='register' class='root-wrapper'>
        <RegisterLayout page='register-root layout-fullheight' customer={customer}>
            {/* Default view */}
            <Registering initial={state.email} customer={customer} defineLanguage={defineLanguage} send={actions.setState} location={location} match={match} />
        </RegisterLayout>
    </div>
);

export {
    Registering,
};

export default Component(state, actions, view, 'register');
