import { t } from 'i18next'

import icoFlagfrFRx2 from '@app/img/ico/flag/flag-fr_FR@2x.png'
import icoFlagenGBx2 from '@app/img/ico/flag/flag-en_GB@2x.png'
import icoFlagenNLx2 from '@app/img/ico/flag/flag-nl_BE@2x.png'
import icoFlagdeDEx2 from '@app/img/ico/flag/flag-de_DE@2x.png'

import icoFlagNLx2 from '@app/img/ico/flag/flag-nl_NL@2x.png'
import icoFlagESx2 from '@app/img/ico/flag/flag-es_ES@2x.png'
import icoFlagPTx2 from '@app/img/ico/flag/flag-pt_PT@2x.png'
import icoFlagLUx2 from '@app/img/ico/flag/flag-fr_LU@2x.png'
import icoFlagIEx2 from '@app/img/ico/flag/flag-en_IE@2x.png'
import icoFlagITx2 from '@app/img/ico/flag/flag-it_IT@2x.png'
import icoFlagCHx2 from '@app/img/ico/flag/flag-fr_CH@2x.png'
import icoFlagGBx2 from '@app/img/ico/flag/flag-en_GB@2x.png'
import icoFlagMAx2 from '@app/img/ico/flag/flag-ar_MA@2x.png'
import icoFlagDZx2 from '@app/img/ico/flag/flag-ar_DZ@2x.png'
import icoFlagTNx2 from '@app/img/ico/flag/flag-ar_TN@2x.png'
import icoFlagLYx2 from '@app/img/ico/flag/flag-ar_LY@2x.png'
import icoFlagSAx2 from '@app/img/ico/flag/flag-ar_SA@2x.png'

// IE z-index is broken by translateY
// export const ANIM_CSS = { transform: 'translateY(-5px)', opacity: '0' };
export const ANIM_CSS = { opacity: '0' }
export const ANIM_EASING = 'ease-in-out'
export const ANIM_DURATION = 500
export const MANDATORY_THEMES = [5, 15, 16, 99]

export const configureSurvey = [
    {
        label: 'ToutK00001',
        key: 'toutk00001',
        frequency: 'weekly',
        issuePeriod: 'weeklyMonday',
        order: 0,
    },
    {
        label: 'Tout-K2',
        key: 'toutk00002',
        frequency: 'weekly',
        issuePeriod: 'weeklyMonday',
        order: 1,
    },
    {
        label: 'KidneyPRO',
        key: 'kidneypro',
        frequency: 'weekly',
        issuePeriod: 'weeklyMonday',
        order: 2,
    },
    {
        label: 'cardioIC',
        key: 'cardioic',
        frequency: 'weekly',
        issuePeriod: 'weeklyMonday',
        order: 3,
    },
]

export const alertColors = [
    { label: 'Toutes', labelI18N: t('Toutes'), key: 'null', order: 0 },
    {
        label: 'Alertes jaunes',
        labelI18N: t('Alertes jaunes'),
        key: '2',
        order: 1,
    },
    {
        label: 'Alertes oranges',
        labelI18N: t('Alertes oranges'),
        key: '3',
        order: 2,
    },
    {
        label: 'Alertes rouges',
        labelI18N: t('Alertes rouges'),
        key: '4',
        order: 3,
    },
    {
        label: 'Alertes Non répondus',
        labelI18N: t('Alertes Non répondus'),
        key: '-1',
        order: 4,
    },
]

export const alertPeriods = [
    { label: 'Toutes', labelI18N: t('Toutes'), key: 'null', order: 0 },
    {
        label: "Aujourd'hui",
        labelI18N: t("Aujourd'hui"),
        key: 'P0D-P0D',
        order: 1,
    },
    { label: 'Hier', labelI18N: t('Hier'), key: 'P1D-P1D', order: 2 },
    {
        label: '7 derniers jours',
        labelI18N: t('7 derniers jours'),
        key: 'P7D-P0D',
        order: 3,
    },
    {
        label: '30 derniers jours',
        labelI18N: t('30 derniers jours'),
        key: 'P30D-P0D',
        order: 4,
    },
]
export const surveyStatus = [
    {
        label: 'Tous les questionnaires',
        labelI18N: t('Tous les questionnaires'),
        key: 'null',
        order: 0,
    },
    {
        label: 'Non répondu',
        labelI18N: t('Non répondu'),
        key: 'nodata',
        order: 1,
    },
    {
        label: 'Seulement les alertes',
        labelI18N: t('Seulement les alertes'),
        key: 'alerts',
        order: 1,
    },
]

// export const CUSTOMERS_COMPANY_PROVISIONNING = ['cerba', 'auchan'];
// export const CUSTOMERS_WHYTE_BRAND = ['cerba'];
export const CUSTOMERS = {
    theraflow: {
        title: 'Theraflow',
        description: 'Theraflow description',
        i18ndescription: t('Theraflow description'),
        logo: 'Theraflow',
        mailto: 'support@theraflow.care',
        appurl: {
            ios: 'https://itunes.apple.com/fr/app/betterise-le-majordome-de-votre-sant%C3%A9/id916264011?mt=8',
            android:
                'https://play.google.com/store/apps/details?id=com.betterise.android',
        },
        modules: {
            noSWRoutes: ['/pdf/'],
            notice: '/__pdf/notices/default/notice-soignant.pdf',
            noticePatient: '/__pdf/notices/default/notice-patient.pdf',
            patient: {
                etapes: true,
            },
            codeSendBy: 'sms',
        },
    },
    weprom: {
        title: 'KidneyPRO',
        description: 'KidneyPRO description',
        i18ndescription: t('WepProm description'),
        logo: 'KidneyPRO',
        mailto: 'support@oncolaxy.care',
        appurl: {
            ios: 'https://itunes.apple.com/fr/app/betterise-le-majordome-de-votre-sant%C3%A9/id916264011?mt=8',
            android:
                'https://play.google.com/store/apps/details?id=com.betterise.android',
        },
        modules: {
            noSWRoutes: ['/pdf/'],
            notice: '/__pdf/notices/weprom/notice-soignant.pdf',
            noticePatient: '/__pdf/notices/weprom/notice-patient.pdf',
            patient: {
                etapes: false,
            },
            codeSendBy: 'email',
        },
    },
    oncoflow: {
        title: 'Oncolaxy',
        description: 'Oncolaxy description',
        i18ndescription: t('Oncolaxy description'),
        logo: 'Oncolaxy',
        mailto: 'support@oncolaxy.care',
        appurl: {
            ios: 'https://itunes.apple.com/fr/app/betterise-le-majordome-de-votre-sant%C3%A9/id916264011?mt=8',
            android:
                'https://play.google.com/store/apps/details?id=com.betterise.android',
        },
        modules: {
            noSWRoutes: ['/pdf/'],
            notice: '/__pdf/notices/oncoflow/notice-soignant.pdf',
            noticePatient: '/__pdf/notices/oncoflow/notice-patient.pdf',
            patient: {
                etapes: true,
            },
            codeSendBy: 'sms',
        },
    },
    unicancer: {
        title: 'Unicancer',
        description: 'Unicancer description',
        i18ndescription: t('Unicancer description'),
        logo: 'Unicancer',
        mailto: 'support@theraflow.care',
        appurl: {
            ios: 'https://itunes.apple.com/fr/app/betterise-le-majordome-de-votre-sant%C3%A9/id916264011?mt=8',
            android:
                'https://play.google.com/store/apps/details?id=com.betterise.android',
        },
        modules: {
            noSWRoutes: ['/pdf/'],
            notice: '/__pdf/notices/unicancer/notice-soignant.pdf',
            noticePatient: '/__pdf/notices/unicancer/notice-patient.pdf',
            patient: {
                etapes: true,
            },
            codeSendBy: 'email',
        },
    },
    uroconnect: {
        title: 'UroConnect',
        description: 'UroConnect description',
        i18ndescription: t('UroConnect description'),
        logo: 'UroConnect',
        mailto: 'support@uroconnect.care',
        appurl: {
            ios: 'https://itunes.apple.com/fr/app/betterise-le-majordome-de-votre-sant%C3%A9/id916264011?mt=8',
            android:
                'https://play.google.com/store/apps/details?id=com.betterise.android',
        },
        modules: {
            noSWRoutes: ['/pdf/'],
            notice: '/__pdf/notices/uroconnect/notice-soignant.pdf',
            noticePatient: '/__pdf/notices/uroconnect/notice-patient.pdf',
            patient: {
                etapes: true,
            },
            codeSendBy: 'email',
        },
    },
    cardiolaxy: {
        title: 'Cardiolaxy',
        description: 'Cardiolaxy description',
        i18ndescription: t('Cardiolaxy description'),
        logo: 'Cardiolaxy',
        mailto: 'support@cardiolaxy.care',
        appurl: {
            ios: 'https://itunes.apple.com/fr/app/betterise-le-majordome-de-votre-sant%C3%A9/id916264011?mt=8',
            android:
                'https://play.google.com/store/apps/details?id=com.betterise.android',
        },
        modules: {
            noSWRoutes: ['/pdf/'],
            notice: '/__pdf/notices/cardiolaxy/notice-soignant.pdf',
            noticePatient: '/__pdf/notices/cardiolaxy/notice-patient.pdf',
            patient: {
                etapes: true,
            },
            codeSendBy: 'sms',
        },
    },
    domicalis: {
        title: 'Domicalis',
        description: 'Domicalis description',
        i18ndescription: t('Domicalis description'),
        logo: 'Domicalis',
        mailto: 'support@theraflow.care',
        appurl: {
            ios: 'https://itunes.apple.com/fr/app/betterise-le-majordome-de-votre-sant%C3%A9/id916264011?mt=8',
            android:
                'https://play.google.com/store/apps/details?id=com.betterise.android',
        },
        modules: {
            noSWRoutes: ['/pdf/'],
            notice: '/__pdf/notices/default/notice-soignant.pdf',
            noticePatient: '/__pdf/notices/default/notice-patient.pdf',
            patient: {
                etapes: true,
            },
            codeSendBy: 'sms',
        },
    },
    resilience: {
        title: 'Resilience',
        description: 'Resilience description',
        i18ndescription: t('Resilience description'),
        logo: 'Resilience',
        mailto: 'soignant@resilience.care',
        appurl: {
            ios: 'https://itunes.apple.com/fr/app/betterise-le-majordome-de-votre-sant%C3%A9/id916264011?mt=8',
            android:
                'https://play.google.com/store/apps/details?id=com.betterise.android',
        },
        modules: {
            noSWRoutes: ['/pdf/'],
            notice: '/__pdf/notices/oncoflow/notice-soignant.pdf',
            noticePatient: '/__pdf/notices/oncoflow/notice-patient.pdf',
            patient: {
                etapes: false,
            },
            codeSendBy: 'sms',
        },
    },
}

export const periodFrequencies = [
    {
        label: 'Tous les lundis, à 10h',
        labelI18N: t('Tous les lundis, à 10h'),
        key: 'weeklyMonday',
        order: 0,
        frequency: 'weekly',
    },
    {
        label: 'Tous les mardis, à 10h',
        labelI18N: t('Tous les mardis, à 10h'),
        key: 'weeklyTuesday',
        order: 1,
        frequency: 'weekly',
    },
    {
        label: 'Tous les mercredis, à 10h',
        labelI18N: t('Tous les mercredis, à 10h'),
        key: 'weeklyWednesday',
        order: 2,
        frequency: 'weekly',
    },
    {
        label: 'Tous les jeudis, à 10h',
        labelI18N: t('Tous les jeudis, à 10h'),
        key: 'weeklyThursday',
        order: 3,
        frequency: 'weekly',
    },
    {
        label: 'Tous les vendredis, à 10h',
        labelI18N: t('Tous les vendredis, à 10h'),
        key: 'weeklyFriday',
        order: 4,
        frequency: 'weekly',
    },
    {
        label: 'Tous les samedis, à 10h',
        labelI18N: t('Tous les samedis, à 10h'),
        key: 'weeklySaturday',
        order: 5,
        frequency: 'weekly',
    },
    {
        label: 'Tous les dimanches, à 10h',
        labelI18N: t('Tous les dimanches, à 10h'),
        key: 'weeklySunday',
        order: 6,
        frequency: 'weekly',
    },
    {
        label: 'Tous les premiers du mois, à 10h',
        labelI18N: t('Tous les premiers du mois, à 10h'),
        key: 'monthlyBegin',
        order: 7,
        frequency: 'monthly',
    },
    {
        label: 'Tous les quinze du mois, à 10h',
        labelI18N: t('Tous les quinze du mois, à 10h'),
        key: 'monthlyMiddle',
        order: 8,
        frequency: 'monthly',
    },
    {
        label: 'Tous les 1er et 15 du mois, 10h',
        labelI18N: t('Tous les 1er et 15 du mois, 10h'),
        key: 'bimonthly',
        order: 10,
        frequency: 'bimonthly',
    },
    {
        label: 'Tous les 3 mois, le 1er jour du mois, 10h',
        labelI18N: t('Tous les 3 mois, le 1er jour du mois, 10h'),
        key: 'quarterly',
        order: 11,
        frequency: 'quarterly',
    },
]

export const periodFrequenciesPeriods = [
    {
        label: 'Hebdomadaire',
        labelI18N: t('Hebdomadaire'),
        key: 'weekly',
        order: 0,
    },
    { label: 'Mensuelle', labelI18N: t('Mensuelle'), key: 'monthly', order: 1 },
    {
        label: 'Bimensuelle',
        labelI18N: t('Bimensuelle'),
        key: 'bimonthly',
        order: 2,
    },
    {
        label: 'Trimestrielle',
        labelI18N: t('Trimestrielle'),
        key: 'quarterly',
        order: 3,
    },
]

export const quarterlyPeriod = [
    { key: [0, 3, 6, 9], value: 'quarterlyJajo' },
    { key: [1, 4, 7, 10], value: 'quarterlyFman' },
    { key: [2, 5, 8, 11], value: 'quarterlyMjsd' },
]

export const secretQuestions = [
    {
        label: 'Quel est le prénom de votre père ?',
        i18nlabel: t('Quel est le prénom de votre père ?'),
        key: 'Quel est le prénom de votre père ?',
    },
    {
        label: 'Quel est le nom de votre peintre préféré ?',
        i18nlabel: t('Quel est le nom de votre peintre préféré ?'),
        key: 'Quel est le nom de votre peintre préféré ?',
    },
    {
        label: 'Quel est le nom de votre musicien préféré ?',
        i18nlabel: t('Quel est le nom de votre musicien préféré ?'),
        key: 'Quel est le nom de votre musicien préféré ?',
    },
    {
        label: 'Quel est le nom de votre auteur préféré ?',
        i18nlabel: t('Quel est le nom de votre auteur préféré ?'),
        key: 'Quel est le nom de votre auteur préféré ?',
    },
    {
        label: 'Quel est le nom de votre personnage de roman préféré ?',
        i18nlabel: t('Quel est le nom de votre personnage de roman préféré ?'),
        key: 'Quel est le nom de votre personnage de roman préféré ?',
    },
]

export const languages = [
    {
        label: 'Français',
        shortlabel: 'FR',
        i18nlabel: t('Français'),
        i18nshortlabel: t('FR'),
        key: 'fr',
        img: icoFlagfrFRx2,
    },
    {
        label: 'Anglais',
        shortlabel: 'EN',
        i18nlabel: t('Anglais'),
        i18nshortlabel: t('EN'),
        key: 'en',
        img: icoFlagenGBx2,
    },
    {
        label: 'Néerlandais',
        shortlabel: 'NE',
        i18nlabel: t('Néerlandais'),
        i18nshortlabel: t('NE'),
        key: 'nl',
        img: icoFlagenNLx2,
    },
    {
        label: 'Allemand',
        shortlabel: 'DE',
        i18nlabel: t('Allemand'),
        i18nshortlabel: t('DE'),
        key: 'de',
        img: icoFlagdeDEx2,
    },
]

export const phonePrefixes = [
    { label: '+33', key: '+33', flag: 'France', img: icoFlagfrFRx2 },
    { label: '+32', key: '+32', flag: 'Belgique', img: icoFlagenNLx2 },
    { label: '+49', key: '+49', flag: 'Allemand', img: icoFlagdeDEx2 },

    {
        label: '+31',
        key: '+31',
        flag: 'Pays-Bas',
        i18nlabel: t('Pays-Bas'),
        img: icoFlagNLx2,
    },
    {
        label: '+34',
        key: '+34',
        flag: 'Espagne',
        i18nlabel: t('Espagne'),
        img: icoFlagESx2,
    },
    {
        label: '+351',
        key: '+351',
        flag: 'Portugal',
        i18nlabel: t('Portugal'),
        img: icoFlagPTx2,
    },
    {
        label: '+352',
        key: '+352',
        flag: 'Luxembourg',
        i18nlabel: t('Luxembourg'),
        img: icoFlagLUx2,
    },
    {
        label: '+353',
        key: '+353',
        flag: 'Irlande',
        i18nlabel: t('Irlande'),
        img: icoFlagIEx2,
    },
    {
        label: '+39',
        key: '+39',
        flag: 'Italie',
        i18nlabel: t('Italie'),
        img: icoFlagITx2,
    },
    {
        label: '+41',
        key: '+41',
        flag: 'Suisse',
        i18nlabel: t('Suisse'),
        img: icoFlagCHx2,
    },
    {
        label: '+44',
        key: '+44',
        flag: 'Royaume-Uni',
        i18nlabel: t('Royaume-Uni'),
        img: icoFlagGBx2,
    },

    {
        label: '+590',
        key: '+590',
        flag: 'Guadeloupe',
        i18nlabel: t('Guadeloupe'),
        img: icoFlagfrFRx2,
    },
    {
        label: '+594',
        key: '+594',
        flag: 'Guyane',
        i18nlabel: t('Guyane'),
        img: icoFlagfrFRx2,
    },
    {
        label: '+262',
        key: '+262',
        flag: 'La reunion',
        i18nlabel: t('La reunion'),
        img: icoFlagfrFRx2,
    },
    {
        label: '+596',
        key: '+596',
        flag: 'Martinique',
        i18nlabel: t('Martinique'),
        img: icoFlagfrFRx2,
    },
    {
        label: '+269',
        key: '+269',
        flag: 'Mayotte',
        i18nlabel: t('Mayotte'),
        img: icoFlagfrFRx2,
    },
    {
        label: '+687',
        key: '+687',
        flag: 'Nouvelle-calédonie',
        i18nlabel: t('Nouvelle-calédonie'),
        img: icoFlagfrFRx2,
    },
    {
        label: '+689',
        key: '+689',
        flag: 'Polynésie française',
        i18nlabel: t('Polynésie française'),
        img: icoFlagfrFRx2,
    },
    {
        label: '+508',
        key: '+508',
        flag: 'Saint-pierre-et-miquelon',
        i18nlabel: t('Saint-pierre-et-miquelon'),
        img: icoFlagfrFRx2,
    },
    {
        label: '+681',
        key: '+681',
        flag: 'Wallis-et-futuna',
        i18nlabel: t('Wallis-et-futuna'),
        img: icoFlagfrFRx2,
    },

    {
        label: '+212',
        key: '+212',
        flag: 'Maroc',
        i18nlabel: t('Maroc'),
        img: icoFlagMAx2,
    },
    {
        label: '+213',
        key: '+213',
        flag: 'Algérie',
        i18nlabel: t('Algérie'),
        img: icoFlagDZx2,
    },
    {
        label: '+216',
        key: '+216',
        flag: 'Tunisie',
        i18nlabel: t('Tunisie'),
        img: icoFlagTNx2,
    },
    {
        label: '+218',
        key: '+218',
        flag: 'Libye',
        i18nlabel: t('Libye'),
        img: icoFlagLYx2,
    },
    {
        label: '+222',
        key: '+222',
        flag: 'Mauritanie',
        i18nlabel: t('Mauritanie'),
        img: icoFlagSAx2,
    },
]

export const bydayslist = {
    1: { label: t('par jour'), key: 1 },
    2: { label: t('par semaine'), key: 2 },
    3: { label: t('par mois'), key: 3 },
}

export const dosage = {
    1: { label: '0.25', key: 0.25 },
    2: { label: '0.5', key: 0.5 },
    3: { label: '0.75', key: 0.75 },
    4: { label: '1', key: 1 },
    5: { label: '2', key: 2 },
    6: { label: '3', key: 3 },
}

export const buttonKinds = {
    1: { label: t('Boutons simples'), key: 1 },
    2: { label: t('QCM'), key: 2 },
}

export const pathologiesTypes = [
    { label: 'Cholestérol', i18nlabel: t('Cholestérol'), key: 'CHOLESTEROL' },
    {
        label: 'Déficit hypophysaire',
        i18nlabel: t('Déficit hypophysaire'),
        key: 'PITUITARYDEFICIT',
    },
    { label: 'Diabète T1', i18nlabel: t('Diabète T1'), key: 'DIABT1' },
    { label: 'Diabète T2', i18nlabel: t('Diabète T2'), key: 'DIABT2' },
    { label: 'Dialyse', i18nlabel: t('Dialyse'), key: 'DYALISIS' },
    { label: 'FIV', i18nlabel: t('FIV'), key: 'FIV' },
    {
        label: 'Greffe rénale',
        i18nlabel: t('Greffe rénale'),
        key: 'KIDNEYTRANSPLANT',
    },
    {
        label: 'Hypersécretion de cortisol (maladie de Cushing)',
        i18nlabel: t('Hypersécretion de cortisol (maladie de Cushing)'),
        key: 'CUSHING',
    },
    {
        label: 'Hypersecretion d’hormone de croissance (acromégalie)',
        i18nlabel: t('Hypersecretion d’hormone de croissance (acromégalie)'),
        key: 'ACROMEGALY',
    },
    {
        label: 'Hypersécrétion prolactine',
        i18nlabel: t('Hypersécrétion prolactine'),
        key: 'PROLCATINHYPERSECRETION',
    },
    {
        label: 'Hypertension',
        i18nlabel: t('Hypertension'),
        key: 'HYPERTENSION',
    },
    {
        label: 'Insuffisance cardiaque',
        i18nlabel: t('Insuffisance cardiaque'),
        key: 'HEARTFAILURE',
    },
    {
        label: 'Insuffisance Rénale Stade 4',
        i18nlabel: t('Insuffisance Rénale Stade 4'),
        key: 'KIDNEYFAILURE4',
    },
    {
        label: 'Insuffisance Rénale Stade 5',
        i18nlabel: t('Insuffisance Rénale Stade 5'),
        key: 'KIDNEYFAILURE5',
    },
    { label: 'Lithiase', i18nlabel: t('Lithiase'), key: 'LITHIASIS' },
    {
        label: 'Maladie Rénale Chronique',
        i18nlabel: t('Maladie Rénale Chronique'),
        key: 'CHRONICKIDNEYDISEASE',
    },
    {
        label: 'Néphrectomie Partielle',
        i18nlabel: t('Néphrectomie Partielle'),
        key: 'NEPHRECTOMYPARTIAL',
    },
    {
        label: 'Néphrectomie Totale',
        i18nlabel: t('Néphrectomie Totale'),
        key: 'NEPHRECTOMYTOTAL',
    },
    {
        label: 'Néphrectomie Totale Thrombus cave',
        i18nlabel: t('Néphrectomie Totale Thrombus cave'),
        key: 'NEPHRECTOMYTOTALTHROMBUS',
    },
    {
        label: 'Nephro-Urétérectomie Totale',
        i18nlabel: t('Nephro-Urétérectomie Totale'),
        key: 'NEPHROURETERECTOMYTOTAL',
    },
    { label: 'Onco-Hémato', i18nlabel: t('Onco-Hémato'), key: 'ONCOHEMATO' },
    {
        label: 'Post infarctus',
        i18nlabel: t('Post infarctus'),
        key: 'POSTINFARCT',
    },
    { label: 'Pré Diabète', i18nlabel: t('Pré Diabète'), key: 'PREDIABETES' },
    {
        label: 'Prévention Primaire',
        i18nlabel: t('Prévention Primaire'),
        key: 'PREVENTION',
    },
    { label: 'Prostate', i18nlabel: t('Prostate'), key: 'PROSTATE' },
    { label: 'Surpoids', i18nlabel: t('Surpoids'), key: 'OVERWEIGHT' },
    {
        label: 'Tumeur hypophysaire',
        i18nlabel: t('Tumeur hypophysaire'),
        key: 'PITUITARYTUMOR',
    },
    {
        label: 'Vessie - cystectomie avec dérivation cutanée',
        i18nlabel: t('Vessie - cystectomie avec dérivation cutanée'),
        key: 'CYSTECTOMYSKINBYPASS',
    },
    {
        label: 'Vessie - cystectomie avec entérocystoplastie',
        i18nlabel: t('Vessie - cystectomie avec entérocystoplastie'),
        key: 'CYSTECTOMYENTEROCYSTOPLASTY',
    },
    { label: 'Chirurgie', i18nlabel: t('Chirurgie'), key: 'SURGERY' },
]

export const takeKinds = [
    {
        label: 'ampoule(s)',
        i18nlabel: t('ampoule(s)'),
        key: 'bulbs',
        sing: t('ampoule'),
        plur: t('ampoules'),
    },
    {
        label: 'application(s)',
        i18nlabel: t('application(s)'),
        key: 'applications',
        sing: t('application'),
        plur: t('applications'),
    },
    {
        label: 'bouffée(s)',
        i18nlabel: t('bouffée(s)'),
        key: 'puffs',
        sing: t('bouffée'),
        plur: t('bouffées'),
    },
    {
        label: 'comprimé(s)',
        i18nlabel: t('comprimé(s)'),
        key: 'tablets',
        sing: t('comprimé'),
        plur: t('comprimés'),
    },
    {
        label: 'cuillère(s) à café',
        i18nlabel: t('cuillère(s) à café'),
        key: 'coffeespoons',
        sing: t('cuillère à café'),
        plur: t('cuillères à café'),
    },
    {
        label: 'cuillère(s) à soupe',
        i18nlabel: t('cuillère(s) à soupe'),
        key: 'soupspoons',
        sing: t('cuillère à soupe'),
        plur: t('cuillères à soupe'),
    },
    {
        label: 'dose(s)',
        i18nlabel: t('dose(s)'),
        key: 'doses',
        sing: t('dose'),
        plur: t('doses'),
    },
    { label: 'g', i18nlabel: t('g'), key: 'g', sing: t('g'), plur: t('g') },
    {
        label: 'gélule(s)',
        i18nlabel: t('gélule(s)'),
        key: 'capsules',
        sing: t('gélule'),
        plur: t('gélules'),
    },
    {
        label: 'goutte(s)',
        i18nlabel: t('goutte(s)'),
        key: 'drops',
        sing: t('goutte'),
        plur: t('gouttes'),
    },
    {
        label: 'granulé(s)',
        i18nlabel: t('granulé(s)'),
        key: 'granules',
        sing: t('granulé'),
        plur: t('granulés'),
    },
    {
        label: 'injection(s)',
        i18nlabel: t('injection(s)'),
        key: 'injections',
        sing: t('injection'),
        plur: t('injections'),
    },
    {
        label: 'lavement(s)',
        i18nlabel: t('lavement(s)'),
        key: 'enemas',
        sing: t('lavement'),
        plur: t('lavements'),
    },
    {
        label: 'ovule(s)',
        i18nlabel: t('ovule(s)'),
        key: 'ovules',
        sing: t('ovule'),
        plur: t('ovules'),
    },
    {
        label: 'ml',
        i18nlabel: t('ml'),
        key: 'ml',
        sing: t('ml'),
        plur: t('ml'),
    },
    {
        label: 'patch(s)',
        i18nlabel: t('patch(s)'),
        key: 'patches',
        sing: t('patch'),
        plur: t('patchs'),
    },
    {
        label: 'pilule(s)',
        i18nlabel: t('pilule(s)'),
        key: 'pill',
        sing: t('pilule'),
        plur: t('pilules'),
    },
    {
        label: 'pulvérisation(s)',
        i18nlabel: t('pulvérisation(s)'),
        key: 'spray',
        sing: t('pulvérisation'),
        plur: t('pulvérisations'),
    },
    {
        label: 'sachet(s)',
        i18nlabel: t('sachet(s)'),
        key: 'bags',
        sing: t('sachet'),
        plur: t('sachets'),
    },
    {
        label: 'suppositoire(s)',
        i18nlabel: t('suppositoire(s)'),
        key: 'suppository',
        sing: t('suppositoire'),
        plur: t('suppositoires'),
    },
    {
        label: 'unité(s)',
        i18nlabel: t('unité(s)'),
        key: 'units',
        sing: t('unité'),
        plur: t('unités'),
    },
]

export const takeFrequencies = [
    { label: 'Tous les jours', i18nlabel: t('Tous les jours'), key: '0' },
    { label: 'Tous les ...', i18nlabel: t('Tous les ...'), key: '1' },
    { label: 'Jours précis', i18nlabel: t('Jours précis'), key: '2' },
    { label: 'Cycle', i18nlabel: t('Cycle'), key: '3' },
]

export const takeFrequenciesMesure = [
    {
        label: 'tous les jours',
        i18nlabel: t('tous les jours'),
        key: '0',
        readable: 'tous les jours',
        i18nreadable: t('tous les jours'),
    },
    {
        label: 'tous les ...',
        i18nlabel: t('tous les ...'),
        key: '1',
        readable: 'tous les',
        i18nreadable: t('tous les'),
    },
    // {'label': 'avant le ...', 'i18nlabel': t('avant le ...'), 'key': '2', 'readable': 'avant le', 'i18nreadable': t('avant le')},
    {
        label: 'jour précis',
        i18nlabel: t('jour précis'),
        key: '3',
        readable: 'le',
        i18nreadable: t('le'),
    },
]

export const takePeriods = [
    {
        marker: 0,
        label: 'matin (6h/10h)',
        i18nlabel: t('matin (6h/10h)'),
        i18nValue: t('matin'),
        key: '6-10',
        prefix: 'le ',
        i18nprefix: t('le '),
        order: 0,
    },
    {
        marker: 1,
        label: 'midi (11h/14h)',
        i18nlabel: t('midi (11h/14h)'),
        i18nValue: t('midi'),
        key: '11-14',
        prefix: 'le ',
        i18nprefix: t('le '),
        order: 1,
    },
    {
        marker: 2,
        label: 'après-midi (15h/17h)',
        i18nlabel: t('après-midi (15h/17h)'),
        i18nValue: t('après-midi'),
        key: '15-17',
        prefix: 'l‘',
        i18nprefix: t('l‘'),
        order: 2,
    },
    {
        marker: 3,
        label: 'soir (18h/21h)',
        i18nlabel: t('soir (18h/21h)'),
        i18nValue: t('soir'),
        key: '18-21',
        prefix: 'le ',
        i18nprefix: t('le '),
        order: 3,
    },
    {
        marker: 4,
        label: 'coucher (21h/00h)',
        i18nlabel: t('coucher (21h/00h)'),
        i18nValue: t('coucher'),
        key: '21-0',
        prefix: 'au ',
        i18nprefix: t('au '),
        order: 4,
    },
]

export const daysFrequencies = [
    { label: 'Lundi', i18nlabel: t('Lundi'), key: 1, order: 0 },
    { label: 'Mardi', i18nlabel: t('Mardi'), key: 2, order: 1 },
    { label: 'Mercredi', i18nlabel: t('Mercredi'), key: 3, order: 2 },
    { label: 'Jeudi', i18nlabel: t('Jeudi'), key: 4, order: 3 },
    { label: 'Vendredi', i18nlabel: t('Vendredi'), key: 5, order: 4 },
    { label: 'Samedi', i18nlabel: t('Samedi'), key: 6, order: 5 },
    { label: 'Dimanche', i18nlabel: t('Dimanche'), key: 7, order: 6 },
]

export const periodsFrequencies = [
    { label: 'jours', i18nlabel: t('jours'), key: 'D' },
    { label: 'semaines', i18nlabel: t('semaines'), key: 'W' },
    { label: 'mois', i18nlabel: t('mois'), key: 'M' },
]

export const sendingWays = [
    { label: 'Par SMS', i18nlabel: t('Par SMS'), key: 'SMS' },
    { label: 'Par Email', i18nlabel: t('Par Email'), key: 'EMAIL' },
]

export const userStatus = [
    { name: t('Patient créé'), id: 1 },
    { name: t('Trackers envoyés'), id: 2 },
    { name: t('Programme démarré'), id: 3 },
    { name: t('QR Code scanné'), id: 4 },
]

export const weekday = {
    1: { label: t('Tous les jours'), value: 0 },
    2: { label: t('Lundi'), value: 'MON' },
    3: { label: t('Mardi'), value: 'TUE' },
    4: { label: t('Mercredi'), value: 'WED' },
    5: { label: t('Jeudi'), value: 'THU' },
    6: { label: t('Vendredi'), value: 'FRI' },
    7: { label: t('Samedi'), value: 'SAT' },
    8: { label: t('Dimanche'), value: 'SUN' },
}

export const alertsKind = {
    0: {
        label: 'bpm',
        readable: t('BPM'),
        title: t('RYTHME CARDIAQUE'),
        key: 'HEARTBEAT_VALUE',
        unit: t('bpm'),
    },
    1: {
        label: 'tension',
        readable: t('TENSION'),
        title: t('TENSION SYSTOLIQUE'),
        key: 'BLOODPRESSURE_SYSTOLIC',
        unit: t('mmHg'),
    },
    2: {
        label: 'weight',
        readable: t('POIDS'),
        title: t('POIDS'),
        key: 'CRYPTEDWEIGHT_VALUE',
        unit: t('kg'),
    },
    3: {
        label: 'activity',
        readable: t('ACTIVITÉ'),
        title: t('NOMBRE DE PAS'),
        key: 'CRYPTEDACTIVITY_STEPS',
        unit: t('pas'),
    },
}

export const alerts = [
    {
        label: t('Minimale'),
        value: 'MIN',
        short: t('Inférieur ou égale à'),
        description: t('déclenchée lorsque la valeur est atteinte'),
        kinds: ['bpm', 'tension', 'weight', 'activity'],
    },
    {
        label: t('Maximale'),
        value: 'MAX',
        short: t('Supérieur ou égale à'),
        description: t('déclenchée lorsque la valeur est dépassée'),
        kinds: ['bpm', 'tension', 'weight', 'activity'],
    },
    {
        label: t('Min / Max'),
        value: 'RANGE',
        short: t('Non comprise entre'),
        description: t(
            'déclenchée lorsque la valeur se trouve entre les valeurs min/max'
        ),
        kinds: ['bpm', 'tension', 'weight', 'activity'],
    },
    {
        label: t('Augmentation'),
        value: 'INCREASE',
        short: t('En augmentation de'),
        description: t('déclenchée lorsque la valeur augmente'),
        kinds: ['weight'],
    },
    {
        label: t('Diminution'),
        value: 'DECREASE',
        short: t('En diminution de'),
        description: t('déclenchée lorsque la valeur diminue'),
        kinds: ['weight'],
    },
]

export const alertsType = {
    MIN: { template: t('minimale'), value: 1 },
    MAX: { template: t('maximale'), value: 2 },
    RANGE: { template: t('minmax'), value: 3 },
    INCREASE: { template: t('augmentation'), value: 4 },
    DECREASE: { template: t('diminution'), value: 5 },
}

export const etpSettings = {
    ACTIVITY: { key: 2, value: 'Activité', i18nValue: t('Activité') },
    ALCOOL: { key: 8, value: 'Alcool', i18nValue: t('Alcool') },
    CANCER: { key: 10, value: 'Anti-cancer', i18nValue: t('Anti-cancer') },
    HAPPINESS: { key: 14, value: 'Bonheur', i18nValue: t('Bonheur') },
    HEART: { key: 13, value: 'Coeur en forme', i18nValue: t('Coeur en forme') },
    DIABETES: { key: 7, value: 'Diabètes', i18nValue: t('Diabètes') },
    FAQ: { key: 28, value: 'FAQ', i18nValue: t('FAQ') },
    TIREDNESS: { key: 18, value: 'Fatigue', i18nValue: t('Fatigue') },
    PREGNANCY: { key: 17, value: 'Grossesse', i18nValue: t('Grossesse') },
    MEMORY: { key: 9, value: 'Mémoire', i18nValue: t('Mémoire') },
    MORAL: { key: 5, value: 'Moral', i18nValue: t('Moral') },
    NUTRITION: { key: 1, value: 'Nutrition', i18nValue: t('Nutrition') },
    WEIGHT: { key: 16, value: 'Poids', i18nValue: t('Poids') },
    SLEEP: { key: 3, value: 'Sommeil', i18nValue: t('Sommeil') },
    STRESS: { key: 4, value: 'Stress', i18nValue: t('Stress') },
    TOBACCO: { key: 6, value: 'Tabac', i18nValue: t('Tabac') },
    TREATMENT: { key: 29, value: 'Traitement', i18nValue: t('Traitement') },
    MYPATHOLOGY: {
        key: 31,
        value: 'Ma pathologie',
        i18nValue: t('Ma pathologie'),
    },
    PITUITARY: { key: 32, value: 'Hypophyse', i18nValue: t('Hypophyse') },
}

export const telesuiviSettings = [
    {
        key: 18,
        value: 'Fatigue',
        i18nValue: t('Fatigue'),
        sentence: 'sa fatigue',
        i18nsentence: t('sa fatigue'),
        crypt: 'CryptedTiredness',
        apicrypt: 'CryptedTiredness',
        chart: 'bar',
        chartValueKey: 'value',
        alertValueKey: [{ crypt: 'value', key: 'value', chart: 'bar' }],
        chartValueOffKey: [],
        unit: '0 : Pas de fatigue - 6 : Beaucoup de fatigue',
        i18nunit: t('0 : Pas de fatigue - 6 : Beaucoup de fatigue'),
        configure: true,
        mesureunit: { value: '/6' },
    },
    {
        key: 18,
        value: 'Glycémie',
        i18nValue: t('Glycémie'),
        sentence: 'sa glycémie',
        i18nsentence: t('sa glycémie'),
        crypt: 'Glycemy',
        apicrypt: 'Glycemy',
        chart: 'bar',
        chartValueKey: 'value',
        alertValueKey: [{ crypt: 'level', key: 'value', chart: 'bar' }],
        chartValueOffKey: [],
        unit: 'En g/L',
        i18nunit: t('En g/L'),
        configure: true,
        mesureunit: 'g/L',
        i18nmesureunit: { value: t('g/L') },
    },
    {
        key: 2,
        value: 'Marche',
        i18nValue: t('Marche'),
        sentence: 'son activité',
        i18nsentence: t('son activité'),
        crypt: 'CryptedActivity',
        apicrypt: 'CryptedActivity',
        chart: 'bar',
        chartValueKey: 'steps',
        alertValueKey: [{ crypt: 'steps', key: 'steps', chart: 'bar' }],
        chartValueOffKey: [],
        unit: 'En nombre de pas',
        i18nunit: t('En nombre de pas'),
        configure: true,
        mesureunit: { steps: 'pas' },
        i18nmesureunit: { steps: t('pas') },
    },
    {
        key: 5,
        value: 'Moral',
        i18nValue: t('Moral'),
        sentence: 'son moral',
        i18nsentence: t('son moral'),
        crypt: 'CryptedMoral',
        apicrypt: 'CryptedMoral',
        chart: 'bar',
        chartValueKey: 'value',
        alertValueKey: [{ crypt: 'value', key: 'value', chart: 'bar' }],
        chartValueOffKey: [],
        unit: '0 : Mauvais - 6 : Très bon',
        i18nunit: t('0 : Mauvais - 6 : Très bon'),
        configure: true,
        mesureunit: { value: '/6' },
    },
    {
        key: 5,
        value: 'Observance',
        i18nValue: t('Observance'),
        sentence: 'son observance',
        i18nsentence: t('son observance'),
        crypt: 'Observance',
        apicrypt: 'Observance',
        chart: 'bar',
        chartValueKey: 'value',
        alertValueKey: [{ crypt: 'value', key: 'value', chart: 'bar' }],
        chartValueOffKey: [],
        unit: 'En %',
        i18nunit: t('En %'),
        configure: false,
        mesureunit: { value: '%' },
    },
    {
        key: 16,
        value: 'Poids',
        i18nValue: t('Poids'),
        sentence: 'son poids',
        i18nsentence: t('son poids'),
        crypt: 'CryptedWeight',
        apicrypt: 'CryptedWeight',
        chart: 'bar',
        chartValueKey: 'value',
        alertValueKey: [{ crypt: 'value', key: 'value', chart: 'bar' }],
        chartValueOffKey: [],
        unit: 'En kg',
        i18nunit: t('En kg'),
        configure: true,
        mesureunit: { value: 'kg' },
        i18nmesureunit: { value: t('kg') },
    },
    {
        key: 0,
        value: 'Rythme Cardiaque',
        i18nValue: t('Rythme Cardiaque'),
        sentence: 'son rythme cardiaque',
        i18nsentencei18nsentence: t('son rythme cardiaque'),
        crypt: 'Heartbeat',
        apicrypt: 'Heartbeat',
        chart: 'bar',
        chartValueKey: 'value',
        alertValueKey: [{ crypt: 'value', key: 'value', chart: 'bar' }],
        chartValueOffKey: [],
        unit: 'En bpm',
        i18nunit: t('En bpm'),
        configure: true,
        mesureunit: { value: 'bpm' },
        i18nmesureunit: { value: t('bpm') },
    },
    {
        key: 3,
        value: 'Sommeil',
        i18nValue: t('Sommeil'),
        sentence: 'son sommeil',
        i18nsentence: t('son sommeil'),
        crypt: 'CryptedSleep',
        apicrypt: 'CryptedSleep',
        chart: 'bar',
        chartValueKey: 'value',
        alertValueKey: [{ crypt: 'value', key: 'value', chart: 'bar' }],
        chartValueOffKey: [],
        unit: '0 : Mauvaise qualité - 6 : Excellente qualité',
        i18nunit: t('0 : Mauvaise qualité - 6 : Excellente qualité'),
        configure: true,
        mesureunit: { value: '/6' },
    },
    {
        key: 4,
        value: 'Stress',
        i18nValue: t('Stress'),
        sentence: 'son stress',
        i18nsentence: t('son stress'),
        crypt: 'CryptedStress',
        apicrypt: 'CryptedStress',
        chart: 'bar',
        chartValueKey: 'value',
        alertValueKey: [{ crypt: 'value', key: 'value', chart: 'bar' }],
        chartValueOffKey: [],
        unit: '0 : Pas de stress - 6 : Beaucoup de stress',
        i18nunit: t('0 : Pas de stress - 6 : Beaucoup de stress'),
        configure: true,
        mesureunit: { value: '/6' },
    },
    {
        key: 6,
        value: 'Tabac',
        i18nValue: t('Tabac'),
        sentence: 'sa consommation de tabac',
        i18nsentence: t('sa consommation de tabac'),
        crypt: 'CryptedSmoke',
        apicrypt: 'CryptedSmoke',
        chart: 'bar',
        chartValueKey: 'value',
        alertValueKey: [{ crypt: 'value', key: 'value', chart: 'bar' }],
        chartValueOffKey: [],
        unit: 'En nombre de cigarettes',
        i18nunit: t('En nombre de cigarettes'),
        configure: true,
        mesureunit: { value: 'cig.' },
        i18nmesureunit: { value: t('cig.') },
    },
    {
        key: 0,
        value: 'Tension',
        i18nValue: t('Tension'),
        sentence: 'sa tension',
        i18nsentence: t('sa tension'),
        crypt: 'BloodPressure',
        apicrypt: 'BloodPressure',
        chart: 'bar',
        chartValueKey: 'systolic',
        alertValueKey: [{ crypt: 'systolic', key: 'systolic', chart: 'bar' }],
        chartValueOffKey: ['diastolic'],
        unit: 'En mmHg',
        i18nunit: t('En mmHg'),
        configure: true,
        mesureunit: { systolic: 'mmHg', diastolic: 'mmHg' },
        i18nmesureunit: { systolic: t('mmHg'), diastolic: t('mmHg') },
    },
    {
        key: 9,
        value: 'Urine',
        i18nValue: t('Urine'),
        sentence: 'son urine',
        i18nsentence: t('son urine'),
        crypt: 'CryptedUrine',
        apicrypt: 'CryptedUrine',
        chart: 'circle',
        chartValueKey: 'ph',
        alertValueKey: [
            { crypt: 'ph', key: 'ph', chart: 'circle' },
            { crypt: 'volume', key: 'volume', chart: 'circle', unit: 'cl' },
            { crypt: 'color', key: 'color', chart: 'bar' },
            { crypt: 'density', key: 'density', chart: null },
        ],
        chartValueOffKey: [],
        unit: 'couleur, pH, volume urinaire (cl) et densité',
        i18nunit: t('couleur, pH, volume urinaire (cl) et densité'),
        configure: true,
        mesureunit: { ph: '', color: '', volume: 'cl' },
        i18nmesureunit: { ph: '', color: '', volume: t('cl') },
    },
    {
        key: 30,
        value: 'Tour de taille',
        i18nValue: t('Tour de taille'),
        sentence: 'son tour de taille',
        i18nsentence: t('son tour de taille'),
        crypt: 'CryptedWaistSize',
        apicrypt: 'CryptedWaistSize',
        chart: 'bar',
        chartValueKey: 'value',
        alertValueKey: [{ crypt: 'value', key: 'value', chart: 'bar' }],
        chartValueOffKey: [],
        unit: 'En cm',
        i18nunit: t('En cm'),
        configure: true,
        mesureunit: { value: 'cm' },
        i18nmesureunit: { value: t('cm') },
    },
    {
        key: 31,
        value: 'Hydratation',
        i18nValue: t('Hydratation'),
        sentence: 'son hydratation',
        i18nsentence: t('son hydratation'),
        crypt: 'CryptedHydration',
        apicrypt: 'CryptedHydration',
        chart: 'bar',
        chartValueKey: 'value',
        alertValueKey: [
            { crypt: 'value', key: 'value', chart: 'bar' },
            { crypt: 'mean', key: 'mean', chart: 'circle', mean: 250 },
        ],
        chartValueOffKey: [],
        unit: 'En L',
        i18nunit: t('En litre'),
        configure: true,
        mesureunit: { value: 'litre', mean: '' },
        i18nmesureunit: { value: t('litre'), mean: '' },
    },
]

export const telesuiviVariations = [
    { key: '0', value: 'augmente de', i18nValue: t('augmente de') },
    { key: '1', value: 'diminue de', i18nValue: t('diminue de') },
    { key: '2', value: 'est supérieur à', i18nValue: t('est supérieur à') },
    { key: '3', value: 'est inférieur à', i18nValue: t('est inférieur à') },
]

export const telesuiviUnits = [
    { key: '0', value: 'kg', i18nValue: t('kg') },
    { key: '1', value: 'stone', i18nValue: t('stone') },
]

export const telesuiviScenarios = [
    {
        key: 0,
        value: '1 mesure est manquante',
        i18nValue: t('1 mesure est manquante'),
        reserved: [],
        mandatoryFrequency: true,
        scenario: {
            dataType: 'CRYPTEDWEIGHT_VALUE',
            triggerType: 'NODATA',
            value1: 0,
            alertUser: true,
            alertMedicalTeam: true,
            alertCoach: true,
        },
    },
    {
        key: 1,
        value: '+2 kg en 2 jours',
        i18nValue: t('+2 kg en 2 jours'),
        reserved: ['CryptedWeight'],
        mandatoryFrequency: false,
        scenario: {
            dataType: 'CRYPTEDWEIGHT_VALUE',
            triggerType: 'INCREASE',
            value1: 2,
            interval: 2,
            alertUser: true,
            alertMedicalTeam: true,
            alertCoach: true,
        },
    },
    {
        key: 2,
        value: '+2 kg en 6 jours',
        i18nValue: t('+2 kg en 6 jours'),
        reserved: ['CryptedWeight'],
        mandatoryFrequency: false,
        scenario: {
            dataType: 'CRYPTEDWEIGHT_VALUE',
            triggerType: 'INCREASE',
            value1: 2,
            interval: 6,
            alertUser: true,
            alertMedicalTeam: true,
            alertCoach: true,
        },
    },
]

export const telesuiviActionsAlert = [
    {
        key: '0',
        label: 'Choisissez une action',
        i18nValue: t('Choisissez une action'),
    },
    { key: '1', label: 'R.A.S', i18nValue: t('R.A.S') },
    { key: '2', label: 'Patient appelé', i18nValue: t('Patient appelé') },
    {
        key: '3',
        label: 'Message laissé au patient',
        i18nValue: t('Message laissé au patient'),
    },
    { key: '4', label: 'Médecin appelé', i18nValue: t('Médecin appelé') },
    { key: '5', label: 'RDV Consult', i18nValue: t('RDV Consult') },
    {
        key: '6',
        label: 'RDV Hospitalisation',
        i18nValue: t('RDV Hospitalisation'),
    },
    { key: '7', label: 'RDV Éducatif', i18nValue: t('RDV Éducatif') },
]
export const studyActionsAlert = [
    {
        key: 'CALLED',
        label: 'Appel au patient et rien à signaler',
        i18nValue: t('Appel au patient et rien à signaler'),
        service: ['survey', 'study'],
    },
    {
        key: 'CALLED_ADVICE',
        label: 'Appel au patient avec conseil',
        i18nValue: t('Appel au patient avec conseil'),
        service: ['survey', 'study'],
    },
    {
        key: 'APPOINTMENT',
        label: 'Nouvelle consultation prévue avec le médecin référent',
        i18nValue: t('Nouvelle consultation prévue avec le médecin référent'),
        service: ['survey', 'study'],
    },
    {
        key: 'APPOINTEMENT_EXTERNAL',
        label: 'Redirection vers le médecin traitant',
        i18nValue: t('Redirection vers le médecin traitant'),
        service: ['survey', 'study'],
    },
    {
        key: 'EMERGENCIES',
        label: 'Envoi aux urgences',
        i18nValue: t('Envoi aux urgences'),
        service: ['survey', 'study'],
    },
    {
        key: 'CLEAR',
        label: 'Alertes redondantes, aucune action supplémentaire',
        i18nValue: t('Alertes redondantes, aucune action supplémentaire'),
        service: ['survey', 'study'],
    },
    {
        key: 'TREATEMENT_DOSAGE_UPDATE',
        label: 'Adaptation des doses de traitement',
        i18nValue: t('Adaptation des doses de traitement'),
        service: ['survey', 'study'],
    },
    {
        key: 'APPOINTEMENT_SPECIALIST',
        label: 'Redirection vers un autre spécialiste',
        i18nValue: t('Redirection vers un autre spécialiste'),
        service: ['survey'],
    },
    {
        key: 'TREATEMENT_NEW',
        label: 'Changement de traitement',
        i18nValue: t('Changement de traitement'),
        service: ['survey'],
    },
    {
        key: 'TREATEMENT_UPDATE',
        label: 'Prescription de traitement concomitant',
        i18nValue: t('Prescription de traitement concomitant'),
        service: ['survey', 'study'],
    },
    {
        key: 'FINISHED',
        label: 'Traitée',
        i18nValue: t('Traitée'),
        service: ['survey', 'study'],
    },
    {
        key: 'OTHER',
        label: 'Autre',
        i18nValue: t('Autre'),
        service: ['survey'],
    },
]
export const studyActionsAlertRDS = [
    {
        key: 'FINISHED',
        label: 'Traitée',
        i18nValue: t('Traitée'),
        service: ['survey', 'study'],
    },
    {
        key: 'CALLED',
        label: 'Appel au patient et rien à signaler',
        i18nValue: t('Appel au patient et rien à signaler'),
        service: ['survey', 'study'],
    },
    {
        key: 'CALLED_ADVICE',
        label: 'Appel au patient avec conseil',
        i18nValue: t('Appel au patient avec conseil'),
        service: ['survey', 'study'],
    },
    {
        key: 'TREATEMENT_UPDATE',
        label: 'Prescription de traitement concomitant',
        i18nValue: t('Prescription de traitement concomitant'),
        service: ['survey', 'study'],
    },
    {
        key: 'TREATEMENT_DOSAGE_UPDATE',
        label: 'Adaptation des doses de traitement',
        i18nValue: t('Adaptation des doses de traitement'),
        service: ['survey', 'study'],
    },
    {
        key: 'EMERGENCIES',
        label: 'Envoi aux urgences',
        i18nValue: t('Envoi aux urgences'),
        service: ['survey', 'study'],
    },
    {
        key: 'APPOINTMENT',
        label: 'Nouvelle consultation prévue avec le médecin référent',
        i18nValue: t('Nouvelle consultation prévue avec le médecin référent'),
        service: ['survey', 'study'],
    },
    {
        key: 'APPOINTEMENT_EXTERNAL',
        label: 'Redirection vers le médecin traitant',
        i18nValue: t('Redirection vers le médecin traitant'),
        service: ['survey', 'study'],
    },
    {
        key: 'APPOINTEMENT_SPECIALIST',
        label: 'Redirection vers un autre spécialiste',
        i18nValue: t('Redirection vers un autre spécialiste'),
        service: ['survey'],
    },
    {
        key: 'CLEAR',
        label: 'Alertes redondantes, aucune action supplémentaire',
        i18nValue: t('Alertes redondantes, aucune action supplémentaire'),
        service: ['survey', 'study'],
    },
    {
        key: 'TREATEMENT_NEW',
        label: 'Changement de traitement',
        i18nValue: t('Changement de traitement'),
        service: ['survey'],
    },
    {
        key: 'SYMPTOM_MANAGEMENT',
        label: 'Gestion de symptômes/ toxicités',
        i18nValue: t('Gestion de symptômes/ toxicités'),
        service: ['survey'],
    },
    {
        key: 'REQUEST_MEDICAL_REFERENT_GR',
        label: 'Demande avis médecin référent (oncologue, chirurgien...)',
        i18nValue: t(
            'Demande avis médecin référent (oncologue, chirurgien...)'
        ),
        service: ['survey'],
    },
    {
        key: 'REQUEST_MEDICAL_OTHER',
        label: 'Demande avis médical autres',
        i18nValue: t('Demande avis médical autres'),
        service: ['survey'],
    },
    {
        key: 'TRANSMISSION_INTERNAL',
        label: 'Transmission informations médicales en interne',
        i18nValue: t('Transmission informations médicales en interne'),
        service: ['survey'],
    },
    {
        key: 'HOMECARE',
        label: 'Organisation soins à domicile',
        i18nValue: t('Organisation soins à domicile'),
        service: ['survey'],
    },
    {
        key: 'REFERAL_SURGERY_REGULATION_NURSE_PLC',
        label: 'Orientation infirmière de régulation de chirurgie du PLC',
        i18nValue: t(
            'Orientation infirmière de régulation de chirurgie du PLC'
        ),
        service: ['survey'],
    },
    {
        key: 'INTERNAL_MEDICAL_APPOINTMENT',
        label: 'Organisation RDV en consultation médicale interne',
        i18nValue: t('Organisation RDV en consultation médicale interne'),
        service: ['survey'],
    },
    {
        key: 'PROGRAMMING_ORGANIZING_HOSPITALIZATION',
        label: 'Organisation / programmation hospitalisation',
        i18nValue: t('Organisation / programmation hospitalisation'),
        service: ['survey'],
    },
    {
        key: 'REFERAL_EXTERNAL_PROFESSIONNAL',
        label: 'Orientation vers professionnel externe ou structure externe',
        i18nValue: t(
            'Orientation vers professionnel externe ou structure externe'
        ),
        service: ['survey'],
    },
    {
        key: 'OTHER_APPOINTEMENT',
        label: 'Organisation RDV autres',
        i18nValue: t('Organisation RDV autres'),
        service: ['survey'],
    },
    {
        key: 'SUPPORT_CARE_SOCIAL_SERVICE',
        label: 'Orientation soins de support : Service social',
        i18nValue: t('Orientation soins de support : Service social'),
        service: ['survey'],
    },
    {
        key: 'SUPPORT_CARE_ONCOPSYCHOLOGY',
        label: 'Orientation soins de support : Oncopsychologie',
        i18nValue: t('Orientation soins de support : Oncopsychologie'),
        service: ['survey'],
    },
    {
        key: 'SUPPORT_CARE_DIET_NUTRITION',
        label: 'Orientation soins de support : Diététique/nutrition',
        i18nValue: t('Orientation soins de support : Diététique/nutrition'),
        service: ['survey'],
    },
    {
        key: 'SUPPORT_CARE_ADDICTOLOGY',
        label: 'Orientation soins de support : Addictologie',
        i18nValue: t('Orientation soins de support : Addictologie'),
        service: ['survey'],
    },
    {
        key: 'TRANSMISSION_INTERNAL_HOSPITALIZATION',
        label: 'Transmission informations interne',
        i18nValue: t('Transmission informations interne'),
        service: ['survey'],
    },
    {
        key: 'TRANSMISSION_HOSPITALIZATION_IDEC',
        label: 'Transmission informations IDEC/cadre',
        i18nValue: t('Transmission informations IDEC/cadre'),
        service: ['survey'],
    },
    {
        key: 'TRANSMISSION_HOSPITALIZATION_SECRETARY',
        label: 'Transmission informations secrétaire',
        i18nValue: t('Transmission informations secrétaire'),
        service: ['survey'],
    },
    {
        key: 'TRANSMISSION_HOSPITALIZATION_SUPPORT_CARE',
        label: 'Transmission informations soins de support',
        i18nValue: t('Transmission informations soins de support'),
        service: ['survey'],
    },
    {
        key: 'LINK_PROVIDER',
        label: 'Lien avec prestataire',
        i18nValue: t('Lien avec prestataire'),
        service: ['survey'],
    },
    {
        key: 'LINK_IDE',
        label: 'Lien avec IDE libéral',
        i18nValue: t('Lien avec IDE libéral'),
        service: ['survey'],
    },
    {
        key: 'LINK_PHARMACY',
        label: 'Lien avec pharmacie',
        i18nValue: t('Lien avec pharmacie'),
        service: ['survey'],
    },
    {
        key: 'LINK_EXTERNAL',
        label: 'Lien avec professionnel en externe',
        i18nValue: t('Lien avec professionnel en externe'),
        service: ['survey'],
    },
    {
        key: 'OTHER',
        label: 'Autre',
        i18nValue: t('Autre'),
        service: ['survey'],
    },
]
/*
    private const CALLED                   = 'CALLED';                   // Appel au patient et rien à signaler
    private const CALLED_ADVICE            = 'CALLED_ADVICE';            // Appel au patient avec conseil
    private const APPOINTMENT              = 'APPOINTMENT';              // Nouvelle consultation prévue avec le médecin référent
    private const APPOINTEMENT_EXTERNAL    = 'APPOINTEMENT_EXTERNAL';    // Redirection vers le médecin traitant
    private const EMERGENCIES              = 'EMERGENCIES';              // Envoi aux urgences
    private const CLEAR                    = 'CLEAR';                    // Alertes redondantes, aucune action supplémentaire
    private const FINISHED                 = 'FINISHED';                 // Traitée
    private const TREATEMENT_DOSAGE_UPDATE = 'TREATEMENT_DOSAGE_UPDATE'; // Adaptation des doses de traitement
    private const TREATEMENT_UPDATE        = 'TREATEMENT_UPDATE';        // Prescription de traitement concomitant
 */

export const studies = [
    { key: 'palatine', label: 'Palatine', i18nLabel: t('Palatine') },
    { key: 'curve', label: 'Curve', i18nLabel: t('Curve') },
    { key: 'less', label: 'Less', i18nLabel: t('Less') },
    { key: 'urorein', label: 'Rein', i18nLabel: t('Rein') },
    { key: 'uroreinambu', label: 'Rein-Ambu', i18nLabel: t('Rein-Ambu') },
    { key: 'uroprostate', label: 'Prostate', i18nLabel: t('Prostate') },
    { key: 'urovessie', label: 'Vessie', i18nLabel: t('Vessie') },
    { key: 'uroaccurate99', label: 'Accurate 99', i18nLabel: t('Accurate 99') },

    {
        key: 'urodipru',
        label: 'Parcours DiPRU',
        i18nLabel: t('Parcours DiPRU'),
    },
    {
        key: 'uroanxiety113dipru',
        label: 'Parcours Anxiety 113 + DiPRU',
        i18nLabel: t('Parcours Anxiety 113 + DiPRU'),
    },
    {
        key: 'uroanxiety113',
        label: 'Parcours Anxiety 113',
        i18nLabel: t('Parcours Anxiety 113'),
    },
    {
        key: 'urosocratic152sa',
        label: 'Parcours Socractic 152 - Surveillance Active',
        i18nLabel: t('Parcours Socractic 152 - Surveillance Active'),
    },
    {
        key: 'urosocratic152sur',
        label: 'Parcours Socratic 152 - Chirurgie',
        i18nLabel: t('Parcours Socratic 152 - Chirurgie'),
    },
    {
        key: 'uropersonalize114',
        label: 'Personalize 114',
        i18nLabel: t('Personalize 114'),
    },
]

export const telesuiviKPI = [
    {
        key: 'Fatigue',
        value: 'CryptedTiredness',
        unit: t('0 : Pas de fatigue - 6 : Beaucoup de fatigue'),
        sufix: '/6',
        legend: [
            {
                minValue: 0,
                maxValue: 2,
                color: '#87CE4E',
                label: '0 à 2 - pas de fatigue',
                labeli18n: t('0 à 2 - pas de fatigue'),
            },
            {
                minValue: 3,
                maxValue: 4,
                color: '#f4ad4a',
                label: '3 à 4 - fatigue moyenne',
                labeli18n: t('3 à 4 - fatigue moyenne'),
            },
            {
                minValue: 5,
                maxValue: 6,
                color: '#eb5c96',
                label: '5 à 6 - fatigue élevée',
                labeli18n: t('5 à 6 - fatigue élevée'),
            },
        ],
        roundValue: 10,
    },
    {
        key: 'Sommeil',
        value: 'CryptedSleep',
        unit: t('0 : Mauvaise qualité - 6 : Excellente qualité'),
        sufix: '/6',
        legend: [
            {
                minValue: 0,
                maxValue: 2,
                color: '#EB5C96',
                label: '0 à 2 - mauvaise nuit',
                labeli18n: t('0 à 2 - mauvaise nuit'),
            },
            {
                minValue: 3,
                maxValue: 4,
                color: '#f4ad4a',
                label: '3 à 4 - nuit moyenne',
                labeli18n: t('3 à 4 - nuit moyenne'),
            },
            {
                minValue: 5,
                maxValue: 6,
                color: '#87ce4e',
                label: '5 à 6 - bonne nuit',
                labeli18n: t('5 à 6 - bonne nuit'),
            },
        ],
        roundValue: 10,
    },
    {
        key: 'Tour de taille',
        value: 'CryptedWaistSize',
        unit: t('En cm'),
        sufix: 'cm',
        legend: [
            {
                minValue: 0,
                maxValue: 99999,
                color: '#4D7CFE',
                label: 'tour de taille en centimètres',
                labeli18n: t('tour de taille en centimètres'),
            },
        ],
        roundValue: 10,
    },
    {
        key: 'Tabac',
        value: 'CryptedSmoke',
        unit: t('En nombre de cigarettes'),
        sufix: 'cig.',
        legend: [
            {
                minValue: 0,
                maxValue: 99999,
                color: '#4D7CFE',
                label: 'nombre de cigarettes consummées',
                labeli18n: t('nombre de cigarettes consummées'),
            },
        ],
        roundValue: 10,
    },
    {
        key: 'Moral',
        value: 'CryptedMoral',
        unit: t('0 : Mauvais - 6 : Très bon'),
        sufix: '/6',
        legend: [
            {
                minValue: 0,
                maxValue: 2,
                color: '#EB5C96',
                label: '0 à 2 - moral faible',
                labeli18n: t('0 à 2 - moral faible'),
            },
            {
                minValue: 3,
                maxValue: 4,
                color: '#f4ad4a',
                label: '3 à 4 - moral moyen',
                labeli18n: t('3 à 4 - moral moyen'),
            },
            {
                minValue: 5,
                maxValue: 6,
                color: '#87ce4e',
                label: '5 à 6 - moral bon',
                labeli18n: t('5 à 6 - moral bon'),
            },
        ],
        roundValue: 10,
    },
    {
        key: 'Tension',
        value: 'BloodPressure',
        unit: '',
        sufix: 'mmHg',
        legend: [
            {
                minValue: 0,
                maxValue: 99999,
                color: '#4D7CFE',
                label: 'tension sytolique en mmHg',
                labeli18n: t('tension sytolique en mmHg'),
            },
        ],
        roundValue: 10,
    },
    {
        key: 'Rythme Cardiaque',
        value: 'Heartbeat',
        unit: '',
        sufix: 'bpm',
        legend: [
            {
                minValue: 0,
                maxValue: 99999,
                color: '#4D7CFE',
                label: 'battements par minute',
                labeli18n: t('battements par minute'),
            },
        ],
        roundValue: 10,
    },
    {
        key: 'Marche',
        value: 'CryptedActivity',
        unit: t('En nombre de pas'),
        sufix: 'pas',
        legend: [
            {
                minValue: 0,
                maxValue: 99999,
                color: '#4D7CFE',
                label: 'nombre de pas cumulés',
                labeli18n: t('nombre de pas cumulés'),
            },
        ],
        roundValue: 10,
    },
    {
        key: 'Stress',
        value: 'CryptedStress',
        unit: t('0 : Pas de stress - 6 : Beaucoup de stress'),
        sufix: '/6',
        legend: [
            {
                minValue: 0,
                maxValue: 2,
                color: '#87ce4e',
                label: '0 à 2 - pas de stress',
                labeli18n: t('0 à 2 - pas de stress'),
            },
            {
                minValue: 3,
                maxValue: 4,
                color: '#f4ad4a',
                label: '3 à 4 - stress moyen',
                labeli18n: t('3 à 4 - stress moyen'),
            },
            {
                minValue: 5,
                maxValue: 6,
                color: '#eb5c96',
                label: '5 à 6 - stress élevé',
                labeli18n: t('5 à 6 - stress élevé'),
            },
        ],
        roundValue: 10,
    },
    {
        key: 'Poids',
        value: 'CryptedWeight',
        unit: t('En kg'),
        sufix: 'kg',
        legend: [
            {
                minValue: 0,
                maxValue: 99999,
                color: '#4D7CFE',
                label: 'poids en kilogrammes',
                labeli18n: t('poids en kilogrammes'),
            },
        ],
        roundValue: 10,
    },
    {
        key: 'Glycémie',
        value: 'Glycemy',
        unit: t('En g/L'),
        sufix: 'g/L',
        legend: [
            {
                minValue: 0,
                maxValue: 999,
                color: '#4D7CFE',
                label: 'niveau de glycémie en g/L',
                labeli18n: t('niveau de glycémie en g/L'),
            },
        ],
        roundValue: 10,
    },
    {
        key: 'Observance',
        value: 'Observance',
        unit: t('En %'),
        sufix: '%',
        legend: [
            {
                minValue: 0,
                maxValue: 40,
                color: '#EB5C96',
                label: '0 à 40%',
                labeli18n: t('0 à 40%'),
            },
            {
                minValue: 40,
                maxValue: 60,
                color: '#f4ad4a',
                label: '40 à 60%',
                labeli18n: t('40 à 60%'),
            },
            {
                minValue: 60,
                maxValue: 100,
                color: '#87ce4e',
                label: '60 à 100%',
                labeli18n: t('60 à 100%'),
            },
        ],
        roundValue: 10,
    },
    {
        key: 'Hydratation',
        value: 'CryptedHydration',
        unit: t('En litre'),
        sufix: 'litre',
        legend: [
            {
                minValue: 0,
                maxValue: 250,
                color: '#F4AD4A',
                label: 'objectif raté',
                labeli18n: t('objectif raté'),
                success: false,
            },
            {
                minValue: 250,
                maxValue: 99999,
                color: '#87ce4e',
                label: 'objectif atteint',
                labeli18n: t('objectif atteint'),
                success: true,
            },
            {
                minValue: 250,
                maxValue: 250,
                color: '#000',
                label: 'objectif',
                labeli18n: t('objectif'),
            },
        ],
        roundValue: false,
    },
    {
        key: 'Urine',
        value: 'CryptedUrine',
        unit: '',
        sufix: '',
        legend: [
            {
                minValue: -1,
                maxValue: -1,
                color: '#4A7EF6',
                label: 'niveau pH',
                labeli18n: t('niveau pH'),
            },
            {
                minValue: -1,
                maxValue: -1,
                color: '#000',
                label: 'volume urinaire',
                labeli18n: t('volume urinaire'),
            },
            { minValue: 1, maxValue: 1, color: '#f6f1c3', label: '' },
            { minValue: 2, maxValue: 2, color: '#f1ebaa', label: '' },
            { minValue: 3, maxValue: 3, color: '#f4ec93', label: '' },
            { minValue: 4, maxValue: 4, color: '#f6dc4a', label: '' },
            { minValue: 5, maxValue: 5, color: '#edd247', label: '' },
            { minValue: 6, maxValue: 6, color: '#e4c241', label: '' },
            { minValue: 7, maxValue: 7, color: '#dbb03b', label: '' },
            {
                minValue: 8,
                maxValue: 8,
                color: '#8d8729',
                label: 'couleur urine',
                labeli18n: t('couleur urine'),
            },
        ],
        roundValue: 10,
    },
]

export const alertesTriggerTypes = [
    {
        key: 'DECREASE',
        value: '1 mesure est en diminution',
        i18nValue: t('1 mesure est en diminution'),
    },
    {
        key: 'INCREASE',
        value: '1 mesure est en augmentation',
        i18nValue: t('1 mesure est en augmentation'),
    },
    {
        key: 'MAX',
        value: '1 mesure a dépassée le maximum',
        i18nValue: t('1 mesure a dépassée le maximum'),
    },
    {
        key: 'MIN',
        value: '1 mesure a dépassée le minimum',
        i18nValue: t('1 mesure a dépassée le minimum'),
    },
    {
        key: 'NODATA',
        value: '1 mesure est manquante',
        i18nValue: t('1 mesure est manquante'),
    },
    {
        key: 'RANGE',
        value: '1 mesure est en dehors des min/max',
        i18nValue: t('1 mesure est en dehors des min/max'),
    },
]

export const biologicalGender = [
    { key: 1, label: 'Masculin', i18nLabel: t('Masculin') },
    { key: 0, label: 'Féminin', i18nLabel: t('Féminin') },
    { key: 2, label: 'Indeterminé', i18nLabel: t('Indeterminé') },
]
