import { h } from 'hyperapp'
import { Component } from '@app/utils'
import { dynLoad, getSupport } from '@app/core'
import { Consent } from '@app/api'
import './index.scss'

const state = {
    support: null,
    legalAgreements: [],
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        actions.setSupport(getSupport('whole'))
        actions.getLegalAgreements()
    },
    /**
     * Retrieve public legal agreements
     * @function Mentions:getLegalAgreements
     */
    getLegalAgreements: () => (state, actions) => {
        Consent.getLastPublicLegalAgreement('doctor').then((res) => {
            actions.setLegalAgreements(res.data)
        })
    },
    setSupport: (newState) => (state) => ({
        support: newState,
    }),
    setLegalAgreements: (newState) => (state) => ({
        legalAgreements: newState,
    }),
}

export const view = dynLoad('view', 'MentionsPublic/views')

export default Component(state, actions, view, 'mentionspublic')
