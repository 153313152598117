import { h } from 'hyperapp';
import { Component } from '@app/utils';
import './index.scss';

import IndexLayout from '@app/layouts/pages/layout';

import Listing from './listing';

const state = {
    email: {
        value: '',
        isValid: false,
        isApproved: null,
        isResetted: null
    },
    password: {
        value: '',
        isValid: false,
        isApproved: null,
        isResetted: null
    }
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        // localStorage.clear();
    },
    setState: ({ ns, key, value }) => state => ({
        [ns]: { ...state[ns], ...{ [key]: value } },
    })
};

const view = (state, actions) => ({ match, customer }, children) => (
    <div key='alertsic' class='root-wrapper'>
        <IndexLayout page='alertsic' customer={customer}>
            {/* Default view */}
            {match.isExact && (
                <Listing initial={state.email} customer={customer} send={actions.setState} />
            )}

        </IndexLayout>
    </div>
);

export {
    Listing,
};

export default Component(state, actions, view, 'alertsic');
