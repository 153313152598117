import { h } from 'hyperapp'

const Trim = (props, children) => {
    if (props.mode) {
        if (props.mode === 'specialchar') {
            //
        }
    } else {
        if (children[0] !== undefined && children[0].length > props.char) {
            return children[0].substr(0, props.char) + ' ...'
        }
    }
    return children
}
export { Trim }
