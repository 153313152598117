import { t } from 'i18next'
import { h } from 'hyperapp'
import { Component } from '@app/utils'
import { allRoutes, getUser, isDefined, logoutAndRedirect } from '@app/core'
import { User } from '@app/api'
import { dynLoad, getSupport } from '@app/core'

import * as constants from '@app/constants'
import { createLocaleFromLangAndUserObject } from '@app/core/localeHandlers/createLocaleFromLangAndUserObject'
import { getLangFromLocalStorage } from '@app/core/localeHandlers/getLangFromLocalStorage'

export const UserMenuView = dynLoad('view', 'usermenus', 'layouts')

const UserMenu = Component(
    {
        URLEmptyTimeline: '',
        URLUserMedia: '',
        userName: '',
        currentLocale: 'fr-FR',
        currentLanguage: 'fr',
        messageContent: '',
        messageKind: 'success',
        messageActive: false,
        support: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            actions.setCurrentLanguage(getLangFromLocalStorage())
            //
            actions.setSupport(getSupport('whole'))
            let currentLocale = localStorage.getItem('locale')
            if (currentLocale) {
                actions.setCurrentLocale(currentLocale)
            }

            if (props && props.display !== 'login') {
                let cb = function (user) {
                    if (user) {
                        if (user.nick) {
                            actions.setUserName(user.nick)
                        }
                        if (user.media && user.media.url) {
                            actions.setUserMedia(user.media.url)
                        }
                    }
                }
                getUser(cb)
            }
        },
        onComponentUpdate: (props) => (state, actions) => {},
        setSupport: (newState) => (state) => ({
            support: newState,
        }),
        setUserName: (newState) => (state) => ({
            userName: newState,
        }),
        setUserMedia: (newState) => (state) => ({
            URLUserMedia: newState,
        }),
        setCurrentLocale: (newState) => (state) => ({
            currentLocale: newState,
        }),
        setCurrentLanguage: (newState) => (state) => ({
            currentLanguage: newState,
        }),
        clearTimeline: () => (state, actions) => {
            User.clearTimeline().then(() => {
                actions.setQueryMessage({
                    newStateKind: 'success',
                    newStateContent: t(
                        'Votre journal a été vider avec succés.'
                    ),
                })
                actions.displaySuccessMessage(true)
            })
        },
        deconnectUser: () => (state, actions) => {
            logoutAndRedirect()
        },
        updateLocale: (lang) => (state, actions) => {
            if (lang && state.currentLanguage !== lang) {
                const locale = createLocaleFromLangAndUserObject(lang, null)
                localStorage.setItem('locale', locale)
                //
                if (window.location.pathname.indexOf('register') > -1) {
                    let newLanguage = constants.custo.languages.find(
                        (f) => f.key === lang
                    )
                    if (isDefined(newLanguage)) {
                        newLanguage = newLanguage.key
                        //
                        let newLocation = window.location.href
                        let trgLocation = new RegExp(/(\/[a-z]{2}\/)/)
                        newLocation = newLocation.replace(
                            trgLocation,
                            '/' + newLanguage + '/'
                        )
                        window.location = newLocation
                        return false
                    }
                }
                actions.setCurrentLanguage(lang)
                //
                setTimeout(() => {
                    window.location.reload()
                }, 400)
            }
        },
        setQueryMessage:
            ({ newStateKind, newStateContent }) =>
            (state) => ({
                messageKind: newStateKind,
                messageContent: newStateContent,
            }),
        displaySuccessMessage: (newState) => (state) => ({
            messageActive: newState,
        }),
    },
    UserMenuView,
    'usermenu'
)

export { UserMenu }
