import { t } from 'i18next';
import { Alert } from '@app/api';
import { allRoutes, addCSRFToken, isDefined, getUser, coreFn } from '@app/core';

import * as constants from '@app/constants';

/**
 * @module AlertsicListing
 * @description Display listing of specific IC alerts, related to activated specific monitoring IC module
 */

export default {
    initAction: function() {
        let AlertsicListing = {
            onComponentCreate: (props) => (state, actions) => {
                let ICMesure = (process.env.APP_IC_MODULE || '');
                actions._setState({key: 'ICMesure', value: ICMesure});
                let then = (loggedUser) => {
                    actions._setState({key: 'doctor', value: loggedUser});
                    if (isDefined(ICMesure) && (ICMesure !== '')) {
                        let dataTypeCurrent = constants.custo.telesuiviSettings.filter((f) => ((f.crypt !== ICMesure) && (state.ICMesureIgnore.indexOf(f.crypt) === -1))).map((m) => m.alertValueKey.filter((f) => (f.key !== 'mean')).map((vKey) => (m.crypt.toUpperCase() + '_' + String(vKey.key).toUpperCase()))).reduce((a, b) => a.concat(b));
                        let themeCurrent = constants.custo.telesuiviSettings.filter((f) => ((f.crypt === ICMesure) && (state.ICMesureIgnore.indexOf(f.crypt) === -1))).map((m) => m.alertValueKey.filter((f) => (f.key !== 'mean')).map((vKey) => (m.crypt.toUpperCase() + '_' + String(vKey.key).toUpperCase()))).reduce((a, b) => a.concat(b));
                        actions._setState({key: 'dataTypeCurrent', value: dataTypeCurrent});
                        actions._setState({key: 'themeCurrent', value: themeCurrent[0].toUpperCase()});
                        actions.getPage();
                    }
                }
                getUser(then);
            },
            /**
            * Redirect when user click on a table row
            * @function AlertsicListing:redirectToPatient
            * @param {string} patientid - Id of patient
            * @param {ymd} date - ymd date of alert
            * @param {string} crypt - crypt of alert
            * @param {string} period - period of alert (week, month)
            */
            redirectToPatient: ({patientid, date, crypt, period}) => (state, actions) => {
                window.location.href = addCSRFToken(allRoutes['private.patient.edit'].pathname.replace(':id', patientid) + '?sp=telesuivi&date=' + date + '&mesure=' + crypt + '&period=' + period);
            },
            /**
            * Retrieve form input on the fly, then call [getPage]{@link module:AlertsicListing~AlertsicListing:getPage})
            * @param {input} input - Contain [isvalid], [name], [id] and [values] fields
            * @function AlertsicListing:retrieveInputAction
            */
            retrieveInputAction: (input) => (state, actions) => {
                const { values, id } = input;
                let patientid = id.split('-')[1];
                let alertid = id.split('-')[0];
                Alert.updateUserAlert(patientid, alertid, {status: values}).then(() => {
                    actions.getPage();
                });
            },
            /**
            * Parse a value to get a human readable string
            * @param {object} el - object to update
            * @param {object} item - Item, containing value to parse
            * @function AlertsicListing:getReadableValue
            */
            getReadableValue: ({el, item}) => (state, actions) => {
                let parsedValue = '';
                if (item.value === 'NODATA') {
                    parsedValue = 'N.C';
                } else {
                    parsedValue = item.value;
                }
                el.innerHTML = parsedValue;
            },
            /**
            * Parse a dataType to get a human readable string
            * @param {object} el - object to update
            * @param {object} item - Item, containing dataType to parse
            * @function AlertsicListing:getReadableDataType
            */
            getReadableDataType: ({el, item}) => (state, actions) => {
                let parsedValue = constants.custo.telesuiviSettings.filter((f) => f.crypt.toUpperCase() === item.dataType.split('_')[0].toUpperCase())[0].value;
                el.innerHTML = parsedValue;
            },
            /**
            * Parse a triggerType to get a human readable string
            * @param {object} el - object to update
            * @param {object} item - Item, containing triggerType to parse
            * @function AlertsicListing:getReadableTriggerType
            */
            getReadableTriggerType: ({el, item}) => (state, actions) => {
                let parsedValue = '';
                if (item.triggerType === 'NODATA') {
                    parsedValue = t('1 mesure est manquante');
                } else if (item.triggerType === 'INCREASE') {
                    parsedValue = t('1 mesure est en augmentation');
                } else {
                    parsedValue = item.triggerType;
                }
                el.innerHTML = parsedValue;
            },
            /**
            * Update listing page following current page variable
            * @function AlertsicListing:getPage
            */
            getPage: () => (state, actions) => {
                let dataAll = {dataType: state.dataTypeCurrent, status: 0};
                if (!state.toTreatAlert) {
                    dataAll = {dataType: state.dataTypeCurrent};
                }
                Alert.getAlertAll(state.page, dataAll).then((alerts) => {
                    let dataSpecific = {dataType: [state.themeCurrent], status: 0};
                    if (!state.toTreatAlert) {
                        dataSpecific = {dataType: [state.themeCurrent]};
                    }
                    Alert.getAlertAll(state.page, dataSpecific).then((icalerts) => {
                        actions._setState({key: 'alerts', value: alerts.data});
                        actions._setState({key: 'icalerts', value: icalerts.data});
                    });
                });
            },
            /**
            * Increments current page, then call [getPage]{@link module:AlertsicListing~AlertsicListing:getPage})
            * @function AlertsicListing:nextPage
            */
            nextPage: () => (state, actions) => {
                actions._setState({key: 'page', value: state.page + 1});
                actions.getPage();
            },
            /**
            * Decrement current page, then call [getPage]{@link module:AlertsicListing~AlertsicListing:getPage})
            * @function AlertsicListing:prevPage
            */
            prevPage: () => (state, actions) => {
                actions._setState({key: 'page', value: state.page - 1});
                actions.getPage();
            },
            /**
            * Update alert mode, then call [getPage]{@link module:AlertsicListing~AlertsicListing:getPage})
            * @function AlertsicListing:switchAlertMode
            * @param {string} totreat - New alert mode
            */
            switchAlertMode: (totreat) => (state, actions) => {
                actions._setToTreatAlert(totreat);
                actions._setState({key: 'toTreatAlert', value: totreat});
                actions.getPage();
            },
        }
        AlertsicListing = {...coreFn, ...AlertsicListing};
        return AlertsicListing;
    }
}