import { t } from 'i18next';
import { h } from 'hyperapp';
import { Card, Tile, Enter } from '@app/elements';
import * as constants from '@app/constants';

import IndexLayout from '@app/layouts/pages/layout';

// import icoCE from '@app/img/mentions/logo-right-ce.png';
// import icoCEx2 from '@app/img/mentions/logo-right-ce@2x.png';

export default {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <IndexLayout page={'help'}>
                <div class='help-central' style={{ 'background-color': '#f8fafb' }}>
                    <Card classes={'btzPageCard btzBackgroundCover'} kind={'datas'}>
                        <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                            <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
                                <Tile>
                                    <Tile.Content>
                                        <p class='btzTitle btzXl'>
                                            {t('Aide.')}
                                        </p>
                                    </Tile.Content>
                                </Tile>
                                <div class='' style='padding: 40px 30px 0;background: white;'>
                                    //
                                </div>
                            </Enter>
                        </Card.Header>
                    </Card>
                </div>
            </IndexLayout>
        );
        return view;
    }
}