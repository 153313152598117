export const OTHERS_SPORTS = [
    {'datatag': 'ACTIVITY_TREESTATS', 'readable': 'Accrobranche'},
    {'datatag': 'ACTIVITY_AEROBICSSTATS', 'readable': 'Aérobic'},
    {'datatag': 'ACTIVITY_MOUNTAINEERINGSTATS', 'readable': 'Alpinisme'},
    {'datatag': 'ACTIVITY_APNEASTATS', 'readable': 'Apnée'},
    {'datatag': 'ACTIVITY_WATERAEROBICSSTATS', 'readable': 'Aqua gym'},
    {'datatag': 'ACTIVITY_MARTIALARTSSTATS', 'readable': 'Arts martiaux'},
    {'datatag': 'ACTIVITY_ATHLETICSSTATS', 'readable': 'Athlétisme'},
    {'datatag': 'ACTIVITY_ROWINGSTATS', 'readable': 'Aviron'},
    {'datatag': 'ACTIVITY_BABYFOOTSTATS', 'readable': 'Baby foot'},
    {'datatag': 'ACTIVITY_BADMINTONSTATS', 'readable': 'Badminton'},
    {'datatag': 'ACTIVITY_BALLTRAPSTATS', 'readable': 'Ball trap'},
    {'datatag': 'ACTIVITY_BALLETONICESTATS', 'readable': 'Ballet sur glace'},
    {'datatag': 'ACTIVITY_BASEBALLSTATS', 'readable': 'Baseball'},
    {'datatag': 'ACTIVITY_BASKETBALLSTATS', 'readable': 'Basket ball'},
    {'datatag': 'ACTIVITY_BATONDEFENSESTATS', 'readable': 'Baton défense'},
    {'datatag': 'ACTIVITY_BEACHSOCCERSTATS', 'readable': 'Beach soccer'},
    {'datatag': 'ACTIVITY_BIATHLONSTATS', 'readable': 'Biathlon'},
    {'datatag': 'ACTIVITY_BEACHVOLLEYBALLSTATS', 'readable': 'Beach volley'},
    {'datatag': 'ACTIVITY_BILLIARDSSTATS', 'readable': 'Billard'},
    {'datatag': 'ACTIVITY_BMXSTATS', 'readable': 'BMX'},
    {'datatag': 'ACTIVITY_BOBSTATS', 'readable': 'Bobsleigh'},
    {'datatag': 'ACTIVITY_BOCCIASTATS', 'readable': 'Boccia'},
    {'datatag': 'ACTIVITY_BODYBOARDSTATS', 'readable': 'Bodyboard'},
    {'datatag': 'ACTIVITY_BOOGIEWOOGIESTATS', 'readable': 'Boogie Woogiue'},
    {'datatag': 'ACTIVITY_BOOMERANGSTATS', 'readable': 'Boomerang'},
    {'datatag': 'ACTIVITY_LYONNAISEBALLSTATS', 'readable': 'Boule lyonnaise'},
    {'datatag': 'ACTIVITY_BOWLINGSTATS', 'readable': 'Bowling'},
    {'datatag': 'ACTIVITY_BOXINGSTATS', 'readable': 'Boxe anglaise'},
    {'datatag': 'ACTIVITY_BURMESEBOXINGSTATS', 'readable': 'Boxe birmane'},
    {'datatag': 'ACTIVITY_FRENCHBOXSTATS', 'readable': 'Boxe française'},
    {'datatag': 'ACTIVITY_THAIBOXINGSTATS', 'readable': 'Boxe thaïlandaise'},
    {'datatag': 'ACTIVITY_BREAKDANCESTATS', 'readable': 'Break dance'},
    {'datatag': 'ACTIVITY_CANOEKAYAKSTATS', 'readable': 'Canoë kayak'},
    {'datatag': 'ACTIVITY_CANYONINGSTATS', 'readable': 'Canyonisme'},
    {'datatag': 'ACTIVITY_CAPOEIRASTATS', 'readable': 'Capoeira'},
    {'datatag': 'ACTIVITY_WRESTLINGSTATS', 'readable': 'Catch'},
    {'datatag': 'ACTIVITY_YACHTINGSTATS', 'readable': 'Char à voile'},
    {'datatag': 'ACTIVITY_CHEERLEADINGSTATS', 'readable': 'Cheerleading'},
    {'datatag': 'ACTIVITY_CIRCUSSTATS', 'readable': 'Cirque'},
    {'datatag': 'ACTIVITY_TAPDANCESTATS', 'readable': 'Claquettes'},
    {'datatag': 'ACTIVITY_RUNNINGSTATS', 'readable': 'Course à pied'},
    {'datatag': 'ACTIVITY_ORIENTEERINGSTATS', 'readable': 'Course d‘orientation'},
    {'datatag': 'ACTIVITY_CRICKETSTATS', 'readable': 'Cricket'},
    {'datatag': 'ACTIVITY_CROQUETSTATS', 'readable': 'Croquet'},
    {'datatag': 'ACTIVITY_BUTTSTATS', 'readable': 'Crosse'},
    {'datatag': 'ACTIVITY_CROSSFITSTATS', 'readable': 'Crossfit'},
    {'datatag': 'ACTIVITY_CURLINGSTATS', 'readable': 'Curling'},
    {'datatag': 'ACTIVITY_CYCLINGSTATS', 'readable': 'Cyclisme'},
    {'datatag': 'ACTIVITY_CYCLOCROSSSTATS', 'readable': 'Cyclo-cross'},
    {'datatag': 'ACTIVITY_AFRICANDANCESTATS', 'readable': 'Danse africaine'},
    {'datatag': 'ACTIVITY_CLASSICDANCESTATS', 'readable': 'Danse classique'},
    {'datatag': 'ACTIVITY_CONTEMPORARYDANCESTATS', 'readable': 'Danse contemporaine'},
    {'datatag': 'ACTIVITY_CONTRYDANCESTATS', 'readable': 'Danse country'},
    {'datatag': 'ACTIVITY_SPANISHDANCESTATS', 'readable': 'Danse espagnole'},
    {'datatag': 'ACTIVITY_INDIANDANCESTATS', 'readable': 'Danse indienne'},
    {'datatag': 'ACTIVITY_JAZZDANCESTATS', 'readable': 'Danse jazz'},
    {'datatag': 'ACTIVITY_MODERNJAZZDANCESTATS', 'readable': 'Danse modern jazz'},
    {'datatag': 'ACTIVITY_ORIENTALDANCESTATS', 'readable': 'Danse orientale'},
    {'datatag': 'ACTIVITY_DANCEONICESTATS', 'readable': 'Danse sur glace'},
    {'datatag': 'ACTIVITY_DECATHLONSTATS', 'readable': 'Décathlon'},
    {'datatag': 'ACTIVITY_HANGGLIDERSTATS', 'readable': 'Deltaplane'},
    {'datatag': 'ACTIVITY_DISCGOLFSTATS', 'readable': 'Disc golf'},
    {'datatag': 'ACTIVITY_HORSERIDINGSTATS', 'readable': 'Equitation'},
    {'datatag': 'ACTIVITY_CLIMBINGSTATS', 'readable': 'Escalade'},
    {'datatag': 'ACTIVITY_FENCINGSTATS', 'readable': 'Escrime'},
    {'datatag': 'ACTIVITY_BODYAWAKENINGSTATS', 'readable': 'Eveil corporel'},
    {'datatag': 'ACTIVITY_FITNESSSTATS', 'readable': 'Fitness'},
    {'datatag': 'ACTIVITY_FLAGSTATS', 'readable': 'Flag'},
    {'datatag': 'ACTIVITY_DARTSSTATS', 'readable': 'Fléchettes'},
    {'datatag': 'ACTIVITY_SOCCERSTATS', 'readable': 'Footbal'},
    {'datatag': 'ACTIVITY_FOOTBALLSTATS', 'readable': 'Football américain'},
    {'datatag': 'ACTIVITY_FOOTINGSTATS', 'readable': 'Footing'},
    {'datatag': 'ACTIVITY_ATHLETICFORCESTATS', 'readable': 'Force athlétique'},
    {'datatag': 'ACTIVITY_FUTSALSTATS', 'readable': 'Futsal'},
    {'datatag': 'ACTIVITY_GOLFSTATS', 'readable': 'Gold'},
    {'datatag': 'ACTIVITY_GYMNASTICSTATS', 'readable': 'Gymnastique'},
    {'datatag': 'ACTIVITY_WEIGHTLIGTINGSTATS', 'readable': 'Haltérophilie'},
    {'datatag': 'ACTIVITY_HANDBALLSTATS', 'readable': 'Handball'},
    {'datatag': 'ACTIVITY_DISABLEDSPORTSTATS', 'readable': 'Handisport'},
    {'datatag': 'ACTIVITY_HIPHOPSTATS', 'readable': 'Hip Hop'},
    {'datatag': 'ACTIVITY_FIELDHOCKEYSTATS', 'readable': 'Hockey sur gazon'},
    {'datatag': 'ACTIVITY_ICEHOCKEYSTATS', 'readable': 'Hockey sur glace'},
    {'datatag': 'ACTIVITY_HORSEBALLSTATS', 'readable': 'Horse ball'},
    {'datatag': 'ACTIVITY_JETSKISTATS', 'readable': 'Jetski'},
    {'datatag': 'ACTIVITY_PALMGAMESTATS', 'readable': 'Jeu de paume'},
    {'datatag': 'ACTIVITY_JORKYBALLSTATS', 'readable': 'Jorkyball'},
    {'datatag': 'ACTIVITY_JUJITSUSTATS', 'readable': 'Ju-jitsu'},
    {'datatag': 'ACTIVITY_JUDOSTATS', 'readable': 'Jugo'},
    {'datatag': 'ACTIVITY_KARATESTATS', 'readable': 'Karaté'},
    {'datatag': 'ACTIVITY_KARTINGSTATS', 'readable': 'Karting'},
    {'datatag': 'ACTIVITY_KICKBOXINGSTATS', 'readable': 'Kick boxing'},
    {'datatag': 'ACTIVITY_KITESURFINGSTATS', 'readable': 'Kite surf'},
    {'datatag': 'ACTIVITY_KRAVMAGASTATS', 'readable': 'Krav maga'},
    {'datatag': 'ACTIVITY_KUNGFUSTATS', 'readable': 'Kung fu'},
    {'datatag': 'ACTIVITY_THECROSSESTATS', 'readable': 'Lacrosse'},
    {'datatag': 'ACTIVITY_TOTHROWTHEJAVELINSTATS', 'readable': 'Lancer de javelot'},
    {'datatag': 'ACTIVITY_HAMMERTHROWSTATS', 'readable': 'Lancer de marteau'},
    {'datatag': 'ACTIVITY_SHOTPUTSTATS', 'readable': 'Lancer de poids'},
    {'datatag': 'ACTIVITY_LUCESTATS', 'readable': 'Luge'},
    {'datatag': 'ACTIVITY_FIGHTSTATS', 'readable': 'Lutte'},
    {'datatag': 'ACTIVITY_ATHLETICWALKSTATS', 'readable': 'Marche athlétique'},
    {'datatag': 'ACTIVITY_UNICYCLESTATS', 'readable': 'Monocycle'},
    {'datatag': 'ACTIVITY_MOTOCROSSSTATS', 'readable': 'Moto cross'},
    {'datatag': 'ACTIVITY_SNOWMOBILESTATS', 'readable': 'Motoneige'},
    {'datatag': 'ACTIVITY_MOUNTAINBOARDSTATS', 'readable': 'Mountainboard'},
    {'datatag': 'ACTIVITY_BODYBUILDINGSTATS', 'readable': 'Musculation'},
    {'datatag': 'ACTIVITY_SWIMSTATS', 'readable': 'Nage'},
    {'datatag': 'ACTIVITY_SWIMMINGSTATS', 'readable': 'Natation'},
    {'datatag': 'ACTIVITY_SYNCHRONIZEDSWIMMINGSTATS', 'readable': 'Natation synchronisée'},
    {'datatag': 'ACTIVITY_PADELSTATS', 'readable': 'Padel'},
    {'datatag': 'ACTIVITY_PAINTBALLSTATS', 'readable': 'Paintball'},
    {'datatag': 'ACTIVITY_PANKRATIONSTATS', 'readable': 'Pancrace'},
    {'datatag': 'ACTIVITY_SKYDIVINGSTATS', 'readable': 'Parachutisme'},
    {'datatag': 'ACTIVITY_PARAMOTORSTATS', 'readable': 'Paramoteur'},
    {'datatag': 'ACTIVITY_PARAGLIDINGSTATS', 'readable': 'PArapente'},
    {'datatag': 'ACTIVITY_PARKOURSTATS', 'readable': 'Parkour'},
    {'datatag': 'ACTIVITY_FIGURESKATINGSTATS', 'readable': 'Patinage artistique'},
    {'datatag': 'ACTIVITY_FISHINGSTATS', 'readable': 'Pêche'},
    {'datatag': 'ACTIVITY_UNDERWATERFISHINGSTATS', 'readable': 'Pêche sous-marine'},
    {'datatag': 'ACTIVITY_BASQUEPELOTASTATS', 'readable': 'Pelote basque'},
    {'datatag': 'ACTIVITY_PENTATHLONSTATS', 'readable': 'Pentathlon'},
    {'datatag': 'ACTIVITY_PETANQUESTATS', 'readable': 'Pétanque'},
    {'datatag': 'ACTIVITY_PILATESSTATS', 'readable': 'Pilates'},
    {'datatag': 'ACTIVITY_SAILBOARDSTATS', 'readable': 'Planche à voile'},
    {'datatag': 'ACTIVITY_DIVINGSTATS', 'readable': 'Plongée'},
    {'datatag': 'ACTIVITY_PLUNGESTATS', 'readable': 'Plongeon'},
    {'datatag': 'ACTIVITY_POLOSTATS', 'readable': 'Polo'},
    {'datatag': 'ACTIVITY_QUADSTATS', 'readable': 'Quad'},
    {'datatag': 'ACTIVITY_KEELSTATS', 'readable': 'Quilles'},
    {'datatag': 'ACTIVITY_RAFTINGSTATS', 'readable': 'Rafting'},
    {'datatag': 'ACTIVITY_RAIDNATURESTATS', 'readable': 'Raid nature'},
    {'datatag': 'ACTIVITY_CARRALLYSTATS', 'readable': 'Rallye automobile'},
    {'datatag': 'ACTIVITY_HORSEBACKRIDINGSTATS', 'readable': 'Randonnée équestre'},
    {'datatag': 'ACTIVITY_HIKINGSTATS', 'readable': 'Randonnée pédestre'},
    {'datatag': 'ACTIVITY_SNOWSHOESTATS', 'readable': 'Raquette à neige'},
    {'datatag': 'ACTIVITY_ROCKSTATS', 'readable': 'Rock'},
    {'datatag': 'ACTIVITY_ACROBATICROCKSTATS', 'readable': 'Rock acrobatique'},
    {'datatag': 'ACTIVITY_ROLLERSTATS', 'readable': 'Roller'},
    {'datatag': 'ACTIVITY_RUGBYSTATS', 'readable': 'Rugby'},
    {'datatag': 'ACTIVITY_SALSASTATS', 'readable': 'Salsa'},
    {'datatag': 'ACTIVITY_SAMBASTATS', 'readable': 'Samba'},
    {'datatag': 'ACTIVITY_BLOWPIPESTATS', 'readable': 'Sarbacane'},
    {'datatag': 'ACTIVITY_POLEVAULTSTATS', 'readable': 'Saut à la perche'},
    {'datatag': 'ACTIVITY_SKIJUMPINGSTATS', 'readable': 'Saut à ski'},
    {'datatag': 'ACTIVITY_HIGHJUMPSTATS', 'readable': 'Saut en hauteur'},
    {'datatag': 'ACTIVITY_LONGJUMPSTATS', 'readable': 'Saut en longueur'},
    {'datatag': 'ACTIVITY_SELFDEFENSESTATS', 'readable': 'Self-défense'},
    {'datatag': 'ACTIVITY_SKATEBOARDSTATS', 'readable': 'Skateboard'},
    {'datatag': 'ACTIVITY_SKISTATS', 'readable': 'Ski'},
    {'datatag': 'ACTIVITY_ALPINESKIINGSTATS', 'readable': 'Ski alpin'},
    {'datatag': 'ACTIVITY_CROSSCOUNTRYSKIINGSTATS', 'readable': 'Ski de fond'},
    {'datatag': 'ACTIVITY_NORDICSKIINGSTATS', 'readable': 'Ski de randonnée'},
    {'datatag': 'ACTIVITY_SPEEDSKIINGSTATS', 'readable': 'Ski de vitesse'},
    {'datatag': 'ACTIVITY_WATERSKIINGSTATS', 'readable': 'Ski nautique'},
    {'datatag': 'ACTIVITY_SNOWBOARDSTATS', 'readable': 'Snowboard'},
    {'datatag': 'ACTIVITY_SOFTBALLSTATS', 'readable': 'Softball'},
    {'datatag': 'ACTIVITY_SPELEOLOGYSTATS', 'readable': 'Spéléologie'},
    {'datatag': 'ACTIVITY_SQUASHSTATS', 'readable': 'Squash'},
    {'datatag': 'ACTIVITY_STANDUPPADDLESTATS', 'readable': 'Stand-up paddle'},
    {'datatag': 'ACTIVITY_SUMOSTATS', 'readable': 'Sumo'},
    {'datatag': 'ACTIVITY_SURFSTATS', 'readable': 'Surf'},
    {'datatag': 'ACTIVITY_TAMBOURINESTATS', 'readable': 'Tambourin'},
    {'datatag': 'ACTIVITY_TANGOSTATS', 'readable': 'Tango'},
    {'datatag': 'ACTIVITY_TENNISSTATS', 'readable': 'Tennis'},
    {'datatag': 'ACTIVITY_TABLETENNISSTATS', 'readable': 'Tennis de table'},
    {'datatag': 'ACTIVITY_ARCHERYSTATS', 'readable': 'Tir à l‘arc'},
    {'datatag': 'ACTIVITY_SPORTIVESHOOTSTATS', 'readable': 'Tir sportif'},
    {'datatag': 'ACTIVITY_UNDERWATERSHOOTINGSTATS', 'readable': 'Tir subaquatique'},
    {'datatag': 'ACTIVITY_SLEDSSTATS', 'readable': 'Traîneaux'},
    {'datatag': 'ACTIVITY_TRAMPOLINESTATS', 'readable': 'Trampoline'},
    {'datatag': 'ACTIVITY_TRIATHLONSTATS', 'readable': 'Triathlon'},
    {'datatag': 'ACTIVITY_TUMBLINGSTATS', 'readable': 'Tumbling'},
    {'datatag': 'ACTIVITY_TWIRLINGBATONSTATS', 'readable': 'Twirling baton'},
    {'datatag': 'ACTIVITY_ULMSTATS', 'readable': 'ULM'},
    {'datatag': 'ACTIVITY_ULTIMATEFRISBEESTATS', 'readable': 'Ultimate Frisbee'},
    {'datatag': 'ACTIVITY_BIKINGSTATS', 'readable': 'Vélo'},
    {'datatag': 'ACTIVITY_SAILSTATS', 'readable': 'Voile'},
    {'datatag': 'ACTIVITY_VOLLEYBALLSTATS', 'readable': 'Volley ball'},
    {'datatag': 'ACTIVITY_VAULTINGSTATS', 'readable': 'Voltige'},
    {'datatag': 'ACTIVITY_MTBSTATS', 'readable': 'VTT'},
    {'datatag': 'ACTIVITY_WAKEBOARDSTATS', 'readable': 'Wakeboard'},
    {'datatag': 'ACTIVITY_WATERPOLOSTATS', 'readable': 'Water polo'},
    {'datatag': 'ACTIVITY_YOGASTATS', 'readable': 'Yoga'},
    {'datatag': 'ACTIVITY_ZUMBASTATS', 'readable': 'Zumba'},
    {'datatag': 'ACTIVITY_ZOUKSTATS', 'readable': 'Zouk'},
];