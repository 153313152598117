import { t } from 'i18next';
import { h } from 'hyperapp';
import { Button, Form, Card } from '@app/elements';

export const ModalViewTwilio = (props, actions) => (
    <Card classes={'btzInformations-btzInformationsModal'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            <div key={props.modalEditing} class={'btzClassMarker-btz'} key={'email'}>
                <Form onsubmit={event => event.preventDefault()}>
                    <div key={props.modalEditing} key={'twilio'}>
                        <div class='thf-twilio-wrapper'>
                            <div id='call-container'>
                                <div id='call-doctor-container' class=''><p>{t('Vous')}</p></div>
                                <div id='call-patient-container' class=''>
                                    <p>{props.patientFName + ' ' + props.patientLName + ', ' + props.patientAge}</p>
                                    <p id='call-patient-container-waiting'>{t('En attente de connexion ...')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Form.Group classes='btzForm-btzFooter'>
                        {props.modalHangup ?
                            <dummy>
                                <Button cancel flat anchorLeft width={'36%'} onclick={(e) => {
                                    props.onTwilioCancelHangup(e);
                                }}>
                                    {t('Annuler')}
                                </Button>
                                <Button flat dangerous anchorRight width={'60%'} onclick={(e) => {
                                    props.onTwilioConfirmHangup(e);
                                }}>
                                    {t('Confirmer')}
                                </Button>
                            </dummy>
                            :
                            <Button flat dangerous onclick={(e) => {
                                props.onTwilioHangup(e);
                            }}>
                                {t('Raccrocher')}
                            </Button>
                        }
                    </Form.Group>
                </Form>
            </div>
        </Card.Header>
    </Card>
);