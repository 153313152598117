import { settings } from '@app/core/settings';
import { handleErrors } from '@app/api/errors';
import { queueQueries } from '@app/api/controlleur';

const { API_URL } = settings;

function getPendingUserLegalAgreement(userType) {
    return queueQueries(`${API_URL}/user-legal-agreement/pending/${userType}/`, 'GET', 'application/json', true, true, true, true, true, null, false, 'doctorConsent');
}
function getLastUserLegalAgreement(userType) {
    return queueQueries(`${API_URL}/user-legal-agreement/current/${userType}/`, 'GET', 'application/json', true, true, true, true, true, null, false, 'doctorConsent');
}
function getLastPublicLegalAgreement(userType) {
    return queueQueries(`${API_URL}/user-legal-agreement/last-public/${userType}/`, 'GET', 'application/json', true, true, true, false, true, null, false, 'doctorConsent');
}
function postUserLegalAgreement(documentId, agreement) {
    return queueQueries(`${API_URL}/user-legal-agreement/agreement/${documentId}/`, 'POST', 'application/json', true, true, true, true, true, {agreement: agreement}, false, 'doctorConsent');
}
function getUserLegalAgreement(id) {
    return queueQueries(`${API_URL}/theraflow/users/${id}/legal-agreement/`, 'GET', 'application/json', true, true, true, true, true, null, false, 'doctorConsent');
}

export const Consent = {
    getPendingUserLegalAgreement,
    getLastPublicLegalAgreement,
    getLastUserLegalAgreement,
    postUserLegalAgreement,
    getUserLegalAgreement,
};