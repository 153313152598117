let loadingQueries = 0;
let loadingDisplayed = false;

function resetQuery() {
    loadingQueries = 0;
    hideLoader();
}

function addQuery() {
    loadingQueries++;
    if (!loadingDisplayed) {
        loadingDisplayed = true;
        displayLoader();
    }
}

function removeQuery(response) {
    loadingQueries--;
    if (+loadingQueries <= 0) {
        loadingQueries = 0;
        loadingDisplayed = false;
        hideLoader();
    }
    return response;
}

function displayLoader() {
    if ((document.getElementById('btzProgressBar') !== undefined)
        && (document.getElementById('btzProgressBar') !== null)) {
        // document.getElementById('btzProgressBar').style.display = 'block';
        // document.getElementById('btzProgressBarOverlay').style.display = 'block';
        document.getElementById('btzProgressBar').classList.add('displayed');
        document.getElementById('btzProgressBarOverlay').classList.add('displayed');
    }
}

function hideLoader() {
    if ((document.getElementById('btzProgressBar') !== undefined)
        && (document.getElementById('btzProgressBar') !== null)) {
        // document.getElementById('btzProgressBar').style.display = 'none';
        // document.getElementById('btzProgressBarOverlay').style.display = 'none';
        document.getElementById('btzProgressBar').classList.remove('displayed');
        document.getElementById('btzProgressBarOverlay').classList.remove('displayed');
    }
}

export const Loader = {
    resetQuery,
    addQuery,
    removeQuery,
};