import { t } from 'i18next'
import { h } from 'hyperapp'
import { Button, Card } from '@app/elements'
import { isDefined } from '@app/core'

import * as rds from '@app/constants/constants-rds'

import icoSend from '@app/img/ico/ico-send-white.png'
import icoSendx2 from '@app/img/ico/ico-send-white@2x.png'
import icoSwitchPhase from '@app/img/ico/ico-phase-switch.png'
import icoSwitchPhasex2 from '@app/img/ico/ico-phase-switch@2x.png'

import icoStart from '@app/img/ico/ico-start.png'
import icoStartx2 from '@app/img/ico/ico-start@2x.png'
import icoStop from '@app/img/ico/ico-stop.png'
import icoStopx2 from '@app/img/ico/ico-stop@2x.png'

export const ModalViewSwitchPhase = (props, actions) => (
    <Card classes={'btzInformations-btzInformationsModal thfModalSwitchPhase'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            <div key={'treatment'}>
                <p class="btzTitle btzXl">{t('Changement de phase ?')}</p>
                <p class="btzSubTitle">
                    {t('Vous souhaitez activer un questionnaire') + ' '}
                    <b>
                        {t(
                            rds.RDSMappingPhase.filter(
                                (f) => f.key === props.medicalStudyEdited.phase
                            )[0].label
                        )}
                    </b>
                    {' ' +
                        t('pour votre Patient.') +
                        ' ' +
                        t('Ceci aura pour effet de remplacer') +
                        ' ' +
                        (props.foundComonPhase.length === 1
                            ? t('le questionnaire actuel')
                            : t('les questionnaires actuels')) +
                        ' '}
                    <b>
                        {t(
                            rds.RDSMappingPhase.filter(
                                (f) => f.key === props.foundComonPhase[0].phase
                            )[0].label
                        )}
                    </b>
                    {' ' + t('par votre nouvelle sélection.')}
                </p>
            </div>
        </Card.Header>
        <Card.Body>
            <div class="thfModalSwitchPhase-wrapper">
                <div
                    class="thfModalSwitchPhase-content"
                    style="vertical-align: top;"
                >
                    <div class="thfModalSwitchPhase-contentImg">
                        <img
                            alt={'Phases communes'}
                            src={
                                rds.RDSMappingPhase.filter(
                                    (f) =>
                                        f.key === props.foundComonPhase[0].phase
                                )[0].img.normal
                            }
                            srcset={`${
                                rds.RDSMappingPhase.filter(
                                    (f) =>
                                        f.key === props.foundComonPhase[0].phase
                                )[0].img.retina
                            } 2x`}
                        />
                        <img
                            alt={'Stop'}
                            src={icoStop}
                            srcset={`${icoStopx2} 2x`}
                        />
                    </div>
                    <div class="">
                        <p class="">
                            {
                                rds.RDSMappingPhase.filter(
                                    (f) =>
                                        f.key === props.foundComonPhase[0].phase
                                )[0].label
                            }
                        </p>
                        {props.foundComonPhase.map((item) => (
                            <p class="">{item.usualName}</p>
                        ))}
                    </div>
                </div>
                <img
                    alt={'Phase switch'}
                    style="vertical-align: super;"
                    src={icoSwitchPhase}
                    srcset={`${icoSwitchPhasex2} 2x`}
                />
                <div class="thfModalSwitchPhase-content">
                    <div class="thfModalSwitchPhase-contentImg">
                        <img
                            alt={'Phases nouvelles'}
                            src={
                                rds.RDSMappingPhase.filter(
                                    (f) =>
                                        f.key === props.medicalStudyEdited.phase
                                )[0].img.normal
                            }
                            srcset={`${
                                rds.RDSMappingPhase.filter(
                                    (f) =>
                                        f.key === props.medicalStudyEdited.phase
                                )[0].img.retina
                            } 2x`}
                        />
                        <img
                            alt={'Commencer'}
                            src={icoStart}
                            srcset={`${icoStartx2} 2x`}
                        />
                    </div>
                    <div class="">
                        <p class="">
                            {
                                rds.RDSMappingPhase.filter(
                                    (f) =>
                                        f.key === props.medicalStudyEdited.phase
                                )[0].label
                            }
                        </p>
                        <p class="">{props.medicalStudyEdited.usualName}</p>
                    </div>
                </div>
            </div>
            <div class="btzCard-btzFooter-btzModal" style="margin-top: 40px;">
                <Button
                    anchorRight
                    flat
                    cancel
                    onclick={(e) => {
                        props.cancelAction(e)
                    }}
                >
                    {t('Annuler')}
                </Button>
                <Button
                    anchorRight
                    flat
                    active
                    onclick={(e) => {
                        props.confirmPhaseSwitching(e)
                    }}
                >
                    <dummy style="color: white;">
                        <img
                            alt={'Envoyer'}
                            style="vertical-align: middle;margin: 0 10px 0 -10px;"
                            src={icoSend}
                            srcset={`${icoSendx2} 2x`}
                        />
                        {t('Changer de phase')}
                    </dummy>
                </Button>
            </div>
        </Card.Body>
    </Card>
)
