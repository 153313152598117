import { h } from 'hyperapp';
import { default as classMerge } from 'classcat';

/**
 * Grid: Global container
 * @param {any} props
 * @param {any} props.classes
 * @param {any} children
 * @example
 * <Grid {...props}>
 *   {children}
 * </Grid>
 */
const Grid = (props, children) => {
    const {
        width = 'xl',
        classes,
        ...otherProps
    } = props;

    const allClasses = classMerge([
        'container',
        `grid-${width}`,
        classes,
    ]);

    return (
        <div class={allClasses} {...otherProps}>
            {children}
        </div >
    );
};

/**
 * Grid: Flexbox powered grid
 * Should be direct child of a Container
 * @param {any} type Row or Oneline
 * @param {any} children
 * @example
 * <Grid.Row type={'gapless'|'oneline'}>
 *    {children}
 * </Grid.Row>
 */
const Row = (props, children) => {
    const {
        type,
        classes,
        ...otherProps
    } = props;

    const allClasses = classMerge([
        'columns',
        type && `col-${type}`,
        classes,
    ]);

    return (
        <div class={allClasses} {...otherProps}>
            {children}
        </div>
    );
};

/**
 * Grid:
 * @param {number} x Varies from 1 to 12
 * @param {any} children
 * @example
 * <Grid.Col size={6}>
 *  {children}
 * </Grid.Col>
 * @todo extend to responsive breakpoint. and offsets...
 */
const Col = (props, children) => {
    const {
        x = '1',
        xl,
        lg,
        md,
        sm,
        xs,
        ml,
        mx,
        mr,
        classes,
        ...otherProps
    } = props;

    const allClasses = classMerge([
        'column',
        `col-${x}`,
        !!xl && `col-xl-${xl}`,
        !!lg && `col-lg-${lg}`,
        !!md && `col-md-${md}`,
        !!sm && `col-sm-${sm}`,
        !!xs && `col-xs-${xs}`,
        !!ml && 'col-ml-auto',
        !!mx && 'col-mx-auto',
        !!mr && 'col-mr-auto',
        classes,
    ]);

    return (
        <div class={allClasses} {...otherProps}>
            {children}
        </div>
    );
};

Grid.Row = Row;
Grid.Col = Col;
export { Grid };
