import { h } from 'hyperapp';
import { Component } from '@app/utils';

import { Grid, Enter } from '@app/elements';
import * as constants from '@app/constants';

import { UserMenu } from '@app/layouts/usermenu';

import './index.scss';

const actions = {
    goBack: () => state => {
        window.history.back();
    }
}

const view = (state, actions) => (props, children) => (
    <div key={'register'} class={props.page}>
        <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
            <Grid classes={'btzWrapper btzWrapperFullHeight btzVerticalAlign'}>
                <Grid.Row classes={'btzWrapper-verticalAlignContent' + (props.howthatwork ? ' btzWrapper-verticalAlignContent-minHeight' : '')}>
                    <Grid.Col x={12} mx={'auto'} style={{ 'position': 'relative', 'height': '100%', 'padding': '0px' }}>
                        <img class='thfCustomerLogo' src={`${process.env.APP_FRONT_WEB_URL}/__logo/${props.customer.toLowerCase()}/logo-blue.png`} srcset={`${process.env.APP_FRONT_WEB_URL}/__logo/${props.customer.toLowerCase()}/logo-blue@2x.png`} alt={props.customer} />
                        <UserMenu display={'login'}></UserMenu>
                        {children}
                    </Grid.Col>
                </Grid.Row>
            </Grid>
        </Enter>
    </div>
);

export default Component({}, actions, view, 'layout');