import { Medical, Alert, Admin, User } from '@app/api'
import { allRoutes, addCSRFToken, isDefined, coreFn, getUser } from '@app/core'

/**
 * @module AlertsListing
 * @description Display listing of alerts, related to activated monitoring modules
 */

export default {
    initAction: function () {
        let AlertsListingRds = {
            onComponentCreate: (props) => (state, actions) => {
                let then = (loggedUser) => {
                    if (loggedUser.readableRole === 'promoter') {
                        actions.initAlertPage()
                    } else {
                        Medical.getMedicalInfos().then((infos) => {
                            let organs = infos.data.organs,
                                parsedOrgans = []
                            for (let organ in organs) {
                                for (let survey in organs[organ]) {
                                    parsedOrgans.push(organs[organ][survey])
                                }
                            }
                            //
                            let availableSurveys =
                                loggedUser?.refHosp?.customerModules
                                    ?.studySettings
                            if (isDefined(availableSurveys)) {
                                availableSurveys = availableSurveys
                                    .filter((f) => f.status)
                                    .map((m) => {
                                        return {
                                            label:
                                                parsedOrgans.filter(
                                                    (f) => f.name === m.name
                                                )[0]?.usualName || m.name,
                                            key: m.name,
                                        }
                                    })
                                actions._setState({
                                    key: 'availableSurveys',
                                    value: availableSurveys,
                                })
                                actions._setState({
                                    key: 'selectedSurvey',
                                    value: availableSurveys.map((m) => m.key),
                                })
                            }
                            //
                            actions.initAlertPage()
                        })
                    }
                }

                getUser(then)
            },
            initAlertPage: () => (state, actions) => {
                User.searchTheraflowUser(0, {}, '').then((res) => {
                    if (isDefined(res.data) && res.data.result.length > 0) {
                        Admin.getInfo().then((info) => {
                            actions.parseInfo(info.data)
                            actions.getPage(true)
                        })
                    } else {
                        actions._setState({ key: 'alerts', value: [] })
                        actions._setState({ key: 'noPatients', value: true })
                        actions.getPage(true)
                    }
                })
            },
            /**
             * Redirect when user click on a table row (to survey tab)
             * @function AlertsListing:redirectToPatient
             * @param {string} surveyid - Id of survey
             */
            redirectToPatient: (surveyid) => (state, actions) => {
                Alert.getSurveyPage(surveyid).then((res) => {
                    let surveyidParam = ''
                    let isMobile = localStorage.getItem('isMobile')
                    if (isMobile == true || isMobile === 'true') {
                        surveyidParam = '&surveyid=' + surveyid
                    }
                    //
                    window.location.href = addCSRFToken(
                        allRoutes['private.patient.edit'].pathname.replace(
                            ':id',
                            res.data.user
                        ) +
                            '?sp=survey&page=' +
                            res.data.page +
                            '&surveyName=' +
                            res.data.name +
                            surveyidParam
                    )
                })
            },
            /**
             * Redirect when user click on a table row
             * @function AlertsListing:parseInfo
             * @param {object} info - Info object, contains informatives data
             */
            parseInfo: (info) => (state, actions) => {
                let parsedInfo = { notAcquitted: 0 }
                for (let studyName in info.countUserMedicalStudySurveyAlertTodo) {
                    if (state.selectedSurvey.indexOf(studyName) > -1) {
                        parsedInfo.notAcquitted +=
                            +info.countUserMedicalStudySurveyAlertTodo[
                                studyName
                            ]
                    }
                }
                actions._setState({ key: 'info', value: parsedInfo })
            },
            /**
             * Sort listing, then call [getPage]{@link module:AlertsListing~AlertsListing:getPage})
             * @function AlertsListing:onSortList
             * @param {string} sortby - The sort key
             */
            onSortList: (sortby) => (state, actions) => {
                let sorting = state.sort,
                    newSorting = { sort: {} }
                if (!isDefined(sorting.sort)) {
                    sorting.sort = {}
                }
                if (isDefined(sorting.sort[sortby])) {
                    if (sorting.sort[sortby] === 'ASC') {
                        newSorting.sort[sortby] = 'DESC'
                    } else {
                        newSorting.sort[sortby] = 'ASC'
                    }
                } else {
                    newSorting.sort[sortby] = 'ASC'
                }
                actions._setState({ key: 'sort', value: newSorting })
                actions.getPage()
            },
            /**
             * Set current page, then call [getPage]{@link module:AlertsListing~AlertsListing:getPage})
             * @function AlertsListing:targetPage
             */
            targetPage: (page) => (state, actions) => {
                actions._setState({ key: 'page', value: page })
                actions.getPage(page)
            },
            /**
             * Update listing page following current page variable
             * @function AlertsListing:getPage
             */
            getPage:
                (initialCall = false) =>
                (state, actions) => {
                    let alertSort = {
                        name: state.selectedSurvey,
                        sort: state.sort.sort,
                    }
                    if (state.selectedDate !== 'null') {
                        alertSort.period = state.selectedDate
                    }
                    if (state.selectedAlertColors !== 'null') {
                        alertSort['alert-colors'] = state.selectedAlertColors
                    }
                    //

                    Medical.getMedicalColoredSurveyAlerts(
                        state.page,
                        alertSort
                    ).then((res) => {
                        if (isDefined(res.data) && !isDefined(res.data.code)) {
                            let totalAlerts = null,
                                alerts = res.data
                            if (isDefined(alerts)) {
                                if (isDefined(alerts.infos)) {
                                    totalAlerts = alerts.infos.count
                                }
                                if (isDefined(alerts.result)) {
                                    alerts = alerts.result
                                }
                            }
                            if (initialCall && alerts.length === 0) {
                                actions._setState({
                                    key: 'noAlertAtAll',
                                    value: true,
                                })
                            }
                            actions._setState({
                                key: 'totalAlerts',
                                value: totalAlerts,
                            })
                            actions._setState({
                                key: 'alerts',
                                value: actions.removeDuplicateAlertKeepHighestColor(
                                    alerts
                                ),
                            })
                        }
                    })
                },
            removeDuplicateAlertKeepHighestColor:
                (alerts) => (state, actions) => {
                    for (let alert in alerts) {
                        alerts[alert].alerts = alerts[alert].alerts.filter(
                            (alertalerts) => {
                                let sameAlerts = alerts[alert].alerts.filter(
                                    (lrt) =>
                                        lrt.evaluationName ===
                                        alertalerts.evaluationName
                                )
                                // found multiple alertalerts with same evaluationName, keeping the higher color rate
                                if (sameAlerts.length > 1) {
                                    let reducedAlerts = sameAlerts.filter(
                                        (f) => alertalerts.color > f.color
                                    )
                                    return isDefined(reducedAlerts[0])
                                }
                                return true
                            }
                        )
                    }
                    return alerts
                },
            /**
             * Parse a patient lname and fname to get a human readable string
             * @param {object} el - object to update
             * @param {object} patient - Patient, containing lname and fname to parse
             * @function AlertsListing:getPatientName
             */
            getPatientName:
                ({ el, patient }) =>
                (state, actions) => {
                    if (isDefined(patient.user)) {
                        patient = patient.user
                    }
                    let patientLName =
                        patient.lname || patient.name.split(' ')[0]
                    let patientFName =
                        patient.fname || patient.name.split(' ')[1]
                    let patientName =
                        patientLName.substring(0, 1).toUpperCase() +
                        patientFName.substring(0, 1).toUpperCase()
                    el.innerHTML = patientName
                },
            /**
             * Increments current page, then call [getPage]{@link module:AlertsListing~AlertsListing:getPage})
             * @function AlertsListing:nextPage
             */
            nextPage: () => (state, actions) => {
                let newPage = state.page + 1
                actions._setState({ key: 'page', value: newPage })
                actions.getPage()
            },
            /**
             * Decrement current page, then call [getPage]{@link module:AlertsListing~AlertsListing:getPage})
             * @function AlertsListing:prevPage
             */
            prevPage: () => (state, actions) => {
                let newPage = state.page - 1
                actions._setState({ key: 'page', value: newPage })
                actions.getPage()
            },
            /**
             * Retrieve form input on the fly from table header survey name filter, then call [getPage]{@link module:AlertsListing~AlertsListing:getPage})
             * @param {input} input - Contain [isvalid], [name], [id] and [values] fields
             * @function AlertsListing:retrieveSurveySort
             */
            retrieveSurveySort: (input) => (state, actions) => {
                const { values } = input
                let newSelectedSurvey = state.selectedSurvey
                if (newSelectedSurvey.indexOf(values) > -1) {
                    newSelectedSurvey.splice(
                        newSelectedSurvey.indexOf(values),
                        1
                    )
                } else {
                    newSelectedSurvey.push(values)
                }
                actions._setState({
                    key: 'selectedSurvey',
                    value: newSelectedSurvey,
                })
                if (newSelectedSurvey.length > 0) {
                    actions.getPage()
                }
            },
            /**
             * Retrieve form input on the fly from table header survey name filter, then call [getPage]{@link module:AlertsListing~AlertsListing:getPage})
             * @param {input} input - Contain [isvalid], [name], [id] and [values] fields
             * @function AlertsListing:retrieveAlertColorsSort
             */
            retrieveAlertColorsSort: (input) => (state, actions) => {
                const { values } = input
                let newSelectedAlertColors = state.selectedAlertColors
                if (newSelectedAlertColors === 'null') {
                    newSelectedAlertColors = []
                }
                if (values === 'null') {
                    newSelectedAlertColors = values
                } else {
                    if (newSelectedAlertColors.indexOf(values) > -1) {
                        newSelectedAlertColors.splice(
                            newSelectedAlertColors.indexOf(values),
                            1
                        )
                    } else {
                        newSelectedAlertColors.push(values)
                    }
                }
                actions._setState({
                    key: 'selectedAlertColors',
                    value: newSelectedAlertColors,
                })
                if (newSelectedAlertColors.length > 0) {
                    actions.getPage()
                }
            },
            /**
             * Retrieve form input on the fly from table header survey name filter, then call [getPage]{@link module:AlertsListing~AlertsListing:getPage})
             * @param {input} input - Contain [isvalid], [name], [id] and [values] fields
             * @function AlertsListing:retrieveDateSort
             */
            retrieveDateSort: (input) => (state, actions) => {
                const { values } = input
                actions._setState({ key: 'selectedDate', value: values })
                actions.getPage()
            },
            /**
             * Display alert mini popup
             * @param {string} id - Id of target alert
             * * @param {string} color - Color of target alert
             * @function AlertscurveListing:retrieveAlertDetail
             */
            retrieveAlertDetail:
                ({ id, color }) =>
                (state, actions) => {
                    if (state.displayedPopup === id + '-' + color) {
                        return false
                    }
                    //
                    actions._setState({
                        key: 'displayedPopup',
                        value: id + '-' + color,
                    })
                    Medical.getMedicalColoredSurveyAlert(id).then((res) => {
                        actions._setState({
                            key: 'alertDetail',
                            value: res.data,
                        })
                    })
                },
        }
        AlertsListingRds = { ...coreFn, ...AlertsListingRds }
        return AlertsListingRds
    },
}
