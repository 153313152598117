/*
Settings that are responsible for application' configuration,
and can be overriden per item basis by using the corresponding
variable environment, e.g.:
`API_URL="https://domain.tld" yarn start`
*/

export { dynLoad } from './dyn-load';
export { coreFn } from './coreFn';
export { clientErrorsHandler } from './clientErrorsHandler';
export { iePolyfills } from './iePolyfills';

export { $, getClosest, getMinHourFromMinutes, getQueryVariable, getReadableDate, closest, getDaysInWeek, getDaysInMonth, isObject } from './tools';
export { createCookie, readCookie, eraseCookie, scrollTo, isEmail, getAge, redirectToGoodIndex, definePatientSession } from './tools';
export { convertMetrics, convertMetricsLabels, getUser, setUser, logoutAndRedirect, getNextSibling, getPreviousSibling } from './tools';
export { capitalize, compareByKey, trimSpecialChar, castToNumberOfFloat, addZero, getDiffDate, parseJsonObjectToString, parseStringToJsonObj } from './tools';
export { isMobile, isMobileOrTablet, redirectToWebOrApp, isValidDateYMDObj, castToBool, guid, isValidDate } from './tools';
export { addCSRFToken, checkForValidCSRFToken, createAndSaveCSRFToken, createDateComplientSafari, arrayRange, isAuthorizedRole } from './tools';
export { getDateObjFromYMD, getYMDfromDateObj, isDefined, createUTCDate, getSupport, ensureNIRValidity, getHash } from './tools';
export { isInteger, isFloat, fromNow, deepCopy, valueCopy, createQueryMessage, sortObject, sortObjectBy, isObjEmpty, ensurePasswordStrength, parseMobileLeadingPlus, parseMobileLeadingZero } from './tools';

export { allRoutes } from './routes';
export { settings } from './settings';
