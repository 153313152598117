import { h } from 'hyperapp'
import { Component } from '@app/utils'
import { allRoutes, addCSRFToken, isDefined } from '@app/core'
import { Link } from '@app/elements'
import * as constants from '@app/constants'

const TopLogoView = Component(
    {
        customer: 'Theraflow',
        customerLogo: 'Theraflow',
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            actions.onComponentUpdate(props)
        },
        onComponentUpdate: (props) => (state, actions) => {
            let customer = state.customer
            if (props.customer) {
                customer = props.customer
            } else {
                customer = process.env.APP_CUSTOMISATION.toLowerCase()
            }
            actions.setCustomer(customer)

            let ccustomer = constants.custo.CUSTOMERS[customer]
            if (isDefined(ccustomer)) {
                actions.setCustomerLogo(ccustomer.logo)
            }
        },
        setCustomer: (newState) => (state) => ({
            customer: newState,
        }),
        setCustomerLogo: (newState) => (state) => ({
            customerLogo: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <ul id="btzMainTopLogo">
            <li style={{ display: 'block' }}>
                <Link
                    class={'btn btn-link'}
                    to={addCSRFToken(
                        isDefined(localStorage.getItem('apitoken'))
                            ? allRoutes['private.patients.listing'].pathname
                            : allRoutes['index'].pathname
                    )}
                    style={{ opacity: 1 }}
                >
                    <img
                        src={`${process.env.APP_FRONT_WEB_URL}/__logo/${state.customer}/logo-white.png`}
                        srcset={`${process.env.APP_FRONT_WEB_URL}/__logo/${state.customer}/logo-white@2x.png`}
                        alt={state.customer}
                    />
                </Link>
            </li>
        </ul>
    ),
    'toplogoview'
)

export { TopLogoView }
