import { t } from 'i18next';
import { h } from 'hyperapp';
import { Button, Card } from '@app/elements';
import { isDefined } from '@app/core';

import * as rds from '@app/constants/constants-rds';
import * as constants from '@app/constants';

export const ModalViewSurveyPreview = (props, actions) => (
    <Card classes={'btzInformations-btzInformationsModal'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            <div key={props.modalEditing} key={'treatment'}>
                {isDefined(props.cardsContentPreview) &&
                    <p class='btzAboveTitle' style='background-color: white;padding-left: 20px !important;'>{t(rds.RDSMappingPhase.filter((f) => f.key === props.cardsContentPreview.phase)?.[0]?.label)}</p>
                }
                <p class='btzTitle btzXl'>{props.cardsContentPreview.usualName}</p>
                <p class='btzSubTitle'>
                    <span><font>{t('Indication clinique')} : </font>{props.cardsContentPreview.clinicalIndication}</span>
                    <span><font>{t('Fréquence recommandée')} : </font>{props.cardsContentPreview.frequencyAvailable?.map((m) => constants.custo.periodFrequenciesPeriods.filter((f) => f.key === m)?.[0]?.label)?.[0] || 'Ø'}.</span>
                    <span><font>{t('Nombre de questions')} : {(isDefined(props.cardsContentPreview.cardsContent) ? props.cardsContentPreview.cardsContent.filter((f) => !isDefined(f.parent)).length : '0')}</font></span>
                </p>
            </div>
        </Card.Header>
        <Card.Body>
            <div class='' style='padding: 0 10px;'>
                {isDefined(props.cardsContentPreview.cardsContent) ?
                    <table class='thfTable thfTableSurveyPreview'>
                        <tbody>
                            {props.cardsContentPreview.cardsContent.map((card) =>
                                <tr class=''>
                                    <td class='question'>
                                        <font>{card.idx}</font><p>{card.label}</p>
                                    </td>
                                    <td class='answers'>
                                        {(['slider', 'counter', 'statcounter', 'cryptedweight', 'bloodpressure', 'heartbeat', 'cryptedtemperature'].indexOf(card.type.toLowerCase()) > -1) &&
                                            <ul>
                                                <li>{t('Saisie numérique')}</li>
                                            </ul>
                                        }
                                        {(card.type.toLowerCase() === 'textarea') &&
                                            <ul>
                                                <li>{t('Saisie libre')}</li>
                                            </ul>
                                        }
                                        {(['button'].indexOf(card.type.toLowerCase()) > -1) &&
                                            <ul>
                                                {card.answers.map((a) =>
                                                    <li>{a}</li>
                                                )}
                                            </ul>
                                        }
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    :
                    <p class='' style='padding: 15px 0 0 15px'>{t('Aucune carte éligible.')}</p>
                }
            </div>
            <div class='btzCard-btzFooter-btzModal' style='margin-top: 40px;'>
                <Button anchorRight flat cancel onclick={(e) => {
                    props.cancelAction(e);
                }}>
                    {t('Fermer')}
                </Button>
            </div>
        </Card.Body>
    </Card>
);