export const state = {
    values: {
        fname: '',
        lname: '',
        bname: '',
        email: '',
        birthdate: {
            year: '',
            month: '',
            day: '',
        },
        hospitalId: '',
        socialSecurityNumber: '',
        inclusionNumber: '',
        gender: 0,
        etapes: false,
        phone: ['', '', '', '', ''],
        phonePrefix: '',
        pathology: '',
        pathologiesType: [],
        doctor: '',
        coach: '',
        nurses: [],
        locale: null,
        timezone: '',
    },
    disclamerAutoBan: false,
    disclamerRightLoosed: { doctor: false, nurses: false, coach: false },
    medicalValues: null,
    medicalAddedValues: {},
    medicalRemovedValues: {},
    isTouched: {
        fname: false,
        lname: false,
        bname: false,
        email: false,
        birthdate: false,
        hospitalId: false,
        socialSecurityNumber: false,
        inclusionNumber: false,
        gender: true,
        etapes: true,
        phone: false,
        phonePrefix: false,
        pathology: false,
        pathologiesType: false,
        doctor: false,
        coach: false,
        nurses: false,
        locale: true,
        timezone: true,
    },
    isValid: {
        fname: false,
        lname: false,
        bname: false,
        email: false,
        birthdate: false,
        hospitalId: false,
        socialSecurityNumber: false,
        inclusionNumber: false,
        gender: true,
        etapes: true,
        phone: false,
        phonePrefix: false,
        pathology: false,
        pathologiesType: false,
        doctor: false,
        coach: false,
        nurses: false,
        locale: true,
        timezone: true,
    },
    errorMessages: {
        fname: '',
        lname: '',
        bname: '',
        email: '',
        birthdate: '',
        hospitalId: '',
        socialSecurityNumber: '',
        inclusionNumber: '',
        gender: '',
        etapes: '',
        phone: '',
        phonePrefix: '',
        pathology: '',
        pathologiesType: '',
        doctor: '',
        coach: '',
        nurses: '',
        locale: '',
        timezone: '',
    },
    isTyping: {
        fname: false,
        lname: false,
        bname: false,
        email: false,
        birthdate: false,
        hospitalId: false,
        socialSecurityNumber: false,
        inclusionNumber: false,
        gender: false,
        etapes: false,
        phone: false,
        phonePrefix: false,
        pathology: false,
        pathologiesType: false,
        doctor: false,
        coach: false,
        nurses: false,
        locale: false,
        timezone: false,
    },
    api: {
        message: '',
        code: 200,
        kind: 'info',
    },
    doctor: null,
    doctorRole: null,
    patientid: null,
    editedPatient: null,
    consents: [],
    hospitals: [],
    timezones: [],
    referenceHealTeam: [],
    referenceCoachTeam: [],
    referenceHospital: null,
    referenceCustomer: '',
    customer: '',
    formIsSubmitting: false,
    formSubmittedOnce: false,
    sendToParent: null,
    updatePatient: null,
    loaded: false,
    medicalTeamUpdateConfirm: false,
    atLeastOneModification: false,
    setAtLeastOneModification: null,
    parseHospitalHealTeam: null,
    availableQRCode: true,
    lifenUser: null,
    previewLifenSynch: false,
    needLifenSynch: false,
}
