import { t } from 'i18next';
import { h } from 'hyperapp';
import { Component } from '@app/utils';
import { getSupport, allRoutes, addCSRFToken, isDefined } from '@app/core';
import './index.scss';


const BotFooterView = Component(
    {
        customer: 'betterise',
        displayed: true,
        urlPdfCgu: '',
        urlPdfCga: '',
        contactMail: 'alfred@betterise.me',
        allRightReserved: 'Betterise Technologies',
        todayYear: 'Ø',
        startYear: '2019',
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            let d = new Date();
            actions.setTodayYear(d.getFullYear());
            actions.setSupport(getSupport());
        },
        redirectToMention: () => (state, actions) => {
            let sessionUser = sessionStorage.getItem('adminuser');
            if (isDefined(sessionUser)) {
                sessionUser = JSON.parse(sessionUser);
                if (isDefined(sessionUser.id)) {
                    window.location.href = addCSRFToken(allRoutes['private.mentions'].pathname);
                    return false;
                }
            }
            window.location.href = addCSRFToken(allRoutes['index.mentions'].pathname);
        },
        setSupport: newState => state => ({
            support: newState
        }),
        setAllRightReserved: newState => state => ({
            allRightReserved: newState
        }),
        setTodayYear: newState => state => ({
            todayYear: newState
        }),
    },
    (state, actions) => (props, children) => (
        <div class='btzFooter-btzFooters'>
            {/*<p class='btzFooter-btzFooter' style='display: inline-block'>{t('Tous droits réservés') + ' ' + state.allRightReserved + ' ' + state.startYear + ' - ' + state.todayYear + ' -'}</p>*/}
            <a class='btn-link' style='display: inline-block;margin-left: 5px;' href={'#'} onclick={actions.redirectToMention}>{t('Mentions légales')}</a>{' - '}
            <p class='btzFooter-btzFooter ovr-lightgrey-colored' style='display: inline-block'>{t('Un problème ?')}</p>
            <a class='btn-link' style='display: inline-block;margin-left: 5px;' href={'mailto:' + state.support}>{state.support}</a>
        </div>
    ),
    'botfooterview'
);

export { BotFooterView };