export const state = {
    patientid: null,
    etp: [],
    programs: [],
    originalPrograms: [],
    availablePrograms: [],
    loaded: false,
    editedPatient: null,
    atLeastOneModification: false,
    setAtLeastOneModification: null,
};
