import { Medical } from '@app/api';
import { enableTheseButton, desableTheseButton } from '@app/core';
import { isDefined, getDateObjFromYMD, getYMDfromDateObj, coreFn } from '@app/core';

/**
 * @module PatientEditConfiguration
 * @description Patient configuration edition
 */

export default {
    initAction: function() {
        let PatientEditConfiguration = {
            onComponentCreate: (props) => (state, actions) => {
                actions._setState({key: 'patientid', value: props.patientid});
                actions.getMedicalStudies(props.patientid);
            },
            /**
            * Get patient medical studies
            * @param {string} patientid - Id of patient
            * @function PatientEditConfiguration:getMedicalStudies
            */
            getMedicalStudies: (patientid) => (state, actions) => {
                Medical.getStudy(patientid).then((medicalStudies) => {
                    actions._setState({key: 'medicalStudies', value: medicalStudies.data});
                    actions._setState({key: 'loaded', value: true});
                })
            },
            /**
            * Update current edited study
            * @param {string} field - Name of field
            * @param {string} value - Value of field
            * @function PatientEditConfiguration:updateToCreateStudy
            */
            updateToCreateStudy: ({field, value}) => (state, actions) => {
                let newStudy = state.newStudy;
                newStudy[field] = value;
                actions._setState({key: 'newStudy', value: newStudy});
            },
            /**
            * Save current selected study
            * @function PatientEditConfiguration:onSaveStudy
            */
            onSaveStudy: () => (state, actions) => {
                if (state.formIsSubmitting) {
                    return false;
                }
                actions._setState({key: 'formIsSubmitting', value: true});
                const { name, frequency, issuePeriod } = state.newStudy;
                let today = getYMDfromDateObj();
                today = getDateObjFromYMD((today.year + '' + today.month + '' + today.day), 'now', true);
                let data = {
                    frequency: frequency,
                    issuePeriod: issuePeriod,
                    name: name,
                    startDate: today,
                    status: true,
                };
                Medical.sendStudy(state.patientid, data).then(() => {
                    actions._setState({key: 'formIsSubmitting', value: false});
                    actions.getMedicalStudies(state.patientid);
                });
            },
            /**
            * Update current selected study period
            * @param {string} studyid - Id of study
            * @param {string} issuePeriod - Period of study
            * @function PatientEditConfiguration:updateStudy
            */
            updateStudy: ({studyid, issuePeriod}) => (state, actions) => {
                if (state.formIsSubmitting) {
                    return false;
                }
                actions._setState({key: 'formIsSubmitting', value: true});
                let toEditStudy = null;
                for (var study in state.medicalStudies) {
                    if (state.medicalStudies[study].id === studyid) {
                        toEditStudy = state.medicalStudies[study];
                    }
                }
                if (!isDefined(toEditStudy)) {
                    return false;
                }
                let data = {
                    frequency: toEditStudy.frequency,
                    issuePeriod: issuePeriod,
                    name: toEditStudy.name,
                    startDate: (toEditStudy.startDate || toEditStudy.date),
                    status: toEditStudy.status,
                };
                Medical.updateStudy(state.patientid, studyid, data).then(() => {
                    actions._setState({key: 'formIsSubmitting', value: false});
                    actions.getMedicalStudies(state.patientid);
                });
            },
            /**
            * Update current selected study status
            * @param {string} studyid - Id of study
            * @param {boolean} status - Status of study (activated/deactivated)
            * @function PatientEditConfiguration:onSwitchStudySend
            */
            onSwitchStudySend: ({studyid, status}) => (state, actions) => {
                if (state.formIsSubmitting) {
                    return false;
                }
                actions._setState({key: 'formIsSubmitting', value: true});
                let toEditStudy = null;
                for (var study in state.medicalStudies) {
                    if (state.medicalStudies[study].id === studyid) {
                        toEditStudy = state.medicalStudies[study];
                    }
                }
                if (!isDefined(toEditStudy)) {
                    return false;
                }
                let data = {
                    frequency: toEditStudy.frequency,
                    issuePeriod: toEditStudy.issuePeriod,
                    name: toEditStudy.name,
                    startDate: (toEditStudy.startDate || toEditStudy.date),
                    status: status,
                };
                Medical.updateStudy(state.patientid, studyid, data).then(() => {
                    actions._setState({key: 'formIsSubmitting', value: false});
                    actions.getMedicalStudies(state.patientid);
                });
            },
        }
        PatientEditConfiguration = {...coreFn, ...PatientEditConfiguration};
        return PatientEditConfiguration;
    }
}