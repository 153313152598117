import { t } from 'i18next';
import { h } from 'hyperapp';
import { isDefined, getReadableDate, createUTCDate } from '@app/core';
import { Card, Button, Tile, Enter, Modal } from '@app/elements';
import * as constants from '@app/constants';

import './../index.scss';

import { ModalViewTreatment } from './modals/modalView-treatment-default';

export default {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div class='patient-central'>
                <Card classes={'btzPageCard btzBackgroundCover'} kind={'datas'} style={{ 'margin': '30px 30px 0px 30px' }}>
                    <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                        <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
                            <dummy>
                                <Tile>
                                    <Tile.Content>
                                        <div class=''>
                                            <div class='thfTreatment-thfTreatmentBlock-thfTreatmentWrapper'>
                                                <p class='btzTitle btzXl'>
                                                    {t('Traitements prescrits') + ' (' + state.treatments.running.length + ')'}
                                                </p>
                                                <div class='thfTreatment-thfTreatmentBlocks thfTreatment-thfTreatmentBlocksRunning'>
                                                    {state.treatments.running.length > 0 &&
                                                        <dummy>
                                                            {state.treatments.running.map((item) =>
                                                                <div class='col-6 col-xs-12' style='vertical-align: top;'>
                                                                    <div class='thfTreatment-thfTreatmentBlock'>
                                                                        <div class='thfTreatment-thfTreatmentBlock-header'>
                                                                            <p class='thfTreatment-thfTreatmentBlock-headerTitle'>{item.name}</p>
                                                                            {(item.treatStatus === 'running') ?
                                                                                <p class='thfColored-green' style='font-size: 14px;font-weight: bold;'>{t('en cours')}</p>
                                                                                :
                                                                                <p class='thfColored-yellow' style='font-size: 14px;font-weight: bold;'>{t('à venir')}</p>
                                                                            }
                                                                            <p key={item.id + '-listings'} class='thfTreatment-thfTreatmentBlock-headerSubTitle' style='font-weight: bold;' oncreate={(e) => { actions.getReadableFrequency({el: e, item: item}); }} onupdate={(e) => { actions.getReadableFrequency({el: e, item: item}); }}></p>
                                                                            <p class='thfTreatment-thfTreatmentBlock-headerSubTitle'><b>{t('Début') + ' : '}</b>{getReadableDate(createUTCDate(item.start.date), 'DD MMMM YYYY')}</p>
                                                                            <p class='thfTreatment-thfTreatmentBlock-headerSubTitle'><b>{t('Fin') + ' : '}</b>{(!isDefined(item.end) ? t('Date de fin non définie') : getReadableDate(createUTCDate(item.end.date), 'DD MMMM YYYY'))}</p>
                                                                        </div>
                                                                        <div class='thfTreatment-thfTreatmentBlock-modal'>
                                                                            <Modal key={'modal-treatment-configuration'} cancelAction={() => { actions.setModalDisplayed(false); }} modalDisplayed={(state.modalDisplayed && (state.modalEditing.indexOf('treatment') > -1) && (state.currentTreatment.id === item.id))} content={<ModalViewTreatment displayed={'treatments'} {...state} retrieveInput={actions.retrieveInput} retrieveInputPrescription={actions.retrieveInputPrescription} onSubmit={actions.onSubmit} cancelAction={() => { actions.setModalDisplayed(false) }} getMedicine={actions.getMedicine} nextTreatmentStep={actions.nextTreatmentStep} setState={actions._setState} addPrescription={actions.addPrescription} removePrescription={actions.removePrescription} getReadableFrequency={actions.getReadableFrequency} rollBackValuesWithPreviousValues={actions.rollBackValuesWithPreviousValues} onEditStatusTreatmentCancel={actions.onEditStatusTreatmentCancel} onEditStatusTreatment={actions.onEditStatusTreatment} loaded={true}></ModalViewTreatment>}>
                                                                                <div onclick={() => {
                                                                                    actions.onEditTreatment(item.id);
                                                                                }}>
                                                                                    <Button loading={(state.formIsSubmitting)} flat>
                                                                                        {t('Configurer')}
                                                                                    </Button>
                                                                                </div>
                                                                            </Modal>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </dummy>
                                                    }
                                                    <div class='col-6 col-xs-12' style='vertical-align: top;'>
                                                        <div class='thfTreatment-thfTreatmentBlock-add'>
                                                            <p class='thfTreatment-thfTreatmentBlock-addTitle' style='margin-bottom: 25px'>{t('Ajouter un nouveau médicament')}</p>
                                                            <div class='thfTreatment-thfTreatmentBlock-modal'>
                                                                <Modal key={'modal-treatment-configuration'} cancelAction={() => { actions.setModalDisplayed(false); }} modalDisplayed={(state.modalDisplayed && (state.modalEditing.indexOf('newtreatment') > -1))} content={<ModalViewTreatment displayed={'newtreatments'} {...state} retrieveInput={actions.retrieveInput} retrieveInputPrescription={actions.retrieveInputPrescription} onSubmit={actions.onSubmit} cancelAction={() => { actions.setModalDisplayed(false) }} getMedicine={actions.getMedicine} nextTreatmentStep={actions.nextTreatmentStep} setState={actions._setState} addPrescription={actions.addPrescription} removePrescription={actions.removePrescription} getReadableFrequency={actions.getReadableFrequency} rollBackValuesWithPreviousValues={actions.rollBackValuesWithPreviousValues}  onEditStatusTreatmentCancel={actions.onEditStatusTreatmentCancel} onEditStatusTreatment={actions.onEditStatusTreatment} loaded={true}></ModalViewTreatment>}>
                                                                    <div style='text-align: center;' onclick={() => {
                                                                        actions.onEditTreatment(null);
                                                                    }}>
                                                                        <Button loading={(state.formIsSubmitting)} flat>
                                                                            {t('Ajouter un traitement')}
                                                                        </Button>
                                                                    </div>
                                                                </Modal>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='thfTreatment-thfTreatmentBlock-thfTreatmentWrapper'>
                                                <p class='btzTitle btzXl'>
                                                    {t('Traitements terminés / arrétés') + ' (' + state.treatments.ended.length + ')'}
                                                </p>
                                                <div class='thfTreatment-thfTreatmentBlocks thfTreatment-thfTreatmentBlocksEnded'>
                                                    {state.treatments.ended.length > 0 ?
                                                        <dummy>
                                                            {state.treatments.ended.map((item) =>
                                                                <div class='col-6 col-xs-12'>
                                                                    <div class='thfTreatment-thfTreatmentBlock'>
                                                                        <div class='thfTreatment-thfTreatmentBlock-header'>
                                                                            <p class='thfTreatment-thfTreatmentBlock-headerTitle'>{item.name}</p>
                                                                            <p class='thfTreatment-thfTreatmentBlock-headerSubTitle thfColored-yellow' style='font-size: 14px;font-weight: bold;'>{t('arrêté')}</p>
                                                                            <p class='thfTreatment-thfTreatmentBlock-headerSubTitle' style='font-weight: bold;' oncreate={(e) => { actions.getReadableFrequency({el: e, item: item}); }}></p>
                                                                            <p class='thfTreatment-thfTreatmentBlock-headerSubTitle'><b>{t('Début') + ' : '}</b>{getReadableDate(createUTCDate(item.start.date), 'DD MMMM YYYY')}</p>
                                                                            <p class='thfTreatment-thfTreatmentBlock-headerSubTitle'><b>{t('Fin') + ' : '}</b>{(!isDefined(item.end) ? t('Date de fin non définie') : getReadableDate(createUTCDate(item.end.date), 'DD MMMM YYYY'))}</p>
                                                                        </div>
                                                                        <div class='thfTreatment-thfTreatmentBlock-modal'>
                                                                            <Modal key={'modal-treatment-configuration'} cancelAction={() => { actions.setModalDisplayed(false); }} modalDisplayed={(state.modalDisplayed && (state.modalEditing.indexOf('treatment') > -1) && (state.currentTreatment.name === item.name))} content={<ModalViewTreatment displayed={'treatments'} {...state} retrieveInput={actions.retrieveInput} retrieveInputPrescription={actions.retrieveInputPrescription} onSubmit={actions.onSubmit} cancelAction={() => { actions.setModalDisplayed(false) }} getMedicine={actions.getMedicine} nextTreatmentStep={actions.nextTreatmentStep} setState={actions._setState} addPrescription={actions.addPrescription} removePrescription={actions.removePrescription} getReadableFrequency={actions.getReadableFrequency} rollBackValuesWithPreviousValues={actions.rollBackValuesWithPreviousValues} onEditStatusTreatmentCancel={actions.onEditStatusTreatmentCancel} onEditStatusTreatment={actions.onEditStatusTreatment} loaded={true}></ModalViewTreatment>}>
                                                                                <div onclick={() => {
                                                                                    actions.onEditTreatment(item.id);
                                                                                }}>
                                                                                    <Button loading={(state.formIsSubmitting)} flat>
                                                                                        {t('Configurer')}
                                                                                    </Button>
                                                                                </div>
                                                                            </Modal>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </dummy>
                                                        :
                                                        <div class='col-6 col-xs-12'>
                                                            <div class='thfTreatment-thfTreatmentBlock-empty'>
                                                                <p class='thfTreatment-thfTreatmentBlock-emptyTitle'>{t('Aucun médicament terminé / arrêté')}</p>
                                                                <p class='thfTreatment-thfTreatmentBlock-emptySubTitle'>{t('Aucun traitement de votre patient n’est arrivé à échéance ou arrêté volotairement.')}</p>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Tile.Content>
                                </Tile>
                            </dummy>
                        </Enter>
                    </Card.Header>
                </Card>
            </div>
        );
        return view;
    }
}