import { h } from 'hyperapp';
import { default as classMerge } from 'classcat';

const Progress = (props, children) => {
    const {
        max,
        value,
        classes,
        ...otherProps
    } = props;

    const allClasses = classMerge([
        'progress',
        classes,
    ]);

    return (
        <progress class={allClasses} value={value} max={max} {...otherProps}>
            {children}
        </progress>
    );
};

export { Progress };
