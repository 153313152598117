import { h } from 'hyperapp';
import { Component } from '@app/utils';
import './index.scss';

const ScrollTo = Component(
    {
        target: null,
    },
    {
        onComponentCreate: (props) => (state) => {
            if (props.target) {
                state.target = props.target;
            }
        },
        onScrollTo: () => (state, actions) => {
            if (state.target === null) {
                return false;
            }
            let targetObj = document.getElementById(state.target);
            if (!targetObj) {
                return false;
            }
        },
    },
    (state, actions) => (props, children) => (
        <div class='btzScrollTo' data-target={state.target} onclick={() => { actions.onScrollTo() }}>
            {children}
        </div>
    ),
    'scrollto'
);

export { ScrollTo };
