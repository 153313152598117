import { t } from 'i18next';
import { h } from 'hyperapp';
import { Card, Button, Form, FormInput, Tile, Enter } from '@app/elements';
import * as constants from '@app/constants';

// import icoNoPatients from '@app/img/deco/img-no-patient.png';
// import icoNoPatientsx2 from '@app/img/deco/img-no-patient@2x.png';

export default {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div class='login-central'>
                <Card classes={'btzPageCard btzBackgroundCover'} kind={'datas'}>
                    <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                        <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
                            {((state.steps.password === true) && (state.registerStep === 'password')) &&
                                <dummy>
                                    <div class='thfProgressBarWrapper'><div class='thfProgressBar' style='width: 5%'></div></div>
                                    <Tile>
                                        <Tile.Content>
                                            <div class='loginHeader'>
                                                <p class='btzTitle btzXl'>{t('Inscrivez-vous.')}</p>
                                                <p class='btzSubTitle'>{t('Pour finaliser la création de votre compte,')}</p>
                                                <p class='btzSubTitle' style='margin: 0px;'>{t('définissez votre mot de passe.')}</p>
                                                <p class='thfInfoBlock'>{state.values.email}</p>
                                            </div>
                                        </Tile.Content>
                                    </Tile>

                                    <Form onsubmit={event => event.preventDefault()}>
                                        <button type='submit' disabled style='display: none' aria-hidden='true'></button>
                                        <Form.Group>
                                            <p class='btzForm-btzLabel'>{t('Votre mot de passe')}</p>
                                            <FormInput
                                                placeholder={t('le mot de passe de votre choix')}
                                                key='input-password'
                                                type='password'
                                                id='password'
                                                name='password'
                                                valueType='password'
                                                value={state.values.password}
                                                isValid={(state.formSubmittedOnce ? state.isValid.password : 'is-default')}
                                                validation={true}
                                                showPasswordButton={true}
                                                activeSpecialValidation={true}
                                                infoBlockAnchor={'right'}
                                                oninputcb={actions._retrieveInput}
                                                onblurcb={actions._retrieveErrorMessageDoctor}
                                                formSubmittedOnce={state.formSubmittedOnce}
                                                errorMessage={state.errorMessages.password}
                                                isTyping={state.isTyping.password}
                                                required
                                            />
                                            <p class='btzForm-btzLabel'>{t('Confirmer votre mot de passe')}</p>
                                            <FormInput
                                                placeholder={t('le mot de passe de votre choix')}
                                                key='input-passwordconfirm'
                                                type='password'
                                                id='passwordconfirm'
                                                name='passwordconfirm'
                                                valueType='password'
                                                value={state.values.passwordconfirm}
                                                validation={true}
                                                showPasswordButton={true}
                                                mustBeEqualTo={state.values.password}
                                                oninputcb={actions._retrieveInput}
                                                onblurcb={actions._retrieveErrorMessageDoctor}
                                                formSubmittedOnce={state.formSubmittedOnce}
                                                errorMessage={state.errorMessages.passwordconfirm}
                                                isTyping={state.isTyping.passwordconfirm}
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group classes='btzForm-btzFooter'>
                                            <Button primary loading={(state.formIsSubmitting)} active={state.isValid.passwordconfirm && state.isValid.password && (state.values.password === state.values.passwordconfirm)} onclick={actions.onSubmitPassword}>
                                                {t('Étape suivante')}
                                            </Button>
                                        </Form.Group>

                                        <div class='' style='text-align: center;'>
                                            <p class='ovr-lightgrey-colored' style='text-align: center !important;'>{t('Vous avez déjà un compte ?')}<a class='btn-link' style='display: inline-block;margin-left: 5px;' href={'#'} onclick={actions.gotoLogin}>{t('Connectez-vous')}</a></p>
                                        </div>
                                    </Form>
                                </dummy>
                            }
                            {((state.steps.code === true) && (state.registerStep === 'code')) &&
                                <dummy>
                                    <div class='thfProgressBarWrapper'><div class='thfProgressBar' style='width: 50%'></div></div>
                                    <Tile>
                                        <Tile.Content>
                                            <div class='loginHeader'>
                                                <p class='btzTitle btzXl'>{t('Confirmez votre identité.')}</p>
                                                <p class='btzSubTitle'>{t('Merci de saisir le code à 6 chiffres qui a été envoyé par SMS.')}</p>
                                                <p class='thfInfoBlock'>{state.values.phone[0] + '.xx.xx.' + state.values.phone[3] + '.' + state.values.phone[4]}</p>
                                            </div>
                                        </Tile.Content>
                                    </Tile>
                                    <Form onsubmit={event => { event.preventDefault(); event.stopPropagation(); }}>
                                        <button type='submit' disabled style='display: none' aria-hidden='true'></button>
                                        <Form.Group>
                                            <p class='btzForm-btzLabel'>{t('Code à 6 chiffres')}</p>
                                            <FormInput
                                                placeholder={['-', '-', '-', '-', '-', '-']}
                                                value={state.values.code}
                                                type='code'
                                                id='code'
                                                name='code'
                                                valueType='code'
                                                isValid={(state.formSubmittedOnce ? state.isValid.code : 'is-default')}
                                                validation={true}
                                                isInteger={true}
                                                dynFocus={true}
                                                oninputcb={actions._retrieveInput}
                                                onblurcb={actions._retrieveErrorMessageDoctor}
                                                formSubmittedOnce={state.formSubmittedOnce}
                                                errorMessage={state.errorMessages.code}
                                                isTyping={state.isTyping.code}
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group classes='btzForm-btzFooter'>
                                            <Button loading={(state.formIsSubmitting)} width={'35%'} flat cancel anchorLeft onclick={() => { actions._setState({key: 'registerStep', value: 'password'}); }}>
                                                {t('Retour')}
                                            </Button>
                                            <Button loading={(state.formIsSubmitting)} width={'60%'} active={state.isValid.code} anchorRight onclick={actions.onSubmitCode}>
                                                {t('Étape suivante')}
                                            </Button>
                                        </Form.Group>
                                    </Form>

                                    <div class='' style='text-align: center;'>
                                        <p class='ovr-lightgrey-colored' style='white-space: nowrap;'>{t('Vous n\'avez pas reçu le code ?')}<a class='btn-link' style='display: inline-block;margin-left: 5px;' href={'#'} onclick={actions.onSubmitPassword}>{t('Me renvoyer le code')}</a></p>
                                    </div>
                                </dummy>
                            }
                            {((state.steps.secret === true) && (state.registerStep === 'secret')) &&
                                <dummy>
                                    <div class='thfProgressBarWrapper'><div class='thfProgressBar' style='width: 95%'></div></div>
                                    <Tile>
                                        <Tile.Content>
                                            <div class='loginHeader'>
                                                <p class='btzTitle btzXl'>{t('Question secrète.')}</p>
                                                <p class='btzSubTitle'>{t('Merci de choisir une question secrète puis renseignez la réponse associée que vous seul connaissez.')}</p>
                                            </div>
                                        </Tile.Content>
                                    </Tile>

                                    <Form onsubmit={event => { event.preventDefault(); event.stopPropagation(); }}>
                                        <button type='submit' disabled style='display: none' aria-hidden='true'></button>
                                        <Form.Group>
                                            <p class='btzForm-btzLabel'>{t('Choix de votre question secrète')}</p>
                                            <FormInput
                                                key='input-question'
                                                type='dropdown'
                                                id='question'
                                                name='question'
                                                value={state.values.question}
                                                list={constants.custo.secretQuestions}
                                                validation={true}
                                                defaultSelected={0}
                                                loaded={state.loaded}
                                                oninputcb={actions._retrieveInput}
                                                onblurcb={actions._retrieveErrorMessageDoctor}
                                                formSubmittedOnce={state.formSubmittedOnce}
                                                errorMessage={state.errorMessages.question}
                                                isTyping={state.isTyping.question}
                                                required
                                            />
                                            <p class='btzForm-btzLabel'>{t('Votre réponse secrète')}</p>
                                            <FormInput
                                                placeholder={t('la réponse de votre choix')}
                                                key='input-secret'
                                                type='text'
                                                id='secret'
                                                name='secret'
                                                value={state.values.secret}
                                                validation={true}
                                                minlength={5}
                                                oninputcb={actions._retrieveInput}
                                                onblurcb={actions._retrieveErrorMessageDoctor}
                                                formSubmittedOnce={state.formSubmittedOnce}
                                                errorMessage={(state.formSubmittedOnce ? state.errorMessages.secret : '')}
                                                isTyping={state.isTyping.secret}
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group classes='btzForm-btzFooter'>
                                            <Button loading={(state.formIsSubmitting)} width={'35%'} flat cancel anchorLeft onclick={actions.goBackToCode}>
                                                {t('Retour')}
                                            </Button>
                                            <Button loading={(state.formIsSubmitting)} width={'60%'} active={state.isValid.secret} anchorRight onclick={actions.onSubmitSecret}>
                                                {t('Terminer')}
                                            </Button>
                                        </Form.Group>
                                    </Form>
                                </dummy>
                            }
                        </Enter>
                    </Card.Header>
                </Card>
            </div>
        );
        return view;
    }
}