import { t } from 'i18next'
import { h } from 'hyperapp'
import { Button, Form, Card } from '@app/elements'

import ViewForm from './../view-form'

export const ModalViewEditStudy = (props, actions) => (
    <Card classes={'btzInformations-btzInformationsModal'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            <div
                key={props.modalEditing}
                class={'btzClassMarker-btz'}
                key={'editstudy'}
            >
                <p class="btzTitle btzXl">{t('Configurer le questionnaire')}</p>
                <p class="btzSubTitle">
                    {t(
                        'Administrez ici le questionnaire de votre patient. Vous pouvez activer / désactiver le questionnaire et modifier quand le questionnaire est envoyé.'
                    )}
                </p>
            </div>
        </Card.Header>
        <Card.Body>
            <Form
                key={props.values.name}
                onsubmit={(event) => event.preventDefault()}
            >
                <ViewForm
                    {...props}
                    retrieveInput={props._retrieveInput}
                    retrieveErrorSendBy={props.retrieveErrorSendBy}
                    from={'modal'}
                />
                <Form.Group classes="btzForm-btzFooter">
                    <Button
                        anchorLeft
                        flat
                        cancel
                        width={'36%'}
                        onclick={(e) => {
                            props.cancelAction(e)
                        }}
                    >
                        {t('Annuler')}
                    </Button>
                    <Button
                        anchorRight
                        flat
                        active
                        width={'60%'}
                        onclick={(e) => {
                            props.onSubmit(e)
                        }}
                    >
                        {t('Terminer')}
                    </Button>
                </Form.Group>
            </Form>
        </Card.Body>
    </Card>
)
