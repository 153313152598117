import { t } from 'i18next'
import { Admin, Phone, Session } from '@app/api'
import {
    enableTheseButton,
    desableTheseButton,
    deepCopy,
    ensurePasswordStrength,
} from '@app/core'
import { isDefined, coreFn } from '@app/core'
import { displayErrors } from '@app/api/errors'
import { getLangFromLocale } from '@app/core/localeHandlers/getLangFromLocale'

/**
 * @module AccountEditProfil
 * @description Doctor account informations consultation and edition.
 */

export default {
    initAction: function () {
        let AccountEditProfil = {
            onComponentCreate: (props) => (state, actions) => {
                actions._setState({
                    key: 'referenceCustomer',
                    value: props.doctor.customer,
                })
                actions._setState({ key: 'hospitals', value: props.hospitals })
                actions._setState({
                    key: 'referenceHospital',
                    value: props.refHospital,
                })
                actions._setState({
                    key: 'updateDoctor',
                    value: props.updateDoctor,
                })
                actions._setState({
                    key: 'informations',
                    value: props.informations,
                })
                actions._setState({ key: 'loaded', value: true })
                actions.onComponentUpdate(props)
            },
            onComponentUpdate: (props) => (state, actions) => {
                actions.hydrateDoctor(props.doctor)
            },
            /**
             * Reset forms when opening edit modal
             * @function AccountEditProfil:onResetForm
             */
            onResetForm: () => (state, actions) => {
                actions._setState({ key: 'formSubmittedOnce', value: false })
                for (var name in state.values) {
                    actions._setTouched({ [name]: false })
                    actions._setTyping({ [name]: true })
                    actions._setValidity({
                        [name]: name === 'phonePrefix' ? true : false,
                    })
                    actions._setValues({
                        [name]:
                            name.indexOf('phone') > -1 && name !== 'phonePrefix'
                                ? ''
                                : name.indexOf('code') > -1
                                ? ['', '', '', '', '', '']
                                : name === 'phonePrefix'
                                ? '+33'
                                : '',
                    })
                    actions._setErrorMessages({ [name]: '' })
                }
            },
            /**
             * Hydrate doctor informations
             * @function AccountEditProfil:hydrateDoctor
             * @param {object} doctor - Doctor object
             */
            hydrateDoctor: (doctor) => (state, actions) => {
                let values = state.values,
                    loggedDoctor = state.doctor
                values.id = doctor.id
                values.fname = doctor.fname
                values.lname = doctor.lname
                values.email = doctor.email
                values.phonePrefix = doctor.phonePrefix || '+33'
                if (isDefined(doctor.phone)) {
                    values.phone = doctor.phone // .match(/.{2}/g);
                }
                //
                loggedDoctor.locale = getLangFromLocale(doctor.locale)
                loggedDoctor.hospitalId = doctor.customer
                loggedDoctor.role = doctor.role
                loggedDoctor.lastConnexion = doctor.lastConnexion || null
                loggedDoctor.customer = doctor.customer || null
                loggedDoctor.question = doctor.question

                if (isDefined(doctor.ssos) && doctor.ssos.length > 0) {
                    for (var sso in doctor.ssos) {
                        if (doctor.ssos[sso].name === 'rpps') {
                            loggedDoctor.rpps = doctor.ssos[sso].id
                        }
                    }
                }
                actions._setState({ key: 'doctor', value: loggedDoctor })
                actions._setState({ key: 'values', value: values })
                actions._setState({ key: 'ovalues', value: deepCopy(values) })
            },
            /**
             * Open email edition side modal
             * @function AccountEditProfil:onEditEmail
             */
            onEditEmail: () => (state, actions) => {
                actions.onResetForm()
                actions._setState({ key: 'modalEditing', value: 'email' })
                actions.setModalDisplayed(true)
            },
            /**
             * Open password edition side modal
             * @function AccountEditProfil:onEditPassword
             */
            onEditPassword: () => (state, actions) => {
                actions.onResetForm()
                actions._setState({ key: 'modalEditing', value: 'password' })
                actions.setModalDisplayed(true)
            },
            /**
             * Open phone edition side modal
             * @function AccountEditProfil:onEditPhone
             */
            onEditPhone: () => (state, actions) => {
                actions.onResetForm()
                actions._setState({ key: 'modalEditing', value: 'phone' })
                actions.setModalDisplayed(true)
            },
            /**
             * Submit edition form, validation and error message displaying
             * @function AccountEditProfil:onSubmit
             */
            onSubmit: (event) => (state, actions) => {
                if (isDefined(event)) {
                    event.preventDefault()
                }
                if (state.formIsSubmitting) {
                    return false
                }
                actions._setTypingFalse()
                actions._retrieveErrorMessageDoctor(null)
                if (state.modalEditing === 'email') {
                    if (state.isValid.email && state.isValid.secret) {
                        actions._setState({
                            key: 'formIsSubmitting',
                            value: true,
                        })
                        actions.updateDoctorEmail()
                    } else {
                        //
                    }
                } else if (state.modalEditing === 'password') {
                    if (
                        state.isValid.secret &&
                        state.isValid.password &&
                        state.values.password === state.values.passwordconfirm
                    ) {
                        actions._setState({
                            key: 'formIsSubmitting',
                            value: true,
                        })
                        actions.updateDoctorPassword()
                    } else {
                        //
                    }
                } else if (state.modalEditing === 'phone-code-secret') {
                    if (
                        state.isValid.secret &&
                        state.isValid.code &&
                        state.isValid.newphone
                    ) {
                        actions._setState({
                            key: 'formIsSubmitting',
                            value: true,
                        })
                        actions.confirmDoctorPhone()
                    } else {
                        //
                    }
                } else {
                    actions._setState({ key: 'formIsSubmitting', value: false })
                    displayErrors(
                        t('Veuillez renseigner toutes les informations.'),
                        2000
                    )
                }
            },
            /**
             * Prepare for phone edition form (if valid, call [updateDoctorPhone]{@link module:AccountEditProfil~AccountEditProfil:updateDoctorPhone})
             * @function AccountEditProfil:onSubmitPhone
             */
            onSubmitPhone: (event) => (state, actions) => {
                if (isDefined(event)) {
                    event.preventDefault()
                }
                if (state.formIsSubmitting) {
                    return false
                }
                actions._setTypingFalse()
                actions._retrieveErrorMessageDoctor(null)
                //
                if (
                    state.modalEditing === 'phone' ||
                    state.modalEditing === 'phone-code'
                ) {
                    if (state.isValid.newphone && state.isValid.phonePrefix) {
                        actions._setState({
                            key: 'formIsSubmitting',
                            value: true,
                        })
                        actions.updateDoctorPhone()
                    } else {
                        //
                    }
                }
            },
            /**
             * Prepare for phone confirmation form (if valid, call [updateDoctorPhoneCode]{@link module:AccountEditProfil~AccountEditProfil:updateDoctorPhoneCode})
             * @function AccountEditProfil:onSubmitPhoneCode
             */
            onSubmitPhoneCode: (event) => (state, actions) => {
                if (isDefined(event)) {
                    event.preventDefault()
                }
                if (state.formIsSubmitting) {
                    return false
                }
                actions._setTypingFalse()
                actions._retrieveErrorMessageDoctor(null)
                //
                if (state.modalEditing === 'phone-code') {
                    if (state.isValid.code) {
                        actions._setState({
                            key: 'formIsSubmitting',
                            value: true,
                        })
                        actions.updateDoctorPhoneCode()
                    } else {
                        //
                    }
                }
            },
            /**
             * Submit doctor email modification form
             * @function AccountEditProfil:updateDoctorEmail
             */
            updateDoctorEmail: () => (state, actions) => {
                Admin.updateDoctor({
                    email: state.values.email,
                    answer: state.values.secret,
                }).then((res) => {
                    actions._setState({ key: 'formIsSubmitting', value: false })
                    if (isDefined(res.data.id)) {
                        displayErrors(
                            t('Mise à jour réussie.'),
                            3000,
                            'success'
                        )
                        actions._setState({ key: 'modalEditing', value: '' })
                        state.updateDoctor()
                    } else {
                        if (res.data.code === '1801') {
                            actions._setValidity({ secret: false })
                            actions._setErrorMessages({
                                secret: t(
                                    'cette réponse ne correspond pas à votre question secrète'
                                ),
                            })
                        } else {
                            displayErrors(res.data.message, 3000)
                        }
                    }
                })
            },
            /**
             * Submit doctor password modification form
             * @function AccountEditProfil:updateDoctorPassword
             */
            updateDoctorPassword: () => (state, actions) => {
                Admin.updateDoctor({
                    newpassword: state.values.password,
                    answer: state.values.secret,
                }).then((res) => {
                    actions._setState({ key: 'formIsSubmitting', value: false })
                    if (isDefined(res.data.id)) {
                        Session.createSession(
                            res.data.email,
                            state.values.password
                        ).then((session) => {
                            if (
                                isDefined(session.data) &&
                                session.data !== false &&
                                isDefined(session.data.token)
                            ) {
                                localStorage.setItem(
                                    'apitoken',
                                    session.data.token
                                )
                                localStorage.setItem(
                                    'refresh',
                                    session.data.refresh
                                )
                                localStorage.setItem(
                                    'expiration',
                                    session.data.expiration.date
                                )
                                //
                                displayErrors(
                                    t('Mise à jour réussie.'),
                                    3000,
                                    'success'
                                )
                                actions._setState({
                                    key: 'modalEditing',
                                    value: '',
                                })
                                state.updateDoctor()
                            }
                        })
                    } else {
                        if (res.data.code === '1801') {
                            actions._setValidity({ secret: false })
                            actions._setErrorMessages({
                                secret: t(
                                    'cette réponse ne correspond pas à votre question secrète'
                                ),
                            })
                        } else {
                            displayErrors(res.data.message, 3000)
                        }
                    }
                })
            },
            /**
             * Submit doctor phone modification
             * @function AccountEditProfil:updateDoctorPhone
             */
            updateDoctorPhone: () => (state, actions) => {
                let newphone = state.values.newphone
                if (Array.isArray(newphone)) {
                    newphone = newphone.join('')
                }
                //
                Phone.askPhone({
                    phone: newphone,
                    phonePrefix: state.values.phonePrefix,
                }).then((res) => {
                    actions._setState({ key: 'formIsSubmitting', value: false })
                    if (isDefined(res.data) && res.data.message == 'ok') {
                        actions._setState({
                            key: 'modalEditing',
                            value: 'phone-code',
                        })
                        actions._setState({
                            key: 'formSubmittedOnce',
                            value: false,
                        })
                    } else {
                        displayErrors(res.data.message, 3000)
                    }
                })
            },
            /**
             * Submit doctor phone code verification
             * @function AccountEditProfil:updateDoctorPhoneCode
             */
            updateDoctorPhoneCode: () => (state, actions) => {
                let code = state.values.code
                if (Array.isArray(code)) {
                    code = code.join('')
                }
                Phone.checkPhone(code).then((res) => {
                    actions._setState({ key: 'formIsSubmitting', value: false })
                    if (isDefined(res.data) && res.data.message === 'ok') {
                        actions._setState({
                            key: 'modalEditing',
                            value: 'phone-code-secret',
                        })
                        actions._setState({
                            key: 'formSubmittedOnce',
                            value: false,
                        })
                    } else {
                        actions._setValidity({ code: false })
                        if (isDefined(res.data) && +res.data.code === 432) {
                            actions._setErrorMessages({
                                code: t(
                                    'le code renseigné ne semble pas être le bon'
                                ),
                            })
                        } else {
                            displayErrors(res.data.message, 3000)
                        }
                    }
                })
            },
            /**
             * Submit doctor phone modification
             * @function AccountEditProfil:confirmDoctorPhone
             */
            confirmDoctorPhone: () => (state, actions) => {
                let code = state.values.code
                if (Array.isArray(code)) {
                    code = code.join('')
                }
                Phone.updatePhone(state.values.secret, code).then((res) => {
                    actions._setState({ key: 'formIsSubmitting', value: false })
                    if (isDefined(res.data) && res.data.message === 'ok') {
                        displayErrors(
                            t('Mise à jour réussie.'),
                            3000,
                            'success'
                        )
                        actions._setState({ key: 'modalEditing', value: '' })
                        actions.setModalDisplayed(false)
                        state.updateDoctor()
                    } else {
                        if (isDefined(res.data) && +res.data.code === 1801) {
                            actions._setValidity({ secret: false })
                            actions._setErrorMessages({
                                secret: t(
                                    'cette réponse ne correspond pas à votre question secrète'
                                ),
                            })
                        } else {
                            displayErrors(res.data.message, 3000)
                        }
                    }
                })
            },
            /**
             * Update modal state
             * @function AccountEditProfil:setModalDisplayed
             * @param {boolean} active - Switch modal display state
             */
            setModalDisplayed: (active) => (state, actions) => ({
                formSubmittedOnce: false,
                modalDisplayed: active,
                modalEditing: active ? state.modalEditing : '',
            }),
        }
        AccountEditProfil = { ...coreFn, ...AccountEditProfil }
        return AccountEditProfil
    },
}
