import { t } from 'i18next';
import { h } from 'hyperapp';
import { isDefined, arrayRange } from '@app/core';
import './index.scss';

const Paginate = (props, children) => {
    const nButton = 5;
    const {
        ...otherProps
    } = props;
    //
    let maxPage = null;
    let minPage = null;
    let lastPage = null;
    let lastPageReached = false;
    if (isDefined(props.total)) {
        lastPage = Math.floor(+props.total / +props.bypage); // total of pages;
        //
        minPage = props.page - Math.floor(nButton / 2); // min page of these 5 page buttons
        if (minPage < 0) {
            minPage = 0;
        }
        //
        maxPage = props.page + Math.floor(nButton / 2); // max page of these 5 page buttons
        if (maxPage > lastPage) {
            maxPage = lastPage;
        } else if (maxPage <= 0) {
            maxPage = 1;
        }
        // when reaching start or end of pagination, need to add missing page numbers
        if ((arrayRange(minPage, maxPage, 1).length < lastPage)) {
            let missingButtons = (((nButton > lastPage) ? lastPage : nButton) - arrayRange(minPage, maxPage, 1).length);
            if ((lastPage - props.page) <= Math.floor(nButton / 2)) {
                minPage -= missingButtons;
            } else {
                maxPage += missingButtons;
            }
        }
        if (((+props.page + 1) * +props.bypage) === +props.total) {
            lastPageReached = true;
        }
    }

    return (
        <div class='thfTable-thfTablePaginations'>
            <div onclick={() => { if (props.page > 0) { props.prevPage(); } }} class={'thfTable-thfTablePagination thfTable-thfTablePagination-toTheLeft ' + ((props.page > 0) ? 'active' : '')}><font>{'<'}</font>{t('Préc')}</div>
            {(isDefined(props.total)) ?
                <div class='thfTable-thfTablePaginationNumbers'>
                    {arrayRange(minPage, maxPage, 1).map((item) =>
                        <div onclick={() => { if (props.page !== item) { props.targetPage(item); } }} class={'thfTable-thfTablePagination thfTable-thfTablePaginationNumber ' + ((item === props.page) ? 'active' : '')}>{(item + 1)}</div>
                    )}
                </div>
                :
                <div class='thfTable-thfTablePagination thfTable-thfTablePaginationNumber'>{props.page + 1}</div>
            }
            <div onclick={() => { if (!lastPageReached && (props.limit >= (props.bypage || 20))) { props.nextPage(); } }} class={'thfTable-thfTablePagination thfTable-thfTablePagination-toTheRight ' + ((!lastPageReached && (props.limit >= (props.bypage || 20))) ? 'active' : '')}>{t('Suiv')}<font>{'>'}</font></div>
        </div>
    );
};

export { Paginate };
