export const state = {
    patientid: null,
    medicalStudies: [],
    loaded: false,
    formIsSubmitting: false,
    newStudy: {
        name: 'toutk00001',
        frequency: 'weekly',
        issuePeriod: 'weeklyMonday',
    },
};
