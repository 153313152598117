export const state = {
    values: {
        email: '',
        password: '',
        passwordconfirm: '',
        code: ['', '', '', '', '', ''],
        phone: '',
        phonePrefix: '',
        question: '',
        secret: '',
    },
    isTouched: {
        email: false,
        password: false,
        passwordconfirm: false,
        phonePrefix: false,
        code: false,
        question: false,
        secret: false,
    },
    isValid: {
        email: false,
        password: false,
        passwordconfirm: false,
        phonePrefix: false,
        code: false,
        question: false,
        secret: false,
    },
    errorMessages: {
        email: '',
        password: '',
        passwordconfirm: '',
        code: '',
        secret: '',
    },
    isTyping: {
        email: false,
        password: false,
        passwordconfirm: false,
        phonePrefix: false,
        code: false,
        question: false,
        secret: false,
    },
    api: {
        message: '',
        kind: 'info'
    },
    recoveryStep: 'email',
    formIsSubmitting: false,
    formSubmittedOnce: false,
    sendToParent: null,
    messageActive: false,
};
