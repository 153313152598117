const mandatoryKeys = [
    'surgeryDate',
    'inclusionDate',
    'visitDate',
    'firstInterviewDate',
]
const isMandatoryField = (values, key) => {
    if (
        !values ||
        Array.isArray(values) ||
        typeof values !== 'object' ||
        !key ||
        typeof key !== 'string'
    ) {
        return undefined
    }
    //
    const inclusionDateExist = values['inclusionDate'] !== undefined
    const surgeryDateExist = values['surgeryDate'] !== undefined
    const visitDateExist = values['visitDate'] !== undefined
    const firstInterviewDateExist = values['firstInterviewDate'] !== undefined
    //
    const inclusionDateIsFilled = !!values['inclusionDate']
    const surgeryDateIsFilled = !!values['surgeryDate']
    const visitDateIsFilled = !!values['visitDate']
    const firstInterviewDateFilled = !!values['firstInterviewDate']
    //
    const inclusionDateMandatory = inclusionDateExist
    const surgeryDateMandatory =
        surgeryDateExist && !values['inclusionDate'] && !values['visitDate']
    const visitDateMandatory = visitDateExist
    const firstInterviewDateMandatory = firstInterviewDateExist
    //
    const atLeastOneIsFilled =
        (inclusionDateExist && inclusionDateIsFilled) ||
        (surgeryDateExist && surgeryDateIsFilled) ||
        (visitDateExist && visitDateIsFilled) ||
        (firstInterviewDateExist && firstInterviewDateFilled)
    //
    if (!atLeastOneIsFilled && mandatoryKeys.includes(key)) {
        return true
    }
    return (
        (key === 'inclusionDate' && inclusionDateMandatory) ||
        (key === 'surgeryDate' && surgeryDateMandatory) ||
        (key === 'visitDate' && visitDateMandatory) ||
        (key === 'firstInterviewDate' && firstInterviewDateMandatory)
    )
}

export { isMandatoryField }
