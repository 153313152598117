import { h } from 'hyperapp';
import { Component } from '@app/utils';

const Fading = Component(
    {
        fade: null,
        time: '',
        easing: '',
        css: '',
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            //
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (props.time) {
                state.time = props.time;
            }
            if (props.easing) {
                state.easing = props.easing;
            }
            if (props.css) {
                state.css = props.css;
            }
            if (state.fade !== props.fade) {
                actions.launchFade();
                actions.setFade(props.fade);
            }
        },
        setFade: newState => state => ({
            fade: newState,
        }),
        launchFade: () => (state, actions) => {
            //
        },
    },
    (state, actions) => (props, children) => (
        <div class={'toolsFading'} style={{ 'transition': 'all 1s ease-in-out' }}>
            {children}
        </div>
    ),
    'fading'
);

export { Fading };
