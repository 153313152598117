import { t } from 'i18next';
import { h } from 'hyperapp';
import { Button, Form, Card } from '@app/elements';

export const ModalViewDeactivate = (props, actions) => (
    <Card classes={'btzInformations-btzInformationsModal'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            <div key={props.modalEditing} class={'btzClassMarker-btz'} key={'survey'}>
                <p class='btzTitle btzXl'>{t('Désactivation')}</p>
                <p class='btzSubTitle'>{t('Vous êtes sur le point de désactiver le module') + ' « ' + props.moduleName + ' ». ' + t('Vous pourrez le réactiver à tout moment.')}</p>
                <Form onsubmit={event => event.preventDefault()}>
                    <Form.Group classes='btzForm-btzFooter'>
                        <Button anchorLeft flat cancel width={'30%'} onclick={(e) => {
                            props.cancelAction(e);
                        }}>
                            {t('Annuler')}
                        </Button>
                        <Button anchorRight flat active dangerous width={'60%'} onclick={(e) => {
                            props.onSubmit(e);
                        }}>
                            {t('Désactiver')}
                        </Button>
                    </Form.Group>
                </Form>
            </div>
        </Card.Header>
    </Card>
);