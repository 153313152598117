import { t } from 'i18next';
import { h } from 'hyperapp';
import { Button, Card } from '@app/elements';
import { isDefined } from '@app/core';

export const ModalViewMedicalConfirm = (props, actions) => (
    <Card classes={'btzInformations-btzInformationsModal'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            <div key={props.modalEditing} key={'treatment'}>
                <p class='btzTitle btzXl'>{t('Demande de confirmation')}</p>
                <p class='btzSubTitle'>{t('Supprimer des membres de l’équipe médicale entraine une perte d’accès aux données du patient pour les membres de l’équipe révoqués. Les nouveaux membres de l’équipe auront uniquement accès aux nouvelles données du patient mais pas aux données antérieures.')}</p>
            </div>
        </Card.Header>
        {props.loading ?
            <Card.Body>
                <div class='thfInput thfLoadingBox' style='height: 100%;position: absolute;left: 0;top: 0;width: 100%;'><div class='thfLoadingBoxWrapper' style='margin: 0px;'></div></div>
            </Card.Body>
            :
            <Card.Body>
                {(props.disclamerAutoBan === true) &&
                    <div class='thfTableMedicalChangesDisclamer'>
                        <p class='btzTitle btzXl'>{t('Attention, vous êtes sur le point de vous retirez totalement des listes des équipes médicales !')}</p>
                        <p class='btzSubTitle'>{t('En réalisant cette action, vous serez automatiquement redirigé et n\'aurez plus accès à ce patient.')}</p>
                    </div>
                }
                {(props.disclamerRightLoosed.coach === true) &&
                    <div class='thfTableMedicalChangesDisclamer'>
                        <p class='btzTitle btzXl'>{t('Attention, vous êtes sur le point de changer votre propre rôle !')}</p>
                        <p class='btzSubTitle'>{t('En réalisant cette action, vous ne pourrez plus éditer les traitements de ce patient.')}</p>
                    </div>
                }
                <div class='' style={{ 'max-height': 'calc(100vh - 305px)' }}>
                    <table class='thfTable thfTableMedicalChanges'>
                        <thead>
                            <tr>
                                <th class='team'></th>
                                <th class='added'>{t('Ajout')}</th>
                                <th class='removed'>{t('Suppression')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class=''>
                                <td class='team'>{t('Médecin référent')}</td>
                                <td class='added'>
                                    {isDefined(props.medicalAddedValues.doctor) &&
                                        <p>{props.hospitals.map((m) => { return m.doctors; }).reduce((a, b) => a.concat(b)).filter((f) => f.id === props.medicalAddedValues.doctor).map((doctor) => { return {label: doctor.fname + ' ' + doctor.lname} })[0].label}</p>
                                    }
                                </td>
                                <td class='removed'>
                                    {isDefined(props.medicalRemovedValues.doctor) &&
                                        <p>{props.hospitals.map((m) => { return m.doctors; }).reduce((a, b) => a.concat(b)).filter((f) => f.id === props.medicalRemovedValues.doctor).map((doctor) => { return {label: doctor.fname + ' ' + doctor.lname} })[0].label}</p>
                                    }
                                </td>
                            </tr>
                            <tr class=''>
                                <td class='team'>{t('Équipe médicale')}</td>
                                <td class='added'>
                                    {(isDefined(props.medicalAddedValues.nurses) && (props.medicalAddedValues.nurses.length > 0)) &&
                                        <dummy>
                                            {props.medicalAddedValues.nurses.map((item) =>
                                                <p>{props.hospitals.map((m) => { return m.nurses; }).reduce((a, b) => a.concat(b)).filter((f) => f.id === item).map((nurse) => { return {label: nurse.fname + ' ' + nurse.lname} })[0].label}</p>
                                            )}
                                        </dummy>
                                    }
                                </td>
                                <td class='removed'>
                                    {(isDefined(props.medicalRemovedValues.nurses) && (props.medicalRemovedValues.nurses.length > 0)) &&
                                        <dummy>
                                            {props.medicalRemovedValues.nurses.map((item) =>
                                                <p>{props.hospitals.map((m) => { return m.nurses; }).reduce((a, b) => a.concat(b)).filter((f) => f.id === item).map((nurse) => { return {label: nurse.fname + ' ' + nurse.lname} })[0].label}</p>
                                            )}
                                        </dummy>
                                    }
                                </td>
                            </tr>
                            <tr class=''>
                                <td class='team'>{t('Accompagnant thérapeutique')}</td>
                                <td class='added'>
                                    {isDefined(props.medicalAddedValues.coach) &&
                                        <p>{props.hospitals.map((m) => { return m.coaches.concat(m.doctors); }).reduce((a, b) => a.concat(b)).filter((f) => f.id === props.medicalAddedValues.coach).map((coach) => { return {label: coach.fname + ' ' + coach.lname} })[0].label}</p>
                                    }
                                </td>
                                <td class='removed'>
                                    {isDefined(props.medicalRemovedValues.coach) &&
                                        <p>{props.hospitals.map((m) => { return m.coaches.concat(m.doctors); }).reduce((a, b) => a.concat(b)).filter((f) => f.id === props.medicalRemovedValues.coach).map((coach) => { return {label: coach.fname + ' ' + coach.lname} })[0].label}</p>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class='btzCard-btzFooter-btzModal' style='margin-top: 40px;'>
                    <Button anchorRight flat margin={'0 0 0 15px'} active onclick={() => {
                        props.onSubmit();
                    }}>
                        {t('Confirmer')}
                    </Button>
                    <Button anchorRight flat cancel onclick={(e) => {
                        props.cancelAction(e);
                    }}>
                        {t('Annuler')}
                    </Button>
                </div>
            </Card.Body>
        }
    </Card>
);