import { t } from 'i18next';
import { h } from 'hyperapp';

export const view = (state, actions) => (props, children) => (
    <div key='RedirectingWay'>
        <div class='btzRedirectingWay'>
            <div class='btzRedirectingWay-btzWrapper'>
                <p class='btzTitle btzXl' style='font-size: 50px !important;line-height: 50px;'>• {t('Traitement en cours')} •</p>
                <p class='btzTitle btzXl'>{t('Nous traitons votre demande, veuillez patienter.')}</p>
            </div>
        </div>
    </div>
);