import { h } from 'hyperapp';
import { Component } from '@app/utils';
import './index.scss';

import IndexLayout from '@app/layouts/pages/layout';

import Add from './add';
import Edit from './edit';

const state = {
    email: {
        value: '',
        isValid: false,
        isApproved: null,
        isResetted: null
    },
    password: {
        value: '',
        isValid: false,
        isApproved: null,
        isResetted: null
    }
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        // localStorage.clear();
    },
    setState: ({ ns, key, value }) => state => ({
        [ns]: { ...state[ns], ...{ [key]: value } },
    })
};

const view = (state, actions) => ({ location, match, customer }, children) => (
    <div key='patient' class='root-wrapper'>
        <IndexLayout page={((location.pathname.indexOf('/patient/add') > -1) ? 'patient' : 'patients')} customer={customer}>
            {/* Default view */}
            {(location.pathname.indexOf('/patient/edit') > -1) && (
                <Edit initial={state.email} customer={customer} match={match} send={actions.setState} />
            )}

            {(location.pathname === '/patient/add') && (
                <Add initial={state.email} customer={customer} match={match} send={actions.setState} />
            )}

        </IndexLayout>
    </div>
);

export {
    Add,
    Edit,
};

export default Component(state, actions, view, 'patient');
