import { t } from 'i18next';
import { h } from 'hyperapp';
import { isDefined, getReadableDate, createUTCDate, getYMDfromDateObj } from '@app/core';
import { allRoutes, addCSRFToken } from '@app/core';
import { Form, Card, FormInput, Enter, Paginate, Button } from '@app/elements';
import * as constants from '@app/constants';

import icoNoPatients from '@app/img/deco/img-no-patient.png';
import icoNoPatientsx2 from '@app/img/deco/img-no-patient@2x.png';

import icoCE from '@app/img/mentions/logo-right-ce.png';
import icoCEx2 from '@app/img/mentions/logo-right-ce@2x.png';

import icoSortAlpha from '@app/img/ico/table/ico-sort-alpha.png';
import icoSortAlphax2 from '@app/img/ico/table/ico-sort-alpha@2x.png';

export default {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div class='patients-central'>
                {(isDefined(state.alerts)) &&
                    <dummy>
                        {(state.alerts.length === 0) ?
                            <div class='thfEmptyTable'>
                                <img src={icoNoPatients} srcset={`${icoNoPatientsx2} 2x`} alt='' />
                                {(state.noPatients === true) ?
                                    <dummy>
                                        <p class='thfEmptyTable-thfEmptyTableTitle'>{t('Pas de patient inclus')}</p>
                                        <p class='thfEmptyTable-thfEmptyTableSubTitle'>{t('Ajoutez un premier patient pour voir le reste des fonctionnalités.')}</p>
                                        <div style='margin: 15px auto auto;width: 200px;'>
                                            <Button active onclick={() => { window.location.href = addCSRFToken(allRoutes['private.patient.add'].pathname); }}>
                                                {t('Ajouter un patient')}
                                            </Button>
                                        </div>
                                    </dummy>
                                    :
                                    <dummy>
                                        <p class='thfEmptyTable-thfEmptyTableTitle'>{t('Aucune alerte à traiter')}</p>
                                        <p class='thfEmptyTable-thfEmptyTableSubTitle'>{t('Vous n’avez actuellement aucune alerte à traiter pour vos patients.')}</p>
                                    </dummy>
                                }
                                <div class='thfTableCertification' style='float: none;margin: auto;width: auto;'>
                                    <p class=''>{(state.ICModule ? t('Télésuivi IC') : t('Télésuivi')) + ' - ' + t('DM Classe I')}</p>
                                    <img src={icoCE} srcset={`${icoCEx2} 2x`} alt='' />
                                </div>
                            </div>
                            :
                            <Card classes={'btzPageCard btzBackgroundCover'} kind={'datas'}>
                                <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                                    <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
                                        <table class='thfTable'>
                                            <thead>
                                                <tr>
                                                    <th class='thfTable-cellName'><p class='thfTable-formInputLabel'>{t('Nom')}</p><img src={icoSortAlpha} srcset={`${icoSortAlphax2} 2x`} alt='' onclick={() => actions.onSortList('lastName')} /></th>
                                                    <th class='thfTable-cellPatho'><p class='thfTable-formInputLabel'>{t('Pathologie')}</p></th>
                                                    <th class='thfTable-cellDate'><p class='thfTable-formInputLabel'>{t('Date')}</p><img src={icoSortAlpha} srcset={`${icoSortAlphax2} 2x`} alt='' onclick={() => actions.onSortList('date')} /></th>
                                                    <th class='thfTable-cellType'><p class='thfTable-formInputLabel'>{t('Type')}</p></th>
                                                    <th class='thfTable-cellValue'><p class='thfTable-formInputLabel'>{t('Alertes')}</p>
                                                        {/*<Form onsubmit={event => event.preventDefault()} classes={'hidden-field'}>
                                                            <Form.Group>
                                                                <FormInput
                                                                    key={'alertsort'}
                                                                    type='hidden-dropdown'
                                                                    id={'alertsort'}
                                                                    name='selectedAlert'
                                                                    value={state.selectedAlert}
                                                                    list={constants.custo.alerts}
                                                                    initialCalls={false}
                                                                    withSearch={false}
                                                                    textedArea={false}
                                                                    oninputcb={actions.retrieveAlertSort}
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </Form>*/}
                                                    </th>
                                                    <th class='thfTable-cellAction'><p class='thfTable-formInputLabel'>{t('Actions')}</p></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {state.alerts.map((item, idx) =>
                                                    <tr onclick={() => { actions.redirectToPatient({patientid: item.user.id, date: getYMDfromDateObj(createUTCDate(item.date.date), true, true), crypt: item.dataType, period: 'week'}); }}>
                                                        <td class='thfTable-cellName'>
                                                            {((isDefined(item.user.userTheraflow) && (item.user.userTheraflow.etapes === true)) ? <font>{t('e')}</font> : '')}
                                                            <div class='btzCard-btzHeader-btzPage-btzNamePuce btzCard-btzHeader-btzPage-btzNamePuce-small'>
                                                                <p key={'listing-alerts-' + item.user.fname + item.user.lname} oncreate={(el) => { actions.getPatientName({el: el, patient: item}); }}></p>
                                                            </div>
                                                            <p class='btzCard-btzHeader-btzPage-btzNamePuce-small-fullName'>{item.user.lname + ' ' + item.user.fname}</p>
                                                        </td>
                                                        <td class='thfTable-cellPatho'><p class='thfTable-cellColored'>{'Ø'}</p></td>
                                                        <td class='thfTable-cellDate'>
                                                            <p>{getReadableDate(createUTCDate(item.date.date, false), 'DD MMMM', true)}</p>
                                                            {item.triggerType === 'NODATA' ?
                                                                <p class='ovr-lightgrey-colored'>{t('non renseigné')}</p>
                                                                :
                                                                <p class='ovr-lightgrey-colored'>{getReadableDate(createUTCDate(item.date.date, false), 'HH:mm')}</p>
                                                            }
                                                        </td>
                                                        <td class='thfTable-cellType' oncreate={(el) => { actions.getCellType({el: el, alert: item}) }}></td>
                                                        <td class='thfTable-cellValue' oncreate={(e) => { actions.getReadableTriggerType({el: e, item: item}); }}></td>
                                                        <td class='thfTable-cellAction'>
                                                            <Form>
                                                                <Form.Group>
                                                                    <FormInput
                                                                        key={'input-alert-action-' + idx}
                                                                        type='dropdown'
                                                                        id={item.id + '-' + item.user.id}
                                                                        name='action'
                                                                        value={constants.custo.telesuiviActionsAlert.filter((f) => +f.key === item.status)[0].key}
                                                                        list={constants.custo.telesuiviActionsAlert}
                                                                        initialCalls={false}
                                                                        validation={true}
                                                                        withSearch={false}
                                                                        textedArea={false}
                                                                        oninputcb={actions.retrieveInputAction}
                                                                        required
                                                                    />
                                                                </Form.Group>
                                                            </Form>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        <Paginate targetPage={actions.targetPage} nextPage={actions.nextPage} prevPage={actions.prevPage} limit={state.alerts.length} total={state.totalAlerts} bypage={state.bypage} page={state.page}></Paginate>
                                    </Enter>
                                </Card.Header>
                                <div class='thfTableCertification'>
                                    <p class=''>{(state.ICModule ? t('Télésuivi IC') : t('Télésuivi')) + ' - ' + t('DM Classe I')}</p>
                                    <img src={icoCE} srcset={`${icoCEx2} 2x`} alt='' />
                                </div>
                            </Card>
                        }
                    </dummy>
                }
            </div>
        );
        return view;
    }
}