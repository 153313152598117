import { t } from 'i18next'
import { h } from 'hyperapp'
import { Component } from '@app/utils'
import { Form, FormInput, Calendar } from '@app/elements'
import * as constants from '@app/constants'

const formInputs = {
    issueSentBy: [
        'curve',
        'less',
        'uro',
        'uroaccurate99',
        'uroanxiety113',
        'uroanxiety113dipru',
        'urodipru',
        'uroprostate',
        'urorein',
        'uroreinambu',
        'urovessie',
        'urosocratic152sa',
        'urosocratic152sur',
        'uropersonalize114',
    ],
    inclusionDate: {
        studies: [
            'palatine',
            'uroaccurate99',
            'uroanxiety113',
            'uroanxiety113dipru',
            'urosocratic152sa',
            'urosocratic152sur',
        ],
        label: "Date d'inclusion",
        i18nlabel: t("Date d'inclusion"),
        optional: false,
        deletable: false,
    },
    beginDate: {
        studies: ['palatine'],
        label: 'Date de début de traitement loco-régional',
        i18nlabel: t('Date de début de traitement loco-régional'),
        optional: true,
        deletable: true,
    },
    endDateLogo: {
        studies: ['palatine'],
        label: 'Date de fin de traitement loco-régional',
        i18nlabel: t('Date de fin de traitement loco-régional'),
        optional: true,
        deletable: true,
    },
    endDate: {
        studies: ['palatine'],
        label: 'Date de fin de traitement',
        i18nlabel: t('Date de fin de traitement'),
        optional: true,
        deletable: true,
    },
    visitDate: {
        studies: ['curve', 'less'],
        label: 'Date de première visite',
        i18nlabel: t('Date de première visite'),
        optional: false,
        deletable: false,
    },
    surgeryDate: {
        studies: [
            'curve',
            'uro',
            'uroaccurate99',
            'uroanxiety113',
            'uroanxiety113dipru',
            'urodipru',
            'uroprostate',
            'urorein',
            'uroreinambu',
            'urovessie',
        ],
        label: 'Date de chirurgie',
        i18nlabel: t('Date de chirurgie'),
        optional: false,
        deletable: false,
    },
    firstInterviewDate: {
        studies: ['uropersonalize114'],
        label: 'Date 1er entretien',
        i18nlabel: t('Date 1er entretien'),
        optional: false,
        deletable: false,
    },
    postT2Date: {
        studies: ['uropersonalize114'],
        label: 'Date entretien post-T2',
        i18nlabel: t('Date entretien post-T2'),
        optional: true,
        deletable: true,
    },
    postSurgeryDate: {
        studies: ['uropersonalize114'],
        label: 'Date entretien post-op',
        i18nlabel: t('Date entretien post-op'),
        optional: true,
        deletable: true,
    },
}
const surveysOptionalSurgeryDate = [
    'curve',
    'uroaccurate99',
    'uroanxiety113',
    'uroanxiety113dipru',
]

const state = {
    fields: [],
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        const studyName = props.values.name
        const fields = Object.entries(formInputs).reduce((acc, entrie) => {
            if ((entrie[1]?.studies ?? []).includes(studyName)) {
                const fieldName = entrie[0]
                return [
                    ...acc,
                    {
                        name: fieldName,
                        deletable: actions.isFieldDeletable({
                            field: fieldName,
                            studyName: studyName,
                        }),
                        optional: actions.isFieldOptional({
                            field: fieldName,
                            studyName: studyName,
                        }),
                    },
                ]
            }
            return acc
        }, [])
        actions.setState({ key: 'fields', value: fields })
    },
    isFieldOptional: ({ field, studyName }) => {
        if (field === 'surgeryDate') {
            return surveysOptionalSurgeryDate.includes(studyName)
        }

        return formInputs[field].optional
    },
    isFieldDeletable: ({ field, studyName }) => {
        if (field === 'surgeryDate') {
            return surveysOptionalSurgeryDate.includes(studyName)
        }

        return formInputs[field].deletable
    },
    setState:
        ({ key, value }) =>
        (state) => {
            state[key] = value
        },
}

const getInputIssueSentByValidity = (formSubmittedOnce, issueSentBy) => {
    return formSubmittedOnce ? issueSentBy : 'is-default'
}

const getInputIssueSentByListLabel = (
    label,
    key,
    phonePrefix,
    phone,
    autoGeneratedEmail,
    email
) => {
    const baseLabel = t(label)
    let sufixLabel = t('non défini')
    if (key === 'SMS') {
        sufixLabel = `${String(phonePrefix)} ${String(phone)}`
    } else if (!autoGeneratedEmail) {
        sufixLabel = email
    }

    return `${baseLabel} (${sufixLabel})`
}

const view = (state, actions) => (props, children) => (
    <Form.Group>
        <div
            class={props.from === 'modal' ? '' : 'col-6 col-xs-12 thfAlignTop'}
        >
            {formInputs.issueSentBy.includes(props.values.name) ? (
                <div>
                    <p class="btzForm-btzLabel">{t("Mode d'émission")}</p>
                    <FormInput
                        key="input-issueSentBy"
                        type="dropdown"
                        id="issueSentBy"
                        name="issueSentBy"
                        value={props.values.issueSentBy}
                        isValid={getInputIssueSentByValidity(
                            props.formSubmittedOnce,
                            props.isValid.issueSentBy
                        )}
                        list={constants.custo.sendingWays.map((m) => {
                            return {
                                label: getInputIssueSentByListLabel(
                                    m.label,
                                    m.key,
                                    props.editedPatient.phonePrefix,
                                    String(props.editedPatient.phone),
                                    props.autoGeneratedEmail,
                                    props.editedPatient.email
                                ),
                                key: m.key,
                            }
                        })}
                        oninputcb={props.retrieveErrorSendBy}
                        errorMessage={props.errorMessages.issueSentBy}
                        isTyping={props.isTyping.issueSentBy}
                        onblurcb={props.retrieveErrorMessageStudy}
                        required
                    />
                </div>
            ) : null}

            {state.fields.map((field) => (
                <Calendar
                    oninput={props.retrieveErrorSendBy}
                    onblurcb={props.retrieveErrorMessageStudy}
                    key={field.name}
                    name={field.name}
                    title={t(formInputs[field.name].label)}
                    calendarDate={
                        props.values[field.name] ?? {
                            day: '',
                            month: '',
                            year: '',
                        }
                    }
                    isValid={props.isValid[field.name]}
                    isTyping={props.isTyping[field.name]}
                    errorMessage={props.errorMessages[field.name]}
                    optional={field.optional}
                    deletetable={field.deletable}
                />
            ))}
        </div>
    </Form.Group>
)

export default Component(state, actions, view, 'instructions')
