import { h } from 'hyperapp';
import { Component } from '@app/utils';
import { Form } from '@app/elements';
import './index.scss';

// import ImgLess from './ico-less.png';
// import ImgLessx2 from './ico-less@2x.png';

const CustomInput = Component(
    {
        uniqName: 'uniq',
        onclick: null,
    },
    {
        onComponentCreate: (props) => (state) => {
            if (props.uniqName) {
                state.uniqName = props.uniqName;
            }
            if (props.onclick) {
                state.onclick = props.onclick;
            }
        },
    },
    (state, actions) => (props, children) => (
        <div class='btzCustomInput'>
            <div class=''>
                <Form.Group>
                    <div>
                        <Form.Input
                            classes={'btzFullWidth ' + props.uniqClass}
                            type='text'
                            name={state.uniqName + '-custom'}
                            maxlength={props.uniqMaxLength}
                            value={props.uniqDefaultValue}
                            placeholder={'0'}
                            onclick={state.onclick}
                        />
                        <p>{props.uniqLabel}</p>
                    </div>
                </Form.Group>
            </div>
        </div>
    ),
    'custominput'
);

export { CustomInput };
