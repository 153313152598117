import { t } from 'i18next';
import { h } from 'hyperapp';
import { Button } from '@app/elements';

export const ModalViewConfirm = (props, actions) => (
    <div key={props.modalEditing} class={'btzClassMarker-btz btzClassMarker-btzAutoHeight'} key={'configuration'}>
        <div class='thfTelesuiviModalBlock' style='border: none !important;'>
            {props.deactivationConfirmKind === 'deactivate' ?
                <dummy>
                    <p class='' style='text-align: center;margin-top: 50px;'>{t('Vous êtes sur le point d\'arrêter le traitement') + ' '}<b>{props.values.name}</b>{'. ' + t('Il ne sera plus affichée pour votre patient.')}</p>
                    <p class='' style='text-align: center'>{t('Vous pourrez le réactiver à tout moment.')}</p>
                </dummy>
                :
                <dummy>
                    <p class='' style='text-align: center;margin-top: 50px;'>{t('Vous êtes sur le point de réactiver le traitement') + ' '}<b>{props.values.name}</b>{'. ' + t('Votre patient pourra à nouveau voir ce traitement.')}</p>
                    <p class='' style='text-align: center'>{t('Vous pourrez le désactiver à tout moment.')}</p>
                </dummy>
            }
            <div class='btzCard-btzFooter-btzModal' style='margin-top: 40px;'>
                <Button anchorLeft flat cancel onclick={() => {
                    props.onEditStatusTreatmentCancel();
                }}>
                    {t('Annuler')}
                </Button>
                {props.deactivationConfirmKind === 'deactivate' ?
                    <Button anchorRight flat dangerous onclick={() => {
                        props.nextTreatmentStep({newStep: 'stop'});
                    }}>
                        {t('Désactiver')}
                    </Button>
                    :
                    <Button anchorRight successed flat onclick={() => {
                        props.nextTreatmentStep({newStep: 'active'});
                    }}>
                        {t('Réactiver')}
                    </Button>
                }
            </div>
        </div>
    </div>
);