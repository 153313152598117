import * as constants from '@app/constants';
export const state = {
    alerts: null,
    info: null,
    icalerts: null,
    page: 0,
    bypage: 20,
    dataTypeCurrent: [],
    alertColors: [0, 1, 2, 3, 4],
    alertDetail: null,
    displayedPopup: '',
    REFStudy: null,
    REFApi: null,
    ICMesure: 'CryptedWeight',
    ICMesureIgnore: ['Observance'],
    ICModule: false,
    toTreatAlert: true,
    noPatients: false,
    doctor: null,
    selectedSurvey: constants.custo.configureSurvey.map((config) => config.key),
    selectedAlertColors: 'null',
    selectedDate: 'null',
    selectedAlert: [],
    sort: {sort: {date: 'DESC'}},
    totalAlerts: null,
    noAlertAtAll: false,
    availableSurveys: [],
};