import { enableTheseButton, desableTheseButton } from '@app/core';

/**
 * @module PatientEditTelesuiviIc
 * @description Patient configuration telesuivi IC
 */

export default {
    initAction: function() {
        const PatientEditTelesuiviIc = {
            onComponentCreate: (props) => (state, actions) => {
                //
            },
        }
        return PatientEditTelesuiviIc;
    }
}