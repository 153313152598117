let parseRoute = null

export function Route(props, children) {
    if (props.render !== undefined) {
        return async function (state, actions) {
            var location = state.location
            var match = parseRoute(props.path, location.pathname, {
                exact: !props.parent,
            })

            let rendered = null
            rendered =
                match &&
                (await props.render.then((v) => {
                    return v
                }))
            //
            return rendered
        }
    } else {
        return function (state, actions) {
            var location = state.location
            var match = parseRoute(props.path, location.pathname, {
                exact: !props.parent,
            })
            let rendered = null
            //
            rendered =
                match &&
                children[0]({
                    match: match,
                    location: location,
                })
            //
            return rendered
        }
    }
}
