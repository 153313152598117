require('@app/__tests__/setup')
const { isMandatoryField } = require('.')

describe('isMandatoryField', () => {
    test.each([
        [undefined, null, null],
        [undefined, undefined, undefined],
        [undefined, [], []],
        [undefined, {}, {}],
        [undefined, [], 'test'],
        [false, {}, 'test'],
        [true, {}, 'surgeryDate'],
        [true, {}, 'inclusionDate'],
        [true, {}, 'visitDate'],
        [
            true,
            { surgeryDate: null, visitDate: null, inclusionDate: '' },
            'surgeryDate',
        ],
        [
            false,
            { surgeryDate: null, visitDate: '20001010', inclusionDate: null },
            'surgeryDate',
        ],
        [
            false,
            { surgeryDate: '', visitDate: null, inclusionDate: '20001010' },
            'surgeryDate',
        ],
        [true, { surgeryDate: '' }, 'surgeryDate'],
        [true, { surgeryDate: null }, 'surgeryDate'],
        [true, { surgeryDate: undefined }, 'surgeryDate'],
        [
            true,
            { surgeryDate: null, visitDate: null, inclusionDate: null },
            'inclusionDate',
        ],
        [
            true,
            { surgeryDate: '20001010', visitDate: null, inclusionDate: null },
            'inclusionDate',
        ],
        [
            true,
            { surgeryDate: null, visitDate: null, inclusionDate: '20001010' },
            'inclusionDate',
        ],
        [true, { inclusionDate: '' }, 'inclusionDate'],
        [true, { inclusionDate: null }, 'inclusionDate'],
        [true, { inclusionDate: undefined }, 'inclusionDate'],
        [
            true,
            { surgeryDate: null, visitDate: null, inclusionDate: null },
            'visitDate',
        ],
        [
            true,
            { surgeryDate: '20001010', visitDate: null, inclusionDate: null },
            'visitDate',
        ],
        [
            true,
            { surgeryDate: null, visitDate: '20001010', inclusionDate: null },
            'visitDate',
        ],
        [true, { visitDate: '' }, 'visitDate'],
        [true, { visitDate: null }, 'visitDate'],
        [true, { visitDate: undefined }, 'visitDate'],
    ])(
        'should return %s when values is %s and key %s',
        (expected, values, key) => {
            expect(isMandatoryField(values, key)).toBe(expected)
        }
    )
})
