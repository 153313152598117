import { t } from "i18next";

import icoAnyCancer from "@app/img/rds/organs/any-cancer.png";
import icoAnyCancerx2 from "@app/img/rds/organs/any-cancer@2x.png";
import icoBladder from "@app/img/rds/organs/bladder.png";
import icoBladderx2 from "@app/img/rds/organs/bladder@2x.png";
import icoBrain from "@app/img/rds/organs/brain.png";
import icoBrainx2 from "@app/img/rds/organs/brain@2x.png";
import icoBreast from "@app/img/rds/organs/breast.png";
import icoBreastx2 from "@app/img/rds/organs/breast@2x.png";
import icoEsophagus from "@app/img/rds/organs/esophagus.png";
import icoEsophagusx2 from "@app/img/rds/organs/esophagus@2x.png";
import icoHematology from "@app/img/rds/organs/hematology.png";
import icoHematologyx2 from "@app/img/rds/organs/hematology@2x.png";
import icoKidney from "@app/img/rds/organs/kidney.png";
import icoKidneyx2 from "@app/img/rds/organs/kidney@2x.png";
import icoLiver from "@app/img/rds/organs/liver.png";
import icoLiverx2 from "@app/img/rds/organs/liver@2x.png";
import icoLung from "@app/img/rds/organs/lung.png";
import icoLungx2 from "@app/img/rds/organs/lung@2x.png";
import icoMelanoma from "@app/img/rds/organs/melanoma.png";
import icoMelanomax2 from "@app/img/rds/organs/melanoma@2x.png";
import icoProstate from "@app/img/rds/organs/prostate.png";
import icoProstatex2 from "@app/img/rds/organs/prostate@2x.png";
import icoRectum from "@app/img/rds/organs/rectum.png";
import icoRectumx2 from "@app/img/rds/organs/rectum@2x.png";
import icoOncoDermato from "@app/img/rds/organs/onco-dermato.png";
import icoOncoDermatox2 from "@app/img/rds/organs/onco-dermato@2x.png";
import icoHeart from "@app/img/rds/organs/heart.png";
import icoHeartx2 from "@app/img/rds/organs/heart@2x.png";
import icoGyneco from "@app/img/rds/organs/gyneco.png";
import icoGynecox2 from "@app/img/rds/organs/gyneco@2x.png";
import icoOvary from "@app/img/rds/organs/ovary.png";
import icoOvaryx2 from "@app/img/rds/organs/ovary@2x.png";

import icoAnyCancerResilience from "@app/img/rds/organs/custo/resilience/any-cancer.png";
import icoAnyCancerResiliencex2 from "@app/img/rds/organs/custo/resilience/any-cancer@2x.png";
import icoBladderResilience from "@app/img/rds/organs/custo/resilience/bladder.png";
import icoBladderResiliencex2 from "@app/img/rds/organs/custo/resilience/bladder@2x.png";
import icoBrainResilience from "@app/img/rds/organs/custo/resilience/brain.png";
import icoBrainResiliencex2 from "@app/img/rds/organs/custo/resilience/brain@2x.png";
import icoBreastResilience from "@app/img/rds/organs/custo/resilience/breast.png";
import icoBreastResiliencex2 from "@app/img/rds/organs/custo/resilience/breast@2x.png";
import icoEsophagusResilience from "@app/img/rds/organs/custo/resilience/esophagus.png";
import icoEsophagusResiliencex2 from "@app/img/rds/organs/custo/resilience/esophagus@2x.png";
import icoHematologyResilience from "@app/img/rds/organs/custo/resilience/hematology.png";
import icoHematologyResiliencex2 from "@app/img/rds/organs/custo/resilience/hematology@2x.png";
import icoKidneyResilience from "@app/img/rds/organs/custo/resilience/kidney.png";
import icoKidneyResiliencex2 from "@app/img/rds/organs/custo/resilience/kidney@2x.png";
import icoLiverResilience from "@app/img/rds/organs/custo/resilience/liver.png";
import icoLiverResiliencex2 from "@app/img/rds/organs/custo/resilience/liver@2x.png";
import icoLungResilience from "@app/img/rds/organs/custo/resilience/lung.png";
import icoLungResiliencex2 from "@app/img/rds/organs/custo/resilience/lung@2x.png";
import icoMelanomaResilience from "@app/img/rds/organs/custo/resilience/melanoma.png";
import icoMelanomaResiliencex2 from "@app/img/rds/organs/custo/resilience/melanoma@2x.png";
import icoProstateResilience from "@app/img/rds/organs/custo/resilience/prostate.png";
import icoProstateResiliencex2 from "@app/img/rds/organs/custo/resilience/prostate@2x.png";
import icoRectumResilience from "@app/img/rds/organs/custo/resilience/rectum.png";
import icoRectumResiliencex2 from "@app/img/rds/organs/custo/resilience/rectum@2x.png";
import icoOncoDermatoResilience from "@app/img/rds/organs/custo/resilience/onco-dermato.png";
import icoOncoDermatoResiliencex2 from "@app/img/rds/organs/custo/resilience/onco-dermato@2x.png";
import icoHeartResilience from "@app/img/rds/organs/custo/resilience/heart.png";
import icoHeartResiliencex2 from "@app/img/rds/organs/custo/resilience/heart@2x.png";
import icoGynecoResilience from "@app/img/rds/organs/custo/resilience/gyneco.png";
import icoGynecoResiliencex2 from "@app/img/rds/organs/custo/resilience/gyneco@2x.png";
import icoOvaryResilience from "@app/img/rds/organs/custo/resilience/ovary.png";
import icoOvaryResiliencex2 from "@app/img/rds/organs/custo/resilience/ovary@2x.png";

export const RDSMapping = [
	{
		key: "ALL_CANCER",
		label: "Tout cancer",
		i18nLabel: t("Tout cancer"),
		img: { normal: icoAnyCancer, retina: icoAnyCancerx2 },
	},
	{
		key: "BLADER",
		label: "Vessie",
		i18nLabel: t("Vessie"),
		img: { normal: icoBladder, retina: icoBladderx2 },
	},
	{
		key: "BRAIN",
		label: "Encéphale",
		i18nLabel: t("Encéphale"),
		img: { normal: icoBrain, retina: icoBrainx2 },
	},
	{
		key: "BREAST",
		label: "Sein",
		i18nLabel: t("Sein"),
		img: { normal: icoBreast, retina: icoBreastx2 },
	},
	{
		key: "UAT_ESOPHAGUS",
		label: "VADS/Oesophage",
		i18nLabel: t("VADS/Oesophage"),
		img: { normal: icoEsophagus, retina: icoEsophagusx2 },
	},
	{
		key: "HEMATOLOGY",
		label: "Onco-Hématologie",
		i18nLabel: t("Onco-Hématologie"),
		img: { normal: icoHematology, retina: icoHematologyx2 },
	},
	{
		key: "KIDNEY",
		label: "Rein",
		i18nLabel: t("Rein"),
		img: { normal: icoKidney, retina: icoKidneyx2 },
	},
	{
		key: "LIVER_GALLBLADER_PANCREAS_STOMACH",
		label: "Foie/VB/Pancreas/Estomac",
		i18nLabel: t("Foie/VB/Pancreas/Estomac"),
		img: { normal: icoLiver, retina: icoLiverx2 },
	},
	{
		key: "LUNG",
		label: "Poumon",
		i18nLabel: t("Poumon"),
		img: { normal: icoLung, retina: icoLungx2 },
	},
	{
		key: "MELANOMA",
		label: "Mélanome",
		i18nLabel: t("Mélanome"),
		img: { normal: icoMelanoma, retina: icoMelanomax2 },
	},
	{
		key: "PROSTATE",
		label: "Prostate",
		i18nLabel: t("Prostate"),
		img: { normal: icoProstate, retina: icoProstatex2 },
	},
	{
		key: "COLON_RECTUM",
		label: "Colon/Rectum",
		i18nLabel: t("Colon/Rectum"),
		img: { normal: icoRectum, retina: icoRectumx2 },
	},
	{
		key: "ONCODERMATO",
		label: "Onco-Dermatologie",
		i18nLabel: t("Onco-Dermatologie"),
		img: { normal: icoOncoDermato, retina: icoOncoDermatox2 },
	},
	{
		key: "OVARY",
		label: "Ovaire",
		i18nLabel: t("Ovaire"),
		img: { normal: icoOvary, retina: icoOvaryx2 },
	},
	{
		key: "GYNECOLOGICAL",
		label: "Gynécologique",
		i18nLabel: t("Gynécologique"),
		img: { normal: icoGyneco, retina: icoGynecox2 },
	},
	{
		key: "HEART",
		label: "Coeur",
		i18nLabel: t("Coeur"),
		img: { normal: icoHeart, retina: icoHeartx2 },
	},
	{
		key: "ONCOHEMATO",
		label: "Onco-Hémato",
		i18nLabel: t("Onco-Hémato"),
		img: { normal: icoHematology, retina: icoHematologyx2 },
	},
];

export const RDSMappingResilience = [
	{
		key: "ALL_CANCER",
		label: "Tout cancer",
		i18nLabel: t("Tout cancer"),
		img: {
			normal: icoAnyCancerResilience,
			retina: icoAnyCancerResiliencex2,
		},
	},
	{
		key: "BLADER",
		label: "Vessie",
		i18nLabel: t("Vessie"),
		img: { normal: icoBladderResilience, retina: icoBladderResiliencex2 },
	},
	{
		key: "BRAIN",
		label: "Encéphale",
		i18nLabel: t("Encéphale"),
		img: { normal: icoBrainResilience, retina: icoBrainResiliencex2 },
	},
	{
		key: "BREAST",
		label: "Sein",
		i18nLabel: t("Sein"),
		img: { normal: icoBreastResilience, retina: icoBreastResiliencex2 },
	},
	{
		key: "UAT_ESOPHAGUS",
		label: "VADS/Oesophage",
		i18nLabel: t("VADS/Oesophage"),
		img: {
			normal: icoEsophagusResilience,
			retina: icoEsophagusResiliencex2,
		},
	},
	{
		key: "HEMATOLOGY",
		label: "Onco-Hématologie",
		i18nLabel: t("Onco-Hématologie"),
		img: {
			normal: icoHematologyResilience,
			retina: icoHematologyResiliencex2,
		},
	},
	{
		key: "KIDNEY",
		label: "Rein",
		i18nLabel: t("Rein"),
		img: { normal: icoKidneyResilience, retina: icoKidneyResiliencex2 },
	},
	{
		key: "LIVER_GALLBLADER_PANCREAS_STOMACH",
		label: "Foie/VB/Pancreas/Estomac",
		i18nLabel: t("Foie/VB/Pancreas/Estomac"),
		img: { normal: icoLiverResilience, retina: icoLiverResiliencex2 },
	},
	{
		key: "LUNG",
		label: "Poumon",
		i18nLabel: t("Poumon"),
		img: { normal: icoLungResilience, retina: icoLungResiliencex2 },
	},
	{
		key: "MELANOMA",
		label: "Mélanome",
		i18nLabel: t("Mélanome"),
		img: { normal: icoMelanomaResilience, retina: icoMelanomaResiliencex2 },
	},
	{
		key: "PROSTATE",
		label: "Prostate",
		i18nLabel: t("Prostate"),
		img: { normal: icoProstateResilience, retina: icoProstateResiliencex2 },
	},
	{
		key: "COLON_RECTUM",
		label: "Colon/Rectum",
		i18nLabel: t("Colon/Rectum"),
		img: { normal: icoRectumResilience, retina: icoRectumResiliencex2 },
	},
	{
		key: "ONCODERMATO",
		label: "Onco-Dermatologie",
		i18nLabel: t("Onco-Dermatologie"),
		img: {
			normal: icoOncoDermatoResilience,
			retina: icoOncoDermatoResiliencex2,
		},
	},
	{
		key: "OVARY",
		label: "Ovaire",
		i18nLabel: t("Ovaire"),
		img: { normal: icoOvaryResilience, retina: icoOvaryResiliencex2 },
	},
	{
		key: "GYNECOLOGICAL",
		label: "Gynécologique",
		i18nLabel: t("Gynécologique"),
		img: { normal: icoGynecoResilience, retina: icoGynecoResiliencex2 },
	},
	{
		key: "HEART",
		label: "Coeur",
		i18nLabel: t("Coeur"),
		img: { normal: icoHeartResilience, retina: icoHeartResiliencex2 },
	},
	{
		key: "ONCOHEMATO",
		label: "Onco-Hémato",
		i18nLabel: t("Onco-Hémato"),
		img: {
			normal: icoHematologyResilience,
			retina: icoHematologyResiliencex2,
		},
	},
];

import icoTreatment from "@app/img/rds/surveys/treatment-big.png";
import icoTreatmentx2 from "@app/img/rds/surveys/treatment-big@2x.png";
import icoSurvey from "@app/img/rds/surveys/surveillance-big.png";
import icoSurveyx2 from "@app/img/rds/surveys/surveillance-big@2x.png";

export const RDSMappingPhase = [
	{
		key: "TREATMENT",
		label: "Traitement",
		i18nLabel: t("Traitement"),
		img: { normal: icoTreatment, retina: icoTreatmentx2 },
	},
	{
		key: "MONITORING",
		label: "Surveillance",
		i18nLabel: t("Surveillance"),
		img: { normal: icoSurvey, retina: icoSurveyx2 },
	},
	{
		key: "TREATMENT_OR_MONITORING",
		label: "Traitement ou Surveillance",
		i18nLabel: t("Traitement ou Surveillance"),
		img: { normal: icoTreatment, retina: icoTreatmentx2 },
	},
	{
		key: "REMOTE_MONITORING",
		label: "Télésurveillance",
		i18nLabel: t("Télésurveillance"),
		img: { normal: icoTreatment, retina: icoTreatmentx2 },
	},
];

export const RDSTreatmentMapping = [
	{
		key: "rectumCITC",
		phase: "TREATMENT",
		usualName: "Colon/Rectum - CITC",
		i18nUsualName: t("Colon/Rectum - CITC"),
		tags: [
			"Radiothérapie",
			"Avec chimiothérapie",
			"Sans chimiothérapie",
			"Non métastatique",
		],
		clinicalIndication:
			"Questionnaire indiqué pendant la phase de chimiothérapie, immunothérapie, thérapie ciblée - métastatique et non métastatique",
		frequencyAvailable: ["monthly", "quaterly"],
	},
	{
		key: "rectumR",
		phase: "TREATMENT",
		usualName: "Colon/Rectum - R",
		i18nUsualName: t("Colon/Rectum - R"),
		tags: [
			"Radiothérapie",
			"Avec chimiothérapie",
			"Sans chimiothérapie",
			"Non métastatique",
		],
		clinicalIndication:
			"Questionnaire indiqué pendant la phase de chimiothérapie, immunothérapie, thérapie ciblée - métastatique et non métastatique",
		frequencyAvailable: ["monthly", "quaterly"],
	},
];

export const RDSTagsMapping = [
	{
		key: "CHEMOTHERAPY",
		label: "Chimiothérapie",
		i18nLabel: t("Chimiothérapie"),
	},
	{
		key: "IMMUNOTHERAPY",
		label: "Immunothérapie",
		i18nLabel: t("Immunothérapie"),
	},
	{
		key: "TARGETED_THERAPY",
		label: "Thérapie ciblée",
		i18nLabel: t("Thérapie ciblée"),
	},
	{ key: "SURGERY", label: "Chirurgie", i18nLabel: t("Chirurgie") },
	{
		key: "RADIOTHERAPY",
		label: "Radiothérapie",
		i18nLabel: t("Radiothérapie"),
	},
	{
		key: "WITH_HORMONE_THERAPY",
		label: "Avec hormonothérapie",
		i18nLabel: t("Avec hormonothérapie"),
	},
	{
		key: "WITHOUT_HORMONE_THERAPY",
		label: "Sans hormonothérapie",
		i18nLabel: t("Sans hormonothérapie"),
	},
	{ key: "METASTATIC", label: "Métastatique", i18nLabel: t("Métastatique") },
	{
		key: "NON_METASTATIC",
		label: "Non métastatique",
		i18nLabel: t("Non métastatique"),
	},
	{
		key: "ALL_TREATMENTS",
		label: "Tous traitements",
		i18nLabel: t("Tous traitements"),
	},
	{
		key: "ALL_PHASES",
		label: "Toutes phases",
		i18nLabel: t("Toutes phases"),
	},
	{
		key: "POST_CHEMOTHERAPY",
		label: "Post chimiothérapie",
		i18nLabel: t("Post chimiothérapie"),
	},
	{
		key: "POST_IMMUNOTHERAPY",
		label: "Post immunothérapie",
		i18nLabel: t("Post immunothérapie"),
	},
	{
		key: "POST_TARGETED_THERAPY",
		label: "Post thérapie ciblée",
		i18nLabel: t("Post thérapie ciblée"),
	},
	{
		key: "POST_SURGERY",
		label: "Post chirurgie",
		i18nLabel: t("Post chirurgie"),
	},
	{
		key: "POST_RADIOTHERAPY",
		label: "Post radiothérapie",
		i18nLabel: t("Post radiothérapie"),
	},
	{
		key: "POST_BRACHYTHERAPY",
		label: "Post curiethérapie",
		i18nLabel: t("Post curiethérapie"),
	},
	{
		key: "WITHOUT_TREATMENT",
		label: "Sans traitement",
		i18nLabel: t("Sans traitement"),
	},
	{
		key: "WITH_CHEMOTHERAPY",
		label: "Avec chimiothérapie",
		i18nLabel: t("Avec chimiothérapie"),
	},
	{
		key: "WITHOUT_CHEMOTHERAPY",
		label: "Sans chimiothérapie",
		i18nLabel: t("Sans chimiothérapie"),
	},
	{
		key: "WITH_ORAL_THERAPY",
		label: "Avec thérapie orale",
		i18nLabel: t("Avec thérapie orale"),
	},
	{
		key: "WITHOUT_ORAL_THERAPY",
		label: "Sans thérapie orale",
		i18nLabel: t("Sans thérapie orale"),
	},
	{
		key: "CHRONIC_HEART_FAILURE",
		label: "Insuffisance cardiaque chronique",
		i18nLabel: t("Insuffisance cardiaque chronique"),
	},
	{ key: "ADJUVANT", label: "Adjuvant", i18nLabel: t("Adjuvant") },
	{
		key: "WITHOUT_ADJUVANT",
		label: "Sans adjuvant",
		i18nLabel: t("Sans adjuvant"),
	},
	{
		key: "POST_TREATMENT",
		label: "Post traitement",
		i18nLabel: t("Post traitement"),
	},
	{ key: "AUTOGRAFT", label: "Autogreffe", i18nLabel: t("Autogreffe") },
	{ key: "ALLOGRAFT", label: "Allogreffe", i18nLabel: t("Allogreffe") },
];
