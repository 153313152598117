require('@app/__tests__/setup')
const {
    sortTimelineByStudySurvey,
    sortTimelineByStudyMoment,
    sortTimelineByStudyDate,
} = require('.')
const {
    uroanxiety113dipru,
    uroanxiety113dipruSortedBySurvey,
    uroanxiety113dipruSortedByMoment,
    uroanxiety113dipruSortedByDate,
    uroanxiety113dipruSortedByAllSort,
} = require('./index-spec-data')

describe('sortTimelineByStudySurvey', () => {
    test.each([
        [[], []],
        [uroanxiety113dipruSortedBySurvey, uroanxiety113dipru.timeline],
    ])(
        'should return %s when values is %s and key %s',
        (expected, timeline) => {
            expect(timeline.sort(sortTimelineByStudySurvey)).toStrictEqual(
                expected
            )
        }
    )
})

describe('sortTimelineByStudyMoment', () => {
    test.each([
        [[], []],
        [uroanxiety113dipruSortedByMoment, uroanxiety113dipru.timeline],
    ])(
        'should return %s when values is %s and key %s',
        (expected, timeline) => {
            expect(timeline.sort(sortTimelineByStudyMoment)).toStrictEqual(
                expected
            )
        }
    )
})

describe('sortTimelineByStudyDate', () => {
    test.each([
        [[], []],
        [uroanxiety113dipruSortedByDate, uroanxiety113dipru.timeline],
    ])(
        'should return %s when values is %s and key %s',
        (expected, timeline) => {
            expect(timeline.sort(sortTimelineByStudyDate)).toStrictEqual(
                expected
            )
        }
    )
})

describe('sortTimelineByStudySurvey && sortTimelineByStudyMoment && sortTimelineByStudyDate', () => {
    test.each([
        [[], []],
        [uroanxiety113dipruSortedByAllSort, uroanxiety113dipru.timeline],
    ])(
        'should return %s when values is %s and key %s',
        (expected, timeline) => {
            let sortedTimeline = timeline
                .sort(sortTimelineByStudyMoment)
                .sort(sortTimelineByStudyMoment)
                .sort(sortTimelineByStudyDate)
            expect(sortedTimeline).toStrictEqual(expected)
        }
    )
})
