import { t } from 'i18next';
import { h } from 'hyperapp';
import { Card, Button, Form, Tile, Enter, FormInput } from '@app/elements';
import { isDefined } from '@app/core';
import * as constants from '@app/constants';

import './../index.scss';

export default {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div class='patient-central'>
                <Card classes={'btzPageCard btzBackgroundCover'} kind={'datas'} style={{ 'margin': '30px 30px 0px 30px' }}>
                    <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                        <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
                            <dummy>
                                <Tile>
                                    {state.loaded &&
                                        <dummy>
                                            <Tile.Content>
                                                <p class='btzTitle btzXl'>
                                                    {t('Modules activés') + ' (' + state.etp.filter((f) => (f.status && isDefined(constants.custo.etpSettings[f.name]) && (state.editedPatient.themes.indexOf(constants.custo.etpSettings[f.name].key) > -1))).length + ')'}
                                                </p>
                                            </Tile.Content>
                                            {(state.editedPatient.customer.customerModules.etp === true) &&
                                                <Form onsubmit={event => event.preventDefault()} classes={'columns'}>
                                                    <div class='col-12'>
                                                        <Form.Group>
                                                            {state.etp.filter((f) => (f.status)).map((item, idx) =>
                                                                <div class='col-6 col-xs-12 thfAlignTop'>
                                                                    <FormInput
                                                                        key={'input-etp-' + idx}
                                                                        type='switch'
                                                                        id={(isDefined(constants.custo.etpSettings[item.name]) ? constants.custo.etpSettings[item.name].key : false)}
                                                                        name={'etp-' + idx}
                                                                        value={(state.editedPatient.themes.indexOf(constants.custo.etpSettings[item.name].key) === -1) ? false : true}
                                                                        list={[{'label': t(constants.custo.etpSettings[item.name].value), 'key': true}, {'label': t(constants.custo.etpSettings[item.name].value), 'key': false}]}
                                                                        validation={true}
                                                                        loaded={state.loaded}
                                                                        oninputcb={actions.retrieveInput}
                                                                        required
                                                                    />
                                                                </div>
                                                            )}
                                                        </Form.Group>
                                                    </div>
                                                </Form>
                                            }
                                            {(state.editedPatient.customer.customerModules.programETP === true) &&
                                                <dummy>
                                                    <Tile.Content>
                                                        <p class='btzTitle btzXl' style='margin-top: 40px;'>
                                                            {t('Programmes activés') + ' (' + state.programs.length + ')'}
                                                        </p>
                                                    </Tile.Content>
                                                    <Form onsubmit={event => event.preventDefault()} classes={'columns'}>
                                                        <div class='col-12'>
                                                            <Form.Group>
                                                                {state.availablePrograms.map((item, idx) =>
                                                                    <div class='col-6 col-xs-12 thfAlignTop'>
                                                                        <FormInput
                                                                            key={'input-program-' + idx}
                                                                            type='switch'
                                                                            id={item.id}
                                                                            name={'program-' + idx}
                                                                            value={isDefined(state.programs.filter((f) => f === item.id)[0])}
                                                                            list={[{'label': item.name, 'key': true}, {'label': item.name, 'key': false}]}
                                                                            validation={true}
                                                                            loaded={state.loaded}
                                                                            oninputcb={actions.retrieveInput}
                                                                            required
                                                                        />
                                                                    </div>
                                                                )}
                                                            </Form.Group>
                                                        </div>
                                                    </Form>
                                                </dummy>
                                            }
                                            <Form onsubmit={event => event.preventDefault()} classes={'columns'}>
                                                <Form.Group classes='btzForm-btzFooter'>
                                                    <Button loading={(state.formIsSubmitting)} active onclick={actions.onSubmit}>
                                                        {t('Enregistrer')}
                                                    </Button>
                                                </Form.Group>
                                            </Form>
                                        </dummy>
                                    }
                                </Tile>
                            </dummy>
                        </Enter>
                    </Card.Header>
                </Card>
            </div>
        );
        return view;
    }
}