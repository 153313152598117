import * as constants from '@app/constants';
export const state = {
    patientid: null,
    editedPatient: null,
    autoGeneratedEmail: false,
    doctor: null,
    medicalStudy: {},
    medicalStudyEdited: null,
    medicalStudyEditedCanceled: null,
    medicalStudyCards: [],
    medicalStudyAnswers: [],
    medicalStudies: [],
    medicalStudiesConst: constants.custo.configureSurvey,
    page: 0,
    formIsSubmitting: false,
    values: {
        issuePeriod: 'weeklyMonday',
        issueSentBy: 'SMS',
    },
    isTouched: {
        issuePeriod: true,
        issueSentBy: true,
    },
    isValid: {
        issuePeriod: true,
        issueSentBy: true,
    },
    errorMessages: {
        issueSentBy: '',
    },
    isTyping: {
        issuePeriod: true,
        issueSentBy: true,
    },
    loaded: false,
    thereAreNextPage: false,
    newStudy: {
        name: 'toutk00001',
        frequency: 'weekly',
        issuePeriod: 'weeklyMonday',
        issueSentBy: 'SMS',
    },
    isValidForm: false,
    formSubmittedOnce: false,
    modalDisplayed: false,
    modalEditing: '',
    popupDisplayed: '',
    referenceHealTeam: [],
    patientHealTeam: [],
    currentAnswer: null,
    queryParam: {
        studyid: null,
        commentid: null,
    },
    newAnswerSelected: false,
    justSendedSurvey: false,
    configurationMode: true,
    selectedOrgan: null,
    surveyPreviewDisplayed: false,
    summaryDisplayed: false,
    switchPhase: false,
    foundComonPhase: null,
    newSurvey: false,
    rdsTreatments: [],
    repeatableMedicalInfos: [],
    autoOpenAnswerFromEmailId: null,
    cardsContentPreview: [],
    surveyUrl: null,
    modaldisplayed: '',
    lastEditedStudy: null,
};
