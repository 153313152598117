export const state = {
    page: 0,
    bypage: 25,
    alerts: null,
    info: null,
    alertColors: [0, 1, 2, 3, 4],
    alertDetail: null,
    displayedPopup: '',
    noPatients: false,
    doctor: null,
    enableSurvey: [],
    selectedSurvey: 'curve',
    selectedAlertColors: 'null',
    selectedDate: 'P30D-P0D',
    selectedStatus: 'null',
    selectedAlert: [],
    sort: {sort: {date: -1}},
    totalAlerts: null,
    noAlertAtAll: false,
};