import { t } from 'i18next';
import { h } from 'hyperapp';
import { Card, Button, Form, Enter } from '@app/elements';
import * as constants from '@app/constants';

export default {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div class='patient-central'>
                <Card classes={'btzPageCard btzBackgroundCover'} kind={'datas'} style={{ 'margin': '30px 30px 0px 30px', 'padding': '0px', 'background-color': 'transparent' }}>
                    <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                        <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
                            <dummy>
                                {state.medicalStudies.map((item, idx) =>
                                    <div class='thfStudyBlock-thfStudyBlockWrapper'>
                                        <div class='thfStudyBlock-thfStudyBlockHeader'>
                                            <div class='thfStudyBlock-thfStudyBlockHeader-puce'><p>{(idx < 9 ? '0' : '') + (idx + 1)}</p></div>
                                            <div class='thfStudyBlock-thfStudyBlockHeader-infos'>
                                                <p class='thfStudyBlock-thfStudyBlockHeader-infosName'>{item.name}</p>
                                                <p class={'thfStudyBlock-thfStudyBlockHeader-infosStatus ' + (item.status ? '' : 'text-dangerous')}>{(item.status ? t('Questionnaire activé') : t('Questionnaire désactivé'))}</p>
                                            </div>
                                        </div>

                                        <Form.Group>
                                            <div class='col-6 col-xs-12 thfAlignTop'>
                                                <p class='btzForm-btzLabel'>{t('Fréquence d\'envoi')}</p>
                                                <Form.Input
                                                    placeholder={t('')}
                                                    type='text'
                                                    id='frequency'
                                                    name='frequency'
                                                    readonly={true}
                                                    value={constants.custo.periodFrequenciesPeriods.filter((f) => f.key === item.frequency)[0].label}
                                                    oninput={actions.waitForLastInput}
                                                    onfocus={actions.onBlur}
                                                    oncreate={actions.onCreate}
                                                    loaded={state.loaded}
                                                    classes={'thfInput'}
                                                    required
                                                />
                                            </div>
                                            <div key={state.formIsSubmitting} class='col-6 col-xs-12 thfAlignTop'>
                                                <p class='btzForm-btzLabel'>{t('Emission')}</p>
                                                <Form.Dropdown
                                                    type='select'
                                                    id='issuePeriod'
                                                    name='issuePeriod'
                                                    list={constants.custo.periodFrequencies}
                                                    search={false}
                                                    textedarea={false}
                                                    value={item.issuePeriod}
                                                    onclick={(e) => { actions.updateStudy({studyid: item.id, issuePeriod: e.target.value}) }}
                                                    onfocus={actions.onBlur}
                                                    oncreate={actions.onCreate}
                                                    loaded={state.loaded}
                                                    classes={'thfInput'}
                                                    required
                                                />
                                            </div>
                                        </Form.Group>

                                        {item.status === true ?
                                            <Form.Group classes='btzForm-btzFooter' style={{ 'text-align': 'center', 'padding-top': '20px' }}>
                                                <Button loading={(state.formIsSubmitting)} dangerous onclick={() => { actions.onSwitchStudySend({studyid: item.id, status: false}); }}>
                                                    {t('Arrêter')}
                                                </Button>
                                            </Form.Group>
                                            :
                                            <Form.Group classes='btzForm-btzFooter' style={{ 'text-align': 'center', 'padding-top': '20px' }}>
                                                <Button active loading={(state.formIsSubmitting)} onclick={() => { actions.onSwitchStudySend({studyid: item.id, status: true}); }}>
                                                    {t('Activer')}
                                                </Button>
                                            </Form.Group>
                                        }
                                    </div>
                                )}
                            </dummy>
                            <dummy>
                                {constants.custo.configureSurvey.length > state.medicalStudies.length &&
                                    <div class='thfStudyBlock-thfStudyBlockWrapper'>
                                        <Form.Group>
                                            <div class='col-6 col-xs-12 thfAlignTop'>
                                                <p class='btzForm-btzLabel'>{t('Nom du questionnaire')}</p>
                                                <Form.Dropdown
                                                    type='select'
                                                    id='name'
                                                    name='name'
                                                    list={constants.custo.configureSurvey}
                                                    search={false}
                                                    textedarea={false}
                                                    value={state.newStudy.name}
                                                    onclick={(e) => { actions.updateToCreateStudy({field: 'name', value: e.target.value}); actions.updateToCreateStudy({field: 'frequency', value: constants.custo.configureSurvey.filter((f) => f.key === e.target.value)[0].frequency}); }}
                                                    onfocus={actions.onBlur}
                                                    loaded={state.loaded}
                                                    classes={'thfInput'}
                                                    required
                                                />
                                            </div>
                                            <div class='col-6 col-xs-12 thfAlignTop'>
                                                <p class='btzForm-btzLabel'>{t('Fréquence d\'envoi')}</p>
                                                <Form.Input
                                                    placeholder={t('')}
                                                    type='text'
                                                    id='frequency'
                                                    name='frequency'
                                                    readonly={true}
                                                    value={((state.newStudy.name !== '') ? (constants.custo.periodFrequenciesPeriods.filter((f) => f.key === constants.custo.configureSurvey.filter((f) => f.key === state.newStudy.name)[0].frequency)[0].label) : '')}
                                                    oninput={actions.waitForLastInput}
                                                    onfocus={actions.onBlur}
                                                    loaded={state.loaded}
                                                    classes={'thfInput'}
                                                    required
                                                />
                                            </div>
                                            <div key={state.formIsSubmitting} class='col-6 col-xs-12 thfAlignTop'>
                                                <p class='btzForm-btzLabel'>{t('Emission')}</p>
                                                <Form.Dropdown
                                                    type='select'
                                                    id='issuePeriod'
                                                    name='issuePeriod'
                                                    list={(state.newStudy.name !== '') ? constants.custo.periodFrequencies.filter((f) => f.frequency === constants.custo.configureSurvey.filter((f) => f.key === state.newStudy.name)[0].frequency) : []}
                                                    readonly={(state.newStudy.name !== '') ? false : true}
                                                    search={false}
                                                    textedarea={false}
                                                    value={state.newStudy.issuePeriod}
                                                    onclick={(e) => { actions.updateToCreateStudy({field: 'issuePeriod', value: e.target.value}) }}
                                                    onfocus={actions.onBlur}
                                                    loaded={state.loaded}
                                                    classes={'thfInput'}
                                                    required
                                                />
                                            </div>
                                        </Form.Group>

                                        {state.loaded &&
                                            <Form.Group classes='btzForm-btzFooter' style={{ 'text-align': 'center', 'padding-top': '20px' }}>
                                                <Button loading={(state.formIsSubmitting)} active={((state.newStudy.name !== '') && (state.newStudy.issuePeriod !== '')) } onclick={actions.onSaveStudy}>
                                                    {t('Sauvegarder')}
                                                </Button>
                                            </Form.Group>
                                        }
                                    </div>
                                }
                            </dummy>
                        </Enter>
                    </Card.Header>
                </Card>
            </div>
        );
        return view;
    }
}