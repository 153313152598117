/*
This file serves as an "Interface", like in Java. For each namespace in the API, 
it exposes an Object Literal with a bunch of methods that corresponding to it. 
Underneath, it uses ES6' Fetch API instead of XMLHttpRequest, as it is more verbose. 
Any response is parsed in JSON by default.

Convention should be to use ES6' "named export" instead of "default export" to 
take advantage of "tree shaking": meaning, having *only* executed code bundled
instead of the whole files.
*/

export { User } from './User';
export { Admin } from './Admin';
export { Alert } from './Alert';
export { Hospital } from './Hospital';
export { Medical } from './Medical';
export { Phone } from './Phone';
export { Schedule } from './Schedule';
export { Session } from './Session';
export { Telesuivi } from './Telesuivi';
export { Treatment } from './Treatment';
export { TwilioAPI } from './Twilio';
export { ClientErrors } from './ClientErrors';
export { Consent } from './Consent';