import * as constants from '@app/constants';
export const state = {
    values: {
        email: '',
        password: '',
        code: ['', '', '', '', '', ''],
        token: '',
        phone: ['', '', '', '', ''],
    },
    isTouched: {
        email: false,
        password: false,
        code: false,
    },
    isValid: {
        email: false,
        password: false,
        code: false,
    },
    isTyping: {
        email: false,
        password: false,
        code: false,
    },
    errorMessages: {
        email: '',
        password: '',
        code: '',
    },
    api: {
        message: '',
        code: 200,
        kind: 'info'
    },
    customer: 'theraflow',
    accessToken: null,
    loginStep: 'login',
    isPasswordContentVisible: true,
    message: [],
    formIsSubmitting: false,
    formSubmittedAtLeastOneTime: false,
    sendToParent: null,
    rendering: false,
    targetapi: {value: 'theraflow-api.betterise.me', name: 'overrideapi', list: constants.custo.APIS},
    formSubmittedOnce: false,
    confirmRefresh: null,
};
