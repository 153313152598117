import { Component } from '@app/utils';
import { h } from 'hyperapp';
import './index.scss';
import { supportedBrowsers, getUserAgentInfos } from './utils';
import { t } from 'i18next';
import * as constants from '@app/constants'
import vulnerabilityIco from '../../img/ico/vulnerability.svg';
import leaveIco from '../../img/ico/leave.svg';

/**
 * @param {any} props All properties to element
 * @param {any} children All children to be nested inside
 * @return vNode
 * @example
 * <UnsupportedBrowserBanner {...props}>
 *   {children}
 * </UnsupportedBrowserBanner>
 */
const UnsupportedBrowserBanner = Component(
    {
        customer: null
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            const mailto = constants.custo.CUSTOMERS[props.customer].mailto
            const serviceName = constants.custo.CUSTOMERS[props.customer].title
            const browserInfos = getUserAgentInfos(navigator.userAgent)
            const { supported, isMobile, browserName, browserMajor } = browserInfos

            if (!supported) {
                const minVersionsSupported =
                supportedBrowsers.reduce((acc, browser) => {
                    return { ...acc, [browser.name]: browser.minVersion }
                }, {})

                const chromeDownloadUrl = `<a href="https://www.google.com/chrome/" target="_blank" rel="noreferrer noopener">Google Chrome v${minVersionsSupported['Chrome']}</a>`
                const firefoxDownloadUrl = `<a href="https://www.mozilla.org/firefox/new/" target="_blank" rel="noreferrer noopener">Mozilla Firefox v${minVersionsSupported['Firefox']}</a>`
                const edgeDownloadUrl = `<a href="https://www.microsoft.com/edge/download" target="_blank" rel="noreferrer noopener">Microsoft Edge v${minVersionsSupported['Edge']}</a>`
                const supportUrl = `<a href="mailto:${mailto}">${mailto}</a>`

                const commonInterpolationVariables = {
                    service: serviceName,
                    chromeDownloadUrl,
                    firefoxDownloadUrl,
                    edgeDownloadUrl,
                    supportUrl,
                    interpolation: {
                        escapeValue: false,
                    },
                }

                const i18nKey = isMobile
                    ? 'unsupportedBrowserBanner.mobile.text'
                    : 'unsupportedBrowserBanner.desktop.text'

                const interpolationVariables = isMobile
                    ? commonInterpolationVariables
                    : { ...commonInterpolationVariables, browserName, browserMajor }

                const html = t(i18nKey, interpolationVariables)

                actions.setTitle(t('unsupportedBrowserBanner.title'));
                actions.setText(html);
                actions.setClosed(false);
            }
            else {
                actions.setClosed(true);
            }
        },

        setTitle: newState => state => ({
            title: newState,
        }),

        setText: newState => state => ({
            text: newState,
        }),

        setClosed: newState => state => ({
            closed: newState,
        }),
    },
    (state, actions) => (props, children) => { 
        
        if (!state.closed) {
            return (
            <div class="banner-wrapper">
                <div className='banner-iconWrapper'>
                    <img src={vulnerabilityIco} alt="" />
                </div>
                <div className='banner-alertBannerTextWrapper'>
                    <span>
                        {state.title}
                    </span>
                    <span innerHTML={state.text}></span>
                </div>
                <button
                    type="button"
                    onclick={() => {actions.setClosed(true)}}
                    className='banner-closeIconWrapper'
                >
                    <img src={leaveIco} alt="" />
                </button>
            </div>)}
    },
    'banner'
);

export { UnsupportedBrowserBanner };