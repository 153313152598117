import { t } from 'i18next';
import { h } from 'hyperapp';
import { isDefined, getReadableDate, createUTCDate } from '@app/core';
import { Card, Enter } from '@app/elements';
import * as constants from '@app/constants';

import Profil from './../subpages/profil';

export default {
    initView: function() {
        let AccountView = (state, actions) => (props, children) => (
            <div class=''>
                {state.loaded &&
                    <dummy>
                        <Card classes={'btzPageCard btzBackgroundCover'} kind={'datas'} style={{ 'padding-bottom': '10px' }}>
                            <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                                <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
                                    <div class='btzCard-btzHeader-btzPage-btzWrapper'>
                                        {isDefined(state.doctor) &&
                                            <div class='btzCard-btzHeader-btzPage-btzInfo' style='max-height: 90px;'>
                                                <div class='col-12 btzCard-btzHeader-topMargin' style='padding-left: 0px;vertical-align: top;'>
                                                    <div class='btzCard-btzHeader-btzPage-btzName' style='display: inline-block;max-width: 75%;'><p><font>{state.doctor.lname}</font>{' ' + state.doctor.fname}</p></div>
                                                </div>
                                                <div class='btzCard-btzContent-btzPage-btzInfo' style='display: block;margin-top: 15px;'>
                                                    <div class='btzCard-btzHeader-btzPage-btzInfoDevided'>
                                                        <p class='btzCard-btzHeader-btzPage-btzInfoSubTitle'>{t('Établissement') + ' : ' + state.doctor.customer + ' - ' + ((isDefined(state.refHospital) && isDefined(state.refHospital.parent)) ? state.refHospital.parent.name : t('Hopital de référence'))}</p>
                                                    </div>
                                                </div>
                                                <div class='btzCard-btzContent-btzPage-btzInfo'>
                                                    <div class='btzCard-btzHeader-btzPage-btzInfoDevided'>
                                                        <p class='btzCard-btzHeader-btzPage-btzInfoSubTitle'>{t('Dernière connexion') + ' : ' + (isDefined(state.doctor.lastConnexion) ? getReadableDate(createUTCDate(state.doctor.lastConnexion.date.date), 'DD MMMM YYYY, HH:mm') : 'Ø') + ' - ' + t('Télésuivi') + ' : '}<font>{(isDefined(state.informations) ? (state.informations.totalPatients + ' ' + t('patients')) : 'Ø')}</font></p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </Enter>
                            </Card.Header>
                        </Card>

                        <div key={state.currentPage}>
                            <Profil {...state} updateDoctor={actions.getDoctor} />
                        </div>
                    </dummy>
                }
            </div>
        );
        return AccountView;
    }
}