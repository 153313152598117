import { t } from 'i18next'
import { h } from 'hyperapp'
import { Component } from '@app/utils'
import { Medical } from '@app/api'
import { Button, Card, Form, FormInput } from '@app/elements'
import {
    isDefined,
    coreFn,
    getReadableDate,
    createUTCDate,
    getDiffDate,
} from '@app/core'

import * as constants from '@app/constants'

import icoSend from '@app/img/ico/ico-send.png'
import icoSendx2 from '@app/img/ico/ico-send@2x.png'
import icoSendWhite from '@app/img/ico/ico-send-white.png'
import icoSendWhitex2 from '@app/img/ico/ico-send-white@2x.png'
import icoSendActive from '@app/img/ico/ico-send-active.png'
import icoSendActivex2 from '@app/img/ico/ico-send-active@2x.png'

import './commentFeature.scss'

export default Component(
    {
        newComment: { taggedUsers: [], content: '' },
        isValid: {
            contentEditable: false,
        },
        isTouched: {
            contentEditable: false,
        },
        isTyping: {
            contentEditable: false,
        },
        errorMessages: {
            contentEditable: '',
        },
        callback: null,
        comments: [],
        currentAnswer: null,
        formSubmittedOnce: false,
        closeButton: true,
    },
    {
        ...coreFn,
        onComponentCreate: (props) => (state, actions) => {
            if (isDefined(props.closeButton)) {
                actions._setState({
                    key: 'closeButton',
                    value: props.closeButton,
                })
            }
            actions.onComponentUpdate(props)
        },
        onComponentUpdate: (props) => (state, actions) => {
            if (isDefined(props.currentAnswer)) {
                let currentAnswer = props.currentAnswer
                if (
                    !isDefined(currentAnswer.usualName) &&
                    isDefined(props.medicalStudyEdited)
                ) {
                    currentAnswer.usualName = props.medicalStudyEdited.usualName
                }
                actions._setState({
                    key: 'currentAnswer',
                    value: currentAnswer,
                })
            }
            if (isDefined(props.comments)) {
                actions._setState({ key: 'comments', value: props.comments })
            }
            if (isDefined(props.callback)) {
                actions._setState({ key: 'callback', value: props.callback })
            }
        },
        submitComment: () => (state, actions) => {
            if (state.newComment.content === '') {
                state.isValid.contentEditable = false
                actions._setErrorMessages({
                    contentEditable: t('veuillez renseigner votre commentaire'),
                })
                return false
            }
            if (state.formSubmittedOnce) {
                return false
            }
            actions._setState({ key: 'formSubmittedOnce', value: true })
            actions._setTyping({ contentEditable: false })
            //
            Medical.addStudyAnswerComment(
                state.currentAnswer.id,
                state.newComment
            ).then((res) => {
                let comments = state.comments,
                    currentAnswer = res.data
                if (!isDefined(currentAnswer.usualName)) {
                    currentAnswer.usualName = state.currentAnswer.usualName
                }
                comments.push(state.newComment)
                if (!isDefined(currentAnswer.values)) {
                    currentAnswer.values = state.currentAnswer.values
                }
                actions._setState({
                    key: 'currentAnswer',
                    value: currentAnswer,
                })
                actions._setState({
                    key: 'newComment',
                    value: { taggedUsers: [], content: '' },
                })
                actions._setState({ key: 'formSubmittedOnce', value: false })
                //
                if (isDefined(state.callback)) {
                    state.callback(currentAnswer)
                }
            })
        },
        retrieveCommentAuthorAndDate:
            ({ el, item }) =>
            (state, actions) => {
                let diffDate = getDiffDate(
                    createUTCDate(item.date.date),
                    createUTCDate()
                )
                let returnString = item.author.fname + ' ' + item.author.lname
                if (diffDate < 1) {
                    returnString +=
                        ' ' + t("a ajouté un commentaire aujourd'hui")
                } else {
                    returnString +=
                        t('a ajouté un commentaire il y a') + ' ' + diffDate
                    if (diffDate > 1) {
                        returnString += ' ' + t('jours')
                    } else {
                        returnString += ' ' + t('jour')
                    }
                }
                el.innerHTML = returnString
            },
        retrieveTaggedDoctor: (input) => (state, actions) => {
            const { name, values } = input
            let taggedContent = {
                content: values.value,
                taggedUsers: values.tags,
            }
            if (values.value !== '') {
                state.isValid.contentEditable = true
                actions._setErrorMessages({ contentEditable: '' })
                actions._setTyping({ contentEditable: true })
            }
            actions._setState({ key: 'newComment', value: taggedContent })
        },
    },
    (state, actions) => (props, children) => (
        <Card
            classes={'btzInformations-btzInformationsModal btzCommentFeature'}
        >
            <Card.Header classes={'btzCard-btzHeader-btzModal'}>
                <div key={'treatment'}>
                    {isDefined(state.currentAnswer) && (
                        <p class="btzTitle btzXl">
                            {isDefined(state.currentAnswer.comments)
                                ? state.currentAnswer.comments.length +
                                  ' ' +
                                  (state.currentAnswer.comments.length > 1
                                      ? t('commentaires')
                                      : t('commentaire'))
                                : 'Commentaire indisponible'}
                        </p>
                    )}
                </div>
            </Card.Header>
            {isDefined(state.currentAnswer) &&
                isDefined(state.currentAnswer.comments) &&
                state.currentAnswer.comments.length > 0 && (
                    <Card.Body
                        classes={'btzCommentFeature-comments'}
                        style={{ 'padding-bottom': '25px' }}
                    >
                        <div class="btzStudy-blockComments">
                            {state.currentAnswer.comments.map((item, idx) => (
                                <div
                                    key={
                                        'answer-comment-' +
                                        state.currentAnswer.id +
                                        '-' +
                                        idx
                                    }
                                    class={
                                        'btzStudy-blockComment ' +
                                        (isDefined(
                                            props.queryParam.commentid
                                        ) &&
                                        props.queryParam.commentid ===
                                            item.date.date.replace(' ', '')
                                            ? 'active'
                                            : '')
                                    }
                                    id={
                                        'surveyanswer-' +
                                        props.trgid +
                                        '-' +
                                        idx
                                    }
                                >
                                    <p
                                        key={
                                            'author-date-' +
                                            state.currentAnswer.id +
                                            '-' +
                                            idx
                                        }
                                        class=""
                                        oncreate={(el) => {
                                            actions.retrieveCommentAuthorAndDate(
                                                { el: el, item: item }
                                            )
                                        }}
                                    ></p>
                                    <Form
                                        key={
                                            'comment-' +
                                            state.currentAnswer.id +
                                            '-' +
                                            idx
                                        }
                                        onsubmit={(event) =>
                                            event.preventDefault()
                                        }
                                    >
                                        <Form.Group>
                                            <div class="col-12 thfAlignTop">
                                                <FormInput
                                                    key={
                                                        'input-comment-' +
                                                        state.currentAnswer.id +
                                                        '-' +
                                                        idx
                                                    }
                                                    type="contentEditable"
                                                    id={'comment-' + idx}
                                                    name={'comment-' + idx}
                                                    value={item.content}
                                                    list={
                                                        props.referenceHealTeam ||
                                                        null
                                                    }
                                                    readonly={true}
                                                    loaded={true}
                                                    textareaRows={1}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            ))}
                        </div>
                    </Card.Body>
                )}
            <Card.Body
                style={{ 'background-color': '#f8fafb', 'padding-top': '20px' }}
            >
                <Form
                    key={'comment-new-' + state.comments.length}
                    onsubmit={(event) => event.preventDefault()}
                >
                    <Form.Group>
                        <div class="col-12 thfAlignTop">
                            <FormInput
                                key="input-comment"
                                type="contentEditable"
                                id="comment"
                                name="comment"
                                value={state.newComment.content}
                                placeholder={t('Votre commentaire ici')}
                                oninputcb={actions.retrieveTaggedDoctor}
                                list={
                                    isDefined(props.referenceHealTeam)
                                        ? props.referenceHealTeam.filter(
                                              (f) =>
                                                  props.patientHealTeam.indexOf(
                                                      f.key
                                                  ) > -1
                                          )
                                        : null
                                }
                                validation={true}
                                loaded={true}
                                textareaRows={1}
                                formSubmittedOnce={props.formSubmittedOnce}
                                errorMessage={
                                    props.errorMessages.contentEditable
                                }
                                required
                            />
                        </div>
                    </Form.Group>
                </Form>
                {props.customer === 'resilience' && (
                    <p class="comment-annotation">
                        {t(
                            'Pour adresser votre commentaire à un membre de l’équipe, appuyez sur le bouton « @ » de votre clavier puis recherchez son nom dans la liste ou appuyez sur « Répondre ». Une notification lui sera envoyée pour le prévenir.'
                        )}
                    </p>
                )}
                <div
                    class="btzCard-btzFooter-btzModal"
                    style="background-color: #f8fafb"
                >
                    {props.customer === 'resilience' ? (
                        <Button
                            anchorRight
                            flat
                            margin={'0 0 0 15px'}
                            active={
                                state.newComment.content !== '' ? true : false
                            }
                            revertedColor
                            onclick={actions.submitComment}
                        >
                            <img
                                class="deactivated-visible"
                                src={icoSend}
                                srcset={`${icoSendx2} 2x`}
                                alt=""
                            />
                            <img
                                class={
                                    props.customer === 'resilience'
                                        ? 'activated activated-visible'
                                        : ''
                                }
                                src={
                                    props.customer === 'resilience'
                                        ? icoSendWhite
                                        : state.newComment.content !== ''
                                        ? icoSendActive
                                        : icoSend
                                }
                                srcset={`${
                                    props.customer === 'resilience'
                                        ? icoSendWhitex2
                                        : state.newComment.content !== ''
                                        ? icoSendActivex2
                                        : icoSendx2
                                } 2x`}
                                alt=""
                                style="display: inline-block;margin-right: 10px;vertical-align: middle;width: 16px;height: 16px;"
                            />
                            {t('Poster votre commentaire')}
                        </Button>
                    ) : (
                        <Button
                            anchorRight
                            flat
                            margin={'0 0 0 15px'}
                            active={
                                state.newComment.content !== '' ? true : false
                            }
                            revertedColor
                            onclick={actions.submitComment}
                        >
                            <img
                                src={
                                    state.newComment.content !== ''
                                        ? icoSendActive
                                        : icoSend
                                }
                                srcset={`${
                                    state.newComment.content !== ''
                                        ? icoSendActivex2
                                        : icoSendx2
                                } 2x`}
                                alt=""
                                style="display: inline-block;margin-right: 10px;vertical-align: middle;width: 16px;height: 16px;"
                            />
                            {t('Poster votre commentaire')}
                        </Button>
                    )}
                    {state.closeButton && (
                        <Button
                            anchorRight
                            flat
                            cancel
                            onclick={props.cancelAction}
                        >
                            {t('Fermer')}
                        </Button>
                    )}
                </div>
            </Card.Body>
        </Card>
    ),
    'commentFeature'
)
