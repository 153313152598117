import { t } from 'i18next';
import { h } from 'hyperapp';
import { isDefined, getReadableDate, createUTCDate, getAge } from '@app/core';
import { allRoutes, addCSRFToken } from '@app/core';
import { Card, Button, Enter, Paginate } from '@app/elements';
import moment from 'moment';
import * as constants from '@app/constants';

import icoSortAlpha from '@app/img/ico/table/ico-sort-alpha.png';
import icoSortAlphax2 from '@app/img/ico/table/ico-sort-alpha@2x.png';

import icoResetSearch from '@app/img/ico/ico-reset-search.png';
import icoResetSearchx2 from '@app/img/ico/ico-reset-search@2x.png';

import icoAdd from '@app/img/ico/ico-add.png';
import icoAddx2 from '@app/img/ico/ico-add@2x.png';

export default {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div class='patients-central'>
                {(isDefined(state.patients) && (state.loaded === true)) &&
                    <dummy>
                        {(state.patients.length === 0) ?
                            <dummy key={state.search}>
                                {(state.search !== '') ?
                                    <div class='thfEmptyTable'>
                                        <p class='thfEmptyTable-thfEmptyTableTitle'>{t('Pas de patients trouvé')}</p>
                                        <p class='thfEmptyTable-thfEmptyTableSubTitle'>{t('Nous n’avons pas trouvé de patient correspondant à cette recherche :') + ' ' + state.search}</p>
                                        <div style='margin: 15px auto auto; display: inline-block;'>
                                            <Button active onclick={actions.resetSearch}>
                                                <img src={icoResetSearch} srcset={`${icoResetSearchx2} 2x`} alt='' />
                                                {t('Réinitialiser la recherche')}
                                            </Button>
                                        </div>
                                    </div>
                                    :
                                    <div class='thfEmptyTable'>
                                        <p class='thfEmptyTable-thfEmptyTableTitle'>{t('Pas de patients inclus')}</p>
                                        <p class='thfEmptyTable-thfEmptyTableSubTitle'>{t('Ajoutez un premier patient pour voir le reste des fonctionnalités')}</p>
                                        <div style='margin: 15px auto auto; display: inline-block;'>
                                            <Button active onclick={() => { window.location.href = addCSRFToken(allRoutes['private.patient.add'].pathname); }}>
                                                <img src={icoAdd} srcset={`${icoAddx2} 2x`} alt='' />
                                                {t('Ajouter un patient')}
                                            </Button>
                                        </div>
                                    </div>
                                }
                            </dummy>
                            :
                            <Card classes={'btzPageCard btzBackgroundCover'} kind={'datas'}>
                                <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                                    <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
                                        {/*state.searching &&
                                            <div style='margin: 15px 0 0;width: 280px;'>
                                                <Button active onclick={actions.resetSearch}>
                                                    {t('Réinitialiser la recherche')}
                                                </Button>
                                            </div>
                                        */}
                                        <table class='thfTable'>
                                            <thead>
                                                <tr>
                                                    <th class='thfTable-cellName'><p class='thfTable-formInputLabel'>{t('Nom')}</p><img src={icoSortAlpha} srcset={`${icoSortAlphax2} 2x`} alt='' onclick={() => actions.onSortList('lastName')} /></th>
                                                    <th class='thfTable-cellPatho'><p class='thfTable-formInputLabel'>{t('Pathologie')}</p></th>
                                                    <th class='thfTable-cellBirthDate'><p class='thfTable-formInputLabel'>{t('Date de naissance')}</p></th>
                                                    <th class='thfTable-cellPatientAge'><p class='thfTable-formInputLabel'>{t('Age')}</p></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {state.patients.map(item =>
                                                    <tr onclick={() => { actions.redirectToPatient(item.id); }}>
                                                        <td class='thfTable-cellName'>
                                                            {((isDefined(item.userTheraflow) && (item.userTheraflow.etapes === true)) ? <font>{t('e')}</font> : '')}
                                                            <div class='btzCard-btzHeader-btzPage-btzNamePuce btzCard-btzHeader-btzPage-btzNamePuce-small'>
                                                                <p key={'listing-patient-' + item.name} oncreate={(el) => { actions.getPatientName({el: el, patient: item}); }}></p>
                                                            </div>
                                                            <p class='btzCard-btzHeader-btzPage-btzNamePuce-small-fullName'>{item.name}</p>
                                                        </td>
                                                        <td class='thfTable-cellPatho'><span class='thfTable-cellColored' title={(isDefined(item.userTheraflow.pathologiesType) && Array.isArray(item.userTheraflow.pathologiesType) && (item.userTheraflow.pathologiesType.length > 0)) ? constants.custo.pathologiesTypes.filter((f) => item.userTheraflow.pathologiesType.indexOf(f.key) > -1).map((m) => t(m.label)).join(', ') : 'Ø'}>{(isDefined(item.userTheraflow.pathologiesType) && Array.isArray(item.userTheraflow.pathologiesType) && (item.userTheraflow.pathologiesType.length > 0)) ? constants.custo.pathologiesTypes.filter((f) => item.userTheraflow.pathologiesType.indexOf(f.key) > -1).map((m) => t(m.label)).join(', ') : 'Ø'}</span></td>
                                                        <td class='thfTable-cellBirthDate'>{getReadableDate(createUTCDate(item.birthdate.date, false), 'DD/MM/YYYY', false, '.')}</td>
                                                        <td class='thfTable-cellPatientAge'>{getAge(createUTCDate(item.birthdate.date), moment(), true)}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                        <Paginate targetPage={actions.targetPage} nextPage={actions.nextPage} prevPage={actions.prevPage} limit={state.patients.length} total={state.totalPatients} bypage={25} page={state.page}></Paginate>
                                    </Enter>
                                </Card.Header>
                            </Card>
                        }
                    </dummy>
                }
            </div>
        );
        return view;
    }
}