import { t } from 'i18next';
import { h } from 'hyperapp';
import { Button, Card } from '@app/elements';
import { isDefined } from '@app/core';

import icoArrow from '@app/img/ico/ico-arrow-white-right.png';
import icoArrowx2 from '@app/img/ico/ico-arrow-white-right@2x.png';

export const ModalViewMedicalConfirm = (props, actions) => (
    <Card classes={'btzInformations-btzInformationsModal'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            <div key={props.modalEditing} key={'treatment'}>
                <p class='btzTitle btzXl btzSubTitle'>{t('Nous vous avons ajouté(e) à l\'équipe médicale')}</p>
            </div>
        </Card.Header>
        <Card.Body>
            <p class='' style='padding: 20px;'>{t('Vous ne faites pas partie de l\'équipe médicale du patient que vous avez inclu. \nPour vous permettre d\'accéder à sa fiche patient, nous vous avons ajouté(e) à son équipe médicale. Cliquez sur "Continuer" pour poursuivre.')}</p>
            <p class='' style='padding: 20px;'>{t('Si ce choix était volontaire cliquez sur "Me retirer". Vous n\'aurez alors pas accès à la fiche de ce patient.')}</p>
            <div class='btzCard-btzFooter-btzModal'>
                <Button anchorRight flat active margin={'0 0 0 15px'} active onclick={() => {
                    props.onAddMeThenSubmit();
                }}>
                    <img class='activated' src={icoArrow} srcset={`${icoArrowx2} 2x`} alt='' />
                    {t('Continuer')}
                </Button>
                <Button anchorRight flat dangerous cancel onclick={(e) => {
                    props.onSubmit();
                }}>
                    {t('Me retirer')}
                </Button>
            </div>
        </Card.Body>
    </Card>
);