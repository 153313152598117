import { h } from 'hyperapp';
import { Component } from '@app/utils';
import { dynLoad, getSupport } from '@app/core';
import './index.scss';

const state = {
    support: null,
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        actions.setSupport(getSupport());
    },
    setSupport: newState => state => ({
        support: newState,
    }),
};

export const view = dynLoad('view', 'Help/views');

export default Component(state, actions, view, 'help');