import { t } from 'i18next';
import { h } from 'hyperapp';
import { Button, Form, FormInput, Calendar } from '@app/elements';
import { isDefined, getYMDfromDateObj, getDateObjFromYMD, isValidDateYMDObj, createUTCDate } from '@app/core';
import * as constants from '@app/constants';

import icoClose from '@app/img/ico/ico-close.png';
import icoClosex2 from '@app/img/ico/ico-close@2x.png';

export const ModalViewTreatmentForm = (props, actions) => (
    <div key={props.modalEditing} key={'configuration'}>
        <div class='thfTreatmentModalBlock' style='border: none !important;'>
            <Form onsubmit={event => event.preventDefault()}>
                {(props.treatmentStep === 0) &&
                    <Form.Group>
                        <div class='col-12 thfAlignTop'>
                            <p class='btzForm-btzLabel' style='display: block;'>{t('Quel est le nom du médicament ?')}</p>
                            <FormInput
                                key='input-name'
                                type='dropdown'
                                id='name'
                                name='name'
                                value={props.values.name}
                                list={props.drugsList}
                                ondropdowncb={props.retrieveInput}
                                queryAction={props.getMedicine}
                                validation={true}
                                validationOpt={{ifNotEmpty: true}}
                                withSearch={true}
                                textedArea={false}
                                oninputcb={props.retrieveInput}
                                onblurcb={props.retrieveErrorMessageTreatment}
                                formSubmittedOnce={props.formSubmittedOnce}
                                errorMessage={props.errorMessages.name}
                                required
                            />
                        </div>
                    </Form.Group>
                }
                {(props.treatmentStep === 1) &&
                    <Form.Group>
                        <div class='col-12 thfAlignTop'>
                            <p class='btzForm-btzLabel'>{t('Fréquence de')}</p>
                            <FormInput
                                key='input-frequencies'
                                type='dropdown'
                                id='frequencies'
                                name='frequencies'
                                value={props.values.frequencies}
                                list={constants.custo.takeFrequencies}
                                initialCalls={false}
                                validation={true}
                                withSearch={false}
                                textedArea={false}
                                oninputcb={props.retrieveInput}
                                required
                            />
                        </div>
                        <div class='col-12 thfAlignTop'>
                            <Calendar oninput={props.retrieveInput} key={'start'} name={'start'} title={t('Date de début')} calendarDate={props.values.start} deletetable={false} isValid={props.isValid.start} max={props.values.end} errorMessage={props.errorMessages.start} formSubmittedOnce={props.formSubmittedOnce} />
                        </div>
                        <div class='col-12 thfAlignTop'>
                            <Calendar oninput={props.retrieveInput} key={'end'} name={'end'} title={t('Date de fin')} calendarDate={props.values.end} deletetable={true} optional={true} isValid={props.isValid.end} noPast={(isDefined(props.values.id) ? false : true)} min={props.values.start} errorMessage={props.errorMessages.end} formSubmittedOnce={props.formSubmittedOnce} />
                        </div>
                        {props.values.frequencies === '1' &&
                            <div class='col-12 thfAlignTop'>
                                <p class='btzForm-btzLabel btzForm-btzLabelTitle'>{t('Tous les ...')}</p>
                                <p class='btzForm-btzLabel' style='display: block;'>{t('Choisissez la périodicité de la prise de ce médicament en jours, semaines ou mois.')}</p>
                                <p class='btzForm-btzLabel'>{t('Tous les')}</p>
                                <FormInput
                                    key='input-intervalday'
                                    type='int'
                                    id='intervalday'
                                    name='intervalday'
                                    value={props.values.intervalday}
                                    validation={true}
                                    isInteger={true}
                                    minValue={1}
                                    oninputcb={props.retrieveInput}
                                    errorMessage={props.errorMessages.intervalday}
                                    required
                                />
                                <FormInput
                                    key='input-interval'
                                    type='dropdown'
                                    id='interval'
                                    name='interval'
                                    value={props.values.interval}
                                    list={constants.custo.periodsFrequencies}
                                    validation={true}
                                    withSearch={false}
                                    textedArea={false}
                                    oninputcb={props.retrieveInput}
                                    required
                                />
                            </div>
                        }
                        {props.values.frequencies === '2' &&
                            <div class='col-12 thfAlignTop'>
                                <p class='btzForm-btzLabel btzForm-btzLabelTitle'>{t('Jours précis')}</p>
                                <p class='btzForm-btzLabel' style='display: block;'>{t('Choisissez le ou les jours de la semaine auxquels ce médicament doit être pris.')}</p>
                                <FormInput
                                    key='input-days'
                                    type='dropdown'
                                    id='days'
                                    name='days'
                                    value={props.values.days}
                                    list={constants.custo.daysFrequencies}
                                    isValid={((props.formSubmittedOnce && !props.isTyping.days) ? props.isValid.days : 'is-default')}
                                    validation={true}
                                    withSearch={false}
                                    textedArea={false}
                                    multiselect={true}
                                    oninputcb={props.retrieveInput}
                                    errorMessage={props.errorMessages.days}
                                    required
                                />
                            </div>
                        }
                        {props.values.frequencies === '3' &&
                            <div class='col-12 thfAlignTop'>
                                <p class='btzForm-btzLabel btzForm-btzLabelTitle'>{t('Cycle')}</p>
                                <p class='btzForm-btzLabel' style='display: block;'>{t('Choisissez le nombre de période de prise et le nombre de jour de repos avant la nouvelle période de prise.')}</p>
                                <div style='margin: 10px'>
                                    <FormInput
                                        key='input-takeday'
                                        type='int'
                                        id='takeday'
                                        name='takeday'
                                        value={props.values.takeday}
                                        validation={true}
                                        isInteger={true}
                                        minValue={1}
                                        oninputcb={props.retrieveInput}
                                        errorMessage={props.errorMessages.takeday}
                                        required
                                    />
                                    <p class='btzForm-btzLabel' style='margin: -30px 0 0 0 !important;display: inline-block !important;'>{t('jour(s) de prise')}</p>
                                </div>
                                <div style='margin: 10px'>
                                    <FormInput
                                        key='input-breakday'
                                        type='int'
                                        id='breakday'
                                        name='breakday'
                                        value={props.values.breakday}
                                        validation={true}
                                        isInteger={true}
                                        minValue={1}
                                        oninputcb={props.retrieveInput}
                                        errorMessage={props.errorMessages.breakday}
                                        required
                                    />
                                    <p class='btzForm-btzLabel' style='margin: -30px 0 0 0 !important;display: inline-block !important;'>{t('jour(s) de pause')}</p>
                                </div>
                            </div>
                        }
                    </Form.Group>
                }
                {(props.treatmentStep === 2) &&
                    <Form.Group>
                        {constants.custo.takePeriods.map((period) =>
                            <div class='thfTreatmentModalBlock-prescriptions'>
                                <div class='thfTreatmentModalBlock-prescriptionsHeader'>
                                    <p class=''>{t(period.label)}</p>
                                    {(isDefined(props.values.prescription.filter((f) => f.marker === period.marker)[0])) &&
                                        <img class='' src={icoClose} srcset={`${icoClosex2} 2x`} alt='' onclick={() => {
                                            props.removePrescription(period);
                                        }} />
                                    }
                                </div>
                                <div class='thfTreatmentModalBlock-prescriptionsContent'>
                                    {(isDefined(props.values.prescription.filter((f) => f.marker === period.marker)[0])) ?
                                        <div class=''>
                                            <FormInput
                                                key='input-dosage'
                                                type='int'
                                                id={'prescription-' + period.marker}
                                                name='dosage'
                                                value={props.values.prescription.filter((f) => f.marker === period.marker)[0].dosage}
                                                validation={true}
                                                isFloat={true}
                                                floatingDecimal={2}
                                                minValue={1}
                                                oninputcb={props.retrieveInputPrescription}
                                                required
                                            />
                                            <FormInput
                                                key='input-dosageUnit'
                                                type='dropdown'
                                                id={'prescription-' + period.marker}
                                                name='dosageUnit'
                                                value={props.values.prescription.filter((f) => f.marker === period.marker)[0].dosageUnit}
                                                list={constants.custo.takeKinds}
                                                validation={true}
                                                withSearch={false}
                                                textedArea={false}
                                                oninputcb={props.retrieveInputPrescription}
                                                required
                                            />
                                            <FormInput
                                                placeholder={t('Contexte') + ' (' + t('facultatif') + ')'}
                                                key='input-context'
                                                type='text'
                                                id={'prescription-' + period.marker}
                                                name='context'
                                                value={props.values.prescription.filter((f) => f.marker === period.marker)[0].context}
                                                validation={true}
                                                optional={true}
                                                oninputcb={props.retrieveInputPrescription}
                                                required
                                            />
                                        </div>
                                        :
                                        <Button style={{ 'margin-left': '15px' }} flat onclick={() => {
                                            props.addPrescription(period);
                                        }}>
                                            {t('Ajouter')}
                                        </Button>
                                    }
                                </div>
                            </div>
                        )}
                    </Form.Group>

                }
                {(props.treatmentStep === 3) &&
                    <div class='thfTreatmentModalBlock-recap'>
                        {(props.values.status === true) &&
                            <div class='thfTreatmentModalBlock-running'>
                                <p class='thfTreatmentModalBlock-title'>{t('Médicament en cours')}</p>
                                <p class='thfTreatmentModalBlock-subTitle'>{t('Ce médicament est aujourd’hui prescrit à votre patient. Il fait partie de ses médicaments en cours.')}</p>
                            </div>
                        }
                        {(props.values.status === false) &&
                            <div class='thfTreatmentModalBlock-ended'>
                                <p class='thfTreatmentModalBlock-title'>{t('Médicament arrêté')}</p>
                                <p class='thfTreatmentModalBlock-subTitle'>{t('Ce médicament a été arrêté volontairement par une personne autorisée. Vous pouvez le réactiver.')}</p>
                            </div>
                        }
                        <div class='thfTreatmentModalBlock-recapHeader'>
                            <p class='thfTreatmentModalBlock-recapInfo'><b>{props.values.name}</b></p>
                            <Button style={{ 'margin-left': '15px' }} flat onclick={() => {
                                props.setState({key: 'treatmentStep', value: 0});
                            }}>
                                {t('Modifier')}
                            </Button>
                        </div>
                        <div class='thfTreatmentModalBlock-recapContent'>
                            <div class='thfTreatmentModalBlock-recapInfo'>
                                <p class='' key={'details'} style='font-weight: bold;' oncreate={(e) => { props.getReadableFrequency({el: e, item: props.values}); }} onupdate={(e) => { props.getReadableFrequency({el: e, item: props.values}); }}></p>
                                <p class=''><b>{t('Début') + ' : '}</b>{getYMDfromDateObj(createUTCDate(getDateObjFromYMD(props.values.start).date), true)}</p>
                                <p class=''><b>{t('Fin') + ' : '}</b>{((isDefined(props.values.end) && isValidDateYMDObj(props.values.end)) ? getYMDfromDateObj(createUTCDate(getDateObjFromYMD(props.values.end).date), true) : t('Aucune date de fin programmée'))}</p>
                            </div>
                            <Button style={{ 'margin-left': '15px' }} flat onclick={() => {
                                props.setState({key: 'treatmentStep', value: 1});
                            }}>
                                {t('Modifier')}
                            </Button>
                        </div>
                        <div class='thfTreatmentModalBlock-recapPrescription'>
                            <div class='thfTreatmentModalBlock-recapInfo'>
                                {props.values.prescription.map((prescription) =>
                                    <div class=''>
                                        <p class=''><b>{t(constants.custo.takePeriods.filter((f) => f.marker === prescription.marker)[0].label) + ' : '}</b>{prescription.dosage + ' ' + t(constants.custo.takeKinds.filter((f) => f.key === prescription.dosageUnit)[0].label)}<i>{isDefined(prescription.context) ? (' - ' + prescription.context) : ''}</i></p>
                                    </div>
                                )}
                            </div>
                            <Button style={{ 'margin-left': '15px' }} flat onclick={() => {
                                props.setState({key: 'treatmentStep', value: 2});
                            }}>
                                {t('Modifier')}
                            </Button>
                        </div>
                    </div>
                }
            </Form>
        </div>
    </div>
);