import { t } from "i18next";
import { h } from "hyperapp";
import { Component } from "@app/utils";
import { isDefined, getReadableDate, getDateObjFromYMD } from "@app/core";
import { Tile } from "@app/elements";

import ViewInstructionDefault from "./subview-instructions";

const actions = {
	//
};

const view = (state, actions) => (props, children) =>
	(
		<dummy>
			<Tile>
				<Tile.Content>
					<p class="btzTitle btzXl">{t("Mentions légales.")}</p>
				</Tile.Content>
			</Tile>
			<div
				class="thfMentionsWrapper"
				style="padding: 20px 15px 0px;background: white;">
				<p class="">
					{t(
						"L‘ensemble des éléments constituant notre plateforme ont été conçus en suivant de près les normes internationales les plus strictes en terme de gestion de la qualité, de sécurité du système d‘information et de la conception de logiciels dispositifs médicaux."
					)}
				</p>
				<div class="" style="margin-bottom: 30px;margin-top: 30px;">
					<div style="display: inline-block;margin-left: 15px;vertical-align: middle;">
						<p class="" style="margin: 0px;line-height: 1;">
							{props.support.mailto}
						</p>
					</div>
				</div>
				<p class="" style="text-transform: capitalize;">
					{t("Produit ") +
						(process.env.APP_VERSIONPRODUCT || "N.C") +
						" (Front " +
						(process.env.APP_VERSION || "N.C") +
						", API " +
						(process.env.APP_VERSIONAPI || "N.C") +
						")" +
						(isDefined(process.env.APP_YMD)
							? ", " +
							  getReadableDate(
									getDateObjFromYMD(process.env.APP_YMD).date,
									"DD MMMM YYYY"
							  )
							: " - N.C")}
				</p>
				<ViewInstructionDefault
					support={props.support}
					legalDocuments={
						Array.isArray(props.legalAgreements)
							? props.legalAgreements.map(
									(item) =>
										item.legalDocument ||
										item.legalDocuments
							  )
							: props.legalAgreements.legalDocument ||
							  props.legalAgreements.legalDocuments
					}></ViewInstructionDefault>
			</div>
		</dummy>
	);

export default Component({}, actions, view, "subview");
