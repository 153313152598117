import { t } from 'i18next'
import { h } from 'hyperapp'
import { isDefined } from '@app/core'
import {
    Card,
    Button,
    Tile,
    Enter,
    Paginate,
    Modal,
    DatePicker,
} from '@app/elements'
import { DataViz } from '@app/elements'
import * as constants from '@app/constants'

import { ModalViewConfiguration } from './modals/modalView-configuration-default'

import { TableViewDefault } from './tables/tableView-default-default'
import { TableViewUrine } from './tables/tableView-urine-default'

import './../index.scss'

export default {
    initView: function () {
        let view = (state, actions) => (props, children) => (
            <div class="patient-central">
                <Card
                    classes={'btzPageCard btzBackgroundCover'}
                    kind={'datas'}
                    style={{ margin: '30px 30px 0px 30px' }}
                >
                    <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                        <Enter
                            time={constants.custo.ANIM_DURATION}
                            easing={constants.custo.ANIM_EASING}
                            css={constants.custo.ANIM_CSS}
                        >
                            <dummy>
                                <Tile>
                                    <Tile.Content>
                                        {!state.switchedToConfiguration ? (
                                            <div
                                                class={
                                                    'thfTopHeader ' +
                                                    state.themeCurrent
                                                        .split('_')[0]
                                                        .toLowerCase() +
                                                    ' widget-activated-' +
                                                    state.widgetSetting
                                                        .HYDRATION_WIDGET
                                                }
                                            >
                                                <div class="thfTopHeader-alertes">
                                                    <Button
                                                        loading={
                                                            state.formIsSubmitting
                                                        }
                                                        active={
                                                            state.menu ===
                                                            'recent'
                                                        }
                                                        flat
                                                        dangerous
                                                        onclick={() => {
                                                            actions.switchAlert(
                                                                'recent'
                                                            )
                                                        }}
                                                    >
                                                        {t('Alertes récentes')}
                                                    </Button>
                                                    <Button
                                                        loading={
                                                            state.formIsSubmitting
                                                        }
                                                        active={
                                                            state.menu === 'all'
                                                        }
                                                        flat
                                                        onclick={() => {
                                                            actions.switchAlert(
                                                                'all'
                                                            )
                                                        }}
                                                    >
                                                        {t(
                                                            'Toutes les alertes'
                                                        )}
                                                    </Button>
                                                    {!state.isICModule ? (
                                                        <Button
                                                            loading={
                                                                state.formIsSubmitting
                                                            }
                                                            primary
                                                            anchorRight
                                                            dangerous
                                                            onclick={
                                                                actions.switchConfiguration
                                                            }
                                                        >
                                                            {t('Configurer')}
                                                        </Button>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                'display':
                                                                    'inline-block',
                                                                'vertical-align':
                                                                    'top',
                                                                'float':
                                                                    'right',
                                                            }}
                                                        >
                                                            <Modal
                                                                key={
                                                                    'modal-telesuivi-configuration'
                                                                }
                                                                cancelAction={() => {
                                                                    actions.setModalDisplayed(
                                                                        false
                                                                    )
                                                                }}
                                                                modalDisplayed={
                                                                    state.modalDisplayed &&
                                                                    state.modalEditing.indexOf(
                                                                        'configuration'
                                                                    ) > -1 &&
                                                                    state
                                                                        .editingMesure
                                                                        .crypt ===
                                                                        state.ICMesure
                                                                }
                                                                content={
                                                                    <ModalViewConfiguration
                                                                        displayed={
                                                                            'configuration'
                                                                        }
                                                                        {...state}
                                                                        retrieveInput={
                                                                            actions.retrieveInput
                                                                        }
                                                                        retrieveErrorMessage={
                                                                            actions.retrieveErrorMessage
                                                                        }
                                                                        retrieveInputStatus={
                                                                            actions.retrieveInputStatus
                                                                        }
                                                                        onSubmit={
                                                                            actions.onSubmit
                                                                        }
                                                                        switchModalTab={
                                                                            actions.switchModalTab
                                                                        }
                                                                        cancelAction={() => {
                                                                            actions.setModalDisplayed(
                                                                                false
                                                                            )
                                                                        }}
                                                                        getReadableRecap={
                                                                            actions.getReadableRecap
                                                                        }
                                                                        onEditCurrentTelesuivi={
                                                                            actions.onEditCurrentTelesuivi
                                                                        }
                                                                        onSaveConfiguration={
                                                                            actions.onSaveConfiguration
                                                                        }
                                                                        onCancelConfiguration={
                                                                            actions.onCancelConfiguration
                                                                        }
                                                                        onActivateAlerting={
                                                                            actions.onActivateAlerting
                                                                        }
                                                                        onDeactivateMesure={
                                                                            actions.onDeactivateMesure
                                                                        }
                                                                        onDeactivateMesureCancel={
                                                                            actions.onDeactivateMesureCancel
                                                                        }
                                                                        onDeleteRecall={
                                                                            actions.onDeleteRecall
                                                                        }
                                                                        retrieveInputWidget={
                                                                            actions.retrieveInputWidget
                                                                        }
                                                                        onSubmitWidget={
                                                                            actions.onSubmitWidget
                                                                        }
                                                                        loaded={
                                                                            true
                                                                        }
                                                                    ></ModalViewConfiguration>
                                                                }
                                                            >
                                                                <div
                                                                    onclick={() => {
                                                                        actions.onEditConfiguration(
                                                                            state.ICMesure
                                                                        )
                                                                    }}
                                                                    onkeydown={() => {
                                                                        actions.onEditConfiguration(
                                                                            state.ICMesure
                                                                        )
                                                                    }}
                                                                >
                                                                    <Button
                                                                        loading={
                                                                            state.formIsSubmitting
                                                                        }
                                                                        primary
                                                                        dangerous
                                                                    >
                                                                        {t(
                                                                            'Configurer'
                                                                        )}
                                                                    </Button>
                                                                </div>
                                                            </Modal>
                                                        </div>
                                                    )}
                                                </div>
                                                {!state.isICModule && (
                                                    <div class="thfTopHeader-themes">
                                                        {state.enableModules.map(
                                                            (item) => (
                                                                <Button
                                                                    loading={
                                                                        state.formIsSubmitting
                                                                    }
                                                                    active={
                                                                        isDefined(
                                                                            state.themeCurrent
                                                                        ) &&
                                                                        isDefined(
                                                                            item.alertValueKey.filter(
                                                                                (
                                                                                    vKey
                                                                                ) =>
                                                                                    item.crypt.toLowerCase() +
                                                                                        '_' +
                                                                                        String(
                                                                                            vKey.key
                                                                                        ).toLowerCase() ===
                                                                                    state.themeCurrent.toLowerCase()
                                                                            )[0]
                                                                        )
                                                                    }
                                                                    flat
                                                                    onclick={() => {
                                                                        actions.switchTheme(
                                                                            item.apicrypt.toLowerCase() +
                                                                                '_' +
                                                                                item.alertValueKey[0].key.toLowerCase()
                                                                        )
                                                                    }}
                                                                    onkeydown={() => {
                                                                        actions.switchTheme(
                                                                            item.apicrypt.toLowerCase() +
                                                                                '_' +
                                                                                item.alertValueKey[0].key.toLowerCase()
                                                                        )
                                                                    }}
                                                                >
                                                                    {t(
                                                                        item.value
                                                                    )}
                                                                </Button>
                                                            )
                                                        )}
                                                    </div>
                                                )}

                                                <dummy
                                                    key={state.forceRender}
                                                    style={{
                                                        display:
                                                            !state.isICModule &&
                                                            state.menu ===
                                                                'specific' &&
                                                            isDefined(
                                                                state.editingMesure
                                                            )
                                                                ? 'block'
                                                                : 'none',
                                                    }}
                                                >
                                                    {state.isICModule ===
                                                        false && (
                                                        <dummy>
                                                            <div class="thfDatavizHeader">
                                                                <p class="btzTitle btzXl">
                                                                    {t(
                                                                        state
                                                                            .editingMesure
                                                                            .value
                                                                    )}
                                                                </p>
                                                                <p class="btzSubTitle btzXl">
                                                                    {t(
                                                                        state
                                                                            .editingMesure
                                                                            .unit
                                                                    )}
                                                                </p>
                                                            </div>
                                                            <DatePicker
                                                                date={
                                                                    state.currentDate
                                                                }
                                                                periodAction={
                                                                    actions.updatePeriod
                                                                }
                                                                period={
                                                                    state.currentPeriod
                                                                }
                                                                selectorAction={
                                                                    actions.updateSelector
                                                                }
                                                                activeTopSticked={
                                                                    false
                                                                }
                                                                activePeriods={{
                                                                    week: true,
                                                                    month: true,
                                                                    year: false,
                                                                }}
                                                                locale={localStorage.getItem(
                                                                    'locale'
                                                                )}
                                                            ></DatePicker>
                                                            <DataViz
                                                                key={
                                                                    state.forceRender
                                                                }
                                                                {...state}
                                                                date={
                                                                    state.currentDate
                                                                }
                                                                period={
                                                                    state.currentPeriod
                                                                }
                                                                mesureType={
                                                                    state
                                                                        .editingMesure
                                                                        .apicrypt
                                                                }
                                                                updateFromChildToParent={
                                                                    actions.updateFromChildToParent
                                                                }
                                                            />
                                                            {isDefined(
                                                                constants.custo.telesuiviKPI.filter(
                                                                    (f) =>
                                                                        f.value.toLowerCase() ===
                                                                        state.themeCurrent
                                                                            .split(
                                                                                '_'
                                                                            )[0]
                                                                            .toLowerCase()
                                                                )[0]
                                                            ) &&
                                                                (state.themeCurrent
                                                                    .split(
                                                                        '_'
                                                                    )[0]
                                                                    .toLowerCase() !==
                                                                    'cryptedhydration' ||
                                                                    (state.themeCurrent
                                                                        .split(
                                                                            '_'
                                                                        )[0]
                                                                        .toLowerCase() ===
                                                                        'cryptedhydration' &&
                                                                        state
                                                                            .widgetSetting[
                                                                            'HYDRATION_WIDGET'
                                                                        ] ===
                                                                            '1')) && (
                                                                    <div class="thfDatavizLegends-wrapper">
                                                                        {constants.custo.telesuiviKPI
                                                                            .filter(
                                                                                (
                                                                                    f
                                                                                ) =>
                                                                                    f.value.toLowerCase() ===
                                                                                    state.themeCurrent
                                                                                        .split(
                                                                                            '_'
                                                                                        )[0]
                                                                                        .toLowerCase()
                                                                            )[0]
                                                                            .legend.map(
                                                                                (
                                                                                    item
                                                                                ) => (
                                                                                    <div class="thfDatavizLegends">
                                                                                        <p
                                                                                            class="thfDatavizLegend-color"
                                                                                            style={{
                                                                                                'background-color':
                                                                                                    item.color,
                                                                                            }}
                                                                                        ></p>
                                                                                        {isDefined(
                                                                                            item.label
                                                                                        ) &&
                                                                                            item.label !==
                                                                                                '' && (
                                                                                                <p class="thfDatavizLegend-label">
                                                                                                    {t(
                                                                                                        item.label
                                                                                                    )}
                                                                                                </p>
                                                                                            )}
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                    </div>
                                                                )}
                                                        </dummy>
                                                    )}
                                                </dummy>
                                                {state.themeCurrent
                                                    .split('_')[0]
                                                    .toLowerCase() ===
                                                'cryptedurine' ? (
                                                    <TableViewUrine
                                                        {...state}
                                                        retrieveInputAction={
                                                            actions.retrieveInputAction
                                                        }
                                                        retrieveCryptValue={
                                                            actions.retrieveCryptValue
                                                        }
                                                        retrieveLegendColor={
                                                            actions.retrieveLegendColor
                                                        }
                                                        retrieveReason={
                                                            actions.retrieveReason
                                                        }
                                                    ></TableViewUrine>
                                                ) : (
                                                    <TableViewDefault
                                                        {...state}
                                                        retrieveInputAction={
                                                            actions.retrieveInputAction
                                                        }
                                                        retrieveCryptValue={
                                                            actions.retrieveCryptValue
                                                        }
                                                        retrieveLegendColor={
                                                            actions.retrieveLegendColor
                                                        }
                                                        retrieveReason={
                                                            actions.retrieveReason
                                                        }
                                                        redirectToDatavizAlert={
                                                            actions.redirectToDatavizAlert
                                                        }
                                                    ></TableViewDefault>
                                                )}

                                                {(state.isICModule ||
                                                    (!state.switchedToConfiguration &&
                                                        (state.menu ===
                                                            'recent' ||
                                                            state.menu ===
                                                                'all'))) && (
                                                    <Paginate
                                                        nextPage={
                                                            actions.nextPage
                                                        }
                                                        prevPage={
                                                            actions.prevPage
                                                        }
                                                        limit={
                                                            state.tableDatas
                                                                .length
                                                        }
                                                        page={state.page}
                                                        bypage={10}
                                                    ></Paginate>
                                                )}
                                            </div>
                                        ) : (
                                            <div class="">
                                                <Button
                                                    loading={
                                                        state.formIsSubmitting
                                                    }
                                                    flat
                                                    anchorRight
                                                    onclick={() => {
                                                        actions.switchConfiguration()
                                                        actions.updateUser()
                                                        actions.switchTheme(
                                                            state.themeCurrent
                                                        )
                                                    }}
                                                >
                                                    {t('Retour au télésuivi')}
                                                </Button>
                                                <div class="thfTelesuivi-thfTelesuiviMesureBlock-thfTelesuiviWrapper">
                                                    <p class="btzTitle btzXl">
                                                        {t(
                                                            'Mesures télésuivies'
                                                        )}
                                                    </p>
                                                    <div class="thfTelesuivi-thfTelesuiviMesureBlocks thfTelesuivi-thfTelesuiviMesureBlocksFollowed">
                                                        {state.mesures.followed
                                                            .length > 0 ? (
                                                            <dummy>
                                                                {state.mesures.followed.map(
                                                                    (item) => (
                                                                        <div class="col-6 col-xs-12">
                                                                            <div class="thfTelesuivi-thfTelesuiviMesureBlock">
                                                                                <div class="thfTelesuivi-thfTelesuiviMesureBlock-header">
                                                                                    <p
                                                                                        class="thfTelesuivi-thfTelesuiviMesureBlock-headerTitle"
                                                                                        title={t(
                                                                                            constants.custo.telesuiviSettings.filter(
                                                                                                (
                                                                                                    f
                                                                                                ) =>
                                                                                                    f.crypt ===
                                                                                                    item.name
                                                                                            )[0]
                                                                                                .value
                                                                                        )}
                                                                                    >
                                                                                        {t(
                                                                                            constants.custo.telesuiviSettings.filter(
                                                                                                (
                                                                                                    f
                                                                                                ) =>
                                                                                                    f.crypt ===
                                                                                                    item.name
                                                                                            )[0]
                                                                                                .value
                                                                                        )}
                                                                                    </p>
                                                                                    <p class="thfTelesuivi-thfTelesuiviMesureBlock-headerSubTitle">
                                                                                        {t(
                                                                                            'suivi activé'
                                                                                        )}
                                                                                    </p>
                                                                                </div>
                                                                                <div class="thfTelesuivi-thfTelesuiviMesureBlock-modal">
                                                                                    <Modal
                                                                                        key={
                                                                                            'modal-telesuivi-configuration'
                                                                                        }
                                                                                        cancelAction={() => {
                                                                                            actions.setModalDisplayed(
                                                                                                false
                                                                                            )
                                                                                        }}
                                                                                        modalDisplayed={
                                                                                            state.modalDisplayed &&
                                                                                            state.modalEditing.indexOf(
                                                                                                'configuration'
                                                                                            ) >
                                                                                                -1 &&
                                                                                            state
                                                                                                .editingMesure
                                                                                                .crypt ===
                                                                                                item.name
                                                                                        }
                                                                                        content={
                                                                                            <ModalViewConfiguration
                                                                                                displayed={
                                                                                                    'configuration'
                                                                                                }
                                                                                                {...state}
                                                                                                retrieveInput={
                                                                                                    actions.retrieveInput
                                                                                                }
                                                                                                retrieveErrorMessage={
                                                                                                    actions.retrieveErrorMessage
                                                                                                }
                                                                                                retrieveInputStatus={
                                                                                                    actions.retrieveInputStatus
                                                                                                }
                                                                                                onSubmit={
                                                                                                    actions.onSubmit
                                                                                                }
                                                                                                switchModalTab={
                                                                                                    actions.switchModalTab
                                                                                                }
                                                                                                cancelAction={() => {
                                                                                                    actions.setModalDisplayed(
                                                                                                        false
                                                                                                    )
                                                                                                }}
                                                                                                getReadableRecap={
                                                                                                    actions.getReadableRecap
                                                                                                }
                                                                                                onEditCurrentTelesuivi={
                                                                                                    actions.onEditCurrentTelesuivi
                                                                                                }
                                                                                                onSaveConfiguration={
                                                                                                    actions.onSaveConfiguration
                                                                                                }
                                                                                                onCancelConfiguration={
                                                                                                    actions.onCancelConfiguration
                                                                                                }
                                                                                                onActivateAlerting={
                                                                                                    actions.onActivateAlerting
                                                                                                }
                                                                                                onDeactivateMesure={
                                                                                                    actions.onDeactivateMesure
                                                                                                }
                                                                                                onDeactivateMesureCancel={
                                                                                                    actions.onDeactivateMesureCancel
                                                                                                }
                                                                                                onDeleteRecall={
                                                                                                    actions.onDeleteRecall
                                                                                                }
                                                                                                retrieveInputWidget={
                                                                                                    actions.retrieveInputWidget
                                                                                                }
                                                                                                onSubmitWidget={
                                                                                                    actions.onSubmitWidget
                                                                                                }
                                                                                                loaded={
                                                                                                    true
                                                                                                }
                                                                                            ></ModalViewConfiguration>
                                                                                        }
                                                                                    >
                                                                                        <div
                                                                                            onclick={() => {
                                                                                                actions.onEditConfiguration(
                                                                                                    item.name
                                                                                                )
                                                                                            }}
                                                                                            onkeydown={() => {
                                                                                                actions.onEditConfiguration(
                                                                                                    item.name
                                                                                                )
                                                                                            }}
                                                                                        >
                                                                                            <Button
                                                                                                loading={
                                                                                                    state.formIsSubmitting
                                                                                                }
                                                                                                flat
                                                                                            >
                                                                                                {t(
                                                                                                    'Configurer'
                                                                                                )}
                                                                                            </Button>
                                                                                        </div>
                                                                                    </Modal>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                )}
                                                            </dummy>
                                                        ) : (
                                                            <div class="thfTelesuivi-thfTelesuiviMesureBlock-empty">
                                                                <p class="thfTelesuivi-thfTelesuiviMesureBlock-emptyTitle">
                                                                    {t(
                                                                        'Aucune mesure télésuivies'
                                                                    )}
                                                                </p>
                                                                <p class="thfTelesuivi-thfTelesuiviMesureBlock-emptySubTitle">
                                                                    {t(
                                                                        'Toutes les mesures sont désactivées ou activées.'
                                                                    )}
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div class="thfTelesuivi-thfTelesuiviMesureBlock-thfTelesuiviWrapper">
                                                    <p class="btzTitle btzXl">
                                                        {t('Mesures activées')}
                                                    </p>
                                                    <div class="thfTelesuivi-thfTelesuiviMesureBlocks thfTelesuivi-thfTelesuiviMesureBlocksActivated">
                                                        {state.mesures.activated
                                                            .length > 0 ? (
                                                            <dummy>
                                                                {state.mesures.activated.map(
                                                                    (item) => (
                                                                        <div class="col-6 col-xs-12">
                                                                            <div class="thfTelesuivi-thfTelesuiviMesureBlock">
                                                                                <div class="thfTelesuivi-thfTelesuiviMesureBlock-header">
                                                                                    <p
                                                                                        class="thfTelesuivi-thfTelesuiviMesureBlock-headerTitle"
                                                                                        title={t(
                                                                                            constants.custo.telesuiviSettings.filter(
                                                                                                (
                                                                                                    f
                                                                                                ) =>
                                                                                                    f.crypt ===
                                                                                                    item.name
                                                                                            )[0]
                                                                                                .value
                                                                                        )}
                                                                                    >
                                                                                        {t(
                                                                                            constants.custo.telesuiviSettings.filter(
                                                                                                (
                                                                                                    f
                                                                                                ) =>
                                                                                                    f.crypt ===
                                                                                                    item.name
                                                                                            )[0]
                                                                                                .value
                                                                                        )}
                                                                                    </p>
                                                                                    <p class="thfTelesuivi-thfTelesuiviMesureBlock-headerSubTitle">
                                                                                        {t(
                                                                                            'mesure activée'
                                                                                        )}
                                                                                    </p>
                                                                                </div>
                                                                                <div class="thfTelesuivi-thfTelesuiviMesureBlock-modal">
                                                                                    <Modal
                                                                                        key={
                                                                                            'modal-telesuivi-configuration'
                                                                                        }
                                                                                        cancelAction={() => {
                                                                                            actions.setModalDisplayed(
                                                                                                false
                                                                                            )
                                                                                        }}
                                                                                        modalDisplayed={
                                                                                            state.modalDisplayed &&
                                                                                            state.modalEditing.indexOf(
                                                                                                'configuration'
                                                                                            ) >
                                                                                                -1 &&
                                                                                            state
                                                                                                .editingMesure
                                                                                                .crypt ===
                                                                                                item.name
                                                                                        }
                                                                                        content={
                                                                                            <ModalViewConfiguration
                                                                                                displayed={
                                                                                                    'configuration'
                                                                                                }
                                                                                                {...state}
                                                                                                retrieveInput={
                                                                                                    actions.retrieveInput
                                                                                                }
                                                                                                retrieveErrorMessage={
                                                                                                    actions.retrieveErrorMessage
                                                                                                }
                                                                                                retrieveInputStatus={
                                                                                                    actions.retrieveInputStatus
                                                                                                }
                                                                                                onSubmit={
                                                                                                    actions.onSubmit
                                                                                                }
                                                                                                switchModalTab={
                                                                                                    actions.switchModalTab
                                                                                                }
                                                                                                cancelAction={() => {
                                                                                                    actions.setModalDisplayed(
                                                                                                        false
                                                                                                    )
                                                                                                }}
                                                                                                getReadableRecap={
                                                                                                    actions.getReadableRecap
                                                                                                }
                                                                                                onEditCurrentTelesuivi={
                                                                                                    actions.onEditCurrentTelesuivi
                                                                                                }
                                                                                                onSaveConfiguration={
                                                                                                    actions.onSaveConfiguration
                                                                                                }
                                                                                                onCancelConfiguration={
                                                                                                    actions.onCancelConfiguration
                                                                                                }
                                                                                                onActivateAlerting={
                                                                                                    actions.onActivateAlerting
                                                                                                }
                                                                                                onDeactivateMesure={
                                                                                                    actions.onDeactivateMesure
                                                                                                }
                                                                                                onDeactivateMesureCancel={
                                                                                                    actions.onDeactivateMesureCancel
                                                                                                }
                                                                                                onDeleteRecall={
                                                                                                    actions.onDeleteRecall
                                                                                                }
                                                                                                retrieveInputWidget={
                                                                                                    actions.retrieveInputWidget
                                                                                                }
                                                                                                onSubmitWidget={
                                                                                                    actions.onSubmitWidget
                                                                                                }
                                                                                                loaded={
                                                                                                    true
                                                                                                }
                                                                                            ></ModalViewConfiguration>
                                                                                        }
                                                                                    >
                                                                                        <div
                                                                                            onclick={() => {
                                                                                                actions.onEditConfiguration(
                                                                                                    item.name
                                                                                                )
                                                                                            }}
                                                                                            onkeydown={() => {
                                                                                                actions.onEditConfiguration(
                                                                                                    item.name
                                                                                                )
                                                                                            }}
                                                                                        >
                                                                                            <Button
                                                                                                loading={
                                                                                                    state.formIsSubmitting
                                                                                                }
                                                                                                flat
                                                                                            >
                                                                                                {t(
                                                                                                    'Configurer'
                                                                                                )}
                                                                                            </Button>
                                                                                        </div>
                                                                                    </Modal>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                )}
                                                            </dummy>
                                                        ) : (
                                                            <div class="thfTelesuivi-thfTelesuiviMesureBlock-empty">
                                                                <p class="thfTelesuivi-thfTelesuiviMesureBlock-emptyTitle">
                                                                    {t(
                                                                        'Aucune mesure activée'
                                                                    )}
                                                                </p>
                                                                <p class="thfTelesuivi-thfTelesuiviMesureBlock-emptySubTitle">
                                                                    {t(
                                                                        'Toutes les mesures sont désactivées ou télésuivies.'
                                                                    )}
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div class="thfTelesuivi-thfTelesuiviMesureBlock-thfTelesuiviWrapper">
                                                    <p class="btzTitle btzXl">
                                                        {t(
                                                            'Mesures désactivées'
                                                        )}
                                                    </p>
                                                    <div class="thfTelesuivi-thfTelesuiviMesureBlocks thfTelesuivi-thfTelesuiviMesureBlocksDeactivated">
                                                        {state.mesures
                                                            .deactivated
                                                            .length > 0 ? (
                                                            <dummy>
                                                                {state.mesures.deactivated.map(
                                                                    (item) => (
                                                                        <div class="col-6 col-xs-12">
                                                                            <div class="thfTelesuivi-thfTelesuiviMesureBlock">
                                                                                <div class="thfTelesuivi-thfTelesuiviMesureBlock-header">
                                                                                    <p
                                                                                        class="thfTelesuivi-thfTelesuiviMesureBlock-headerTitle"
                                                                                        title={t(
                                                                                            constants.custo.telesuiviSettings.filter(
                                                                                                (
                                                                                                    f
                                                                                                ) =>
                                                                                                    f.crypt ===
                                                                                                    item.name
                                                                                            )[0]
                                                                                                .value
                                                                                        )}
                                                                                    >
                                                                                        {t(
                                                                                            constants.custo.telesuiviSettings.filter(
                                                                                                (
                                                                                                    f
                                                                                                ) =>
                                                                                                    f.crypt ===
                                                                                                    item.name
                                                                                            )[0]
                                                                                                .value
                                                                                        )}
                                                                                    </p>
                                                                                </div>
                                                                                <Button
                                                                                    loading={
                                                                                        state.formIsSubmitting
                                                                                    }
                                                                                    flat
                                                                                    active
                                                                                    onclick={() => {
                                                                                        actions.onActivateMesure(
                                                                                            item.name
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    {t(
                                                                                        'Activer'
                                                                                    )}
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                )}
                                                            </dummy>
                                                        ) : (
                                                            <div class="thfTelesuivi-thfTelesuiviMesureBlock-empty">
                                                                <p class="thfTelesuivi-thfTelesuiviMesureBlock-emptyTitle">
                                                                    {t(
                                                                        'Aucune désactivée'
                                                                    )}
                                                                </p>
                                                                <p class="thfTelesuivi-thfTelesuiviMesureBlock-emptySubTitle">
                                                                    {t(
                                                                        'Toutes les mesures sont activées ou télésuivies.'
                                                                    )}
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Tile.Content>
                                </Tile>
                            </dummy>
                        </Enter>
                    </Card.Header>
                </Card>
            </div>
        )
        return view
    },
}
