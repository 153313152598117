import { t } from 'i18next';
import { h } from 'hyperapp';
import { isDefined } from '@app/core';
import { allRoutes, addCSRFToken } from '@app/core';
import { Form, Card, Enter, Paginate, FormInput, Button } from '@app/elements';
import * as constants from '@app/constants';

import icoNoPatients from '@app/img/deco/img-no-patient.png';
import icoNoPatientsx2 from '@app/img/deco/img-no-patient@2x.png';

import icoCE from '@app/img/mentions/logo-right-ce.png';
import icoCEx2 from '@app/img/mentions/logo-right-ce@2x.png';

import icoSortAlpha from '@app/img/ico/table/ico-sort-alpha.png';
import icoSortAlphax2 from '@app/img/ico/table/ico-sort-alpha@2x.png';

export default {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div class='patients-central'>
                {isDefined(state.alerts) &&
                    <dummy>
                        <div class='thfTableHeader'>
                            <div class='thfTableMenus'>
                                {constants.custo.studies.filter((f) => (state.enableSurvey.indexOf(f.key) > -1)).map((item) =>
                                    <div class={'thfTableMenu ' + ((state.selectedSurvey === item.key) ? 'active' : '')} onclick={() => { actions.updateSelectedSurvey(item.key); }}>
                                        {item.label + (isDefined(state?.infos?.countUserMedicalStudySurveyAlertTodo?.[item.key]) ? ' (' + state?.infos?.countUserMedicalStudySurveyAlertTodo?.[item.key] + ')' : '')}
                                    </div>
                                )}
                            </div>
                            <Form onsubmit={event => event.preventDefault()}>
                                <Form.Group>
                                    <p class='thfTable-formInputLabel'>{t('Période')}</p>
                                    <FormInput
                                        key={'datesort'}
                                        type='dropdown'
                                        id={'datesort'}
                                        name='selectedDate'
                                        value={state.selectedDate}
                                        list={constants.custo.alertPeriods}
                                        initialCalls={false}
                                        withSearch={false}
                                        textedArea={false}
                                        oninputcb={actions.retrieveDateSort}
                                        isSmallDropdown={true}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group style={{ 'width': '160px' }}>
                                    <p class='thfTable-formInputLabel'>{t('Statut')}</p>
                                    <FormInput
                                        key={'statussort'}
                                        type='dropdown'
                                        id={'statussort'}
                                        name='selectedDate'
                                        value={state.selectedStatus}
                                        list={constants.custo.surveyStatus}
                                        initialCalls={false}
                                        withSearch={false}
                                        textedArea={false}
                                        oninputcb={actions.retrieveSurveyStatus}
                                        isSmallDropdown={true}
                                        required
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                        {(state.alerts.length === 0) ?
                            <div class='thfEmptyTable'>
                                <img src={icoNoPatients} srcset={`${icoNoPatientsx2} 2x`} alt='' />
                                {(state.noPatients === true) ?
                                    <dummy>
                                        <p class='thfEmptyTable-thfEmptyTableTitle'>{t('Pas de patient inclus')}</p>
                                        <p class='thfEmptyTable-thfEmptyTableSubTitle'>{t('Ajoutez un premier patient pour voir le reste des fonctionnalités.')}</p>
                                        <div style='margin: 15px auto auto;width: 200px;'>
                                            <Button active onclick={() => { window.location.href = addCSRFToken(allRoutes['private.patient.add'].pathname); }}>
                                                {t('Ajouter un patient')}
                                            </Button>
                                        </div>
                                    </dummy>
                                    :
                                    <dummy>
                                        <p class='thfEmptyTable-thfEmptyTableTitle'>{t('Aucune alerte à traiter')}</p>
                                        <p class='thfEmptyTable-thfEmptyTableSubTitle'>{t('Vous n’avez actuellement aucune alerte à traiter pour vos patients.')}</p>
                                    </dummy>
                                }
                                {props.customer === 'uroconnect' &&
                                    <div class='thfTableCertification'>
                                        <p class=''>{'UroConnect - ' + t('DM Classe I')}</p>
                                        <img src={icoCE} srcset={`${icoCEx2} 2x`} alt='' />
                                    </div>
                                }
                                {props.customer === 'oncoflow' &&
                                    <div class='thfTableCertification'>
                                        <p class=''>{'Oncolaxy - ' + t('DM Classe IIa')}</p>
                                        <img src={icoCE} srcset={`${icoCEx2} 2x`} alt='' />
                                    </div>
                                }
                            </div>
                            :
                            <Card classes={'btzPageCard btzBackgroundCover'} kind={'datas'}>
                                <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                                    <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
                                        <table class='thfTable'>
                                            {(state.selectedSurvey === 'curve') ?
                                                <thead>
                                                    <tr>
                                                        <th class='thfTable-cellName'>{t('Nom')}<img src={icoSortAlpha} srcset={`${icoSortAlphax2} 2x`} alt='' onclick={() => actions.onSortList('lastName')} /></th>
                                                        <th class='thfTable-cellMoment1' colspan={'3'}>{t('M1')}</th>
                                                        <th class='thfTable-cellMoment2' colspan={'3'}>{t('M2')}</th>
                                                        <th class='thfTable-cellMoment3' colspan={'6'}>{t('M3')}</th>
                                                        <th class=''></th>
                                                    </tr>
                                                </thead>
                                                :
                                                <thead>
                                                    <tr>
                                                        <th class='thfTable-cellName'>{t('Nom')}<img src={icoSortAlpha} srcset={`${icoSortAlphax2} 2x`} alt='' onclick={() => actions.onSortList('lastName')} /></th>
                                                        {state.alerts[0].moments.map((item) =>
                                                            <th class='thfTable-cellMoment'>{item.survey}</th>
                                                        )}
                                                        <th class=''></th>
                                                    </tr>
                                                </thead>
                                            }
                                            <tbody>
                                                {state.alerts.map(item =>
                                                    <tr>
                                                        <td class='thfTable-cellName' onclick={() => { actions.redirectToPatient({patient: item, moment: null}); }}>
                                                            <div class='btzCard-btzHeader-btzPage-btzNamePuce btzCard-btzHeader-btzPage-btzNamePuce-small'>
                                                                <p key={'listing-alertscurve-' + item.firstName + item.lastName} oncreate={(el) => { actions.getPatientName({el: el, patient: item}); }}></p>
                                                            </div>
                                                            <div class='thfTable-cellNameWrapper'>
                                                                <p class='btzCard-btzHeader-btzPage-btzNamePuce-small-fullName'>{item.lastName + ' ' + item.firstName}</p>
                                                                <span>{item.phonePrefix + item.phone}</span>
                                                            </div>
                                                        </td>
                                                        {item.moments.map((m) =>
                                                            <td tabindex='0'  onclick={(e) => { if ( !isDefined(m.answers[0]) || ((m.answers[0].alerts[0] === 'NODATA') || (m.answers[0].alerts.length === 0))) { actions.redirectToPatient({patient: item, moment: null}); } else { e.preventDefault(); e.stopPropagation(); actions.retrieveAlertDetail({id: m.answers[0].id, color: 'none'}) }}} onblur={() => { actions._setState({key: 'displayedPopup', value: null}); }} class={'thfTable-cellMomentCell ' + ((state.selectedSurvey === 'curve') ? ('cellMomentCell-' + m.moment) : '')}>
                                                                {((isDefined(m.answers[0]) && isDefined(m.answers[0].alerts) && (m.answers[0].alerts.length > 0) && (m.answers[0].alerts[0] !== 'NODATA')) ? (m.answers[0].alerts.length) : ((isDefined(m.answers[0]) && (m.answers[0].alerts[0] === 'NODATA')) ? '?' : '-'))}<span class={'thfPuce-cellColored ' + (!isDefined(m.answers[0]) ? '' : ((isDefined(m.answers[0].alerts[0]) && (m.answers[0].alerts[0] === 'NODATA')) ? 'stepNodata' : (!isDefined(m.answers[0].alerts[0] && (!isDefined(m.answers[0].answered) || m.answers[0].answered))) ? 'stepSuccess' : 'stepError')) + ' ' + ((isDefined(m.answers[0]) && isDefined(m.answers[0].action)) ? 'cellAlertTreated' : '')}></span>
                                                                {(isDefined(state.alertDetail) && isDefined(m.answers[0]) && (state.displayedPopup === (m.answers[0].id + '-' + 'none'))) &&
                                                                    <div class='lightPopup'>
                                                                        <div class='lightPopup-content'>
                                                                            {state.alertDetail.map((lrtD) =>
                                                                                <div class='thfTable-cellAlertColoredDetail'>
                                                                                    <p class='thfTable-cellAlertColoredDetailQuestion'>{lrtD.question}</p>
                                                                                    <p class='thfTable-cellAlertColoredDetailAnswer'>{lrtD.labeledAnswer}</p>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div class='lightPopup-footer' onclick={(e) => { e.preventDefault(); e.stopPropagation(); actions.redirectToPatient({patient: item, moment: m}) }}>
                                                                            <p class=''>{t('Voir le détail')}</p>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </td>
                                                        )}
                                                        <td class='thfTable-cellMore'>
                                                            <Form onsubmit={event => event.preventDefault()} classes={'hidden-field three-little-dot'}>
                                                                <Form.Group>
                                                                    <FormInput
                                                                        key={'surveyexport'}
                                                                        type='hidden-dropdown'
                                                                        id={'surveyexport'}
                                                                        name=''
                                                                        list={[{'label': t('Voir le détail'), 'key': 'redirect', 'redirectLink': () => { actions.redirectToPatient({patient: item, moment: null}); }}]}
                                                                        required
                                                                    />
                                                                </Form.Group>
                                                            </Form>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        <Paginate targetPage={actions.targetPage} nextPage={actions.nextPage} prevPage={actions.prevPage} limit={state.alerts.length} total={state.totalAlerts} bypage={state.bypage} page={state.page}></Paginate>
                                    </Enter>
                                </Card.Header>
                                {props.customer === 'uroconnect' &&
                                    <div class='thfTableCertification'>
                                        <p class=''>{'UroConnect - ' + t('DM Classe I')}</p>
                                        <img src={icoCE} srcset={`${icoCEx2} 2x`} alt='' />
                                    </div>
                                }
                                {props.customer === 'oncoflow' &&
                                    <div class='thfTableCertification'>
                                        <p class=''>{'Oncolaxy - ' + t('DM Classe IIa')}</p>
                                        <img src={icoCE} srcset={`${icoCEx2} 2x`} alt='' />
                                    </div>
                                }
                            </Card>
                        }
                    </dummy>
                }
            </div>
        );
        return view;
    }
}