import { t } from 'i18next';
import { h } from 'hyperapp';

/**
 * Translation element, takes only children
 * Not battle tested.
 * @param {any} children
 * @returns {String} chidren Translated children
 *  // DONT UNDERSTAND THE INTEREST
 */
const Trans = (props, children) => {
    return t(children)
};

export { Trans };
