import { t } from "i18next";
import { h } from "hyperapp";
import { Component } from "@app/utils";
import { isDefined, getReadableDate, getDateObjFromYMD } from "@app/core";
import { Tile } from "@app/elements";

import icoMD from "@app/img/mentions/logo-right-md.png";
import icoMDx2 from "@app/img/mentions/logo-right-md@2x.png";
import icoLOT from "@app/img/mentions/logo-right-lot.png";
import icoLOTx2 from "@app/img/mentions/logo-right-lot@2x.png";
import icoCE from "@app/img/mentions/logo-right-ce.png";
import icoCEx2 from "@app/img/mentions/logo-right-ce@2x.png";
import icoFACTORY from "@app/img/mentions/logo-right-factory.png";
import icoFACTORYx2 from "@app/img/mentions/logo-right-factory@2x.png";
import icoINFOS from "@app/img/mentions/logo-infos.png";
import icoINFOSx2 from "@app/img/mentions/logo-infos@2x.png";

import ViewInstructionDefault from "./subview-instructions";

const actions = {
	//
};

const view = (state, actions) => (props, children) =>
	(
		<dummy>
			<Tile>
				<Tile.Content>
					<p class="btzTitle btzXl">{t("Mentions légales.")}</p>
				</Tile.Content>
			</Tile>
			<div
				class="thfMentionsWrapper"
				style="padding: 20px 15px 0px;background: white;">
				<p class="">
					{t(
						"L‘ensemble des éléments constituant notre plateforme ont été conçus en suivant de près les normes internationales les plus strictes en terme de gestion de la qualité, de sécurité du système d‘information et de la conception de logiciels dispositifs médicaux."
					)}
				</p>
				<div class="" style="margin-bottom: 30px;margin-top: 30px;">
					<div
						class="thfMentionsLogos"
						style="display: inline-block;vertical-align: middle;height: 40px;">
						<img
							class=""
							style="margin-right: 10px;height: 40px;"
							src={icoMD}
							srcset={`${icoMDx2} 2x`}
							alt=""
						/>
						<img
							class=""
							style="margin-right: 5px;height: 40px;"
							src={icoCE}
							srcset={`${icoCEx2} 2x`}
							alt=""
						/>
						<p
							class=""
							style="display: inline-block; margin: 0px 10px 0px 0px; height: 15px;line-height: 1;">
							{"0459"}
						</p>
						<div style="display: inline-block; margin-right: 15px;position: relative;">
							<img
								class=""
								style="height: 40px;"
								src={icoINFOS}
								srcset={`${icoINFOSx2} 2x`}
								alt=""
							/>
							{isDefined(props.support.modules.notice) && (
								<a
									class="underlined"
									style="display: block; font-size: 10px; position: absolute; bottom: -15px;width: 100%;text-align: center;"
									href={
										process.env.APP_FRONT_WEB_URL +
										props.support.modules.notice
									}
									target="_blank">
									{t("lien notice")}
								</a>
							)}
						</div>
						<img
							class=""
							style="height: 40px;"
							src={icoFACTORY}
							srcset={`${icoFACTORYx2} 2x`}
							alt=""
						/>
					</div>
					<div style="display: inline-block;margin-left: 15px;vertical-align: middle;">
						<p class="" style="margin: 0px;line-height: 1.3;">
							Betterise Technologies
						</p>
						<p class="" style="margin: 0px;line-height: 1.3;">
							24 avenue Victor Hugo
						</p>
						<p class="" style="margin: 0px;line-height: 1.3;">
							64200 Biarritz, France
						</p>
						<p class="" style="margin: 0px;line-height: 1.3;">
							{props.support.mailto}
						</p>
					</div>
				</div>
				<p class="" style="text-transform: capitalize;">
					<img
						class=""
						style="margin-right: 10px;height: 40px;vertical-align: middle;"
						src={icoLOT}
						srcset={`${icoLOTx2} 2x`}
						alt=""
					/>
					{t("Produit ") +
						(process.env.APP_VERSIONPRODUCT || "N.C") +
						" (Front " +
						(process.env.APP_VERSION || "N.C") +
						", API " +
						(process.env.APP_VERSIONAPI || "N.C") +
						")" +
						(isDefined(process.env.APP_YMD)
							? ", " +
							  getReadableDate(
									getDateObjFromYMD(process.env.APP_YMD).date,
									"DD MMMM YYYY"
							  )
							: " - N.C")}
				</p>
				<p class="">
					{t(
						"Le portail Theraflow est un portail d’éducation thérapeutique et de transfert de données destiné à des patients atteints de maladies chroniques. Le portail Theraflow dispose de modules non médicaux d’éducation thérapeutique, de communication et de transmission de données."
					)}
				</p>
				<p class="">
					{t(
						"Le portail Theraflow dispose également de modules médicaux dont Theraflow IC."
					)}
				</p>
				<p class="">
					{t(
						"Theraflow IC est un dispositif médical logiciel de Classe I ayant pour usage prévu : la détection de poussées d’insuffisance cardiaque des patients diagnostiqués souffrant d’insuffisance cardiaque."
					)}
				</p>
				<p class="">
					{t(
						"Theraflow IC est indiqué pour le suivi du poids chez ces patients pour la détection de nouvelles poussées d’insuffisance cardiaque."
					)}
				</p>
				<p class="" style="margin-bottom: 20px;">
					{t(
						"Remarque : ce dispositif médical ne remplace pas les soins médicaux ou la pratique médical habituels. Un médecin doit toujours être contacté en cas de nouveaux symptômes ou avant toute modification de traitement."
					)}
				</p>
				<ViewInstructionDefault
					support={props.support}
					legalDocuments={
						Array.isArray(props.legalAgreements)
							? props.legalAgreements.map(
									(item) =>
										item.legalDocument ||
										item.legalDocuments
							  )
							: props.legalAgreements.legalDocument ||
							  props.legalAgreements.legalDocuments
					}></ViewInstructionDefault>
			</div>
		</dummy>
	);

export default Component({}, actions, view, "subview");
