import { t } from 'i18next'
import { User } from '@app/api'
import {
    enableTheseButton,
    desableTheseButton,
    deepCopy,
    valueCopy,
    ensureNIRValidity,
    parseMobileLeadingPlus,
    createUTCDate,
} from '@app/core'
import {
    isDefined,
    getYMDfromDateObj,
    addCSRFToken,
    allRoutes,
    coreFn,
} from '@app/core'
import { displayErrors } from '@app/api/errors'
import * as constants from '@app/constants'
import { getLangFromLocale } from '@app/core/localeHandlers/getLangFromLocale'
import { createLocaleFromLangAndUserObject } from '@app/core/localeHandlers/createLocaleFromLangAndUserObject'

/**
 * @module PatientEditProfil
 * @description Patient configuration etp
 */

export default {
    initAction: function () {
        let PatientEditProfil = {
            onComponentCreate: (props) => (state, actions) => {
                if (isDefined(props.customer)) {
                    if (
                        ['oncoflow', 'unicancer'].indexOf(
                            props.customer.toLowerCase()
                        ) > -1
                    ) {
                        actions._setValidity({ coach: true })
                    }
                    if (
                        ['cardiolaxy', 'weprom'].indexOf(
                            props.customer.toLowerCase()
                        ) > -1
                    ) {
                        actions._setState({
                            key: 'availableQRCode',
                            value: false,
                        })
                    }
                }
                actions._setState({ key: 'customer', value: props.customer })
                //
                User.getTimezone().then((timezones) => {
                    if (isDefined(timezones.data)) {
                        actions._setState({
                            key: 'timezones',
                            value: timezones.data.map((tmz) => {
                                return { key: tmz, label: tmz }
                            }),
                        })
                    }
                })
                //
                let toHydratePatient = deepCopy(props.editedPatient)
                if (!isDefined(toHydratePatient.locale)) {
                    toHydratePatient.locale = props.doctor.locale
                }
                actions.hydratePatient(toHydratePatient)
                actions.onComponentUpdate(props)
            },

            onComponentUpdate: (props) => (state, actions) => {
                if (
                    !isDefined(state.setAtLeastOneModification) &&
                    props.setAtLeastOneModification
                ) {
                    state.setAtLeastOneModification =
                        props.setAtLeastOneModification
                }
                actions._setState({ key: 'doctor', value: props.doctor })
                actions._setState({
                    key: 'doctorRole',
                    value: props.doctorRole,
                })
                actions._setState({ key: 'patientid', value: props.patientid })
                actions._setState({
                    key: 'referenceCustomer',
                    value: props.referenceCustomer,
                })
                actions._setState({ key: 'hospitals', value: props.hospitals })
                actions._setState({
                    key: 'referenceHospital',
                    value: props.referenceHospital,
                })
                actions._setState({
                    key: 'referenceHealTeam',
                    value: props.referenceHealTeam,
                })
                actions._setState({
                    key: 'referenceCoachTeam',
                    value: props.referenceCoachTeam,
                })
                actions._setState({
                    key: 'editedPatient',
                    value: props.editedPatient,
                })
                actions._setState({
                    key: 'consents',
                    value: props.editedPatient.consents,
                })
                actions._setState({
                    key: 'updatePatient',
                    value: props.updatePatient,
                })
                actions._setState({
                    key: 'parseHospitalHealTeam',
                    value: props.parseHospitalHealTeam,
                })
                actions._setState({ key: 'loaded', value: true })
                if (
                    constants.custo.CUSTOMERS[props.customer].modules.patient
                        .etapes === false
                ) {
                    actions._setValidity({ etapes: true })
                    actions._setValidity({ coach: true })
                }
            },
            synchLifenPatient: () => (state, actions) => {
                let values = state.values
                values.fname = state.lifenUser.firstName
                values.lname = state.lifenUser.lastName
                values.bname = state.lifenUser.maiden
                values.phone = state.lifenUser.phone
                values.phonePrefix = state.lifenUser.prefix
                values.email = state.lifenUser.email
                // values.timezone = state.lifenUser.timezone;
                // values.language = state.lifenUser.language;
                values.socialSecurityNumber =
                    state.lifenUser.socialSecurityNumber
                values.hospitalId = state.lifenUser.ipp
                values.gender = state.lifenUser.gender
                values.birthdate = getYMDfromDateObj(
                    createUTCDate(state.lifenUser.birthDate.date)
                )
                actions._setValuesObj(values)
                actions.updatePatient()
            },
            /**
             * Hydrate patient then call [hydrateOriginalMedicalValues]{@link module:PatientEditProfil~PatientEditProfil:hydrateOriginalMedicalValues})
             * @function PatientEditProfil:hydratePatient
             * @param {object} patient - Patient object
             */
            hydratePatient: (patient) => (state, actions) => {
                let values = state.values
                values.fname = patient.fname
                values.lname = patient.lname
                values.bname = patient.bname
                if (isDefined(patient.birthdate, true, true)) {
                    values.birthdate = getYMDfromDateObj(
                        createUTCDate(patient.birthdate.date)
                    )
                }
                if (isDefined(patient.userTheraflow)) {
                    values.hospitalId = patient.userTheraflow.hospitalId
                    values.socialSecurityNumber =
                        patient.userTheraflow.socialSecurityNumber
                    values.inclusionNumber =
                        patient.userTheraflow.inclusionNumber
                    values.etapes = patient.userTheraflow.etapes
                    values.pathologiesType =
                        patient.userTheraflow.pathologiesType
                    values.pathology = patient.userTheraflow.pathology
                    //
                    values.doctor = isDefined(patient.userTheraflow.doctor)
                        ? patient.userTheraflow.doctor.id
                        : null
                    values.coach = isDefined(patient.userTheraflow.coach)
                        ? patient.userTheraflow.coach.id
                        : null
                    values.nurses =
                        isDefined(patient.userTheraflow.nurses) &&
                        patient.userTheraflow.nurses.length > 0
                            ? patient.userTheraflow.nurses.map((m) => m.id)
                            : []
                }
                values.gender = patient.gender
                values.locale = getLangFromLocale(patient.locale)
                values.timezone = patient.timezone
                values.email = patient.email
                values.phonePrefix = isDefined(patient.phonePrefix)
                    ? parseMobileLeadingPlus(patient.phonePrefix)
                    : '+33'
                values.phone = patient.phone //.match(/.{2}/g);
                //
                let lifenId = patient.ssos.filter(
                    (sso) => sso.name === 'lifen'
                )[0]
                if (isDefined(lifenId)) {
                    User.getLyfenUserByLifenId(lifenId.id).then((res) => {
                        let lifenUser = res.data[0]
                        actions._setState({
                            key: 'lifenUser',
                            value: lifenUser,
                        })
                        if (isDefined(lifenUser.firstName, true, true)) {
                            if (
                                lifenUser.firstName !== values.fname ||
                                lifenUser.lastName !== values.lname ||
                                lifenUser.maiden !== values.bname ||
                                lifenUser.gender !== values.gender ||
                                getYMDfromDateObj(
                                    createUTCDate(lifenUser.birthDate.date),
                                    true,
                                    true
                                ) !==
                                    getYMDfromDateObj(
                                        createUTCDate(values.birthdate),
                                        true,
                                        true
                                    ) ||
                                lifenUser.ipp !== values.hospitalId ||
                                lifenUser.email !== values.email ||
                                // || (lifenUser.language !== values.language)
                                // || (lifenUser.timezone !== values.timezone)
                                (isDefined(lifenUser.phone) &&
                                    lifenUser.phone !== values.phone) ||
                                (isDefined(lifenUser.prefix) &&
                                    lifenUser.prefix !== values.phonePrefix)
                            ) {
                                actions._setState({
                                    key: 'previewLifenSynch',
                                    value: true,
                                })
                            }
                        }
                    })
                }
                //
                actions._setValuesObj(values)
                actions._setOValuesObj(deepCopy(values))
                actions.hydrateOriginalMedicalValues()
            },
            /**
             * Parse and hydrate initial medical teams (in order to make a comparaison before saving, to display disclamer medical changes modal)
             * @function PatientEditProfil:hydrateOriginalMedicalValues
             */
            hydrateOriginalMedicalValues:
                (force = false) =>
                (state, actions) => {
                    let currentMedicalValues = state.medicalValues
                    if (force || !isDefined(currentMedicalValues)) {
                        currentMedicalValues = {
                            doctor: valueCopy(state.values.doctor),
                            coach: valueCopy(state.values.coach),
                            nurses: valueCopy(state.values.nurses),
                        }
                        actions._setState({
                            key: 'medicalValues',
                            value: currentMedicalValues,
                        })
                    }
                },
            /**
             * Compare old and new medical teams, to display disclamer medical changes modal
             * @function PatientEditProfil:compareOldAndNewMedicalTeam
             */
            compareOldAndNewMedicalTeam: () => (state, actions) => {
                let confirm = false
                let currentMedicalValues = state.medicalValues
                //
                let sValue = '',
                    mValue = ''
                let added = { doctor: null, coach: null, nurses: [] }
                let removed = { doctor: null, coach: null, nurses: [] }
                let doctorId = state.doctor.id
                for (let mLabel in currentMedicalValues) {
                    mValue = currentMedicalValues[mLabel]
                    for (let sLabel in state.values) {
                        sValue = state.values[sLabel]
                        if (mLabel === sLabel) {
                            if (!isDefined(mValue)) {
                                mValue = ''
                            }
                            if (!isDefined(sValue)) {
                                sValue = ''
                            }
                            if (Array.isArray(mValue)) {
                                let arrValue = null
                                for (arrValue in mValue) {
                                    if (
                                        sValue.indexOf(mValue[arrValue]) === -1
                                    ) {
                                        removed[mLabel].push(mValue[arrValue])
                                        confirm = true
                                    }
                                }
                                for (arrValue in sValue) {
                                    if (
                                        mValue.indexOf(sValue[arrValue]) === -1
                                    ) {
                                        added[mLabel].push(sValue[arrValue])
                                        confirm = true
                                    }
                                }
                            } else {
                                if (mValue !== sValue) {
                                    confirm = true
                                    if (mValue !== '') {
                                        removed[mLabel] = mValue
                                    }
                                    if (sValue !== '') {
                                        added[mLabel] = sValue
                                    }
                                }
                            }
                            //
                        }
                    }
                }
                //
                let mappedAdded = []
                Object.values(added).forEach((item) => {
                    if (Array.isArray(item)) {
                        Object.values(item).forEach((itemm) => {
                            mappedAdded.push(itemm)
                            return itemm
                        })
                    } else if (isDefined(item)) {
                        mappedAdded.push(item)
                        return item
                    }
                })
                let mappedRemoved = []
                Object.values(removed).forEach((item) => {
                    if (Array.isArray(item)) {
                        Object.values(item).forEach((itemm) => {
                            mappedRemoved.push(itemm)
                            return itemm
                        })
                    } else if (isDefined(item)) {
                        mappedRemoved.push(item)
                        return item
                    }
                })
                //
                if (
                    isDefined(mappedAdded) &&
                    mappedAdded.indexOf(doctorId) === -1 &&
                    isDefined(mappedRemoved) &&
                    mappedRemoved.indexOf(doctorId) > -1
                ) {
                    actions._setState({ key: 'disclamerAutoBan', value: true })
                } else {
                    actions._setState({ key: 'disclamerAutoBan', value: false })
                }
                let disclamerRightLoosed = state.disclamerRightLoosed
                if (doctorId === state.values.coach) {
                    disclamerRightLoosed.coach = true
                } else {
                    disclamerRightLoosed.coach = false
                }
                actions._setState({
                    key: 'disclamerRightLoosed',
                    value: disclamerRightLoosed,
                })
                //
                actions._setState({ key: 'medicalAddedValues', value: added })
                actions._setState({
                    key: 'medicalRemovedValues',
                    value: removed,
                })
                actions.setMedicalTeamUpdateConfirm(confirm)
                return confirm
            },
            /**
             * Validation patient form and error message displaying
             * @function PatientEditProfil:onSubmit
             */
            onSubmit: (event) => (state, actions) => {
                event.preventDefault()
                if (state.formIsSubmitting) {
                    return false
                }
                actions._setTypingFalse()
                actions._retrieveErrorMessagePatient(null)
                //
                if (
                    actions.isValidSpecificField() &&
                    state.isValid.email &&
                    state.isValid.fname &&
                    state.isValid.lname &&
                    state.isValid.birthdate &&
                    state.isValid.hospitalId &&
                    state.isValid.gender &&
                    state.isValid.phone &&
                    state.isValid.doctor &&
                    state.isValid.nurses &&
                    state.isValid.socialSecurityNumber
                ) {
                    actions._setState({ key: 'formIsSubmitting', value: true })
                    actions.updatePatient()
                } else {
                    displayErrors(
                        t('Veuillez renseigner toutes les informations.'),
                        2000
                    )
                }
            },
            isValidSpecificField: () => (state, actions) => {
                let returnValue = true
                if (state.customer.toLowerCase().indexOf('uroconnect') === -1) {
                    if (!state.isValid.coach) {
                        returnValue = false
                    }
                }
                if (
                    state.customer.toLowerCase().indexOf('uroconnect') >= -1 ||
                    state.customer.toLowerCase().indexOf('resilience') >= -1
                ) {
                    if (!state.isValid.pathologiesType) {
                        returnValue = false
                    }
                }
                return returnValue
            },
            /**
             * Parse data following customer, then verify disponibility of email, if success call [doUpdatePatient]{@link module:PatientEditProfil~PatientEditProfil:doUpdatePatient})
             * @function PatientEditProfil:updatePatient
             */
            updatePatient:
                (force = false) =>
                (state, actions) => {
                    let confirm = actions.compareOldAndNewMedicalTeam()
                    if (!force && confirm) {
                        // displaying confirm popup
                        return false
                    }
                    //
                    let parsedValues = deepCopy(state.values)
                    if (Array.isArray(parsedValues.phone)) {
                        parsedValues.phone = parsedValues.phone.join('')
                    }
                    parsedValues.locale = createLocaleFromLangAndUserObject(
                        parsedValues.locale,
                        state.editedPatient
                    )
                    parsedValues.birthdate =
                        parsedValues.birthdate.year +
                        '' +
                        parsedValues.birthdate.month +
                        '' +
                        parsedValues.birthdate.day
                    // parsedValues.gender = ((parsedValues.gender === 1) || (parsedValues.gender === true)) ? true : false;
                    if (state.customer === 'oncoflow') {
                        // parsedValues.pathologiesType = [];
                        delete parsedValues.socialSecurityNumber
                    }
                    //
                    actions.setMedicalTeamUpdateConfirm(false)
                    let email = state.values.email
                    if (email.indexOf(state.patientid) > -1) {
                        email = null
                        delete parsedValues.email
                    }
                    //
                    if (
                        isDefined(email) &&
                        state.editedPatient.email !== email
                    ) {
                        User.isEmailAvailable(email).then((res) => {
                            if (
                                isDefined(res.data) &&
                                isDefined(res.data.code) &&
                                res.data.code === '102'
                            ) {
                                actions.doUpdatePatient(parsedValues)
                            } else {
                                actions._setState({
                                    key: 'formIsSubmitting',
                                    value: false,
                                })
                                actions._setErrorMessages({
                                    email: t('Cet email est déjà utilisé'),
                                })
                                actions._setValidity({ email: false })
                            }
                        })
                    } else {
                        actions.doUpdatePatient(parsedValues)
                    }
                },
            /**
             * Send all data to update current patient, then call [getTheraflowUser]{@link module:PatientEdit~PatientEdit:getTheraflowUser})
             * @param {object} parsedValues - Parsed patient datas
             * @function PatientEditProfil:doUpdatePatient
             */
            doUpdatePatient: (parsedValues) => (state, actions) => {
                User.updateTheraflowUser(state.patientid, parsedValues).then(
                    (res) => {
                        actions._setState({
                            key: 'formIsSubmitting',
                            value: false,
                        })
                        if (isDefined(res.data)) {
                            if (isDefined(res.data.id)) {
                                displayErrors(
                                    t('Patient mis à jour avec succès.'),
                                    3000,
                                    'success'
                                )
                                actions._handleLeaveWithoutSaveing(false)
                                if (state.disclamerAutoBan === true) {
                                    window.location.href = addCSRFToken(
                                        allRoutes['private.patients.listing']
                                            .pathname
                                    )
                                    return false
                                }
                                //
                                actions.setLifenSynchConfirm(false)
                                actions.setPreviewLifenSynchConfirm(false)
                                actions._setOValuesObj(deepCopy(state.values))
                                actions.hydrateOriginalMedicalValues(true)
                                state.setAtLeastOneModification(false)
                                state.updatePatient()
                            } else {
                                let errorMessage = res.data.message
                                if (+res.data.code === 149) {
                                    errorMessage = t(
                                        'Merci de renseigner un numéro de téléphone valide'
                                    )
                                }
                                displayErrors(errorMessage, 3000)
                                actions._handle400(res.data)
                            }
                        } else {
                            displayErrors(
                                t(
                                    'Erreur inconnue, veuillez essayer à nouveau.'
                                ),
                                3000
                            )
                        }
                    }
                )
            },
            /**
             * Retrieve hospital on the fly, then call [parseHospitalHealTeam]{@link module:PatientEdit~PatientEdit:parseHospitalHealTeam})
             * @param {input} input - Contain [isvalid], [name], [id] and [values] fields
             * @function PatientEditProfil:retrieveHospital
             */
            retrieveHospital: (input) => (state, actions) => {
                const { name, values } = input
                if (state[name] === values) {
                    // if we are selecting the same hospital as already defined, no reset
                    return false
                }
                //
                actions._setState({ key: [name], value: values })
                //
                let refHosp = null
                for (let hosp in state.hospitals) {
                    if (state.hospitals[hosp].id === values) {
                        refHosp = state.hospitals[hosp]
                    }
                }
                //
                actions._setValues({ doctor: '' })
                actions._setValidity({ doctor: false })
                actions._setValues({ coach: '' })
                actions._setValidity({ coach: 'is-default' })
                actions._setValues({ nurses: [] })
                actions._setValidity({ nurses: 'is-default' })
                if (isDefined(refHosp)) {
                    state.parseHospitalHealTeam(refHosp)
                }
            },
            /**
             * Validate and update values/validity of NIR (used by <socialSecurityNumber> field)
             * @function PatientEditProfil:ensureNIRValidity
             * @param {string} value - The NIR to validate
             */
            ensureNIRValidity: (value) => (state, actions) => {
                let isvalid = ensureNIRValidity(
                    value,
                    state.values.gender || '',
                    true
                )
                if (isvalid !== true) {
                    isvalid = false
                }
                actions._setValidity({ socialSecurityNumber: isvalid })
                actions._setValues({ socialSecurityNumber: value })
                return isvalid
            },
            /**
             * Show/hide medical disclamer change modal
             * @function PatientEditProfil:setMedicalTeamUpdateConfirm
             * @param {object} newState - Target {[name]: {string}} to update
             */
            setMedicalTeamUpdateConfirm: (newState) => (state) => ({
                formIsSubmitting: newState,
                medicalTeamUpdateConfirm: newState,
            }),
            setLifenSynchConfirm: (newState) => (state) => ({
                needLifenSynch: newState,
            }),
            setPreviewLifenSynchConfirm: (newState) => (state) => ({
                previewLifenSynch: newState,
            }),
            addWholeTeam: () => (state, actions) => {
                if (
                    isDefined(state.values.doctor) &&
                    state.values.doctor !== ''
                ) {
                    let wholeTeam = state.referenceHealTeam
                        .filter(
                            (f) =>
                                f.key !== state.values.doctor &&
                                f.key !== state.values.coach &&
                                state.values.nurses.indexOf(f.key) === -1
                        )
                        .map((m) => m.key)
                    let existingTeam = state.values.nurses
                    for (let member in wholeTeam) {
                        existingTeam.push(wholeTeam[member])
                    }
                    actions._setValues({ nurses: existingTeam })
                    actions._setState({
                        key: 'addWholeDisclamerMessage',
                        value: '',
                    })
                } else {
                    actions._setState({
                        key: 'addWholeDisclamerMessage',
                        value: t(
                            'Sélectionnez le médecin référent avant d’ajouter « Tous »'
                        ),
                    })
                }
            },
        }
        PatientEditProfil = { ...coreFn, ...PatientEditProfil }
        return PatientEditProfil
    },
}
