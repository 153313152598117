import { settings } from '@app/core/settings';
import { handleErrors } from '@app/api/errors';
import { queueQueries, executeSynchedQuery } from '@app/api/controlleur';

const { API_URL } = settings;

function generateToken(id, identity) {
    return queueQueries(`${API_URL}/twilio/token/${id}/${identity}`, 'GET');
}
function closeRoom(id) {
    return executeSynchedQuery(`${API_URL}/twilio/disconnect/${id}`, 'POST', 'application/json', false);
}

export const TwilioAPI = {
    generateToken,
    closeRoom,
};