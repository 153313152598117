import { t } from 'i18next'
import { h } from 'hyperapp'
import { Button, Form, FormInput, Card, Modal } from '@app/elements'
import { isDefined } from '@app/core'
import * as constants from '@app/constants'

import { ModalViewSurveyPreview } from './modalView-surveyPreview-default'

import icoSend from '@app/img/ico/ico-send-white.png'
import icoSendx2 from '@app/img/ico/ico-send-white@2x.png'

import icoDangerous from '@app/img/ico/ico-dangerous.png'
import icoDangerousx2 from '@app/img/ico/ico-dangerous@2x.png'

export const ModalViewSurvey = (props, actions) => (
    <Card classes={'btzInformations-btzInformationsModal'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            <div class={'btzClassMarker-btz'} key={'survey'}>
                <p class="btzTitle btzXl">
                    {props.medicalStudyEdited.usualName}
                </p>
                {props.newSurvey ? (
                    <p class="btzSubTitle">
                        {t(
                            "Complétez les champs suivants pour commencer les envois de ce questionnaire pour votre patient. Consultez le récapitulatif avant d'activer les envois."
                        )}
                    </p>
                ) : (
                    <p class="btzSubTitle">
                        {t(
                            'Administrez ici le questionnaire de votre patient. Vous pouvez activer / désactiver le questionnaire et modifier quand le questionnaire est envoyé.'
                        )}
                    </p>
                )}

                <Form
                    onsubmit={(event) => event.preventDefault()}
                    classes={
                        props.customer +
                        '-formMarker' +
                        ' ' +
                        'medicalStudyEditedStatus-' +
                        props.medicalStudyEdited.status
                    }
                    style={{
                        'height':
                            String(props.medicalStudyEdited.status) === 'false'
                                ? 'calc(100vh - 350px)'
                                : 'calc(100vh - 245px)',
                        'overflow-y': 'auto',
                        'overflow-x': 'hidden',
                    }}
                >
                    <Form.Group>
                        <div class="col-12 thfAlignTop">
                            {(!props.newSurvey ||
                                isDefined(props.medicalStudyEdited.key)) && (
                                <FormInput
                                    key="input-status"
                                    type="switch"
                                    id="status"
                                    name="status"
                                    value={props.medicalStudyEdited.status}
                                    list={[
                                        {
                                            label:
                                                t('Questionnaire') +
                                                ' ' +
                                                props.medicalStudyEdited
                                                    .usualName +
                                                ' ' +
                                                t('activé'),
                                            key: true,
                                        },
                                        {
                                            label:
                                                t('Questionnaire') +
                                                ' ' +
                                                props.medicalStudyEdited
                                                    .usualName +
                                                ' ' +
                                                t('désactivé'),
                                            key: false,
                                        },
                                    ]}
                                    oninputcb={props.retrieveInput}
                                    loaded={props.loaded}
                                    optClasses={'input-switch-dangerous-off'}
                                    required
                                />
                            )}
                            <p class="btzForm-btzLabel">
                                {t("Fréquence d'envoi")}
                            </p>
                            <FormInput
                                key="input-frequency"
                                type="dropdown"
                                id="frequency"
                                name="frequency"
                                value={t(props.medicalStudyEdited.frequency)}
                                list={
                                    props.medicalStudyEdited.frequencyAvailable
                                }
                                oninputcb={props.retrieveInput}
                                readonly={
                                    props.medicalStudyEdited.status === true
                                        ? false
                                        : true
                                }
                                loaded={props.loaded}
                                isTyping={props.isTyping.issuePeriod}
                                stricMode={true}
                                required
                            />
                            <p class="btzForm-btzLabel">{t("Date d'envoi")}</p>
                            <FormInput
                                key="input-issuePeriod"
                                type="dropdown"
                                id="issuePeriod"
                                name="issuePeriod"
                                value={props.medicalStudyEdited.issuePeriod}
                                list={constants.custo.periodFrequencies
                                    .filter(
                                        (f) =>
                                            isDefined(
                                                props.medicalStudyEdited
                                                    .frequencyAvailable
                                            ) &&
                                            props.medicalStudyEdited.frequencyAvailable
                                                .map((m) => m.key)
                                                .indexOf(f.frequency) > -1
                                    )
                                    .filter(
                                        (f) =>
                                            f.frequency ===
                                            props.medicalStudyEdited.frequency
                                    )
                                    .map((m) => {
                                        return { label: t(m.label), key: m.key }
                                    })}
                                oninputcb={props.retrieveInput}
                                readonly={
                                    props.medicalStudyEdited.status === true
                                        ? false
                                        : true
                                }
                                loaded={props.loaded}
                                isTyping={props.isTyping.issuePeriod}
                                required
                            />
                            <p class="btzForm-btzLabel">
                                {t("Mode d'émission")}
                            </p>
                            <FormInput
                                key="input-issueSentBy"
                                type="dropdown"
                                id="issueSentBy"
                                name="issueSentBy"
                                value={props.medicalStudyEdited.issueSentBy}
                                isValid={
                                    props.formSubmittedOnce
                                        ? props.isValid.issueSentBy
                                        : 'is-default'
                                }
                                list={constants.custo.sendingWays.map((m) => {
                                    return {
                                        label:
                                            t(m.label) +
                                            ' (' +
                                            (m.key === 'SMS'
                                                ? props.editedPatient
                                                      .phonePrefix +
                                                  props.editedPatient.phone
                                                : props.autoGeneratedEmail
                                                ? t('non défini')
                                                : props.editedPatient.email) +
                                            ')',
                                        key: m.key,
                                    }
                                })}
                                oninputcb={props.retrieveInput}
                                readonly={
                                    props.medicalStudyEdited.status === true
                                        ? false
                                        : true
                                }
                                errorMessage={props.errorMessages.issueSentBy}
                                isTyping={props.isTyping.issueSentBy}
                                required
                            />
                        </div>
                    </Form.Group>
                    {String(props.medicalStudyEdited.status) === 'false' && (
                        <p
                            class="btzSubTitle btzSubTitleFooter thfColored-red"
                            style="text-align: center;"
                        >
                            {t(
                                "Vous êtes sur le point d'arrêter les envois du questionnaire. Une fois cette action validée, votre patient ne recevra plus le questionnaire"
                            )}
                        </p>
                    )}
                    {isDefined(props.medicalStudyEdited) && (
                        <div class="btzFooterSummary">
                            <div class="btzFooterSummaryBlock">
                                <p class="btzFooterSummaryBlockTitle">
                                    {t('Récapitulatif') + ' :'}
                                </p>
                                {isDefined(
                                    props.medicalStudyEdited.issueSentBy
                                ) &&
                                    isDefined(
                                        props.medicalStudyEdited.issuePeriod
                                    ) && (
                                        <p class="btzFooterSummaryBlockContent">
                                            {t(
                                                'Avec cette configuration, les questionnaires sont envoyés par'
                                            ) +
                                                ' ' +
                                                (props.medicalStudyEdited
                                                    .issueSentBy === 'SMS'
                                                    ? t('SMS')
                                                    : t('EMAIL')) +
                                                ', ' +
                                                constants.custo.periodFrequencies
                                                    .filter(
                                                        (f) =>
                                                            f.key ===
                                                            props
                                                                .medicalStudyEdited
                                                                .issuePeriod
                                                    )[0]
                                                    .label.toLowerCase()}
                                        </p>
                                    )}
                            </div>
                            {isDefined(
                                props.repeatableMedicalInfos.filter(
                                    (f) =>
                                        f.key === props.medicalStudyEdited.organ
                                )[0]
                            ) && (
                                <dummy>
                                    <div
                                        class="btzFooterSummaryBlock"
                                        style="margin-bottom: 25px;"
                                    >
                                        <p
                                            class="btzFooterSummaryBlockTitle"
                                            style="margin-bottom: 0px !important;"
                                        >
                                            {t('Questionnaire') + ' :'}
                                        </p>
                                        <p
                                            class="btzFooterSummaryBlockLink"
                                            style="display: inline;"
                                            onclick={(e) => {
                                                props.retrieveSurveyContent(
                                                    props.medicalStudyEdited
                                                )
                                            }}
                                        >
                                            {
                                                props.repeatableMedicalInfos
                                                    .filter(
                                                        (f) =>
                                                            f.key ===
                                                            props
                                                                .medicalStudyEdited
                                                                .organ
                                                    )[0]
                                                    .surveys.filter(
                                                        (f) =>
                                                            f.name ===
                                                            props
                                                                .medicalStudyEdited
                                                                .name
                                                    )[0].usualName
                                            }
                                        </p>
                                    </div>
                                    <div class="btzFooterSummaryBlock">
                                        <p
                                            class="btzFooterSummaryBlockTitle"
                                            style="display: inline;margin: 0 5px 0 0;line-height: 1.2;"
                                        >
                                            {t('Indication clinique') + ' :'}
                                        </p>
                                        <p
                                            class="btzFooterSummaryBlockContent"
                                            style="display: inline;margin: 0px !important;line-height: 1.2;"
                                        >
                                            {
                                                props.repeatableMedicalInfos
                                                    .filter(
                                                        (f) =>
                                                            f.key ===
                                                            props
                                                                .medicalStudyEdited
                                                                .organ
                                                    )[0]
                                                    .surveys.filter(
                                                        (f) =>
                                                            f.name ===
                                                            props
                                                                .medicalStudyEdited
                                                                .name
                                                    )[0].clinicalIndication
                                            }
                                        </p>
                                    </div>
                                </dummy>
                            )}
                        </div>
                    )}
                    <Form.Group classes="btzForm-btzFooter">
                        <Button
                            anchorLeft
                            flat
                            cancel
                            width={'36%'}
                            onclick={(e) => {
                                props.cancelAction(e)
                            }}
                        >
                            {t('Annuler')}
                        </Button>
                        <Button
                            anchorRight
                            flat
                            active={
                                props.medicalStudyEdited.issueSentBy ===
                                    'EMAIL' && props.autoGeneratedEmail
                                    ? false
                                    : true
                            }
                            dangerous={
                                String(props.medicalStudyEdited.status) ===
                                'true'
                                    ? false
                                    : true
                            }
                            onclick={(e) => {
                                props.onSubmit(e)
                            }}
                        >
                            {String(props.medicalStudyEdited.status) ===
                            'true' ? (
                                <dummy style="color: white;">
                                    <img
                                        alt={'Modifier'}
                                        style="vertical-align: middle;margin: 0 10px 0 -10px;"
                                        src={icoSend}
                                        srcset={`${icoSendx2} 2x`}
                                    />
                                    {props.newSurvey
                                        ? isDefined(
                                              props.medicalStudyEdited.key
                                          )
                                            ? t('Réactiver')
                                            : t('Activer')
                                        : t('Modifier')}
                                </dummy>
                            ) : (
                                <dummy style="color: white;">
                                    <img
                                        alt={'Stop'}
                                        style="vertical-align: middle;margin: 0 10px 0 -10px;"
                                        src={icoDangerous}
                                        srcset={`${icoDangerousx2} 2x`}
                                    />
                                    {t('Arrêter')}
                                </dummy>
                            )}
                        </Button>
                    </Form.Group>
                </Form>
            </div>
        </Card.Header>

        {props.surveyPreviewDisplayed && (
            <Modal
                key={'modal-medical-confirm'}
                customClass="modal-central"
                cancelAction={() => {
                    props._setState({
                        key: 'surveyPreviewDisplayed',
                        value: false,
                    })
                }}
                modalDisplayed={true}
                content={
                    <ModalViewSurveyPreview
                        displayed={'medicalconfirm'}
                        {...props}
                        cancelAction={() => {
                            props._setState({
                                key: 'surveyPreviewDisplayed',
                                value: false,
                            })
                        }}
                        loaded={true}
                    ></ModalViewSurveyPreview>
                }
            ></Modal>
        )}
    </Card>
)
