import { h } from 'hyperapp';
import { Card } from '@app/elements';
import { isDefined } from '@app/core';

export const ModalViewDoSurvey = (props, actions) => (
    <dummy>
        <Card classes={'btzInformations-btzInformationsModal btzDoSurvey-modal'}>
            <Card.Body>
                <iframe id='inlineFrameExample'
                    title='Inline Frame Example'
                    style='border: none;width: 100%;height: calc(100vh - 75px);'
                    src={(isDefined(props.surveyUrl) ? props.surveyUrl : '')}>
                </iframe>
            </Card.Body>
        </Card>
    </dummy>
);