import { t } from 'i18next';
import { h } from 'hyperapp';
import { Button, Card } from '@app/elements';
import { isDefined, getYMDfromDateObj, createUTCDate } from '@app/core';

import icoTreat from '@app/img/ico/ico-treat.png';
import icoTreatx2 from '@app/img/ico/ico-treat@2x.png';

export const ModalViewLifenSynch = (props, actions) => (
    <Card classes={'btzInformations-btzInformationsModal'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            <div key={props.modalEditing} key={'treatment'}>
                <p class='btzTitle btzXl'>{t('Demande de confirmation')}</p>
                <p class='btzSubTitle'>{t('Vous avez demandé la synchronisation des données du patient avec le Dossier Patient Informatisé de votre établissement.')}</p>
                <p class='btzSubTitle'>{t('Les informations suivantes vont être modifiées ou complétées sur le profil du patient :')}</p>
                <ul class='btzSubTitle'>
                    {(props?.lifenUser?.firstName !== props?.values?.fname) &&
                        <li>{t('Prénom') + ' (' + (props?.values?.fname || t('vide')) + ' > ' + (props?.lifenUser?.firstName || t('vide')) + ')'}</li>
                    }
                    {(props?.lifenUser?.lastName !== props?.values?.lname) &&
                        <li>{t('Nom d\'usage') + ' (' + (props?.values?.lname || t('vide')) + ' > ' + (props?.lifenUser?.lastName || t('vide')) + ')'}</li>
                    }
                    {(props?.lifenUser?.lastName !== props?.values?.lname) &&
                        <li>{t('Nom de naissance') + ' (' + (props?.values?.bname || t('vide')) + ' > ' + (props?.lifenUser?.maiden || t('vide')) + ')'}</li>
                    }
                    {(props?.lifenUser?.gender !== props?.values?.gender) &&
                        <li>{t('Genre') + ' (' + (isDefined(props?.values?.gender) ? (props?.values?.gender ? t('Masculin') : t('Féminin')) : t('vide')) + ' > ' + (isDefined(props?.lifenUser?.gender) ? (props?.lifenUser?.gender ? t('Masculin') : t('Féminin')) : t('vide')) + ')'}</li>
                    }
                    {(isDefined(props?.lifenUser?.birthDate) && (getYMDfromDateObj(createUTCDate(props?.lifenUser?.birthDate.date), true, true) !== getYMDfromDateObj(createUTCDate(props?.values?.birthdate), true, true))) &&
                        <li>{t('Date de naissance') + ' (' + (isDefined(props?.values?.birthdate) ? getYMDfromDateObj(createUTCDate(props?.values?.birthdate), true) : t('vide')) + ' > ' + (isDefined(props?.lifenUser?.birthDate) ? getYMDfromDateObj(createUTCDate(props?.lifenUser?.birthDate.date), true) : t('vide')) + ')'}</li>
                    }
                    {(props?.lifenUser?.ipp !== props?.values?.hospitalId) &&
                        <li>{t('Identifiant patient unique') + ' (' + (props?.values?.ipp || t('vide')) + ' > ' + (props?.lifenUser?.hospitalId || t('vide')) + ')'}</li>
                    }
                    {(isDefined(props?.lifenUser?.phone) && (props?.lifenUser?.phone !== props?.values?.phone)) &&
                        <li>{t('Numéro de téléphone') + ' (' + (props?.values?.phone || t('vide')) + ' > ' + (props?.lifenUser?.phone || t('vide')) + ')'}</li>
                    }
                    {(isDefined(props?.lifenUser?.prefix) && (props?.lifenUser?.prefix !== props?.values?.phonePrefix)) &&
                        <li>{t('Préfix téléphonique') + ' (' + (props?.values?.prefix || t('vide')) + ' > ' + (props?.lifenUser?.phonePrefix || t('vide')) + ')'}</li>
                    }
                    {(isDefined(props?.lifenUser?.email) && (props?.lifenUser?.email !== props?.values?.email)) &&
                        <li>{t('Email') + ' (' + (props?.values?.email || t('vide')) + ' > ' + (props?.lifenUser?.email || t('vide')) + ')'}</li>
                    }
                </ul>
            </div>
        </Card.Header>
        <Card.Body>
            <div class='btzCard-btzFooter-btzModal' style='margin-top: 40px;'>
                <Button anchorRight flat margin={'0 0 0 15px'} active onclick={() => {
                    props.onSubmit();
                }}>
                    <img class='activated activated-visible' src={icoTreat} srcset={`${icoTreatx2} 2x`} alt='' />
                    {t('Valider')}
                </Button>
                <Button anchorRight flat cancel onclick={(e) => {
                    props.cancelAction(e);
                }}>
                    {t('Annuler')}
                </Button>
            </div>
        </Card.Body>
    </Card>
);