import { t } from 'i18next'
import { h } from 'hyperapp'
import { allRoutes, addCSRFToken, isDefined } from '@app/core'
import { Link, Form, FormInput } from '@app/elements'
import * as constants from '@app/constants'

import icoAccount from '@app/img/ico/custo/resilience/ico-account.png'
import icoAccountx2 from '@app/img/ico/custo/resilience/ico-account@2x.png'
import icoAccountGrey from '@app/img/ico/custo/resilience/ico-account.png'
import icoAccountGreyx2 from '@app/img/ico/custo/resilience/ico-account@2x.png'

import icoLogout from '@app/img/ico/left-menu/ico-logout.png'
import icoLogoutx2 from '@app/img/ico/left-menu/ico-logout@2x.png'

import icoMentions from '@app/img/ico/custo/resilience/ico-mentions.png'
import icoMentionsx2 from '@app/img/ico/custo/resilience/ico-mentions@2x.png'
import icoMentionsGrey from '@app/img/ico/custo/resilience/ico-mentions-active.png'
import icoMentionsGreyx2 from '@app/img/ico/custo/resilience/ico-mentions-active@2x.png'

export default {
    initView: function () {
        let view = (state, actions) => (props, children) => (
            <div class={'usermenu-displayed-' + props.display}>
                {state.messageActive && (
                    <MessagePopup
                        key={'msspp-usermenu'}
                        kind={state.messageKind}
                        active={'active'}
                        duration={3000}
                        durationCallback={() =>
                            actions.displaySuccessMessage(false)
                        }
                        message={state.messageContent}
                    ></MessagePopup>
                )}
                {props.display === 'login' && (
                    <div class={'usermenu-userlanguages'}>
                        <Form onsubmit={(event) => event.preventDefault()}>
                            <Form.Group>
                                <FormInput
                                    key="input-locale"
                                    type="dropdown"
                                    id="locale"
                                    name="locale"
                                    value={state.currentLanguage}
                                    list={constants.custo.languages.map((m) => {
                                        return {
                                            label: t(m.label),
                                            key: m.key,
                                            img: m.img,
                                        }
                                    })}
                                    oninputcb={(input) => {
                                        const { values } = input
                                        actions.updateLocale(values)
                                    }}
                                />
                            </Form.Group>
                        </Form>
                    </div>
                )}
                {props.display !== 'login' && (
                    <ul class="usermenu-bottom">
                        <li
                            onclick={() =>
                                (window.location.href = addCSRFToken(
                                    allRoutes['private.account'].pathname
                                ))
                            }
                            class={props.page == 'account' ? 'active' : ''}
                        >
                            <div>
                                {props.page == 'account' ? (
                                    <img
                                        class="activated"
                                        src={icoAccountGrey}
                                        srcset={`${icoAccountGreyx2} 2x`}
                                        alt=""
                                    />
                                ) : (
                                    <img
                                        class="activated"
                                        src={icoAccount}
                                        srcset={`${icoAccountx2} 2x`}
                                        alt=""
                                    />
                                )}
                            </div>
                            <Link>{t('Votre compte')}</Link>
                        </li>
                        <li
                            onclick={() =>
                                (window.location.href = addCSRFToken(
                                    allRoutes['private.mentions'].pathname
                                ))
                            }
                            class={props.page == 'mentions' ? 'active' : ''}
                        >
                            <div>
                                {props.page == 'mentions' ? (
                                    <img
                                        class="activated"
                                        src={icoMentionsGrey}
                                        srcset={`${icoMentionsGreyx2} 2x`}
                                        alt=""
                                    />
                                ) : (
                                    <img
                                        class="activated"
                                        src={icoMentions}
                                        srcset={`${icoMentionsx2} 2x`}
                                        alt=""
                                    />
                                )}
                            </div>
                            <Link>{t('Mentions légales')}</Link>
                        </li>
                        <li onclick={actions.deconnectUser}>
                            <div>
                                <img
                                    class="activated"
                                    src={icoLogout}
                                    srcset={`${icoLogoutx2} 2x`}
                                    alt=""
                                />
                            </div>
                            <Link>{t('Déconnexion')}</Link>
                        </li>
                    </ul>
                )}
            </div>
        )
        return view
    },
}
