import { Admin } from '@app/api'
import { h } from 'hyperapp'
import { Component } from '@app/utils'

import { Grid, Navbar, Enter } from '@app/elements'
import {
    allRoutes,
    isDefined,
    getUser,
    parseJsonObjectToString,
    parseStringToJsonObj,
} from '@app/core'
import * as constants from '@app/constants'

import { TopLogoView } from '@app/layouts/toplogo'
import { TopMenuView } from '@app/layouts/topmenu'
import { LeftMenuView } from '@app/layouts/leftmenu'

import { BotFooterView } from '@app/layouts/botfooter'

import './index.scss'

const state = {
    logged: false,
    doctor: null,
    minifiedLeftMenu: false,
    infos: [],
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        if (localStorage.getItem('minifiedLeftMenu') === 'true') {
            actions.minifyLeftMenu()
        }
        //
        // let infos = sessionStorage.getItem('infos');
        // if (isDefined(infos) && (infos !== false)) {
        //     actions.setInfos(parseStringToJsonObj(infos));
        // } else {
        actions.updateInfos()
        // }
        window.addEventListener('updateInfos', actions.updateInfos)
        actions.onComponentUpdate(props)
    },
    onComponentUpdate: (props) => (state, actions) => {
        getUser((adminUser) => {
            if (isDefined(adminUser) && adminUser !== false) {
                actions.setDoctor(adminUser)
                if (isDefined(adminUser.id)) {
                    actions.setLogged(true)
                }
            }
        })
        //
    },
    updateInfos: () => (state, actions) => {
        Admin.getInfo().then((res) => {
            sessionStorage.setItem('infos', parseJsonObjectToString(res.data))
            actions.setInfos(res.data)
        })
    },
    minifyLeftMenu: () => (state, actions) => {
        localStorage.setItem('minifiedLeftMenu', !state.minifiedLeftMenu)
        actions.setMinifiedLeftMenu(!state.minifiedLeftMenu)
    },
    setMinifiedLeftMenu: (newState) => (state) => ({
        minifiedLeftMenu: newState,
    }),
    setLogged: (newState) => (state) => ({
        logged: newState,
    }),
    setDoctor: (newState) => (state) => ({
        doctor: newState,
    }),
    setInfos: (newState) => (state) => ({
        infos: newState,
    }),
    goBack: () => (state) => {
        window.history.back()
    },
}

const view = (state, actions) => (props, children) => (
    <div
        key={'register'}
        class={
            props.page +
            ' ' +
            (state.minifiedLeftMenu ? 'minifiedLeftMenu' : '')
        }
    >
        <Enter
            time={constants.custo.ANIM_DURATION}
            easing={constants.custo.ANIM_EASING}
            css={constants.custo.ANIM_CSS}
        >
            <Grid classes={'btzHeader btzHeader-btzNoMediaQuery'}>
                <Grid.Row classes={'btzHeaderWrapper'}>
                    <Grid.Col x={12} mx={'auto'} classes={'p-0'}>
                        <Navbar>
                            <Navbar.Section classes="btzHeader-btzHeaderLeftHand">
                                <TopLogoView
                                    {...props}
                                    color={'black'}
                                    redirectTo={allRoutes['index'].pathname}
                                    customer={props.customer}
                                ></TopLogoView>
                            </Navbar.Section>

                            <Navbar.Section classes="btzHeader-btzHeaderRightHand">
                                {state.logged && (
                                    <TopMenuView
                                        {...props}
                                        doctor={state.doctor}
                                        customer={props.customer}
                                    ></TopMenuView>
                                )}
                            </Navbar.Section>
                        </Navbar>
                    </Grid.Col>
                </Grid.Row>
            </Grid>
            <Grid
                classes={'btzWrapper btzWrapperFullHeight btzVerticalAlign'}
                style={{ padding: '0px' }}
            >
                <Grid.Row
                    classes={
                        'btzWrapper-verticalAlignContent' +
                        (props.howthatwork
                            ? ' btzWrapper-verticalAlignContent-minHeight'
                            : '')
                    }
                >
                    <Grid.Col
                        x={12}
                        mx={'auto'}
                        style={{
                            'position': 'relative',
                            'padding-left': '0px',
                            'height': '100%',
                        }}
                    >
                        <div class="btzMainPage btzMainPageLeft">
                            {state.logged && (
                                <LeftMenuView
                                    {...props}
                                    infos={state.infos}
                                    minifyLeftMenu={actions.minifyLeftMenu}
                                    minifiedLeftMenu={state.minifiedLeftMenu}
                                    customer={props.customer}
                                    doctor={state.doctor}
                                ></LeftMenuView>
                            )}
                        </div>
                        <div class="btzMainPage btzMainPageCenter">
                            <div class="btzMainPagePaddedMarker">
                                {children}
                            </div>
                            <BotFooterView
                                {...props}
                                customer={props.customerssoname}
                            ></BotFooterView>
                        </div>
                    </Grid.Col>
                </Grid.Row>
            </Grid>
        </Enter>
    </div>
)

export default Component(state, actions, view, 'layout')
