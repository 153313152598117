import { h } from 'hyperapp';
import { Component } from '@app/utils';
import './index.scss';

const SimpleChart = Component(
    {
        percent: 0,
        color: 'grey',
        inverted: '',
        rotateValue: '',
        unit: ''
    },
    {
        // rest = rest.match(/\d/g).join('');
        onComponentCreate: (props) => (state, actions) => {
            let origValue = props.value;
            let minValue = actions.parseToMinuteOnly(props.value);
            let values = props.values;
            let maxvalue = null;
            for (var val in values) {
                val = actions.parseToMinuteOnly(values[val]);
                if ((maxvalue === null) || (+maxvalue < +val)) {
                    maxvalue = +val;
                }
            }

            let rotateValue = 0;
            if (minValue) {
                if (+minValue > 0) {
                    actions.setPercent(minValue);
                    if (+maxvalue > 60) {
                        minValue = (minValue * 60) / maxvalue;
                    }
                    if (Math.round(minValue / 60) > 0.5) {
                        actions.setInverted('inverted');
                    }
                    rotateValue = 'rotate(' + minValue / 60 + 'turn)';
                }
            } else {
                actions.setOrigValue('Ø');
            }

            actions.setRotateValue(rotateValue);

            if (props.color) {
                state.color = props.color;
            }
            if (props.unit) {
                actions.setUnit(props.unit);
            }
            if (origValue) {
                if (origValue.indexOf('h') < 0) {
                    origValue = origValue.replace(/[ ]min/gi, '');
                    origValue += '‘';
                }
                actions.setOrigValue(origValue);
            }
        },
        setRotateValue: newState => state => ({
            rotateValue: newState,
        }),
        setUnit: newState => state => ({
            unit: newState,
        }),
        setPercent: newState => state => ({
            percent: newState,
        }),
        setOrigValue: newState => state => ({
            origValue: newState,
        }),
        setInverted: newState => state => ({
            inverted: newState,
        }),
        parseToMinuteOnly: (value) => (state, actions) => {
            if (value === undefined) {
                return 0;
            }
            let values = value.replace('min', '').split('h'), parsedValue = value.replace('min', '');
            if (values.length > 1) {
                parsedValue = (+values[0] * 60) + +values[1];
            }
            return parsedValue;
        }
    },
    (state, actions) => (props, children) => (
        <div class='btzSimpleChart' data-percent={state.percent}>
            <div class='btzSimpleChart-btzPieChart'>
                <div class='btzSimpleChart-btzBack'></div>
                <div class={state.inverted + ' btzSimpleChart-btzSlice'} style={{ 'transform': state.rotateValue }}></div>
                <span>{state.origValue}</span>
            </div>
        </div>
    ),
    'simplechart'
);

export { SimpleChart };
