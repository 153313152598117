import { t } from 'i18next'
import moment from 'moment'
import { User, Alert, Schedule, Telesuivi } from '@app/api'
import {
    enableTheseButton,
    desableTheseButton,
    deepCopy,
    isValidDateYMDObj,
    castToNumberOfFloat,
    getDiffDate,
} from '@app/core'
import {
    isDefined,
    getYMDfromDateObj,
    getDateObjFromYMD,
    createUTCDate,
    coreFn,
    guid,
} from '@app/core'

import * as constants from '@app/constants'

/**
 * @module PatientEditTelesuivi
 * @description Patient configuration telesuivi
 */

export default {
    initAction: function () {
        let PatientEditTelesuivi = {
            onComponentCreate: (props) => (state, actions) => {
                state.values = deepCopy(state.ovalues)
                state.patientid = props.patientid
                //
                actions.updateComponent(props)
                actions._setState({
                    key: 'updatePatient',
                    value: props.updatePatient,
                })
                actions._setState({
                    key: 'editedPatient',
                    value: props.editedPatient,
                })
            },
            updateComponent: (props) => (state, actions) => {
                let dataTypeSpecific = constants.custo.telesuiviSettings
                    .filter(
                        (f) =>
                            f.crypt === state.ICMesure &&
                            state.ICMesureIgnore.indexOf(f.crypt) === -1
                    )
                    .map((m) =>
                        m.alertValueKey.map(
                            (vKey) =>
                                m.apicrypt.toUpperCase() +
                                '_' +
                                String(vKey.crypt).toUpperCase()
                        )
                    )
                    .reduce((a, b) => a.concat(b))
                let dataTypeAll = constants.custo.telesuiviSettings
                    .filter(
                        (f) =>
                            f.crypt !== state.ICMesure &&
                            state.ICMesureIgnore.indexOf(f.crypt) === -1
                    )
                    .map((m) =>
                        m.alertValueKey.map(
                            (vKey) =>
                                m.apicrypt.toUpperCase() +
                                '_' +
                                String(vKey.crypt).toUpperCase()
                        )
                    )
                    .reduce((a, b) => a.concat(b))
                //
                if (isDefined(props) && isDefined(props.dynamicParam)) {
                    if (isDefined(props.dynamicParam.date)) {
                        actions._setState({
                            key: 'currentDate',
                            value: props.dynamicParam.date,
                        })
                    }
                    if (isDefined(props.dynamicParam.mesure)) {
                        actions._setState({
                            key: 'themeCurrent',
                            value: props.dynamicParam.mesure,
                        })
                        dataTypeSpecific[0] = props.dynamicParam.mesure
                    }
                    if (isDefined(props.dynamicParam.period)) {
                        actions._setState({
                            key: 'currentPeriod',
                            value: props.dynamicParam.period,
                        })
                    }
                }
                //
                if (state.isICModule) {
                    actions._setState({
                        key: 'editingMesure',
                        value: constants.custo.telesuiviSettings.filter(
                            (f) => f.crypt === state.ICMesure
                        )[0],
                    })
                    actions._setState({
                        key: 'dataType',
                        value: {
                            all: dataTypeSpecific,
                            specific: dataTypeSpecific,
                        },
                    })
                    actions.switchAlert('recent')
                } else {
                    state.dataType = {
                        all: dataTypeAll,
                        specific: dataTypeSpecific,
                    }
                    //
                    if (isDefined(props)) {
                        actions.updateUser(
                            isDefined(props) ? props.editedPatient : null
                        )
                        actions.switchTheme()
                    }
                }
            },
            /**
             * Get mesure mesures, then call [parseTableDatas]{@link module:PatientEditTelesuivi~PatientEditTelesuivi:parseTableDatas})
             * @function PatientEditTelesuivi:getMesures
             * @param {object} newAlerts - Array of alert objects (last retrieve from API)
             */
            getMesures: (newAlerts) => (state, actions) => {
                let mesureType = state.themeCurrent.split('_')[0]
                if (
                    state.specificDashApi.indexOf(mesureType.toLowerCase()) > -1
                ) {
                    Telesuivi.getDataDashboard(
                        state.patientid,
                        mesureType.toLowerCase() === 'observance'
                            ? 'treatment'
                            : mesureType.toLowerCase(),
                        state.currentDate,
                        state.currentPeriod
                    ).then((res) => {
                        let observanceDatas = res.data,
                            parsedObservanceDatas = []
                        // 'TREATMENT_GLOBAL_COMPLIANCE'
                        for (var dat in observanceDatas.interval) {
                            let trgDate = createUTCDate(
                                getDateObjFromYMD(dat).date,
                                true
                            )
                            if (
                                getDiffDate(
                                    trgDate,
                                    createUTCDate(null, true)
                                ) >= 0
                            ) {
                                let isToday =
                                    getDiffDate(
                                        trgDate,
                                        createUTCDate(null, true)
                                    ) === 0
                                if (!isToday) {
                                    trgDate = trgDate
                                        .set({
                                            hour: 0,
                                            minute: 0,
                                            second: 0,
                                            millisecond: 0,
                                        })
                                        .add(24, 'hours')
                                        .add(
                                            moment().utcOffset() / -60,
                                            'hours'
                                        )
                                        .add(-1, 'minutes')
                                }
                                observanceDatas.interval[dat][
                                    'TREATMENT_GLOBAL_COMPLIANCE'
                                ].total = Math.round(
                                    observanceDatas.interval[dat][
                                        'TREATMENT_GLOBAL_COMPLIANCE'
                                    ].total
                                )
                                //
                                parsedObservanceDatas.push({
                                    alert: {},
                                    alerts: [],
                                    closedAt: null,
                                    closedBy: null,
                                    dataType: 'OBSERVANCE_VALUE',
                                    value: observanceDatas.interval[dat][
                                        'TREATMENT_GLOBAL_COMPLIANCE'
                                    ].total,
                                    date: { date: trgDate.toDate().toString() },
                                })
                            }
                        }
                        state.chartMesures = observanceDatas
                        actions.parseTableDatas({
                            mesures: parsedObservanceDatas,
                            alerts: newAlerts,
                            objective: null,
                        })
                    })
                } else {
                    Telesuivi.getData(
                        state.patientid,
                        mesureType.toLowerCase() === 'observance'
                            ? 'treatment'
                            : mesureType.toLowerCase(),
                        state.currentDate,
                        state.currentPeriod
                    ).then((res) => {
                        let forced = false,
                            resMesure = res.data
                        if (
                            (mesureType.toLowerCase() === 'cryptedhydration' &&
                                state.widgetSetting['HYDRATION_WIDGET'] ===
                                    '1') ||
                            forced
                        ) {
                            Telesuivi.getData(
                                state.patientid,
                                'cryptedweight',
                                state.currentDate,
                                state.currentPeriod
                            ).then((weightres) => {
                                actions.parseTableDatas({
                                    mesures: resMesure,
                                    alerts: newAlerts,
                                    objective: weightres.data,
                                })
                            })
                        } else {
                            actions.parseTableDatas({
                                mesures: resMesure,
                                alerts: newAlerts,
                                objective: null,
                            })
                        }
                    })
                }
            },
            /**
             * Get mesure alerts, then call [agglomerateDataByAlertKind]{@link module:PatientEditTelesuivi~PatientEditTelesuivi:agglomerateDataByAlertKind}) and [getMesures]{@link module:PatientEditTelesuivi~PatientEditTelesuivi:getMesures})
             * @function PatientEditTelesuivi:getAlerts
             */
            getAlerts: () => (state, actions) => {
                if (state.menu === 'recent') {
                    Alert.getUserAlertRecent(state.patientid, state.page, {
                        dataType: state.dataTypeCurrent,
                        pastDays: state.pastDays,
                    }).then((alerts) => {
                        state.alerts = alerts.data
                        state.deactivationConfirm = false
                        actions.parseTableDatas({
                            mesures: null,
                            alerts: alerts.data,
                            objective: null,
                        })
                    })
                } else if (state.menu === 'all') {
                    Alert.getUserAlertAll(state.patientid, state.page, {
                        dataType: state.dataTypeCurrent,
                    }).then((alerts) => {
                        state.alerts = alerts.data
                        state.deactivationConfirm = false
                        actions.parseTableDatas({
                            mesures: null,
                            alerts: alerts.data,
                            objective: null,
                        })
                    })
                } else if (state.menu === 'specific') {
                    let dataType = state.themeCurrent.toLowerCase()
                    if (dataType === 'observance_value') {
                        state.deactivationConfirm = false
                        actions.getMesures(null)
                        return false
                    }
                    let nAlert = state.editingMesure.alertValueKey.length,
                        toAgglomerateDataFromKind = []
                    for (var aValue in state.editingMesure.alertValueKey) {
                        if (
                            state.editingMesure.alertValueKey[aValue].key ===
                            'mean'
                        ) {
                            nAlert--
                            continue
                        }
                        Alert.getUserAlertByDataType(
                            state.patientid,
                            dataType.split('_')[0] +
                                '_' +
                                state.editingMesure.alertValueKey[aValue].crypt,
                            state.currentDate,
                            state.currentPeriod
                        ).then((res) => {
                            nAlert--
                            toAgglomerateDataFromKind.push(res.data)
                            if (nAlert === 0) {
                                state.deactivationConfirm = false
                                actions.agglomerateDataByAlertKind(
                                    toAgglomerateDataFromKind
                                )
                            }
                        })
                    }
                }
            },
            /**
             * Agglomerate data following alert kind
             * @function PatientEditTelesuivi:agglomerateDataByAlertKind
             * @param {array} toAgglomerateDataFromKind - Array of alert objects
             */
            agglomerateDataByAlertKind:
                (toAgglomerateDataFromKind) => (state, actions) => {
                    let agglomeratedDataByKind = []
                    for (var aggKind in toAgglomerateDataFromKind) {
                        for (var agg in toAgglomerateDataFromKind[aggKind]) {
                            if (
                                isDefined(
                                    toAgglomerateDataFromKind[aggKind][agg]
                                )
                            ) {
                                agglomeratedDataByKind[+aggKind + +agg] =
                                    toAgglomerateDataFromKind[aggKind][agg]
                            }
                        }
                    }
                    //
                    state.alerts = agglomeratedDataByKind
                    actions.getMesures(agglomeratedDataByKind)
                },
            /**
             * Get patient if not defined, then call [parseModules]{@link module:PatientEditTelesuivi~PatientEditTelesuivi:parseModules})
             * @function PatientEditTelesuivi:updateUser
             * @param {object} patient - Patient object containing the modules to parse
             */
            updateUser: (patient) => (state, actions) => {
                if (isDefined(patient)) {
                    actions.parseModules(patient)
                } else {
                    User.getTheraflowUserById(state.patientid).then(
                        (editedPatient) => {
                            actions.parseModules(editedPatient.data)
                            state.updatePatient()
                        }
                    )
                }
            },
            /**
             * Parse patient module to retrive deactivated, monitored and activated mesure
             * @function PatientEditTelesuivi:parseModules
             * @param {object} patient - Patient object containing the modules to parse
             */
            parseModules: (patient) => (state, actions) => {
                if (!isDefined(patient)) {
                    patient = state.editedPatient
                }
                let modules = [],
                    hospital = null
                if (isDefined(patient.customer)) {
                    modules =
                        patient.customer.customerModules.monitoringSettings.filter(
                            (f) => f.status === true
                        )
                    hospital = patient.customer
                }
                actions._setState({ key: 'modules', value: modules })
                //
                let monitoringSettings = patient.monitoringSettings || []
                //
                let activatedMesure = []
                let deactivatedMesure = []
                let monitoredMesure = []
                let found = false,
                    monitored = false
                for (let mod in modules) {
                    found = false
                    monitored = false
                    for (let acmod in monitoringSettings) {
                        if (
                            monitoringSettings[acmod].type == modules[mod].name
                        ) {
                            found = true
                            if (monitoringSettings[acmod].watched) {
                                monitored = true
                            }
                        }
                    }
                    if (!found) {
                        deactivatedMesure.push(modules[mod])
                    } else {
                        if (monitored) {
                            monitoredMesure.push(modules[mod])
                        } else {
                            activatedMesure.push(modules[mod])
                        }
                    }
                }
                actions._setState({
                    key: 'mesures',
                    value: {
                        followed: monitoredMesure,
                        activated: activatedMesure,
                        deactivated: deactivatedMesure,
                    },
                })
                //
                let enableModules = constants.custo.telesuiviSettings.filter(
                    (f) => {
                        return (
                            ((isDefined(
                                modules.filter((mod) => mod.name === f.crypt)[0]
                            )
                                ? true
                                : false) &&
                                (isDefined(
                                    monitoredMesure.filter(
                                        (foll) => foll.name === f.crypt
                                    )[0]
                                )
                                    ? true
                                    : false)) ||
                            isDefined(
                                activatedMesure.filter(
                                    (act) => act.name === f.crypt
                                )[0]
                            )
                        )
                    }
                )
                actions._setState({
                    key: 'enableModules',
                    value: enableModules,
                })
                if (
                    !state.isICModule &&
                    enableModules.length > 0 &&
                    !isDefined(
                        enableModules.filter(
                            (f) => f.crypt === state.ICMesure
                        )[0]
                    )
                ) {
                    actions._setState({
                        key: 'ICMesure',
                        value: enableModules[0].crypt,
                    })
                    actions._setState({
                        key: 'ICMesureUnit',
                        value: enableModules[0].chartValueKey,
                    })
                }
                let widgetSetting = state.widgetSetting
                widgetSetting['HYDRATION_WIDGET'] = isDefined(
                    patient.settings.filter(
                        (f) => f.name === 'HYDRATION_WIDGET'
                    )[0]
                )
                    ? patient.settings.filter(
                          (f) => f.name === 'HYDRATION_WIDGET'
                      )[0].value
                    : '0'
                actions.setWidgetSettings(widgetSetting)
            },
            /**
             * Change the kind of alerts listing displayed
             * @function PatientEditTelesuivi:switchAlert
             * @param {string} kind - Target alert kind to display (all/recent/specific)
             */
            switchAlert: (kind) => (state, actions) => {
                let newDataType = []
                if (kind === 'all') {
                    newDataType = state.dataType.all.filter(
                        (f) => f.toLowerCase().indexOf('_mean') === -1
                    )
                    state.themeCurrent = ''
                } else if (kind === 'specific') {
                    newDataType = state.dataType.specific
                } else if (kind === 'recent') {
                    newDataType = state.dataType.all.filter(
                        (f) => f.toLowerCase().indexOf('_mean') === -1
                    )
                    state.themeCurrent = ''
                }
                state.menu = kind
                state.dataTypeCurrent = newDataType
                actions.getAlerts()
            },
            /**
             * Retrieve alerts and mesures then display mesure configuration modal
             * @function PatientEditTelesuivi:onEditConfiguration
             * @param {string} crypt - Target crypt mesure to configure
             */
            onEditConfiguration: (crypt) => (state, actions) => {
                actions.closeModal()
                actions.onDeactivateMesureCancel()
                actions.switchModalTab('recall')
                actions._setState({ key: 'loading', value: true })
                let apiCrypt = constants.custo.telesuiviSettings
                    .filter(
                        (f) =>
                            f.crypt === crypt &&
                            state.ICMesureIgnore.indexOf(crypt) === -1
                    )
                    .map((m) => m.apicrypt)
                let editingSchedule = null
                let callback = (schedule) => {
                    if (
                        isDefined(schedule) &&
                        isDefined(schedule.data) &&
                        isDefined(schedule.data.id)
                    ) {
                        editingSchedule = schedule.data
                    } else {
                        editingSchedule = {}
                        editingSchedule.measureType = crypt
                    }
                    //
                    let callbackalerts = (scheduleAlerts) => {
                        editingSchedule.alerts = scheduleAlerts || []
                        editingSchedule.status = true
                        //
                        actions.hydrateSchedule({
                            schedule: editingSchedule,
                            fromapi: true,
                        })
                        actions.setModalDisplayed(true)
                        actions._setState({
                            key: 'modalEditing',
                            value: 'configuration',
                        })
                        actions._setState({
                            key: 'editingMesure',
                            value: constants.custo.telesuiviSettings.filter(
                                (f) => f.crypt === crypt
                            )[0],
                        })
                        actions._setState({ key: 'loading', value: false })
                        actions._setState({
                            key: 'formSubmittedOnce',
                            value: false,
                        })
                    }
                    if (apiCrypt.length === 0) {
                        callbackalerts(null)
                    } else {
                        Alert.getAlert(state.patientid).then(
                            (scheduleAlerts) => {
                                if (
                                    isDefined(editingSchedule) &&
                                    isDefined(scheduleAlerts.data)
                                ) {
                                    scheduleAlerts = scheduleAlerts.data.filter(
                                        (f) =>
                                            f.dataType.indexOf(
                                                editingSchedule.measureType.toUpperCase()
                                            ) > -1
                                    )
                                } else {
                                    scheduleAlerts = []
                                }
                                callbackalerts(scheduleAlerts)
                            }
                        )
                    }
                }
                //
                if (apiCrypt.length === 0) {
                    callback(null)
                } else {
                    Schedule.getSchedule(state.patientid, apiCrypt).then(
                        (schedule) => {
                            callback(schedule)
                        }
                    )
                }
            },
            /**
             * Activate current configured mesure, then call [updateUser]{@link module:PatientEditTelesuivi~PatientEditTelesuivi:updateUser})
             * @function PatientEditTelesuivi:onActivateMesure
             * @param {string} crypt - Target crypt mesure to activate
             */
            onActivateMesure: (crypt) => (state, actions) => {
                let newMonitoring = deepCopy(state.mesures.activated)
                    .map((m) => m.name)
                    .concat(deepCopy(state.mesures.followed).map((m) => m.name))
                newMonitoring.push(crypt)
                actions.closeModal()
                User.updateMonitoring(state.patientid, {
                    monitoringSettings: newMonitoring,
                }).then(() => {
                    actions.updateUser(null)
                })
            },
            /**
             * Cancel deactivatation of current configured mesure
             * @function PatientEditTelesuivi:onDeactivateMesureCancel
             */
            onDeactivateMesureCancel: () => (state, actions) => {
                actions._setState({ key: 'deactivationConfirm', value: false })
                actions._setState({ key: 'deactivationConfirmKind', value: '' })
            },
            /**
             * Deactivate current configured mesure, then call [updateUser]{@link module:PatientEditTelesuivi~PatientEditTelesuivi:updateUser})
             * @function PatientEditTelesuivi:onDeactivateMesure
             */
            onDeactivateMesure: (kind) => (state, actions) => {
                if (!state.deactivationConfirm) {
                    actions._setState({
                        key: 'deactivationConfirm',
                        value: true,
                    })
                    actions._setState({
                        key: 'deactivationConfirmKind',
                        value: kind,
                    })
                } else {
                    if (kind === 'deactivate') {
                        // let newMonitoring = deepCopy(state.mesures.activated).map((m) => m.name).concat(deepCopy(state.mesures.followed).map((m) => m.name));
                        // let toRemoveMonitoring = state.editingMesure.crypt;
                        // newMonitoring.splice(newMonitoring.indexOf(toRemoveMonitoring), 1);
                        // User.updateMonitoring(state.patientid, {monitoringSettings: newMonitoring}).then((patient) => {
                        //     actions.setDeactivationConfirm(false);
                        //     actions.updateUser(patient.data);
                        // });
                    } else if (kind === 'recallwithalerts') {
                        Schedule.deleteScheduleWithAlerts(
                            state.patientid,
                            state.values.id
                        ).then(() => {
                            actions.closeModal()
                            actions.updateUser(null)
                        })
                    }
                }
            },
            /**
             * Delete the recall of current configured mesure, then call [onCancelConfiguration]{@link module:PatientEditTelesuivi~PatientEditTelesuivi:onCancelConfiguration})
             * @function PatientEditTelesuivi:onDeleteRecall
             */
            onDeleteRecall: () => (state, actions) => {
                let resetedSchedule = deepCopy(state.ovalues)
                resetedSchedule.alerts = state.values.alerts.filter(
                    (f) => f.key !== 0
                )
                resetedSchedule.id = state.values.id
                resetedSchedule.toDelete = true
                actions._setState({
                    key: 'scheduleCurrent',
                    value: resetedSchedule,
                })
                actions.onCancelConfiguration()
            },
            /**
             * Increments current page, then call [getAlerts]{@link module:PatientEditTelesuivi~PatientEditTelesuivi:getAlerts})
             * @function PatientEditTelesuivi:nextPage
             */
            nextPage: () => (state, actions) => {
                actions._setState({ key: 'page', value: state.page + 1 })
                actions.getAlerts()
            },
            /**
             * Increments current page, then call [getAlerts]{@link module:PatientEditTelesuivi~PatientEditTelesuivi:getAlerts})
             * @function PatientEditTelesuivi:prevPage
             */
            prevPage: () => (state, actions) => {
                actions._setState({ key: 'page', value: state.page - 1 })
                actions.getAlerts()
            },
            /**
             * Change current displayed mesure, then call [switchAlert]{@link module:PatientEditTelesuivi~PatientEditTelesuivi:switchAlert})
             * @function PatientEditTelesuivi:switchTheme
             * @param {string} crypt - Target crypt mesure to display
             */
            switchTheme:
                (crypt = null) =>
                (state, actions) => {
                    if (!isDefined(crypt) || crypt === '') {
                        crypt = state.ICMesure + '_' + state.ICMesureUnit
                    }
                    if (state.enableModules.length === 0) {
                        actions.switchAlert('recent')
                    } else {
                        crypt = crypt.toUpperCase()
                        state.themeCurrent = crypt
                        state.editingMesure =
                            constants.custo.telesuiviSettings.filter(
                                (f) =>
                                    f.alertValueKey.filter(
                                        (vKey) =>
                                            f.crypt.toUpperCase() +
                                                '_' +
                                                String(
                                                    vKey.key
                                                ).toUpperCase() ===
                                            crypt
                                    )[0]
                            )[0]
                        actions.switchAlert('specific')
                    }
                },
            /**
             * Change current displayed modal tab, then call [switchAlert]{@link module:PatientEditTelesuivi~PatientEditTelesuivi:switchAlert})
             * @function PatientEditTelesuivi:switchModalTab
             * @param {string} page - Target modal tab to display
             */
            switchModalTab: (newState) => (state) => ({
                modalTab: newState,
            }),
            /**
             * Eneable or desable current telesuivi edition
             * @function PatientEditTelesuivi:onEditCurrentTelesuivi
             * @param {boolean} newState - /
             */
            onEditCurrentTelesuivi: (newState) => (state) => ({
                editCurrentTelesuivi: newState,
                formSubmittedOnce: false,
            }),
            /**
             * Parse mesures and alerts data to fit table display
             * @function PatientEditTelesuivi:parseTableDatas
             * @param {array} mesures - Array of mesure objects
             * @param {array} alerts - Array of alert objects
             */
            parseTableDatas:
                ({ mesures, alerts, objective }) =>
                (state, actions) => {
                    let mesureType = state.themeCurrent.split('_')[0]
                    if (isDefined(alerts) && isDefined(mesures)) {
                        for (var lrt in alerts) {
                            var alert = alerts[lrt]
                            var foundNODATA = false
                            for (var day in mesures) {
                                var dataDay = mesures[day]
                                var dataDate = dataDay.date
                                if (
                                    isDefined(dataDate) &&
                                    isDefined(dataDate.date)
                                ) {
                                    dataDate = dataDate.date
                                        .split(' ')[0]
                                        .replace(/-/g, '')
                                }
                                var alertDate = alert.date
                                if (isDefined(alertDate)) {
                                    alertDate = alertDate.date
                                    if (isDefined(alertDate)) {
                                        alertDate = alertDate
                                            .split(' ')[0]
                                            .replace(/-/g, '')
                                        if (
                                            dataDate === alertDate &&
                                            alert.triggerType === 'NODATA' &&
                                            !foundNODATA
                                        ) {
                                            if (!isDefined(dataDay.alerts)) {
                                                dataDay.alerts = []
                                            }
                                            alert.value = null
                                            dataDay.alerts.push(alert)
                                            foundNODATA = true
                                        }
                                    }
                                }
                            }
                            if (!foundNODATA) {
                                if (mesures.length > 0) {
                                    for (let mesure in mesures) {
                                        if (
                                            isDefined(mesures[mesure].alerts) &&
                                            isDefined(
                                                mesures[mesure].alerts.filter(
                                                    (f) => f.id === alert.id
                                                )[0]
                                            )
                                        ) {
                                            mesures[mesure] = alert
                                            break
                                        } else {
                                            mesures.push(alert)
                                        }
                                    }
                                } else {
                                    mesures.push(alert)
                                }
                            } else {
                                mesures.push(alert)
                            }
                        }
                    }
                    //
                    var parsedDatavisData = [],
                        dataFound = false
                    if (isDefined(mesures)) {
                        for (day in mesures) {
                            dataFound = false
                            //
                            if (
                                isDefined(mesures[day].alerts) &&
                                mesures[day].alerts.length > 0
                            ) {
                                for (lrt in mesures[day].alerts) {
                                    // mesures[day].alerts[lrt].id = mesures[day].alerts[lrt].id;
                                    // mesures[day].alerts[lrt].status = mesures[day].alerts[lrt].status;
                                    if (
                                        isDefined(
                                            mesures[day].alerts[lrt].alert
                                        )
                                    ) {
                                        mesures[day].alerts[lrt].value1 =
                                            mesures[day].alerts[
                                                lrt
                                            ].alert.value1
                                        mesures[day].alerts[lrt].interval =
                                            mesures[day].alerts[
                                                lrt
                                            ].alert.interval
                                    }
                                    if (
                                        !isDefined(
                                            parsedDatavisData.filter(
                                                (f) =>
                                                    f.id ===
                                                    mesures[day].alerts[lrt].id
                                            )[0]
                                        )
                                    ) {
                                        parsedDatavisData.push(
                                            mesures[day].alerts[lrt]
                                        )
                                        // dataFound = true;
                                    }
                                }
                            }
                            //
                            var copiedDatavizData = null,
                                copiedDatavizDataObjective = null
                            if (isDefined(objective)) {
                                let mesureDate = mesures[day].date.date
                                for (var obj in objective) {
                                    let objDate = objective[obj].date.date
                                    if (
                                        getYMDfromDateObj(
                                            createUTCDate(objDate),
                                            true,
                                            true
                                        ) ===
                                        getYMDfromDateObj(
                                            createUTCDate(mesureDate),
                                            true,
                                            true
                                        )
                                    ) {
                                        copiedDatavizDataObjective =
                                            (35 * objective[obj].value) / 1000 // 35ml x kg = ml / 1000 = litre
                                    }
                                }
                            }
                            //
                            if (
                                isDefined(mesures[day].value) &&
                                mesures[day].value !== ''
                            ) {
                                copiedDatavizData = mesures[day]
                                copiedDatavizData.triggerType = ''
                                copiedDatavizData.alerts = []
                                if (isDefined(copiedDatavizDataObjective)) {
                                    copiedDatavizData.objective =
                                        copiedDatavizDataObjective
                                }
                                if (
                                    mesureType.toLowerCase() ===
                                    'cryptedhydration'
                                ) {
                                    mesures[day].value =
                                        mesures[day].value / 100
                                }
                                if (
                                    !isDefined(
                                        parsedDatavisData.filter(
                                            (f) => f.id === copiedDatavizData.id
                                        )[0]
                                    ) ||
                                    mesureType.toLowerCase() === 'observance'
                                ) {
                                    parsedDatavisData.push(copiedDatavizData)
                                    dataFound = true
                                }
                            }
                            //
                            if (!dataFound) {
                                copiedDatavizData = mesures[day]
                                if (isDefined(copiedDatavizDataObjective)) {
                                    copiedDatavizData.objective =
                                        copiedDatavizDataObjective
                                }
                                if (
                                    !isDefined(
                                        parsedDatavisData.filter(
                                            (f) => f.id === copiedDatavizData.id
                                        )[0]
                                    )
                                ) {
                                    parsedDatavisData.push(copiedDatavizData)
                                }
                            }
                        }
                    } else {
                        parsedDatavisData = alerts
                    }
                    // fixe for 00:00 alert triggered
                    for (var parsed in parsedDatavisData) {
                        if (isDefined(parsedDatavisData[parsed])) {
                            if (
                                !isDefined(parsedDatavisData[parsed].dataType)
                            ) {
                                parsedDatavisData[parsed].dataType =
                                    state.themeCurrent
                            }
                            parsedDatavisData[parsed].readableDataType =
                                constants.custo.telesuiviSettings.filter(
                                    (f) =>
                                        f.crypt.toLowerCase() ===
                                        parsedDatavisData[parsed].dataType
                                            .split('_')[0]
                                            .toLowerCase()
                                )
                            if (
                                isDefined(
                                    parsedDatavisData[parsed]
                                        .readableDataType[0]
                                )
                            ) {
                                parsedDatavisData[parsed].readableDataType =
                                    parsedDatavisData[
                                        parsed
                                    ].readableDataType[0].value
                            }
                            if (parsedDatavisData[parsed].date) {
                                if (parsedDatavisData[parsed].date.date) {
                                    if (
                                        parsedDatavisData[
                                            parsed
                                        ].date.date.indexOf('22:00') > -1
                                    ) {
                                        parsedDatavisData[parsed].date.date =
                                            parsedDatavisData[
                                                parsed
                                            ].date.date.replace(
                                                '22:00',
                                                '21:59'
                                            )
                                    }
                                }
                            }
                        }
                    }
                    if (isDefined(alerts)) {
                        state.alerts = alerts
                    }
                    parsedDatavisData.sort((a, b) => {
                        return new Date(a.date.date) - new Date(b.date.date)
                    })
                    // if ((isDefined(mesures)) && (mesureType.toLowerCase() !== 'observance')) {
                    if (mesureType.toLowerCase() !== 'observance') {
                        state.chartMesures = parsedDatavisData
                    }
                    actions._setState({
                        key: 'tableDatas',
                        value: parsedDatavisData,
                    })
                    actions._setState({
                        key: 'tableDatasReversed',
                        value: parsedDatavisData.reverse(),
                    })
                },
            /**
             * Activate or deactivate a schedule alert
             * @function PatientEditTelesuivi:onActivateAlerting
             * @param {string} alert - The kind of alert to activate/deactivate (NODATA, INCREASE, ...)
             */
            onActivateAlerting: (alert) => (state, actions) => {
                let alertObj = constants.custo.telesuiviScenarios.filter(
                    (f) => f.key === alert
                )[0]
                let hydratedValues = state.values
                if (isDefined(alertObj) && isDefined(hydratedValues)) {
                    if (
                        isDefined(
                            hydratedValues.alerts.filter(
                                (f) => f.key === alertObj.key
                            )[0]
                        )
                    ) {
                        hydratedValues.alerts = hydratedValues.alerts.filter(
                            (f) => f.key !== alertObj.key
                        )
                    } else {
                        alertObj.scenario.dataType =
                            state.editingMesure.crypt.toUpperCase() +
                            '_' +
                            state.editingMesure.alertValueKey[0].crypt.toUpperCase()
                        hydratedValues.alerts.push(alertObj)
                    }
                }
                actions._setState({ key: 'values', value: hydratedValues })
            },
            /**
             * Submit schedule configuration form or delete it
             * @function PatientEditTelesuivi:onSubmit
             */
            onSubmit: () => (state, actions) => {
                if (state.values.toDelete) {
                    if (isDefined(state.scheduleCurrent.id)) {
                        Schedule.deleteSchedule(
                            state.patientid,
                            state.scheduleCurrent.id
                        ).then(() => {
                            actions.closeModal()
                            actions.updateUser(null)
                        })
                    } else {
                        actions.closeModal()
                    }
                    //
                } else {
                    let parsedData = {
                        measureType: state.editingMesure.crypt,
                        start: getDateObjFromYMD(
                            state.values.start,
                            null,
                            true
                        ),
                    }
                    if (
                        !isDefined(state.values.frequencies) ||
                        state.values.frequencies === ''
                    ) {
                        actions.onSubmitAlert(state.values.alerts)
                        return false
                    }
                    //
                    if (+state.values.frequencies === 0) {
                        parsedData.days = state.values.days
                        parsedData.dailySchedule = state.values.dailySchedule
                    } else if (+state.values.frequencies === 1) {
                        parsedData.interval =
                            'P' +
                            state.values.intervalday +
                            state.values.interval
                        parsedData.dailySchedule = state.values.dailySchedule
                    } else if (+state.values.frequencies === 2) {
                        parsedData.before = getDateObjFromYMD(
                            state.values.before,
                            null,
                            true
                        )
                    } else if (+state.values.frequencies === 3) {
                        parsedData.days = state.values.days
                        parsedData.dailySchedule = state.values.dailySchedule
                    }
                    if (+state.values.frequencies !== 2) {
                        if (
                            isDefined(state.values.end) &&
                            isValidDateYMDObj(state.values.end)
                        ) {
                            parsedData.end = getDateObjFromYMD(
                                state.values.end,
                                'now',
                                true
                            )
                        }
                    }
                    if (isDefined(parsedData.dailySchedule)) {
                        var tempDSchedule = []
                        for (var dsch in parsedData.dailySchedule) {
                            tempDSchedule.push({
                                push: true,
                                startHour:
                                    parsedData.dailySchedule[dsch].split(
                                        '-'
                                    )[0],
                                endHour:
                                    parsedData.dailySchedule[dsch].split(
                                        '-'
                                    )[1],
                            })
                        }
                        parsedData.dailySchedule = tempDSchedule
                    }
                    //
                    if (
                        !isDefined(parsedData.before) &&
                        !isDefined(parsedData.interval) &&
                        (!isDefined(parsedData.cycle) ||
                            parsedData.cycle.length === 0) &&
                        (!isDefined(parsedData.days) ||
                            parsedData.days.length === 0)
                    ) {
                        alert(
                            '! [interval] / [cycle] / [days] / [before], none of these are configured ! critical error !'
                        )
                        return false
                    } else if (
                        (!isDefined(parsedData.dailySchedule) ||
                            parsedData.dailySchedule.length === 0) &&
                        !isDefined(parsedData.before)
                    ) {
                        alert(
                            '! [dailySchedule] are not configured ! critical error !'
                        )
                        return false
                    }
                    //
                    if (
                        state.editingMesure.crypt.toLowerCase() ===
                            'observance' ||
                        !state.values.status
                    ) {
                        actions.onSubmitAlert(state.values.alerts)
                    } else {
                        if (isDefined(state.scheduleCurrent.id)) {
                            Schedule.updateSchedule(
                                state.patientid,
                                state.scheduleCurrent.id,
                                parsedData
                            ).then(() => {
                                actions.onSubmitAlert(state.values.alerts)
                            })
                        } else {
                            Schedule.createSchedule(
                                state.patientid,
                                parsedData
                            ).then(() => {
                                actions.onSubmitAlert(state.values.alerts)
                            })
                        }
                    }
                }
            },
            /**
             * Submit schedule configuration form or delete it
             * @function PatientEditTelesuivi:onSubmitWidget
             */
            onSubmitWidget: () => (state, actions) => {
                User.updateTheraflowUserSettings(state.patientid, [
                    {
                        name: 'HYDRATION_WIDGET',
                        value: state.widgetSetting['HYDRATION_WIDGET'],
                    },
                ]).then(() => {
                    actions.closeModal()
                    state.updatePatient()
                })
            },
            /**
             * Create or delete activated alerts scenario, then call [updateMonitoring]{@link module:PatientEditTelesuivi~PatientEditTelesuivi:updateMonitoring})
             * @function PatientEditTelesuivi:onSubmitAlert
             * @param {array} alerts - Array of alert objects
             */
            onSubmitAlert: (alerts) => (state, actions) => {
                let existingAlerts = state.scheduleCurrent.alerts
                let toRemoveAlert = []
                let newAlerts = []
                for (var existingAlert in existingAlerts) {
                    if (
                        !isDefined(
                            alerts.filter(
                                (f) =>
                                    f.key === existingAlerts[existingAlert].key
                            )[0]
                        )
                    ) {
                        toRemoveAlert.push(existingAlerts[existingAlert])
                    }
                }
                for (var newAlert in alerts) {
                    if (
                        !isDefined(
                            existingAlerts.filter(
                                (f) => f.key === alerts[newAlert].key
                            )[0]
                        )
                    ) {
                        newAlerts.push(alerts[newAlert])
                    }
                }
                //
                let nAlert = toRemoveAlert.length + newAlerts.length
                if (nAlert === 0) {
                    actions.updateMonitoring()
                    actions.closeModal()
                    actions.updateUser()
                    return false
                }
                //
                let toSendAlert = null
                for (toSendAlert in newAlerts) {
                    let newScenario = newAlerts[toSendAlert].scenario
                    delete newScenario.key
                    delete newScenario.id
                    //
                    Alert.createAlert(state.patientid, newScenario).then(() => {
                        nAlert--
                        if (nAlert === 0) {
                            actions.updateMonitoring()
                            actions.closeModal()
                            actions.updateUser()
                        }
                    })
                }
                for (toSendAlert in toRemoveAlert) {
                    Alert.deleteAlert(toRemoveAlert[toSendAlert].id).then(
                        () => {
                            nAlert--
                            if (nAlert === 0) {
                                actions.updateMonitoring()
                                actions.closeModal()
                                actions.updateUser()
                            }
                        }
                    )
                }
            },
            /**
             * Update monitored mesure status (activated/deactivated), then call [updateUser]{@link module:PatientEditTelesuivi~PatientEditTelesuivi:updateUser})
             * @function PatientEditTelesuivi:updateMonitoring
             */
            updateMonitoring: () => (state, actions) => {
                let scheduleStatus = state.values.status,
                    scheduleStatusChanged = false
                let newMonitoring = deepCopy(state.mesures.activated)
                    .map((m) => m.name)
                    .concat(deepCopy(state.mesures.followed).map((m) => m.name))
                if (
                    !scheduleStatus &&
                    newMonitoring.indexOf(state.editingMesure.crypt) > -1
                ) {
                    newMonitoring.splice(
                        newMonitoring.indexOf(state.editingMesure.crypt),
                        1
                    )
                    scheduleStatusChanged = true
                } else if (
                    scheduleStatus &&
                    newMonitoring.indexOf(state.editingMesure.crypt) === -1
                ) {
                    newMonitoring.push(state.editingMesure.crypt)
                    scheduleStatusChanged = true
                }
                if (scheduleStatusChanged) {
                    User.updateMonitoring(state.patientid, {
                        monitoringSettings: newMonitoring,
                    }).then(() => {
                        actions.updateUser(null)
                    })
                } else {
                    actions.updateUser(null)
                }
                actions._setState({ key: 'deactivationConfirm', value: false })
            },
            /**
             * Confirm mesure configuration, then call [hydrateSchedule]{@link module:PatientEditTelesuivi~PatientEditTelesuivi:hydrateSchedule}) with new schedule values
             * @function PatientEditTelesuivi:closeModal
             */
            onSaveConfiguration: () => (state, actions) => {
                let valuesValidity = actions.checkValuesValidity()
                if (valuesValidity) {
                    actions.hydrateSchedule({
                        schedule: state.values,
                        fromapi: false,
                    })
                    actions.onEditCurrentTelesuivi(false)
                }
            },
            /**
             * Cancel mesure configuration, reset fields and rollback schedule values by calling [hydrateSchedule]{@link module:PatientEditTelesuivi~PatientEditTelesuivi:hydrateSchedule}) with previous existing schedule values
             * @function PatientEditTelesuivi:closeModal
             */
            onCancelConfiguration: () => (state, actions) => {
                if (!isDefined(state.scheduleCurrent.id)) {
                    state.scheduleCurrent.exist = false
                    state.scheduleCurrent.frequencies = null
                }
                actions._setValidity({ frequencies: false })
                actions.hydrateSchedule({
                    schedule: state.scheduleCurrent,
                    fromapi: false,
                })
                actions.onEditCurrentTelesuivi(false)
            },
            /**
             * Close configuration modal and reset fields
             * @function PatientEditTelesuivi:closeModal
             */
            closeModal: () => (state, actions) => {
                actions._setValidity({ frequencies: false })
                actions.onEditCurrentTelesuivi(false)
                actions._setState({ key: 'updateStatus', value: false })
                actions._setState({ key: 'scheduleCurrent', value: null })
                actions.setModalDisplayed(false)
                actions._setState({ key: 'modalEditing', value: '' })
            },
            /**
             * Retrieve readable phrase from values
             * @function PatientEditTelesuivi:getReadableRecap
             * @param {object} el - Dom element to modify
             * @param {object} schedule - Schedule object containing values
             */
            getReadableRecap:
                ({ el, schedule }) =>
                (state, actions) => {
                    let sentence = t(state.editingMesure.sentence)
                    //
                    let days = schedule.days
                    if (schedule.frequencies === '0') {
                        days = t('tous les jours')
                    } else if (schedule.frequencies === '1') {
                        days =
                            t('tous les') +
                            ' ' +
                            schedule.intervalday +
                            ' ' +
                            constants.custo.periodsFrequencies.filter(
                                (f) => f.key === schedule.interval
                            )[0].label
                    } else if (schedule.frequencies !== '2') {
                        let parsedFrequencies = t('le') + ' '
                        let loopidx = days.length
                        for (var cDay in constants.custo.daysFrequencies) {
                            for (var fDay in days) {
                                if (
                                    +constants.custo.daysFrequencies[cDay]
                                        .key === +days[fDay]
                                ) {
                                    parsedFrequencies +=
                                        constants.custo.daysFrequencies[cDay]
                                            .label
                                    if (loopidx === 1) {
                                        parsedFrequencies += ''
                                    } else if (loopidx === 2) {
                                        parsedFrequencies += ' ' + t('et') + ' '
                                    } else {
                                        parsedFrequencies += ', '
                                    }
                                    loopidx--
                                }
                            }
                        }
                        days = parsedFrequencies
                    }
                    //
                    let frequency = ''
                    if (days !== '' && days.length > 0) {
                        frequency =
                            "<b class='ovr-base-colored'>" +
                            t(days) +
                            '' +
                            '</b>'
                    } else {
                        sentence += '.'
                    }
                    //
                    let dailySchedules = schedule.dailySchedule,
                        parsedDailySchedules = '',
                        loopidx = dailySchedules.length
                    for (var dailySchedule in dailySchedules) {
                        for (var takePeriod in constants.custo.takePeriods) {
                            if (
                                String(
                                    constants.custo.takePeriods[takePeriod].key
                                ) ===
                                    String(
                                        dailySchedules[dailySchedule].startHour
                                    ) +
                                        '-' +
                                        String(
                                            dailySchedules[dailySchedule]
                                                .endHour
                                        ) ||
                                String(
                                    constants.custo.takePeriods[takePeriod].key
                                ) === String(dailySchedules[dailySchedule])
                            ) {
                                parsedDailySchedules +=
                                    t(
                                        constants.custo.takePeriods[takePeriod]
                                            .prefix
                                    ) +
                                    "<b class='ovr-base-colored'>" +
                                    t(
                                        constants.custo.takePeriods[
                                            takePeriod
                                        ].label.split(' ')[0]
                                    ) +
                                    '</b>'
                                if (loopidx === 1) {
                                    parsedDailySchedules += ''
                                } else if (loopidx === 2) {
                                    parsedDailySchedules += ' ' + t('et') + ' '
                                } else {
                                    parsedDailySchedules += ', '
                                }
                                loopidx--
                            }
                        }
                    }
                    if (parsedDailySchedules !== '') {
                        parsedDailySchedules += '.'
                    }
                    //
                    let dates = '',
                        startDate = schedule.start,
                        endDate = schedule.end,
                        beforeDate = schedule.before
                    if (
                        isDefined(beforeDate) &&
                        isValidDateYMDObj(beforeDate)
                    ) {
                        dates =
                            t('Avant le') +
                            ' ' +
                            "<b class='ovr-base-colored'>" +
                            beforeDate.day +
                            '/' +
                            beforeDate.month +
                            '/' +
                            beforeDate.year +
                            '</b>'
                    } else {
                        if (
                            isDefined(startDate) &&
                            isValidDateYMDObj(startDate)
                        ) {
                            dates =
                                t('Du') +
                                ' ' +
                                "<b class='ovr-base-colored'>" +
                                startDate.day +
                                '/' +
                                startDate.month +
                                '/' +
                                startDate.year +
                                '</b>'
                        }
                        if (isDefined(endDate) && isValidDateYMDObj(endDate)) {
                            dates +=
                                ' ' +
                                t('au') +
                                ' ' +
                                "<b class='ovr-base-colored'>" +
                                endDate.day +
                                '/' +
                                endDate.month +
                                '/' +
                                endDate.year +
                                '</b>'
                        } else {
                            dates +=
                                ' ' +
                                t("jusqu'à") +
                                ' ' +
                                "<b class='ovr-base-colored'>" +
                                t('illimité') +
                                '</b>'
                        }
                    }

                    el.innerHTML =
                        sentence +
                        ' ' +
                        frequency +
                        ' ' +
                        parsedDailySchedules +
                        ' ' +
                        dates +
                        '.'
                },
            /**
             * Retrieve form input on the fly
             * @function PatientEditTelesuivi:retrieveInput
             * @param {input} input - Contain [isvalid], [name], [id] and [values] fields
             */
            retrieveInput: (input) => (state, actions) => {
                const { isvalid, name, values } = input
                if (name === 'frequencies') {
                    let parsedValues = state.values
                    if (values === '0') {
                        parsedValues.dailySchedule = state.dvalues.dailySchedule
                        parsedValues.before = null
                    } else if (values === '1') {
                        parsedValues.interval = state.dvalues.interval
                        parsedValues.intervalday = state.dvalues.intervalday
                        parsedValues.before = null
                    } else if (values === '2') {
                        //
                    } else if (values === '3') {
                        parsedValues.days = state.dvalues.ddays
                        parsedValues.dailySchedule = state.dvalues.dailySchedule
                        parsedValues.before = null
                    } else {
                        parsedValues.days = state.dvalues.days
                        parsedValues.frequencies = state.dvalues.frequencies
                        parsedValues.dailySchedule = state.dvalues.dailySchedule
                        parsedValues.before = null
                    }
                    actions._setState({ key: 'values', value: parsedValues })
                }
                if (isvalid) {
                    actions._setErrorMessages({ [name]: '' })
                }
                if (name === 'status') {
                    actions._setState({
                        key: 'updateStatus',
                        value: !state.updateStatus,
                    })
                }
                //
                actions._setValues({ [name]: values })
                actions._setValidity({ [name]: isvalid })
                actions._setTyping({ [name]: true })
            },
            /**
             * Retrieve form widget activation input on the fly
             * @function PatientEditTelesuivi:retrieveInputWidget
             * @param {input} input - Contain [isvalid], [name], [id] and [values] fields
             */
            retrieveInputWidget: (input) => (state, actions) => {
                const { values } = input
                let widgetSetting = state.widgetSetting
                widgetSetting['HYDRATION_WIDGET'] = values
                actions.setWidgetSettings(widgetSetting)
            },
            setWidgetSettings: (newState) => (state) => ({
                widgetSetting: newState,
            }),
            /**
             * Retrieve error directly from input or when submitting form
             * @function PatientEditTelesuivi:retrieveErrorMessage
             */
            retrieveErrorMessage: (target) => (state, actions) => {
                if (isDefined(target)) {
                    target = target.target.getAttribute('name')
                }
                actions._setState({ key: 'formSubmittedOnce', value: true })
                //
                let values = state.values
                let isvalid = state.isValid
                let valuesValid = true,
                    name = '',
                    message = ''
                if (!isvalid.frequencies) {
                    name = 'frequencies'
                    message = t('veuillez sélectionner une fréquence de prise')
                    valuesValid = false
                } else {
                    if (values.frequencies === '0') {
                        if (!isvalid.dailySchedule) {
                            name = 'dailySchedule'
                            message = t(
                                'veuillez sélectionner les moments de prise'
                            )
                            valuesValid = false
                        }
                        if (!isvalid.start) {
                            name = 'start'
                            message = t(
                                'veuillez renseigner une date de début valide'
                            )
                            valuesValid = false
                        }
                        if (!isvalid.end) {
                            name = 'end'
                            message = t(
                                'veuillez renseigner une date de fin valide'
                            )
                            valuesValid = false
                        }
                    } else if (values.frequencies === '1') {
                        if (!isvalid.interval) {
                            name = 'interval'
                            message = t(
                                'veuillez sélectionner la periode de prise'
                            )
                            valuesValid = false
                        }
                        if (!isvalid.intervalday) {
                            name = 'intervalday'
                            message = t(
                                "veuillez renseigner l'interval de prise"
                            )
                            valuesValid = false
                        }
                        if (!isvalid.start) {
                            name = 'start'
                            message = t(
                                'veuillez renseigner une date de début valide'
                            )
                            valuesValid = false
                        }
                        if (!isvalid.end) {
                            name = 'end'
                            message = t(
                                'veuillez renseigner une date de fin valide'
                            )
                            valuesValid = false
                        }
                    } else if (values.frequencies === '2') {
                        if (!isvalid.before) {
                            name = 'before'
                            message = t('veuillez renseigner la date limite')
                            valuesValid = false
                        }
                    } else if (values.frequencies === '3') {
                        if (!isvalid.days) {
                            name = 'days'
                            message = t(
                                'veuillez sélectionner les jours de prise'
                            )
                            valuesValid = false
                        }
                        if (!isvalid.dailySchedule) {
                            name = 'dailySchedule'
                            message = t(
                                'veuillez sélectionner les moments de prise'
                            )
                            valuesValid = false
                        }
                        if (!isvalid.start) {
                            name = 'start'
                            message = t(
                                'veuillez renseigner une date de début valide'
                            )
                            valuesValid = false
                        }
                        if (!isvalid.end) {
                            name = 'end'
                            message = t(
                                'veuillez renseigner une date de fin valide'
                            )
                            valuesValid = false
                        }
                    }
                }
                if (!valuesValid) {
                    actions._setErrorMessages({ [name]: message })
                    actions._setTyping({ [name]: false })
                    actions._setValidity({ [name]: false })
                }
                return valuesValid
            },
            /**
             * Retrieve form <action> dropdown contained in table alerts listing, send the API update call then call [getAlerts]{@link module:PatientEditTelesuivi~PatientEditTelesuivi:getAlerts})
             * @function PatientEditTelesuivi:retrieveInputAction
             * @param {input} input - Contain [isvalid], [name], [id] and [values] fields
             */
            retrieveInputAction: (input) => (state, actions) => {
                const { values, id } = input
                Alert.updateUserAlert(state.patientid, id, {
                    status: values,
                }).then(() => {
                    actions.getAlerts()
                })
            },
            /**
             * Parse and hydrate state from schedule object
             * @function PatientEditTelesuivi:hydrateSchedule
             * @param {object} schedule - Schedule object containing all values
             * @param {boolean} fromapi - Particular treatment if the call after an API call
             */
            hydrateSchedule:
                ({ schedule, fromapi }) =>
                (state, actions) => {
                    let values = deepCopy(state.ovalues)
                    values.end = null
                    values.before = null
                    //
                    if (
                        !isDefined(schedule) ||
                        (!isDefined(schedule.frequencies) &&
                            !isDefined(schedule.id))
                    ) {
                        values.days = state.dvalues.days
                        values.frequencies = state.dvalues.frequencies
                        values.measureType = state.editingMesure.crypt
                    } else {
                        values.exist = true
                        values.measureType = state.editingMesure.crypt
                        if (isDefined(schedule.id)) {
                            values.id = schedule.id
                        }
                        if (isDefined(schedule.toDelete)) {
                            values.toDelete = schedule.toDelete
                        }
                        //
                        values.frequencies = '0'
                        values.days = schedule.days
                        if (isDefined(schedule.days)) {
                            values.days = schedule.days
                            if (schedule.days.length < 7) {
                                values.frequencies = '3'
                            }
                        }
                        if (isDefined(schedule.cycle)) {
                            values.cycle = schedule.cycle
                        }
                        if (
                            isDefined(schedule.dailySchedule) &&
                            schedule.dailySchedule.length > 0
                        ) {
                            let newDailySchedule = []
                            for (let schd in schedule.dailySchedule) {
                                if (
                                    isDefined(
                                        schedule.dailySchedule[schd].startHour
                                    )
                                ) {
                                    newDailySchedule.push(
                                        schedule.dailySchedule[schd].startHour +
                                            '-' +
                                            schedule.dailySchedule[schd].endHour
                                    )
                                } else {
                                    newDailySchedule.push(
                                        schedule.dailySchedule[schd]
                                    )
                                }
                            }
                            values.dailySchedule = newDailySchedule
                        }
                        if (isDefined(schedule.interval)) {
                            values.interval = schedule.interval.slice(-1)
                            values.intervalday = schedule.interval.replace(
                                /P|D|W|M/g,
                                ''
                            )
                            values.frequencies = '1'
                        }
                        if (isDefined(schedule.intervalday)) {
                            values.intervalday = schedule.intervalday
                        }
                        if (isDefined(schedule.start)) {
                            if (fromapi && isDefined(schedule.start.date)) {
                                values.start = getYMDfromDateObj(
                                    new Date(
                                        createUTCDate(schedule.start.date, true)
                                    )
                                )
                            } else {
                                values.start = schedule.start
                            }
                        }
                        if (isDefined(schedule.end)) {
                            if (fromapi && isDefined(schedule.end.date)) {
                                values.end = getYMDfromDateObj(
                                    new Date(
                                        createUTCDate(schedule.end.date, true)
                                    )
                                )
                            } else {
                                values.end = null
                                if (isValidDateYMDObj(schedule.end)) {
                                    values.end = schedule.end
                                }
                            }
                        }
                        if (isDefined(schedule.before)) {
                            values.frequencies = '2'
                            values.days = []
                            values.dailySchedule = []
                            if (isDefined(schedule.before)) {
                                if (
                                    fromapi &&
                                    isDefined(schedule.before.date)
                                ) {
                                    values.before = getYMDfromDateObj(
                                        new Date(
                                            createUTCDate(schedule.before.date)
                                        )
                                    )
                                } else {
                                    if (isValidDateYMDObj(schedule.before)) {
                                        values.before = schedule.before
                                    }
                                }
                            }
                        }
                        if (
                            isDefined(schedule.frequencies) &&
                            schedule.frequencies !== ''
                        ) {
                            values.frequencies = schedule.frequencies
                            if (schedule.frequencies === '0') {
                                values.days = deepCopy(state.dvalues.days)
                            }
                        }
                        if (isDefined(schedule.alerts)) {
                            let parsedAlerts = [],
                                refKey = null,
                                alertObj = null,
                                alertScenario = null
                            for (var alert in schedule.alerts) {
                                alert = schedule.alerts[alert]
                                alertScenario = alert
                                if (isDefined(alertScenario.scenario)) {
                                    alertScenario = deepCopy(
                                        alertScenario.scenario
                                    )
                                }
                                refKey = null
                                alertObj = null
                                //
                                if (alertScenario.triggerType === 'NODATA') {
                                    refKey = 0
                                } else if (
                                    alertScenario.triggerType === 'INCREASE'
                                ) {
                                    if (+alertScenario.interval === 2) {
                                        refKey = 1
                                    } else if (+alertScenario.interval === 6) {
                                        refKey = 2
                                    }
                                }
                                //
                                if (isDefined(refKey)) {
                                    alertObj =
                                        constants.custo.telesuiviScenarios.filter(
                                            (f) => f.key === refKey
                                        )[0]
                                    if (isDefined(alertObj)) {
                                        alertObj = alertObj.scenario
                                        alertObj.id = alert.id
                                        alertObj.key = refKey
                                        alertObj.dataType = values.measureType
                                        alertObj.value1 = alertScenario.value1
                                        alertObj.interval =
                                            alertScenario.interval
                                        parsedAlerts.push(alertObj)
                                    }
                                }
                            }
                            values.alerts = parsedAlerts
                        }
                    }
                    //
                    if (isDefined(schedule.status)) {
                        values.status = schedule.status
                    } else if (!isDefined(values.status)) {
                        values.status = false
                        let actualMonitoring = deepCopy(state.mesures.activated)
                            .map((m) => m.name)
                            .concat(
                                deepCopy(state.mesures.followed).map(
                                    (m) => m.name
                                )
                            )
                        if (actualMonitoring.indexOf(values.measureType) > -1) {
                            values.status = true
                        }
                    }
                    actions._setState({
                        key: 'scheduleCurrent',
                        value: deepCopy(values),
                    })
                    actions._setState({ key: 'values', value: values })
                },
            /**
             * Verify the validity of current configured mesure
             * @function PatientEditTelesuivi:checkValuesValidity
             */
            checkValuesValidity: () => (state, actions) => {
                actions._setTypingFalse()
                return actions.retrieveErrorMessage(null)
            },
            /**
             * Retrieve color to display in alert listing
             * @function PatientEditTelesuivi:retrieveLegendColor
             * @param {object} e - Dom element to modify
             * @param {object} oValue - Value object containing the value corresponding to searched color
             * @param {string} chart - Chart key to use to retrieve color from constants
             */
            retrieveLegendColor:
                ({ e, oValue, chart }) =>
                (state, actions) => {
                    let defaultLegendColor = state.defaultLegendColor
                    let refLegend = constants.custo.telesuiviKPI.filter(
                        (f) =>
                            f.value.toLowerCase() ===
                            state.editingMesure.crypt.toLowerCase()
                    )[0]
                    let optMean = oValue.objective,
                        legend = null
                    if (
                        !isDefined(oValue.alert) ||
                        !isDefined(oValue.alert.triggerType) ||
                        oValue.alert.triggerType === ''
                    ) {
                        if (isDefined(refLegend)) {
                            refLegend = refLegend.legend
                        }
                        if (isDefined(refLegend)) {
                            if (isDefined(optMean)) {
                                for (legend in refLegend) {
                                    if (refLegend[legend].success === true) {
                                        refLegend[legend].minValue = optMean
                                    } else if (
                                        refLegend[legend].success === false
                                    ) {
                                        refLegend[legend].maxValue = optMean
                                    }
                                }
                            }
                            //
                            if (isDefined(oValue[chart[0].key])) {
                                for (legend in refLegend) {
                                    if (
                                        castToNumberOfFloat(
                                            oValue[chart[0].key]
                                        ) >=
                                        castToNumberOfFloat(
                                            refLegend[legend].minValue
                                        )
                                    ) {
                                        defaultLegendColor =
                                            refLegend[legend].color
                                    }
                                }
                            }
                        }
                    } else {
                        if (oValue.alert.triggerType === 'NODATA') {
                            defaultLegendColor = '#F2F4F6'
                        } else {
                            defaultLegendColor = '#FF3498'
                        }
                    }
                    if (isDefined(e.style)) {
                        e.style.backgroundColor = defaultLegendColor
                    }
                },
            /**
             * Retrieve color to display in alert listing
             * @function PatientEditTelesuivi:retrieveReason
             * @param {object} e - Dom element to modify
             * @param {object} oValue - Value object containing the value corresponding to searched color
             * @param {string} chart - Chart key to use to retrieve color from constants
             */
            retrieveReason:
                ({ e, oValue }) =>
                (state, actions) => {
                    let returnedReason = '-'
                    if (
                        isDefined(oValue.alert) &&
                        oValue.alert.triggerType !== '' &&
                        isDefined(
                            constants.custo.alertesTriggerTypes.filter(
                                (f) => f.key === oValue.alert.triggerType
                            )[0]
                        )
                    ) {
                        let value1 = oValue.alert.value1
                        let interval = oValue.alert.interval
                        if (isDefined(value1) && isDefined(interval)) {
                            returnedReason =
                                '+' +
                                value1 +
                                t('kg') +
                                ' ' +
                                t('en') +
                                ' ' +
                                interval +
                                ' ' +
                                t('jours')
                        } else {
                            returnedReason = t(
                                constants.custo.alertesTriggerTypes.filter(
                                    (f) => f.key === oValue.alert.triggerType
                                )[0].value
                            )
                        }
                    }
                    e.innerHTML = returnedReason
                },
            /**
             * Retrieve crypt value to display in alert listing
             * @function PatientEditTelesuivi:retrieveCryptValue
             * @param {object} item - Value object containing the value to display
             */
            retrieveCryptValue:
                ({ e, item }) =>
                (state, actions) => {
                    let returnValue = 'n.c'
                    let itemValue = item.value
                    let itemValue1 = item.value1
                    let itemInterval = item.interval
                    let itemTriggerType = item.triggerType
                    //
                    if (
                        itemTriggerType !== 'NODATA' &&
                        itemValue !== 'NODATA'
                    ) {
                        let objValue = constants.custo.telesuiviSettings.filter(
                            (f) =>
                                f.crypt.toLowerCase() ===
                                item.dataType.split('_')[0].toLowerCase()
                        )[0]
                        returnValue = item[objValue.chartValueKey]
                        if (isNaN(returnValue) || returnValue === 'NaN') {
                            returnValue = 'n.c'
                        }
                        if (
                            isDefined(objValue.chartValueOffKey) &&
                            objValue.chartValueOffKey.length > 0
                        ) {
                            returnValue +=
                                '/' + item[objValue.chartValueOffKey[0]]
                        }
                        if (!isDefined(returnValue)) {
                            returnValue = 'n.c'
                        }
                    } else if (itemTriggerType === 'INCREASE') {
                        returnValue =
                            '+' +
                            itemValue1 +
                            t('kg') +
                            ' ' +
                            t('en') +
                            ' ' +
                            itemInterval +
                            ' ' +
                            t('jours')
                    }
                    //
                    if (returnValue !== 'n.c') {
                        returnValue +=
                            ' ' +
                            constants.custo.telesuiviKPI.filter(
                                (f) =>
                                    f.value.toLowerCase() ===
                                    item.dataType.split('_')[0].toLowerCase()
                            )[0].sufix
                    }
                    e.innerHTML = returnValue
                },
            /**
             * Redirect from alerts listing to dataviz
             * @function PatientEditTelesuivi:redirectToDatavizAlert
             * @param {string} period - The new period to display
             * @param {date} date - The new date to display
             */
            redirectToDatavizAlert:
                ({ date, crypt, period }) =>
                (state, actions) => {
                    actions._setState({ key: 'currentDate', value: date })
                    actions._setState({ key: 'themeCurrent', value: crypt })
                    actions._setState({ key: 'currentPeriod', value: period })
                    actions._setState({ key: 'menu', value: 'specific' })
                    if (state.isICModule) {
                        actions._setState({ key: 'isICModule', value: false })
                        actions.updateComponent(null)
                        actions.updateUser(state.editedPatient)
                        actions.switchTheme(crypt)
                    } else {
                        actions.switchTheme(crypt)
                    }
                },
            /**
             * Linked to DataViz component, to update current date and period when a click on the dataviz occur, then call [switchAlert]{@link module:PatientEditTelesuivi~PatientEditTelesuivi:switchAlert})
             * @function PatientEditTelesuivi:updatePeriod
             * @param {string} period - The new period to display
             * @param {date} date - The new date to display
             */
            updateFromChildToParent:
                ({ period, date }) =>
                (state, actions) => {
                    state.currentDate = date
                    state.currentPeriod = period
                    actions.switchAlert('specific')
                },
            /**
             * Linked to DatePicker component, to update current period, then call [switchAlert]{@link module:PatientEditTelesuivi~PatientEditTelesuivi:switchAlert})
             * @function PatientEditTelesuivi:updatePeriod
             * @param {string} period - The new period to display
             */
            updatePeriod: (period) => (state, actions) => {
                // actions._setState({key: 'currentPeriod', value: period});
                state.currentPeriod = period
                actions.switchAlert('specific')
            },
            /**
             * Linked to DatePicker component, to update current date, then call [switchAlert]{@link module:PatientEditTelesuivi~PatientEditTelesuivi:switchAlert})
             * @function PatientEditTelesuivi:updateSelector
             * @param {date} date - The new date to display
             */
            updateSelector: (date) => (state, actions) => {
                // actions._setState({key: 'currentDate', value: date});
                state.currentDate = date
                actions.switchAlert('specific')
            },
            /**
             * Update modal display status and kind of display
             * @function PatientEditTelesuivi:setModalDisplayed
             * @param {boolean} active - Show/hide modal, if false, empty <modalEditing>
             */
            setModalDisplayed: (active) => (state, actions) => ({
                modalDisplayed: active,
                modalEditing: active ? state.modalEditing : '',
            }),
            /**
             * Switch from dataviz to configuration
             * @function PatientEditTelesuivi:switchConfiguration
             */
            switchConfiguration: () => (state, actions) => ({
                switchedToConfiguration: !state.switchedToConfiguration,
                forceRender: guid(),
            }),
        }
        PatientEditTelesuivi = { ...coreFn, ...PatientEditTelesuivi }
        return PatientEditTelesuivi
    },
}
