import { User } from '@app/api';
import { isDefined, allRoutes, addCSRFToken, getQueryVariable, coreFn } from '@app/core';

/**
 * @module PatientsListing
 * @description Recovery page
 */

export default {
    initAction: function() {
        let PatientsListing = {
            onComponentCreate: (props) => (state, actions) => {
                let search = getQueryVariable('search', true);
                if (!search) {
                    search = '';
                } else {
                    actions._setState({key: 'search', value: search});
                }
                actions._setState({key: 'customer', value: props.customer});
                actions.getPage();
            },
            /**
            * Redirect when user click on a table row (to survey tab)
            * @function PatientsListing:redirectToPatient
            * @param {string} patientid - Id of patient
            */
            redirectToPatient: (id) => (state, actions) => {
                let doctor = sessionStorage.getItem('adminuser');
                let tabRedirect = '';
                if (isDefined(doctor) && (state.customer === 'oncoflow')) {
                    doctor = JSON.parse(doctor);
                    if (isDefined(doctor.refHosp) && isDefined(doctor.refHosp.customerModules.studySurveyResult) && (doctor.refHosp.customerModules.studySurveyResult === true)) {
                        tabRedirect = '?sp=survey';
                    }
                }
                window.location.href = addCSRFToken(allRoutes['private.patient.edit'].pathname.replace(':id', id) + tabRedirect);
            },
            /**
            * Sort listing, then call [getPage]{@link module:PatientsListing~PatientsListing:getPage})
            * @function PatientsListing:onSortList
            * @param {string} sortby - The sort key
            */
            onSortList: (sortby) => (state, actions) => {
                let sorting = state.sort;
                if (!isDefined(sorting.sort)) {
                    sorting.sort = {};
                }
                if (isDefined(sorting.sort[(sortby)])) {
                    if (sorting.sort[(sortby)] === 1) {
                        sorting.sort[(sortby)] = -1;
                    } else {
                        sorting.sort[(sortby)] = 1;
                    }
                } else {
                    sorting.sort[(sortby)] = 1;
                }
                actions._setState({key: 'sort', value: sorting});
                actions.getPage();
            },
            /**
            * Reset search, then call [getPage]{@link module:PatientsListing~PatientsListing:getPage})
            * @function PatientsListing:resetSearch
            */
            resetSearch: () => (state, actions) => {
                actions._setState({key: 'search', value: ''});
                actions._setState({key: 'loaded', value: false});
                actions._setState({key: 'searching', value: false});
                actions.getPage();
            },
            /**
            * Update listing page following current page, sort and search variables
            * @function PatientsListing:getPage
            */
            getPage: (page = null) => (state, actions) => {
                User.searchTheraflowUser((isDefined(page) ? page : state.page), state.sort, state.search).then((res) => {
                    let totalPatients = null, patients = res.data;
                    if (isDefined(patients)) {
                        if (isDefined(patients.infos)) {
                            totalPatients = patients.infos.count;
                        }
                        if (isDefined(patients.result)) {
                            patients = patients.result;
                        }
                    }
                    actions._setState({key: 'totalPatients', value: totalPatients});
                    actions._setState({key: 'patients', value: patients});
                    actions._setState({key: 'loaded', value: true});
                    if (state.search !== '') {
                        actions._setState({key: 'searching', value: true});
                    }
                });
            },
            /**
            * Parse a patient lname and fname to get a human readable string
            * @param {object} el - object to update
            * @param {object} patient - Patient, containing lname and fname to parse
            * @function PatientsListing:getPatientName
            */
            getPatientName: ({el, patient}) => (state, actions) => {
                let patientLName = patient.lname;
                if (!isDefined(patientLName)) {
                    if (isDefined(patient.name) && isDefined(patient.name.split(' ')[0])) {
                        patientLName = patient.name.split(' ')[0];
                    } else {
                        patientLName = '';
                    }
                }
                //
                let patientFName = patient.fname;
                if (!isDefined(patientFName)) {
                    if (isDefined(patient.name) && isDefined(patient.name.split(' ')[1])) {
                        patientFName = patient.name.split(' ')[1];
                    } else {
                        patientFName = '';
                    }
                }
                let patientName = patientLName.substring(0, 1).toUpperCase() + patientFName.substring(0, 1).toUpperCase();
                el.innerHTML = patientName;
            },
            /**
            * Set current page, then call [getPage]{@link module:PatientsListing~PatientsListing:getPage})
            * @function PatientsListing:targetPage
            */
            targetPage: (page) => (state, actions) => {
                actions._setState({key: 'page', value: page});
                actions.getPage(page);
            },
            /**
            * Increments current page, then call [getPage]{@link module:PatientsListing~PatientsListing:getPage})
            * @function PatientsListing:nextPage
            */
            nextPage: () => (state, actions) => {
                actions._setState({key: 'page', value: state.page + 1});
                actions.getPage();
            },
            /**
            * Increments current page, then call [getPage]{@link module:PatientsListing~PatientsListing:getPage})
            * @function PatientsListing:prevPage
            */
            prevPage: () => (state, actions) => {
                actions._setState({key: 'page', value: state.page - 1});
                actions.getPage();
            },
            /**
            * Update state
            * @function PatientsListing:setState
            * @param {string} key - Target key to update
            * @param {string} value - value
            */
            setState: ({ key, value }) => state => ({
                [key]: value,
            }),
        }
        PatientsListing = {...coreFn, ...PatientsListing};
        return PatientsListing;
    }
}