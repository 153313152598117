import { t } from 'i18next';
import { h } from 'hyperapp';
import { isDefined, getReadableDate, createUTCDate } from '@app/core';
import { Card, Form, FormInput, Tile, Enter, Modal } from '@app/elements';
import * as constants from '@app/constants';

import { ModalViewEmail } from './modals/modalView-email-default';
import { ModalViewPhone } from './modals/modalView-phone-default';
import { ModalViewPassword } from './modals/modalView-password-default';

import icoEdit from '@app/img/ico/ico-edit-pencil.png';
import icoEditx2 from '@app/img/ico/ico-edit-pencil@2x.png';

export default {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div class='patient-central'>
                <Card classes={'btzPageCard btzBackgroundCover'} kind={'datas'} style={{ 'margin': '30px 30px 0px 30px' }}>
                    <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                        <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
                            <dummy>
                                <Tile>
                                    <Tile.Content>
                                        <p class='btzTitle btzXl'>
                                            {t('Sécurité du compte')}
                                        </p>
                                    </Tile.Content>
                                </Tile>

                                <Form onsubmit={event => event.preventDefault()}>

                                    <div class='col-6 col-xs-12' style='vertical-align: top'>
                                        <Form.Group>
                                            <div class='thfAlignTop'>
                                                <p class='btzForm-btzLabel'>{t('Adresse email')}</p>
                                                <FormInput
                                                    placeholder={t('votre email')}
                                                    key='input-email'
                                                    type='text'
                                                    id='email'
                                                    name='email'
                                                    value={state.ovalues.email}
                                                    readonly={true}
                                                    validation={false}
                                                    loaded={state.loaded}
                                                    required
                                                />
                                                <Modal key={'modal-doctor-email'} cancelAction={() => { actions.setModalDisplayed(false) }} modalDisplayed={(state.modalDisplayed && (state.modalEditing.indexOf('email') > -1))} content={<ModalViewEmail displayed={'email'} {...state} retrieveInput={actions._retrieveInput} retrieveErrorMessage={actions._retrieveErrorMessageDoctor} onSubmit={actions.onSubmit} cancelAction={() => { actions.setModalDisplayed(false) }} loaded={true}></ModalViewEmail>}>
                                                    <div onclick={() => {
                                                        actions.onEditEmail();
                                                    }}>
                                                        <img class='btzForm-thfSubline' src={icoEdit} srcset={`${icoEditx2} 2x`} alt='' />
                                                    </div>
                                                </Modal>
                                            </div>
                                            <div class='thfAlignTop'>
                                                <p class='btzForm-btzLabel'>{t('Mot de passe')}</p>
                                                <FormInput
                                                    placeholder={t('votre mot de passe')}
                                                    key='input-password'
                                                    type='password'
                                                    id='password'
                                                    name='password'
                                                    valueType='password'
                                                    value={'**********'}
                                                    readonly={true}
                                                    validation={false}
                                                    loaded={state.loaded}
                                                    required
                                                />
                                                <Modal key={'modal-doctor-password'} cancelAction={() => { actions.setModalDisplayed(false) }} modalDisplayed={(state.modalDisplayed && (state.modalEditing.indexOf('password') > -1))} content={<ModalViewPassword displayed={'password'} {...state} retrieveInput={actions._retrieveInput} retrieveErrorMessage={actions._retrieveErrorMessageDoctor} onSubmit={actions.onSubmit} cancelAction={() => { actions.setModalDisplayed(false) }} loaded={true}></ModalViewPassword>}>
                                                    <div onclick={() => {
                                                        actions.onEditPassword();
                                                    }}>
                                                        <img class='btzForm-thfSubline' src={icoEdit} srcset={`${icoEditx2} 2x`} alt='' />
                                                    </div>
                                                </Modal>
                                            </div>
                                            <div class='thfAlignTop thfCustomPhone'>
                                                <p class='btzForm-btzLabel'>{t('Téléphone portable')}</p>
                                                <FormInput
                                                    key='input-phonePrefix'
                                                    type='dropdown'
                                                    id='phonePrefix'
                                                    name='phonePrefix'
                                                    value={state.ovalues.phonePrefix}
                                                    list={constants.custo.phonePrefixes}
                                                    readonly={true}
                                                    validation={false}
                                                    withSearch={false}
                                                    textedArea={false}
                                                    loaded={state.loaded}
                                                    required
                                                />
                                                <FormInput
                                                    placeholder={['01', '23', '45', '67', '89']}
                                                    key='input-phone'
                                                    type='phone'
                                                    id='phone'
                                                    name='phone'
                                                    valueType='phone'
                                                    value={state.ovalues.phone}
                                                    readonly={true}
                                                    validation={false}
                                                    loaded={state.loaded}
                                                    required
                                                />
                                                <Modal key={'modal-doctor-phone'} cancelAction={() => { actions.setModalDisplayed(false) }} modalDisplayed={(state.modalDisplayed && (state.modalEditing.indexOf('phone') > -1))} content={<ModalViewPhone displayed={'phone'} {...state} retrieveInput={actions._retrieveInput} retrieveErrorMessage={actions._retrieveErrorMessageDoctor} onSubmit={actions.onSubmit} onSubmitPhone={actions.onSubmitPhone} onSubmitPhoneCode={actions.onSubmitPhoneCode} cancelAction={() => { actions.setModalDisplayed(false) }} loaded={true}></ModalViewPhone>}>
                                                    <div onclick={() => {
                                                        actions.onEditPhone();
                                                    }}>
                                                        <img class='btzForm-thfSubline' src={icoEdit} srcset={`${icoEditx2} 2x`} alt='' />
                                                    </div>
                                                </Modal>
                                            </div>
                                            <div class='thfAlignTop'>
                                                <p class='btzForm-btzLabel'>{t('Numéro RPPS')}</p>
                                                <FormInput
                                                    key='input-rpps'
                                                    type='text'
                                                    id='rpps'
                                                    name='rpps'
                                                    value={state.doctor.rpps}
                                                    readonly={true}
                                                    validation={false}
                                                    loaded={state.loaded}
                                                    required
                                                />
                                            </div>
                                            <div class='btzCard-btzContent-btzPage-btzInfo'>
                                                <div class='btzCard-btzHeader-btzPage-btzInfoDevided'>
                                                    <p class='btzCard-btzHeader-btzPage-btzInfoSubTitle'>{t('Dernière connexion')}</p>
                                                    <p class='btzCard-btzHeader-btzPage-btzInfoTitle'>{(isDefined(state.doctor.lastConnexion) ? getReadableDate(createUTCDate(state.doctor.lastConnexion.date.date), 'DD MMMM YYYY, HH:mm') : 'Ø')}</p>
                                                </div>
                                                <div class='btzCard-btzHeader-btzPage-btzInfoDevided'>
                                                    <p class='btzCard-btzHeader-btzPage-btzInfoSubTitle'>{t('Nombre de patients suivis')}</p>
                                                    <p class='btzCard-btzHeader-btzPage-btzInfoTitle'>{(isDefined(state.informations) ? (state.informations.totalPatients) : 'Ø')}</p>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div class='col-6 col-xs-12' style='vertical-align: top'>
                                        <Form.Group>
                                            <div class='thfAlignTop'>
                                                <p class='btzForm-btzLabel'>{t('Langue préférée')}</p>
                                                <FormInput
                                                    key='input-locale'
                                                    type='dropdown'
                                                    id='locale'
                                                    name='locale'
                                                    value={state.doctor.locale}
                                                    list={constants.custo.languages}
                                                    oninputcb={actions._retrieveInput}
                                                    onblurcb={actions._retrieveErrorMessageDoctor}
                                                />
                                            </div>
                                        </Form.Group>
                                    </div>
                                </Form>
                            </dummy>
                        </Enter>
                    </Card.Header>
                </Card>
            </div>
        );
        return view;
    }
}