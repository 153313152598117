const sortTimelineByStudySurvey = (x, y) => {
    const isInferior = x.medicalStudySurvey < y.medicalStudySurvey ? -1 : 1
    return x.medicalStudySurvey === y.medicalStudySurvey ||
        x.medicalStudySurvey === undefined
        ? 0
        : isInferior
}

const sortTimelineByStudyMoment = (x, y) => {
    const isInferior = x.medicalStudyMoment < y.medicalStudyMoment ? -1 : 1
    return x.medicalStudyMoment === y.medicalStudyMoment ||
        x.medicalStudySurvey === undefined
        ? 0
        : isInferior
}

const sortTimelineByStudyDate = (x, y) => {
    const isInferior =
        new Date(x.date.date.split(' ')[0]).getTime() <
        new Date(y.date.date.split(' ')[0]).getTime()
            ? -1
            : 1
    return new Date(x.date.date.split(' ')[0]).getTime() ===
        new Date(y.date.date.split(' ')[0]).getTime()
        ? 0
        : isInferior
}

export {
    sortTimelineByStudySurvey,
    sortTimelineByStudyMoment,
    sortTimelineByStudyDate,
}
