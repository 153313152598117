const uroanxiety113dipru = {
    name: 'uroanxiety113dipru',
    status: true,
    date: {
        date: '2024-02-09 10:46:16.539000',
        timezone_type: 3,
        timezone: 'UTC',
    },
    userMedicalStudyId: '65c60278fe36739d8201f288',
    userMedicalStudyDatas: {
        issueSentBy: 'SMS',
        inclusionDate: {
            date: '2024-02-09 23:00:00.000000',
            timezone_type: 3,
            timezone: 'UTC',
        },
        surgeryDate: {
            date: '2024-02-14 23:00:00.000000',
            timezone_type: 3,
            timezone: 'UTC',
        },
        status: true,
    },
    urlToken: null,
    timeline: [
        {
            type: 'DATE',
            date: {
                date: '2024-02-09 23:00:00.000000',
                timezone_type: 3,
                timezone: 'UTC',
            },
            label: 'inclusionDate',
            inFutur: false,
        },
        {
            type: 'SURVEY',
            date: {
                date: '2024-02-11 00:00:00.000000',
                timezone_type: 3,
                timezone: 'Europe/Paris',
            },
            isExpired: false,
            medicalStudyMoment: 'M1',
            medicalStudySurvey: 'EQ5D5L',
            answered: false,
            alerts: null,
            action: null,
            commentsCount: null,
            timelineCardId: null,
            surveyId: null,
            answerId: null,
            inFutur: true,
        },
        {
            type: 'SURVEY',
            date: {
                date: '2024-02-11 00:00:00.000000',
                timezone_type: 3,
                timezone: 'Europe/Paris',
            },
            isExpired: false,
            medicalStudyMoment: 'M1',
            medicalStudySurvey: 'HLSEUQ16',
            answered: false,
            alerts: null,
            action: null,
            commentsCount: null,
            timelineCardId: null,
            surveyId: null,
            answerId: null,
            inFutur: true,
        },
        {
            type: 'SURVEY',
            date: {
                date: '2024-02-11 00:00:00.000000',
                timezone_type: 3,
                timezone: 'Europe/Paris',
            },
            isExpired: false,
            medicalStudyMoment: 'M1',
            medicalStudySurvey: 'STAI',
            answered: false,
            alerts: null,
            action: null,
            commentsCount: null,
            timelineCardId: null,
            surveyId: null,
            answerId: null,
            inFutur: true,
        },
        {
            type: 'DATE',
            date: {
                date: '2024-02-14 23:00:00.000000',
                timezone_type: 3,
                timezone: 'UTC',
            },
            label: 'surgeryDate',
            inFutur: true,
        },
        {
            type: 'SURVEY',
            date: {
                date: '2024-02-14 00:00:00.000000',
                timezone_type: 3,
                timezone: 'Europe/Paris',
            },
            isExpired: false,
            medicalStudyMoment: 'M3',
            medicalStudySurvey: 'STAI',
            answered: false,
            alerts: null,
            action: null,
            commentsCount: null,
            timelineCardId: null,
            surveyId: null,
            answerId: null,
            inFutur: true,
        },
        {
            type: 'SURVEY',
            date: {
                date: '2024-02-14 00:00:00.000000',
                timezone_type: 3,
                timezone: 'Europe/Paris',
            },
            isExpired: false,
            medicalStudyMoment: 'M3',
            medicalStudySurvey: 'WAKE',
            answered: false,
            alerts: null,
            action: null,
            commentsCount: null,
            timelineCardId: null,
            surveyId: null,
            answerId: null,
            inFutur: true,
        },
        {
            type: 'SURVEY',
            date: {
                date: '2024-03-01 00:00:00.000000',
                timezone_type: 3,
                timezone: 'Europe/Paris',
            },
            isExpired: false,
            medicalStudyMoment: 'M5',
            medicalStudySurvey: 'EQ5D5L',
            answered: false,
            alerts: null,
            action: null,
            commentsCount: null,
            timelineCardId: null,
            surveyId: null,
            answerId: null,
            inFutur: true,
        },
        {
            type: 'SURVEY',
            date: {
                date: '2024-03-01 00:00:00.000000',
                timezone_type: 3,
                timezone: 'Europe/Paris',
            },
            isExpired: false,
            medicalStudyMoment: 'M5',
            medicalStudySurvey: 'HLSEUQ16',
            answered: false,
            alerts: null,
            action: null,
            commentsCount: null,
            timelineCardId: null,
            surveyId: null,
            answerId: null,
            inFutur: true,
        },
        {
            type: 'SURVEY',
            date: {
                date: '2024-03-01 00:00:00.000000',
                timezone_type: 3,
                timezone: 'Europe/Paris',
            },
            isExpired: false,
            medicalStudyMoment: 'M5',
            medicalStudySurvey: 'STAI',
            answered: false,
            alerts: null,
            action: null,
            commentsCount: null,
            timelineCardId: null,
            surveyId: null,
            answerId: null,
            inFutur: true,
        },
        {
            type: 'SURVEY',
            date: {
                date: '2024-03-16 00:00:00.000000',
                timezone_type: 3,
                timezone: 'Europe/Paris',
            },
            isExpired: false,
            medicalStudyMoment: 'M7',
            medicalStudySurvey: 'ESATIS',
            answered: false,
            alerts: null,
            action: null,
            commentsCount: null,
            timelineCardId: null,
            surveyId: null,
            answerId: null,
            inFutur: true,
        },
    ],
}

const uroanxiety113dipruSortedBySurvey = [
    {
        date: {
            date: '2024-02-09 23:00:00.000000',
            timezone: 'UTC',
            timezone_type: 3,
        },
        inFutur: false,
        label: 'inclusionDate',
        type: 'DATE',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-02-11 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M1',
        medicalStudySurvey: 'EQ5D5L',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-03-01 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M5',
        medicalStudySurvey: 'EQ5D5L',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-03-16 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M7',
        medicalStudySurvey: 'ESATIS',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-02-11 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M1',
        medicalStudySurvey: 'HLSEUQ16',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-03-01 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M5',
        medicalStudySurvey: 'HLSEUQ16',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-02-11 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M1',
        medicalStudySurvey: 'STAI',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        date: {
            date: '2024-02-14 23:00:00.000000',
            timezone: 'UTC',
            timezone_type: 3,
        },
        inFutur: true,
        label: 'surgeryDate',
        type: 'DATE',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-02-14 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M3',
        medicalStudySurvey: 'STAI',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-03-01 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M5',
        medicalStudySurvey: 'STAI',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-02-14 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M3',
        medicalStudySurvey: 'WAKE',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
]

const uroanxiety113dipruSortedByMoment = [
    {
        date: {
            date: '2024-02-09 23:00:00.000000',
            timezone: 'UTC',
            timezone_type: 3,
        },
        inFutur: false,
        label: 'inclusionDate',
        type: 'DATE',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-02-11 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M1',
        medicalStudySurvey: 'EQ5D5L',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-02-11 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M1',
        medicalStudySurvey: 'HLSEUQ16',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-02-11 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M1',
        medicalStudySurvey: 'STAI',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-02-14 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M3',
        medicalStudySurvey: 'STAI',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-02-14 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M3',
        medicalStudySurvey: 'WAKE',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-03-01 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M5',
        medicalStudySurvey: 'EQ5D5L',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-03-01 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M5',
        medicalStudySurvey: 'HLSEUQ16',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-03-01 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M5',
        medicalStudySurvey: 'STAI',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-03-16 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M7',
        medicalStudySurvey: 'ESATIS',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        date: {
            date: '2024-02-14 23:00:00.000000',
            timezone: 'UTC',
            timezone_type: 3,
        },
        inFutur: true,
        label: 'surgeryDate',
        type: 'DATE',
    },
]

const uroanxiety113dipruSortedByDate = [
    {
        date: {
            date: '2024-02-09 23:00:00.000000',
            timezone: 'UTC',
            timezone_type: 3,
        },
        inFutur: false,
        label: 'inclusionDate',
        type: 'DATE',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-02-11 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M1',
        medicalStudySurvey: 'EQ5D5L',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-02-11 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M1',
        medicalStudySurvey: 'HLSEUQ16',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-02-11 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M1',
        medicalStudySurvey: 'STAI',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-02-14 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M3',
        medicalStudySurvey: 'STAI',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-02-14 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M3',
        medicalStudySurvey: 'WAKE',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        date: {
            date: '2024-02-14 23:00:00.000000',
            timezone: 'UTC',
            timezone_type: 3,
        },
        inFutur: true,
        label: 'surgeryDate',
        type: 'DATE',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-03-01 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M5',
        medicalStudySurvey: 'EQ5D5L',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-03-01 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M5',
        medicalStudySurvey: 'HLSEUQ16',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-03-01 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M5',
        medicalStudySurvey: 'STAI',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-03-16 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M7',
        medicalStudySurvey: 'ESATIS',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
]

const uroanxiety113dipruSortedByAllSort = [
    {
        date: {
            date: '2024-02-09 23:00:00.000000',
            timezone: 'UTC',
            timezone_type: 3,
        },
        inFutur: false,
        label: 'inclusionDate',
        type: 'DATE',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-02-11 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M1',
        medicalStudySurvey: 'EQ5D5L',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-02-11 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M1',
        medicalStudySurvey: 'HLSEUQ16',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-02-11 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M1',
        medicalStudySurvey: 'STAI',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-02-14 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M3',
        medicalStudySurvey: 'STAI',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-02-14 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M3',
        medicalStudySurvey: 'WAKE',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        date: {
            date: '2024-02-14 23:00:00.000000',
            timezone: 'UTC',
            timezone_type: 3,
        },
        inFutur: true,
        label: 'surgeryDate',
        type: 'DATE',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-03-01 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M5',
        medicalStudySurvey: 'EQ5D5L',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-03-01 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M5',
        medicalStudySurvey: 'HLSEUQ16',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-03-01 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M5',
        medicalStudySurvey: 'STAI',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
    {
        action: null,
        alerts: null,
        answerId: null,
        answered: false,
        commentsCount: null,
        date: {
            date: '2024-03-16 00:00:00.000000',
            timezone: 'Europe/Paris',
            timezone_type: 3,
        },
        inFutur: true,
        isExpired: false,
        medicalStudyMoment: 'M7',
        medicalStudySurvey: 'ESATIS',
        surveyId: null,
        timelineCardId: null,
        type: 'SURVEY',
    },
]

export {
    uroanxiety113dipru,
    uroanxiety113dipruSortedBySurvey,
    uroanxiety113dipruSortedByMoment,
    uroanxiety113dipruSortedByDate,
    uroanxiety113dipruSortedByAllSort,
}
