import { h } from 'hyperapp'
import './index.scss'

import imgDelete from './delete-icon.png'
import imgDeletex2 from './delete-icon@2x.png'

const Delete = (props, children) => {
    const { ...otherProps } = props

    return (
        <img
            alt={'delete'}
            class="sup-delete"
            src={imgDelete}
            srcset={`${imgDeletex2} 2x`}
            onclick={() => {
                props.action()
            }}
            style={{ position: 'absolute', top: '-10px', right: '-20px' }}
        />
    )
}

export { Delete }
