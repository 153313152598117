import { t } from 'i18next'
import {
    allRoutes,
    isDefined,
    logoutAndRedirect,
    getQueryVariable,
    isAuthorizedRole,
    definePatientSession,
} from '@app/core'

import { handleErrors, displayErrors } from '@app/api/errors'
import { handleHeaders } from '@app/api/headers'
import { Loader } from '@app/api/loader'

const executeSynchedQuery = (
    url,
    method = 'POST',
    ctype = 'application/json',
    withToken = true,
    datas = null
) => {
    return new Promise(function (resolve, reject) {
        var request = new XMLHttpRequest()
        request.open(method, url)
        request.setRequestHeader('Content-Type', ctype)
        if (withToken) {
            request.setRequestHeader(
                'X-Token',
                localStorage.getItem('apitoken')
            )
        }
        request.onload = resolve
        request.onerror = reject
        request.send(datas)
    })
}

const executeQuery = async (
    url,
    method,
    ctype,
    withLoader,
    withHeader,
    withErrors,
    withToken,
    mustBeQueued,
    datas,
    withVersion,
    flag
) => {
    if (
        !isDefined(flag) ||
        (isDefined(flag) &&
            [
                'doctorSession',
                'doctorReset',
                'doctorAccount',
                'doctorConsent',
            ].indexOf(flag) === -1)
    ) {
        if (
            !isAuthorizedRole() &&
            method !== 'GET' &&
            url.indexOf('listing') === -1 &&
            url.indexOf('search') === -1
        ) {
            displayErrors(
                t('Votre rôle ne vous permet pas de réaliser cette opération.'),
                5000
            )
            return false
        }
    }
    //
    let overrideapi = sessionStorage.getItem('overrideapi')
    if (isDefined(overrideapi) && overrideapi !== 'null') {
        if (url.indexOf('-release') > -1) {
            overrideapi = overrideapi.replace('-api', '-api-release')
        }
        if (url.indexOf('https') > -1) {
            url = url.replace(
                /(https:|)(^|\/\/)(.*?\/)/g,
                'https://' + overrideapi + '/'
            )
        } else {
            url = url.replace(
                /(http:|)(^|\/\/)(.*?\/)/g,
                'https://' + overrideapi + '/'
            )
        }
    }
    //
    let token = localStorage.getItem('apitoken')
    if (!isDefined(token)) {
        token = getQueryVariable('xtoken', false)
    }
    //
    if (withLoader) {
        Loader.addQuery()
    }
    let oHeader = {}
    if (withToken) {
        if (ctype === null) {
            if (!isDefined(token)) {
                logoutAndRedirect()
            }
            oHeader = {
                'X-Token': token,
            }
        } else {
            if (!isDefined(token)) {
                logoutAndRedirect()
            }
            oHeader = {
                'X-Token': token,
                'Content-Type': ctype,
            }
        }
    } else if (ctype !== null) {
        oHeader = {
            'Content-Type': ctype,
        }
    }
    if (withVersion) {
        if (!isDefined(oHeader)) {
            oHeader = {}
        }
        oHeader['X-Version'] = process.env.APP_VERSION || 'N.A'
    }

    let oBody = null
    let totalSize = 0
    if (datas instanceof FormData) {
        oBody = datas
        for (var bod of oBody.entries()) {
            for (var entry in bod) {
                if (bod[entry].size !== undefined) {
                    totalSize += bod[entry].size
                }
            }
        }
    } else if (datas !== null) {
        oBody = JSON.stringify(datas)
    }
    if (totalSize > 2000000) {
        displayErrors(
            t(
                'Le contenue de la requête est supérieur à 2M, veuillez envoyer moins de donnée ou une image moins grande!'
            ),
            5000
        )
        return false
    }
    return await fetch(url, {
        method: method,
        headers: new Headers(oHeader),
        body: oBody,
    })
        .then((response) => {
            if (withLoader) {
                Loader.removeQuery()
            }
            return response
        })
        .then((response) => {
            if (withHeader) {
                return handleHeaders(response)
            } else {
                return response
            }
        })
        .then((response) => {
            if (response.status === 204) {
                return {}
            }
            return response.json()
        })
        .then((response) => {
            if (withErrors) {
                return handleErrors(response)
            } else {
                return response
            }
        })
        .then((data) => {
            return { data: data }
        })
        // .then(data => ({ data }))
        .catch((error) => {
            Loader.removeQuery()
            if (String(error).indexOf('fetch') > -1) {
                error = t(
                    'Erreur de communication avec le serveur, veuillez réessayer.'
                )
            }
            displayErrors(error, 2000)
            return { error: error }
        })
    //.catch(error => ({ error }))
}

let onceRefreshAtOnce = false
const TIMER = 1000
function sleeping() {
    var promise = new Promise(function (resolve) {
        window.setTimeout(function () {
            resolve('sleep done.')
        }, TIMER)
    })
    return promise
}

const queueQueries = async (
    url,
    method,
    ctype = 'application/json',
    withLoader = true,
    withHeader = true,
    withErrors = true,
    withToken = true,
    mustBeQueued = true,
    datas = null,
    withVersion = false,
    flag = null
) => {
    let expiration = localStorage.getItem('expiration')
    if (expiration != false && expiration !== null && expiration !== 'null') {
        if (onceRefreshAtOnce) {
            return sleeping().then(() => {
                return queueQueries(
                    url,
                    method,
                    ctype,
                    withLoader,
                    withHeader,
                    withErrors,
                    withToken,
                    mustBeQueued,
                    datas,
                    withVersion,
                    flag
                )
            })
        } else {
            let expirationDate = expiration.split(' ')[0].split('-')
            let expirationTime = expiration
                .split(' ')[1]
                .split('.')[0]
                .split(':')
            let dateExpiration = new Date(
                Date.UTC(
                    expirationDate[0],
                    +expirationDate[1] - 1,
                    expirationDate[2],
                    expirationTime[0],
                    expirationTime[1],
                    expirationTime[2]
                )
            )
            let dateOffset = new Date().getTimezoneOffset()
            dateExpiration.setMinutes(dateExpiration.getMinutes() - dateOffset)

            let now = new Date()
            now.setMinutes(now.getMinutes() - dateOffset)

            if (dateExpiration.getTime() < now.getTime()) {
                return refreshSession()
            } else {
                return executeQuery(
                    url,
                    method,
                    ctype,
                    withLoader,
                    withHeader,
                    withErrors,
                    withToken,
                    mustBeQueued,
                    datas,
                    withVersion,
                    flag
                )
            }
        }
    } else {
        return executeQuery(
            url,
            method,
            ctype,
            withLoader,
            withHeader,
            withErrors,
            withToken,
            mustBeQueued,
            datas,
            withVersion,
            flag
        )
    }
}

function refreshSession() {
    let logoutMessage = t(
        "Votre dernière connexion a été effectuée il y a plus d'une heure. Pour des raisons de sécurité vous devez vous reconnecter. Veuillez vérifier que vos dernières modifications sur le service ont bien été prises en compte."
    )
    let refreshToken = localStorage.getItem('refresh')
    //
    let optRedirect = ''
    if (
        window.location.href.indexOf('chv') > -1 ||
        window.location.href.indexOf('ijb') > -1
    ) {
        optRedirect = '?redirect=public.redirectingway' + '&e=' + logoutMessage
        localStorage.removeItem('apitoken')
        definePatientSession()
    } else {
        optRedirect = '?e=' + logoutMessage
    }
    //
    let redirectURL = allRoutes['index'].pathname + optRedirect
    onceRefreshAtOnce = true
    if (refreshToken) {
        window.location.href = redirectURL
    } else {
        window.location.href = redirectURL
        return false
    }
}

export { queueQueries }
export { executeSynchedQuery }
export { executeQuery }
