import { getYMDfromDateObj } from '@app/core';

export const state = {
    customer: 'theraflow',
    treatments: {running: [], ended: []},
    currentTreatment: null,
    formIsSubmitting: false,
    editFromRecap: false,
    treatmentStep: 0,
    drugsList: [],
    dprescription: {
        marker: null,
        isvalid: false,
        startHour: null,
        endHour: null,
        push: true,
        dosage: '1',
        dosageUnit: 'pill',
        content: '',
    },
    dvalues: {
        name: '',
        ddays: [1, 2],
        days: [1, 2, 3, 4, 5, 6, 7],
        frequencies: '0',
        takeday: '1',
        breakday: '1',
        interval: 'D',
        intervalday: 2,
    },
    ovalues: {
        name: '',
        days: [1],
        frequencies: '',
        interval: '',
        intervalday: '',
        cycle: '',
        takeday: '',
        breakday: '',
        start: getYMDfromDateObj(),
        end: {
            year: '',
            month: '',
            day: ''
        },
        prescription: [],
        toDelete: false,
        measureType: '',
    },
    previousValues: {},
    values: {},
    isTouched: {
        name: false,
        days: false,
        frequencies: false,
        interval: false,
        intervalday: false,
        cycle: false,
        takeday: false,
        breakday: false,
        start: false,
        end: false,
        prescription: false,
    },
    isValid: {
        name: false,
        days: false,
        frequencies: true,
        interval: false,
        intervalday: false,
        cycle: false,
        takeday: false,
        breakday: false,
        start: false,
        end: false,
        prescription: false,
    },
    isTyping: {
        name: false,
        days: false,
        frequencies: false,
        interval: false,
        intervalday: false,
        cycle: false,
        takeday: false,
        breakday: false,
        start: false,
        end: false,
        prescription: false,
    },
    errorMessages: {
        name: '',
        days: '',
        frequencies: '',
        interval: '',
        intervalday: '',
        cycle: '',
        takeday: '',
        breakday: '',
        start: '',
        end: '',
        prescription: '',
    },
    formSubmittedOnce: false,
    deactivationConfirm: false,
    deactivationConfirmKind: '',
    modalEditing: '',
    charsReplace: {'\u00e9': 'é', '\u00e8': 'è', '\u00e0': 'à'},
};
